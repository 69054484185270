import React, { PureComponent, ReactNode } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { i18n } from "../../../../../configuration/i18n";
import { CompanyDataTable } from "./company.dataTable";
import { CompanyDataType } from "../../superAdminStats/stats.container";
import './company.tabs.css';
import { CompanyChart } from "./company.chart";

interface Props {
    commerce: CompanyDataType[]
    association: CompanyDataType[]
    entreprise: CompanyDataType[]
    cumulativeChartDataSets: Map<string, number>;
}

export class CompanyTabs extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className={'company_tab_container mb-3'}>
                <div className="mb-4">
                    <h2 className="title-stats">{i18n.stats.companies_list}</h2>
                </div>
                <Tabs>
                    <TabList>
                        <Tab>{i18n.stats.chart}</Tab>
                        <Tab>{i18n.stats.commerce}</Tab>
                        <Tab>{i18n.stats.association}</Tab>
                        <Tab>{i18n.stats.entreprise}</Tab>
                    </TabList>

                    <TabPanel>
                        <CompanyChart cumulativeDataSets={this.props.cumulativeChartDataSets}/>
                    </TabPanel>

                    <TabPanel>
                        <CompanyDataTable data={this.props.commerce}/>
                    </TabPanel>

                    <TabPanel>
                        <CompanyDataTable data={this.props.association}/>
                    </TabPanel>

                    <TabPanel>
                        <CompanyDataTable data={this.props.entreprise}/>
                    </TabPanel>
                </Tabs>
            </div>

        )
    }
}
