import React from "react";
import { i18n } from "../../../../../configuration/i18n";
import { TextInput } from "../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeConfigurationDetailsType } from "../types";
import { ThemeFontSelector } from "./font.selector";

interface Props {
    data: ThemeConfigurationDetailsType;
    isSubmitted: boolean;
    onChange: (key: keyof ThemeConfigurationDetailsType, value: string) => void;
    onPrimaryColorChange: (value: string) => void;
}

export const GeneralConfiguration = (props: Props): JSX.Element => {
    return (
        <div>
            <TextInput type={'text'}
                       error={props.isSubmitted && props.data.website.length === 0}
                       label={i18n.company.website}
                       value={props.data.website ?? ''}
                       placeholder={'mondomaine.fr'}
                       raiseUpdates={(value: string) => props.onChange('website', value)}/>

            <TextInput type={'text'}
                       error={props.isSubmitted && props.data.title.length === 0}
                       label={i18n.company.website_title}
                       value={props.data.title ?? ''}
                       raiseUpdates={(value: string) => props.onChange('title', value)}/>

            <TextInput type={'color'}
                       error={props.isSubmitted && props.data.primaryColor.length === 0}
                       label={i18n.company.website_primary_color}
                       value={props.data.primaryColor ?? ''}
                       raiseUpdates={(value: string) => props.onPrimaryColorChange(value)}/>

            <TextInput type={'color'}
                       error={props.isSubmitted && props.data.secondaryColor.length === 0}
                       label={i18n.company.website_secondary_color}
                       value={props.data.secondaryColor ?? ''}
                       raiseUpdates={(value: string) => props.onChange('secondaryColor', value)}/>

            <ThemeFontSelector error={props.isSubmitted && props.data.primaryFont.length === 0}
                               label={i18n.company.website_primary_font}
                               value={props.data.primaryFont ?? ''}
                               onChange={(value: string) => props.onChange('primaryFont', value)}/>

            <ThemeFontSelector error={props.isSubmitted && props.data.secondaryFont.length === 0}
                               label={i18n.company.website_secondary_font}
                               value={props.data.secondaryFont ?? ''}
                               onChange={(value: string) => props.onChange('secondaryFont', value)}/>
        </div>
    )
}
