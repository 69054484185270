import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { LOAD_MANAGEMENT_INSTANT, LoadManagementInstantAction } from "./loadManagementInstant.types";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";
import { ManagementInstant } from "../../domain/entities/ManagementInstant";
import { loadManagementInstantFailed, loadManagementInstantSucceeded } from "./loadManagementInstant.actions";

export const loadManagementInstantEpic: Epic = (action$: ActionsObservable<LoadManagementInstantAction>,
                                                store: StateObservable<AppState>,
                                                { instantManagementService }: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(LOAD_MANAGEMENT_INSTANT),
        switchMap(action => instantManagementService.loadManagementInstantById(action.payload)
            .pipe(
                map((instant: ManagementInstant) => loadManagementInstantSucceeded(instant)),
                catchError(error => of(loadManagementInstantFailed(error)))
            )
        )
    )
