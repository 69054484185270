import {
    UpdateInstantStatusAction,
    UpdateInstantStatusFailedAction,
    UpdateInstantStatusSucceededAction
} from "./updateInstantStatus.types";

export const UPDATE_INSTANT_STATUS = 'UPDATE_INSTANT_STATUS'
export const UPDATE_INSTANT_STATUS_SUCCEEDED = 'UPDATE_INSTANT_STATUS_SUCCEEDED'
export const UPDATE_INSTANT_STATUS_FAILED = 'UPDATE_INSTANT_STATUS_FAILED'

export const updateInstantStatus = (instantId: string, status: boolean): UpdateInstantStatusAction => ({
    type   : UPDATE_INSTANT_STATUS,
    payload: { instantId, status }
})

export const updateInstantStatusSucceeded = (): UpdateInstantStatusSucceededAction => ({
    type: UPDATE_INSTANT_STATUS_SUCCEEDED
})

export const updateInstantStatusFailed = (error: string): UpdateInstantStatusFailedAction => ({
    type   : UPDATE_INSTANT_STATUS_FAILED,
    payload: error
})
