import React, { PureComponent, ReactNode } from "react";
import { Schedule } from "../../../../../domain/entities/types/activationTimeType";
import { RecurrentSchedulePreview } from "./recurrentSchedule.preview";
import { RecurrentScheduleForm } from "./recurrentSchedule.form";
import { CompanyOpeningHours } from "../../../../../../company/domain/entity/companyOpeningHours";

interface Props {
    schedules: Schedule[] | undefined
    companyOpeningHours: CompanyOpeningHours[] | undefined
    raiseUpdate: (schedule: Schedule[]) => void
    cleanAllSchedules: () => void
}

interface State {
    schedules: Schedule[] | undefined;
}

export class RecurrentScheduleContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            schedules: this.props.schedules
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.schedules && this.props.schedules !== prevProps.schedules)
            this.setState({ schedules: this.props.schedules })
    }

    render(): ReactNode {
        const recurrentSchedulePreviews = this.state.schedules?.map((schedule: Schedule, index: number) => {
            return <RecurrentSchedulePreview onRemove={(): void => this.removeSchedule(index)}
                                             schedule={schedule}
                                             key={Math.random() + (new Date()).getTime()}/>
        })

        return (
            <div>
                <div className={'opening-hours-items sub-section wizard-form-input'}>
                {recurrentSchedulePreviews}
                </div>
                <RecurrentScheduleForm schedules={this.state.schedules}
                                       companyOpeningHours={this.props.companyOpeningHours}
                                       cleanAllSchedules={() => this.props.cleanAllSchedules()}
                                       raiseUpdate={(schedules: Schedule[]): void => {
                                           this.setState({ schedules: [...schedules] })
                                           this.props.raiseUpdate([...schedules])
                                       }}/>
            </div>
        )
    }

    removeSchedule(index: number): void {
        if (this.state.schedules) {
            const newSchedule = [...this.state.schedules]
            newSchedule.splice(index, 1)
            this.setState({
                schedules: [...newSchedule]
            }, () => this.cleanAndUpdate())
        }
    }

    private cleanAndUpdate(): void {
        if (this.state.schedules) {
            const cleanedSchedules = this.state.schedules.map(item => {
                if (this.isUUID(item.id))
                    return { ...item, id: '' }
                return item
            })
            this.props.raiseUpdate(cleanedSchedules)
        }
    }

    private isUUID(id: string): boolean {
        const regexp = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
        return regexp.test(id);
    }
}
