import React from 'react'
import { Company } from "../../../../../company/domain/entity/company";
import { ManagementInstantFormType } from "../../form/type/managementInstantFormType";
import { ManagementInstant } from "../../../../domain/entities/ManagementInstant";
import { ApplicationContext } from "../../../../../configuration/application.context";
import { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { ManagementInstantCoordinates } from "../../../../domain/entities/ManagementInstantCoordinates";
import { ActivationTimeType } from "../../../../domain/entities/types/activationTimeType";
import { InstantLocationSection } from "../../form/sections/instantLocation.section";
import { InstantPlaningSection } from "../../form/sections/instantPlaning.section";
import { InstantNotificationSection } from "../../form/sections/instantNotification.section";
import { ManagementInstantBuilder } from "../../../../domain/entities/managementInstantBuilder";
import { InstantFormValidator } from "../../form/instantForm.validator";
import { NotificationDateModal } from "../../form/components/notificationDate.modal";
import { BasicInformationOfferInstantSection } from "./component/basicInformationOfferInstant.section";

interface Props {
    createInstant: (instant: ManagementInstant) => void;
    company: Company | null
    loadingCreation: boolean
    successCreation: null | boolean
    onCreate: () => void
}

interface State extends ManagementInstantFormType {
    id: string;
    businessId: string;
    activeSections: number[];
    errorSection: number | undefined;
    errorMsg: string
    visibleNotificationModal: boolean
}

const moment = ApplicationContext.getInstance().momentJs()

export class CreateSpecialOfferInstantContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            id                      : '',
            businessId              : '',
            description             : '',
            picture                 : undefined,
            pictureUrl              : undefined,
            title                   : '',
            category                : 'gift',
            longDescription         : '',
            address                 : '',
            city                    : '',
            zip                     : '',
            coordinates             : {
                latitude : 0,
                longitude: 0
            },
            activationTime          : undefined,
            notificationSent        : undefined,
            notificationDate        : undefined,
            activeSections          : [0],
            errorSection            : undefined,
            errorMsg                : '',
            visibleNotificationModal: false
        }
    }

    componentDidMount(): void {
        document.title = i18n.management_instant.offer_creation_page_title;
        if (this.props.company)
            this.setState({
                businessId : this.props.company.id,
                address    : this.props.company.address.address,
                city       : this.props.company.address.city,
                zip        : this.props.company.address.zipCode,
                coordinates: {
                    latitude : Number(this.props.company.address.latitude),
                    longitude: Number(this.props.company.address.longitude)
                }
            })
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.company !== this.props.company && this.props.company)
            this.setState({
                businessId : this.props.company.id,
                address    : this.props.company.address.address,
                city       : this.props.company.address.city,
                zip        : this.props.company.address.zipCode,
                coordinates: {
                    latitude : Number(this.props.company.address.latitude),
                    longitude: Number(this.props.company.address.longitude)
                }
            })
        if (this.props.successCreation && prevProps.loadingCreation !== this.props.loadingCreation)
            this.props.onCreate()
    }

    render(): ReactNode {
        const loader = this.props.loadingCreation && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        return (
            <div className="profile-edition">
                {loader}
                <div className="wizard-form-field mb-85">

                    <BasicInformationOfferInstantSection instant={this.state}
                                                         raiseUpdates={(key, value): void => this.raiseUpdates(key, value)}
                                                         hasError={this.state.errorSection === 0}/>
                    <InstantLocationSection instant={this.state}
                                            raiseUpdates={(key, value): void => this.raiseUpdates(key, value)}
                                            hasError={this.state.errorSection === 1}/>
                    <InstantPlaningSection instant={this.state}
                                           companyOpeningHours={this.props.company?.openingHours}
                                           raiseUpdate={(key, value): void => this.raiseUpdates(key, value)}
                                           hasError={this.state.errorSection === 2}/>
                    <InstantNotificationSection notificationDate={this.state.notificationDate}
                                                isNotificationSent={this.state.notificationSent}
                                                allowedNotification={this.props.company ? this.props.company.membership.allowedNotifications === -1 : false}
                                                raiseUpdate={(date: string | undefined): void => this.raiseUpdates('notificationDate', date)}
                                                hasError={this.state.errorSection === 3}/>

                    <p className={'error mt-4'}>{this.state.errorMsg}</p>

                    <button className={'instant-submit-btn'} onClick={(e) => {
                        e.preventDefault();
                        this.openNotificationModal()
                    }}>
                        {i18n.management_instant.save_instant}
                    </button>
                    <NotificationDateModal visible={this.state.visibleNotificationModal}
                                           onClose={() => this.setState({visibleNotificationModal: false})}
                                           publishInstant={() => this.createInstant()}
                                           setNotificationDate={() => this.setNotificationDateAndSave()}/>
                </div>
            </div>
        )
    }

    openNotificationModal(): void {
        const submissionResult = InstantFormValidator.validate(this.state)
        if (submissionResult === undefined && this.props.loadingCreation === false) {
            if (this.state.notificationDate)
                this.createInstant()
            else
                this.setState({visibleNotificationModal: true})

        } else if (submissionResult !== undefined) {
            this.setState({
                errorSection  : submissionResult,
                activeSections: [submissionResult],
                errorMsg      : this.renderError()
            })
        }
    }

    setNotificationDateAndSave(): void {
        this.setState({
            visibleNotificationModal: false,
            notificationDate        : moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm')
        }, () => this.createInstant())

    }

    raiseUpdates(key: keyof ManagementInstantFormType, value: string | File | ActivationTimeType | ManagementInstantCoordinates | undefined): void {
        this.setState({
            ...this.state, [key]: value
        })
    }

    createInstant(): void {
        const notificationDate = this.state.notificationDate ? moment(this.state.notificationDate, 'YYYY-MM-DD HH:mm').format() : undefined
        if (this.props.company && this.state.picture && this.state.activationTime) {
            const instantSubmission = new ManagementInstantBuilder()
                .withCategory('gift')
                .withBusinessId(this.props.company.id)
                .withTitle(this.state.title)
                .withShortDescription(this.state.description)
                .withLongDescription(this.state.longDescription)
                .withPicture(this.state.picture)
                .withAddress(this.state.address)
                .withCity(this.state.city)
                .withZipcode(Number(this.state.zip))
                .withActivationTimeType(this.state.activationTime)
                .withNotificationDate(notificationDate)
                .build()
            this.props.createInstant(instantSubmission)
        }
    }

    renderError(): string {
        if (this.state.title.length === 0 || this.state.description.length === 0 ||
            this.state.longDescription.length === 0 || !this.state.picture ||
            this.state.address.length === 0 || this.state.city.length === 0 || this.state.zip.length === 0)
            return i18n.management_instant.form_data_error
        else if (!InstantFormValidator.checkTitle(this.state.title))
            return i18n.management_instant.title_format_error
        else if (!InstantFormValidator.checkDescription(this.state.description) || this.state.longDescription.length < 17)
            return i18n.management_instant.description_format_error
        else if (!InstantFormValidator.checkLongDescription(this.state.longDescription))
            return i18n.management_instant.long_description_format_error
        else
            return i18n.management_instant.form_data_error
    }
}
