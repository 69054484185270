import React from "react";

interface Props {
    icon: string;
    title: string | undefined;
}

export const CompanyContactItem = (props: Props): JSX.Element | null => {
    if (props.title)
        return (
            <div className="single-info mt-20">
                <div className="info-icon">
                    <i className={props.icon}/>
                </div>
                <div className="info-content">
                    <p>{props.title}</p>
                </div>
            </div>
        )
    else
        return null
}
