import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { ClickCollectService } from "../../domain/gateway/clickCollectService";
import { cancelOrderFailed, cancelOrderSucceeded } from "./actions";
import { CANCEL_ORDER, CancelOrderAction } from "./actionTypes";

export const cancelOrderEpic: Epic = (action$: ActionsObservable<CancelOrderAction>, store: StateObservable<AppState>,
                                      {clickCollectService}: { clickCollectService: ClickCollectService }) =>
    action$.pipe(
        ofType(CANCEL_ORDER),
        switchMap(action => clickCollectService.cancelOrder(action.payload.orderId, action.payload.message)
            .pipe(
                map(() => cancelOrderSucceeded()),
                catchError(err => of(cancelOrderFailed(err)))
            )
        )
    )
