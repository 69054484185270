import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../../redux-configuration/AppState";
import { MessagingService } from "../../../domain/gateway/messaging.service";
import { SEND_MESSAGE, SendMessageAction } from "./actionTypes";
import { sendMessageFailed, sendMessageSucceeded } from "./actions";

export const sendChatMessageEpic: Epic = (action$: ActionsObservable<SendMessageAction>, store$: StateObservable<AppState>,
                                          {messagingService}: { messagingService: MessagingService }) =>
    action$.pipe(
        ofType(SEND_MESSAGE),
        switchMap(action => messagingService.sendChatMessage(action.payload.conversationId, action.payload.businessId, action.payload.message, action.payload.image)
            .pipe(
                map(() => sendMessageSucceeded()),
                catchError(error => of(sendMessageFailed(error)))
            ))
    )
