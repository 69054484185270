export const DELETE_MUNICIPALITY_SERVICE = 'DELETE_MUNICIPALITY_SERVICE'
export const DELETE_MUNICIPALITY_SERVICE_FAILED = 'DELETE_MUNICIPALITY_SERVICE_FAILED'
export const DELETE_MUNICIPALITY_SERVICE_SUCCEEDED = 'DELETE_MUNICIPALITY_SERVICE_SUCCEEDED'

export interface DeleteMunicipalityServiceAction {
    type: typeof DELETE_MUNICIPALITY_SERVICE;
    payload: string;
}

export interface DeleteMunicipalityServiceFailedAction {
    type: typeof DELETE_MUNICIPALITY_SERVICE_FAILED;
    payload: string
}

export interface DeleteMunicipalityServiceSucceededAction {
    type: typeof DELETE_MUNICIPALITY_SERVICE_SUCCEEDED;
}

export type DeleteMunicipalityServiceActionTypes =
    DeleteMunicipalityServiceAction
    | DeleteMunicipalityServiceFailedAction
    | DeleteMunicipalityServiceSucceededAction
