import {
    LOAD_PRODUCTS, LOAD_PRODUCTS_FAILED, LOAD_PRODUCTS_SUCCEEDED,
    LoadProductsAction, LoadProductsFailedAction, LoadProductsSucceededAction
} from "./actionTypes";
import { ProductDetails } from "../../domain/entities/productDetails";

export const loadProducts = (companyId: string): LoadProductsAction => ({
    type   : LOAD_PRODUCTS,
    payload: companyId
})

export const loadProductsFailed = (error: string): LoadProductsFailedAction => ({
    type   : LOAD_PRODUCTS_FAILED,
    payload: error
})

export const loadProductsSucceeded = (products: ProductDetails[]): LoadProductsSucceededAction => ({
    type   : LOAD_PRODUCTS_SUCCEEDED,
    payload: products
})
