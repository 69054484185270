import React from "react";
import { i18n } from "../../../../../configuration/i18n";
import { ThemeWelcomeBlockType } from "../types";
import { ValueOf } from "../../../../../common/domain/types/app.categories";
import { ThemeCheckboxInput } from "./checkbox.input";

interface Props {
    data: ThemeWelcomeBlockType;
    error: boolean;
    raiseUpdates: (key: keyof ThemeWelcomeBlockType, value: ValueOf<ThemeWelcomeBlockType>) => void;
}

export const ThemeLinkInput = (props: Props): JSX.Element => {
    const error = props.error ? 'error' : ''

    return (
        <div className={'wizard-form-input align-items-start'}>
            <div className={'d-flex flex-column link-label'}>
                <label>{i18n.company.button_url}</label>

                <ThemeCheckboxInput label={'Ouvrir dans une nouvelle fenetre'}
                                    checked={props.data.openInNewWindow}
                                    className={'sm-checkbox'}
                                    onChange={() => props.raiseUpdates('openInNewWindow', !props.data.openInNewWindow)}/>
            </div>

            <input type={'text'}
                   value={props.data.buttonUrl ?? ''}
                   className={error}
                   onChange={(event) => props.raiseUpdates('buttonUrl', event.target.value)}/>
        </div>
    )
}
