import { connect } from 'react-redux';
import { SignUpForm } from "./signUp.form";
import { AppState } from "../../../../../../redux-configuration/AppState";
import {
    signUpErrorSelector,
    signUpLoadingSelector,
} from "../../../../../usecases/signUp/selectors";
import { CityHeader } from "../../../../../domain/entity/cityHeader";
import { searchedCitiesSelector } from "../../../../../usecases/searchCity/selector";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined;
    cities: CityHeader [] | null
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: signUpLoadingSelector(state),
    error  : signUpErrorSelector(state),
    cities : searchedCitiesSelector(state)
})


export const SignUpContainer = connect(mapStateToProps)(SignUpForm)
