import { ThemeDetails } from "../../../domain/entity/ThemeDetails";
import { ThemeBlock } from "../../../domain/entity/ThemeBlock";
import { ThemeConfiguration } from "../../../domain/entity/ThemeConfiguration";
import { ThemeConfigurationFormState } from "./themeConfigurationFrom.container";
import { ThemeConfigurationBuilder } from "../../../domain/builder/themeConfiguration.builder";
import { Company } from "../../../domain/entity/company";
import { AppCategories, AssociationCategories } from "../../../../common/domain/types/app.categories";
import { ThemeSocialMedia } from "../../../domain/entity/ThemeSocialMedia";
import { ThemeWelcomeBlock } from "../../../domain/entity/ThemeWelcomeBlock";
import { ThemeFoodMenu } from "../../../domain/entity/ThemeFoodMenu";
import { ThemeEcommerce } from "../../../domain/entity/ThemeEcommerce";

export class ThemeConfigurationFormMapper {

    static mapToThemeConfiguration(theme: ThemeConfigurationFormState): ThemeConfiguration {
        const settings = new ThemeDetails(
            theme.general.title,
            theme.general.website,
            theme.general.primaryColor,
            theme.general.secondaryColor,
            theme.general.primaryFont,
            theme.general.secondaryFont,
            theme.review.placeId
        )
        const header = new ThemeBlock(
            true,
            theme.header.title ?? '',
            theme.header.textColor ?? '',
            theme.header.backgroundColor ?? '',
            theme.header.images ?? []
        )

        const footer = new ThemeBlock(
            true,
            theme.footer.title ?? '',
            theme.footer.textColor ?? '',
            theme.footer.backgroundColor ?? '',
            theme.footer.images ?? []
        )

        const welcome = new ThemeWelcomeBlock(
            theme.welcome.subTitle ?? '',
            theme.welcome.buttonLabel ?? '',
            theme.welcome.buttonUrl ?? '',
            theme.welcome.videoUrl ?? '',
            theme.welcome.openInNewWindow ?? false,
            theme.welcome.backgroundColor ?? ''
        )

        const about = new ThemeBlock(
            true,
            '',
            theme.about.textColor ?? '',
            theme.about.backgroundColor ?? '',
            [],
            theme.about.titleColor ?? ''
        )

        const gallery = new ThemeBlock(
            theme.gallery.isActive,
            theme.gallery.subTitle ?? '',
            theme.gallery.textColor ?? '',
            theme.gallery.backgroundColor ?? '',
            theme.gallery.images ?? []
        )

        const instants = new ThemeBlock(
            theme.instants.isActive,
            theme.instants.subTitle ?? '',
            theme.instants.textColor ?? '',
            theme.instants.backgroundColor ?? '',
            [],
            '',
            theme.instants.itemColor ?? ''
        )

        const address = new ThemeBlock(
            theme.address.isActive,
            theme.address.subTitle ?? '',
            theme.address.textColor ?? '',
            theme.address.backgroundColor ?? '',
            theme.address.images ?? [],
            '',
            theme.address.itemColor ?? ''
        )

        const review = new ThemeBlock(
            theme.review.isActive,
            theme.review.subTitle ?? '',
            theme.review.textColor ?? '',
            theme.review.backgroundColor ?? ''
        )

        const contact = new ThemeBlock(
            theme.contact.isActive,
            theme.contact.subTitle ?? '',
            theme.contact.textColor ?? '',
            theme.contact.backgroundColor ?? ''
        )

        const socialMedia = new ThemeSocialMedia(
            this.addHttpsIfMissing(theme.socialMedia.facebook),
            this.addHttpsIfMissing(theme.socialMedia.instagram),
            this.addHttpsIfMissing(theme.socialMedia.tiktok),
            this.addHttpsIfMissing(theme.socialMedia.linkedin)
        )

        const foodMenu = new ThemeFoodMenu(
            theme.foodMenu.isActive,
            theme.foodMenu.headerTitle ?? '',
            theme.foodMenu.title ?? '',
            theme.foodMenu.subTitle ?? '',
            theme.foodMenu.backgroundColor ?? '',
            theme.foodMenu.displayAsIcon,
            theme.foodMenu.tabs ?? []
        )

        const ecommerce = new ThemeEcommerce(
            theme.ecommerce.isActive,
            theme.ecommerce.headerTitle ?? '',
            theme.ecommerce.title ?? '',
            theme.ecommerce.subTitle ?? '',
            theme.ecommerce.backgroundImage ?? '',
            theme.ecommerce.type ?? 'default',
            theme.ecommerce.paymentOptions ?? [],
            theme.ecommerce.documentUrl ?? '',
            theme.ecommerce.navigateTo ?? 'shop'
        )

        const themeConfigurationBuilder = new ThemeConfigurationBuilder()
            .withGeneral(settings)
            .withHeader(header)
            .withFooter(footer)
            .withWelcome(welcome)
            .withAbout(about)
            .withGallery(gallery)
            .withInstants(instants)
            .withAddress(address)
            .withReview(review)
            .withContact(contact)
            .withSocialMedia(socialMedia)
            .withFoodMenu(foodMenu)
            .withEcommerce(ecommerce)

        if (theme.clickCollect) {
            const clickCollect = new ThemeBlock(
                theme.clickCollect.isActive,
                theme.clickCollect.subTitle ?? '',
                theme.clickCollect.textColor ?? '',
                theme.clickCollect.backgroundColor ?? ''
            )
            themeConfigurationBuilder.withClickCollect(clickCollect)
        }

        return themeConfigurationBuilder.build()
    }

    static mapToDefaultThemeConfigurationState(company: Company): ThemeConfigurationFormState {
        const category = company.type === 'association' ? AssociationCategories[company.category] : AppCategories[company.category]
        const clickCollect = company.clickCollect.active ? {
            isActive       : true,
            backgroundColor: ThemeConfiguration.TEXT_COLOR,
        } : undefined


        return {
            general    : {
                title         : company.name + " - " + category + " à " + company.address.city,
                website       : company.themeConfiguration?.general.website ?? "",
                primaryColor  : ThemeConfiguration.PRIMARY_COLOR,
                secondaryColor: ThemeConfiguration.SECONDARY_COLOR,
                primaryFont   : ThemeConfiguration.PRIMARY_FONT,
                secondaryFont : ThemeConfiguration.SECONDARY_FONT
            },
            header     : {
                isActive       : true,
                textColor      : ThemeConfiguration.TEXT_COLOR,
                backgroundColor: ThemeConfiguration.PRIMARY_COLOR,
                images         : [company.profileImageUrl]
            },
            footer     : {
                isActive       : true,
                textColor      : ThemeConfiguration.TEXT_COLOR,
                backgroundColor: ThemeConfiguration.PRIMARY_COLOR,
                images         : [company.profileImageUrl]
            },
            welcome    : {
                subTitle       : category + " à " + company.address.city,
                videoUrl       : '',
                buttonUrl      : '#about',
                buttonLabel    : 'À propos',
                openInNewWindow: false,
                backgroundColor: ThemeWelcomeBlock.BACKGROUND_COLOR
            },
            about      : {
                isActive       : true,
                textColor      : ThemeConfiguration.TEXT_COLOR,
                titleColor     : ThemeConfiguration.SECONDARY_COLOR,
                backgroundColor: ThemeConfiguration.PRIMARY_COLOR
            },
            gallery    : {
                isActive       : false,
                subTitle       : "Les photos de notre " + category,
                backgroundColor: ThemeConfiguration.BACKGROUND_COLOR,
                images         : []
            },
            instants   : {
                isActive       : true,
                subTitle       : "Découvrez toutes nos offres",
                backgroundColor: ThemeConfiguration.TEXT_COLOR,
                itemColor      : ThemeConfiguration.PRIMARY_COLOR
            },
            contact    : {
                isActive       : true,
                subTitle       : "Besoin d’un conseil, envoyez-nous un message",
                backgroundColor: ThemeConfiguration.TEXT_COLOR,
            },
            address    : {
                isActive       : true,
                subTitle       : "Rendez-nous visite dans notre " + category,
                backgroundColor: ThemeConfiguration.BACKGROUND_COLOR,
                itemColor      : ThemeConfiguration.PRIMARY_COLOR
            },
            review     : {
                isActive       : false,
                subTitle       : "Les avis de nos clients",
                backgroundColor: ThemeConfiguration.TEXT_COLOR,
                placeId        : ''
            },
            clickCollect,
            foodMenu   : {
                isActive       : false,
                headerTitle    : '',
                title          : '',
                subTitle       : '',
                displayAsIcon  : false,
                backgroundColor: '',
                tabs           : []
            },
            socialMedia: {
                facebook : '',
                instagram: '',
                tiktok   : '',
                linkedin : ''
            },
            ecommerce  : {
                isActive       : false,
                headerTitle    : '',
                title          : '',
                subTitle       : '',
                backgroundImage: '',
                type           : 'default',
                paymentOptions : [],
                documentUrl    : "",
                navigateTo     : "shop",
            },
            isSubmitted: false,
            success    : false,
            errorMsg   : undefined
        }
    }

    static addHttpsIfMissing(url: string): string {
        if (!ThemeConfigurationFormMapper.isValidUrl(url)) {
            return url
        }
        url = url.replace(/^http:\/\//i, 'https://')

        const httpsRegex = /^https:\/\//i;
        if (!httpsRegex.test(url)) {
            return "https://" + url;
        }

        return url;
    }

    static isValidUrl(url: string): boolean {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    }
}
