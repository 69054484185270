import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { LOAD_OWNED_COMPANY, LoadOwnedCompanyAction } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { CompanyService } from "../../domain/gateway/company.service";
import { loadCompanyDetails, loadCompanyDetailsFailed } from "./actions";

export const loadOwnedCompanyEpic: Epic = (action$: ActionsObservable<LoadOwnedCompanyAction>,
                                         store: StateObservable<AppState>,
                                         { companyService }: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(LOAD_OWNED_COMPANY),
        switchMap(() => companyService.getCompanyId()
            .pipe(
                concatMap((companyId: string) => [
                    loadCompanyDetails(companyId)
                ]),
                catchError(error => of(loadCompanyDetailsFailed(error)))
            )
        )
    )
