export abstract class AdministrativeNumber {

    constructor(protected _number: string) {
    }

    abstract validateNumber(): boolean

    abstract type(): string

    abstract length(): number

    get number(): string {
        return this._number
    }
}