import React from 'react'
import { CategoryType } from "../../../instant/domain/entites/types/categoryType";
import cultural from '../../../assets/icons/cultural.png'
import sport from '../../../assets/icons/sport.png'
import discovery from '../../../assets/icons/discovery.png'
import musical from '../../../assets/icons/musical.png'
import gourmand from '../../../assets/icons/gourmand.png'
import heart from '../../../assets/icons/heart.png'
import info from '../../../assets/icons/info.png'
import offer from '../../../assets/icons/offer.png'
import gift from '../../../assets/icons/gift.png'
import festif from '../../../assets/icons/festif.png'
import vivez_clichy_marker from '../../../assets/img/vivez_clichy_marker.jpeg'

export const ApplicationIcons = (iconType: CategoryType, width: number, height: number): JSX.Element =>{
    switch (iconType){
        case 'cultural':
            return <img src={cultural} width={width} height={height}/>
        case 'sportive':
           return <img src={sport} width={width} height={height}/>
        case 'discovery':
            return <img src={discovery} width={width} height={height}/>
        case 'musical':
            return <img src={musical} width={width} height={height} />
        case 'gourmand':
           return <img src={gourmand} width={width} height={height}/>
        case 'festive':
            return  <img src={festif} width={width} height={height}/>
        case 'heart':
            return  <img src={heart} width={width} height={height}/>
        case 'info':
            return  <img src={info} width={width} height={height}/>
        case 'offer':
            return  <img src={offer} width={width} height={height}/>
        case 'gift':
            return  <img src={gift} width={width} height={height}/>
        default:
            return<img src={vivez_clichy_marker} width={width} height={height}/>
    }
}