export class ThemeSocialMedia {
    constructor(
        protected _facebook: string,
        protected _instagram: string,
        protected _tiktok: string,
        protected _linkedin: string
    ) {}

    get facebook(): string {
        return this._facebook;
    }

    get instagram(): string {
        return this._instagram;
    }

    get tiktok(): string {
        return this._tiktok;
    }

    get linkedin(): string {
        return this._linkedin;
    }
}
