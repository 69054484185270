import { Follower } from "../../domain/entity/follower";

export const LOAD_FOLLOWERS = 'LOAD_FOLLOWERS'
export const LOAD_FOLLOWERS_FAILED = 'LOAD_FOLLOWERS_FAILED'
export const LOAD_FOLLOWERS_SUCCEEDED = 'LOAD_FOLLOWERS_SUCCEEDED'

export interface LoadFollowersAction {
    type: typeof LOAD_FOLLOWERS;
    payload: string;
}

export interface LoadFollowersFailedAction {
    type: typeof LOAD_FOLLOWERS_FAILED;
    payload: string;
}

export interface LoadFollowersSucceededAction {
    type: typeof LOAD_FOLLOWERS_SUCCEEDED;
    payload: Follower[];
}

export type LoadFollowersActionTypes = LoadFollowersAction | LoadFollowersFailedAction | LoadFollowersSucceededAction
