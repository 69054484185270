import {
    UPLOAD_ADVANCED_IMAGE,
    UPLOAD_ADVANCED_IMAGE_FAILED,
    UPLOAD_ADVANCED_IMAGE_SUCCEEDED, UploadAdvancedImageAction,
    UploadAdvancedImageFailedAction, UploadAdvancedImageSucceededAction
} from "./actionTypes";

export const uploadAdvancedImage = (image: File, companyId: string): UploadAdvancedImageAction => ({
    type   : UPLOAD_ADVANCED_IMAGE,
    payload: {image, companyId}
})

export const uploadAdvancedImageSucceeded = (imageURl: string): UploadAdvancedImageSucceededAction => ({
    type   : UPLOAD_ADVANCED_IMAGE_SUCCEEDED,
    payload: imageURl
})

export const uploadAdvancedImageFailed = (error: string): UploadAdvancedImageFailedAction => ({
    type   : UPLOAD_ADVANCED_IMAGE_FAILED,
    payload: error
})
