import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { HttpClient } from "../../../domain/gateway/httpClients";

export class ObservableAjaxHttpClient implements HttpClient {

    get<R>(url: string, headers?: {[key: string]: unknown}): Observable<R> {
        return ajax.getJSON<R>(url, headers)
    }

    post(url: string, body?: {[key: string]: unknown} | string, headers?: {[key: string]: unknown}): Observable<AjaxResponse> {
        return ajax.post(url, body, headers)
    }

    delete(url: string, headers?: object): Observable<AjaxResponse> {
        return ajax.delete(url, headers)
    }
}
