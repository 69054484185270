import { MunicipalServiceDto, ServiceOrderDTO, ServiceIconDto } from "../dto/municipalService.dto";
import { MunicipalService } from "../../../../domain/entities/municipalService";
import { MunicipalServiceBuilder } from "../../../../domain/builder/municipalService.builder";
import { ServiceIcon } from "../../../../domain/entities/serviceIcon";

export class MunicipalityMapper {
    static mapMunicipalServiceDtoToMunicipalService(servicesDto: MunicipalServiceDto[]): MunicipalService[] {
        return servicesDto.map(service => {
            const icon = new ServiceIcon(service.serviceIcon.id, service.serviceIcon.tags, service.serviceIcon.url)
            return new MunicipalServiceBuilder()
                .withId(service.id)
                .withType(service.type)
                .withLabel(service.label)
                .withContent(service.content)
                .withIcon(icon)
                .withRank(service.rank)
                .build()
        })
    }

    static mapServicesOrdersDTO(servicesIds: string[]): ServiceOrderDTO[] {
        return servicesIds.map((id, index) => {
            return {id, rank: index + 1}
        })
    }

    static mapServiceIconDtoToServiceIcon(icons: ServiceIconDto[]): ServiceIcon[] {
        return icons.map(icon => new ServiceIcon(icon.id, icon.tags, icon.url))
    }
}
