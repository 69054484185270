import { LoadLocalCodeState } from "../../configuration/state";
import {
    LOAD_LOCAL_CODE,
    LOAD_LOCAL_CODE_FAILED,
    LOAD_LOCAL_CODE_SUCCEEDED,
    LoadLocalCodeActionTypes
} from "./actionTypes";


const initialState: LoadLocalCodeState = {
    loading: false,
    code   : undefined,
    error  : undefined
}

export const loadLocalCodeReducer = (state = initialState, action: LoadLocalCodeActionTypes): LoadLocalCodeState => {
    switch (action.type) {
        case LOAD_LOCAL_CODE:
            return {
                loading: true,
                code   : undefined,
                error  : undefined
            }
        case LOAD_LOCAL_CODE_SUCCEEDED:
            return {
                loading: false,
                code   : action.payload,
                error  : undefined
            }
        case LOAD_LOCAL_CODE_FAILED:
            return {
                loading: false,
                code   : undefined,
                error  : action.payload
            }
        default:
            return state
    }
}
