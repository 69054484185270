import React from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeEcommerceType } from "../../types";
import { ThemeCheckboxInput } from "../checkbox.input";
import { ThemeImageInput } from "../imageInput";
import { EcommercePaymentOptions } from "./payment.options";
import { EcommerceTypeOptions } from "./type.options";
import { useDispatch, useSelector } from "react-redux";
import {
    uploadAdvancedImageLoadingSelector,
    uploadedAdvancedImageSelector,
} from "../../../../../usecases/uploadImage/selector";
import { AppState } from "../../../../../../redux-configuration/AppState";
import { uploadAdvancedImage } from "../../../../../usecases/uploadImage/actions";
import { EcommerceShopOptions } from "./shop.options";
import { EcommerceDocumentInput } from "./document.input";

interface Props {
    data: ThemeEcommerceType;
    isSubmitted: boolean;
    companyId: string;
    onChange: (key: keyof ThemeEcommerceType, value: string | boolean | string[]) => void;
}

export const EcommerceBlockConfiguration = (props: Props): JSX.Element => {

    const loading = useSelector((state: AppState) => uploadAdvancedImageLoadingSelector(state))
    const documentUrl = useSelector((state: AppState) => uploadedAdvancedImageSelector(state))

    const dispatch = useDispatch()

    return (
        <div className="ecommerce-section">
            <ThemeCheckboxInput label={"Activer le service E-commerce"}
                                checked={props.data.isActive}
                                onChange={(checked: boolean) =>
                                    props.onChange("isActive", checked)
                                }/>

            {props.data.isActive && <div className={"mt-3"}>
                <TextInput type={"text"}
                           error={props.isSubmitted && (!props.data.headerTitle || props.data.headerTitle.length === 0)}
                           label={i18n.company.headerTitle}
                           value={props.data.headerTitle ?? ""}
                           raiseUpdates={(value: string) => props.onChange("headerTitle", value)}/>

                <TextInput type={"text"}
                           error={props.isSubmitted && (!props.data.title || props.data.title.length === 0)}
                           label={i18n.company.section_title}
                           value={props.data.title ?? ""}
                           raiseUpdates={(value: string) => props.onChange("title", value)}/>

                <TextInput type={"text"}
                           error={props.isSubmitted && (!props.data.subTitle || props.data.subTitle.length === 0)}
                           label={i18n.company.sub_title}
                           value={props.data.subTitle ?? ""}
                           raiseUpdates={(value: string) => props.onChange("subTitle", value)}/>

                <ThemeImageInput previousUrl={props.data.backgroundImage ?? ""}
                                 companyId={props.companyId}
                                 onChange={(value: string) => props.onChange("backgroundImage", value)}/>

                <EcommerceTypeOptions value={props.data.type}
                                      onChange={(value: string) => props.onChange("type", value)}/>

                <EcommercePaymentOptions values={props.data.paymentOptions}
                                         onChange={(values: string[]) => props.onChange("paymentOptions", values)}/>

                <EcommerceShopOptions value={props.data.navigateTo}
                                      onChange={(value: string) => props.onChange("navigateTo", value)}/>

                <EcommerceDocumentInput loading={loading}
                                        documentUrl={props.data.documentUrl}
                                        uploadedDocumentUrl={documentUrl}
                                        onChange={(url: string) => props.onChange("documentUrl", url)}
                                        uploadDocument={(doc: File) => dispatch(uploadAdvancedImage(doc, props.companyId))}/>
            </div>}
        </div>
    )
}
