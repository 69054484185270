import { ManageMessagingState } from "../../../configuration/state";
import {
    LOAD_BROADCAST_CONVERSATION, LOAD_BROADCAST_CONVERSATION_FAILED, LOAD_BROADCAST_CONVERSATION_SUCCEEDED,
    LoadBroadcastConversationActionTypes
} from "./actionTypes";

const initialState: ManageMessagingState = {
    success: null,
    loading: false,
    error  : undefined
}

export const loadBroadcastConversationReducer = (state = initialState, action: LoadBroadcastConversationActionTypes): ManageMessagingState => {
    switch (action.type) {
        case LOAD_BROADCAST_CONVERSATION:
            return {
                success: null,
                loading: true,
                error  : undefined
            }
        case LOAD_BROADCAST_CONVERSATION_SUCCEEDED:
            return {
                success: true,
                loading: false,
                error  : undefined
            }
        case LOAD_BROADCAST_CONVERSATION_FAILED:
            return {
                success: false,
                loading: false,
                error  : action.payload
            }
        default:
            return state
    }
}
