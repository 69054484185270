import { MunicipalService } from "../../domain/entities/municipalService";

export const EDIT_MUNICIPAL_SERVICE = 'EDIT_MUNICIPAL_SERVICE'
export const EDIT_MUNICIPAL_SERVICE_FAILED = 'EDIT_MUNICIPAL_SERVICE_FAILED'
export const EDIT_MUNICIPAL_SERVICE_SUCCEEDED = 'EDIT_MUNICIPAL_SERVICE_SUCCEEDED'

export interface EditMunicipalServiceAction {
    type: typeof EDIT_MUNICIPAL_SERVICE
    payload: MunicipalService
}

export interface EditMunicipalServiceFailedAction {
    type: typeof EDIT_MUNICIPAL_SERVICE_FAILED
    payload: string
}

export interface EditMunicipalServiceSucceededAction {
    type: typeof EDIT_MUNICIPAL_SERVICE_SUCCEEDED
}

export type EditMunicipalServiceActionTypes = EditMunicipalServiceAction | EditMunicipalServiceFailedAction | EditMunicipalServiceSucceededAction
