import React, { useEffect, useState } from "react";
import { i18n } from "../../../../../../configuration/i18n";

interface Props {
    type: 'image' | 'video';
    previousUrl: string;
    companyId: string;
    loading: boolean;
    error: string | undefined;
    newFileUrl: string | undefined;
    uploadImage: (image: File, companyId: string) => void;
    onChange: (value: string) => void;
}

export const ThemeLogoInputContainer = (props: Props): JSX.Element => {

    const [hasNewImage, setHasNewImage] = useState<boolean>(false)

    useEffect(() => {
        if (props.newFileUrl && hasNewImage) {
            props.onChange(props.newFileUrl)
            setHasNewImage(false)
        }
    }, [props.newFileUrl])

    const loading = props.loading && hasNewImage ? ' loading' : ''

    return (
        <div className={'logo-input wizard-form-input align-items-start mb-0'}>
            <div>
                <label className={'mt-3 text-left'}>{props.type === 'image' ? i18n.company.website_logo : i18n.company.website_video}</label>
                {props.error && <p className={'mt-3 text-left error'}>{i18n.company.video_error}</p>}
            </div>

            <div className={'img-container'}>
                {props.type === 'image' ? <img src={props.previousUrl} alt={"gallery"}/> : (
                    <video muted controls loop autoPlay className="w-100 h-100">
                        <source src={props.previousUrl} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                )}

                <div className={'img-overlay d-flex align-items-center justify-content-center' + loading}>
                    {props.loading && hasNewImage ? <div className={'image-loader loader'}/> : (
                        <div className={'add-img-btn'}>
                            <span>Changer</span>

                            <input type="file" onChange={e => {
                                if (e.target.files?.[0]) {
                                    setHasNewImage(true)
                                    props.uploadImage(e.target.files[0], props.companyId)
                                }
                            }}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
