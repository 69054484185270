import React, { PureComponent, ReactNode } from 'react';
import { reduxStore } from "./redux-configuration/store.redux";
import { Provider } from "react-redux";
import { loadStoredToken } from "./authentication/usecases/loadStoredToken/action";
import { Unsubscribe } from "redux";
import { storedTokenSelector } from "./authentication/usecases/loadStoredToken/selector";
import { RootNavigation } from "./navigation/adapters/primaries/rootNavigation";
import { loadOwnedCompany } from "./company/usecases/loadCompanyDetails/actions";
import {
    companyDetailsSelector,
    loadCompanyDetailsErrorSelector
} from "./company/usecases/loadCompanyDetails/selector";

export const store = reduxStore()

interface State {
    isAuthenticated: null | boolean;
}

export class App extends PureComponent<{}, State> {

    private unsubscribe: Unsubscribe;

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            isAuthenticated: null
        }
    }

    componentDidCatch(error: Error): void {
        this.notifySlack(error);
    }

    componentDidMount(): void {
        this.readinessListener().finally(() => {
            this.setState({isAuthenticated: this.hasSession()})
            this.unsubscribe()
        })
        store.dispatch(loadStoredToken())
    }

    render(): ReactNode {
        if (this.state.isAuthenticated !== null)
            return (
                <Provider store={store}>
                    <RootNavigation hasSession={this.state.isAuthenticated}/>
                </Provider>
            );
        else
            return (
                <div className={'show-loader'}>
                    <div className={'loader'}/>
                </div>
            )
    }

    private readinessListener(): Promise<void> {
        return new Promise<void>((resolve: () => void) => {

            let eventCounter = 0
            this.unsubscribe = store.subscribe(() => {
                if (eventCounter === 0 && this.hasFinishAuthenticating()) {
                    ++eventCounter
                    if (this.hasSession()) {
                        this.initiateActions()
                    } else {
                        this.initiateRegistrationActions()
                    }
                    resolve()
                }
            })
        })
    }

    hasFinishAuthenticating(): boolean {
        return storedTokenSelector(store.getState()) !== null
    }

    hasSession(): boolean {
        return storedTokenSelector(store.getState()) !== 'TOKEN_NOT_FOUND'
    }

    initiateActions(): void {
       store.dispatch(loadOwnedCompany())
    }

    initiateRegistrationActions(): void {
        /*
        store.dispatch(loadLocalUser())
        store.dispatch(loadLocalCode())
        store.dispatch(loadLocalBusiness())
         */
    }

    private hasFinishLoadingCompany(): boolean {
        return companyDetailsSelector(store.getState()) !== null || loadCompanyDetailsErrorSelector(store.getState()) !== undefined
    }

    private hasCompany(): boolean {
        const company = store.getState().company.company
        return company !== null
    }

    private notifySlack(error: Error) {
        const url = process.env.REACT_APP_API_EXCEPTION_URL ?? ''
        fetch(url, {
            method: 'post',
            body  : JSON.stringify({
                "channel"    : process.env.REACT_APP_API_EXCEPTION_CHANNEL,
                "attachments": [
                    {
                        "color"      : "#c92e58",
                        "author_name": "Exception Catcher",
                        "title"      : error.name,
                        "text"       : error.message,
                        "fields"     : [{"title": "Stack", "value": error.stack, "short": false}],
                        "footer"     : "Front Web",
                        "footer_icon": "https://platform.slack-edge.com/img/default_application_icon.png",
                        "ts"         : (new Date()).getTime()
                    }
                ]
            })
        })
    }
}

export default App;
