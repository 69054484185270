import {
    LOAD_LOCAL_BUSINESS,
    LOAD_LOCAL_BUSINESS_FAILED,
    LOAD_LOCAL_BUSINESS_SUCCEEDED,
    LoadLocalBusinessActionTypes
} from "./actionTypes";
import { Company } from "../../../company/domain/entity/company";

export const loadLocalBusiness = (): LoadLocalBusinessActionTypes => ({
    type: LOAD_LOCAL_BUSINESS
})

export const loadLocalBusinessSucceeded = (business: Company): LoadLocalBusinessActionTypes => ({
    type   : LOAD_LOCAL_BUSINESS_SUCCEEDED,
    payload: business
})

export const loadLocalBusinessFailed = (error: string): LoadLocalBusinessActionTypes => ({
    type   : LOAD_LOCAL_BUSINESS_FAILED,
    payload: error
})
