import { Company } from "../../../domain/entity/company";
import { AppState } from "../../../../redux-configuration/AppState";
import { companySelector } from "../../../usecases/updateStoreCompany/selector";
import { connect } from "react-redux";
import { QrcodeContainer } from "./qrcode.container";

interface StateToPropsType {
    company: Company | null;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companySelector(state)
})

export const QrCodeProfile = connect(mapStateToProps)(QrcodeContainer)
