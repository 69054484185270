import { ProductDetails } from "../../domain/entities/productDetails";

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED'
export const ADD_PRODUCT_SUCCEEDED = 'ADD_PRODUCT_SUCCEEDED'

export interface AddProductAction {
    type: typeof ADD_PRODUCT;
    payload: {
        product: ProductDetails;
        companyId: string;
    };
}

export interface AddProductFailedAction {
    type: typeof ADD_PRODUCT_FAILED;
    payload: string;
}

export interface AddProductSucceededAction {
    type: typeof ADD_PRODUCT_SUCCEEDED;
}

export type AddProductActionTypes = AddProductAction | AddProductFailedAction | AddProductSucceededAction
