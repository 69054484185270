export const PaymentI18n = {
    monthly_subscription    : 'Facturation mensuelle',
    yearly_subscription     : 'Facturation annuelle (2 mois offerts) ',
    month                   : 'mois',
    year                    : 'an',
    subtitle_year           : '⭐️ 2 mois offerts ⭐',
    subtitle_month          : '(Facturation mensuelle)',
    buy                     : 'Choisir',
    subscription_title      : 'Votre abonnement',
    free_primium            : (type: string): string => type === 'association' ? '(Gratuit pour les associations)' : '',
    subscription_description: 'Pour changer votre offre, merci de choisir parmi la liste ci dessous ',
    payment_sucess          : 'Paiement confirmé',
    payment_fail            : 'Paiement annulé',
    has_no_payment          : 'Vous n\'avez pas de paiment',
    oops_payment_failed     : 'Oops! Paiement échoué',
    try_again               : 'Réessayer',
    sth_went_wrong          : 'Quelque chose s\'est mal passé, vous pouvez réessayer votre paiement',
    yes_payment_succeeded   : 'Ouii! Paiement réussi',
    go_to_profile           : 'Vers votre profile',
    congrats                : 'Félicitations, Maintenant vous avez un abonnement ',
    server_error            : 'Une erreur serveur est survenu',
    unpaid                  : 'Vous n\'avez pas payé votre abonnement!',
    popular                 : 'Populaire',
    link_payment            : '<p>RDV sur votre espace de facturation pour :</p>' +
        '<ul><li>Gérer vos moyens de paiement</li>' +
        '<li>Modifier vos informations de facturation</li>' +
        '<li>Et voir votre historique de facturation</li></ul>',
    invoicing_space         : 'Espace de facturation',
    no_engagement           : '(Pas d\'engagements)',
    engagement_year         : '(Engagement de 12 mois)'
}
