import { combineReducers } from 'redux';
import { EcommerceOrderState, EcommerceState } from "./state";
import { loadProductsReducer } from "../usecases/loadProducts/reducer";
import { addProductReducer } from "../usecases/addProduct/reducer";
import { editProductReducer } from "../usecases/editProduct/reducer";
import { deleteProductReducer } from "../usecases/deleteProduct/reducer";
import { editProductStatusReducer } from "../usecases/editProductStatus/reducer";
import { loadEcommerceOrdersReducer } from "../usecases/loadOrders/reducer";

export const ecommerceRootReducer = combineReducers<EcommerceState>({
    productList      : loadProductsReducer,
    addProduct       : addProductReducer,
    editProduct      : editProductReducer,
    deleteProduct    : deleteProductReducer,
    editProductStatus: editProductStatusReducer,
    order            : combineReducers<EcommerceOrderState>({
        list: loadEcommerceOrdersReducer
    })
})
