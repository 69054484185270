import { AppState } from "../../../../redux-configuration/AppState";
import { EcommerceOrdersContainer } from "./orders.container";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import { EcommerceOrder } from "../../../domain/entities/ecommerceOrder";
import { loadEcommerceOrders } from "../../../usecases/loadOrders/actions";
import { LoadEcommerceOrdersAction } from "../../../usecases/loadOrders/actionTypes";
import {
    ecommerceOrdersSelector, loadEcommerceOrdersErrorSelector, loadEcommerceOrdersLoadingSelector
} from "../../../usecases/loadOrders/selectors";

interface StateToPropsType {
    company: Company | null;
    orders: EcommerceOrder[] | undefined;
    loading: boolean;
    error: string | undefined;
}

interface DispatchToPropsType {
    loadOrders: (companyId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companySelector(state),
    orders : ecommerceOrdersSelector(state),
    loading: loadEcommerceOrdersLoadingSelector(state),
    error  : loadEcommerceOrdersErrorSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadOrders: (companyId: string): LoadEcommerceOrdersAction => dispatch(loadEcommerceOrders(companyId))
})

export const EcommerceOrdersPage = connect(mapStateToProps, mapDispatchToProps)(EcommerceOrdersContainer)
