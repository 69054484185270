import { ApplicationContext } from "../../../../configuration/application.context";
import { ManagementInstantFormType } from "./type/managementInstantFormType";
import { ActivationTimeType } from "../../../domain/entities/types/activationTimeType";


const moment = ApplicationContext.getInstance().momentJs()

export class InstantFormValidator {

    static validate(form: ManagementInstantFormType): number | undefined {
        if (!InstantFormValidator.checkCategory(form.category) ||
            !InstantFormValidator.checkTitle(form.title) ||
            !InstantFormValidator.checkPicture(form.picture) ||
            !InstantFormValidator.checkDescription(form.description) ||
            !InstantFormValidator.checkLongDescription(form.longDescription)
        )
            return 0
        if (!InstantFormValidator.checkAddress(form.address) ||
            !InstantFormValidator.checkCity(form.city) ||
            !InstantFormValidator.checkZipCode(form.zip))
            return 1
        if (!InstantFormValidator.checkSchedule(form.activationTime))
            return 2
        if (!InstantFormValidator.checkNotificationDate(form.notificationDate, form.notificationSent))
            return 3
        else
            return undefined
    }

    static checkCategory(type: string | 'default'): boolean {
        return type !== 'default'
    }

    static checkAddress(address: string): boolean {
        const regexp = new RegExp(/.{5,}/);
        return regexp.test(address)
    }

    static checkZipCode(zip: string): boolean {
        const regexp = new RegExp(/^(?:[0-8]\d|9[0-8])\d{3}$/);
        return regexp.test(zip)
    }

    static checkDescription(description: string): boolean {
        return description.length < 255 && description.length > 9
    }

    static checkPicture(picture: File | undefined): boolean {
        return picture !== undefined
    }

    static checkSchedule(schedules: ActivationTimeType | undefined): boolean {
        return (
            schedules !== undefined &&
            Array.isArray(schedules.schedule) &&
            schedules.schedule.length > 0 &&
            schedules.schedule[0] !== undefined &&
            this.isValidSchedules(schedules)
        )
    }

    static checkCity(city: string): boolean {
        return city.length > 3
    }

    static checkTitle(title: string): boolean {
        return title.length > 5
    }

    static checkLongDescription(longDescription: string): boolean {
        return longDescription.length < 3000 && longDescription.length > 16
    }

    static isValidSchedules(schedules: ActivationTimeType): boolean {
        if (schedules.key === 'fixed' && Array.isArray(schedules.schedule))
            return moment(schedules.schedule[0].dateRange.endTime).isAfter(moment(schedules.schedule[0].dateRange.startTime))
        else
            return true
    }

    static checkNotificationDate(notificationDate: string | undefined, isNotificationSent: boolean | undefined): boolean {
        return notificationDate && notificationDate !== 'CLEAR' && !isNotificationSent
            ? moment(notificationDate, 'YYYY-MM-DD HH:mm').isAfter(moment().add(4, 'minutes')) : true
    }
}
