import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { LOAD_FOLLOWERS, LoadFollowersAction } from "./actionTypes";
import { loadFollowersFailed, loadFollowersSucceeded } from "./actions";
import { Follower } from "../../domain/entity/follower";
import { ApplicationContext } from "../../../configuration/application.context";
import { CompanyService } from "../../domain/gateway/company.service";

export const loadFollowersEpic: Epic = (action$: ActionsObservable<LoadFollowersAction>,
                                        store: StateObservable<AppState>,
                                        { companyService }: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(LOAD_FOLLOWERS),
        switchMap(
            (action) => companyService.loadFollowersList(action.payload)
                .pipe(
                    map((data: Follower[]) => loadFollowersSucceeded(data.sort(sortByDate()))),
                    catchError(error => of(loadFollowersFailed(error)))
                )
        )
    )
const moment = ApplicationContext.getInstance().momentJs()
const sortByDate = () => (a: Follower, b: Follower): number => (a.followDate === '' ? 1:-1) - (b.followDate === '' ? 1:-1) ||
    moment(b.followDate,'DD-MM-YYYY') - moment(a.followDate, 'DD-MM-YYYY')

