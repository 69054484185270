import { CHANGE_SERVICES_ORDER, CHANGE_SERVICES_ORDER_FAILED, CHANGE_SERVICES_ORDER_SUCCEEDED, ChangeServicesOrderActionTypes } from "./actionTypes";
import { HandleMunicipalServiceState } from "../../configuration/state";

const initialState: HandleMunicipalServiceState = {
    loading: false,
    error  : undefined
}

export const changeServicesOrderReducer = (state = initialState, action: ChangeServicesOrderActionTypes): HandleMunicipalServiceState => {
    switch (action.type) {
        case CHANGE_SERVICES_ORDER:
            return {
                loading: true,
                error  : undefined
            }
        case CHANGE_SERVICES_ORDER_FAILED:
            return {
                loading: false,
                error  : action.payload
            }
        case CHANGE_SERVICES_ORDER_SUCCEEDED:
            return {
                loading: false,
                error  : undefined
            }
        default:
            return state;
    }
}
