import { ManagementInstantDelayType } from "./types/managementInstantDelayType";


export class ManagementInstantActivationTime {

    constructor(private _id: string,
                private _delayType: ManagementInstantDelayType,
                private _startDateTime: string,
                private _endDateTime: string,
                private _isEnabled: boolean,
                private _activationDayType: string) {
    }

    get id(): string {
        return this._id;
    }

    get DelayType(): ManagementInstantDelayType {
        return this._delayType;
    }

    get startDateTime(): string {
        return this._startDateTime;
    }

    get endDateTime(): string {
        return this._endDateTime;
    }

    get activationDayType(): string {
        return this._activationDayType;
    }

    get isEnabled(): boolean {
        return this._isEnabled;
    }
}
