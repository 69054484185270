import { Company } from "../../../../company/domain/entity/company";
import { AppState } from "../../../../redux-configuration/AppState";
import { companyDetailsSelector } from "../../../../company/usecases/loadCompanyDetails/selector";
import { connect } from "react-redux";
import { LoginContainer } from "./login.container";

interface StateToPropsType {
    company: Company | null;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companyDetailsSelector(state)
})


export const Login = connect(mapStateToProps)(LoginContainer)
