import { StatsService } from "../domain/gateway/StatsService";
import { InMemoryStatsService } from "../adapters/secondaries/inMemory/InMemoryStats.service";
import { ApiStatsService } from "../adapters/secondaries/real/ApiStats.service";

export class StatsDependenciesFactory {
    static getStatsService(): StatsService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiStatsService()
            default:
                return new InMemoryStatsService()
        }
    }
}
