import { AppState } from "../../../redux-configuration/AppState";
import { LOAD_INSTANTS_COMPANY, LoadInstantsCompanyAction } from "./loadInstantsCompany.types";
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ManagementInstantHeader } from "../../domain/entities/managementInstantHeader";
import { loadInstantsCompanyFailed, loadInstantsCompanySucceeded } from "./loadInstantsCompany.actions";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";

export const loadInstantsCompanyEpic: Epic = (action$: ActionsObservable<LoadInstantsCompanyAction>,
                                              store: StateObservable<AppState>,
                                              { instantManagementService }: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(LOAD_INSTANTS_COMPANY),
        switchMap(action => instantManagementService.retrieveInstantsCompany(action.payload)
            .pipe(
                map((instants: ManagementInstantHeader[]) => loadInstantsCompanySucceeded(instants)),
                catchError(error => of(loadInstantsCompanyFailed(error)))
            ))
    )