import {
    EDIT_COMPANY,
    EDIT_COMPANY_FAILED,
    EDIT_COMPANY_SUCCEEDED,
    EditCompanyActionTypes
} from "./actionTypes";
import { Company } from "../../domain/entity/company";

export const editCompany = (company: Company): EditCompanyActionTypes => ({
    type   : EDIT_COMPANY,
    payload: company
})

export const editCompanySucceeded = (): EditCompanyActionTypes => ({
    type: EDIT_COMPANY_SUCCEEDED
})

export const editCompanyFailed = (error: string): EditCompanyActionTypes => ({
    type   : EDIT_COMPANY_FAILED,
    payload: error
})
