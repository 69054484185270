import { ApplicationContext } from "../../../../../configuration/application.context";
import { BroadcastConversationDTO, ChatMessageDto, ConversationDto } from "../dto/conversation.dto";
import { Conversation } from "../../../../domain/entities/conversation";
import { ChatUser } from "../../../../domain/entities/chatUser";
import { ChatUserBuilder } from "../../../../domain/builder/chatUser.builder";
import { ConversationBuilder } from "../../../../domain/builder/conversationBuilder";
import { ChatMessage } from "../../../../domain/entities/chatMessage";
import { ChatMessageBuilder } from "../../../../domain/builder/chatMessage.builder";
import { BroadcastConversation } from "../../../../domain/entities/broadcastConversation";
import { BroadcastConversationBuilder } from "../../../../domain/builder/broadcastConversation.builder";
import { BroadcastMessage } from "../../../../domain/entities/broadcastMessage";
import { BroadcastMessageBuilder } from "../../../../domain/builder/broadcastMessage.builder";
import { i18n } from "../../../../../configuration/i18n";


const moment = ApplicationContext.getInstance().momentJs()

export class MessagingMapper {

    static mapConversationDtoToConversationArray(remoteconversations: ConversationDto[]): Conversation[] {
        const conversations: Conversation[] = []
        remoteconversations.map(item => {
            const users: ChatUser[] = []
            let owner: ChatUser = new ChatUserBuilder().build()
            item.participantCollection.map(user =>
                user.uuid === item.owner ?
                    owner = new ChatUserBuilder().withId(user.realId).withName(user.name).withAvatar(user.avatarUrl).build()
                    : users.push(new ChatUserBuilder().withId(user.realId).withAvatar(user.avatarUrl).withName(user.name).build())
            )
            conversations.push(new ConversationBuilder().withId(item.uuid)
                .withTitle(item.label)
                .withUnreadMessage(item.newMessage)
                .withExcerpt(item.excerpt)
                .withUpdatedAt(moment(item.lastUpdate))
                .withOwner(owner)
                .withUsers(users)
                .withPicture(item.picture)
                .build()
            )
        })
        return conversations
    }

    static mapChatMessageDtoToChatMessages(messages: ChatMessageDto[]): ChatMessage[] {
        const chatMessages: ChatMessage[] = []
        messages.map(item => {
                const user = new ChatUserBuilder().withId(item.senderId).withAvatar(item.senderAvatar).build()
                chatMessages.push(new ChatMessageBuilder()
                    .withId(item.id)
                    .withUser(user)
                    .withCreatedAt(item.sendAt)
                    .withContent(item.message.replaceAll('\n', '<br>'))
                    .withImage(item.image)
                    .build())
            }
        )
        return chatMessages
    }

    static mapDtoConversationToBroadcastConversation(broadcastConversation: BroadcastConversationDTO): BroadcastConversation {
        const messages: BroadcastMessage[] = []
        const conversation: BroadcastConversationBuilder = new BroadcastConversationBuilder()
            .withTitle(broadcastConversation.title)

        if (broadcastConversation.excerpt && broadcastConversation.excerpt.length > 0)
            conversation.withExcerpt(broadcastConversation.excerpt)

        if (broadcastConversation.lastBroadcast)
            conversation.withUpdatedAt(broadcastConversation.lastBroadcast)

        broadcastConversation.list.map(message => {
                messages.push(
                    new BroadcastMessageBuilder()
                        .withId(message.id)
                        .withCreatedAt(message.sentAt)
                        .withContent(message.message.replaceAll('\n', '<br>'))
                        .withImage(message.image)
                        .withSentTo(i18n.messaging.sent_to_followers(message.receiversCount))
                        .build())
            }
        )

        return conversation
            .withMessages(messages)
            .build()
    }
}
