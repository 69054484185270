import { ApplicationContext } from "../../../configuration/application.context";

const moment = ApplicationContext.getInstance().momentJs()

export class StatsProfile {
    constructor(
        private _count: number,
        private _creationDate: Date
    ) {
    }

    get count(): number {
        return this._count;
    }

    get creationDate(): string {
        return moment(this._creationDate).format('ll');
    }
}