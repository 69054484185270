import { RegisterBusinessState } from "../../configuration/state";
import {
    REGISTER_BUSINESS,
    REGISTER_BUSINESS_FAILED,
    REGISTER_BUSINESS_SUCCEEDED,
    RegisterBusinessActionTypes
} from "./actionTypes";

const initialState: RegisterBusinessState = {
    loading   : false,
    businessId: undefined,
    error     : undefined
}

export const registerBusinessReducer = (state = initialState, action: RegisterBusinessActionTypes): RegisterBusinessState => {
    switch (action.type) {
        case REGISTER_BUSINESS:
            return {
                loading   : true,
                businessId: undefined,
                error     : undefined
            }
        case REGISTER_BUSINESS_SUCCEEDED:
            return {
                loading   : false,
                businessId: action.payload,
                error     : undefined
            }
        case REGISTER_BUSINESS_FAILED:
            return {
                loading   : false,
                businessId: undefined,
                error     : action.payload
            }
        default:
            return state
    }
}
