import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { EDIT_PRODUCT_STATUS, EditProductStatusAction } from "./actionTypes";
import { editProductStatusFailed, editProductStatusSucceeded } from "./actions";
import { ProductService } from "../../domain/gateway/product.service";

export const editProductStatusEpic: Epic = (action$: ActionsObservable<EditProductStatusAction>,
                                            store: StateObservable<AppState>,
                                            {productService}: { productService: ProductService }) =>
    action$.pipe(
        ofType(EDIT_PRODUCT_STATUS),
        switchMap(
            (action) => productService.editProductStatus(action.payload.product, action.payload.companyId)
                .pipe(
                    map(() => editProductStatusSucceeded()),
                    catchError(error => of(editProductStatusFailed(error)))
                )
        )
    )
