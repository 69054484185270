import React, { PureComponent, ReactNode } from 'react'
import { CreateAgendaInstant } from "../../creation/agendaEvent";
import { CreateServiceInstant } from '../../creation/service';
import { CreateSpecialOfferInstant } from '../../creation/specialOffer';

interface Props {
    visible: boolean;
    form: 'event' | 'service' | 'special_offer'
    titleForm: string;
    onClose: () => void;
}

export class CreateInstantFormModal extends PureComponent<Props> {
    render(): ReactNode {
        if (this.props.visible)
            return (
                <div className="modal modal-creation default-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="header">
                                <p className={'title'}>{this.props.titleForm}</p>
                                <span className={'close-button'} onClick={() => this.props.onClose()}/>
                            </div>

                            <div className="body">
                                {this.props.form === 'event' ?
                                    <CreateAgendaInstant onCreate={(): void => this.props.onClose()}/> :
                                    this.props.form === 'special_offer' ?
                                        <CreateSpecialOfferInstant onCreate={(): void => this.props.onClose()}/> :
                                        <CreateServiceInstant onCreate={(): void => this.props.onClose()}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return null
    }
}
