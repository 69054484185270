import React from 'react';
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";

export const EmailsProPage = (): JSX.Element => {
        return (
            <div className={'h-100'}>
                <DefaultPageHeader title={'Emails Pro'}/>

                <div className="h-100" style={{overflow: 'hidden'}}>
                    <iframe src={'https://webmail01.vivezici.fr/#1'}
                            sandbox={''}
                            style={{
                                border: 'none',
                                height: '100%',
                                width: '100%'
                            }}
                            height={'100%'}
                            width={'100%'}/>
                </div>
            </div>
        )
}
