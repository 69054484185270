import { combineEpics, Epic } from 'redux-observable';
import { loadProductsEpic } from "../usecases/loadProducts/epic";
import { addProductEpic } from "../usecases/addProduct/epic";
import { editProductEpic } from "../usecases/editProduct/epic";
import { deleteProductEpic } from "../usecases/deleteProduct/epic";
import { editProductStatusEpic } from "../usecases/editProductStatus/epic";
import { loadEcommerceOrdersEpic } from "../usecases/loadOrders/epic";

export const ecommerceRootEpic: Epic = combineEpics(
    loadProductsEpic,
    addProductEpic,
    editProductEpic,
    deleteProductEpic,
    editProductStatusEpic,
    loadEcommerceOrdersEpic
)
