import { ApiInstantService } from "../adapters/secondaries/real/api.instant.service";
import { InstantService } from "../domain/gateway/instant.service";
import { InmemoryInstantService } from "../adapters/secondaries/inmemory/inmemory.instant.service";

export class instantDependenciesFactory {
    static getInstantService(): InstantService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiInstantService()
            default:
                return new InmemoryInstantService()
        }
    }
}