import React, { PureComponent, ReactNode } from 'react'
import { subscriptionProData } from "../../../data/subscriptionProData";
import { i18n } from "../../../../../../configuration/i18n";
import { SubscriptionId } from "../../../../../domain/entities/types/subscription.type";

interface Props {
    isMonthly: boolean;
    preferredOffer: 'basic' | 'premium' | 'digital'
}

export class SubscriptionProHeaderTable extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <thead>
            <tr className='header_table'>
                <th style={{width: '35%', padding: 0}}></th>
                {subscriptionProData.map(subscription => {
                    return (<th key={subscription.id} className={this.props.preferredOffer === subscription.id ? 'best_offer' : undefined}>
                        {this.props.preferredOffer === subscription.id ? <div className="ribbon-2">{i18n.payment.popular}</div> : undefined}
                        <div className={this.props.preferredOffer === subscription.id ? 'premium' : 'basic'}>
                            <p className={'title_offer'}>{subscription.label}</p>
                        </div>
                        <div className={'price_header'}>
                            <div
                                className={'price_label'}>{`${this.props.isMonthly ? subscription.monthly_price.toFixed(2) : subscription.promo_price}€`}<span
                                className={'currency_ht'}>{'HT'}</span>
                            </div>
                            <span
                                className={'price_currency'}>{`soit ${this.props.isMonthly ? this.getTTCPrice(subscription.monthly_price) : this.getTTCPrice(subscription.promo_price)}€ ttc / ${this.props.isMonthly ? i18n.payment.month : i18n.payment.year}`}</span>
                            <p className={'price_currency'}
                               style={{fontSize: 9, marginTop: -10}}>{subscription.id === SubscriptionId.DIGITAL ? i18n.payment.engagement_year : i18n.payment.no_engagement}</p>
                            <p className={'price_subtitle'}>{!this.props.isMonthly ? i18n.payment.subtitle_year : '\u00A0'}</p>
                        </div>
                    </th>)

                })}
            </tr>
            </thead>
        )
    }

    getTTCPrice(price: number): string {
        return (price * 1.2).toFixed(2)
    }
}
