export class SocialPageInfo{
    constructor(
        protected _idPage: string,
        protected _name: string,
        protected _image: string
    ) {
    }

    get idPage(): string {
        return this._idPage;
    }

    get name(): string {
        return this._name;
    }

    get image(): string{
        return this._image
    }
}
