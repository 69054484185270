import { ManagementInstantCategoryType } from "./types/managementInstantCategoryType";

export class ManagementInstantInformation {

    constructor(
        private _id: string,
        private _businessId: string,
        private _title: string,
        private _picture: File,
        private _pictureUrl: string,
        private _shortDescription: string,
        private _longDescription: string,
        private _category: ManagementInstantCategoryType,
        private _instantStatus: boolean
    ) {
    }

    get title(): string {
        return this._title;
    }

    get picture(): File {
        return this._picture;
    }

    get pictureUrl(): string {
        return this._pictureUrl;
    }

    get shortDescription(): string {
        return this._shortDescription;
    }

    get longDescription(): string {
        return this._longDescription;
    }

    get category(): ManagementInstantCategoryType {
        return this._category;
    }

    get id(): string {
        return this._id;
    }

    get businessId(): string {
        return this._businessId;
    }

    get instantStatus(): boolean {
        return this._instantStatus;
    }
}
