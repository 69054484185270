import React from "react";
import { i18n } from "../../../../configuration/i18n";

export const NoCompanyFound = (): JSX.Element => {
    return (
        <div className="inner pt-4 pb-5 clearfix">
            <div className="wizard-title text-center w-100">
                <h1 className="title">{i18n.authentication.no_company_found}</h1>

                <p className="info text-center px-3">
                    {i18n.authentication.are_u_sure}<br/>
                    {i18n.authentication.create_pro_account}
                    <a className="js-btn-next"
                       style={{ textDecoration: 'underline' }}
                       href={'/inscription'}>{i18n.authentication.here}</a>
                </p>
            </div>
        </div>
    )
};
