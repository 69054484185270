import React, { useState } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { ThemeFoodMenuDocumentType } from "../../types";
import { FoodMenuPreview } from "./foodMenu.preview";
import { AddFoodMenuModal } from "./modal";

interface Props {
    isSubmitted: boolean;
    children: JSX.Element;
    data: ThemeFoodMenuDocumentType[];
    onChange: (menus: ThemeFoodMenuDocumentType[]) => void;
}

export const FoodMenuInput = (props: Props): JSX.Element => {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    return (
        <div className={'wizard-form-input align-items-start mb-0'}>
            <label className={'mt-3'}>{i18n.company.menu_files}</label>

            <div>
                <div className={'d-flex align-items-center flex-wrap image-list'}>
                    {props.data.map((item, index) => (
                        <FoodMenuPreview key={index}
                                         imageUrl={item.icon}
                                         title={item.title}
                                         removeImage={() => {
                                             const menus = [...props.data]
                                             menus.splice(index, 1)
                                             props.onChange([...menus])
                                         }}/>
                    ))}
                    <div className={'d-flex flex-column'}>
                        <div className={'add-img-btn d-flex align-items-center justify-content-center flex-column position-relative'}>
                            <div className={'text-center'} onClick={() => setIsModalVisible(true)}>
                                <i className="fa fa-plus"/>
                                <p>Ajouter un document</p>
                            </div>
                        </div>

                        <AddFoodMenuModal isVisible={isModalVisible}
                                          onClose={() => setIsModalVisible(false)}
                                          addFoodMenu={(menu: ThemeFoodMenuDocumentType) => {
                                              props.onChange([...props.data, menu])
                                          }}/>
                    </div>
                </div>

                {props.children}
            </div>
        </div>
    )
}
