import { ContentPostType } from "../../domain/entities/types/contentPostType";
import { SocialPageInfo } from "../../../company/domain/entity/socialPageInfo";

export const POST_INSTANT_ON_FACEBOOK = 'POST_INSTANT_ON_FACEBOOK'
export const POST_INSTANT_ON_FACEBOOK_FAILED ='POST_INSTANT_ON_FACEBOOK_FAILED'
export const POST_INSTANT_ON_FACEBOOK_SUCCEEDED ='POST_INSTANT_ON_FACEBOOK_SUCCEEDED'

export interface PostInstantOnFacebookAction {
    type: typeof POST_INSTANT_ON_FACEBOOK;
    payload: {page: SocialPageInfo, content: ContentPostType}
}

export interface PostInstantOnFacebookFailedAction {
    type: typeof POST_INSTANT_ON_FACEBOOK_FAILED;
    payload: string
}
export interface PostInstantOnFacebookSucceededAction {
    type: typeof POST_INSTANT_ON_FACEBOOK_SUCCEEDED;
}

export type PostInstantOnFacebookActionTypes = PostInstantOnFacebookAction | PostInstantOnFacebookFailedAction | PostInstantOnFacebookSucceededAction
