import React, { PureComponent, ReactNode } from "react";
import { Company } from "../../../domain/entity/company";
import './companyProfile.css';
import { i18n } from "../../../../configuration/i18n";
import { CompanyOpeningHoursPresentational } from "./components/company.openingHours";
import { CompanyContactsPresentational } from "./components/company.contacts";
import { CompanyProfileHeader } from "./components/company.header";
import { CompanyInstants } from "./components/company.instants";
import { ManagementInstantHeader } from "../../../../instant-management/domain/entities/managementInstantHeader";
import { CompanyHeaderTitle } from "./components/companyHeaderTitle";
import { OpenMobileApplicationModal } from "./components/mobile.modal";
import { MobileView } from "react-device-detect";
import { Follower } from "../../../domain/entity/follower";
import { FollowersCompanyModal } from "./components/followers.company.modal";

interface Props {
    company: Company | null
    registrationStep: number
    instants: ManagementInstantHeader[] | null
    loadInstants: (companyId: string) => void;
    loadFollowers: (companyId: string) => void;
    followers: Follower[] | null;
    loading: boolean;
}

interface State {
    visibleFollower: boolean;
}

export class CompanyProfileContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            visibleFollower: false
        }
    }

    componentDidMount(): void {
        document.title = i18n.company.company_profile_page_title;
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.company !== this.props.company && this.props.company) {
            if (this.props.loading === false && this.props.registrationStep < 3) {
                window.location.href = '/inscription'
            }
            this.props.loadInstants(this.props.company.id)
            this.props.loadFollowers(this.props.company.id)
        }
    }

    render(): ReactNode {
        if (this.props.company) {
            return (
                <div>
                    <CompanyHeaderTitle company={this.props.company}/>

                    <div className={'container-fluid custom-width-1260'}>
                        <CompanyProfileHeader company={this.props.company}
                                              onPressFollower={() => this.setState({visibleFollower: true})}/>

                        <div className="profile-section-container mt-3 mb-3 pt-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="section_about">
                                        <h2 className="title">{i18n.company.about}</h2>
                                        <div className={'parse-to-html px-3'}
                                             dangerouslySetInnerHTML={{__html: this.props.company.contacts.description}}/>
                                    </div>
                                </div>

                                <CompanyOpeningHoursPresentational openingHours={this.props.company.openingHours}/>
                            </div>

                            <div className="profile-section mt-5">
                                <h2 className="title">{i18n.company.instants}</h2>

                                <CompanyInstants instants={this.props.instants}/>
                            </div>

                            <div className="profile-section mt-5">
                                <CompanyContactsPresentational company={this.props.company}/>
                            </div>
                        </div>
                    </div>

                    <FollowersCompanyModal visible={this.state.visibleFollower}
                                           onClose={() => this.setState({visibleFollower: false})}
                                           followers={this.props.followers}/>

                    <MobileView>
                        <OpenMobileApplicationModal companyId={this.props.company.id}/>
                    </MobileView>
                </div>
            )
        } else
            return <div/>
    }
}
