export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED'
export const DELETE_PRODUCT_SUCCEEDED = 'DELETE_PRODUCT_SUCCEEDED'

export interface DeleteProductAction {
    type: typeof DELETE_PRODUCT;
    payload:  {
        productId: string;
        companyId: string;
    }
}

export interface DeleteProductFailedAction {
    type: typeof DELETE_PRODUCT_FAILED;
    payload: string;
}

export interface DeleteProductSucceededAction {
    type: typeof DELETE_PRODUCT_SUCCEEDED;
}

export type DeleteProductActionTypes = DeleteProductAction | DeleteProductFailedAction | DeleteProductSucceededAction
