export class ThemeWelcomeBlock {

    static BACKGROUND_COLOR = "#2e2822";

    constructor(
        protected _text: string,
        protected _buttonLabel: string,
        protected _buttonUrl: string,
        protected _videoUrl: string,
        protected _openInNewWindow: boolean,
        protected _backgroundColor: string
    ) {
    }


    get text(): string {
        return this._text;
    }

    get buttonLabel(): string {
        return this._buttonLabel;
    }

    get buttonUrl(): string {
        return this._buttonUrl;
    }

    get videoUrl(): string {
        return this._videoUrl;
    }

    get openInNewWindow(): boolean {
        return this._openInNewWindow;
    }

    get backgroundColor(): string {
        return this._backgroundColor;
    }
}
