import { Company } from "../../../domain/entity/company";
import { AppState } from "../../../../redux-configuration/AppState";
import { companySelector } from "../../../usecases/updateStoreCompany/selector";
import { connect } from "react-redux";
import { CompanyEditionContainer } from "./companyEdition.container";
import { Dispatch } from "redux";
import {
    editCompanyErrorSelector,
    editCompanyLoadingSelector,
    editCompanySuccessSelector
} from "../../../usecases/editCompany/selector";
import { editCompany } from "../../../usecases/editCompany/actions";
import { EditCompanyActionTypes } from "../../../usecases/editCompany/actionTypes";

interface StateToPropsType {
    company: Company | null;
    loading: boolean;
    success: boolean | null;
    error: string | undefined;
}

interface DispatchToPropsType {
    editCompany: (company: Company) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companySelector(state),
    loading: editCompanyLoadingSelector(state),
    success: editCompanySuccessSelector(state),
    error  : editCompanyErrorSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    editCompany: (company: Company): EditCompanyActionTypes => dispatch(editCompany(company))
})

export const CompanyEdition = connect(mapStateToProps, mapDispatchToProps)(CompanyEditionContainer)
