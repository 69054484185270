import { combineReducers } from 'redux';
import { AppState } from './AppState';
import { registrationReducer } from "../registration/configuration/rootReducer";
import { companyReducer } from "../company/configuration/rootReducer";
import { ManagementInstantsRootReducer } from "../instant-management/configuration/rootReducer";
import { InstantRootReducer } from "../instant/configuration/rootReducer";
import { PaymentRootReducer } from "../payment/configuration/rootReducer";
import { messagingRootReducer } from "../messaging/configuration/rootReducer";
import { clickCollectRootReducer } from "../click-collect/configuration/rootReducer";
import { municipalityRootReducer } from "../municipality/configuration/rootReducer";
import { authenticationRootReducer } from "../authentication/configuration/rootReducer";
import { statsRootReducer } from "../stats/configuration/rootReducer";
import { ecommerceRootReducer } from "../ecommerce/configuration/rootReducer";

export const reduxReducer = combineReducers<AppState>({
    registration     : registrationReducer,
    authentication   : authenticationRootReducer,
    company          : companyReducer,
    managementInstant: ManagementInstantsRootReducer,
    stats            : statsRootReducer,
    instants         : InstantRootReducer,
    payment          : PaymentRootReducer,
    messaging        : messagingRootReducer,
    clickCollect     : clickCollectRootReducer,
    municipality     : municipalityRootReducer,
    ecommerce        : ecommerceRootReducer
})
