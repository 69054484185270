import React from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeFoodMenuDocumentType, ThemeFoodMenuType } from "../../types";
import { ThemeCheckboxInput } from "../checkbox.input";
import { KeyOf, ValueOf } from "../../../../../../common/domain/types/app.categories";
import { FoodMenuInput } from "./foodMenu.input";

interface Props {
    isSubmitted: boolean;
    data: ThemeFoodMenuType;
    onChange: (key: KeyOf<ThemeFoodMenuType>, value: ValueOf<ThemeFoodMenuType>) => void;
}

export const FoodMenuBlockConfiguration = (props: Props): JSX.Element => {

    return (
        <div id={"theme-gallery"}>
            <ThemeCheckboxInput label={'Activer la zone documents'}
                                checked={props.data.isActive}
                                onChange={(checked: boolean) => props.onChange('isActive', checked)}/>

            {props.data.isActive && (
                <div className={'mt-3'}>
                    <TextInput type={'text'}
                               error={props.isSubmitted && (!props.data.headerTitle || props.data.headerTitle.length === 0)}
                               label={i18n.company.headerTitle}
                               value={props.data.headerTitle ?? ''}
                               raiseUpdates={(value: string) => props.onChange('headerTitle', value)}/>

                    <TextInput type={'text'}
                               error={props.isSubmitted && (!props.data.title || props.data.title.length === 0)}
                               label={i18n.company.section_title}
                               value={props.data.title ?? ''}
                               raiseUpdates={(value: string) => props.onChange('title', value)}/>

                    <TextInput type={'text'}
                               error={props.isSubmitted && (!props.data.subTitle || props.data.subTitle.length === 0)}
                               label={i18n.company.sub_title}
                               value={props.data.subTitle ?? ''}
                               raiseUpdates={(value: string) => props.onChange('subTitle', value)}/>

                    <TextInput type={'color'}
                               error={props.isSubmitted && (!props.data.backgroundColor || props.data.backgroundColor.length === 0)}
                               label={i18n.company.background_color}
                               value={props.data.backgroundColor ?? ''}
                               raiseUpdates={(value: string) => props.onChange('backgroundColor', value)}/>

                    <FoodMenuInput isSubmitted={props.isSubmitted}
                                   data={props.data.tabs}
                                   onChange={(menus: ThemeFoodMenuDocumentType[]) => props.onChange('tabs', menus)}>
                        <div className="food-menu-checkbox mb-4">
                            <ThemeCheckboxInput label="Afficher comme icône"
                                                checked={props.data.displayAsIcon}
                                                onChange={(isChecked: boolean) => props.onChange("displayAsIcon", isChecked)}/>
                        </div>
                    </FoodMenuInput>
                </div>
            )}
        </div>
    )
}
