import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { validateOrderFailed, validateOrderSucceeded } from './actions';
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { VALIDATE_ORDER, ValidateOrderAction } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { Order } from "../../domain/entities/order";
import { ClickCollectService } from "../../domain/gateway/clickCollectService";

export const validateOrderEpic: Epic = (action$: ActionsObservable<ValidateOrderAction>, store: StateObservable<AppState>,
                                        {clickCollectService}: { clickCollectService: ClickCollectService }) =>
    action$.pipe(
        ofType(VALIDATE_ORDER),
        switchMap((action: { payload: { order: Order; total: string; } }) => clickCollectService.validateOrder(action.payload.order, action.payload.total)
            .pipe(
                map((conversationId: string) => validateOrderSucceeded(conversationId)),
                catchError(err => of(validateOrderFailed(err)))
            )
        )
    )
