import {
    LISTING_MUNICIPAL_SERVICES, LISTING_MUNICIPAL_SERVICES_FAILED, LISTING_MUNICIPAL_SERVICES_SUCCEEDED, LISTING_SERVICE_ICONS_SUCCEEDED,
    ListingMunicipalServicesActionTypes
} from "./actionTypes";
import { ListingMunicipalServicesState } from "../../configuration/state";

const initialState: ListingMunicipalServicesState = {
    loading : false,
    error   : undefined,
    services: undefined,
    icons   : undefined
}
export const listingMunicipalServicesReducer = (state = initialState, action: ListingMunicipalServicesActionTypes): ListingMunicipalServicesState => {
    switch (action.type) {
        case LISTING_MUNICIPAL_SERVICES:
            return {loading: true, error: undefined, services: undefined, icons: undefined}
        case LISTING_MUNICIPAL_SERVICES_FAILED:
            return {loading: false, error: action.payload, services: undefined, icons: undefined}
        case LISTING_MUNICIPAL_SERVICES_SUCCEEDED:
            return {loading: false, error: undefined, services: action.payload, icons: state.icons}
        case LISTING_SERVICE_ICONS_SUCCEEDED:
            return {loading: false, error: undefined, services: state.services, icons: action.payload}
        default:
            return state
    }
}
