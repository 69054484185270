import { AppState } from "../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { editProductErrorSelector, editProductLoadingSelector, editProductSuccessSelector } from "../../../usecases/editProduct/selectors";
import { ProductDetails } from "../../../domain/entities/productDetails";
import { editProduct, editResetAction } from "../../../usecases/editProduct/actions";
import { EditProductAction, EditResetAction } from "../../../usecases/editProduct/actionTypes";
import { EditProductContainer } from "./editProduct.container";
import { productsSelector } from "../../../usecases/loadProducts/selectors";
import { LoadProductsAction } from "../../../usecases/loadProducts/actionTypes";
import { loadProducts } from "../../../usecases/loadProducts/actions";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import { Company } from "../../../../company/domain/entity/company";

interface StateToPropsType {
    products: ProductDetails[] | undefined;
    company: Company | null;
    loading: boolean;
    success: boolean | undefined;
    error: string | undefined;
}

interface DispatchToPropsType {
    editProduct: (product: ProductDetails, companyId: string) => void;
    loadProducts: (companyId: string) => void;
    reset: () => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    products: productsSelector(state),
    company : companySelector(state),
    loading : editProductLoadingSelector(state),
    success : editProductSuccessSelector(state),
    error   : editProductErrorSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    editProduct : (product: ProductDetails, companyId: string): EditProductAction => dispatch(editProduct(product, companyId)),
    loadProducts: (companyId: string): LoadProductsAction => dispatch(loadProducts(companyId)),
    reset       : (): EditResetAction => dispatch(editResetAction())
})

export const EditProductPage = connect(mapStateToProps, mapDispatchToProps)(EditProductContainer)
