import { AsyncState } from "../../configuration/state";
import { SIGN_UP, SIGN_UP_FAILED, SIGN_UP_SUCCEEDED, SignUpActionTypes } from "./actionTypes";

const initialState: AsyncState = {
    loading: false,
    success: null,
    error  : undefined
}

export const signUpReducer = (state = initialState, action: SignUpActionTypes): AsyncState => {
    switch (action.type) {
        case SIGN_UP:
            return {
                loading: true,
                success: null,
                error  : undefined
            }
        case SIGN_UP_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case SIGN_UP_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
}
