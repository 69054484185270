import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../configuration/i18n";
import { Company } from "../../../../company/domain/entity/company";
import '../superAdminStats/stats.css';
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { CartesianData } from "../../../domain/entity/cartesianData";
import { StatCard } from "../components/card/statCard";
import { ManagementInstantHeader } from "../../../../instant-management/domain/entities/managementInstantHeader";
import { BusinessVisitsChart } from "./components/business.visits.chart";
import { BusinessFollowChart } from "./components/business.follow.chart";


interface Props {
    loading: boolean;
    company: Company | null;
    visitStats: CartesianData[] | undefined;
    followersStats: CartesianData[] | undefined;
    retrieveCompanyStats: (companyId: string) => void;
    followersNumber: number | undefined;
    instants: ManagementInstantHeader[] | null;
}

export class CompanyStatsContainer extends PureComponent<Props> {

    constructor(props: Props) {
        super(props);
    }


    componentDidMount(): void {
        document.title = i18n.stats.stats_page_title;
        if (this.props.company) {
            this.props.retrieveCompanyStats(this.props.company.id)
        }
    }

    render(): ReactNode {

        if (this.props.visitStats != undefined && this.props.followersStats) {

            const visitsSum = this.props.visitStats.reduce((accumulator, currentValue, currentIndex) => {
                if (this.props.visitStats && currentIndex === (this.props.visitStats.length - 1)) {
                    return accumulator
                }
                return accumulator + currentValue.yValue
            }, 0)


            const visitsAverage = parseInt((visitsSum / this.props.visitStats.length).toString())
            const last = this.props.visitStats[this.props.visitStats.length - 2].yValue
            const visitsKpi = parseFloat((((last - visitsAverage) / visitsAverage) * 100).toFixed(2))
            const visitsKpiColor = visitsKpi > 0 ? "green" : visitsKpi == 0 ? undefined : 'red'
            const visitsKpiSign = visitsKpi > 0 ? "+" : undefined

            return (
                <div>
                    <DefaultPageHeader title={i18n.stats.clichy_stats}/>
                    <br/>
                    <div className="container-fluid  custom-width-1230 mb-5">
                        <div className="d-flex  flex-wrap mb-5">
                            <StatCard title={i18n.stats.follower}
                                      number={this.props.followersNumber}/>

                            <StatCard title={i18n.stats.active_instants}
                                      number={this.props.instants?.reduce((acc, current) => acc + (current.isCurrentlyActive() ? 1 : 0), 0)}/>

                            <StatCard title={i18n.stats.instants_number}
                                      number={this.props.instants?.length}/>


                            <StatCard title={i18n.stats.visit_transform_rate}
                                      color={visitsKpiColor}
                                      prefix={visitsKpiSign}
                                      number={visitsKpi}
                                      suffix={'%'}/>
                        </div>

                        <div className="d-flex flex-wrap mb-3 container-stats mb-5">
                            <BusinessFollowChart followData={this.props.followersStats}/>
                        </div>

                        <div className="d-flex flex-wrap container-stats mb-5">
                            <BusinessVisitsChart visitsData={this.props.visitStats}/>
                        </div>
                    </div>
                </div>
            )
        } else
            return (
                <div className={'show-loader'}>
                    <div className={'loader'}/>
                </div>
            )
    }
}
