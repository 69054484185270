export class Follower {
    constructor(
        private _phoneNumber: string,
        private _firstName: string,
        private _lastName: string,
        private _imageURL: string,
        private _zipcode: number,
        private _city: string | undefined,
        private _followDate: string
    ) {
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get imageURL(): string {
        return this._imageURL
    }

    get zipcode(): number {
        return this._zipcode
    }

    get city(): string | undefined {
        return this._city
    }
    get followDate(): string {
        return this._followDate
    }

    get displayName(): string {
        return this._firstName + ' ' + this._lastName
    }
}
