import { ThemeDetails } from "../entity/ThemeDetails";
import { ThemeBlock } from "../entity/ThemeBlock";
import { ThemeConfiguration } from "../entity/ThemeConfiguration";
import { ThemeSocialMedia } from "../entity/ThemeSocialMedia";
import { ThemeWelcomeBlock } from "../entity/ThemeWelcomeBlock";
import { ThemeFoodMenu } from "../entity/ThemeFoodMenu";
import { ThemeEcommerce } from "../entity/ThemeEcommerce";

export class ThemeConfigurationBuilder {

    protected _general: ThemeDetails
    protected _header: ThemeBlock
    protected _footer: ThemeBlock
    protected _welcome: ThemeWelcomeBlock
    protected _about: ThemeBlock
    protected _instants: ThemeBlock
    protected _gallery: ThemeBlock
    protected _address: ThemeBlock
    protected _contact: ThemeBlock
    protected _review: ThemeBlock
    protected _clickCollect: ThemeBlock
    protected _foodMenu: ThemeFoodMenu
    protected _socialMedia: ThemeSocialMedia
    protected _ecommerce: ThemeEcommerce


    withGeneral(value: ThemeDetails): ThemeConfigurationBuilder {
        this._general = value;
        return this;
    }

    withHeader(value: ThemeBlock): ThemeConfigurationBuilder {
        this._header = value;
        return this;
    }

    withFooter(value: ThemeBlock): ThemeConfigurationBuilder {
        this._footer = value;
        return this;
    }

    withWelcome(value: ThemeWelcomeBlock): ThemeConfigurationBuilder {
        this._welcome = value;
        return this;
    }

    withAbout(value: ThemeBlock): ThemeConfigurationBuilder {
        this._about = value;
        return this;
    }

    withInstants(value: ThemeBlock): ThemeConfigurationBuilder {
        this._instants = value;
        return this;
    }

    withGallery(value: ThemeBlock): ThemeConfigurationBuilder {
        this._gallery = value;
        return this;
    }

    withAddress(value: ThemeBlock): ThemeConfigurationBuilder {
        this._address = value;
        return this;
    }

    withContact(value: ThemeBlock): ThemeConfigurationBuilder {
        this._contact = value;
        return this;
    }

    withReview(value: ThemeBlock): ThemeConfigurationBuilder {
        this._review = value;
        return this;
    }

    withClickCollect(value: ThemeBlock): ThemeConfigurationBuilder {
        this._clickCollect = value;
        return this;
    }

    withFoodMenu(value: ThemeFoodMenu): ThemeConfigurationBuilder {
        this._foodMenu = value;
        return this;
    }

    withSocialMedia(value: ThemeSocialMedia): ThemeConfigurationBuilder {
        this._socialMedia = value;
        return this;
    }

    withEcommerce(value: ThemeEcommerce): ThemeConfigurationBuilder {
        this._ecommerce = value;
        return this;
    }

    build(): ThemeConfiguration {
        return new ThemeConfiguration(
            this._general,
            this._header,
            this._footer,
            this._welcome,
            this._about,
            this._instants,
            this._gallery,
            this._address,
            this._contact,
            this._review,
            this._clickCollect,
            this._foodMenu,
            this._socialMedia,
            this._ecommerce
        )
    }
}
