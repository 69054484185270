import { combineReducers } from "redux";
import { StatsState } from "./state";
import { retrieveStatsReducer } from "../usecases/retrieveSuperAdminStats/retrieveStats.reducer";
import { retrieveCompanyStatsReducer } from "../usecases/retrieveCompanyStats/retrieveCompanyStats.reducer";

export const statsRootReducer = combineReducers<StatsState>({
    superAdmin: retrieveStatsReducer,
    proAccount: retrieveCompanyStatsReducer

})
