import React, { PureComponent, ReactNode } from "react";
import "./services.css";
import { ServiceItem } from "./components/service.item";
import { AddServiceButton } from "./components/addService.button";
import { ServicesPageHeader } from "./components/header";
import { Company } from "../../../company/domain/entity/company";
import { MunicipalService } from "../../domain/entities/municipalService";
import { FormServiceModal } from "./formService";

interface Props {
    company: Company | null;
    services: MunicipalService[] | undefined;
    deleteService: (serviceId: string) => void;
    loadServices: (municipalityId: string) => void;
    changeServicesOrder: (municipalityId: string, serviceIds: string[]) => void;
    loading: boolean;
}

interface State {
    services: MunicipalService[] | null;
    formServiceModal: boolean
    showButton: boolean
    selectedService: MunicipalService | undefined;
}

export class MunicipalityServicesContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            services        : null,
            formServiceModal: false,
            showButton      : false,
            selectedService : undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.company && this.props.company !== prevProps.company) {
            this.props.loadServices(this.props.company.id)
        }
        if (this.props.services && this.props.services !== prevProps.services) {
            this.setState({services: [...this.props.services]})
        }
    }

    componentDidMount(): void {
        if (this.props.company) {
            this.props.loadServices(this.props.company.id)
        }
    }

    render(): ReactNode {
        const spinner = this.props.loading ? <div className={'show-loader'}>
            <div className={'loader'}/>
        </div> : null
        return (
            <div>
                <ServicesPageHeader title={'Services'}
                                    showButton={this.state.showButton}
                                    addService={() => this.setState({formServiceModal: true, selectedService: undefined})}
                                    changeOrders={() => this.saveNewOrder()}/>

                <div className={'services-container'}>
                    <div className={'d-flex flex-wrap'}>
                        {spinner}
                        {this.renderServices()}

                        <AddServiceButton onClick={() => this.setState({formServiceModal: true, selectedService: undefined})}/>
                    </div>
                </div>

                <FormServiceModal service={this.state.selectedService} onClose={() => this.setState({formServiceModal: false})}
                                  visible={this.state.formServiceModal}/>
            </div>
        )
    }

    renderServices(): JSX.Element[] | undefined {
        if (this.state.services)
            return this.state.services.map(service => (
                <ServiceItem service={service}
                             key={service.id}
                             editService={() => this.setState({formServiceModal: true, selectedService: service})}
                             deleteService={() => this.props.deleteService(service.id.toString())}
                             swapServices={(sourceServiceId: string, destinationServiceId: string) => this.swapServices(sourceServiceId, destinationServiceId)}/>
            ))
    }

    saveNewOrder(): void {
        if (this.props.company && this.state.services) {
            const ids = this.state.services.map(service => service.id)
            this.props.changeServicesOrder(this.props.company.id, ids)
            this.setState({showButton: false})
        }
    }

    swapServices(sourceServiceId: string, destinationServiceId: string): void {
        const services = this.state.services ? this.state.services.slice() : [];
        let fromIndex = -1;
        let toIndex = -1;

        for (let i = 0; i < services.length; i++) {
            if (services[i].id === sourceServiceId) {
                fromIndex = i;
            }
            if (services[i].id === destinationServiceId) {
                toIndex = i;
            }
        }

        if (fromIndex != -1 && toIndex != -1) {
            const fromService = services[fromIndex];
            services[fromIndex] = services[toIndex];
            services[toIndex] = fromService;
            this.setState({services: services, showButton: true});
        }
    }
}
