import { LoadMessageState } from "../../../configuration/state";
import {
    LOAD_PREVIOUS_CHAT_MESSAGES_FAILED, LOAD_PREVIOUS_CHAT_MESSAGES_SUCCEEDED, LOAD_PREVIOUS_CHAT_MESSAGES, LoadPreviousChatMessagesActionTypes
} from "./actionTypes";

const initialState: LoadMessageState = {
    loading : false,
    messages: null,
    error   : undefined
}

export const loadPreviousChatMessagesReducer = (state = initialState, action: LoadPreviousChatMessagesActionTypes): LoadMessageState => {
    switch (action.type) {
        case LOAD_PREVIOUS_CHAT_MESSAGES:
            return {
                loading : true,
                messages: null,
                error   : undefined
            }
        case LOAD_PREVIOUS_CHAT_MESSAGES_SUCCEEDED:
            return {
                loading : false,
                messages: action.payload,
                error   : undefined
            }
        case LOAD_PREVIOUS_CHAT_MESSAGES_FAILED:
            return {
                loading : false,
                messages: null,
                error   : action.payload,
            }
        default:
            return state
    }
}
