import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ProductDetails } from "../../../../domain/entities/productDetails";
import { ProductDetailsBuilder } from "../../../../domain/entities/productDetails.builder";
import { editProductStatus } from "../../../../usecases/editProductStatus/actions";

interface Props {
    product: ProductDetails;
    companyId: string | undefined;
}

export const ProductStatusButton = (props: Props): JSX.Element => {
    const [status, setStatus] = useState<boolean>(props.product.isActive);
    const dispatch = useDispatch();

    const changeStatus = (value: boolean) => {
        setStatus(value);
        if (props.companyId) {
            const updatedProduct = new ProductDetailsBuilder()
                .withId(props.product.id)
                .withIsActive(value)
                .build();
            dispatch(editProductStatus(updatedProduct, props.companyId));
        }
    };

    if (status) {
        return (
            <button
                className={"status completed-status"}
                onClick={() => changeStatus(false)}
            >
                Activé
            </button>
        );
    }
    return (
        <button
            className={"status cancelled-status"}
            onClick={() => changeStatus(true)}
        >
            Désactivé
        </button>
    );
};
