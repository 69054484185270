import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { PaymentService } from "../../domain/gateway/paymentService";
import { RETRIEVE_PAYMENT_SESSION, RetrievePaymentSessionAction } from "./actionTypes";
import { retrievePaymentSessionFailed, retrievePaymentSessionSucceeded } from "./action";

export const retrievePaymentSessionEpic: Epic = (action$: ActionsObservable<RetrievePaymentSessionAction>,
                                                 store: StateObservable<AppState>,
                                                 { paymentService }: { paymentService: PaymentService }) =>
    action$.pipe(
        ofType(RETRIEVE_PAYMENT_SESSION),
        switchMap((action) => paymentService.retrievePaymentSession(action.payload.companyId, action.payload.subscriptionId, action.payload.periodicity)
            .pipe(
                map((sessionId: string) => retrievePaymentSessionSucceeded(sessionId)),
                catchError((error: string) => of(retrievePaymentSessionFailed(error)))
            )
        )
    )
