import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import GoogleMapReact from 'google-map-react';
import { TextInput } from "../inputs/text.input";
import Geocode from "react-geocode";
import Marker from "../../marker/marker";
import { store } from "../../../../../App";
import { updateSingleField } from "../../../../../company/usecases/updateSingleField/actions";
import { KeyOf, ValueOf } from "../../../../domain/types/app.categories";
import { BusinessRegistrationType } from "../../../../../registration/domain/type/BusinessRegistrationType";

export interface FormLocationStateType {
    address: string;
    city: string;
    zipCode: string;
    latitude: string;
    longitude: string;
}

interface Props {
    location: FormLocationStateType;
    raiseUpdate: (location: FormLocationStateType) => void
    isSubmitted: boolean
}

interface State {
    location: FormLocationStateType;
    addressError: boolean;
    cityError: boolean;
    zipCodeError: boolean;
}

export class FormLocationSection extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            location    : {
                address  : this.props.location.address,
                city     : this.props.location.city,
                zipCode  : this.props.location.zipCode,
                latitude : '48.90365039372989',
                longitude: '2.304496350563756'
            },
            addressError: false,
            cityError   : false,
            zipCodeError: false
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.location && this.props.location !== prevProps.location)
            this.setState({
                location: {
                    ...this.props.location,
                    address: this.props.location.address,
                    city   : this.props.location.city,
                    zipCode: this.props.location.zipCode
                }
            })

        if (this.props.isSubmitted && this.props.isSubmitted !== prevProps.isSubmitted)
            this.setState({
                addressError: !this.state.location.address || this.state.location.address.length === 0,
                cityError   : this.state.location.city.length === 0,
                zipCodeError: this.state.location.zipCode.length === 0
            })
    }

    render(): ReactNode {
        return (
            <div className={'section_pro'}>
                <span className="wizard-sub-text">{i18n.common.location_description}</span>

                <div className={'px-3'}>
                    <TextInput type={'text'}
                               className={'profile-edition-input'}
                               error={this.state.addressError}
                               label={i18n.common.street}
                               value={this.state.location.address}
                               onBlur={() => this.updateField('address', this.state.location.address)}
                               raiseUpdates={(address: string) => this.setState({
                                   location    : {...this.state.location, address},
                                   addressError: false
                               }, () => this.getCoordinatesFromAddress())}/>

                    <TextInput type={'text'}
                               error={this.state.cityError}
                               className={'profile-edition-input'}
                               label={i18n.common.city}
                               value={this.state.location.city}
                               onBlur={() => this.updateField('city', this.state.location.city)}
                               raiseUpdates={(city: string) => this.setState({
                                   location : {...this.state.location, city},
                                   cityError: false
                               }, () => this.getCoordinatesFromAddress())}/>

                    <TextInput type={'text'}
                               className={'profile-edition-input'}
                               error={this.state.zipCodeError}
                               label={i18n.common.zipCode}
                               value={this.state.location.zipCode}
                               onBlur={() => this.updateField('zipCode', this.state.location.zipCode)}
                               raiseUpdates={(zipCode: string) => this.setState({
                                   location    : {...this.state.location, zipCode},
                                   zipCodeError: false
                               }, () => this.getCoordinatesFromAddress())}/>

                    <div className="wizard-form-input map sub-section mb-3">
                        <GoogleMapReact defaultZoom={17}
                                        defaultCenter={{lat: 48.893610, lng: 2.330100}}
                                        bootstrapURLKeys={{key: 'AIzaSyDq3EfG7M6rh2ajWdFZ5axtrWU8efmxstI'}}
                                        center={{
                                            lat: Number(this.state.location.latitude),
                                            lng: Number(this.state.location.longitude)
                                        }}>

                            <Marker lat={Number(this.state.location.latitude)}
                                    lng={Number(this.state.location.longitude)}/>
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        )
    }

    getCoordinatesFromAddress(): void {
        if (this.state.location.address && this.state.location.city && this.state.location.zipCode && this.state.location.address.length > 0 && this.state.location.city.length > 0 && this.state.location.zipCode.length === 5) {
            Geocode.setApiKey("AIzaSyDq3EfG7M6rh2ajWdFZ5axtrWU8efmxstI");
            Geocode.setLanguage("fr");
            Geocode.setRegion("fr");
            Geocode.fromAddress(this.state.location.address + ', ' + this.state.location.city + ', ' + this.state.location.zipCode).then(
                (response) => {
                    const {lat, lng} = response.results[0].geometry.location;
                    this.setState({
                        location: {
                            ...this.state.location,
                            latitude : lat,
                            longitude: lng
                        }
                    }, () => this.props.raiseUpdate(this.state.location))
                }
            );
        } else
            this.props.raiseUpdate(this.state.location)
    }

    private updateField(field: KeyOf<BusinessRegistrationType>, value: ValueOf<BusinessRegistrationType>) {
        this.getCoordinatesFromAddress()
        switch (field) {
            default:
                store.dispatch(updateSingleField(field, value))
        }
    }
}
