import {
    LOAD_BUSINESS_BY_CODE,
    LOAD_BUSINESS_BY_CODE_FAILED,
    LOAD_BUSINESS_BY_CODE_SUCCEEDED,
    LoadBusinessByCodeActionTypes
} from "./actionTypes";
import { LoadBusinessState } from "../../configuration/state";

const initialState: LoadBusinessState = {
    loading : false,
    business: null,
    error   : undefined
}

export const loadBusinessByCodeReducer = (state = initialState, action: LoadBusinessByCodeActionTypes): LoadBusinessState => {
    switch (action.type) {
        case LOAD_BUSINESS_BY_CODE:
            return {
                loading : true,
                business: null,
                error   : undefined
            }
        case LOAD_BUSINESS_BY_CODE_SUCCEEDED:
            return {
                loading : false,
                business: action.payload,
                error   : undefined
            }
        case LOAD_BUSINESS_BY_CODE_FAILED:
            return {
                loading : false,
                business: null,
                error   : action.payload
            }
        default:
            return state
    }
}
