import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChatMessage } from '../../../domain/entities/chatMessage';
import { AppState } from "../../../../redux-configuration/AppState";
import { MessagingService } from "../../../domain/gateway/messaging.service";
import { LOAD_NEW_CHAT_MESSAGES, LoadNewChatMessagesAction } from "./actionTypes";
import { loadNewChatMessagesFailed, loadNewChatMessagesSucceeded } from "./actions";

const sortById = (chatMessages: ChatMessage[]): ChatMessage[] => chatMessages.sort(
    (item1: ChatMessage, item2: ChatMessage) => item1.id > item2.id ? -1 : 1)

export const loadNewChatMessagesEpic: Epic = (action$: ActionsObservable<LoadNewChatMessagesAction>, store$: StateObservable<AppState>,
                                              {messagingService}: { messagingService: MessagingService }) =>
    action$.pipe(
        ofType(LOAD_NEW_CHAT_MESSAGES),
        switchMap(
            action => messagingService.loadNewChatMessages(action.payload.conversationId, action.payload.messageId)
                .pipe(
                    map((chatMessages: ChatMessage[]) => loadNewChatMessagesSucceeded(sortById(chatMessages))),
                    catchError(error => of(loadNewChatMessagesFailed(error)))
                )
        )
    )
