import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { EDIT_COMPANY, EditCompanyActionType } from "./actionTypes";
import { CompanyService } from "../../domain/gateway/company.service";
import { Company } from "../../domain/entity/company";
import { editCompanyFailed, editCompanySucceeded } from "./actions";

export const editCompanyEpic: Epic = (action$: ActionsObservable<EditCompanyActionType>,
                                         store: StateObservable<AppState>,
                                         { companyService }: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(EDIT_COMPANY),
        switchMap((action: { payload: Company }) => companyService.editCompany(action.payload)
            .pipe(
                map(() =>
                    editCompanySucceeded()
                ),
                catchError(err => of(editCompanyFailed(err)))
            )
        )
    );
