import { EditCompanyState } from "../../configuration/state";
import {
    EDIT_COMPANY,
    EDIT_COMPANY_FAILED,
    EDIT_COMPANY_SUCCEEDED,
    EditCompanyActionTypes
} from "./actionTypes";

const initialState: EditCompanyState = {
    loading: false,
    success: null,
    error  : undefined
}

export const editCompanyReducer = (state = initialState, action: EditCompanyActionTypes): EditCompanyState => {
    switch (action.type) {
        case EDIT_COMPANY:
            return {
                loading: true,
                success: null,
                error  : undefined
            }
        case EDIT_COMPANY_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case EDIT_COMPANY_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
}
