import { CityHeader } from "../../domain/entity/cityHeader";

export const SEARCH_CITY = 'SEARCH_CITY'
export const SEARCH_CITY_SUCCEEDED = 'SEARCH_CITY_SUCCEEDED'
export const SEARCH_CITY_FAILED = 'SEARCH_CITY_FAILED'

export interface SearchCityAction {
    type: typeof SEARCH_CITY;
    payload: string;
}

export interface SearchCitySucceededAction {
    type: typeof SEARCH_CITY_SUCCEEDED;
    payload: CityHeader[] | null;
}

export interface SearchCityFailedAction {
    type: typeof SEARCH_CITY_FAILED;
    payload: string;
}

export type SearchCityActionTypes =
    SearchCityAction
    | SearchCitySucceededAction
    | SearchCityFailedAction
