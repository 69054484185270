import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../configuration/i18n";
import { TextInput } from "../../../../common/adapters/primaries/form/inputs/text.input";
import { SubmitButton } from "../../../../registration/adapters/primaries/registration/components/submit.button";

interface Props {
    phoneNumber: string;
    onPressBack: () => void;
    onPressNext: (step: number) => void;
    loading: boolean;
    error: string | undefined;
    hasBusiness: boolean | null;
    businessError: string | undefined;
    sendConfirmationCode: (phoneNumber: string, code: string) => void;
}

interface State {
    codeSms: string;
    errorMsg: string | undefined;
}

export class ConfirmationForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            codeSms : '',
            errorMsg: undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.hasBusiness && prevProps.hasBusiness !== this.props.hasBusiness) {
            this.props.onPressNext(3)
        }
        if (this.props.businessError && prevProps.businessError !== this.props.businessError) {
            this.props.onPressNext(4)
        }
        if (this.props.error && this.props.error !== prevProps.error) {
            this.setState({errorMsg: this.renderErrorMessage(this.props.error)})
        }
    }

    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        return (
            <div className="wizard-forms pb-4">
                {loader}
                <div className="inner clearfix mb-5 px-3">
                    <div className="wizard-title pt-5">
                        <h1 className="wizard-sub-text">{i18n.authentication.add_sms_code}</h1>
                    </div>

                    <div className="wizard-form-field">
                        <TextInput type={'text'} className={'registration-input'}
                                   maxLength={5}
                                   error={!!this.state.errorMsg}
                                   label={i18n.authentication.confirmationCode}
                                   value={this.state.codeSms}
                                   raiseUpdates={(codeSms: string) => this.setState({
                                       codeSms,
                                       errorMsg: undefined
                                   })}/>

                        <p className={'error'}>{this.state.errorMsg}</p>
                    </div>
                </div>

                <div className="px-3">
                    <SubmitButton submit={() => this.sendConfirmationCode()} hasBackButton
                                  goBack={() => this.props.onPressBack()}/>
                </div>
            </div>
        )
    }

    sendConfirmationCode(): void {
        if (this.state.codeSms.length === 5) {
            this.props.sendConfirmationCode(this.props.phoneNumber, this.state.codeSms)
        } else if (this.state.codeSms.length === 0) {
            this.setState({errorMsg: i18n.authentication.empty_code_error})
        } else {
            this.setState({errorMsg: i18n.authentication.code_error})
        }
    }

    renderErrorMessage(error: string): string {
        if (error === '403') {
            return i18n.authentication.contact_vivez_clichy
        } else {
            return i18n.authentication.enter_valid_code
        }
    }
}
