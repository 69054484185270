import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { registrationEpic } from "../registration/configuration/rootEpic";
import { registrationEpicDependencies } from "../registration/configuration/epic.dependencies";
import { AuthenticationEpicsDependencies } from "../authentication/configuration/dependencies.redux";
import { companyEpic } from "../company/configuration/rootEpic";
import { companyEpicDependencies } from "../company/configuration/epic.dependencies";
import { managementInstantRootEpics } from "../instant-management/configuration/rootEpics";
import { managamentInstantEpicDependencies } from "../instant-management/configuration/epic.dependencies";
import { statsEpicDependencies } from "../stats/configuration/epic.dependencies";
import { instantRootEpics } from "../instant/configuration/rootEpics";
import { instantEpicDependencies } from "../instant/configuration/epic.dependencies";
import { paymentRootEpics } from "../payment/configuration/rootEpics";
import { paymentEpicsDependencies } from "../payment/configuration/dependencies.redux";
import { messagingRootEpics } from "../messaging/configuration/rootEpics";
import { messagingEpicsDependencies } from "../messaging/configuration/dependencies.redux";
import { clickCollectRootEpics } from "../click-collect/configuration/rootEpic";
import { clickCollectEpicDependencies } from "../click-collect/configuration/epic.dependencies";
import { municipalityRootEpics } from "../municipality/configuration/rootEpic";
import { municipalityEpicsDependencies } from "../municipality/configuration/dependencies.redux";
import { authenticationRootEpic } from "../authentication/configuration/rootEpic";
import { statsRootEpics } from "../stats/configuration/rootEpics";
import { ecommerceRootEpic } from "../ecommerce/configuration/rootEpic";
import { ecommerceEpicDependencies } from "../ecommerce/configuration/epic.dependencies";

export const rootEpics = combineEpics(
    registrationEpic,
    companyEpic,
    authenticationRootEpic,
    managementInstantRootEpics,
    statsRootEpics,
    instantRootEpics,
    paymentRootEpics,
    messagingRootEpics,
    clickCollectRootEpics,
    municipalityRootEpics,
    ecommerceRootEpic
)

export const epicsMiddleware = createEpicMiddleware({
    dependencies: {
        ...registrationEpicDependencies,
        ...AuthenticationEpicsDependencies,
        ...companyEpicDependencies,
        ...managamentInstantEpicDependencies,
        ...statsEpicDependencies,
        ...instantEpicDependencies,
        ...paymentEpicsDependencies,
        ...messagingEpicsDependencies,
        ...clickCollectEpicDependencies,
        ...municipalityEpicsDependencies,
        ...ecommerceEpicDependencies
    }
})
