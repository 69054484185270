import { User } from "../../domain/entity/user";

export const LOAD_LOCAL_USER = 'LOAD_LOCAL_USER'
export const LOAD_LOCAL_USER_SUCCEEDED = 'LOAD_LOCAL_USER_SUCCEEDED'
export const LOAD_LOCAL_USER_FAILED = 'LOAD_LOCAL_USER_FAILED'

export interface LoadLocalUserAction {
    type: typeof LOAD_LOCAL_USER;
}

export interface LoadLocalUserSucceededAction {
    type: typeof LOAD_LOCAL_USER_SUCCEEDED;
    payload: User;
}

export interface LoadLocalUserFailedAction {
    type: typeof LOAD_LOCAL_USER_FAILED;
    payload: string;
}

export type LoadLocalUserActionType = LoadLocalUserAction

export type LoadLocalUserActionTypes =
    LoadLocalUserAction
    | LoadLocalUserFailedAction
    | LoadLocalUserSucceededAction
