import React, { PureComponent, ReactNode } from "react";
import { ChatMessage } from "../../../../domain/entities/chatMessage";
import { Order } from "../../../../../click-collect/domain/entities/order";
import { CancelOrderModal } from "../modal/cancelOrder.modal";
import { ValidateOrderModal } from "../modal/validateOrder.modal";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    message: ChatMessage;
    order: Order | null;
    cancellationSuccess: boolean;
    cancellationLoading: boolean;
    cancellationError: string | undefined;
    validationError: string | undefined;
    validationSuccess: string | undefined;
    validationLoading: boolean;
    loadOrder: (orderId: string) => void;
    validateOrder: (order: Order, total: string) => void;
    cancelOrder: (orderId: string, message: string) => void;
}

interface State {
    showValidationModal: boolean;
    showCancellationModal: boolean;
}

export class ClickCollectMessageItem extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showValidationModal  : false,
            showCancellationModal: false
        }
    }

    render(): ReactNode {
        return (
            <div className={'user-message'}>
                <p dangerouslySetInnerHTML={{ __html: this.getMessageContent() }} />

                {this.props.message.image && <img src={this.props.message.image}/>}

                <div className={'d-flex'}>
                    <button className={'cancel-btn'} onClick={() => this.setState({showCancellationModal: true})}>{i18n.messaging.cancel}</button>
                    <button className={'validate-btn'} onClick={() => this.loadOrder()}>{i18n.messaging.validate}</button>
                </div>

                <CancelOrderModal visible={this.state.showCancellationModal}
                                  cancelOrder={(message: string) => this.cancelOrder(message)}
                                  onClose={() => this.setState({showCancellationModal: false})}
                                  success={this.props.cancellationSuccess}
                                  loading={this.props.cancellationLoading}
                                  error={this.props.cancellationError}/>

                <ValidateOrderModal visible={this.state.showValidationModal}
                                    order={this.props.order}
                                    validateOrder={(order: Order, total: string) => this.props.validateOrder(order, total)}
                                    onClose={() => this.setState({showValidationModal: false})}
                                    success={this.props.validationSuccess}
                                    loading={this.props.validationLoading}
                                    error={this.props.validationError}/>
            </div>
        );
    }

    getMessageContent(): string {
        const index = this.props.message.content.indexOf('#markdown[')
        return this.props.message.content.slice(0, index)
    }

    loadOrder(): void {
        const orderId = this.getOrderId()
        this.props.loadOrder(orderId)
        this.setState({showValidationModal: true})
    }

    cancelOrder(message: string): void {
        const orderId = this.getOrderId()
        if (orderId.length > 0) {
            this.props.cancelOrder(orderId, message)
        }
    }

    getOrderId(): string {
        const index = this.props.message.content.indexOf('#markdown[')
        return this.props.message.content.slice(index)
            .replace('#markdown[action=order&orderId=', '')
            .replace(']', '')
    }
}
