import { i18n } from "../../../configuration/i18n";

export interface OpeningHoursType {
    id: string;
    daytype: string;
    startTime: string;
    endTime: string;
    isPaused: boolean;
}

type OpeningDayNamesType = {
    [key: string]: string
}

export const OpeningDayNames: OpeningDayNamesType = {
    all_days             : i18n.common.all_days,
    mondays_to_fridays   : i18n.common.mondays_to_fridays,
    tuesdays_to_fridays    : i18n.common.tuesdays_to_fridays,
    mondays_to_saturdays : i18n.common.mondays_to_saturdays,
    tuesdays_to_saturdays: i18n.common.tuesdays_to_saturdays,
    saturdays_and_sundays: i18n.common.saturdays_and_sundays,
    all_mondays          : i18n.common.mondays,
    all_tuesdays         : i18n.common.tuesdays,
    all_wednesdays       : i18n.common.wednesdays,
    all_thursdays        : i18n.common.thursdays,
    all_fridays          : i18n.common.fridays,
    all_saturdays        : i18n.common.saturdays,
    all_sundays          : i18n.common.sundays
}
