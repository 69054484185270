import React from "react";
import creditCard from '../../../../assets/img/credit-card-success.png'
import '../payment.css';
import { i18n } from "../../../../configuration/i18n";

interface Props {
    membership: string | undefined;
}

export const SuccessPaymentContent = (props: Props): JSX.Element => {
    return (
        <div className="container success-payment">
            <div className="row align-items-center h-100">
                <div className="col-12 col-md-6 col-xl-5 success-content">
                    <i className="fa fa-check"/>
                    <h2 className="success-title">{i18n.payment.yes_payment_succeeded}</h2>
                    <p>{i18n.payment.congrats} {props.membership}</p>
                    <a href={'/company'}>{i18n.payment.go_to_profile}</a>
                </div>

                <div className="col-md-6 col-xl-7 success-img">
                    <img src={creditCard} alt="success payment" className="credit-card-img"/>
                </div>
            </div>
        </div>
    )
}
