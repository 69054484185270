import { ApiPaymentService } from "../adapters/secondaries/real/ApiPaymentService";
import { PaymentService } from "../domain/gateway/paymentService";

export class PaymentDependenciesFactory {

    static getPaymentService(): PaymentService {
        switch (process.env.NODE_ENV) {
            case 'production':
            case 'development':
                return new ApiPaymentService()
            default:
                return new ApiPaymentService()
        }
    }
}
