import React, { useEffect, useState } from "react";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { i18n } from "../../../../configuration/i18n";
import { useParams } from "react-router-dom";
import { Company } from "../../../../company/domain/entity/company";
import { EcommerceOrder } from "../../../domain/entities/ecommerceOrder";

interface Props {
    company: Company | null;
    orders: EcommerceOrder[] | undefined;
    loading: boolean;
    error: string | undefined;
    loadOrders: (companyId: string) => void;
}

export const EcommerceOrderDetailsContainer = (props: Props): JSX.Element => {
    const params: { id: string } = useParams();

    const [orderDetails, setOrderDetails] = useState<
        EcommerceOrder | undefined
    >(undefined);

    const getOrderDetails = () => {
        const order = props.orders?.find(
            (item: EcommerceOrder) => item.id.toString() === params.id
        );
        if (order) {
            setOrderDetails(order);
        }
    };

    useEffect(() => {
        document.title = i18n.ecommerce.order_details_page_title;
        getOrderDetails();
    }, []);

    useEffect(() => {
        if (!props.orders && props.company) {
            props.loadOrders(props.company.id);
        }
    }, [props.company]);

    useEffect(() => {
        getOrderDetails();
    }, [props.orders]);

    const loader = props.loading && (
        <div className={"show-loader"}>
            <div className={"loader"} />
        </div>
    );

    const euro = Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
    });

    return (
        <div>
            <DefaultPageHeader title={i18n.ecommerce.order_details} />

            {loader}
            {orderDetails && (
                <div className="container-fluid custom-width-1260">
                    <div className="row">
                        <div className="col-6 my-3">
                            <div className="transaction-info h-100">
                                <h3>
                                    {orderDetails.products
                                        .map((item) => item.name)
                                        .join(", ")}
                                </h3>
                                <h4>{euro.format(orderDetails.price)}</h4>
                            </div>
                        </div>

                        <div className="col-6 my-3">
                            <div className="transaction-info h-100">
                                <h3>Acheteur</h3>
                                <div className="d-flex">
                                    <b>Nom</b>
                                    <b>: {orderDetails.buyer.name}</b>
                                </div>
                                <div className="d-flex">
                                    <b>Téléphone</b>
                                    <b>: {orderDetails.buyer.phone}</b>
                                </div>
                                <div className="d-flex">
                                    <b>Email</b>
                                    <b>: {orderDetails.buyer.email}</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"transaction-iframe"}>
                        <iframe
                            src={orderDetails.eventUrl}
                            title={"Détails de la commande"}
                            width={"100%"}
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    );
};
