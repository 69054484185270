import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
    UPDATE_INSTANT_STATUS,
    updateInstantStatusFailed,
    updateInstantStatusSucceeded
} from './updateInstantStatus.actions';
import { AppState } from "../../../redux-configuration/AppState";
import { UpdateInstantStatusAction } from "./updateInstantStatus.types";
import { ApplicationContext } from "../../../configuration/application.context";
import { ManagementInstant } from "../../domain/entities/ManagementInstant";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";
import { ManagementInstantBuilder } from "../../domain/entities/managementInstantBuilder";
import { ManagementInstantActivationTime } from "../../domain/entities/managementInstantActivationTime";

const moment = ApplicationContext.getInstance().momentJs()

export const updateInstantStatusEpic: Epic = (action$: ActionsObservable<UpdateInstantStatusAction>,
                                              store: StateObservable<AppState>,
                                              { instantManagementService }: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(UPDATE_INSTANT_STATUS),
        switchMap(action => instantManagementService.loadManagementInstantById(action.payload.instantId)
            .pipe(
                mergeMap((instant: ManagementInstant) => instantManagementService.editRemoteInstant(updateInstant(instant, action.payload.status))
                    .pipe(
                        map(() => updateInstantStatusSucceeded()),
                        catchError(error => of(updateInstantStatusFailed(error)))
                    )
                ),
                catchError(error => of(updateInstantStatusFailed(error)))
            )
        )
    )

const updateInstant = (instant: ManagementInstant, status: boolean): ManagementInstant => {

    const instantActivationTimes = instant.instantActivationTimes.map((item: ManagementInstantActivationTime) => {
        return new ManagementInstantActivationTime(
            item.id,
            item.DelayType,
            item.startDateTime,
            item.endDateTime,
            status,
            item.activationDayType
        )
    })
    const notificationDate = getNotificationDate(instant.notificationDate, instant.notificationSent)

    return new ManagementInstantBuilder()
        .withId(instant.instantInformation.id)
        .withBusinessId(instant.instantInformation.businessId)
        .withTitle(instant.instantInformation.title)
        .withShortDescription(instant.instantInformation.shortDescription)
        .withLongDescription(instant.instantInformation.longDescription)
        .withCategory(instant.instantInformation.category)
        .withAddress(instant.address.address)
        .withCity(instant.address.city)
        .withZipcode(instant.address.zipCode)
        .withActivationTime(instantActivationTimes)
        .withNotificationSent(instant.notificationSent)
        .withNotificationDate(notificationDate)
        .withInstantStatus(status)
        .build()
}

const getNotificationDate = (notificationDate: string, notificationSent: boolean | undefined): string => {
    return notificationSent ? undefined :
        notificationDate ? moment(notificationDate, 'YYYY-MM-DD HH:mm').format() : undefined
}
