import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { ManagementInstantFormType } from "../type/managementInstantFormType";
import { ActivationTimeType, Schedule } from "../../../../domain/entities/types/activationTimeType";
import { FixedScheduleContainer } from "../components/fixedSchedule/fixedSchedule.container";
import { RecurrentScheduleContainer } from "../components/recurrentSchedule/recurrentSchedule.container";
import { CompanyOpeningHours } from "../../../../../company/domain/entity/companyOpeningHours";

interface Props {
    instant: ManagementInstantFormType;
    companyOpeningHours: CompanyOpeningHours[] | undefined
    raiseUpdate: (key: keyof ManagementInstantFormType, value: ActivationTimeType | undefined) => void
    hasError: boolean
}

interface State {
    fixedSchedule: Schedule | undefined;
    recurrentSchedule: Schedule[];
    index: number;
}

export class InstantPlaningSection extends PureComponent<Props, State> {

    private fixedSchedule: Schedule | undefined
    private recurrentSchedule: Schedule[]

    constructor(props: Props) {
        super(props)
        if (this.props.instant.activationTime) {
            if (this.props.instant.activationTime.key === 'fixed') {
                this.recurrentSchedule = []
                if (Array.isArray(this.props.instant.activationTime.schedule))
                    this.fixedSchedule = this.props.instant.activationTime.schedule[0]
                else
                    this.fixedSchedule = this.props.instant.activationTime.schedule
            } else {
                this.fixedSchedule = undefined
                if (Array.isArray(this.props.instant.activationTime.schedule))
                    this.recurrentSchedule = this.props.instant.activationTime.schedule
                else
                    this.recurrentSchedule = []
            }
        }
        this.state = {
            index            : 0,
            fixedSchedule    : this.fixedSchedule,
            recurrentSchedule: this.recurrentSchedule
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.instant && this.props.instant !== prevProps.instant) {
            if (this.props.instant.activationTime) {
                if (this.props.instant.activationTime.key === 'fixed') {
                    this.recurrentSchedule = []
                    if (Array.isArray(this.props.instant.activationTime.schedule))
                        this.fixedSchedule = this.props.instant.activationTime.schedule[0]
                    else
                        this.fixedSchedule = this.props.instant.activationTime.schedule
                } else {
                    this.fixedSchedule = undefined
                    if (Array.isArray(this.props.instant.activationTime.schedule))
                        this.recurrentSchedule = this.props.instant.activationTime.schedule
                    else
                        this.recurrentSchedule = []
                }
            }
            this.setState({
                fixedSchedule    : this.fixedSchedule,
                recurrentSchedule: this.recurrentSchedule,
                index            : this.fixedSchedule !== undefined ? 1 : 0
            })
        }
    }

    render(): ReactNode {
        const error = this.props.hasError && this.isScheduleEmpty() &&
            <p className={'error'}>{i18n.management_instant.schedule_format_error}</p>

        return (
            <div className={"opening-hours-block section_pro"}>
                <span className="wizard-sub-text">{i18n.management_instant.activationTime_sub_title}</span>

                <div className={'px-3'}>
                    <div className="tab sub-section">
                        <button className={this.renderClassNameTab(0)}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.setState({index: 0})
                                }}>{i18n.management_instant.recurrent}</button>
                        <button className={this.renderClassNameTab(1)}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.setState({index: 1})
                                }}>{i18n.management_instant.fixed}</button>
                    </div>

                    <div id="recurrent" className="tabcontent" style={this.displayTab(0)}>
                        <RecurrentScheduleContainer schedules={this.state.recurrentSchedule}
                                                    companyOpeningHours={this.props.companyOpeningHours}
                                                    cleanAllSchedules={() => this.cleanAllSchedules()}
                                                    raiseUpdate={(schedules: Schedule[]) => this.updateRecurrentSchedule(schedules)}/>
                    </div>

                    <div id="fixed" className="tabcontent" style={this.displayTab(1)}>
                        <FixedScheduleContainer schedule={this.state.fixedSchedule}
                                                raiseUpdate={(schedule: Schedule | undefined) => this.updateFixedSchedule(schedule)}
                                                isSubmitted={false}/>
                    </div>

                    {error}
                </div>
            </div>
        )
    }

    renderClassNameTab(index: number): string {
        if (this.state.index === index)
            return 'btn-tab active'
        else
            return 'btn-tab'
    }

    displayTab(index: number): Object {
        if (this.state.index === index)
            return {display: 'block'}
        else
            return {display: 'none'}
    }

    updateFixedSchedule(schedule: Schedule | undefined): void {
        this.setState({fixedSchedule: schedule})
        if (schedule)
            this.props.raiseUpdate('activationTime', {
                key     : 'fixed',
                schedule: [schedule]
            })
        else
            this.props.raiseUpdate('activationTime', {
                key     : 'fixed',
                schedule: undefined
            })
    }

    updateRecurrentSchedule(schedules: Schedule[]): void {
        this.setState({recurrentSchedule: schedules})
        this.props.raiseUpdate('activationTime', {
            key     : 'recurrent',
            schedule: schedules
        })
    }

    isScheduleEmpty(): boolean {
        return (!this.state.fixedSchedule && (!this.state.recurrentSchedule || this.state.recurrentSchedule.length === 0))
    }

    cleanAllSchedules(): void {
        this.setState({recurrentSchedule: []})
        this.props.raiseUpdate('activationTime', {
            key     : 'recurrent',
            schedule: []
        })
    }
}
