import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { CropImageModal } from "../../modal/cropImageModal";
import Resizer from "react-image-file-resizer";

interface Props {
    label: string;
    subtitle?: string;
    image: File | undefined;
    imageUrl: string | undefined;
    imageWidth: number;
    imageHeight: number;
    raiseUpdate: (image: File | undefined, imageUrl: string | undefined) => void;
    isSubmitted: boolean;
    className?: string
}

interface State {
    image: File | undefined;
    imageUrl: string | undefined;
    imageName: string | undefined;
    imageError: boolean;
    isVisible: boolean;
    errorMessage: string;
}

export class ImageCropInput extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            image       : this.props.image,
            imageUrl    : this.props.imageUrl,
            imageName   : undefined,
            imageError  : false,
            isVisible   : false,
            errorMessage: ''
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.image && this.props.image !== prevProps.image)
            this.setState({
                image   : this.props.image,
                imageUrl: this.props.imageUrl
            })

        if (this.props.isSubmitted && this.props.isSubmitted !== prevProps.isSubmitted)
            this.setState({
                imageError: !this.state.image && this.state.imageUrl === ''
            })
    }

    render(): ReactNode {
        const errorMessage = this.state.errorMessage !== '' &&
            <p className={'error image-crop-error'}>{this.state.errorMessage}</p>

        const className = this.state.imageError ? 'error custom-file' : 'custom-file'
        const classNameContainer = this.props.className ? this.props.className + ' wizard-document-upload wizard-form-input' : 'wizard-document-upload wizard-form-input'

        return (
            <div>
                <div className={classNameContainer}>
                    <label>{this.props.label}
                        <span className={'subtitle_image'}>{this.props.subtitle}</span>
                    </label>
                    <div className={className}>
                        <input type="file"
                               id="customFile"
                               className="custom-file-input"
                               onChange={e => {
                                   if (e.target.files)
                                       this.onChange(e.target.files[0])
                               }}/>

                        {this.renderContent()}

                        {errorMessage}
                    </div>
                </div>

                {this.renderModal()}
            </div>
        )
    }

    renderContent(): JSX.Element {
        if (this.state.imageUrl)
            return (
                <label className={'custom-file-label active'} style={{backgroundColor: '#D9D9D9'}}
                       htmlFor="customFile">
                    <img className={'picture-uploaded'} src={this.state.imageUrl}/>
                </label>
            )
        else
            return (
                <label className={'custom-file-label'} htmlFor="customFile">
                    <span>{i18n.registration.image_placeholder}</span>
                </label>
            )
    }

    onChange(image: File | undefined): void {
        if (image !== undefined)
            this.checkFileSize(image).then(result => {
                if (result)
                    this.setState({
                        image,
                        imageUrl  : image && URL.createObjectURL(image),
                        imageName : image && image.name,
                        imageError: false,
                        isVisible : true
                    })
                else
                    this.setState({errorMessage: i18n.common.error_size_picture(this.props.imageWidth, this.props.imageHeight)})
            })
    }

    checkFileSize(image: File): Promise<boolean> {
        const promiseCheck = new Promise<boolean>((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(image);
            img.onload = () => {
                URL.revokeObjectURL(objectUrl);
                resolve(img.width >= this.props.imageWidth && img.height >= this.props.imageHeight)
            }
            img.src = objectUrl
        })
        return promiseCheck
    }

    renderModal(): JSX.Element | undefined {
        if (this.state.imageUrl && this.state.isVisible && this.state.imageName)
            return (
                <CropImageModal url={this.state.imageUrl}
                                name={this.state.imageName}
                                width={this.props.imageWidth}
                                height={this.props.imageHeight}
                                crop={(image: File) => {
                                    this.setState({
                                        image,
                                        imageUrl    : image && URL.createObjectURL(image),
                                        imageError  : false,
                                        isVisible   : false,
                                        errorMessage: ''
                                    }, () => this.resizeImage(image, this.props.imageWidth, this.props.imageHeight))
                                }}
                                close={() => this.setState({
                                    isVisible: false,
                                    imageUrl : '',
                                    image    : undefined
                                }, () => this.props.raiseUpdate(this.state.image, this.state.imageUrl))}/>
            )
    }

    resizeImage(picture: File, width: number, height: number): void {
        Resizer.imageFileResizer(picture, width, height, "JPEG", 100, 0,
            (result) => {
                fetch(result.toString())
                    .then(res => res.blob())
                    .then(blob => {
                        const file = new File([blob], picture.name, {type: "image/png"})
                        this.setState({
                            image     : file,
                            imageUrl  : file && URL.createObjectURL(file),
                            imageName : file && file.name,
                            imageError: false,
                            isVisible : false
                        }, () => this.props.raiseUpdate(this.state.image, this.state.imageUrl))
                    })
            })
    }
}
