import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LOAD_COMPANY_DETAILS, LoadCompanyDetailsActionType } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { CompanyService } from "../../domain/gateway/company.service";
import { Company } from "../../domain/entity/company";
import { loadCompanyDetailsFailed, loadCompanyDetailsSucceeded } from "./actions";
import { concatMap } from "rxjs/internal/operators";
import { updateStoreCompany } from "../updateStoreCompany/action";
import {
    loadInstantsCompany
} from "../../../instant-management/usecases/loadInstantsCompany/loadInstantsCompany.actions";
import { setRegistrationStep } from "../../../registration/usecases/manageRegistrationStep/action";

export const loadCompanyDetailsEpic: Epic = (action$: ActionsObservable<LoadCompanyDetailsActionType>,
                                             store: StateObservable<AppState>,
                                             {companyService}: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(LOAD_COMPANY_DETAILS),
        switchMap((action: { payload: string }) => companyService.getCompanyDetails(action.payload)
            .pipe(
                concatMap((company: Company) => {
                        const step = guessStep(company)

                        return [
                            setRegistrationStep(step),
                            loadCompanyDetailsSucceeded(company),
                            updateStoreCompany(company),
                            loadInstantsCompany(company.id)
                        ]
                    }
                ),
                catchError(err => of(loadCompanyDetailsFailed(err)))
            )
        )
    )

const guessStep = (business: Company): 2 | 3 => {
    if (business.profileImageUrl !== '' &&
        business.coverImageUrl !== '' &&
        business.category !== null &&
        business.contacts.description !== '' &&
        business.contacts.phoneNumber !== '' &&
        business.contacts.email !== null) {
        return 3
    }
    return 2
}
