import { MunicipalService } from "../../domain/entities/municipalService";

export const ADD_MUNICIPAL_SERVICE = 'ADD_MUNICIPAL_SERVICE'
export const ADD_MUNICIPAL_SERVICE_FAILED = 'ADD_MUNICIPAL_SERVICE_FAILED'
export const ADD_MUNICIPAL_SERVICE_SUCCEEDED = 'ADD_MUNICIPAL_SERVICE_SUCCEEDED'

export interface AddMunicipalServiceAction {
    type: typeof ADD_MUNICIPAL_SERVICE;
    payload:{service: MunicipalService, cityId: string}
}

export interface AddMunicipalServiceFailedAction {
    type: typeof ADD_MUNICIPAL_SERVICE_FAILED;
    payload: string
}

export interface AddMunicipalServiceSucceededAction {
    type: typeof ADD_MUNICIPAL_SERVICE_SUCCEEDED;
}

export type AddMunicipalServiceActionTypes = AddMunicipalServiceAction | AddMunicipalServiceFailedAction | AddMunicipalServiceSucceededAction
