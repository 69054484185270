import {
    LOAD_BUSINESS_BY_CODE,
    LOAD_BUSINESS_BY_CODE_FAILED,
    LOAD_BUSINESS_BY_CODE_SUCCEEDED,
    LoadBusinessByCodeActionTypes
} from "./actionTypes";
import { Company } from "../../../company/domain/entity/company";

export const loadBusinessByCode = (code: string, stringifiedInputs: string): LoadBusinessByCodeActionTypes => ({
    type   : LOAD_BUSINESS_BY_CODE,
    payload: {code, stringifiedInputs}
})

export const loadBusinessByCodeSucceeded = (business: Company): LoadBusinessByCodeActionTypes => ({
    type   : LOAD_BUSINESS_BY_CODE_SUCCEEDED,
    payload: business
})

export const loadBusinessByCodeFailed = (error: string): LoadBusinessByCodeActionTypes => ({
    type   : LOAD_BUSINESS_BY_CODE_FAILED,
    payload: error
})
