import { combineReducers } from "redux";
import { MunicipalityState } from "./state";
import { listingMunicipalServicesReducer } from "../usecases/listingMunicipalServices/reducer";
import { addMunicipalServiceReducer } from "../usecases/addMunicipalService/reducer";
import { deleteMunicipalityServiceReducer } from "../usecases/delete/reducer";
import { editMunicipalServiceReducer } from "../usecases/editMunicipalService/reducer";
import { changeServicesOrderReducer } from "../usecases/changeOrder/reducer";

export const municipalityRootReducer = combineReducers<MunicipalityState>({
    listingServices: listingMunicipalServicesReducer,
    addService     : addMunicipalServiceReducer,
    deleteService  : deleteMunicipalityServiceReducer,
    changeOrder    : changeServicesOrderReducer,
    editService    : editMunicipalServiceReducer
})
