export class CompanyLegalInfo {

    constructor(
        protected _companyName: string,
        protected _registeredName: string,
        protected _legalStatus: string,
        protected _capitalAmount: string,
        protected _address: string,
        protected _phoneNumber: string,
        protected _email: string,
        protected _siret: string,
        protected _tva: string,
        protected _others: string
    ) {
    }

    get companyName(): string {
        return this._companyName;
    }

    get registeredName(): string {
        return this._registeredName;
    }

    get legalStatus(): string {
        return this._legalStatus;
    }

    get capitalAmount(): string {
        return this._capitalAmount;
    }

    get address(): string {
        return this._address;
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    get email(): string {
        return this._email;
    }

    get siret(): string {
        return this._siret;
    }

    get tva(): string {
        return this._tva;
    }

    get others(): string {
        return this._others;
    }
}
