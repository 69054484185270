import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { searchCityFailed, searchCitySucceeded } from './actions';
import { SEARCH_CITY, SearchCityAction } from "./actionTypes";
import { Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { CityService } from "../../domain/gateway/city.service";
import { CityHeader } from "../../domain/entity/cityHeader";

export const searchCityEpic: Epic = (action$: Observable<SearchCityAction>,
                                            store: StateObservable<AppState>,
                                            {cityService}: { cityService: CityService }) =>
    action$
        .pipe(
        ofType(SEARCH_CITY),
        switchMap(action => cityService.searchCity(action.payload)
            .pipe(
                map((cities: CityHeader[]) => searchCitySucceeded(cities)),
                catchError(error => of(searchCityFailed(error)))
            )
        )
    )
