export class CartesianData {

    constructor(
        private _xValue: string,
        private _yValue: number) {
    }

    get xValue(): string {
        return this._xValue;
    }

    get yValue(): number {
        return this._yValue;
    }
}
