import React, { PureComponent, ReactNode } from "react";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";
import { i18n } from "../../../../../../configuration/i18n";
import { FormLegalInfoStateType } from "../types";
import { FormValidation } from "../../../../../../common/adapters/primaries/form/validation";
import { Company } from "../../../../../domain/entity/company";
import { CompanySubdomainInput } from "../subdomain.input";

interface Props {
    legalInfo: FormLegalInfoStateType;
    isSubmitted: boolean;
    company: Company
    raiseUpdates: (key: keyof FormLegalInfoStateType, value: string) => void;
}

export class CompanyWebSiteForm extends PureComponent<Props> {

    render(): ReactNode {

        const companyTypeLabel = this.props.company.type === 'association' ? 'L`association' : this.props.company.type === 'mairie' ? 'La mairie' : 'La société'
        const defaultLegalStatus = this.props.company.type === 'association' ? i18n.company.asso_legal_text : ''
        const administrativeNumberLabel = this.props.company.type === 'association' ? i18n.company.rna_number : i18n.company.siret_number

        return (
            <div className={'website-form pl-sm-4 ml-sm-5 mb-4 py-3'}>
                <CompanySubdomainInput value={this.props.legalInfo.subdomain}
                                       error={this.props.isSubmitted && !FormValidation.validatePremiumSubdomain(this.props.legalInfo.subdomain)}
                                       raiseUpdates={(value: string) => this.props.raiseUpdates('subdomain', value)}/>

                <div className={'legal-info d-flex justify-content-between mb-4'}>
                    <p>Les mentions légales :</p>
                    <i className="fa fa-angle-down"/>
                </div>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && this.props.legalInfo.companyName.length < 2}
                           label={companyTypeLabel}
                           value={this.props.legalInfo.companyName || this.props.company.name}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('companyName', value)}/>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && this.props.legalInfo.legalStatus.length < 2}
                           label={i18n.company.legal_form}
                           value={this.props.legalInfo.legalStatus || defaultLegalStatus}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('legalStatus', value)}/>

                <TextInput type={'numeric'}
                           error={this.props.isSubmitted && !FormValidation.validateNumber(this.props.legalInfo.capitalAmount)}
                           label={i18n.company.capital}
                           value={this.props.legalInfo.capitalAmount}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('capitalAmount', value)}/>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && this.props.legalInfo.address.length < 2}
                           label={i18n.company.legal_address}
                           value={this.props.legalInfo.address || this.props.company.address.fullAddress}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('address', value)}/>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && !FormValidation.validatePhoneNumberFix(this.props.legalInfo.phoneNumber)}
                           label={i18n.company.legal_phone_number}
                           value={this.props.legalInfo.phoneNumber || this.props.company.contacts.phoneNumber}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('phoneNumber', value)}/>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && !FormValidation.validateEmail(this.props.legalInfo.email)}
                           label={i18n.company.legal_email}
                           value={this.props.legalInfo.email || this.props.company.contacts.email}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('email', value)}/>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && (this.props.legalInfo.siret.length !== 14 || !FormValidation.validateNumber(this.props.legalInfo.siret))}
                           label={administrativeNumberLabel}
                           value={this.props.legalInfo.siret || this.props.company.siren}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('siret', value)}/>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && this.props.legalInfo.tva.length < 2}
                           label={i18n.company.tva_number}
                           value={this.props.legalInfo.tva}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('tva', value)}/>

                <TextInput type={'text'}
                           error={this.props.isSubmitted && this.props.legalInfo.registeredName.length < 2}
                           label={i18n.company.immatricule}
                           value={this.props.legalInfo.registeredName}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('registeredName', value)}/>

                <TextInput type={'text'}
                           error={false}
                           label={i18n.company.others}
                           value={this.props.legalInfo.others}
                           raiseUpdates={(value: string) => this.props.raiseUpdates('others', value)}/>
            </div>
        )
    }
}
