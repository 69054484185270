import React, { PureComponent, ReactNode } from "react";
import "./success.css";
import { i18n } from "../../../../../../configuration/i18n";
import { SubscriptionTable } from "../../../../../../payment/adapters/primaries/subscription/subscriptionTable";
import { SubscriptionId } from "../../../../../../payment/domain/entities/types/subscription.type";
import { DescriptionTypeInstant } from "./descriptionTypeInstant";
import { CreateInstantFormModal } from "../../../../../../instant-management/adapters/primaries/form/components/createInstantForm.modal";
import { Company } from "../../../../../../company/domain/entity/company";
import { InstantManagementSpecifications } from "../../../../../../instant-management/adapters/primaries/specifications";
import { ManagementInstantHeader } from "../../../../../../instant-management/domain/entities/managementInstantHeader";

interface Props {
    type: string | undefined
    business: Company | null;
    instants: ManagementInstantHeader[] | null;
}

interface State {
    displaySubscription: boolean;
    displayForm: boolean;
    formType: 'event' | 'special_offer' | 'service'
    title_form: string;
}

export class SuccessContainer extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            displaySubscription: false,
            displayForm        : false,
            title_form         : '',
            formType           : 'event'
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
    }

    render(): ReactNode {
        return (
            <div className="success">
                <div className="content">
                    <div className='title'>{i18n.registration.subtitle_success_inscription(this.props.type)}</div>

                    <div className={'sub-section description header_description'}
                         dangerouslySetInnerHTML={{__html: i18n.registration.description_step_3(this.props.type)}}>
                    </div>

                    <DescriptionTypeInstant description={i18n.registration.description_service(this.props.type)}
                                            labelButton={this.canAddService() ? i18n.registration.add_service : i18n.registration.service_added}
                                            canAdd={this.canAddService()}
                                            onClick={() => this.addInstant('service', i18n.registration.add_service)}/>

                    <DescriptionTypeInstant description={i18n.registration.description_special_offer(this.props.type)}
                                            labelButton={this.canAddSpecialOffer() ? i18n.registration.add_special_offer : i18n.registration.special_offer_added}
                                            canAdd={this.canAddSpecialOffer()}
                                            onClick={() => this.addInstant('special_offer', i18n.registration.add_special_offer)}/>

                    <DescriptionTypeInstant description={i18n.registration.description_event}
                                            labelButton={this.canAddEvent() ? i18n.registration.add_event : i18n.registration.event_added}
                                            canAdd={this.canAddEvent()}
                                            onClick={() => this.addInstant('event', i18n.registration.add_event)}/>

                    <div className={'sub-section description'}
                         dangerouslySetInnerHTML={{__html: i18n.registration.description_notification_step_3(this.props.type)}}>
                    </div>

                    <a href="/company" className={'nav-btn'}>{i18n.registration.show_profile_pro}</a>

                    <div className={'sub-section description'}
                         dangerouslySetInnerHTML={{__html: i18n.registration.description_subscription_step_3}}>
                    </div>

                    {this.props.type === 'association' && (
                        <div className={'sub-section description'}
                             dangerouslySetInnerHTML={{__html: i18n.registration.description_subscription_association}}>
                        </div>
                    )}

                    <CreateInstantFormModal visible={this.state.displayForm}
                                            form={this.state.formType}
                                            titleForm={this.state.title_form}
                                            onClose={() => this.setState({displayForm: false})}/>
                </div>

                {this.props.business?.type !== 'mairie' ?
                    <div className={'col-md-12 subscription_registration'}>
                    <div style={{paddingTop: 15}}>
                        <a className={this.state.displaySubscription ? 'btn_pro_subscription open' : 'btn_pro_subscription'}
                           onClick={() => this.setState({displaySubscription: !this.state.displaySubscription})}>
                            {i18n.registration.display_subscription_offer_link}
                            <i className={this.state.displaySubscription ? "fas fa-chevron-down" : "fas fa-chevron-right"}/>
                        </a>
                    </div>

                    {this.state.displaySubscription &&
                        <div className={"subscription-accordion"}><SubscriptionTable preferredOffer={SubscriptionId.PREMIUM}/></div>}
                </div> : null}
            </div>
        )
    }

    canAddEvent(): boolean {
        if (this.props.instants) {
            const spec = new InstantManagementSpecifications(this.props.instants)
            return InstantManagementSpecifications.canAddInstant(spec.events, this.props.business?.membership.allowedInstants ?? 0)
        }
        return false
    }

    canAddService(): boolean {
        if (this.props.instants) {
            const spec = new InstantManagementSpecifications(this.props.instants)
            return (InstantManagementSpecifications.canAddInstant(spec.services, this.props.business?.membership.allowedInstants ?? 0))
        }
        return false
    }

    canAddSpecialOffer(): boolean {
        if (this.props.instants) {
            const spec = new InstantManagementSpecifications(this.props.instants)
            return (InstantManagementSpecifications.canAddInstant(spec.specialOffers, this.props.business?.membership.allowedInstants ?? 0))
        }
        return false
    }

    addInstant(type: 'event' | 'special_offer' | 'service', title: string): void {
        this.setState({
            displayForm: true,
            formType   : type,
            title_form : title
        })
    }

}
