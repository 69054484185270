import React, { PureComponent, ReactNode } from 'react'
import { subscriptionProData } from "../../../data/subscriptionProData";
import { SubscriptionProperty } from "../../../../../domain/entities/types/subscription.type";

interface Props{
    preferredOffer: 'basic' | 'premium' | 'digital'
}
export class SubscriptionsProBodyTable extends PureComponent<Props>{
    render(): ReactNode{
        const propertiesSubscription = subscriptionProData[ 0 ].properties
        return (
            <>
            {propertiesSubscription.map((item: SubscriptionProperty, i: number) => {
                return (<tr key={item.label} className={i%2 === 0 ? "active": "info"}>
                        <th scope="row">
                            {item.label}
                        </th>
                        <td className={this.props.preferredOffer === subscriptionProData[ 0 ].id ? 'best_offer' : undefined}>

                            {this.renderProperty(subscriptionProData[ 0 ].properties[ i ])}
                        </td>
                        <td className={this.props.preferredOffer === subscriptionProData[ 1 ].id ? 'best_offer' : undefined}>
                            {this.renderProperty(subscriptionProData[ 1 ].properties[ i ])}</td>
                        <td className={this.props.preferredOffer === subscriptionProData[ 2 ].id ? 'best_offer' : undefined}>
                            {this.renderProperty(subscriptionProData[ 2 ].properties[ i ])}</td>
                    </tr>
                )
            })
            }
            </>
        )
    }
    renderProperty(property: SubscriptionProperty): JSX.Element {
        return property.value === true ?
            property.id ==='support' ?
                <div className={'properties_success'}><i className='fas fa-envelope mr-4'/>
                    <i className='fas fa-phone mr-4'/>
                    <i className='fas fa-user'/>
                </div> :
            <div className="check-mark">
            <i className="fas fa-check"/>
        </div> : property.value === false ?
            property.id ==='support' ?
                <div><i className='fas fa-envelope'/></div>
           : <div><i className='fas fa-times'/></div> :
            <div className={'label'}>{property.value}</div>
    }
}
