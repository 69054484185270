export const CommonI18n = {
    profile                      : 'Mon profil',
    instants                     : 'Mes instants',
    stats                        : 'Statistiques',
    agenda                       : 'Agenda',
    services                     : 'Services',
    subscription                 : 'Abonnement',
    messages                     : 'Messages',
    products                     : 'Produits',
    orders                       : 'Mes commandes',
    all_products                 : 'Tous les produits',
    add_product                  : 'Ajouter un produit',
    edit_product                 : 'Modifier un produit',
    qrcode                       : 'Marketing',
    emails_pro                   : 'Emails Pro',
    myStats                      : 'Mes statistiques',
    profile_description          : 'Personnalisez votre compte pro',
    location_description         : 'Adresse de l\'établissement',
    opening_hours_description    : 'Gérez vos horaires d\'ouverture : ',
    opening_hours_error          : 'Veuiller entrer un créneau',
    opening_hours_sub_description: 'Choisissez la fréquence puis indiquez l\'heure d\'ouverture et de fermeture, avant de cliquer sur Ajouter (vous pouvez ajouter plusieurs créneaux).',
    contact_description          : (type: string): string => `Présentez votre ${type === 'association' ? 'association' : 'établissement'} et ajoutez vos informations de contact :`,
    profile_picture              : 'Photo de profil ou logo (Carré)*',
    cover_picture                : 'Photo de couverture (paysage)*',
    business_name                : 'Nom de l\'établissement*',
    openingHours                 : 'Fréquence du créneau*',
    from                         : 'Ouvert de',
    to                           : 'à',
    add                          : 'Ajout le créneau ',
    street                       : 'Rue*',
    city                         : 'Ville*',
    zipCode                      : 'Code postal*',
    category                     : 'Catégorie*',
    description                  : 'Description détaillée*',
    website                      : 'Site internet*',
    email                        : 'Email*',
    phoneNumber                  : 'Téléphone portable*',
    crop                         : 'Crop',
    crop_your_image              : 'Recadrez votre image',
    all_days                     : 'Tous les jours',
    mondays_to_fridays           : 'Du Lundi au Vendredi',
    tuesdays_to_fridays          : 'Du Mardi au Vendredi',
    mondays_to_saturdays         : 'Du Lundi au Samedi',
    tuesdays_to_saturdays        : 'Du Mardi au Samedi',
    saturdays_and_sundays        : 'Samedi et Dimanche',
    mondays                      : 'Tous les Lundis',
    tuesdays                     : 'Tous les Mardis',
    wednesdays                   : 'Tous les Mercredis',
    thursdays                    : 'Tous les Jeudis',
    fridays                      : 'Tous les Vendredis',
    saturdays                    : 'Tous les Samedis',
    sundays                      : 'Tous les Dimanches',
    error_size_picture           : (width: number, height: number): string => `Les dimensions de l’image doivent être supérieures à ${width}x${height} pixels`,
    zoom                         : 'Zoom',
    rotation                     : 'Rotation',
    dont_have_website            : 'Je n\'ai pas de site internet',
    subtitle_profile_picture     : '(Dimension supérieure à 500x500px)',
    subtitle_cover_picture       : '(Dimension supérieure à 1200x600px)',
    edit_image                   : 'Editer l’image',
    template_builder             : 'Template builder',
}
