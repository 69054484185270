import { BroadcastConversation } from '../../../domain/entities/broadcastConversation';

export const UPDATE_BROADCAST_CONVERSATION = 'UPDATE_BROADCAST_CONVERSATION'

export interface UpdateBroadcastConversationAction {
    type: typeof UPDATE_BROADCAST_CONVERSATION;
    payload: BroadcastConversation;
}

export const updateBroadcastConversation = (conversation: BroadcastConversation): UpdateBroadcastConversationAction => ({
    type   : UPDATE_BROADCAST_CONVERSATION,
    payload: conversation
})
