import { Order } from "../../domain/entities/order";

export const LOAD_ORDER = 'LOAD_ORDER'
export const LOAD_ORDER_FAILED = 'LOAD_ORDER_FAILED'
export const LOAD_ORDER_SUCCEEDED = 'LOAD_ORDER_SUCCEEDED'

export interface LoadOrderAction {
    type: typeof LOAD_ORDER;
    payload: string;
}

export interface LoadOrderFailedAction {
    type: typeof LOAD_ORDER_FAILED;
    payload: string;
}

export interface LoadOrderSucceededAction {
    type: typeof LOAD_ORDER_SUCCEEDED;
    payload: Order;
}

export type LoadOrderActionTypes = LoadOrderAction | LoadOrderFailedAction | LoadOrderSucceededAction
