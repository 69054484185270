import { RetrieveProAccountStatsState } from "../../configuration/state";
import {
    RETRIEVE_COMPANY_STATS,
    RETRIEVE_COMPANY_STATS_FAILED,
    RETRIEVE_COMPANY_STATS_SUCCEEDED, RetrieveCompanyStatsActionTypes
} from "./retrieveCompanyStats.types";


const initialState: RetrieveProAccountStatsState = {
    loading  : false,
    error    : undefined,
    visits   : undefined,
    followers: undefined,
}

export const retrieveCompanyStatsReducer = (state = initialState, action: RetrieveCompanyStatsActionTypes): RetrieveProAccountStatsState => {
    switch (action.type) {
        case RETRIEVE_COMPANY_STATS:
            return {
                ...state,
                loading: true,
                error  : undefined,
            }
        case RETRIEVE_COMPANY_STATS_FAILED:
            return {
                ...state,
                loading: false,
                error  : action.payload
            }
        case RETRIEVE_COMPANY_STATS_SUCCEEDED:
            return {
                followers: action.payload.followers,
                visits   : action.payload.visits,
                loading  : false,
                error    : undefined
            }
        default:
            return state
    }
}


