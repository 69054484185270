export class CompanyOpeningHours {
    constructor(
        protected _id: string,
        protected _startTime: string,
        protected _endTime: string,
        protected _openingDays: string,
        protected _isPaused: boolean
    ) {}

    get id(): string {
        return this._id
    }

    get startTime(): string {
        return this._startTime
    }

    get endTime(): string {
        return this._endTime
    }

    get openingDays(): string {
        return this._openingDays
    }


    get isPaused(): boolean {
        return this._isPaused;
    }
}
