export class TimeLeftPercentage {

    static calculateTimeLeftPercentage(timeLeft: number): number {
        return this.isUnderOneHour(timeLeft)
            ? this.ceilTimeLeftPercentage(timeLeft)
            : 100
    }

    private static isUnderOneHour(timeLeft: number): boolean {
        return timeLeft < this.convertMinutesToSeconds(60)
    }

    private static ceilTimeLeftPercentage(timeLeft: number): number {
        const percentage = this.calculateTheTimeLeftPercentage(timeLeft)
        return percentage < 0
            ? 0
            : percentage
    }

    private static calculateTheTimeLeftPercentage(timeLeft: number): number {
        return Math.ceil(timeLeft * 100 / this.convertMinutesToSeconds(60))
    }

    private static convertMinutesToSeconds(minutes: number): number {
        return minutes * 60
    }
}
