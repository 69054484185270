import { Follower } from "../entity/follower";


export class FollowerBuilder {
    protected _phoneNumber: string
    protected _firstName: string
    protected _lastName: string
    protected _imageURL: string
    protected _zipcode: number
    protected _city: string | undefined
    private _followDate: string

    withPhoneNumber(value: string): FollowerBuilder {
        this._phoneNumber = value
        return this
    }

    withFirstName(value: string): FollowerBuilder {
        this._firstName = value
        return this
    }

    withLastName(value: string): FollowerBuilder {
        this._lastName = value
        return this
    }

    withImageURL(value: string): FollowerBuilder {
        this._imageURL = value
        return this
    }

    withZipCode(value: number): FollowerBuilder {
        this._zipcode = value
        return this
    }

    withCity(value: string | undefined): FollowerBuilder {
        this._city = value
        return this
    }

    withFollowDate(value: string): FollowerBuilder {
        this._followDate= value
        return this
    }

    build(): Follower {
        return new Follower(
            this._phoneNumber,
            this._firstName,
            this._lastName,
            this._imageURL,
            this._zipcode,
            this._city,
            this._followDate
        )
    }
}
