export const CHANGE_SERVICES_ORDER = 'CHANGE_SERVICES_ORDER'
export const CHANGE_SERVICES_ORDER_FAILED = 'CHANGE_SERVICES_ORDER_FAILED'
export const CHANGE_SERVICES_ORDER_SUCCEEDED = 'CHANGE_SERVICES_ORDER_SUCCEEDED'

export interface ChangeServicesOrderAction {
    type: typeof CHANGE_SERVICES_ORDER;
    payload: {
        mairieId: string;
        serviceIds: string[];
    };
}

export interface ChangeServicesOrderFailedAction {
    type: typeof CHANGE_SERVICES_ORDER_FAILED;
    payload: string;
}

export interface ChangeServicesOrderSucceededAction {
    type: typeof CHANGE_SERVICES_ORDER_SUCCEEDED;
}

export type ChangeServicesOrderActionTypes =
    ChangeServicesOrderAction
    | ChangeServicesOrderFailedAction
    | ChangeServicesOrderSucceededAction
