import { AuthenticationRepository } from "../domain/gateway/authenticationRepository";
import { InMemoryAuthenticationRepository } from "../adapters/secondaries/inmemory/inMemoryAuthenticationRepository";
import { LocalStorageRepository } from "../adapters/secondaries/real/localStorage.repository";

export class AuthenticationDependenciesFactory {

    static getAuthenticationRepository(): AuthenticationRepository {
        switch (process.env.NODE_ENV) {
            case 'production':
            case 'development':
                return new LocalStorageRepository()
            default:
                return new InMemoryAuthenticationRepository()
        }
    }
}
