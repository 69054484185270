import { AppState } from "../../../redux-configuration/AppState";
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { ADD_MUNICIPAL_SERVICE, AddMunicipalServiceAction } from "./actionTypes";
import {
    addMunicipalServiceFailed, addMunicipalServiceSucceeded
} from "./actions";
import { MunicipalityService } from "../../domain/gateway/municipality.service";
import { listingMunicipalServicesSucceeded } from "../listingMunicipalServices/actions";
import { MunicipalService } from "../../domain/entities/municipalService";

export const addMunicipalServiceEpic: Epic = (action$: ActionsObservable<AddMunicipalServiceAction>,
                                              store: StateObservable<AppState>,
                                              {municipalityService}: { municipalityService: MunicipalityService }) =>
    action$.pipe(
        ofType(ADD_MUNICIPAL_SERVICE),
        switchMap(action => municipalityService.addMunicipalService(action.payload.service, action.payload.cityId)
            .pipe(
                concatMap((services: MunicipalService[]) => {
                    services.sort((a, b) => a.rank - b.rank)
                    return [
                        listingMunicipalServicesSucceeded(services),
                        addMunicipalServiceSucceeded()
                    ]
                }),
                catchError(error => of(addMunicipalServiceFailed(error)))
            )
        )
    )
