import { SubscriptionId, SubscriptionType } from "../../../domain/entities/types/subscription.type";

export const subscriptionProData: SubscriptionType[] =
                 [
                     {
                         id           : SubscriptionId.BASIC,
                         label        : 'STARTER',
                         monthly_price: 9.90,
                         promo_price  : 99,
                         properties   : [
                             {label: 'Référencement', value: true, id: 'referencement'},
                             {label: 'Notifications abonnés', value: true, id: 'notification_follow'},
                             {label: 'Messagerie Pro', value: true, id: 'messaging_pro'},
                             {label: 'Événements, services, offres spéciales', value: '3 / type', id: 'instants'},
                             {label: 'Message groupé (notification)', value: '1 / semaine', id: 'broadcast'},
                             {label: 'Click and Collect ', value: false, id: 'click_collect'},
                             {label: 'Site internet en vivezici.fr', value: false, id: 'subdomain'},
                             {label: 'Domaine personnalisé', value: false, id: 'custom_domain'},
                             {label: 'Emails personnalisés', value: false, id: 'custom_emails'},
                             {label: 'Multipost Réseaux Sociaux', value: false, id: 'multipost_social_media'},
                             {label: 'Support technique personnalisé', value: false, id: 'support'},
                         ]
                     },
                     {
                         id           : SubscriptionId.PREMIUM,
                         label        : 'PREMIUM',
                         monthly_price: 24.90,
                         promo_price  : 249,
                         properties   : [
                             {label: 'Référencement', value: true, id: 'referencement'},
                             {label: 'Notifications abonnés', value: true, id: 'notification_follow'},
                             {label: 'Messagerie Pro', value: true, id: 'messaging_pro'},
                             {label: 'Événements, services, offres spéciales', value: 'Illimité', id: 'instants'},
                             {label: 'Message groupé (notification)', value: 'Illimité', id: 'broadcast'},
                             {label: 'Click and Collect ', value: true, id: 'click_collect'},
                             {label: 'Site internet en vivezici.fr', value: true, id: 'subdomain'},
                             {label: 'Domaine personnalisé', value: false, id: 'custom_domain'},
                             {label: 'Emails personnalisés', value: false, id: 'custom_emails'},
                             {label: 'Multipost Réseaux Sociaux', value: false, id: 'multipost_social_media'},
                             {label: 'Support technique personnalisé', value: false, id: 'support'},
                         ]
                     },
                     {
                         id           : SubscriptionId.DIGITAL,
                         label        : 'ADVANCED',
                         monthly_price: 49.90,
                         promo_price  : 499,
                         properties   : [
                             {label: 'Référencement', value: true, id: 'referencement'},
                             {label: 'Notifications abonnés', value: true, id: 'notification_follow'},
                             {label: 'Messagerie Pro', value: true, id: 'messaging_pro'},
                             {label: 'Événements, services, offres spéciales', value: 'Illimité', id: 'instants'},
                             {label: 'Message groupé (notification)', value: 'Illimité', id: 'broadcast'},
                             {label: 'Click and Collect ', value: true, id: 'click_collect'},
                             {label: 'Site internet en vivezici.fr', value: true, id: 'subdomain'},
                             {label: 'Domaine personnalisé', value: true, id: 'custom_domain'},
                             {label: 'Emails personnalisés', value: true, id: 'custom_emails'},
                             {label: 'Multipost Réseaux Sociaux', value: true, id: 'multipost_social_media'},
                             {label: 'Support technique personnalisé', value: true, id: 'support'},
                         ]
                     }
                 ]
