import React, { PureComponent, ReactNode } from "react";
import upload_image from '../../../../../../assets/img/upload_image.png';
import './fileWithIcon.input.css';
import { ClickCollectPicture } from "../../../../../../company/domain/types/clickCollectPicture";

interface Props {
    file: ClickCollectPicture
    error: boolean
    raiseUpdates: (file: ClickCollectPicture) => void
}

export class FileWithIconInput extends PureComponent<Props> {

    render(): ReactNode {
        const pictureUrl = this.props.file.pictureUrl || upload_image
        const className = this.props.error ? 'file-upload premium-img-btn error' : 'file-upload premium-img-btn'

        return (
            <div className={'premium-img-container'}>
                <button type="button" className={this.props.file.pictureUrl ? '' : 'd-none'}
                        onClick={() => this.props.raiseUpdates({
                            ...this.props.file,
                            pictureUrl: '',
                            picture   : undefined
                        })}>
                    <i className="fa fa-times"/>
                </button>

                <div className={className}>
                    <img src={pictureUrl}/>

                    <input type="file" onChange={e => {
                        if (e.target.files)
                            this.props.raiseUpdates({
                                ...this.props.file,
                                pictureUrl: URL.createObjectURL(e.target.files[0]),
                                picture   : e.target.files[0]
                            })
                    }}/>
                </div>
            </div>
        )
    }
}
