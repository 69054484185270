import {
    LISTING_MUNICIPAL_SERVICES, LISTING_MUNICIPAL_SERVICES_FAILED, LISTING_MUNICIPAL_SERVICES_SUCCEEDED, LISTING_SERVICE_ICONS_SUCCEEDED,
    ListingMunicipalServicesAction, ListingMunicipalServicesFailedAction, ListingMunicipalServicesSucceededAction, ListingServiceIconsSucceededAction
} from "./actionTypes";
import { MunicipalService } from "../../domain/entities/municipalService";
import { ServiceIcon } from "../../domain/entities/serviceIcon";

export const listingMunicipalServices = (cityId: string): ListingMunicipalServicesAction => ({
    type   : LISTING_MUNICIPAL_SERVICES,
    payload: cityId
})

export const listingMunicipalServicesFailed = (error: string): ListingMunicipalServicesFailedAction => ({
    type   : LISTING_MUNICIPAL_SERVICES_FAILED,
    payload: error
})

export const listingMunicipalServicesSucceeded = (services: MunicipalService[]): ListingMunicipalServicesSucceededAction => ({
    type   : LISTING_MUNICIPAL_SERVICES_SUCCEEDED,
    payload: services
})

export const listingServiceIconsSucceeded = (icons: ServiceIcon[]): ListingServiceIconsSucceededAction =>({
    type: LISTING_SERVICE_ICONS_SUCCEEDED,
    payload: icons
})
