
import { ManagementInstant } from "../../domain/entities/ManagementInstant";
import {
    LOAD_MANAGEMENT_INSTANT_FAILED, LOAD_MANAGEMENT_INSTANT,
    LOAD_MANAGEMENT_INSTANT_SUCCEEDED, LoadManagementInstantAction,
    LoadManagementInstantFailedAction, LoadManagementInstantSucceededAction
} from "./loadManagementInstant.types";

export const loadManagementInstant = (instantId: string): LoadManagementInstantAction => ({
    type   : LOAD_MANAGEMENT_INSTANT,
    payload: instantId
})

export const loadManagementInstantSucceeded = (instant: ManagementInstant): LoadManagementInstantSucceededAction => ({
    type   : LOAD_MANAGEMENT_INSTANT_SUCCEEDED,
    payload: instant
})

export const loadManagementInstantFailed = (error: string): LoadManagementInstantFailedAction => ({
    type   : LOAD_MANAGEMENT_INSTANT_FAILED,
    payload: error
})
