import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, concatMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { EDIT_PRODUCT, EditProductAction } from "./actionTypes";
import { editProductFailed, editProductSucceeded } from "./actions";
import { ProductService } from "../../domain/gateway/product.service";
import { loadProducts } from "../loadProducts/actions";

export const editProductEpic: Epic = (action$: ActionsObservable<EditProductAction>,
                                      store: StateObservable<AppState>,
                                      {productService}: { productService: ProductService }) =>
    action$.pipe(
        ofType(EDIT_PRODUCT),
        switchMap(
            (action) => productService.editProduct(action.payload.product, action.payload.companyId)
                .pipe(
                    concatMap(() => [
                        loadProducts(action.payload.companyId),
                        editProductSucceeded()
                    ]),
                    catchError(error => of(editProductFailed(error)))
                )
        )
    )
