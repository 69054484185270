import React, { PureComponent, ReactNode } from 'react'
import { i18n } from '../../../../../../configuration/i18n';
import './subscription.mobile.version.css'
import { SubscriptionProperty, SubscriptionType } from "../../../../../domain/entities/types/subscription.type";

interface Props {
    subscription: SubscriptionType
    onPress: () => void
    isPreferred: boolean,
    isMonthly: boolean
}

export class SubscriptionItem extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className="col-12">
                <div className={this.props.isPreferred ? "single-pricing single-pricing-white" : "single-pricing"}>
                    <div className="price-head">
                        <h2>{this.props.subscription.label}</h2>

                    </div>
                    <h1 className="price">{`${this.props.isMonthly ? this.props.subscription.monthly_price.toFixed(2) : this.props.subscription.promo_price}€`}<span className={'currency_ht'}>{'HT'}</span></h1>
                    <span
                        className={'price_currency'}>{`soit ${this.props.isMonthly ? this.getTTCPrice(this.props.subscription.monthly_price) : this.getTTCPrice(this.props.subscription.promo_price)}€ ttc / ${this.props.isMonthly ? i18n.payment.month : i18n.payment.year}`}</span>
                    <p className={'price_subtitle'}>{!this.props.isMonthly ? i18n.payment.subtitle_year : ''}</p>

                    <ul>
                        {this.props.subscription.properties.map((item, index) => this.renderProperty(item, index))}
                    </ul>
                    <a onClick={() => this.props.onPress()}>{i18n.payment.buy}</a>
                </div>
            </div>
        )
    }

    renderProperty(property: SubscriptionProperty, index: number): JSX.Element {
        return property.value === true ? (
                <li key={index}>
                    <i className="fas fa-check"/>{property.label}
                </li>
            )
            : property.value === false ?
                property.label === 'Support technique' ?
                    (<li key={index}>
                        <i className='fas fa-envelope'/>{property.label}
                    </li>) :
                (<li key={index}>
                   <i className='fas fa-times'/>{property.label}
                </li>) :
                (<li key={index}>
                    <i className="fas fa-check"/>{`${property.label} (${property.value})`}
                </li>)
    }
    getTTCPrice(price: number): string{
        return (price * 1.2).toFixed(2)
    }
}
