import { Company } from "../../../company/domain/entity/company";

export const LOAD_LOCAL_BUSINESS = 'LOAD_LOCAL_BUSINESS'
export const LOAD_LOCAL_BUSINESS_SUCCEEDED = 'LOAD_LOCAL_BUSINESS_SUCCEEDED'
export const LOAD_LOCAL_BUSINESS_FAILED = 'LOAD_LOCAL_BUSINESS_FAILED'

export interface LoadLocalBusinessAction {
    type: typeof LOAD_LOCAL_BUSINESS;
}

export interface LoadLocalBusinessSucceededAction {
    type: typeof LOAD_LOCAL_BUSINESS_SUCCEEDED;
    payload: Company;
}

export interface LoadLocalBusinessFailedAction {
    type: typeof LOAD_LOCAL_BUSINESS_FAILED;
    payload: string;
}

export type LoadLocalBusinessActionType = LoadLocalBusinessAction

export type LoadLocalBusinessActionTypes =
    LoadLocalBusinessAction
    | LoadLocalBusinessFailedAction
    | LoadLocalBusinessSucceededAction
