import { MessagingService } from "../domain/gateway/messaging.service";
import { ApiMessagingService } from "../adapters/secondaries/real/apiMessaging.service";
import { InMemoryMessagingService } from "../adapters/secondaries/inMemory/inMemoryMessaging.service";

export class MessagingDependenciesFactory {

    static getMessagingService(): MessagingService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiMessagingService()
            default:
                return new InMemoryMessagingService()
        }
    }

}
