export const CompanyI18n = {
    company_profile_page_title  : 'VivezICI | Mon profil',
    company_edition_page_title  : 'VivezICI | Modifier mon profil',
    company_theme_title         : 'VivezICI | Paramètres du site internet',
    subscription_page_title     : 'VivezICI | Abonnement',
    opening_hours               : 'Horaires d\'ouverture',
    closed                      : 'Fermé ',
    open                        : 'Ouvert',
    monday                      : 'Lundi',
    tuesday                     : 'Mardi',
    wednesday                   : 'Mercredi',
    thursday                    : 'Jeudi',
    friday                      : 'Vendredi',
    saturday                    : 'Samedi',
    sunday                      : 'Dimanche',
    no_openingHours             : 'Pas d\'horaires d\'ouverture indiqués.',
    followers                   : 'abonnés',
    about                       : 'À Propos',
    our_address                 : 'Notre Adresse',
    contact                     : 'Contact',
    route                       : 'Itinéraire',
    temporary_closed            : 'Fermé temporairement',
    edit_profile                : 'Modifier mon profil',
    deleteInstant               : (title: string): string => `êtes-vous sûr de vouloir supprimer l'instant: ${title}`,
    edit_succeeded              : 'Modification terminée',
    save                        : 'Enregistrer',
    instants                    : 'Instants',
    error_subdomain_name        : 'Le sous-domaine n\'est pas valide',
    error_legal_info            : 'Veuillez vérifiez vos informations légales',
    error_click_collect_pictures: 'Vous devez ajouter au moins une image',
    error_picture_size          : 'Les dimensions de l’image doivent être supérieures à 800 x 1000 pixels',
    add_pictures_clickCollect   : 'Vous devez ajouter au moins une image, Choisissez les images à afficher, par exemple :',
    your_menu                   : '- les pages de votre menu (restaurant)',
    your_products               : '- vos promotions ou vos produits à emporter',
    active_clickCollect         : 'Activer le service Click & Collect',
    description_clickCollect    : 'Phrase d’accroche',
    active_subdomain            : 'Activer le site internet',
    membership_title            : 'Compte Premium : Vos avantages clients',
    subdomain                   : 'Sous-domaine',
    legal_company_name          : 'La société',
    legal_form                  : 'Forme juridique',
    capital                     : 'Montant du capital social',
    legal_address               : 'Adresse du siège social',
    legal_phone_number          : 'Coordonnées téléphoniques',
    legal_email                 : 'Email de contact',
    siret_number                : 'Numéro de SIRET',
    rna_number                  : 'Numéro RNA',
    asso_legal_text             : 'Association à but non-lucratif (loi 1901)',
    tva_number                  : 'Numéro de TVA intracommunautaire',
    immatricule                 : 'Immatriculée au registre national des entreprises',
    others                      : 'Autre information',
    no_instant_found            : 'Il n\'y a pas d\'instants dans les 3 prochaines semaines',
    show_website                : 'Voir mon site web',
    my_profile                  : 'Mon profil',
    launch_app                  : 'Lancer l\'application',
    install_app                 : 'Retrouvez-nous sur',
    vivez_clichy                : 'l\'application mobile VIVEZ ICI !',
    qrcode_title                : 'Marketing:',
    description_qrcode          : '<p><b>Téléchargez votre QR code personnalisé :</b></p><br/>' +
        '<p>Vos clients pourront s’abonner à votre compte pro en scannant ce QR code : c’est simple et efficace !</p><br/>' +
        '<p>Téléchargez le QR code et posez-le près de votre caisse, sur vos tables ou sur votre carte en demandant à vos clients de le scanner pour suivre votre actualité et être au courant de vos offres et de vos prochains événements.</p><br/>' +
        '<p>Pour rappel, chaque abonné à votre compte pro pourra plus tard être notifié et relancé lorsque vous voudrez annoncer vos nouvelles offres et vos prochains événements, un peu comme une newsletter.</p><br/>' +
        '<p>L’idée est de leur rappeler que vous existez et de les faire revenir dans votre commerce.</p>',
    download                    : 'Télécharger',
    description_qrcode_web      : '<p><b>Téléchargez votre QR code de SITE INTERNET :</b></p><br/>' +
        '<p>Vos clients pourront découvrir votre site internet puis s’abonner à votre compte pro en cliquant sur l’icône abonnement.</p><br/>' +
        '<p>C’est moins efficace pour transformer un client en abonné mais ça vous permet de communiquer sur votre site internet avant de communiquer sur l’application mobile : une popup invite le visiteur de votre site internet à voir votre profil pro sur l’application mobile au bout de 10 secondes.</p><br/>' +
        '<p>Téléchargez le QR code et posez-le sur votre carte de visite ou bien sur les flyers que vous réalisez pour communiquer sur votre commerce.</p><br/>' +
        '<p>Il est préférable d’utiliser le QR code d’ABONNEMENT pour plus d’efficacité car l’objectif est d’avoir le plus grand nombre d’abonnés à notifier et à faire revenir dans votre commerce.</p>',
    description_qrcode_foodMenu : '<p><b>Téléchargez votre QR code de votre Menu :</b></p><br/>' +
        '<p>Vos clients pourront découvrir vos différents documents ou cartes grâce à ce QR code.</p><br/>' +
        '<p>Téléchargez-le et utilisez-le pour afficher vos documents (cartes, menus, certifications, offres) sur le mobile de vos clients. ' +
        'Par exemple, dans la restauration, vous pouvez imprimer le QR code et le poser sur les tables pour que vos clients découvrent vos menus.</p><br/>',
    description_qrcode_review   : '<p><b>Téléchargez votre QR code pour l’ajout d’un avis Google :</b></p><br/>' +
        '<p>Vos clients peuvent ajouter un avis Google pour votre commerce en scannant ce QR code.</p><br/>' +
        '<p>C\'est très intéressant pour générer du trafic vers votre commerce car la plupart des internautes regardent les avis Google avant d\'aller dans un commerce.</p><br/>',
    followers_list              : 'Liste des abonnés',
    website                     : 'Nom de domaine *',
    website_title               : 'Titre du site internet *',
    website_primary_color       : 'Couleur primaire *',
    website_secondary_color     : 'Couleur secondaire *',
    website_primary_font        : 'Police des titres *',
    website_secondary_font      : 'Police des textes *',
    website_configuration       : 'Paramètres du site internet',
    website_logo                : 'Logo',
    website_video               : 'Vidéo',
    video_error                 : 'La taille du vidéo ne doit dépasser 100 Mo',
    website_place_id            : 'Id Google My Business *',
    sub_title                   : 'Phrase d’accroche *',
    headerTitle                 : 'Libellé dans le menu *',
    section_title               : 'Titre de la rubrique *',
    title_color                 : 'Couleur du titre *',
    text_color                  : 'Couleur du texte *',
    background_color            : 'Couleur du fond *',
    instant_color               : 'Couleur d\'instant *',
    address_color               : 'Couleur d\'adresse *',
    images                      : 'Photos (4 conseillés)',
    menu_files                  : 'Documents (maximum 4) *',
    min_6_images                : 'Photos (au moins 6) *',
    empty_form_error            : 'Veuillez remplir tous les champs',
    gallery_images_error        : 'Veuillez ajouter minimum 6 images pour la gallery',
    food_menu_documents_error   : 'Veuillez ajouter minimum un document pour le block Documents',
    default_theme               : 'Paramètres par défaut',
    success_message             : 'Votre thème est mis à jour. ',
    button_label                : 'Texte du bouton',
    button_url                  : 'Url du bouton',
    doc_title                   : 'Titre du document *',
    icon                        : 'Icone *',
    pdf                         : 'Fichier PDF *',
}
