import {
    RETRIEVE_PAYMENT_SESSION,
    RETRIEVE_PAYMENT_SESSION_FAILED,
    RETRIEVE_PAYMENT_SESSION_SUCCEEDED, RetrievePaymentSessionAction, RetrievePaymentSessionActionTypes,
} from "./actionTypes";
import { SubscriptionPeriodicity, SubscriptionId } from "../../domain/entities/types/subscription.type";

export const retrievePaymentSession = (companyId: string, subscriptionId: SubscriptionId, periodicity: SubscriptionPeriodicity): RetrievePaymentSessionAction => ({
    type: RETRIEVE_PAYMENT_SESSION,
    payload: { companyId, subscriptionId, periodicity }
})

export const retrievePaymentSessionSucceeded = (sessionId: string): RetrievePaymentSessionActionTypes => ({
    type   : RETRIEVE_PAYMENT_SESSION_SUCCEEDED,
    payload: sessionId
})

export const retrievePaymentSessionFailed = (error: string): RetrievePaymentSessionActionTypes => ({
    type: RETRIEVE_PAYMENT_SESSION_FAILED,
    payload: error
})
