import React, { ReactNode } from "react";
import { PureComponent } from "react";
import '../css/pageNotFound.error.css';
import logo from '../../../../assets/img/vivez-clichy-marker.jpeg';
import { i18n } from "../../../../configuration/i18n";

export class PageNotFoundError extends PureComponent {

    render(): ReactNode {
        return (
            <div className="error-content d-flex align-items-center justify-content-center">
                <div className="im-sheep">
                    <div className="top">
                        <img src={logo} alt="vivez-ici" style={{ width: "100px" }}/>
                    </div>
                </div>
                <h1 className="error">{i18n.navigation.not_found_error}</h1>
                <h2>{i18n.navigation.oups}</h2>
            </div>
        )
    }
}
