import React from "react";
import { ProductDetailsType } from "../types";
import { TextInput } from "../../../../common/adapters/primaries/form/inputs/text.input";
import { i18n } from "../../../../configuration/i18n";
import { ValueOf } from "../../../../common/domain/types/app.categories";
import { ProductImageInput } from "./image.input";

interface Props {
    error: boolean;
    data: ProductDetailsType;
    onChange: (key: keyof ProductDetailsType, value: ValueOf<ProductDetailsType>) => void;
}

export const EcommerceProductGeneralForm = (props: Props): JSX.Element => {
    return (
        <div id={"theme-gallery"}>
            <TextInput type={'text'}
                       error={props.error && props.data.name.length === 0}
                       label={i18n.ecommerce.name}
                       value={props.data.name}
                       raiseUpdates={(value: string) => props.onChange('name', value)}/>

            <TextInput type={'text'}
                       error={props.error && props.data.category.length === 0}
                       label={i18n.ecommerce.category}
                       value={props.data.category}
                       raiseUpdates={(value: string) => props.onChange('category', value)}/>

            <TextInput type={'number'}
                       error={props.error && props.data.price.length === 0}
                       label={i18n.ecommerce.price}
                       value={props.data.price}
                       raiseUpdates={(value: string) => props.onChange('price', value)}/>

            <TextInput type={'number'}
                       error={props.error && props.data.stock.length === 0}
                       label={i18n.ecommerce.stock}
                       value={props.data.stock}
                       raiseUpdates={(value: string) => props.onChange('stock', value)}/>

            <TextInput type={'text'}
                       numberOfLines={4}
                       error={props.error && props.data.shortDescription.length === 0}
                       label={i18n.ecommerce.short_description}
                       value={props.data.shortDescription}
                       raiseUpdates={(value: string) => props.onChange('shortDescription', value)}/>

            <ProductImageInput image={props.data.image}
                               error={props.error && !!props.data.image}
                               raiseUpdates={(value: ValueOf<ProductDetailsType>) => props.onChange('image', value)}/>
        </div>
    )
}
