import { AppState } from "../../../redux-configuration/AppState";
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MunicipalityService } from "../../domain/gateway/municipality.service";
import { CHANGE_SERVICES_ORDER, ChangeServicesOrderAction } from "./actionTypes";
import { changeServicesOrderFailed, changeServicesOrderSucceeded } from "./actions";

export const changeServicesOrderEpic: Epic = (action$: ActionsObservable<ChangeServicesOrderAction>,
                                                    store: StateObservable<AppState>,
                                                    {municipalityService}: { municipalityService: MunicipalityService }) =>
    action$.pipe(
        ofType(CHANGE_SERVICES_ORDER),
        switchMap(action => municipalityService.changeServicesOrder(action.payload.mairieId, action.payload.serviceIds)
            .pipe(
                map(() => changeServicesOrderSucceeded()),
                catchError(error => of(changeServicesOrderFailed(error)))
            )
        )
    )
