import React from 'react';

interface Props {
    title: string;
    showButton: boolean;
    addService: () => void;
    changeOrders: () => void;
}

export const ServicesPageHeader = (props: Props): JSX.Element => {
    return (
        <div className={'profile-edition'}>
            <div className="section-title">
                <h2 className="title">{props.title}</h2>

                <div className={'buttons-header-container'}>
                    {props.showButton &&(
                        <button className={'edit-btn'} onClick={() => props.changeOrders()}>
                            Enregistrer le changement
                        </button>
                    )}

                    <button className={'edit-btn'} onClick={() => props.addService()}>
                        Ajouter un service
                    </button>
                </div>
            </div>
        </div>
    )
}
