import React, { ReactNode } from "react";
import { SideBar } from "./sidebar";
import "./defaultPage.css";

interface Props {
    children: ReactNode;
}

export const DefaultPage = (props: Props): JSX.Element => {
    return (
        <div className={'d-flex flex-wrap'}>
            <SideBar/>
            <div className={'page-content'}>
                {props.children}
            </div>
        </div>
    )
}
