import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { editInstantFailed, editInstantSucceeded } from './editinstant.actions';
import { AppState } from "../../../redux-configuration/AppState";
import { EDIT_INSTANT, EditInstantAction } from "./editInstant.types";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";
export const editInstantEpic: Epic = (action$: ActionsObservable<EditInstantAction>,
                                       store: StateObservable<AppState>,
                                       { instantManagementService }: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(EDIT_INSTANT),
        switchMap(action => instantManagementService.editRemoteInstant(action.payload)
            .pipe(
                map(() => editInstantSucceeded()),
                catchError(error => of(editInstantFailed(error)))
            )
        )
    )
