import React from "react";
import creditCard from "../../../../assets/img/credit-card-fail.jpeg";
import { i18n } from "../../../../configuration/i18n";

interface Props {
    error: string
}

export const PaymentFailContent = (props: Props): JSX.Element => {
    return (
        <div className="container success-payment">
            <div className="row align-items-center h-100">
                <div className="col-5 success-content">
                    <i className="fa fa-times"/>
                    <h2 className="success-title">{i18n.payment.oops_payment_failed}</h2>
                    <p>{props.error}</p>
                    <a href={'/subscription'}>{i18n.payment.try_again}</a>
                </div>

                <div className="col-7 success-img">
                    <img src={creditCard} alt="success payment" className="credit-card-img"/>
                </div>
            </div>
        </div>
    )
}
