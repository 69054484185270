import React, { PureComponent, ReactNode } from 'react';
import { i18n } from "../../../../../../configuration/i18n";

export class BannerInvoicingLink extends PureComponent {
    render(): ReactNode {
        const paymentPortal = process.env.REACT_APP_STRIPE_CLIENT_PORTAL
        return (
            <div className={'d-sm-flex justify-content-between align-items-center p-3'}>
                <div className={'text_html'} dangerouslySetInnerHTML={{__html: i18n.payment.link_payment}}/>
                <a href={paymentPortal} rel="noreferrer" target='_blank' className={'invoicing-btn'}>{i18n.payment.invoicing_space}</a>
            </div>
        )
    }
}
