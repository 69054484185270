export class ThemeDetails {

    constructor(
        protected _title: string,
        protected _website: string,
        protected _primaryColor: string,
        protected _secondaryColor: string,
        protected _primaryFont: string,
        protected _secondaryFont: string,
        protected _placeId?: string
    ) {
    }

    get title(): string {
        return this._title;
    }

    get website(): string {
        return this._website;
    }

    get primaryColor(): string {
        return this._primaryColor;
    }

    get secondaryColor(): string {
        return this._secondaryColor;
    }

    get primaryFont(): string {
        return this._primaryFont;
    }

    get secondaryFont(): string {
        return this._secondaryFont;
    }

    get placeId(): string | undefined {
        return this._placeId;
    }
}
