import { User } from "../../domain/entity/user";

export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED'
export const SIGN_UP_SUCCEEDED = 'SIGN_UP_SUCCEEDED'

interface SignUpAction {
    type: typeof SIGN_UP;
    payload: User;
}

interface SignUpFailedAction {
    type: typeof SIGN_UP_FAILED;
    payload: string;
}
interface SignUpSucceededAction {
    type: typeof SIGN_UP_SUCCEEDED;
}

export type SignUpActionType = SignUpAction
export type SignUpActionTypes = SignUpAction | SignUpFailedAction | SignUpSucceededAction
