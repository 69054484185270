import { ChatMessage } from "../../../domain/entities/chatMessage";

export const LOAD_NEW_CHAT_MESSAGES = 'LOAD_NEW_CHAT_MESSAGES'
export const LOAD_NEW_CHAT_MESSAGES_SUCCEEDED = 'LOAD_NEW_CHAT_MESSAGES_SUCCEEDED'
export const LOAD_NEW_CHAT_MESSAGES_FAILED = 'LOAD_NEW_CHAT_MESSAGES_FAILED'

export interface LoadNewChatMessagesAction {
    type: typeof LOAD_NEW_CHAT_MESSAGES;
    payload: {
        conversationId: string;
        messageId: number
    };
}

export interface LoadNewChatMessagesSucceededAction {
    type: typeof LOAD_NEW_CHAT_MESSAGES_SUCCEEDED;
    payload: ChatMessage[];
}

export interface LoadNewChatMessagesFailedAction {
    type: typeof LOAD_NEW_CHAT_MESSAGES_FAILED;
    payload: string;
}

export type LoadNewChatMessagesActionTypes =
    LoadNewChatMessagesAction
    | LoadNewChatMessagesSucceededAction
    | LoadNewChatMessagesFailedAction
