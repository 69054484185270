export class TimeLeftHelpers {

    static HumanizeTimeLeft(timeLeft: number): string {
        return timeLeft < 0
            ? ''
            : `${this.stringifyHourPart(timeLeft)}:` +
            `${this.stringifyMinutePart(this.extractMinutesLeft(timeLeft))}:` +
            `${this.stringifySecondPart(this.extractSecondsLeft(timeLeft))}`
    }

    static stringifyHourPart(timeLeft: number): string {
        return this.padLeft(Math.floor(timeLeft / 3600))
    }

    static stringifyMinutePart(timeLeft: number): string {
        return this.padLeft(Math.floor(timeLeft / 60))
    }

    private static extractMinutesLeft(timeLeft: number): number {
        return this.isMoreThanOneHour(timeLeft)
            ? timeLeft % 3600
            : timeLeft
    }

    private static extractSecondsLeft(timeLeft: number): number {
        return this.isMoreThanOneMinute(timeLeft)
            ? timeLeft % 60
            : timeLeft
    }

    private static isMoreThanOneMinute(timeLeft: number): boolean {
        return timeLeft / 60 >= 1
    }

    static stringifySecondPart(timeLeft: number): string {
        return this.padLeft(timeLeft)
    }


    private static isMoreThanOneHour(timeLeft: number): boolean {
        return timeLeft / 3600 >= 1
    }

    private static padLeft(str: number): string {
        const pad = '00'
        return pad.substring(0, pad.length - str.toString().length) + str
    }

}
