import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { Company } from "../../../../domain/entity/company";
import { AppCategories } from "../../../../../common/domain/types/app.categories";

interface Props {
    company: Company;
    onPressFollower: () => void;
}

export class CompanyProfileHeader extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className="profile-section profile-header">
                <div className="cover-img" style={{backgroundImage: `url("${this.props.company.coverImageUrl}")`}}>
                    <div className={'d-flex header_content align-items-center'}>
                        <div className="profile-img" style={{backgroundImage: `url("${this.props.company.profileImageUrl}")`}}/>

                        <div className="profile-info">
                            <h1 className="title">{this.props.company.name}</h1>
                            <span className="category">{AppCategories[this.props.company.category]}</span>
                        </div>
                    </div>
                </div>

                <div className="followers d-flex align-items-center">
                    <i className={'far fa-bell'}/>

                    <button className={'followers_btn'} onClick={() => this.props.onPressFollower()}>
                        <strong>{this.props.company.followersCount} {i18n.company.followers}</strong>
                    </button>
                </div>
            </div>
        )
    }
}
