import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { OpeningHoursItem } from "../../openingHours/openingHours.item";
import { OpeningDayNames, OpeningHoursType } from "../../../../domain/types/openingHours.types";
import { TimeInput } from "../inputs/time/time.input";
import { OpeningDaysSelector } from "../inputs/openingDaysSelector";
import { store } from "../../../../../App";
import { updateSingleField } from "../../../../../company/usecases/updateSingleField/actions";

interface Props {
    openingHours: OpeningHoursType[];
    raiseUpdate: (openingHours: OpeningHoursType[]) => void
    isSubmitted: boolean
}

interface State {
    selectedDay: string;
    startTime: string;
    endTime: string;
    openingHours: OpeningHoursType[];
    startTimeError: boolean
    endTimeError: boolean
    errorMsg: string
}

export class FormOpeningHoursSection extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            selectedDay   : 'all_days',
            startTime     : '',
            endTime       : '',
            openingHours  : this.props.openingHours,
            startTimeError: false,
            endTimeError  : false,
            errorMsg      : ''
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.openingHours !== this.props.openingHours)
            this.setState({openingHours: this.props.openingHours})
        if (this.props.isSubmitted && this.props.isSubmitted !== prevProps.isSubmitted)
            if (this.state.openingHours.length === 0)
                this.setState({errorMsg: i18n.common.opening_hours_error})
    }

    render(): ReactNode {
        return (
            <div className={'section_pro opening-hours-block'}>
                <span className="wizard-sub-text">{i18n.common.opening_hours_description}</span>

                <div className={'px-3'}>
                    <span className="wizard-sub-subtitle sub-section">{i18n.common.opening_hours_sub_description}</span>

                    <div className={'opening-hours-items sub-section wizard-form-input'}>
                        {this.state.openingHours.map((item, index) =>
                            <OpeningHoursItem key={index} day={OpeningDayNames[item.daytype]}
                                              time={item.startTime + ' - ' + item.endTime}
                                              remove={() => this.removeOpeningHoursItem(index)}/>)}
                    </div>

                    <OpeningDaysSelector className={'registration-input'}
                                         onChange={(value) => this.setState({selectedDay: value, errorMsg: ''})}
                                         label={i18n.common.openingHours} selected={this.state.selectedDay}/>

                    <div className="wizard-form-input opening-hours registration-input">
                        <label className="start">{i18n.common.from}</label>
                        <TimeInput time={this.state.startTime}
                                   className={this.state.startTimeError ? 'error' : ''}
                                   onChange={(startTime: string): void => this.setState({startTime, errorMsg: ''})}/>

                        <label className="end">{i18n.common.to}</label>
                        <TimeInput time={this.state.endTime}
                                   className={this.state.endTimeError ? 'error' : ''}
                                   onChange={(endTime: string): void => this.setState({endTime, errorMsg: ''})}/>
                    </div>

                    <p className={'error'}>{this.state.errorMsg}</p>

                    <div className={'sub-section'}>
                        <span className={'opening-hours-btn add-btn'} onClick={() => this.addOpeningHoursItem()}>
                            {i18n.common.add}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    addOpeningHoursItem(): void {
        this.setState({
            startTimeError: this.state.startTime.length < 5,
            endTimeError  : this.validateEndTime()
        }, () => {
            if (!this.state.startTimeError && !this.state.endTimeError)
                this.setState({
                    openingHours: [
                        ...this.state.openingHours,
                        {
                            id       : '',
                            daytype  : this.state.selectedDay,
                            startTime: this.state.startTime,
                            endTime  : this.state.endTime,
                            isPaused : false
                        }
                    ],
                    errorMsg    : ''
                }, () => {
                    store.dispatch(updateSingleField('openingHours', this.state.openingHours))
                    this.props.raiseUpdate(this.state.openingHours)
                })
        })
    }

    validateEndTime(): boolean {
        if (this.state.startTime.length === 5 && this.state.endTime.length === 5) {
            return this.state.startTime === this.state.endTime
        } else
            return this.state.endTime.length < 5
    }

    removeOpeningHoursItem(index: number): void {
        const openingHours = [...this.state.openingHours]
        openingHours.splice(index, 1)
        this.setState({
            openingHours: [...openingHours]
        }, () => {
            store.dispatch(updateSingleField('openingHours', this.state.openingHours))
            this.props.raiseUpdate(this.state.openingHours)
        })
    }
}
