import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { BroadcastConversation } from '../../../domain/entities/broadcastConversation';
import { MessagingService } from "../../../domain/gateway/messaging.service";
import { AppState } from "../../../../redux-configuration/AppState";
import { SEND_BROADCAST_MESSAGE, SendBroadcastMessageAction } from "./actionTypes";
import { sendBroadcastMessageFailed, sendBroadcastMessageSucceeded } from "./actions";
import { updateBroadcastConversation } from "../update/action";


export const sendBroadcastMessageEpic: Epic = (action$: ActionsObservable<SendBroadcastMessageAction>, store$: StateObservable<AppState>,
                                               {messagingService}: { messagingService: MessagingService }) =>
    action$.pipe(
        ofType(SEND_BROADCAST_MESSAGE),
        switchMap((action: { payload: { businessId: string; message: string; image: File | undefined } }) =>
            messagingService.sendBroadcastMessage(action.payload.businessId, action.payload.message, action.payload.image)
                .pipe(
                    concatMap((conversation: BroadcastConversation) => [
                        sendBroadcastMessageSucceeded(),
                        updateBroadcastConversation(conversation)
                    ]),
                    catchError(error => of(sendBroadcastMessageFailed(error)))
                )
        )
    )
