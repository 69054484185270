import { LOAD_ORDER, LOAD_ORDER_FAILED, LOAD_ORDER_SUCCEEDED, LoadOrderActionTypes } from './actionTypes';
import { LoadOrderState } from "../../configuration/state";

const initialState: LoadOrderState = {
    loading: false,
    error  : undefined,
    order  : null
}
export const loadOrderReducer = (state = initialState, action: LoadOrderActionTypes): LoadOrderState => {
    switch (action.type) {
        case LOAD_ORDER:
            return {
                loading: true,
                error  : undefined,
                order  : null
            }
        case LOAD_ORDER_FAILED:
            return {
                loading: false,
                error  : action.payload,
                order  : null
            }
        case LOAD_ORDER_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                order  : action.payload
            }
        default:
            return state
    }
}
