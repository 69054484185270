import { ThemeBlock } from "./ThemeBlock";
import { ThemeDetails } from "./ThemeDetails";
import { ThemeSocialMedia } from "./ThemeSocialMedia";
import { ThemeWelcomeBlock } from "./ThemeWelcomeBlock";
import { ThemeFoodMenu } from "./ThemeFoodMenu";
import { ThemeEcommerce } from "./ThemeEcommerce";

export class ThemeConfiguration {

    static PRIMARY_COLOR = "#324178";
    static SECONDARY_COLOR = "#ffcb00";
    static TEXT_COLOR = "#ffffff";
    static TITLE_COLOR = "#000000";
    static BACKGROUND_COLOR = "#f6f7fd";
    static PRIMARY_FONT = "Raleway";
    static SECONDARY_FONT = "Open Sans";

    constructor(
        protected _general: ThemeDetails,
        protected _header: ThemeBlock,
        protected _footer: ThemeBlock,
        protected _welcome: ThemeWelcomeBlock,
        protected _about: ThemeBlock,
        protected _instants: ThemeBlock,
        protected _gallery: ThemeBlock,
        protected _address: ThemeBlock,
        protected _contact: ThemeBlock,
        protected _review: ThemeBlock,
        protected _clickCollect: ThemeBlock,
        protected _foodMenu: ThemeFoodMenu,
        protected _socialMedia: ThemeSocialMedia,
        protected _ecommerce: ThemeEcommerce
    ) {
    }

    get general(): ThemeDetails {
        return this._general;
    }

    get header(): ThemeBlock {
        return this._header;
    }

    get footer(): ThemeBlock {
        return this._footer;
    }

    get welcome(): ThemeWelcomeBlock {
        return this._welcome;
    }

    get about(): ThemeBlock {
        return this._about;
    }

    get instants(): ThemeBlock {
        return this._instants;
    }

    get gallery(): ThemeBlock {
        return this._gallery;
    }

    get address(): ThemeBlock {
        return this._address;
    }

    get contact(): ThemeBlock {
        return this._contact;
    }

    get review(): ThemeBlock {
        return this._review;
    }

    get clickCollect(): ThemeBlock {
        return this._clickCollect;
    }

    get foodMenu(): ThemeFoodMenu {
        return this._foodMenu;
    }

    get socialMedia(): ThemeSocialMedia {
        return this._socialMedia;
    }

    get ecommerce(): ThemeEcommerce {
        return this._ecommerce;
    }
}
