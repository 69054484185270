import { Observable, of } from 'rxjs';
import { ApplicationContext } from "../../../../configuration/application.context";
import { MessagingService } from "../../../domain/gateway/messaging.service";
import { Conversation } from "../../../domain/entities/conversation";
import { ConversationBuilder } from "../../../domain/builder/conversationBuilder";
import { ChatMessage } from "../../../domain/entities/chatMessage";
import { BroadcastConversation } from "../../../domain/entities/broadcastConversation";
import { BroadcastConversationBuilder } from "../../../domain/builder/broadcastConversation.builder";
import { BroadcastMessageBuilder } from "../../../domain/builder/broadcastMessage.builder";

const moment = ApplicationContext.getInstance().momentJs()

export class InMemoryMessagingService implements MessagingService {

    loadPreviousChatMessages(): Observable<ChatMessage[]> {
        return of([])
    }

    loadNewChatMessages(): Observable<ChatMessage[]> {
        return of([])
    }

    sendChatMessage(): Observable<void> {
        return of(void 0)
    }

    retrieveBusinessConversations(): Observable<Conversation[]> {
        return of([
            new ConversationBuilder()
                .withTitle('Nilsson')
                .withId('06')
                .withExcerpt('hello !! how are you ?')
                .withUpdatedAt(moment().subtract(48, 'hour'))
                .withUnreadMessage(2)
                .build(),
            new ConversationBuilder()
                .withTitle('Nilsson')
                .withId('07')
                .withExcerpt('hello !! how are you ?')
                .withUpdatedAt(moment().subtract(48, 'hour'))
                .withUnreadMessage(2)
                .build()
        ])
    }

    loadBroadcastConversation(): Observable<BroadcastConversation> {
        return of(new BroadcastConversationBuilder()
            .withId('id-1')
            .withTitle('Message à mes 134 followers')
            .withExcerpt('Bonjour, Lorem ipsum...')
            .withUpdatedAt(new Date())
            .withMessages([
                new BroadcastMessageBuilder()
                    .withId(1)
                    .withContent('Bonjour, Lorem ipsum lorem ipsum')
                    .withCreatedAt(new Date())
                    .build()
            ])
            .build())
    }

    sendBroadcastMessage(): Observable<BroadcastConversation> {
        return of(new BroadcastConversationBuilder()
            .withId('id-1')
            .withTitle('Message à mes 134 followers')
            .withExcerpt('new msg ...')
            .withUpdatedAt(new Date())
            .withMessages([
                new BroadcastMessageBuilder()
                    .withId(1)
                    .withContent('Bonjour, Lorem ipsum lorem ipsum')
                    .withCreatedAt(new Date())
                    .build(),
                new BroadcastMessageBuilder()
                    .withId(1)
                    .withContent('new msg pour vous')
                    .withCreatedAt(new Date())
                    .build()
            ])
            .build())
    }

}
