import {
    LOAD_PREVIEW_INSTANTS_COMPANY,
    LOAD_PREVIEW_INSTANTS_COMPANY_FAILED,
    LOAD_PREVIEW_INSTANTS_COMPANY_SUCCEEDED,
    LoadPreviewInstantsCompanyAction,
    LoadPreviewInstantsCompanyFailedAction,
    LoadPreviewInstantsCompanySucceededAction
} from "./loadPreviewInstantsCompany.types";
import { ManagementInstantHeader } from "../../domain/entities/managementInstantHeader";


export const loadPreviewInstantsCompany = (companyId: string): LoadPreviewInstantsCompanyAction => ({
    type   : LOAD_PREVIEW_INSTANTS_COMPANY,
    payload: companyId
})

export const loadPreviewInstantsCompanySucceeded = (instants: ManagementInstantHeader[]): LoadPreviewInstantsCompanySucceededAction => ({
    type   : LOAD_PREVIEW_INSTANTS_COMPANY_SUCCEEDED,
    payload: instants
})

export const loadPreviewInstantsCompanyFailed = (error: string): LoadPreviewInstantsCompanyFailedAction => ({
    type   : LOAD_PREVIEW_INSTANTS_COMPANY_FAILED,
    payload: error
})
