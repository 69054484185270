import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FIND_FACEBOOK_PAGE, FindFacebookPageAction } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { findFacebookPageFailed, findFacebookPageSucceeded } from "./actions";
import { CompanyService } from "../../domain/gateway/company.service";
import { SocialPageInfo } from "../../domain/entity/socialPageInfo";

export const findFacebookPageEpic: Epic = (action$: ActionsObservable<FindFacebookPageAction>,
                                           store: StateObservable<AppState>,
                                           {companyService}: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(FIND_FACEBOOK_PAGE),
        switchMap(() => companyService.findFacebookPagesInfo()
            .pipe(
                        map((facebookPages: SocialPageInfo[]) => findFacebookPageSucceeded(facebookPages)
                        ), catchError(error => of(findFacebookPageFailed(error))))
        )
    )
