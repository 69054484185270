import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { POST_INSTANT_ON_FACEBOOK, PostInstantOnFacebookAction } from "./actionTypes";
import { postInstantOnFacebookFailed, postInstantOnFacebookSucceeded } from "./actions";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";

export const postInstantOnFacebookEpic: Epic = (action$: ActionsObservable<PostInstantOnFacebookAction>,
                                                store: StateObservable<AppState>,
                                                {instantManagementService}: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(POST_INSTANT_ON_FACEBOOK),
        switchMap(action => instantManagementService.shareInstantOnFacebook(action.payload.page, action.payload.content)
            .pipe(
                map(() => postInstantOnFacebookSucceeded()),
                catchError(error => of(postInstantOnFacebookFailed(error)))
            )
        )
    )

