import React, { PureComponent, ReactNode } from "react";

interface Props {
    onClick: (image: File | undefined) => void;
}

interface State {
    image: File | undefined;
}

export class ImageButtonPicker extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            image: undefined
        }
    }

    render(): ReactNode {
        return (
            <div className={'position-relative icon-btn'}>
                <input type="file"
                       className={'message-image-input'}
                       onChange={e => {
                           if (e.target.files)
                               this.onChange(e.target.files[0])
                       }}/>

                <i className="fa fa-image"/>
            </div>
        )
    }

    onChange(image: File | undefined): void {
        if (image !== undefined) {
            this.setState({
                image,
            }, () => this.props.onClick(this.state.image))
        }
    }
}
