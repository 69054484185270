import {
    LOAD_COMPANY_DETAILS,
    LOAD_COMPANY_DETAILS_FAILED,
    LOAD_COMPANY_DETAILS_SUCCEEDED, LOAD_OWNED_COMPANY, LoadCompanyDetailsActionTypes, LoadOwnedCompanyAction
} from "./actionTypes";
import { Company } from "../../domain/entity/company";

export const loadCompanyDetails = (companyId: string): LoadCompanyDetailsActionTypes => ({
    type   : LOAD_COMPANY_DETAILS,
    payload: companyId
})

export const loadOwnedCompany = (): LoadOwnedCompanyAction => ({
    type: LOAD_OWNED_COMPANY
})

export const loadCompanyDetailsSucceeded = (company: Company): LoadCompanyDetailsActionTypes => ({
    type   : LOAD_COMPANY_DETAILS_SUCCEEDED,
    payload: company
})

export const loadCompanyDetailsFailed = (error: string): LoadCompanyDetailsActionTypes => ({
    type   : LOAD_COMPANY_DETAILS_FAILED,
    payload: error
})
