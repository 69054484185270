import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { BusinessInfo } from "../../components/businessInfo";
import { BusinessTypeSelector } from "../../components/inputs/businessType.selector";
import PrivacyAndCGU from "../../components/privacyAndCGU";
import { AdministrativeNumberInput } from "../../components/inputs/administrativeNumber.input";
import { AdministrativeNumber } from "../../../../../domain/entity/business/administrativeNumber";
import { Siret } from "../../../../../domain/entity/business/Siret";
import { RNA } from "../../../../../domain/entity/business/RNA";
import { Company } from "../../../../../../company/domain/entity/company";

interface Props {
    loading: boolean;
    loadingBusinessByCode: boolean
    registrationError: string | undefined;
    loadingError: string | undefined;
    businessByCode: Company | null;
    loadBusinessByCode: (code: string, stringifiedInputs: string) => void;
    defaultType: string | undefined;
    hasError: boolean;
    updateType: (type: string) => void;
    raiseUpdates: (key: 'administrativeNumber', value: AdministrativeNumber) => void;
    phoneNumber: string;
}

interface State {
    type: string;
    code: AdministrativeNumber;
    errorCode: boolean;
    errorMsg: string | undefined
}

export class BusinessRegistrationForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            type     : this.props.defaultType ? this.props.defaultType : 'commerce',
            code     : this.props.defaultType && this.props.defaultType === 'association' ? new RNA('') : new Siret(''),
            errorCode: false,
            errorMsg : undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.hasError && this.renderFormErrorMessage()) {
            this.setState({errorCode: !this.state.code.validateNumber(), errorMsg: this.renderFormErrorMessage()})
        } else if (this.props.loadingError && this.props.loadingError !== prevProps.loadingError) {
            this.setState({errorCode: true, errorMsg: this.renderLoadingErrorMessage(this.props.loadingError)})
        }
    }

    componentDidMount(): void {
        this.initiateFormWithUrlParameters()
    }

    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        return (
            <div className="wizard-forms pb-3">
                {loader}

                <div className="inner pb-4 pb-sm-5 clearfix registration-section">

                    <div className="wizard-form-field">
                        <div className="wizard-title">
                            <div className={'title-registration'}>
                                {this.props.defaultType && this.props.defaultType !== 'commerce' ? i18n.registration.company_info(this.props.defaultType) : i18n.registration.commerce_info}
                            </div>

                            <p className="wizard-sub-text">{this.getText()}</p>
                        </div>

                        <div className="px-3">
                            {!this.props.defaultType &&
                                <BusinessTypeSelector value={this.state.type}
                                                      className={'registration-input'}
                                                      disabled={this.props.defaultType !== undefined}
                                                      onChange={(type: string) => this.setState({
                                                          type,
                                                          code     : type === 'association' ? new RNA('') : new Siret(''),
                                                          errorCode: false,
                                                      }, () => {
                                                          this.props.updateType(type)
                                                      })}/>
                            }

                            <AdministrativeNumberInput className={'registration-input'}
                                                       value={this.state.code}
                                                       label={this.state.type === 'association' ? i18n.registration.rna : i18n.registration.siret}
                                                       error={this.state.errorCode}
                                                       raiseUpdates={(code: string) => this.onChangeCode(code)}/>

                            {this.renderBusinessInfo()}
                        </div>

                        {this.state.errorMsg && <p className={'error'}
                                                   dangerouslySetInnerHTML={{__html: this.state.errorMsg}}/>}
                        <PrivacyAndCGU type={this.state.type}/>
                    </div>
                </div>
            </div>
        )
    }

    private getText() {
        return this.props.defaultType === 'mairie' ?
            i18n.registration.register_mairie_description : this.props.defaultType === 'association' ? i18n.registration.register_association_description : i18n.registration.register_business_description;
    }

    onChangeCode(code: string): void {
        this.setState({
            code    : this.state.type === 'association' ? new RNA(code) : new Siret(code),
            errorMsg: undefined
        }, (): void => {
            this.props.raiseUpdates('administrativeNumber', this.state.type === 'association' ? new RNA(code) : new Siret(code))
            if (this.state.code.length() === this.state.code.number.length) {
                if (this.state.code.validateNumber()) {
                    const inputElements = document.getElementsByTagName('input');
                    const inputs: Array<string | undefined> = [];
                    for (let index = 0; index < inputElements.length; index++) {
                        const input = inputElements.item(index);
                        inputs.push(input?.value)
                    }
                    const stringifiedInputs: string = inputs.join(', ')

                    this.setState({
                        errorCode: false,
                        errorMsg : undefined
                    }, () => this.props.loadBusinessByCode(code, stringifiedInputs))
                } else {
                    this.setState({
                        errorCode: true,
                        errorMsg : this.state.type === 'association' ? i18n.registration.invalid_rna : i18n.registration.invalid_siret
                    })
                }
            }
        })
    }

    renderBusinessInfo(): JSX.Element | undefined {
        if (this.props.businessByCode && this.state.code.validateNumber())
            return (
                <div className="wizard-form-input">
                    <label/>
                    <BusinessInfo title={this.props.businessByCode.name}
                                  location={this.props.businessByCode.address}/>
                </div>
            )
        else if (this.props.loadingBusinessByCode)
            return (
                <div className="wizard-form-input">
                    <div className={'loader'}/>
                </div>
            )
    }

    renderFormErrorMessage(): string | undefined {
        if (this.state.code.number.length === 0) {
            if (this.state.type === 'association') {
                return i18n.registration.empty_rna_error
            } else {
                return i18n.registration.empty_siret_error
            }
        } else if (!this.state.code.validateNumber() && this.state.type !== 'association') {
            return i18n.registration.invalid_siret
        } else if (!this.state.code.validateNumber() && this.state.type === 'association') {
            return i18n.registration.invalid_rna
        }
    }

    renderLoadingErrorMessage(error: string): string {
        if (error === '409') {
            if (this.state.type === 'association') {
                return i18n.registration.rna_already_used(this.props.phoneNumber || undefined) + i18n.registration.contact_vivezici
            } else {
                return i18n.registration.siren_already_used(this.props.phoneNumber || undefined) + i18n.registration.contact_vivezici
            }
        } else if (error === '400') {
            if (this.state.type === 'association') {
                return i18n.registration.invalid_rna
            } else {
                return i18n.registration.invalid_siret
            }
        } else {
            return i18n.registration.server_error
        }
    }

    initiateFormWithUrlParameters(): void {
        const paramsString = window.location.search
        const searchParams = new URLSearchParams(paramsString);

        if (paramsString.length > 0) {
            const proSiret = searchParams.get('prosiret')
            if (proSiret) {
                if (/^([wW]).+/.test(proSiret)) {
                    this.setState({code: new RNA(proSiret), type: 'association'}, () => {
                        this.onChangeCode(proSiret)
                    })
                } else if (!isNaN(parseInt(proSiret)) && parseInt(proSiret) !== 0) {
                    this.setState({code: new Siret(proSiret)}, () => {
                        this.onChangeCode(proSiret)
                    })
                }
            }
        }
    }

}
