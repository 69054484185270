import {
    SEARCH_CITY, SEARCH_CITY_FAILED,
    SEARCH_CITY_SUCCEEDED,
    SearchCityAction, SearchCityFailedAction,
    SearchCitySucceededAction
} from "./actionTypes";
import { CityHeader } from "../../domain/entity/cityHeader";

export const searchCity = (keywords: string): SearchCityAction => ({
    type   : SEARCH_CITY,
    payload: keywords
})

export const searchCitySucceeded = (data: CityHeader[]|null): SearchCitySucceededAction => ({
    type   : SEARCH_CITY_SUCCEEDED,
    payload: data
})

export const searchCityFailed = (error: string): SearchCityFailedAction => ({
    type   : SEARCH_CITY_FAILED,
    payload: error
})
