export const CANCEL_ORDER = 'CANCEL_ORDER'
export const CANCEL_ORDER_FAILED = 'CANCERL_ORDER_FAILED'
export const CANCEL_ORDER_SUCCEEDED = 'CANCEL_ORDER_SUCCEEDED'

export interface CancelOrderAction {
    type: typeof CANCEL_ORDER;
    payload: {
        orderId: string;
        message: string;
    };
}

export interface CancelOrderFailedAction {
    type: typeof CANCEL_ORDER_FAILED;
    payload: string;
}

export interface CancelOrderSucceeded {
    type: typeof CANCEL_ORDER_SUCCEEDED;
}

export type CancelOrderActionTypes = CancelOrderAction | CancelOrderFailedAction | CancelOrderSucceeded
