import React, { MouseEvent, PureComponent } from "react";
import { i18n } from "../../../../../configuration/i18n";
import icon from '../../../../../assets/img/vivez-clichy-marker.jpeg';
import { ReactNode } from "react";

interface Props {
    companyId: string;
}

interface State {
    visible: boolean;
}

export class OpenMobileApplicationModal extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.setState({visible: true})
        }, 10000)
    }

    render(): ReactNode {
        if (this.state.visible) {
            return (
                <div className="modal mobile-modal default-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="header border-0">
                                <span className={'close-button'} onClick={(): void => this.setState({visible: false})}/>
                            </div>

                            <div className="body text-center">
                                <img src={icon} className={'broadcast-icon'} alt={'Vivez Ici'}/>
                                <p className={'mb-1'}>{i18n.company.install_app}</p>
                                <p>{i18n.company.vivez_clichy}</p>

                                <div className={'mt-3'}>
                                    <button className={'default-btn'} onClick={(event: MouseEvent): void => this.launchApp(event)}>
                                        {i18n.company.launch_app}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return <div/>
    }

    launchApp(event: MouseEvent): void {
        const url ="https://vparis.page.link/?link=https://www.vivezici.fr/public_info_page/?businessId=" +
            this.props.companyId + "&apn=com.vivezici.vivezparis&isi=6444020748&ibi=com.vivezici.vivezparis";
        event.preventDefault();
        this.setState({visible: false})
        window.location.replace(url);
    }
}
