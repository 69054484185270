import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../configuration/i18n";
import { PaymentStatus } from "../../../domain/entities/types/subscription.type";
import { PaymentFailContent } from "../paymentFail/paymentFail.content";
import { SuccessPaymentContent } from "./successPayement.content";
import { Company } from "../../../../company/domain/entity/company";

interface Props {
    paymentStatus: PaymentStatus | undefined;
    paymentError: string | undefined;
    loading: boolean;
    validatePayment: (sessionId: string, companyId: string) => void;
    company: Company | null;
}

interface State {
    paymentError: string | undefined;
    loading: boolean;
}

export class SuccessPaymentContainer extends PureComponent<Props, State> {
    private searchParams: URLSearchParams;
    private paramsString: string;
    private hasNotBeenValidated: boolean;

    constructor(props: Props) {

        super(props);
        this.hasNotBeenValidated = true
        this.state = {
            paymentError: undefined,
            loading: true
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.paymentError && this.props.paymentError !== prevProps.paymentError)
            this.setState({ paymentError: i18n.payment.server_error, loading: false })

        if (this.props.loading && this.props.loading !== prevProps.loading)
            this.setState({ loading: this.props.loading })

        if (this.props.paymentStatus && this.props.paymentStatus !== prevProps.paymentStatus)
            this.setState({ loading: false })

        this.validatePayment();
    }


    componentDidMount(): void {
        document.title = i18n.payment.payment_sucess;
        this.paramsString = window.location.search
        this.searchParams = new URLSearchParams(this.paramsString);
    }

    render(): ReactNode {

        const error = this.state.paymentError ? this.state.paymentError :
            this.props.paymentStatus === PaymentStatus.UNPAID ? i18n.payment.unpaid : i18n.payment.server_error

        const content = this.props.paymentStatus === PaymentStatus.PAID ?
            <SuccessPaymentContent membership={this.props.company?.membership.type}/>
            : <PaymentFailContent error={error}/>

        if (this.state.loading)
            return (
                <div>
                    <div className={'d-flex justify-content-center align-items-center w-100 h-100'}>
                        <div className={'loader'}/>
                    </div>
                </div>
            )
        else
            return (
                <div>
                    {content}
                </div>
            )
    }


    private validatePayment() {
        if (this.props.company && this.hasNotBeenValidated) {
            this.hasNotBeenValidated = false
            if (this.paramsString.length) {
                const sessionId = this.searchParams.get('session_id')
                if (sessionId) {
                    this.props.validatePayment(sessionId, this.props.company.id)
                } else {
                    this.setState({ paymentError: i18n.payment.has_no_payment, loading: false })
                }
            } else {
                this.setState({ paymentError: i18n.payment.has_no_payment, loading: false })
            }
        }
    }
}
