import {
    REGISTER_BUSINESS,
    REGISTER_BUSINESS_FAILED,
    REGISTER_BUSINESS_SUCCEEDED,
    RegisterBusinessActionTypes
} from "./actionTypes";
import { Company } from "../../../company/domain/entity/company";
import { User } from "../../domain/entity/user";

export const registerBusiness = (business: Company, user: User, originalProspectEmail: string | null): RegisterBusinessActionTypes => ({
    type   : REGISTER_BUSINESS,
    payload: {business, user, originalProspectEmail}
})

export const registerBusinessSucceeded = (businessId: string): RegisterBusinessActionTypes => ({
    type   : REGISTER_BUSINESS_SUCCEEDED,
    payload: businessId
})

export const registerBusinessFailed = (error: string): RegisterBusinessActionTypes => ({
    type   : REGISTER_BUSINESS_FAILED,
    payload: error
})
