import { CompanyOpeningHours } from "../entity/companyOpeningHours";
import { Company } from "../entity/company";
import { CompanyContact } from "../entity/companyContact";
import { CompanyAddress } from "../entity/companyAddress";
import { OpeningHoursType } from "../../../common/domain/types/openingHours.types";
import { ClickCollectPicture } from "../types/clickCollectPicture";
import { CompanyClickCollect } from "../entity/companyClickCollect";
import { CompanyMembership } from "../entity/companyMembership";
import { CompanyLegalInfo } from "../entity/companyLegalInfo";
import { ThemeConfiguration } from "../entity/ThemeConfiguration";
import { AdministrativeNumber } from "../../../registration/domain/entity/business/administrativeNumber";

export class CompanyBuilder {
    protected _id: string
    protected _sellerId: string|undefined

    protected _name: string
    protected _type: string
    protected _category: string
    protected _membership: CompanyMembership
    protected _administrativeNumber: AdministrativeNumber
    protected _address: string
    protected _zipcode: string
    protected _city: string
    protected _longitude: string
    protected _latitude: string

    protected _siren: string
    protected _coverImage: File
    protected _profileImage: File
    protected _cinFront: File
    protected _cinBack: File
    protected _coverImageUrl: string
    protected _profileImageUrl: string

    protected _openingHours: CompanyOpeningHours[] = []

    protected _description: string
    protected _website: string
    protected _email: string
    protected _phoneNumber: string
    protected _followersCount: number;

    protected _premiumSubdomain: string;

    protected _activeClickCollect: boolean
    protected _picturesClickCollect: ClickCollectPicture[]
    protected _descriptionClickCollect: string
    protected _legalInfo: CompanyLegalInfo
    protected _themeConfiguration: ThemeConfiguration

    withId(value: string): CompanyBuilder {
        this._id = value
        return this
    }
    withSellerId(value: string|undefined): CompanyBuilder {
        this._sellerId = value
        return this
    }

    withName(value: string): CompanyBuilder {
        this._name = value
        return this
    }

    withType(value: string): CompanyBuilder {
        this._type = value
        return this
    }

    withCategory(value: string): CompanyBuilder {
        this._category = value
        return this
    }

    withMembership(value: CompanyMembership): CompanyBuilder {
        this._membership = value
        return this
    }

    withAdministrativeNumber(value: AdministrativeNumber): CompanyBuilder {
        this._administrativeNumber = value
        return this
    }

    fromCompany(business: Company):CompanyBuilder{
        this._id = business.id
        this._sellerId = business.sellerId
        this._name = business.name
        this._type = business.type
        this._category = business.category
        this._coverImage = business.coverImage
        this._profileImage = business.profileImage
        this._cinFront = business.cinFront
        this._cinBack = business.cinBack

        this._membership = business.membership
        this._administrativeNumber = business.administrativeNumber

        this._description = business.contacts.description
        this._website = business.contacts.website
        this._email = business.contacts.email
        this._phoneNumber = business.contacts.phoneNumber

        this._address = business.address.address
        this._zipcode = business.address.zipCode
        this._latitude = business.address.latitude
        this._longitude = business.address.longitude
        this._city = business.address.city

        this._openingHours = business.openingHours

        return this
    }
    withAddress(value: string): CompanyBuilder {
        this._address = value
        return this
    }

    withZipCode(value: string): CompanyBuilder {
        this._zipcode = value
        return this
    }

    withCity(value: string): CompanyBuilder {
        this._city = value
        return this
    }

    withLongitude(value: string): CompanyBuilder {
        this._longitude = value
        return this
    }

    withLatitude(value: string): CompanyBuilder {
        this._latitude = value
        return this
    }

    withSiren(value: string): CompanyBuilder {
        this._siren = value
        return this
    }

    withCoverImage(value: File): CompanyBuilder {
        this._coverImage = value
        return this
    }

    withProfileImage(value: File): CompanyBuilder {
        this._profileImage = value
        return this
    }

    withCinFront(value: File): CompanyBuilder {
        this._cinFront = value
        return this
    }

    withCinBack(value: File): CompanyBuilder {
        this._cinBack = value
        return this
    }

    withCoverImageUrl(value: string): CompanyBuilder {
        this._coverImageUrl = value
        return this
    }

    withProfileImageUrl(value: string): CompanyBuilder {
        this._profileImageUrl = value
        return this
    }

    withOpeningHours(value: OpeningHoursType[]): CompanyBuilder {
        value.map(item => {
            this._openingHours.push(
                new CompanyOpeningHours(
                    item.id,
                    item.startTime,
                    item.endTime,
                    item.daytype,
                    item.isPaused
                )
            )
        })
        return this
    }

    withDescription(value: string): CompanyBuilder {
        this._description = value
        return this
    }

    withWebsite(value: string): CompanyBuilder {
        this._website = value
        return this
    }

    withEmail(value: string): CompanyBuilder {
        this._email = value
        return this
    }

    withPhoneNumber(value: string): CompanyBuilder {
        this._phoneNumber = value
        return this
    }

    withFollowersCount(value: number): CompanyBuilder {
        this._followersCount = value
        return this
    }

    withPremiumSubdomain(value: string): CompanyBuilder {
        this._premiumSubdomain = value
        return this
    }

    withDescriptionClickCollect(value: string): CompanyBuilder {
        this._descriptionClickCollect = value
        return this
    }

    withActiveClickCollect(value: boolean): CompanyBuilder {
        this._activeClickCollect = value
        return this
    }

    withPicturesClickCollect(value: ClickCollectPicture[]): CompanyBuilder {
        this._picturesClickCollect = value
        return this
    }

    withLegalInfo(value: CompanyLegalInfo): CompanyBuilder {
        this._legalInfo = value
        return this
    }

    withThemeConfiguration(value: ThemeConfiguration): CompanyBuilder {
        this._themeConfiguration = value
        return this
    }

    build(): Company {
        const contact = new CompanyContact(
            this._description,
            this._website,
            this._email,
            this._phoneNumber
        )

        const address = new CompanyAddress(
            this._address,
            this._zipcode,
            this._latitude,
            this._longitude,
            this._city
        )

        const clickCollect = new CompanyClickCollect(this._activeClickCollect, this._descriptionClickCollect, this._picturesClickCollect)

        return new Company(
            this._id,
            this._sellerId,
            this._name,
            this._type,
            this._category,
            this._membership,
            this._administrativeNumber,
            this._siren,
            this._coverImage,
            this._profileImage,
            this._cinFront,
            this._cinBack,
            this._coverImageUrl,
            this._profileImageUrl,
            this._followersCount,
            this._openingHours,
            contact,
            address,
            this._premiumSubdomain,
            clickCollect,
            this._legalInfo,
            this._themeConfiguration
        )
    }
}
