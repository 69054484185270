import { AppState } from "../../../redux-configuration/AppState";
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ManagementInstantHeader } from "../../domain/entities/managementInstantHeader";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";
import { LOAD_PREVIEW_INSTANTS_COMPANY, LoadPreviewInstantsCompanyAction } from "./loadPreviewInstantsCompany.types";
import {
    loadPreviewInstantsCompanyFailed,
    loadPreviewInstantsCompanySucceeded
} from "./loadPreviewInstantsCompany.actions";

export const loadPreviewInstantsCompanyEpic: Epic = (action$: ActionsObservable<LoadPreviewInstantsCompanyAction>,
                                              store: StateObservable<AppState>,
                                              { instantManagementService }: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(LOAD_PREVIEW_INSTANTS_COMPANY),
        switchMap(action => instantManagementService.retrievePreviewInstantsCompany(action.payload)
            .pipe(
                map((instants: ManagementInstantHeader[]) => loadPreviewInstantsCompanySucceeded(instants)),
                catchError(error => of(loadPreviewInstantsCompanyFailed(error)))
            ))
    )