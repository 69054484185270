import React from 'react';
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    allowedBroadcast: number;
    onClose: () => void;
}

export const BroadcastAlert = (props: Props): JSX.Element => {
    return (
        <div className={'broadcast-alert'} onClick={() => props.onClose()}>
            <i className="fa fa-times close-icon" aria-hidden="true"/>
            <p>{i18n.messaging.allowed_broadcast(props.allowedBroadcast)}</p>
        </div>
    )
}
