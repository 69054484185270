import { ChatUser } from '../entities/chatUser';
import { Conversation } from '../entities/conversation';

export class ConversationBuilder {
    protected _id: string
    protected _title: string
    protected _excerpt: string
    protected _updatedAt: Date
    protected _unreadMessages: number
    protected _users: ChatUser[]
    protected _owner: ChatUser
    protected _picture: string
    protected _isBlocked: boolean

    withId(value: string): ConversationBuilder {
        this._id = value
        return this
    }

    withTitle(value: string): ConversationBuilder {
        this._title = value
        return this
    }

    withExcerpt(value: string): ConversationBuilder {
        this._excerpt = value
        return this
    }

    withUpdatedAt(value: Date): ConversationBuilder {
        this._updatedAt = value
        return this
    }

    withUnreadMessage(value: number): ConversationBuilder {
        this._unreadMessages = value
        return this
    }

    withUsers(value: ChatUser[]): ConversationBuilder {
        this._users = value
        return this
    }

    withOwner(value: ChatUser): ConversationBuilder {
        this._owner = value
        return this
    }

    withPicture(value: string): ConversationBuilder {
        this._picture = value
        return this
    }

    blockConversation(conversation: Conversation): ConversationBuilder {
        this._id = conversation.id
        this._title = conversation.title
        this._excerpt = conversation.excerpt
        this._updatedAt = conversation.updatedAt
        this._unreadMessages = conversation.unreadMessages
        this._users = conversation.users
        this._owner = conversation.owner
        this._picture = conversation.picture
        this._isBlocked = true

        return this
    }

    build(): Conversation {
        return new Conversation(
            this._id,
            this._title,
            this._excerpt,
            this._updatedAt,
            this._unreadMessages,
            this._users,
            this._owner,
            this._picture,
            this._isBlocked
        )
    }
}
