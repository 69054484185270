export class Order {
    constructor(
        protected _id: string,
        protected _time: string,
        protected _content: string,
        protected _coupon: boolean,
        protected _textCoupon: string,
        protected _minOrder: string
    ) {
    }

    get id(): string {
        return this._id;
    }

    get time(): string {
        return this._time;
    }

    get content(): string {
        return this._content;
    }

    get coupon(): boolean {
        return this._coupon
    }

    get textCoupon(): string {
        return this._textCoupon
    }

    get minOrder(): string {
        return this._minOrder
    }
}
