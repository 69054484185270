import { ManagementInstantCoordinates } from "./ManagementInstantCoordinates";

export class ManagementInstantAddress {

    constructor(private _address: string,
                private _zipCode: number,
                private _city: string, private _coords: ManagementInstantCoordinates) {
    }

    get address(): string {
        return this._address;
    }

    get zipCode(): number {
        return this._zipCode;
    }

    get city(): string {
        return this._city;
    }

    get coords(): ManagementInstantCoordinates {
        return this._coords;
    }
}
