import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FIND_INSTAGRAM_ACCOUNT, FindInstagramAccountAction } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { findInstagramAccountFailed, findInstagramAccountSucceeded } from "./actions";
import { CompanyService } from "../../domain/gateway/company.service";
import { SocialPageInfo } from "../../domain/entity/socialPageInfo";

export const findInstagramAccountEpic: Epic = (action$: ActionsObservable<FindInstagramAccountAction>,
                                               store: StateObservable<AppState>,
                                               { companyService }: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(FIND_INSTAGRAM_ACCOUNT),
        switchMap(() => companyService.findInstagramPagesInfo()
            .pipe(
                map((instagramAccounts: SocialPageInfo[]) => findInstagramAccountSucceeded(instagramAccounts)),
                catchError(error => of(findInstagramAccountFailed(error))))
        )
    )
