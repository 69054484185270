import { combineEpics, Epic } from 'redux-observable';
import { loadCompanyDetailsEpic } from "../usecases/loadCompanyDetails/loadComapnyDetailsepic";
import { editCompanyEpic } from "../usecases/editCompany/epic";
import { findFacebookPageEpic } from "../usecases/findFacebookPage/epic";
import { findInstagramAccountEpic } from "../usecases/findInstagramAccount/epic";
import { loadFollowersEpic } from "../usecases/loadFollowers/epic";
import { updateSingleFieldEpic } from "../usecases/updateSingleField/epic";
import { loadOwnedCompanyEpic } from "../usecases/loadCompanyDetails/loadOwnedCompanyepic";
import { uploadAdvancedImageEpic } from "../usecases/uploadImage/epic";

export const companyEpic: Epic = combineEpics(
    loadCompanyDetailsEpic,
    loadOwnedCompanyEpic,
    editCompanyEpic,
    findFacebookPageEpic,
    findInstagramAccountEpic,
    loadFollowersEpic,
    updateSingleFieldEpic,
    uploadAdvancedImageEpic
)
