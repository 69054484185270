import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from "../../../../redux-configuration/AppState";
import { ConfirmationForm } from "./confirmation.form";
import { sendLoginConfirmationCodeErrorSelector, sendLoginConfirmationCodeLoadingSelector } from "../../../usecases/confirmation/selectors";
import { sendLoginConfirmationCode } from "../../../usecases/confirmation/action";
import { SendLoginConfirmationCodeActionTypes } from "../../../usecases/confirmation/actionTypes";
import {
    companyDetailsSelector, loadCompanyDetailsErrorSelector,
    loadCompanyDetailsLoadingSelector
} from "../../../../company/usecases/loadCompanyDetails/selector";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined;
    hasBusiness: boolean | null;
    businessError: string | undefined;
}

interface DispatchToPropsType {
    sendConfirmationCode: (phoneNumber: string, code: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading      : sendLoginConfirmationCodeLoadingSelector(state) || loadCompanyDetailsLoadingSelector(state),
    error        : sendLoginConfirmationCodeErrorSelector(state),
    hasBusiness  : companyDetailsSelector(state) !== null,
    businessError: loadCompanyDetailsErrorSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    sendConfirmationCode: (phoneNumber: string, code: string): SendLoginConfirmationCodeActionTypes => dispatch(sendLoginConfirmationCode(phoneNumber, code))
})

export const ConfirmationContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmationForm)
