import React from "react";
import { ChatMessage } from "../../../../domain/entities/chatMessage";

interface Props {
    message: ChatMessage
}

export const UserMessage = (props: Props): JSX.Element => {

    return (
        <div className={'user-message'}>
            <p dangerouslySetInnerHTML={{ __html: props.message.content }} />

            {props.message.image && <img src={props.message.image}/>}
        </div>
    )

}
