import React from "react";
import { i18n } from "../../../../../configuration/i18n";
import { TextInput } from "../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeConfigurationBlockType } from "../types";
import { ThemeCheckboxInput } from "./checkbox.input";
import { TextWithIconInput } from "../../../../../common/adapters/primaries/form/inputs/textWithIcon.input";

interface Props {
    data: ThemeConfigurationBlockType;
    isSubmitted: boolean
    onChange: (key: keyof ThemeConfigurationBlockType, value: string | boolean) => void;
}

export const ReviewBlockConfiguration = (props: Props): JSX.Element => {
    return (
        <div>
            <ThemeCheckboxInput label={'Activer les avis'}
                                checked={props.data.isActive}
                                onChange={(checked: boolean) => props.onChange('isActive', checked)}/>

            {props.data.isActive && (
                <div className={'mt-3'}>
                    <TextInput type={'text'}
                               error={props.isSubmitted && (!props.data.subTitle || props.data.subTitle.length === 0)}
                               label={i18n.company.sub_title}
                               value={props.data.subTitle ?? ''}
                               raiseUpdates={(value: string) => props.onChange('subTitle', value)}/>

                    <TextInput type={'color'}
                               error={props.isSubmitted && (!props.data.backgroundColor || props.data.backgroundColor.length === 0)}
                               label={i18n.company.background_color}
                               value={props.data.backgroundColor ?? ''}
                               raiseUpdates={(value: string) => props.onChange('backgroundColor', value)}/>

                    <TextWithIconInput type={'text'}
                                       icon={'fa fa-info-circle '}
                                       onIconClick={()=>  window.open('https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder', '_blank')}
                                       error={props.isSubmitted && (!props.data.placeId || props.data.placeId.length === 0)}
                                       label={i18n.company.website_place_id}
                                       value={props.data.placeId ?? ''}
                                       placeholder={'Place ID de votre compte Google My Business'}
                                       raiseUpdates={(value: string) => props.onChange('placeId', value)}/>
                </div>
            )}
        </div>
    )
}
