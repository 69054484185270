import React, { PureComponent, ReactNode } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
    label: string
    error: boolean
    value: string | undefined
    raiseUpdates: (value: string) => void
    onBlur?: () => void
    className?: string
}

export class PhoneInputField extends PureComponent<Props> {
    render(): ReactNode {
        const className = this.props.error ? 'error' : ''
        const containerClass = this.props.className ? this.props.className + ' wizard-form-input' : 'wizard-form-input'
        return (
            <div className={containerClass}>
                <label>{this.props.label}</label>
                <PhoneInput country={'fr'}
                            buttonStyle={{top: 1, bottom: 1}}
                            placeholder={''}
                            onlyCountries={['fr']}
                            inputClass={className}
                            onBlur={this.props.onBlur}
                            value={this.props.value}
                            countryCodeEditable={true}
                            disableDropdown={true}
                            onChange={(value, data, event, formattedValue) => this.props.raiseUpdates(formattedValue.replace(/\s/g, ''))}/>
            </div>
        )
    }
}
