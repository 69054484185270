import React, { PureComponent, ReactNode } from "react";
import { InstantHeader } from "../../../../domain/entites/instantHeader";
import { InstantTimeSpecification } from "../../../../domain/entites/specifications/time/instant.time.specification";
import './agendaInstantItem.css'
import { ApplicationIcons } from "../../../../../common/adapters/primaries/applicationIcons";

interface Props {
    instant: InstantHeader
    key: string
}

interface State {
    timeLeftColor: string;
    timeLeftStatus: string;
    timeLeftFormatted: string;
}

export class AgendaInstantItem extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            timeLeftColor    : this.determineTimeLeftColor(),
            timeLeftStatus   : this.determineTimeLeftStatus(),
            timeLeftFormatted: this.props.instant.formattedTimeLeft()
        }
    }

    render(): ReactNode {
        return (
            <div className="instant-card d-flex flex-wrap">
                <div className="block-image" style={{ backgroundImage: `url("${this.props.instant.picture}")` }}/>

                <div className="block-content">
                    <div className="d-flex justify-content-start align-items-center">
                        {ApplicationIcons(this.props.instant.category, 25, 28)}
                        <h3 className="title margin-10">
                            {this.props.instant.title}
                        </h3>
                    </div>

                    <span className="instant-description">{this.props.instant.description}</span>
                    <div className={'d-flex align-items-center'}>
                        <i className="far fa-clock"/>
                        <p className="instant-date margin-10" style={{ color: this.state.timeLeftColor }}>
                            {this.state.timeLeftStatus + ' ' + this.state.timeLeftFormatted}</p>
                    </div>
                </div>
            </div>
        )
    }

    private determineTimeLeftColor(): string {
        return InstantTimeSpecification.timeLeftColor(this.props.instant.timeLeftInSecond(), this.props.instant.isStarted())
    }

    private determineTimeLeftStatus(): string {
        return InstantTimeSpecification.timeLeftStatus(this.props.instant.timeLeftInSecond(), this.props.instant.isStarted())
    }
}
