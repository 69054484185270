import React from "react";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    visible: boolean;
    onClose: () => void;
    sendBroadcast: () => void
}

export const SendBroadcastModal = (props: Props): JSX.Element => {
    if (props.visible)
        return (
            <div className="modal order-modal default-modal broadcast-modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className={'header'}>
                            <h3 className={'title'}>{i18n.messaging.broadcast_msg}</h3>
                            <span className={'close-button'} onClick={() => props.onClose()}/>
                        </div>

                        <div className={'body'}>
                            <p>{i18n.messaging.send_broadcast_confirmation}</p>

                            <div className={'d-flex justify-content-end mt-3'}>
                                <button className={'default-btn back-btn mr-3'} onClick={() => props.onClose()}>
                                    {i18n.messaging.cancel}
                                </button>

                                <button className={'default-btn'} onClick={() => props.sendBroadcast()}>
                                    {i18n.messaging.send}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    return <div/>
}
