import { ApplicationContext } from "../../../../../configuration/application.context";
import { InstantHeaderDTO } from "../dto/instantHeader.dto";
import { InstantHeader } from "../../../../domain/entites/instantHeader";
import { InstantHeaderBuilder } from "../../../../domain/entites/instantHeader.builder";
import { CategoryType } from "../../../../domain/entites/types/categoryType";
import { DelayType } from "../../../../domain/entites/types/delayType";


const moment = ApplicationContext.getInstance().momentJs()

export class InstantMapper {

    static mapToArrayInstantHeaders(remoteInstants: InstantHeaderDTO[]): InstantHeader[] {
        const instantHeaderArray: InstantHeader[] = []
        remoteInstants.map(remoteInstant => {
            remoteInstant.activationTimes.map(activationTime => {
                instantHeaderArray.push(new InstantHeaderBuilder()
                    .withId(remoteInstant.id)
                    .withTitle(remoteInstant.title)
                    .withCategory(this.convertIdToCategory(remoteInstant.categoryId))
                    .withPicture(remoteInstant.iconUrl)
                    .withDescription(remoteInstant.shortDescription)
                    .withLatitude(remoteInstant.latitude)
                    .withLongitude(remoteInstant.longitude)
                    .withDelay(this.convertToDomainDelay(activationTime.activationTimeType))
                    .withOriginalStartDate(this.getDate(
                        activationTime.startDateTime
                    ))
                    .withActivationStartDate(this.getDate(
                        activationTime.activationStartDateTime
                    ))
                    .withActivationEndDate(this.getDate(
                        activationTime.endDateTime
                    ))
                    .withBusinessId(remoteInstant.companyId)
                    .build()
                )
            })
        })
        return instantHeaderArray
    }

    public static getDate(dateTime: string): Date {
        return moment(dateTime, 'YYYY-MM-DDTHH:mm:ss').toDate()
    }

    private static convertToDomainDelay(activationTimeType: 'during_instant' | 'just_before_and_during_instant'): DelayType {
        if (activationTimeType === 'during_instant')
            return 'DURING'
        else
            return 'DURING_AND_BEFORE_TWO_HOUR'
    }

    private static convertIdToCategory(id: number): CategoryType {
        const categories: { [key: number]: CategoryType } = {
            1 : 'cultural',
            2 : 'sportive',
            3 : 'discovery',
            4 : 'gourmand',
            5 : 'festive',
            6 : 'heart',
            7 : 'info',
            8 : 'offer',
            9 : 'gift',
            10: 'musical'
        }
        return categories[id]
    }
}
