import React, { PureComponent, ReactNode } from "react";
import { StatsCompany } from "../../../domain/entity/statsCompany";
import { StatsProfile } from "../../../domain/entity/statsProfile";
import { UsersChart } from "../components/users/users.chart";
import { CompanyTabs } from "../components/companies/company.tabs";
import { i18n } from "../../../../configuration/i18n";
import { StatCard } from "../components/card/statCard";
import { AppCategories, AssociationCategories } from "../../../../common/domain/types/app.categories";
import { Company } from "../../../../company/domain/entity/company";
import './stats.css'
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";

export interface CompanyDataType {
    name: string;
    category: string;
    address: string;
    icon: string;
    subdomain: string;
    email: string;
    creationDate: Date
}

interface Props {
    company: Company | null;
    companies: StatsCompany[] | undefined;
    profiles: StatsProfile[] | undefined;
    retrieveStats: (cityCode: string) => void;
}

interface State {
    commerce: CompanyDataType[];
    entreprise: CompanyDataType[];
    association: CompanyDataType[];
    cumulativeDataSets: Map<string, number>
}

export class StatsContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            commerce          : [],
            entreprise        : [],
            association       : [],
            cumulativeDataSets: new Map<string, number>(),
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.company && this.props.company !== prevProps.company) {
            this.props.retrieveStats(this.props.company.address.zipCode)
        }

        if (this.props.companies && this.props.companies !== prevProps.companies) {
            this.setCompanies()
        }
    }

    componentDidMount(): void {
        document.title = i18n.stats.stats_page_title;
        if (this.props.company) {
            this.props.retrieveStats(this.props.company.address.zipCode)
        }
    }

    render(): ReactNode {
        if (this.props.profiles && this.props.companies) {
            return (
                <div>
                    <DefaultPageHeader title={i18n.stats.clichy_stats}/>

                    <div className="container-fluid custom-width-1260">
                        <div className="d-flex flex-wrap mt-3">
                            <StatCard title={i18n.stats.inscription} number={this.renderProfilesNumber()}/>
                            <StatCard title={i18n.stats.commerces} number={this.state.commerce.length}/>
                            <StatCard title={i18n.stats.associations} number={this.state.association.length}/>
                            <StatCard title={i18n.stats.entreprises} number={this.state.entreprise.length}/>
                        </div>

                        <UsersChart profiles={this.props.profiles}/>

                        <CompanyTabs cumulativeChartDataSets={this.state.cumulativeDataSets}
                                     commerce={this.state.commerce}
                                     association={this.state.association}
                                     entreprise={this.state.entreprise}/>
                    </div>
                </div>
            )
        } else
            return (
                <div className={'show-loader'}>
                    <div className={'loader'}/>
                </div>
            )
    }

    renderProfilesNumber(): number {
        if (this.props.profiles && this.props.profiles.length > 0)
            return this.props.profiles[this.props.profiles.length - 1].count
        else
            return 0
    }

    setCompanies(): void {
        const cumulativeDataSets = new Map<string, number>()
        let sum = 0

        const commerce: CompanyDataType[] = []
        const entreprise: CompanyDataType[] = []
        const association: CompanyDataType[] = []
        if (this.props.companies) {
            this.props.companies.map((company: StatsCompany) => {

                cumulativeDataSets.set(company.stringCreationDate, ++sum)

                const category = company.category ? company.type === 'association' ?
                    AssociationCategories[company.category] : AppCategories[company.category] : ''

                const companyData: CompanyDataType = {
                    name        : company.name,
                    category,
                    address     : company.address,
                    icon        : company.icon,
                    subdomain   : company.subdomain,
                    email       : company.email,
                    creationDate: company.creationDate
                }

                if (company.type === 'commerce')
                    commerce.push(companyData)
                else if (company.type === 'entreprise')
                    entreprise.push(companyData)
                else if (company.type === 'association')
                    association.push(companyData)
            })
            this.setState({commerce, entreprise, association, cumulativeDataSets})
        }
    }
}
