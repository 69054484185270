import { CartesianData } from "../../domain/entity/cartesianData";

export const RETRIEVE_COMPANY_STATS = 'RETRIEVE_COMPANY_STATS'
export const RETRIEVE_COMPANY_STATS_SUCCEEDED = 'RETRIEVE_COMPANY_STATS_SUCCEEDED'
export const RETRIEVE_COMPANY_STATS_FAILED = 'RETRIEVE_COMPANY_STATS_FAILED'

export interface RetrieveCompanyStatsAction {
    type: typeof RETRIEVE_COMPANY_STATS;
    payload: string;
}

export interface RetrieveCompanyStatsSucceededAction {
    type: typeof RETRIEVE_COMPANY_STATS_SUCCEEDED;
    payload: {
        visits: CartesianData[] | undefined;
        followers: CartesianData[] | undefined;
    };
}

export interface RetrieveCompanyStatsFailedAction {
    type: typeof RETRIEVE_COMPANY_STATS_FAILED;
    payload: string;
}

export type RetrieveCompanyStatsActionTypes =
    RetrieveCompanyStatsAction
    | RetrieveCompanyStatsSucceededAction
    | RetrieveCompanyStatsFailedAction
