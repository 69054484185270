import {
    LOAD_ECOMMERCE_ORDERS, LOAD_ECOMMERCE_ORDERS_FAILED, LOAD_ECOMMERCE_ORDERS_SUCCEEDED,
    LoadEcommerceOrdersAction, LoadEcommerceOrdersFailedAction, LoadEcommerceOrdersSucceededAction
} from "./actionTypes";
import { EcommerceOrder } from "../../domain/entities/ecommerceOrder";

export const loadEcommerceOrders = (companyId: string): LoadEcommerceOrdersAction => ({
    type   : LOAD_ECOMMERCE_ORDERS,
    payload: companyId
})

export const loadEcommerceOrdersFailed = (error: string): LoadEcommerceOrdersFailedAction => ({
    type   : LOAD_ECOMMERCE_ORDERS_FAILED,
    payload: error
})

export const loadEcommerceOrdersSucceeded = (orders: EcommerceOrder[]): LoadEcommerceOrdersSucceededAction => ({
    type   : LOAD_ECOMMERCE_ORDERS_SUCCEEDED,
    payload: orders
})
