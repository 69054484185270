import { LOAD_FOLLOWERS, LOAD_FOLLOWERS_FAILED, LOAD_FOLLOWERS_SUCCEEDED, LoadFollowersActionTypes } from "./actionTypes";
import { LoadFollowersState } from "../../configuration/state";

const initialState: LoadFollowersState = {
    loading  : false,
    error    : undefined,
    followers: null
}
export const loadFollowersReducer = (state = initialState, action: LoadFollowersActionTypes): LoadFollowersState => {
    switch (action.type) {
        case LOAD_FOLLOWERS:
            return {loading: true, error: undefined, followers: null}
        case LOAD_FOLLOWERS_FAILED:
            return {loading: false, error: action.payload, followers: null}
        case LOAD_FOLLOWERS_SUCCEEDED:
            return {followers: action.payload, loading: false, error: undefined}
        default:
            return state
    }
}
