import React, { ReactNode } from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteProps } from "react-router";
import { DefaultPage } from "../../../common/adapters/primaries/defaultPage";

export interface Props extends RouteProps {
    isAuthenticated: boolean
}

const registrationPath = 'login';

export class PrivateArea extends Route<Props> {

    public render(): ReactNode {
        let redirectPath = ''

        if (!this.props.isAuthenticated) {
            redirectPath = registrationPath
        }

        if (redirectPath !== '') {
            const renderComponent = () => (<Redirect to={{ pathname: redirectPath }}/>);

            return <Route exact {...this.props} component={renderComponent} render={undefined}/>;

        } else {
            return <DefaultPage><Route exact {...this.props}/></DefaultPage>;
        }
    }
}
