import {
    UPDATE_PROFILE,
    UPDATE_PROFILE_FAILED,
    UPDATE_PROFILE_SUCCEEDED,
    UpdateProfileActionTypes
} from "./actionTypes";
import { AsyncState } from "../../configuration/state";

const initialState: AsyncState = {
    loading: false,
    success: null,
    error  : undefined
}

export const updateProfileReducer = (state = initialState, action: UpdateProfileActionTypes): AsyncState => {
    switch (action.type) {
        case UPDATE_PROFILE:
            return {
                loading: true,
                success: null,
                error  : undefined
            }
        case UPDATE_PROFILE_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case UPDATE_PROFILE_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
}
