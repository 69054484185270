import { User } from "../../domain/entity/user";
import {
    UPDATE_PROFILE,
    UPDATE_PROFILE_FAILED,
    UPDATE_PROFILE_SUCCEEDED,
    UpdateProfileActionTypes
} from "./actionTypes";

export const updateProfile = (user: User): UpdateProfileActionTypes => ({
    type   : UPDATE_PROFILE,
    payload: user
})

export const updateProfileSucceeded = (): UpdateProfileActionTypes => ({
    type   : UPDATE_PROFILE_SUCCEEDED
})

export const updateProfileFailed = (error: string): UpdateProfileActionTypes => ({
    type   : UPDATE_PROFILE_FAILED,
    payload: error
})
