import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    uploadAdvancedImageErrorSelector, uploadAdvancedImageLoadingSelector, uploadedAdvancedImageSelector
} from "../../../../../usecases/uploadImage/selector";
import { UploadAdvancedImageAction } from "../../../../../usecases/uploadImage/actionTypes";
import { uploadAdvancedImage } from "../../../../../usecases/uploadImage/actions";
import { AppState } from "../../../../../../redux-configuration/AppState";
import { ThemeImageInputContainer } from "./image.input";

interface StateToPropsType {
    newFileUrl: string | undefined;
    loading: boolean;
    error: string | undefined;
}

interface DispatchToPropsType {
    uploadImage: (image: File, companyId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    newFileUrl: uploadedAdvancedImageSelector(state),
    loading   : uploadAdvancedImageLoadingSelector(state),
    error     : uploadAdvancedImageErrorSelector(state)

})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    uploadImage: (image: File, companyId: string): UploadAdvancedImageAction => dispatch(uploadAdvancedImage(image, companyId))
})

export const ThemeImageInput = connect(mapStateToProps, mapDispatchToProps)(ThemeImageInputContainer)
