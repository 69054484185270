import { MunicipalService } from "../entities/municipalService";
import { ServiceIcon } from "../entities/serviceIcon";

export class MunicipalServiceBuilder {
    protected _id: string;
    protected _label: string;
    protected _type: string;
    protected _content: string;
    protected _rank: number;
    protected _icon: ServiceIcon;
    protected _active: boolean;

    withId(value: string): MunicipalServiceBuilder {
        this._id = value
        return this
    }

    withLabel(value: string): MunicipalServiceBuilder {
        this._label = value
        return this
    }

    withType(value: string): MunicipalServiceBuilder {
        this._type = value
        return this
    }

    withContent(value: string): MunicipalServiceBuilder {
        this._content = value
        return this
    }

    withRank(value: number): MunicipalServiceBuilder {
        this._rank = value
        return this
    }

    fromMunicipalService(service: MunicipalService): MunicipalServiceBuilder {
        this._id = service.id
        this._label = service.label
        this._type = service.type
        this._content = service.content
        this._rank = service.rank
        this._icon = service.icon
        this._active = service.active
        return this
    }

    withIcon(value: ServiceIcon): MunicipalServiceBuilder {
        this._icon = value
        return this
    }

    withActive(value: boolean): MunicipalServiceBuilder {
        this._active = value
        return this
    }

    build(): MunicipalService {
        return new MunicipalService(this._id, this._label, this._type, this._content, this._rank, this._icon, this._active)
    }
}
