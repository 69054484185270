export const MessagingI18n = {
    page_title                 : 'VivezICI | Messages',
    messages                   : 'Messages',
    sent_to_followers          : (number: string | number): string => `Message envoyé à ${number} abonnés`,
    order_cancellation         : 'Annulation de commande',
    order_validation           : 'Validation de commande',
    back                       : 'Retour',
    send                       : 'Envoyer',
    cancel_msg                 : 'Message d\'annulation:',
    validate_msg               : 'Message de validation:',
    error_message_401          : 'Vous n’êtes pas autorisés à modifier la commande',
    error_message_404          : 'La commande n\'existe pas',
    error_message_409          : 'Cette commande a déjà été traitée',
    date                       : 'Date',
    to                         : 'à',
    price                      : 'Total',
    empty_message_error        : 'Veuillez saisir un message d\'annulation',
    cancel                     : 'Annuler',
    validate                   : 'Valider',
    empty_broadcast_list       : 'Vous n\'avez pas de messages envoyés à vos abonnés',
    empty_conversation_list    : 'Vous n\'avez pas de conversations',
    allowed_conversations      : (number: number): string => 'Vous ne pouvez voir que les ' + number + ' dernières conversations',
    allowed_broadcast          : (number: number): string => 'Vous devez attendre lundi prochain pour envoyer un nouveau message groupé: vous ne pouvez envoyer que ' + number + ' message par semaine avec votre abonnement.',
    send_broadcast_confirmation: 'Souhaitez-vous vraiment envoyer ce message à tous vos abonnés ? ',
    broadcast_msg              : 'Message groupé',
}
