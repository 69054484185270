import { ManagementInstantHeader } from "../../domain/entities/managementInstantHeader";

export class InstantManagementSpecifications {

    private static specialOfferCategory = 'gift'
    private static EventCategories = ['cultural', 'sportive', 'discovery', 'heart', 'festive', 'info', 'musical']

    private _services: ManagementInstantHeader[] = []
    private _specialOffers: ManagementInstantHeader[] = []
    private _events: ManagementInstantHeader[] = []

    constructor(instants: ManagementInstantHeader[]) {
        instants.map((instant: ManagementInstantHeader) => {
            if (instant.category === InstantManagementSpecifications.specialOfferCategory) {
                this._specialOffers.push(instant)
            }
            else if (InstantManagementSpecifications.EventCategories.includes(instant.category) && instant.hasFixedActivationDayType()) {
                this._events.push(instant)
            }
            else {
                this._services.push(instant)
            }
        })
    }

    get services(): ManagementInstantHeader[] {
        return this._services;
    }

    get specialOffers(): ManagementInstantHeader[] {
        return this._specialOffers;
    }

    get events(): ManagementInstantHeader[] {
        return this._events;
    }

    static canAddInstant(instants: ManagementInstantHeader[], allowedInstants: number): boolean {
        return (allowedInstants === -1 || instants.length < allowedInstants)
    }
}
