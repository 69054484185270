export const DELETE_INSTANT = 'DELETE_INSTANT'
export const DELETE_INSTANT_SUCCEEDED = 'DELETE_INSTANT_SUCCEEDED'
export const DELETE_INSTANT_FAILED = 'DELETE_INSTANT_FAILED'

export interface DeleteInstantAction {
    type: typeof DELETE_INSTANT;
    payload: {
        instantId: string;
        companyId: string;
    };
}

export interface DeleteInstantSucceededAction {
    type: typeof DELETE_INSTANT_SUCCEEDED;
}

export interface DeleteInstantFailedAction {
    type: typeof DELETE_INSTANT_FAILED;
    payload: string;
}

export type DeleteInstantActionTypes =
    DeleteInstantAction
    | DeleteInstantSucceededAction
    | DeleteInstantFailedAction
