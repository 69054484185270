import { ManagementInstantAddress } from "./managementInstantAddress";
import { ManagementInstantActivationTime } from "./managementInstantActivationTime";
import { ManagementInstantInformation } from "./managementInstantInformation";

export class ManagementInstant {

    constructor(private _instantInformation: ManagementInstantInformation,
                private _address: ManagementInstantAddress,
                private _instantActivationTimes: ManagementInstantActivationTime[],
                private _notificationSent: boolean,
                private _notificationDate: string,
                private _instantStatus: boolean,
    ) {
    }


    get notificationDate(): string {
        return this._notificationDate;
    }

    get instantInformation(): ManagementInstantInformation {
        return this._instantInformation;
    }

    get instantActivationTimes(): ManagementInstantActivationTime[] {
        return this._instantActivationTimes;
    }

    get address(): ManagementInstantAddress {
        return this._address;
    }

    get notificationSent(): boolean {
        return this._notificationSent;
    }

    get instantStatus(): boolean {
        return this._instantStatus;
    }
}
