import { ContentPostType } from "../../../../../domain/entities/types/contentPostType";
import { connect } from "react-redux";
import { AppState } from "../../../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { SocialPageInfo } from "../../../../../../company/domain/entity/socialPageInfo";
import { FindInstagramAccountAction } from "../../../../../../company/usecases/findInstagramAccount/actionTypes";
import { findInstagramAccount } from "../../../../../../company/usecases/findInstagramAccount/actions";
import { PostInstantOnInstagramAction } from "../../../../../usecases/postOnInstagram/actionTypes";
import { postInstantOnInstagram } from "../../../../../usecases/postOnInstagram/actions";
import { findInstagramAccountErrorSelector, instagramAccountSelector } from "../../../../../../company/usecases/findInstagramAccount/selectors";
import { postInstantOnInstagramLoadingSelector } from "../../../../../usecases/postOnInstagram/selectors";
import { PostInstagramModalContainer } from "./postInstagramModalContainer";

interface StateToPropsType {
    loading: boolean;
    instagramAccounts: SocialPageInfo[] | null;
    errorFindInstagramAccount: string | undefined;
}

interface DispatchToPropsType {
    postInstant: (page: SocialPageInfo, content: ContentPostType) => void;
    findInstagramAccounts: () => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading            : postInstantOnInstagramLoadingSelector(state),
    instagramAccounts         : instagramAccountSelector(state),
    errorFindInstagramAccount: findInstagramAccountErrorSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    postInstant  : (page: SocialPageInfo, content: ContentPostType): PostInstantOnInstagramAction => dispatch(postInstantOnInstagram(page, content)),
    findInstagramAccounts: (): FindInstagramAccountAction => dispatch(findInstagramAccount())
})

export const PostInstagramModal = connect(mapStateToProps, mapDispatchToProps)(PostInstagramModalContainer)
