import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Conversation } from "../../../domain/entities/conversation";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { BroadcastConversationDTO, ChatMessageDto, ConversationDto } from "./dto/conversation.dto";
import { MessagingMapper } from "./mapper/messaging.mapper";
import { ChatMessage } from "../../../domain/entities/chatMessage";
import { BroadcastConversation } from "../../../domain/entities/broadcastConversation";
import { MessagingService } from "../../../domain/gateway/messaging.service";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";

export class ApiMessagingService implements MessagingService {

    loadPreviousChatMessages(conversationId: string, messageId: number): Observable<ChatMessage[]> {
        const url = messageId === 0 ?
            process.env.REACT_APP_API_URL + '/v1/discussions/' + conversationId + '/messages'
            : process.env.REACT_APP_API_URL + '/v1/discussions/' + conversationId + '/messages?selection_way=before&message_id=' + messageId

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: ChatMessageDto[] }>(url).pipe(
            map(result => MessagingMapper.mapChatMessageDtoToChatMessages(result.data)),
            catchError(err => throwError(err))
        )
    }

    loadNewChatMessages(conversationId: string, messageId: number): Observable<ChatMessage[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/discussions/${conversationId}/messages?selection_way=after&message_id=${messageId}`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: ChatMessageDto[] }>(url).pipe(
            map(result => MessagingMapper.mapChatMessageDtoToChatMessages(result.data)),
            catchError(err => throwError(err))
        )
    }

    sendChatMessage(conversationId: string, companyId: string, message: string, image: File | undefined): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/discussions/${conversationId}/message`
        const body = new FormData()

        body.append('discussionId', conversationId)
        body.append('companyId', companyId)
        body.append('message', message)
        if (image) {
            body.append('image', image)
        }

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body).pipe(
            map(() => void 0),
            catchError(err => throwError(err))
        )
    }

    retrieveBusinessConversations(businessId: string): Observable<Conversation[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/chat/discussion?companyId=${businessId}`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: ConversationDto[] }>(url).pipe(
            map(result => MessagingMapper.mapConversationDtoToConversationArray(result.data)),
            catchError(err => throwError(err))
        )
    }

    loadBroadcastConversation(businessId: string): Observable<BroadcastConversation> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/' + businessId + '/broadcast'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: BroadcastConversationDTO }>(url).pipe(
            map(result => MessagingMapper.mapDtoConversationToBroadcastConversation(result.data)),
            catchError(err => throwError(err))
        )
    }

    sendBroadcastMessage(businessId: string, message: string, image: File | undefined): Observable<BroadcastConversation> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/' + businessId + '/broadcast'
        const body = new FormData()

        body.append('message', message)
        body.append('companyId', businessId)
        if (image) {
            body.append('image', image)
        }

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body).pipe(
            map((result: { response: { data: BroadcastConversationDTO } }) =>
                MessagingMapper.mapDtoConversationToBroadcastConversation(result.response.data)),
            catchError(err => throwError(err))
        )
    }
}
