import React, { PureComponent, ReactNode } from 'react'
import { Follower } from "../../../../domain/entity/follower";
import { i18n } from "../../../../../configuration/i18n";
import { FollowerItem } from "./followerItem";

interface Props{
    visible: boolean
    onClose:() => void
    followers: Follower[] | null
}
export class FollowersCompanyModal extends PureComponent<Props>{
    render(): ReactNode {
        const loader = this.props.followers === null ?  <div className={'show-loader'}>
            <div className={'loader'}/>
    </div> : <div/>
        if (this.props.visible)
            return (
                <div className="modal default-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="header">
                                <p className={'title'}>{i18n.company.followers_list}</p>
                                <span className={'close-button close_post_modal'} onClick={() => this.props.onClose()}/>
                            </div>
                            <div className="body ">
                                <div className={'followers_container'}>
                                    {loader}
                                {this.props.followers?.map(follower => <FollowerItem follower={follower} key={follower.phoneNumber} />)}
                                </div>
                            </div>
                            </div>
                    </div>
                </div>)
        return (<div/>)
    }

}
