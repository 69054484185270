import {
    UPDATE_SINGLE_FIELD,
    UPDATE_SINGLE_FIELD_FAILED,
    UPDATE_SINGLE_FIELD_SUCCEEDED,
    UpdateSingleFieldAction,
    UpdateSingleFieldFailedAction,
    UpdateSingleFieldSucceededAction,
} from "./actionTypes";
import { BusinessRegistrationType } from "../../../registration/domain/type/BusinessRegistrationType";
import { ValueOf } from "../../../common/domain/types/app.categories";

export const updateSingleField = (field: keyof BusinessRegistrationType, value: ValueOf<BusinessRegistrationType>): UpdateSingleFieldAction => ({
    type   : UPDATE_SINGLE_FIELD,
    payload: {field, value}
})

export const updateSingleFieldSucceeded = (): UpdateSingleFieldSucceededAction => ({
    type: UPDATE_SINGLE_FIELD_SUCCEEDED
})

export const updateSingleFieldFailed = (error: string): UpdateSingleFieldFailedAction => ({
    type   : UPDATE_SINGLE_FIELD_FAILED,
    payload: error
})
