import { UploadAdvancedImageState } from "../../configuration/state";
import {
    UPLOAD_ADVANCED_IMAGE,
    UPLOAD_ADVANCED_IMAGE_FAILED,
    UPLOAD_ADVANCED_IMAGE_SUCCEEDED,
    UploadAdvancedImageActionTypes
} from "./actionTypes";

const initialState: UploadAdvancedImageState = {
    loading : false,
    imageUrl: undefined,
    error   : undefined
}

export const uploadAdvancedImageReducer = (state = initialState, action: UploadAdvancedImageActionTypes): UploadAdvancedImageState => {
    switch (action.type) {
        case UPLOAD_ADVANCED_IMAGE:
            return {
                loading : true,
                imageUrl: undefined,
                error   : undefined
            }
        case UPLOAD_ADVANCED_IMAGE_SUCCEEDED:
            return {
                loading : false,
                imageUrl: action.payload,
                error   : undefined
            }
        case UPLOAD_ADVANCED_IMAGE_FAILED:
            return {
                loading : false,
                imageUrl: undefined,
                error   : action.payload
            }
        default:
            return state
    }
}
