import {
    ADD_PRODUCT, ADD_PRODUCT_FAILED, ADD_PRODUCT_SUCCEEDED,
    AddProductAction, AddProductFailedAction, AddProductSucceededAction,
} from "./actionTypes";
import { ProductDetails } from "../../domain/entities/productDetails";

export const addProduct = (product: ProductDetails, companyId: string): AddProductAction => ({
    type   : ADD_PRODUCT,
    payload: {product, companyId}
})

export const addProductFailed = (error: string): AddProductFailedAction => ({
    type   : ADD_PRODUCT_FAILED,
    payload: error
})

export const addProductSucceeded = (): AddProductSucceededAction => ({
    type: ADD_PRODUCT_SUCCEEDED
})
