import { AppState } from "../../../redux-configuration/AppState";
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { EDIT_MUNICIPAL_SERVICE, EditMunicipalServiceAction } from "./actionTypes";
import {
    editMunicipalServiceFailed, editMunicipalServiceSucceeded
} from "./actions";
import { MunicipalityService } from "../../domain/gateway/municipality.service";
import { listingMunicipalServicesSucceeded } from "../listingMunicipalServices/actions";
import { MunicipalService } from "../../domain/entities/municipalService";

export const editMunicipalServiceEpic: Epic = (action$: ActionsObservable<EditMunicipalServiceAction>,
                                              store: StateObservable<AppState>,
                                              {municipalityService}: { municipalityService: MunicipalityService }) =>
    action$.pipe(
        ofType(EDIT_MUNICIPAL_SERVICE),
        switchMap(action => municipalityService.editMunicipalService(action.payload)
            .pipe(
                concatMap((services: MunicipalService[]) => [
                    listingMunicipalServicesSucceeded(services),
                    editMunicipalServiceSucceeded()
                ]),
                catchError(error => of(editMunicipalServiceFailed(error)))
            )
        )
    )
