import React, { PureComponent, ReactNode } from "react";
import association from '../../../../../assets/img/companyType/association.png'
import entreprise from '../../../../../assets/img/companyType/entreprise.png'
import commerce from '../../../../../assets/img/companyType/commerce.jpg'
import mairie from '../../../../../assets/img/companyType/mairie.png'
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    type: string | undefined
    step: number
}

export class HeaderForm extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div>
                <img src={this.getPicture(this.props.type)} alt={'VivezIci'}
                     className={'img-fluid w-100'}/>
                {this.getTitle(this.props.step)}
            </div>
        )
    }

    getTitle(step: number): JSX.Element {
        const title = this.props.type === 'mairie' ?
            i18n.registration.create_mairie_account : this.props.type === 'association' ? i18n.registration.create_mairie_association : i18n.registration.create_pro_account
        switch (step) {
            case 1:
                return <h2 className="title">{title}</h2>
            case 2:
                return <h2
                    className="title ">{i18n.registration.professional_info_title(this.props.type ? this.props.type : 'commerce')}</h2>
            case 3:
                return (
                    <div className={'success-header d-sm-flex align-items-center justify-content-center'}>
                        <h2 className="title">{i18n.registration.review_title}</h2>
                        <div className="large-check-mark">
                            <i className="fas fa-check"/>
                        </div>
                    </div>
                )
            default:
                return <h2 className="title">{i18n.registration.create_pro_account}</h2>
        }
    }

    getPicture(type: string | undefined): string {
        switch (type) {
            case 'association':
                return association;
            case 'entreprise':
                return entreprise;
            case 'mairie':
                return mairie;
            default:
                return commerce;
        }
    }
}
