import { EDIT_INSTANT, EDIT_INSTANT_FAILED, EDIT_INSTANT_SUCCEEDED, EditInstantActionTypes } from "./editInstant.types";
import { EditInstantState } from "../../configuration/state";

const initialState: EditInstantState = {
    isLoading: false,
    error    : undefined,
    success  : null
}

export const editInstantReducer = (state = initialState, action: EditInstantActionTypes): EditInstantState => {
    switch (action.type) {
        case EDIT_INSTANT:
            return {
                isLoading: true,
                error    : undefined,
                success  : null,
            }
        case EDIT_INSTANT_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                error    : undefined,
                success  : true,
            }
        case EDIT_INSTANT_FAILED:
            return {
                ...state,
                isLoading: false,
                error    : action.payload,
                success  : false
            }
        default:
            return state
    }
}
