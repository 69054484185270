import { AppState } from "../../../../redux-configuration/AppState";
import { connect } from "react-redux";
import { SuccessPaymentContainer } from "./successPayment.container";
import { PaymentStatus } from "../../../domain/entities/types/subscription.type";
import { Dispatch } from "redux";
import { ValidatePaymentAction } from "../../../usecases/validatePayment/actionTypes";
import { validatePayment } from "../../../usecases/validatePayment/action";
import {
    paymentStatusSelector,
    validatePaymentErrorSelector,
    validatePaymentLoadingSelector
} from "../../../usecases/validatePayment/selector";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import { Company } from "../../../../company/domain/entity/company";

interface StateToPropsType {
    paymentStatus: PaymentStatus | undefined;
    paymentError: string | undefined;
    loading: boolean;
    company: Company | null;
}

interface DispatchToPropsType {
    validatePayment: (sessionId: string, companyId: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    validatePayment: (sessionId: string, companyId: string): ValidatePaymentAction => dispatch(validatePayment(sessionId, companyId))
})

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading      : validatePaymentLoadingSelector(state),
    paymentStatus: paymentStatusSelector(state),
    paymentError : validatePaymentErrorSelector(state),
    company      : companySelector(state)
})
export const PaymentSuccess = connect(mapStateToProps, mapDispatchToProps)(SuccessPaymentContainer)
