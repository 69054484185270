import { ApplicationContext } from "../../../../../configuration/application.context";
import { CompanyOpeningHours } from "../../../../domain/entity/companyOpeningHours";
import { i18n } from "../../../../../configuration/i18n";

export interface CompanyOpeningDays {
    day: string;
    hour: string[];
}

const moment = ApplicationContext.getInstance().momentJs()

export class CompanySpecifications {

    static getCompanyOpeningDays(openingHours: CompanyOpeningHours[]): CompanyOpeningDays[] {
        const dailyOpeningHours: CompanyOpeningDays[] = [
            { day: i18n.company.monday, hour: [] },
            { day: i18n.company.tuesday, hour: [] },
            { day: i18n.company.wednesday, hour: [] },
            { day: i18n.company.thursday, hour: [] },
            { day: i18n.company.friday, hour: [] },
            { day: i18n.company.saturday, hour: [] },
            { day: i18n.company.sunday, hour: [] }
        ]
        openingHours.map(item => {
            if (CompanySpecifications.isInRange(i18n.company.monday, item.openingDays, item.isPaused))
                dailyOpeningHours[0].hour.push(moment(item.startTime, 'HH:mm:ssZ').format('HH:mm')
                    + ' - ' + moment(item.endTime, 'HH:mm:ssZ').format('HH:mm'))

            if (CompanySpecifications.isInRange(i18n.company.tuesday, item.openingDays, item.isPaused))
                dailyOpeningHours[1].hour.push(moment(item.startTime, 'HH:mm:ssZ').format('HH:mm')
                    + ' - ' + moment(item.endTime, 'HH:mm:ssZ').format('HH:mm'))

            if (CompanySpecifications.isInRange(i18n.company.wednesday, item.openingDays, item.isPaused))
                dailyOpeningHours[2].hour.push(moment(item.startTime, 'HH:mm:ssZ').format('HH:mm')
                    + ' - ' + moment(item.endTime, 'HH:mm:ssZ').format('HH:mm'))

            if (CompanySpecifications.isInRange(i18n.company.thursday, item.openingDays, item.isPaused))
                dailyOpeningHours[3].hour.push(moment(item.startTime, 'HH:mm:ssZ').format('HH:mm')
                    + ' - ' + moment(item.endTime, 'HH:mm:ssZ').format('HH:mm'))

            if (CompanySpecifications.isInRange(i18n.company.friday, item.openingDays, item.isPaused))
                dailyOpeningHours[4].hour.push(moment(item.startTime, 'HH:mm:ssZ').format('HH:mm')
                    + ' - ' + moment(item.endTime, 'HH:mm:ssZ').format('HH:mm'))

            if (CompanySpecifications.isInRange(i18n.company.saturday, item.openingDays, item.isPaused))
                dailyOpeningHours[5].hour.push(moment(item.startTime, 'HH:mm:ssZ').format('HH:mm')
                    + ' - ' + moment(item.endTime, 'HH:mm:ssZ').format('HH:mm'))

            if (CompanySpecifications.isInRange(i18n.company.sunday, item.openingDays, item.isPaused))
                dailyOpeningHours[6].hour.push(moment(item.startTime, 'HH:mm:ssZ').format('HH:mm')
                    + ' - ' + moment(item.endTime, 'HH:mm:ssZ').format('HH:mm'))
        })
        dailyOpeningHours.map((item: CompanyOpeningDays) => {
            if (item.hour.length === 0) {
                item.hour = [i18n.company.closed]
            }
            return item
        })
        return dailyOpeningHours
    }

    static isInRange(day: string, range: string, isPaused: boolean): boolean {
        switch (day) {
            case i18n.company.monday:
                return ['all_days', 'mondays_to_fridays', 'mondays_to_saturdays', 'all_mondays'].includes(range) && isPaused === false
            case  i18n.company.tuesday:
                return ['all_days', 'mondays_to_fridays', 'tuesdays_to_fridays', 'mondays_to_saturdays', 'tuesdays_to_saturdays', 'all_tuesdays'].includes(range) && isPaused === false
            case  i18n.company.wednesday:
                return ['all_days', 'mondays_to_fridays', 'tuesdays_to_fridays', 'mondays_to_saturdays', 'tuesdays_to_saturdays', 'all_wednesdays'].includes(range) && isPaused === false
            case  i18n.company.thursday:
                return ['all_days', 'mondays_to_fridays', 'tuesdays_to_fridays', 'mondays_to_saturdays', 'tuesdays_to_saturdays', 'all_thursdays'].includes(range) && isPaused === false
            case  i18n.company.friday:
                return ['all_days', 'mondays_to_fridays', 'tuesdays_to_fridays', 'mondays_to_saturdays', 'tuesdays_to_saturdays', 'all_fridays'].includes(range) && isPaused === false
            case  i18n.company.saturday:
                return ['all_days', 'saturdays_and_sundays', 'mondays_to_saturdays', 'tuesdays_to_saturdays', 'all_saturdays'].includes(range) && isPaused === false
            case  i18n.company.sunday:
                return ['all_days', 'saturdays_and_sundays', 'all_sundays'].includes(range) && isPaused === false
            default:
                return false
        }
    }
}
