import { User } from "../../../../domain/entity/user";
import { UserDto } from "../dto/user.dto";
import { UserBuilder } from "../../../../domain/builder/user.builder";

const moment = require('moment');

export class UserMapper {

    static mapUserEntityToApiUser(user: User): UserDto {
        return {
            first_name   : user.firstName,
            last_name    : user.lastName,
            date_of_birth: moment(user.birthDate).format('YYYY-MM-DD'),
            gender       : '',
            email        : user.email,
            zip_code     : user.zipCode,
            city         : user.city
        }
    }

    static mapLocalUser(data: string): User {
        const localUser = JSON.parse(data)
        const user: User = new UserBuilder()
            .withFirstName(localUser._firstName)
            .withLastName(localUser._lastName)
            .withPhoneNumber(localUser._phoneNumber)
            .withBirthDate(localUser._birthDate)
            .withEmail(localUser._email)
            .withCity(localUser._city)
            .withZipCode(localUser._zipcode)
            .build()
        return user
    }
}
