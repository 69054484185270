import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { RETRIEVE_SUPER_ADMIN_STATS, RetrieveSuperAdminStatsAction } from "./retrieveStats.types";
import { AppState } from "../../../redux-configuration/AppState";
import { StatsService } from "../../domain/gateway/StatsService";
import { retrieveSuperAdminStatsFailed, retrieveSuperAdminStatsSucceeded } from "./retrieveStats.actions";
import { StatsCompany } from "../../domain/entity/statsCompany";

export const retrieveSuperAdminStatsEpic: Epic = (action$: ActionsObservable<RetrieveSuperAdminStatsAction>,
                                                  store: StateObservable<AppState>,
                                                  { statsService }: { statsService: StatsService }) =>
    action$.pipe(
        ofType(RETRIEVE_SUPER_ADMIN_STATS),
        switchMap(action => statsService.retrieveSuperAdminStats(action.payload)
            .pipe(
                map(({profiles, companies}) => retrieveSuperAdminStatsSucceeded(profiles, cleanCompanies(companies))),
                catchError(err => of(retrieveSuperAdminStatsFailed(err)))
            )
        )
    )


const cleanCompanies = (companies: StatsCompany[]): StatsCompany[] =>
    companies.filter((item, index, items) => items.findIndex(element => element.id === item.id) === index)
