import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    label: string;
    value: string | undefined;
    error: boolean;
    raiseUpdates: (file: File) => void;
    className?: string;
    errorMessage?: string;
}

export class PictureInput extends PureComponent<Props> {

    render(): ReactNode {
        const className = this.props.error ? 'error custom-file' : 'custom-file'
        const classContainer = this.props.className ? this.props.className + ' wizard-document-upload wizard-form-input' : 'wizard-document-upload wizard-form-input'
        const errorMessage = this.props.errorMessage && this.props.errorMessage !=='' && <div className={'error_picture'}>{this.props.errorMessage}</div>
        return (
            <div className={classContainer}>
                <label>{this.props.label}</label>
                <div className={className}>
                    <input type="file"
                           id="customFile"
                           className="custom-file-input"
                           onChange={e => {
                               if (e.target.files)
                                   this.props.raiseUpdates(e.target.files[0])
                           }}/>

                    {this.renderContent()}
                    {errorMessage}
                </div>
            </div>
        )
    }

    renderContent(): JSX.Element {
        if (this.props.value)
            return (
                <label className={'custom-file-label active'} style={{backgroundColor: '#aaaaaa'}} htmlFor="customFile">
                    <img className={'picture-uploaded'} src={this.props.value}/>
                </label>
            )
        else
            return (
                <label className={'custom-file-label'} htmlFor="customFile">
                    <span>{i18n.registration.image_placeholder}</span>
                </label>
            )
    }
}
