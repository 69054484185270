import React, { PureComponent, ReactNode } from 'react'
import { Follower } from "../../../../domain/entity/follower";

interface Props{
    follower:Follower
}
export class FollowerItem extends PureComponent<Props>{
    render(): ReactNode {
        return <div className={'col-sm-6'}>
            <div className={'follower_item'}>
            <img className={'follower_img'} src={this.props.follower.imageURL}/>
            <div className={'follower_text'}>
                <div className={'follower_name'}>{this.props.follower?.firstName} {this.props.follower?.lastName.charAt(0)}.</div>
                <div>{this.props.follower.city}</div>
            </div>
                <div className={'follow_date'}>{this.props.follower.followDate}</div>

            </div>
        </div>
    }
}
