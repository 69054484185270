import React, { useEffect, useState } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeConfigurationBlockType } from "../../types";
import { ThemeCheckboxInput } from "../checkbox.input";
import { GalleryImageInput } from "./image.input";

interface Props {
    isSubmitted: boolean;
    type: 'address' | 'gallery';
    data: ThemeConfigurationBlockType;
    companyId: string;
    loading: boolean;
    error: string | undefined;
    imageUrl: string | undefined;
    uploadImage: (image: File, companyId: string) => void;
    onChange: (key: keyof ThemeConfigurationBlockType, value: string[] | string | boolean) => void;
}

export const BlockWithImagesContainer = (props: Props): JSX.Element => {

    const [hasNewImage, setHasNewImage] = useState<boolean>(false)
    const isActiveLabel = props.type === 'gallery' ? 'Activer la galerie' : 'Activer l\'adresse'
    const imagesLabel = props.type === 'gallery' ? i18n.company.min_6_images : i18n.company.images

    useEffect(() => {
        if (props.imageUrl && hasNewImage) {
            const images = props.data.images ? [...props.data.images] : []
            props.onChange('images', [...images, props.imageUrl])
            setHasNewImage(false)
        }
    }, [props.imageUrl])

    return (
        <div id={"theme-gallery"}>
            <ThemeCheckboxInput label={isActiveLabel}
                                checked={props.data.isActive}
                                onChange={(checked: boolean) => props.onChange('isActive', checked)}/>

            {props.data.isActive && (
                <div className={'mt-3'}>
                    <TextInput type={'text'}
                               error={props.isSubmitted && (!props.data.subTitle || props.data.subTitle.length === 0)}
                               label={i18n.company.sub_title}
                               value={props.data.subTitle ?? ''}
                               raiseUpdates={(value: string) => props.onChange('subTitle', value)}/>

                    <TextInput type={'color'}
                               error={props.isSubmitted && (!props.data.backgroundColor || props.data.backgroundColor.length === 0)}
                               label={i18n.company.background_color}
                               value={props.data.backgroundColor ?? ''}
                               raiseUpdates={(value: string) => props.onChange('backgroundColor', value)}/>

                    {props.type === 'address' &&
                        <TextInput type={'color'}
                                   error={props.isSubmitted && (!props.data.itemColor || props.data.itemColor.length === 0)}
                                   label={i18n.company.address_color}
                                   value={props.data.itemColor ?? ''}
                                   raiseUpdates={(value: string) => props.onChange('itemColor', value)}/>}

                    <div className={'wizard-form-input align-items-start mb-0'}>
                        <label className={'mt-3'}>{imagesLabel}</label>

                        <div className={'d-flex align-items-center flex-wrap image-list'}>
                            {props.data.images?.map((imageUrl, index) => (
                                <GalleryImageInput key={index}
                                                   imageUrl={imageUrl}
                                                   removeImage={() => {
                                                       const images = props.data.images ? [...props.data.images] : []
                                                       images.splice(index, 1)
                                                       props.onChange('images', [...images])
                                                   }}/>
                            ))}

                            <div className={'add-img-btn d-flex align-items-center justify-content-center flex-column position-relative'}>

                                {props.loading && hasNewImage ? <div className={'image-loader loader'}/> : (
                                    <div className={'text-center'}>
                                        <i className="fa fa-plus"/>
                                        <p>Ajouter une photo</p>
                                    </div>
                                )}

                                <input type="file" onChange={e => {
                                    if (e.target.files?.[0]) {
                                        setHasNewImage(true)
                                        props.uploadImage(e.target.files[0], props.companyId)
                                    }
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
