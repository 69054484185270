import { ADD_MUNICIPAL_SERVICE, ADD_MUNICIPAL_SERVICE_FAILED, ADD_MUNICIPAL_SERVICE_SUCCEEDED, AddMunicipalServiceActionTypes } from "./actionTypes";
import { HandleMunicipalServiceState } from "../../configuration/state";

const initialState: HandleMunicipalServiceState = {
    loading: false,
    error  : undefined
}
export const addMunicipalServiceReducer = (state = initialState, action: AddMunicipalServiceActionTypes): HandleMunicipalServiceState => {
    switch (action.type) {
        case ADD_MUNICIPAL_SERVICE:
            return {loading: true, error: undefined}
        case ADD_MUNICIPAL_SERVICE_FAILED:
            return {loading: false, error: action.payload}
        case ADD_MUNICIPAL_SERVICE_SUCCEEDED:
            return {loading: false, error: undefined}
        default:
            return state;
    }
}
