import { connect } from 'react-redux';
import { AppState } from "../../../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import {
    updateBusinessErrorSelector,
    updateBusinessLoadingSelector
} from "../../../../../usecases/updateBusiness/selectors";
import { BusinessInfoForm } from "./businessInfo.form";
import { UpdateBusinessActionTypes } from "../../../../../usecases/updateBusiness/actionTypes";
import { updateBusiness } from "../../../../../usecases/updateBusiness/actions";
import { Company } from "../../../../../../company/domain/entity/company";
import { companySelector } from "../../../../../../company/usecases/updateStoreCompany/selector";
import { UpdateSingleFieldAction } from "../../../../../../company/usecases/updateSingleField/actionTypes";
import { updateSingleField } from "../../../../../../company/usecases/updateSingleField/actions";
import { KeyOf, ValueOf } from "../../../../../../common/domain/types/app.categories";
import { BusinessRegistrationType } from "../../../../../domain/type/BusinessRegistrationType";
import { loadCompanyDetailsLoadingSelector } from "../../../../../../company/usecases/loadCompanyDetails/selector";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined;
    company: Company | null
}

interface DispatchToPropsType {
    updateBusiness: (business: Company) => void;
    updateSingleField: (field: KeyOf<BusinessRegistrationType>, value: ValueOf<BusinessRegistrationType>) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: updateBusinessLoadingSelector(state) || loadCompanyDetailsLoadingSelector(state),
    error  : updateBusinessErrorSelector(state),
    company: companySelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    updateBusiness    : (business: Company): UpdateBusinessActionTypes => dispatch(updateBusiness(business)),
    updateSingleField : (field: KeyOf<BusinessRegistrationType>, value: ValueOf<BusinessRegistrationType>): UpdateSingleFieldAction => dispatch(updateSingleField(field, value))
})

export const BusinessInfoContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessInfoForm)
