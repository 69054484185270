import React from 'react'
import { ManagementInstantHeader } from "../../../../../instant-management/domain/entities/managementInstantHeader";
import { ApplicationContext } from "../../../../../configuration/application.context";
import "react-multi-carousel/lib/styles.css";
import { ApplicationIcons } from "../../../../../common/adapters/primaries/applicationIcons";

interface Props {
    instant: ManagementInstantHeader;
}

const moment = ApplicationContext.getInstance().momentJs()

export const CompanyInstantItem = (props: Props): JSX.Element => {
    return (
        <div className="single-blog">
            <div className="blog-image">
                <img src={props.instant.picture} alt={props.instant.title}/>
            </div>

            <div className="blog-content">
                <div className="content">
                    <div className="d-flex justify-content-start align-items-center mb-2">
                        {ApplicationIcons(props.instant.category, 25, 28)}
                        <h3 className="title margin-10">{props.instant.title}</h3>
                    </div>

                    <p className="clichy-blue">{moment(props.instant.dateStart).format('DD MMMM YYYY à HH:mm')}</p>
                    <span>{props.instant.description}</span>
                </div>
            </div>
        </div>
    )
}
