import { ManagementInstantCategoryType } from "./types/managementInstantCategoryType";
import { ActivationDayType } from "./types/activationDayType";

export class ManagementInstantHeader {

    constructor(protected _id: string,
                protected _title: string,
                protected _picture: string,
                protected _description: string,
                protected _category: ManagementInstantCategoryType,
                protected _latitude: number,
                protected _longitude: number,
                protected _zipCode: string,
                protected _delay: string,
                protected _activationStartDate: Date,
                protected _activationEndDate: Date,
                protected _originalStartDate: Date,
                protected _lastUpdate: Date,
                protected _businessId: string,
                protected _status: boolean,
                private _activationDayType: ActivationDayType
    ) {
    }

    get id(): string {
        return this._id
    }

    get title(): string {
        return this._title
    }

    get picture(): string {
        return this._picture
    }

    get description(): string {
        return this._description
    }

    get category(): ManagementInstantCategoryType {
        return this._category;
    }

    get latitude(): number {
        return this._latitude
    }

    get longitude(): number {
        return this._longitude
    }

    get delay(): string {
        return this._delay
    }

    get originalStartDate(): Date {
        return this._originalStartDate;
    }

    get dateStart(): Date {
        return this._activationStartDate
    }

    get dateEnd(): Date {
        return this._activationEndDate
    }

    get lastUpdate(): Date {
        return this._lastUpdate
    }

    get businessId(): string {
        return this._businessId;
    }

    get status(): boolean {
        return this._status
    }

    get zipCode(): string {
        return this._zipCode;
    }

    hasFixedActivationDayType(): boolean {
        return this._activationDayType === 'fixed';
    }

    isCurrentlyActive(): boolean {
        return this._status && (this._activationEndDate.getTime() > (new Date()).getTime())
    }
}
