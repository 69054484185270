import React from "react";
import { ProductDetailsType } from "../types";
import { TextInput } from "../../../../common/adapters/primaries/form/inputs/text.input";
import { i18n } from "../../../../configuration/i18n";
import { ValueOf } from "../../../../common/domain/types/app.categories";
import { ProductImagesInput } from "./images.input";
import { ProductGalleryImage } from "../../../domain/entities/productGalleryImage";
import { HtmlEditor } from "../../../../common/adapters/primaries/form/inputs/htmlEditor";

interface Props {
    error: boolean;
    data: ProductDetailsType;
    onChange: (key: keyof ProductDetailsType, value: ValueOf<ProductDetailsType>) => void;
}

export const EcommerceProductAdvancedForm = (props: Props): JSX.Element => {
    return (
        <div id={"theme-gallery"}>
            <HtmlEditor value={props.data.description}
                        raiseUpdates={(value: string): void => props.onChange("description", value)}
                        error={props.error && props.data.description.length === 0}/>

            <div className={"wizard-form-input"} style={{alignItems: "flex-start"}}>
                <label>{i18n.ecommerce.size}</label>

                <div style={{display: "grid"}}>
                    <input type={"number"}
                           value={props.data.length}
                           placeholder={"longueur (cm)"}
                           className={"mb-2"}
                           onChange={(event) =>
                               props.onChange("length", event.target.value)
                           }/>

                    <input type={"number"}
                           value={props.data.width}
                           className={"mb-2"}
                           placeholder={"largeur (cm)"}
                           onChange={(event) =>
                               props.onChange("width", event.target.value)
                           }/>
                    <input type={"number"}
                           value={props.data.height}
                           placeholder={"hauteur (cm)"}
                           onChange={(event) =>
                               props.onChange("height", event.target.value)
                           }/>
                </div>
            </div>

            <TextInput type={"number"}
                       error={props.error && props.data.weight.length === 0}
                       label={i18n.ecommerce.weight}
                       value={props.data.weight}
                       placeholder={i18n.ecommerce.weight_placeholder}
                       raiseUpdates={(value: string) =>
                           props.onChange("weight", value)
                       }/>

            <ProductImagesInput images={props.data.gallery}
                                error={props.error && props.data.gallery.length === 0}
                                raiseUpdates={(value: (ProductGalleryImage | File | string)[]) => props.onChange("gallery", value)}/>
        </div>
    );
};
