import { ApplicationContext } from "../../../../../../configuration/application.context";
import { TimeHelpers } from "./Time.helpers";
import { i18n } from "../../../../../../configuration/i18n";

const moment = ApplicationContext.getInstance().momentJs()

export class TimeLeftStatus {

    static findTimeLeftStatus(timeLeft: number, isStarted: boolean): string {
        if (timeLeft > 0)
            return `${this.timeLeftStatusIsStarted(isStarted)} ${this.timeLeftStatusDay(timeLeft)}`
        else
            return i18n.instants.time_left_status_expired
    }

    static timeLeftStatusIsStarted(isStarted: boolean): string {
        return isStarted ? i18n.instants.time_left_status_ends_on : i18n.instants.time_left_status_starts_on
    }

    static timeLeftStatusDay(timeLeft: number): string {
        return TimeHelpers.isTodayInstant(timeLeft) ? i18n.instants.in :
            TimeHelpers.isTomorrowInstant(timeLeft) ?
                `${i18n.instants.tomorrow} ${i18n.instants.on}`
                : TimeHelpers.isThisWeekInstant(timeLeft) ?
                `${moment().add(timeLeft, 'seconds').format('dddd')} ${i18n.instants.on}`
                : `${moment().add(timeLeft, 'seconds').format('DD MMM')} ${i18n.instants.on}`
    }
}
