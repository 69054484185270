import { ApiMunicipalityService } from "../adapters/secondaries/real/apiMunicipalityService";
import { MunicipalityService } from "../domain/gateway/municipality.service";
import { InMemoryMunicipalityService } from "../adapters/secondaries/inMemory/inMemoryMunicipalityService";

export class MunicipalityDependenciesFactory {

    static getMunicipalityService(): MunicipalityService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiMunicipalityService()
            default:
                return new InMemoryMunicipalityService()
        }
    }

}
