import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import '../form.css';
import {
    FormProfileSection,
    FormProfileStateType
} from "../../../../../../common/adapters/primaries/form/sections/formProfile.section";
import {
    FormLocationSection,
    FormLocationStateType
} from "../../../../../../common/adapters/primaries/form/sections/formLocation.section";
import {
    FormOpeningHoursSection
} from "../../../../../../common/adapters/primaries/form/sections/formOpeningHours.section";
import {
    FormContactSection,
    FormContactStateType
} from "../../../../../../common/adapters/primaries/form/sections/formContact.section";
import { FormHeader } from "../../../../../../common/adapters/primaries/form/form.header";
import { SubmitButton } from "../../components/submit.button";
import { OpeningHoursType } from "../../../../../../common/domain/types/openingHours.types";
import { FormValidation } from "../../../../../../common/adapters/primaries/form/validation";
import { Company } from "../../../../../../company/domain/entity/company";
import { KeyOf, ValueOf } from "../../../../../../common/domain/types/app.categories";
import { BusinessRegistrationType } from "../../../../../domain/type/BusinessRegistrationType";
import { CompanyBuilder } from "../../../../../../company/domain/builder/company.builder";

interface Props {
    loading: boolean;
    error: string | undefined;
    updateBusiness: (business: Company) => void;
    updateSingleField: (field: KeyOf<BusinessRegistrationType>, value: ValueOf<BusinessRegistrationType>) => void;
    type: string | undefined
    company: Company | null
}

interface State {
    profile: FormProfileStateType;
    location: FormLocationStateType;
    openingHours: OpeningHoursType[];
    contact: FormContactStateType;
    isSubmitted: boolean
    errorMsg: string | undefined
}

export class BusinessInfoForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            profile     : {
                name           : '',
                category       : '',
                coverImage     : undefined,
                profileImage   : undefined,
                coverImageUrl  : undefined,
                profileImageUrl: undefined,
                companyType    : ''
            },
            location    : {
                address  : '',
                city     : '',
                zipCode  : '',
                latitude : '',
                longitude: ''
            },
            openingHours: [],
            contact     : {
                website    : '',
                noWebsite  : false,
                email      : '',
                phoneNumber: '',
                description: ''
            },
            isSubmitted : false,
            errorMsg    : undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.company === null && this.props.company !== null) {
            this.updateBusinessState(this.initiateFormWithUrlParameters(this.props.company))
        }
        if (this.props.error && this.props.error !== prevProps.error) {
            this.setState({errorMsg: this.getErrorMessage(this.props.error)})
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
    }

    render(): ReactNode {
        const type = this.props.type ? this.props.type : 'compte pro'
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        return (
            <div className="wizard-forms pb-3">
                {loader}
                {
                    this.props.company?.contacts.email && <iframe
                        src={`https://ckclick.com/api-product/scoring-track?tracker_id=S8H1O7X3-A5D8C3I6-Q3Z9B9M7-V3E7F8E8&prospect_email=${this.props.company?.contacts.email}&event=convert`}
                        width="0" height="0"/>
                }

                <div className="inner pb-4 pb-sm-5 clearfix">

                    <div className="wizard-form-field">
                        <FormHeader text={i18n.registration.update_business_description(type)}/>

                        <FormProfileSection profile={this.state.profile}
                                            alreadySavedBusiness={this.props.company}
                                            isSubmitted={this.state.isSubmitted}
                                            raiseUpdate={(profile: FormProfileStateType) => this.setState({
                                                profile,
                                                isSubmitted: false,
                                                errorMsg   : ''
                                            })}/>

                        <FormLocationSection location={this.state.location}
                                             isSubmitted={this.state.isSubmitted}
                                             raiseUpdate={(location: FormLocationStateType) => this.setState({
                                                 location,
                                                 isSubmitted: false,
                                                 errorMsg   : ''
                                             })}/>

                        <FormOpeningHoursSection openingHours={this.state.openingHours}
                                                 isSubmitted={this.state.isSubmitted}
                                                 raiseUpdate={(openingHours: OpeningHoursType[]) => this.setState({
                                                     openingHours,
                                                     isSubmitted: false,
                                                     errorMsg   : ''
                                                 })}/>

                        <FormContactSection contact={this.state.contact}
                                            type={type}
                                            isSubmitted={this.state.isSubmitted}
                                            raiseUpdate={(contact: FormContactStateType) => this.setState({
                                                contact,
                                                isSubmitted: false,
                                                errorMsg   : ''
                                            })}/>

                        {this.state.errorMsg &&
                            <p className={'error'} dangerouslySetInnerHTML={{__html: this.state.errorMsg}}/>}

                    </div>
                </div>

                <div className={'registration-footer'}>
                    <SubmitButton submit={() => this.updateBusiness()}
                                  goBack={() => void 0}/>
                </div>
            </div>
        )
    }

    validateForm(): string | undefined {

        if ((!this.state.profile.profileImage && !this.state.profile?.profileImageUrl) ||
            (!this.state.profile.coverImage && !this.state.profile?.coverImageUrl) ||
            this.hasEmptyValue(this.state.profile.name) || this.hasEmptyValue(this.state.profile.category) ||
            this.hasEmptyValue(this.state.location.address) || this.hasEmptyValue(this.state.location.city) ||
            this.hasEmptyValue(this.state.location.zipCode) || this.hasEmptyValue(this.state.contact.description) ||
            this.hasEmptyValue(this.state.contact.email) || this.hasEmptyValue(this.state.contact.phoneNumber) ||
            (this.hasEmptyValue(this.state.contact.website) && !this.state.contact.noWebsite) ||
            this.state.openingHours.length === 0) {
            return i18n.registration.required_data_error
        } else if (this.state.profile.name.length < 3) {
            return i18n.registration.business_name_format_error
        } else if (this.state.location.address.length < 6) {
            return i18n.registration.address_format_error
        } else if (this.state.location.city.length < 4) {
            return i18n.registration.city_format_error
        } else if (!FormValidation.validate('zipCode', this.state.location.zipCode)) {
            return i18n.registration.zipCode_format_error
        } else if (this.state.contact.description.length < 14) {
            return i18n.registration.description_format_error
        } else if (!FormValidation.validateWebsite(this.state.contact.website) && !this.state.contact.noWebsite) {
            return i18n.registration.site_format_error
        } else if (!FormValidation.validateEmail(this.state.contact.email)) {
            return i18n.registration.email_format_error
        } else if (!FormValidation.validatePhoneNumberFix(this.state.contact.phoneNumber)) {
            return i18n.registration.phone_number_error
        }
    }

    updateBusiness(): void {
        this.setState({
            errorMsg   : this.validateForm(),
            isSubmitted: true
        }, () => {
            if (!this.state.errorMsg && this.props.company !== null) {
                const company: Company = new CompanyBuilder().fromCompany(this.props.company).build()
                const business: CompanyBuilder = new CompanyBuilder()
                    .withId(company.id)
                    .withName(this.state.profile.name)
                    .withCategory(this.state.profile.category)
                    .withType(this.state.profile.companyType)
                    .withMembership(company.membership)
                    .withAddress(this.state.location.address)
                    .withZipCode(this.state.location.zipCode)
                    .withCity(this.state.location.city)
                    .withLongitude(this.state.location.longitude)
                    .withLatitude(this.state.location.latitude)
                    .withOpeningHours(this.state.openingHours)
                    .withDescription(this.state.contact.description)
                    .withWebsite(this.state.contact.website === 'none' ? '' : this.state.contact.website)
                    .withEmail(this.state.contact.email)
                    .withPhoneNumber(this.state.contact.phoneNumber)

                if (this.state.profile.profileImage) {
                    business.withProfileImage(this.state.profile.profileImage)
                }

                if (this.state.profile.coverImage) {
                    business.withCoverImage(this.state.profile.coverImage)
                }

                this.props.updateBusiness(business.build())
            }
        })
    }

    getErrorMessage(error: string): string {
        if (error === '415') {
            return i18n.registration.over_sized_image_error
        } else if (error === '404') {
            return i18n.registration.data_format_error
        } else {
            return i18n.registration.server_error
        }
    }

    updateBusinessState(business: Company): void {
        this.setState({
            profile     : {
                category       : business.category,
                coverImage     : business.coverImage,
                profileImage   : business.profileImage,
                coverImageUrl  : business.coverImageUrl,
                profileImageUrl: business.profileImageUrl,
                name           : business.name,
                companyType    : business.type
            },
            location    : {
                ...this.state.location,
                address: business.address.address,
                city   : business.address.city,
                zipCode: business.address.zipCode
            },
            contact     : {
                website    : business.contacts.website,
                noWebsite  : business.contacts.website === '',
                email      : business.contacts.email,
                phoneNumber: business.contacts.phoneNumber,
                description: business.contacts.description
            },
            openingHours: business.openingHours.map((item): OpeningHoursType => {
                return {
                    daytype  : item.openingDays,
                    endTime  : item.endTime,
                    startTime: item.startTime,
                    id       : item.id,
                    isPaused : item.isPaused
                }
            })
        })
    }

    hasEmptyValue(value: string | undefined | null): boolean {
        return !value || value.length === 0
    }

    initiateFormWithUrlParameters(company: Company): Company {
        const paramsString = window.location.search
        const searchParams = new URLSearchParams(paramsString);

        const fromUrlBusiness = new CompanyBuilder()

        fromUrlBusiness.fromCompany(company)

        if (paramsString.length > 0) {
            const proName = searchParams.get('procompanyname')
            const proEmail = searchParams.get('proemail')
            const proPhone = searchParams.get('prophone')
            const proCity = searchParams.get('procity')

            const proZipcode = searchParams.get('prozipcode')


            if (proCity && proCity !== 'NULL') {
                fromUrlBusiness.withCity(proCity)
            }
            if (proZipcode && proZipcode !== 'NULL') {
                fromUrlBusiness.withZipCode(proZipcode)
            }
            if (proName && proName !== 'NULL')
                fromUrlBusiness.withName(proName)
            if (proEmail && proEmail !== 'NULL')
                fromUrlBusiness.withEmail(proEmail)
            if (proPhone && proPhone !== 'NULL')
                fromUrlBusiness.withPhoneNumber('+' + proPhone.trim())
        }
        return fromUrlBusiness.build()
    }
}
