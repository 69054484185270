import { AppState } from "../../../redux-configuration/AppState";
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { DELETE_MUNICIPALITY_SERVICE, DeleteMunicipalityServiceAction } from "./actionTypes";
import { MunicipalityService } from "../../domain/gateway/municipality.service";
import { deleteMunicipalityServiceFailed, deleteMunicipalityServiceSucceeded } from "./actions";
import { MunicipalService } from "../../domain/entities/municipalService";
import { listingMunicipalServicesSucceeded } from "../listingMunicipalServices/actions";

export const deleteMunicipalityServiceEpic: Epic = (action$: ActionsObservable<DeleteMunicipalityServiceAction>,
                                                    store: StateObservable<AppState>,
                                                    {municipalityService}: { municipalityService: MunicipalityService }) =>
    action$.pipe(
        ofType(DELETE_MUNICIPALITY_SERVICE),
        switchMap(action => municipalityService.deleteService(action.payload)
            .pipe(
                concatMap((services: MunicipalService[]) => {
                    services.sort((a, b) => a.rank - b.rank)
                    return [
                        listingMunicipalServicesSucceeded(services),
                        deleteMunicipalityServiceSucceeded()
                    ]
                }),
                catchError(error => of(deleteMunicipalityServiceFailed(error)))
            )
        )
    )
