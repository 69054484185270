export class CompanyContact {
    constructor(
        protected _description: string,
        protected _website: string,
        protected _email: string,
        protected _phoneNumber: string
    ) {}

    get description(): string {
        return this._description
    }

    get website(): string {
        return this._website
    }

    get email(): string {
        return this._email
    }

    get phoneNumber(): string {
        return this._phoneNumber
    }
}
