import { ApplicationContext } from "../../../../configuration/application.context";
import { ManagementInstantHeader } from "../../../domain/entities/managementInstantHeader";
import { Observable, throwError, of, from } from "rxjs";
import { InstantManagementService } from "../../../domain/gateway/instantManagementService";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { InstantManagementHeaderDto, ManagementInstantDTO } from "./DTO/instantManagementHeader.dto";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { InstantManagementMapper } from "./mapper/instantManagement.mapper";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ManagementInstant } from "../../../domain/entities/ManagementInstant";
import { ManagementInstantActivationTime } from "../../../domain/entities/managementInstantActivationTime";
import { ContentPostType } from "../../../domain/entities/types/contentPostType";
import { SocialPageInfo } from "../../../../company/domain/entity/socialPageInfo";


const moment = ApplicationContext.getInstance().momentJs()

interface ActivationTimesUpdateCommandType {
    id: string;
    activationTimeType: string;
    activeDayType: string;
    startDateTime: string;
    endDateTime: string;
    isEnabled: string;
}

export class ApiInstantManagementService implements InstantManagementService {

    retrieveInstantsCompany(businessId: string): Observable<ManagementInstantHeader[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/company/${businessId}`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: InstantManagementHeaderDto[] }>(url)
            .pipe(
                map((response: { data: InstantManagementHeaderDto[] }) =>
                    response.data.map(instantInfoItem => InstantManagementMapper.mapToInstantHeader(instantInfoItem))
                        .sort((instantA, instantB) => instantB.lastUpdate.getTime() - instantA.lastUpdate.getTime())
                        .sort((instantA, instantB) => (instantA.status === instantB.status) ? 0 : instantA.status ? -1 : 1)
                ),
                catchError(err => throwError(err.status.toString()))
            )
    }


    loadManagementInstantById(id: string): Observable<ManagementInstant> {
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/${id}`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: ManagementInstantDTO }>(url)
            .pipe(
                map((response: { data: ManagementInstantDTO }) => InstantManagementMapper.mapToSubmissionInstant(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    editRemoteInstant(instant: ManagementInstant): Observable<void> {
        const body = new FormData();

        if (instant.instantInformation.picture) {
            body.append('picture', instant.instantInformation.picture);
        }
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/${instant.instantInformation.id}/update-instant-and-activationTimes`
        body.append('id', instant.instantInformation.id);
        body.append('title', instant.instantInformation.title);
        body.append('categoryId', InstantManagementMapper.convertCategoryToId(instant.instantInformation.category));
        body.append('shortDescription', instant.instantInformation.shortDescription);
        body.append('longDescription', instant.instantInformation.longDescription);
        body.append('streetAddress', instant.address.address);
        body.append('city', instant.address.city);
        body.append('zipCode', String(instant.address.zipCode));
        if (instant.notificationDate)
            body.append('notificationDate', instant.notificationDate)

        const activationTimesArray = instant.instantActivationTimes
            .map(item => this.activationTimesUpdateCommand(item))
            .sort(item => item.id === '' ? -1 : 1)
        body.append('activationTimes', JSON.stringify(activationTimesArray))
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.message))
            )
    }

    deleteInstant(instantId: string): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/${instantId}`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .delete(url)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    private activationTimesUpdateCommand(instantActivationTime: ManagementInstantActivationTime): ActivationTimesUpdateCommandType {
        return {
            id                : instantActivationTime.id,
            activationTimeType: instantActivationTime.DelayType === 'DURING' ? 'during_instant' : 'just_before_and_during_instant',
            activeDayType     : instantActivationTime.activationDayType,
            startDateTime     : instantActivationTime.startDateTime,
            endDateTime       : instantActivationTime.endDateTime,
            isEnabled         : instantActivationTime.isEnabled ? '1' : '0'
        }
    }

    createRemoteInstant(instant: ManagementInstant): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/instants`
        const body = new FormData();
        body.append('companyId', instant.instantInformation.businessId);
        body.append('title', instant.instantInformation.title);
        body.append('categoryId', InstantManagementMapper.convertCategoryToId(instant.instantInformation.category));
        body.append('shortDescription', instant.instantInformation.shortDescription);
        body.append('longDescription', instant.instantInformation.longDescription);
        body.append('streetAddress', instant.address.address);
        body.append('city', instant.address.city);
        body.append('zipCode', instant.address.zipCode.toString());
        body.append('picture', instant.instantInformation.picture);
        if (instant.notificationDate)
            body.append('notificationDate', instant.notificationDate)

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                mergeMap(success => {
                    const counter = 0
                    const instantId = success.response.data
                    const createActiveTimeUrl = `${process.env.REACT_APP_API_URL}/v1/instants/${instantId}/activation-times`
                    const requestBodies = instant.instantActivationTimes.map(item => this.activationTimesCreateCommand(item, instantId))

                    return this.createActivationTime(createActiveTimeUrl, requestBodies, counter)
                }),
                catchError(err => throwError(err.response.message))
            )
    }

    private activationTimesCreateCommand(instantActivationTime: ManagementInstantActivationTime, instantId: string): {} {
        return {
            activationTimeType: instantActivationTime.DelayType === 'DURING' ? 'during_instant' : 'just_before_and_during_instant',
            activeDayType     : instantActivationTime.activationDayType,
            startDateTime     : instantActivationTime.startDateTime,
            endDateTime       : instantActivationTime.endDateTime,
            isEnabled         : instantActivationTime.isEnabled ? '1' : '0',
            instantId
        }
    }

    private createActivationTime(url: string, requestBodies: {}[], counter: number): Observable<void> {
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, requestBodies[counter]).pipe(
                mergeMap(() => {
                    if (requestBodies.length === counter + 1)
                        return of(void 0)
                    return this.createActivationTime(url, requestBodies, counter + 1)
                }),
                catchError(err => throwError(err.response.message))
            )
    }

    retrievePreviewInstantsCompany(businessId: string): Observable<ManagementInstantHeader[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/company/${businessId}/upcoming?date=${moment().format()}`

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: InstantManagementHeaderDto[] }>(url)
            .pipe(
                map((response: { data: InstantManagementHeaderDto[] }) =>
                    response.data.map(instantInfoItem => InstantManagementMapper.mapToInstantHeader(instantInfoItem))
                ),


                catchError(err => throwError(err.status.toString()))
            )
    }

    shareInstantOnFacebook(page: SocialPageInfo, content: ContentPostType): Observable<void> {
        const sharePromise = new Promise<void>((resolve, reject) => {
            window.FB.login((response: { authResponse: { accessToken: string } }) => {
                if (response.authResponse) {
                    window.FB.api(
                        `/${page.idPage}?fields=access_token&access_token=${response.authResponse.accessToken}`,
                        ({access_token}: { access_token: string }) => {
                            if (access_token)
                                window.FB.api(
                                    `/${page.idPage}/photos?access_token=${access_token}`,
                                    "POST", {
                                        message     : content.text,
                                        url         : content.image,
                                        link        : content.link,
                                        access_token: access_token
                                    }, () => resolve(void 0),
                                    (error: string) => {
                                        reject(error)
                                    }
                                )
                            else
                                reject('NO_PAGE_FOUND')
                        }
                    )
                } else
                    reject('NOT_CONNECTED')
            }, () => reject('NOT_CONNECTED'));

        })
        return from(sharePromise)
    }

    shareInstantOnInstagram(page: SocialPageInfo, content: ContentPostType): Observable<void> {
        const sharePromise = new Promise<void>((resolve, reject) => {
            window.FB.login((response: { authResponse: { accessToken: string } }) => {
                if (response.authResponse) {
                    window.FB.api(
                        `/${page.idPage}/media?access_token=${response.authResponse.accessToken}`,
                        "POST", {
                            image_url   : content.image,
                            caption     : content.text,
                            access_token: response.authResponse.accessToken
                        }, (data: { id: string, error: string }) => {
                            if (data.id)
                                window.FB.api(
                                    `/${page.idPage}/media_publish?creation_id=${data.id}`,
                                    "POST", {
                                        creation_id : data.id,
                                        access_token: response.authResponse.accessToken
                                    }, {},
                                    ((data: { id: string, error: string }) => {
                                        if (data.id)
                                            resolve(void 0)
                                        else if (data.error)
                                            reject(data.error)
                                    })
                                )
                            else if (data.error)
                                reject(data.error)
                        })
                } else
                    reject('NOT_CONNECTED')
            }, () => reject('NOT_CONNECTED'));

        })
        return from(sharePromise)
    }
}
