import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ADD_PRODUCT, AddProductAction } from "./actionTypes";
import { addProductFailed, addProductSucceeded } from "./actions";
import { ProductService } from "../../domain/gateway/product.service";

export const addProductEpic: Epic = (action$: ActionsObservable<AddProductAction>,
                                     store: StateObservable<AppState>,
                                     {productService}: { productService: ProductService }) =>
    action$.pipe(
        ofType(ADD_PRODUCT),
        switchMap(
            (action) => productService.addProduct(action.payload.product, action.payload.companyId)
                .pipe(
                    map(() => addProductSucceeded()),
                    catchError(error => of(addProductFailed(error)))
                )
        )
    )
