import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { AppState } from "../../../../redux-configuration/AppState";
import { BroadcastConversation } from "../../../domain/entities/broadcastConversation";
import { MessagingService } from "../../../domain/gateway/messaging.service";
import { loadBroadcastConversationFailed, loadBroadcastConversationSucceeded } from "./actions";
import { LOAD_BROADCAST_CONVERSATION, LoadBroadcastConversationAction } from "./actionTypes";
import { updateBroadcastConversation } from "../update/action";

export const loadBroadcastConversationEpic: Epic = (action$: ActionsObservable<LoadBroadcastConversationAction>, store: StateObservable<AppState>,
                                                    {messagingService}: { messagingService: MessagingService }) =>
    action$.pipe(
        ofType(LOAD_BROADCAST_CONVERSATION),
        switchMap((action: { payload: string }) => messagingService.loadBroadcastConversation(action.payload)
            .pipe(
                concatMap((conversation: BroadcastConversation) => [
                    loadBroadcastConversationSucceeded(),
                    updateBroadcastConversation(conversation)
                ]),
                catchError(err => of(loadBroadcastConversationFailed(err)))
            )
        )
    )
