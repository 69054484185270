import { ChatMessage } from '../../../domain/entities/chatMessage';
import {
    LOAD_PREVIOUS_CHAT_MESSAGES_FAILED, LOAD_PREVIOUS_CHAT_MESSAGES_SUCCEEDED, LOAD_PREVIOUS_CHAT_MESSAGES,
    LoadPreviousChatMessagesFailedAction,
    LoadPreviousChatMessagesSucceededAction,
    LoadPreviousChatMessagesAction
} from "./actionTypes";

export const loadPreviousChatMessages = (conversationId: string, messageId: number): LoadPreviousChatMessagesAction => ({
    type   : LOAD_PREVIOUS_CHAT_MESSAGES,
    payload: {conversationId, messageId}
})

export const loadPreviousChatMessagesSucceeded = (chatMessages: ChatMessage[]): LoadPreviousChatMessagesSucceededAction => ({
    type   : LOAD_PREVIOUS_CHAT_MESSAGES_SUCCEEDED,
    payload: chatMessages
})

export const loadPreviousChatMessagesFailed = (error: string): LoadPreviousChatMessagesFailedAction => ({
    type   : LOAD_PREVIOUS_CHAT_MESSAGES_FAILED,
    payload: error
})
