import { CityDTO } from "../dto/city.dto"
import { CityHeader } from "../../../../domain/entity/cityHeader";
import { CityHeaderBuilder } from "../../../../domain/builder/cityHeader.builder";

export class CityMapper {

    static mapDataToCities = (data: CityDTO[]): CityHeader[] => {
        return data.map(cityDTO => (new CityHeaderBuilder())
            .withName(cityDTO.name)
            .withZipCode(cityDTO.zipCode)
            .build()
        )
    }
}
