import { ActivationTimesDTO, InstantManagementHeaderDto, ManagementInstantDTO } from "../DTO/instantManagementHeader.dto";
import { ManagementInstantHeader } from "../../../../domain/entities/managementInstantHeader";
import { ManagementInstantHeaderBuilder } from "../../../../domain/entities/managementInstantHeaderBuilder";
import { ManagementInstantCategoryType } from "../../../../domain/entities/types/managementInstantCategoryType";
import { ApplicationContext } from "../../../../../configuration/application.context";
import { ManagementInstant } from "../../../../domain/entities/ManagementInstant";
import { ActivationTimeType } from "../../../../domain/entities/types/activationTimeType";
import { ManagementInstantBuilder } from "../../../../domain/entities/managementInstantBuilder";
import { ActivationDayType } from "../../../../domain/entities/types/activationDayType";

const moment = ApplicationContext.getInstance().momentJs();

export class InstantManagementMapper {

    static mapToInstantHeader(apiInstant: InstantManagementHeaderDto): ManagementInstantHeader {
        let status = false;
        let activationDayType: ActivationDayType = 'all_days';

        apiInstant.activationTimes.map(activationTime => {
            if (activationTime.isEnabled)
                status = true
            activationDayType = activationTime.activeDayType
        });

        const plainString = apiInstant.shortDescription.replace(/<[^>]+>/g, '');

        return new ManagementInstantHeaderBuilder()
            .withId(apiInstant.id)
            .withBusinessId(apiInstant.companyId)
            .withTitle(apiInstant.title)
            .withDescription(plainString)
            .withPicture(apiInstant.pictureUrl)
            .withCategory(this.convertIdToCategory(apiInstant.categoryId))
            .withActivationStartDate(this.getDate(apiInstant.activationTimes[0].startDateTime))
            .withActivationEndDate(this.getDate(apiInstant.activationTimes[0].endDateTime))
            .withLastUpdate(this.getDate(apiInstant.lastModificationDate))
            .withStatus(status)
            .withZipcode(apiInstant.zipCode)
            .withActivationDayType(activationDayType)
            .build()
    }

    static mapToSubmissionInstant(apiInstant: ManagementInstantDTO): ManagementInstant {
        const activationTimes: ActivationTimeType = {
            key     : apiInstant.activationTimes[0].activeDayType === 'fixed' ? 'fixed' : 'recurrent',
            schedule: []
        }
        apiInstant.activationTimes.map((activationTime: ActivationTimesDTO) => {
            if (Array.isArray(activationTimes.schedule))
                activationTimes.schedule.push({
                    id       : activationTime.id.toString(),
                    isPaused : !activationTime.isEnabled,
                    prePoned : activationTime.activationTimeType === 'just_before_and_during_instant',
                    dateRange: {
                        key      : activationTime.activeDayType,
                        label    : activationTime.activeDayType,
                        startTime: this.getStartDateTime(activationTime.activeDayType, activationTime.startDateTime),
                        endTime  : this.getEndDateTime(activationTime.activeDayType, activationTime.endDateTime)
                    }
                })
        });

        const picturePieces = apiInstant.pictureUrl.split('/');
        const picture = new File([apiInstant.pictureUrl], picturePieces[ picturePieces.length - 1 ], { type: "image/jpeg" })

        const notificationDate = apiInstant.notificationDate && apiInstant.notificationDate.length > 0
            ? moment(apiInstant.notificationDate).format('YYYY-MM-DD HH:mm') : apiInstant.notificationDate

        const longDescription = apiInstant.longDescription
            .replaceAll('<b>', '<strong>')
            .replaceAll('</b>', '</strong>')
            .replaceAll('<i>', '<em>')
            .replaceAll('</i>', '</em>')

        return (new ManagementInstantBuilder())
            .withId(apiInstant.id)
            .withBusinessId(apiInstant.companyId)
            .withTitle(apiInstant.title)
            .withShortDescription(apiInstant.shortDescription)
            .withLongDescription(longDescription)
            .withPicture(picture)
            .withPictureUrl(apiInstant.pictureUrl)
            .withCategory(this.convertIdToCategory(apiInstant.categoryId))
            .withAddress(apiInstant.streetAddress)
            .withCity(apiInstant.city)
            .withZipcode(apiInstant.zipCode)
            .withCoords({ latitude: apiInstant.latitude, longitude: apiInstant.longitude })
            .withActivationTimeType(activationTimes)
            .withNotificationSent(apiInstant.notificationSent)
            .withNotificationDate(notificationDate)
            .build()
    }

    public static getDate(dateTime: string): Date {
        return moment(dateTime, 'YYYY-MM-DDTHH:mm:ss').toDate()
    }

    static convertIdToCategory(id: number): ManagementInstantCategoryType {
        const categories: { [key: number]: ManagementInstantCategoryType } = {
            1 : 'cultural',
            2 : 'sportive',
            3 : 'discovery',
            4 : 'gourmand',
            5 : 'festive',
            6 : 'heart',
            7 : 'info',
            8 : 'offer',
            9 : 'gift',
            10: 'musical'
        };
        return categories[id]
    }

    static convertCategoryToId(category: ManagementInstantCategoryType): string {
        const categories: { [key: string]: number } = {
            'cultural' : 1,
            'sportive' : 2,
            'discovery': 3,
            'gourmand' : 4,
            'festive'  : 5,
            'heart'    : 6,
            'info'     : 7,
            'offer'    : 8,
            'gift'     : 9,
            'musical'  : 10
        };
        return String(categories[category])
    }

    private static getStartDateTime(activeDayType: string, startDateTime: string): string {
        if (activeDayType === 'fixed')
            return startDateTime
        return moment.parseZone(startDateTime, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm:ssZ')
    }

    private static getEndDateTime(activeDayType: string, dateTime: string): string {
        if (activeDayType === 'fixed')
            return dateTime
        return moment.parseZone(dateTime, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm:ssZ')
    }
}
