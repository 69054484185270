import {
    EDIT_PRODUCT, EDIT_PRODUCT_FAILED, EDIT_PRODUCT_SUCCEEDED, EDIT_RESET_ACTION,
    EditProductAction, EditProductFailedAction, EditProductSucceededAction, EditResetAction,
} from "./actionTypes";
import { ProductDetails } from "../../domain/entities/productDetails";

export const editProduct = (product: ProductDetails, companyId: string): EditProductAction => ({
    type   : EDIT_PRODUCT,
    payload: {product, companyId},
})

export const editProductFailed = (error: string): EditProductFailedAction => ({
    type   : EDIT_PRODUCT_FAILED,
    payload: error
})

export const editProductSucceeded = (): EditProductSucceededAction => ({
    type: EDIT_PRODUCT_SUCCEEDED
})

export const editResetAction = (): EditResetAction => ({
    type: EDIT_RESET_ACTION
})
