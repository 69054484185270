import React from "react";
import { BroadcastMessage } from "../../../../domain/entities/broadcastMessage";
import { BroadcastMessageItem } from "./broadcastMessage.item";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    broadcastMessages: BroadcastMessage[];
}

export const BroadcastMessagesList = (props: Props): JSX.Element => {

    return (
        <div className={'message-list d-flex flex-column-reverse'}>
            {props.broadcastMessages.length === 0 && <p className={'empty-list'}>{i18n.messaging.empty_broadcast_list}</p>}

            {
                props.broadcastMessages.map((message: BroadcastMessage) =>
                    <BroadcastMessageItem key={message.id} message={message}/>
                )
            }
        </div>
    );
}
