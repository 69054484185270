import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../configuration/i18n";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { AccordionItem } from "./components/accordion.item";
import "./theme.css";
import { GeneralConfiguration } from "./components/general";
import { HeaderConfiguration } from "./components/header";
import { WelcomeConfiguration } from "./components/welcome";
import { DefaultBlockConfiguration } from "./components/default.block";
import { BlockWithImagesConfiguration } from "./components/blockWithImages";
import { AboutConfiguration } from "./components/about";
import { Company } from "../../../domain/entity/company";
import {
    ThemeConfigurationBlockType,
    ThemeConfigurationDetailsType, ThemeEcommerceType, ThemeFoodMenuType,
    ThemeSocialMediaType,
    ThemeWelcomeBlockType
} from "./types";
import { CompanyBuilder } from "../../../domain/builder/company.builder";
import { OpeningHoursType } from "../../../../common/domain/types/openingHours.types";
import { CompanyOpeningHours } from "../../../domain/entity/companyOpeningHours";
import { ThemeFormValidation } from "./form.validation";
import { ThemeConfigurationFormMapper } from "./mapper";
import { SuccessThemeConfiguration } from "./components/success.message";
import { ReviewBlockConfiguration } from "./components/review";
import { SocialMediaConfiguration } from "./components/socialMedia";
import { ClickCollectBlockConfiguration } from "./components/clickCollect";
import { KeyOf, ValueOf } from "../../../../common/domain/types/app.categories";
import { InstantBlockConfiguration } from "./components/block.instant";
import { FoodMenuBlockConfiguration } from "./components/foodMenuBlock/foodMenu.block";
import { EcommerceBlockConfiguration } from "./components/ecommerceBlock/ecommerce";

interface Props {
    company: Company | null;
    loading: boolean;
    success: boolean | null;
    error: string | undefined;
    editCompany: (company: Company) => void;
}

export interface ThemeConfigurationFormState {
    general: ThemeConfigurationDetailsType;
    header: ThemeConfigurationBlockType;
    footer: ThemeConfigurationBlockType;
    welcome: ThemeWelcomeBlockType;
    about: ThemeConfigurationBlockType;
    gallery: ThemeConfigurationBlockType;
    instants: ThemeConfigurationBlockType;
    contact: ThemeConfigurationBlockType;
    address: ThemeConfigurationBlockType;
    review: ThemeConfigurationBlockType;
    socialMedia: ThemeSocialMediaType;
    clickCollect: ThemeConfigurationBlockType | undefined;
    foodMenu: ThemeFoodMenuType;
    ecommerce: ThemeEcommerceType;
    isSubmitted: boolean;
    success: boolean;
    errorMsg: string | undefined;
}

export class ThemeConfigurationFormContainer extends PureComponent<Props, ThemeConfigurationFormState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            general     : {
                title         : this.props.company?.themeConfiguration.general.title ?? '',
                website       : this.props.company?.themeConfiguration.general.website ?? '',
                primaryColor  : this.props.company?.themeConfiguration.general.primaryColor ?? '',
                secondaryColor: this.props.company?.themeConfiguration.general.secondaryColor ?? '',
                primaryFont   : this.props.company?.themeConfiguration.general.primaryFont ?? '',
                secondaryFont : this.props.company?.themeConfiguration.general.secondaryFont ?? ''
            },
            header      : {
                isActive       : true,
                textColor      : this.props.company?.themeConfiguration.header.textColor ?? '',
                backgroundColor: this.props.company?.themeConfiguration.header.backgroundColor ?? '',
                images         : this.props.company?.themeConfiguration.header.images ?? []
            },
            footer      : {
                isActive       : true,
                textColor      : this.props.company?.themeConfiguration.footer.textColor ?? '',
                backgroundColor: this.props.company?.themeConfiguration.footer.backgroundColor ?? '',
                images         : this.props.company?.themeConfiguration.footer.images ?? []
            },
            welcome     : {
                subTitle       : this.props.company?.themeConfiguration.welcome.text ?? '',
                videoUrl       : this.props.company?.themeConfiguration.welcome.videoUrl ?? '',
                buttonUrl      : this.props.company?.themeConfiguration.welcome.buttonUrl ?? '',
                buttonLabel    : this.props.company?.themeConfiguration.welcome.buttonLabel ?? '',
                openInNewWindow: this.props.company?.themeConfiguration.welcome.openInNewWindow ?? false,
                backgroundColor: this.props.company?.themeConfiguration.welcome.backgroundColor ?? ''
            },
            about       : {
                isActive       : true,
                titleColor     : this.props.company?.themeConfiguration.about.titleColor ?? '',
                textColor      : this.props.company?.themeConfiguration.about.textColor ?? '',
                backgroundColor: this.props.company?.themeConfiguration.about.backgroundColor ?? ''
            },
            gallery     : {
                isActive       : false,
                subTitle       : this.props.company?.themeConfiguration.gallery?.text ?? '',
                backgroundColor: this.props.company?.themeConfiguration.gallery?.backgroundColor ?? '',
                images         : this.props.company?.themeConfiguration.gallery?.images ?? []
            },
            instants    : {
                isActive       : true,
                subTitle       : this.props.company?.themeConfiguration.instants?.text ?? '',
                backgroundColor: this.props.company?.themeConfiguration.instants?.backgroundColor ?? '',
                itemColor      : this.props.company?.themeConfiguration.instants?.itemColor ?? ''
            },
            contact     : {
                isActive       : true,
                subTitle       : this.props.company?.themeConfiguration.contact?.text ?? '',
                backgroundColor: this.props.company?.themeConfiguration.contact?.backgroundColor ?? ''
            },
            address     : {
                isActive       : true,
                subTitle       : this.props.company?.themeConfiguration.address?.text ?? '',
                backgroundColor: this.props.company?.themeConfiguration.address?.backgroundColor ?? '',
                images         : this.props.company?.themeConfiguration.address?.images ?? [],
                itemColor      : this.props.company?.themeConfiguration.address?.itemColor ?? ''
            },
            review      : {
                isActive       : false,
                subTitle       : this.props.company?.themeConfiguration.review?.text ?? '',
                backgroundColor: this.props.company?.themeConfiguration.review?.backgroundColor ?? '',
                placeId        : this.props.company?.themeConfiguration.general?.placeId ?? ''
            },
            clickCollect: undefined,
            foodMenu    : {
                isActive       : this.props.company?.themeConfiguration.foodMenu?.isActive ?? false,
                headerTitle    : this.props.company?.themeConfiguration.foodMenu?.headerTitle ?? '',
                title          : this.props.company?.themeConfiguration.foodMenu?.title ?? '',
                subTitle       : this.props.company?.themeConfiguration.foodMenu?.subtitle ?? '',
                backgroundColor: this.props.company?.themeConfiguration.foodMenu?.backgroundColor ?? '',
                displayAsIcon  : this.props.company?.themeConfiguration.foodMenu?.displayAsIcon ?? false,
                tabs           : this.props.company?.themeConfiguration.foodMenu?.tabs ?? []
            },
            socialMedia : {
                facebook : this.props.company?.themeConfiguration.socialMedia?.facebook ?? '',
                instagram: this.props.company?.themeConfiguration.socialMedia?.instagram ?? '',
                tiktok   : this.props.company?.themeConfiguration.socialMedia?.tiktok ?? '',
                linkedin : this.props.company?.themeConfiguration.socialMedia?.linkedin ?? ''
            },
            ecommerce   : {
                isActive       : this.props.company?.themeConfiguration.ecommerce?.isActive ?? false,
                headerTitle    : this.props.company?.themeConfiguration.ecommerce?.headerTitle ?? '',
                title          : this.props.company?.themeConfiguration.ecommerce?.title ?? '',
                subTitle       : this.props.company?.themeConfiguration.ecommerce?.subtitle ?? '',
                backgroundImage: this.props.company?.themeConfiguration.ecommerce?.backgroundImage ?? '',
                type           : this.props.company?.themeConfiguration.ecommerce?.type ?? 'default',
                paymentOptions : this.props.company?.themeConfiguration.ecommerce?.paymentOptions ?? [],
                documentUrl    : this.props.company?.themeConfiguration.ecommerce?.documentUrl ?? '',
                navigateTo     : this.props.company?.themeConfiguration.ecommerce?.navigateTo ?? 'shop',
            },
            isSubmitted : false,
            success     : false,
            errorMsg    : undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.company && this.props.company.membership.type !== 'digital') {
            window.location.href = 'error'
        } else if (this.props.company && prevProps.company !== this.props.company) {
            this.setState({
                ...this.state,
                general     : {
                    title         : this.props.company.themeConfiguration.general.title ?? '',
                    website       : this.props.company.themeConfiguration.general.website ?? '',
                    primaryColor  : this.props.company.themeConfiguration.general.primaryColor ?? '',
                    secondaryColor: this.props.company.themeConfiguration.general.secondaryColor ?? '',
                    primaryFont   : this.props.company.themeConfiguration.general.primaryFont ?? '',
                    secondaryFont : this.props.company.themeConfiguration.general.secondaryFont ?? ''
                },
                header      : {
                    isActive       : true,
                    textColor      : this.props.company.themeConfiguration.header.textColor ?? '',
                    backgroundColor: this.props.company.themeConfiguration.header.backgroundColor ?? '',
                    images         : this.props.company.themeConfiguration.header.images ?? []
                },
                footer      : {
                    isActive       : true,
                    textColor      : this.props.company.themeConfiguration.footer.textColor ?? '',
                    backgroundColor: this.props.company.themeConfiguration.footer.backgroundColor ?? '',
                    images         : this.props.company.themeConfiguration.footer.images ?? []
                },
                welcome     : {
                    subTitle       : this.props.company.themeConfiguration.welcome.text ?? '',
                    videoUrl       : this.props.company.themeConfiguration.welcome.videoUrl ?? '',
                    buttonUrl      : this.props.company.themeConfiguration.welcome.buttonUrl ?? '',
                    buttonLabel    : this.props.company.themeConfiguration.welcome.buttonLabel ?? '',
                    openInNewWindow: this.props.company.themeConfiguration.welcome.openInNewWindow ?? false,
                    backgroundColor: this.props.company.themeConfiguration.welcome.backgroundColor ?? ''
                },
                about       : {
                    isActive       : true,
                    titleColor     : this.props.company.themeConfiguration.about.titleColor ?? '',
                    textColor      : this.props.company.themeConfiguration.about.textColor ?? '',
                    backgroundColor: this.props.company.themeConfiguration.about.backgroundColor ?? ''
                },
                gallery     : {
                    isActive       : this.props.company.themeConfiguration.gallery?.isActive,
                    subTitle       : this.props.company.themeConfiguration.gallery?.text ?? '',
                    backgroundColor: this.props.company.themeConfiguration.gallery?.backgroundColor ?? '',
                    images         : this.props.company?.themeConfiguration.gallery?.images ?? []
                },
                instants    : {
                    isActive       : this.props.company.themeConfiguration.instants?.isActive,
                    subTitle       : this.props.company.themeConfiguration.instants?.text ?? '',
                    backgroundColor: this.props.company.themeConfiguration.instants?.backgroundColor ?? '',
                    itemColor      : this.props.company.themeConfiguration.instants?.itemColor ?? ''
                },
                contact     : {
                    isActive       : this.props.company.themeConfiguration.contact?.isActive,
                    subTitle       : this.props.company.themeConfiguration.contact?.text ?? '',
                    backgroundColor: this.props.company.themeConfiguration.contact?.backgroundColor ?? ''
                },
                address     : {
                    isActive       : this.props.company.themeConfiguration.address?.isActive,
                    subTitle       : this.props.company.themeConfiguration.address?.text ?? '',
                    backgroundColor: this.props.company.themeConfiguration.address?.backgroundColor ?? '',
                    images         : this.props.company.themeConfiguration.address?.images ?? [],
                    itemColor      : this.props.company.themeConfiguration.address?.itemColor ?? ''
                },
                review      : {
                    isActive       : this.props.company.themeConfiguration.review?.isActive,
                    subTitle       : this.props.company.themeConfiguration.review?.text ?? '',
                    backgroundColor: this.props.company.themeConfiguration.review?.backgroundColor ?? '',
                    placeId        : this.props.company.themeConfiguration.general?.placeId ?? ''
                },
                clickCollect: this.props.company.clickCollect.active ? {
                    isActive       : this.props.company.themeConfiguration.clickCollect?.isActive,
                    backgroundColor: this.props.company.themeConfiguration.clickCollect?.backgroundColor ?? ''
                } : undefined,
                foodMenu    : {
                    isActive       : this.props.company.themeConfiguration.foodMenu?.isActive,
                    headerTitle    : this.props.company.themeConfiguration.foodMenu?.headerTitle ?? '',
                    title          : this.props.company.themeConfiguration.foodMenu?.title ?? '',
                    subTitle       : this.props.company.themeConfiguration.foodMenu?.subtitle ?? '',
                    backgroundColor: this.props.company.themeConfiguration.foodMenu?.backgroundColor ?? '',
                    displayAsIcon  : this.props.company.themeConfiguration.foodMenu?.displayAsIcon,
                    tabs           : this.props.company.themeConfiguration.foodMenu?.tabs ?? []
                },
                socialMedia : {
                    facebook : this.props.company.themeConfiguration.socialMedia?.facebook ?? '',
                    instagram: this.props.company.themeConfiguration.socialMedia?.instagram ?? '',
                    tiktok   : this.props.company.themeConfiguration.socialMedia?.tiktok ?? '',
                    linkedin : this.props.company.themeConfiguration.socialMedia?.linkedin ?? ''
                },
                ecommerce   : {
                    isActive       : this.props.company.themeConfiguration.ecommerce?.isActive,
                    headerTitle    : this.props.company.themeConfiguration.ecommerce?.headerTitle ?? '',
                    title          : this.props.company.themeConfiguration.ecommerce?.title ?? '',
                    subTitle       : this.props.company.themeConfiguration.ecommerce?.subtitle ?? '',
                    backgroundImage: this.props.company.themeConfiguration.ecommerce?.backgroundImage ?? '',
                    type           : this.props.company.themeConfiguration.ecommerce?.type ?? 'default',
                    paymentOptions : this.props.company.themeConfiguration.ecommerce?.paymentOptions ?? [],
                    documentUrl    : this.props.company.themeConfiguration.ecommerce?.documentUrl ?? '',
                    navigateTo     : this.props.company.themeConfiguration.ecommerce?.navigateTo ?? 'shop',
                }
            })
        }
        if (this.props.success && prevProps.success !== this.props.success) {
            this.setState({success: true})
        }
    }

    componentDidMount(): void {
        document.title = i18n.company.company_theme_title;
    }

    render(): ReactNode {
        console.log(this.props.company?.themeConfiguration)
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )
        return (
            <div>
                <DefaultPageHeader title={i18n.company.website_configuration}/>

                <div className="profile-edition theme-builder">
                    {loader}

                    <div className="wizard-form-field mt-3 mb-60">
                        <div id="accordion">
                            <AccordionItem title={"Général"}>
                                <GeneralConfiguration data={this.state.general}
                                                      isSubmitted={this.state.isSubmitted}
                                                      onChange={(key: keyof ThemeConfigurationDetailsType, value: string) => this.setState({
                                                          ...this.state,
                                                          general    : {
                                                              ...this.state.general,
                                                              [key]: value
                                                          },
                                                          isSubmitted: false,
                                                          success    : false,
                                                          errorMsg   : undefined
                                                      })}
                                                      onPrimaryColorChange={(value: string) => this.onPrimaryColorChange(value)}/>
                            </AccordionItem>

                            <AccordionItem title={"Header"}>
                                <HeaderConfiguration data={this.state.header}
                                                     isSubmitted={this.state.isSubmitted}
                                                     companyId={this.props.company?.id ?? ''}
                                                     onChange={(key: keyof ThemeConfigurationBlockType, value: string[] | string) => this.setState({
                                                         ...this.state,
                                                         header     : {
                                                             ...this.state.header,
                                                             [key]: value
                                                         },
                                                         isSubmitted: false,
                                                         success    : false,
                                                         errorMsg   : undefined
                                                     })}/>
                            </AccordionItem>

                            <AccordionItem title={"Accueil"}>
                                <WelcomeConfiguration data={this.state.welcome}
                                                      isSubmitted={this.state.isSubmitted}
                                                      companyId={this.props.company?.id ?? ''}
                                                      onChange={(key: keyof ThemeWelcomeBlockType, value: ValueOf<ThemeWelcomeBlockType>) => this.setState({
                                                          ...this.state,
                                                          welcome    : {
                                                              ...this.state.welcome,
                                                              [key]: value
                                                          },
                                                          isSubmitted: false,
                                                          success    : false,
                                                          errorMsg   : undefined
                                                      })}/>
                            </AccordionItem>

                            <AccordionItem title={"A propos"}>
                                <AboutConfiguration data={this.state.about}
                                                    isSubmitted={this.state.isSubmitted}
                                                    onChange={(key: keyof ThemeConfigurationBlockType, value: string) => this.setState({
                                                        ...this.state,
                                                        about      : {
                                                            ...this.state.about,
                                                            [key]: value
                                                        },
                                                        isSubmitted: false,
                                                        success    : false,
                                                        errorMsg   : undefined
                                                    })}/>
                            </AccordionItem>

                            <AccordionItem title={"Documents"}>
                                <FoodMenuBlockConfiguration data={this.state.foodMenu}
                                                            isSubmitted={this.state.isSubmitted}
                                                            onChange={(key: KeyOf<ThemeFoodMenuType>, value: ValueOf<ThemeFoodMenuType>) => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    foodMenu   : {
                                                                        ...this.state.foodMenu,
                                                                        [key]: value
                                                                    },
                                                                    isSubmitted: false,
                                                                    success    : false,
                                                                    errorMsg   : undefined
                                                                })
                                                            }}/>
                            </AccordionItem>

                            <AccordionItem title={"Galerie"}>
                                <BlockWithImagesConfiguration data={this.state.gallery}
                                                              type={'gallery'}
                                                              isSubmitted={this.state.isSubmitted}
                                                              companyId={this.props.company?.id ?? ''}
                                                              onChange={(key: keyof ThemeConfigurationBlockType, value: string[] | string | boolean) => this.setState({
                                                                  ...this.state,
                                                                  gallery    : {
                                                                      ...this.state.gallery,
                                                                      [key]: value
                                                                  },
                                                                  isSubmitted: false,
                                                                  success    : false,
                                                                  errorMsg   : undefined
                                                              })}/>
                            </AccordionItem>

                            <AccordionItem title={"Nos offres"}>
                                <InstantBlockConfiguration data={this.state.instants}
                                                           isSubmitted={this.state.isSubmitted}
                                                           onChange={(key: keyof ThemeConfigurationBlockType, value: string | boolean) => this.setState({
                                                               ...this.state,
                                                               instants: {
                                                                   ...this.state.instants,
                                                                   [key]: value
                                                               }
                                                           })}/>
                            </AccordionItem>

                            {this.state.clickCollect && <AccordionItem title="Click & Collect">
                                <ClickCollectBlockConfiguration label="Activer click & collect"
                                                                data={this.state.clickCollect}
                                                                isSubmitted={this.state.isSubmitted}
                                                                onChange={(key: keyof ThemeConfigurationBlockType, value: string | boolean) => {
                                                                    if (this.state.clickCollect) {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            clickCollect: {
                                                                                ...this.state.clickCollect,
                                                                                [key]: value
                                                                            },
                                                                            isSubmitted : false,
                                                                            success     : false,
                                                                            errorMsg    : undefined
                                                                        })
                                                                    }
                                                                }}/>
                            </AccordionItem>}

                            <AccordionItem title="Adresse">
                                <BlockWithImagesConfiguration data={this.state.address}
                                                              type="address"
                                                              isSubmitted={this.state.isSubmitted}
                                                              companyId={this.props.company?.id ?? ''}
                                                              onChange={(key: keyof ThemeConfigurationBlockType, value: string[] | string | boolean) => this.setState({
                                                                  ...this.state,
                                                                  address    : {
                                                                      ...this.state.address,
                                                                      [key]: value
                                                                  },
                                                                  isSubmitted: false,
                                                                  success    : false,
                                                                  errorMsg   : undefined
                                                              })}/>
                            </AccordionItem>

                            <AccordionItem title={"Avis google"}>
                                <ReviewBlockConfiguration data={this.state.review}
                                                          isSubmitted={this.state.isSubmitted}
                                                          onChange={(key: keyof ThemeConfigurationBlockType, value: string | boolean) => this.setState({
                                                              ...this.state,
                                                              review     : {
                                                                  ...this.state.review,
                                                                  [key]: value
                                                              },
                                                              isSubmitted: false,
                                                              success    : false,
                                                              errorMsg   : undefined
                                                          })}/>
                            </AccordionItem>

                            <AccordionItem title={"Contact"}>
                                <DefaultBlockConfiguration label={"Activer contact"}
                                                           data={this.state.contact}
                                                           isSubmitted={this.state.isSubmitted}
                                                           onChange={(key: keyof ThemeConfigurationBlockType, value: string | boolean) => this.setState({
                                                               ...this.state,
                                                               contact    : {
                                                                   ...this.state.contact,
                                                                   [key]: value
                                                               },
                                                               isSubmitted: false,
                                                               success    : false,
                                                               errorMsg   : undefined
                                                           })}/>
                            </AccordionItem>

                            <AccordionItem title={"Footer"}>
                                <HeaderConfiguration data={this.state.footer}
                                                     isSubmitted={this.state.isSubmitted}
                                                     companyId={this.props.company?.id ?? ""}
                                                     onChange={(key: keyof ThemeConfigurationBlockType, value: string[] | string) => this.setState({
                                                         ...this.state,
                                                         footer     : {
                                                             ...this.state.footer,
                                                             [key]: value
                                                         },
                                                         isSubmitted: false,
                                                         success    : false,
                                                         errorMsg   : undefined
                                                     })}/>
                            </AccordionItem>

                            <AccordionItem title={"Social Media"}>
                                <SocialMediaConfiguration data={this.state.socialMedia}
                                                          isSubmitted={this.state.isSubmitted}
                                                          onChange={(key: keyof ThemeSocialMediaType, value: string) => this.setState({
                                                              ...this.state,
                                                              socialMedia: {
                                                                  ...this.state.socialMedia,
                                                                  [key]: value
                                                              },
                                                              isSubmitted: false,
                                                              success    : false,
                                                              errorMsg   : undefined
                                                          })}/>
                            </AccordionItem>

                            <AccordionItem title={"Ecommerce"}>
                                <EcommerceBlockConfiguration data={this.state.ecommerce}
                                                             isSubmitted={this.state.isSubmitted}
                                                             companyId={this.props.company?.id ?? ""}
                                                             onChange={(key: keyof ThemeEcommerceType, value: string | boolean | string[]) => this.setState({
                                                                 ...this.state,
                                                                 ecommerce  : {
                                                                     ...this.state.ecommerce,
                                                                     [key]: value
                                                                 },
                                                                 isSubmitted: false,
                                                                 success    : false,
                                                                 errorMsg   : undefined
                                                             })}/>
                            </AccordionItem>
                        </div>
                    </div>

                    <div className="wizard-form-field">
                        {this.state.errorMsg && <p className={'error'}>{this.state.errorMsg}</p>}
                        {this.state.success && <SuccessThemeConfiguration
                            website={this.props.company?.themeConfiguration.general.website}/>}

                        <div className="d-flex justify-content-center flex-wrap my-5">
                            <button onClick={() => this.reinitializeThemeConfiguration()}
                                    className="company-submit-btn bg-transparent">
                                {i18n.company.default_theme}
                            </button>

                            <button onClick={() => this.submitForm()}
                                    className="company-submit-btn">
                                {i18n.company.save}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onPrimaryColorChange = (value: string): void => {
        this.setState({
            ...this.state,
            general: {
                ...this.state.general,
                primaryColor: value
            },
            header : {
                ...this.state.header,
                backgroundColor: value
            },
            footer : {
                ...this.state.footer,
                backgroundColor: value
            },
            about  : {
                ...this.state.about,
                backgroundColor: value
            }
        })
    }

    validateForm = (): string | undefined => {
        if (this.state.general.website.length === 0 || this.state.general.title.length === 0 ||
            this.state.general.primaryColor.length === 0 || this.state.general.secondaryColor.length === 0 ||
            this.state.general.primaryFont.length === 0 || this.state.general.secondaryFont.length === 0 ||
            this.state.header.textColor?.length === 0 || this.state.header.backgroundColor?.length === 0 ||
            this.state.footer.textColor?.length === 0 || this.state.footer.backgroundColor?.length === 0 ||
            this.state.welcome.subTitle?.length === 0 ||
            this.state.welcome.buttonLabel?.length === 0 || this.state.welcome.buttonUrl?.length === 0 ||
            this.state.about.textColor?.length === 0 || this.state.about.backgroundColor?.length === 0 ||
            !ThemeFormValidation.isValidBlock(this.state.gallery) ||
            !ThemeFormValidation.isValidBlock(this.state.instants) ||
            !ThemeFormValidation.isValidBlock(this.state.address) ||
            !ThemeFormValidation.isValidBlock(this.state.contact) ||
            !ThemeFormValidation.isValidReviewBlock(this.state.review) ||
            !ThemeFormValidation.isValidFoodMenuBlock(this.state.foodMenu) ||
            !ThemeFormValidation.isValidEcommerceBlock(this.state.ecommerce)
        ) {

            return i18n.company.empty_form_error
        } else if (!ThemeFormValidation.isValidGalleryImages(this.state.gallery)) {
            return i18n.company.gallery_images_error
        } else if (!ThemeFormValidation.isValidFoodMenuDocuments(this.state.foodMenu)) {
            return i18n.company.food_menu_documents_error
        }
    }

    submitForm = (): void => {
        this.setState({
            isSubmitted: true,
            errorMsg   : this.validateForm()
        }, () => {
            if (!this.state.errorMsg && this.props.company) {
                const companyBuilder: CompanyBuilder = new CompanyBuilder()
                    .withId(this.props.company.id)
                    .withMembership(this.props.company.membership)
                    .withSiren(this.props.company.siren)
                    .withName(this.props.company.name)
                    .withType(this.props.company.type)
                    .withCategory(this.props.company.category)
                    .withAddress(this.props.company.address.address)
                    .withZipCode(this.props.company.address.zipCode)
                    .withCity(this.props.company.address.city)
                    .withLongitude(this.props.company.address.longitude)
                    .withLatitude(this.props.company.address.latitude)
                    .withOpeningHours(this.mapOpeningHours(this.props.company.openingHours))
                    .withDescription(this.props.company.contacts.description)
                    .withWebsite(this.props.company.contacts.website)
                    .withEmail(this.props.company.contacts.email)
                    .withPremiumSubdomain(this.props.company.premiumSubdomain)
                    .withActiveClickCollect(this.props.company.clickCollect.active)
                    .withDescriptionClickCollect(this.props.company.clickCollect.description)
                    .withPicturesClickCollect(this.props.company.clickCollect.pictures)
                    .withPhoneNumber(this.props.company.contacts.phoneNumber)
                    .withLegalInfo(this.props.company.legalInfo)
                    .withThemeConfiguration(ThemeConfigurationFormMapper.mapToThemeConfiguration(this.state))

                this.props.editCompany(companyBuilder.build())
            }
        })
    }

    reinitializeThemeConfiguration = (): void => {
        if (this.props.company) {
            this.setState({...ThemeConfigurationFormMapper.mapToDefaultThemeConfigurationState(this.props.company)})
        }
    }

    private mapOpeningHours(data: CompanyOpeningHours[]) {
        const array: OpeningHoursType[] = []

        data.map(openingHour => {
            array.push({
                id       : openingHour.id,
                daytype  : openingHour.openingDays,
                startTime: openingHour.startTime,
                endTime  : openingHour.endTime,
                isPaused : openingHour.isPaused
            })
        })

        return array
    }

}
