import React, { PureComponent, ReactNode } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router";
import { Registration } from "../../../registration/adapters/primaries/registration";
import { PrivateArea } from "./private.route";
import { PageNotFoundError } from "./components/pageNotFound.error";
import { InstantsListing } from "../../../instant-management/adapters/primaries/instantsListing";
import { CompanyProfile } from "../../../company/adapters/primaries/profile";
import { EditServiceInstant } from "../../../instant-management/adapters/primaries/edition/service";
import { CompanyEdition } from "../../../company/adapters/primaries/edition";
import { Stats } from "../../../stats/adapters/primaries/superAdminStats";
import { AgendaInstants } from "../../../instant/adapters/primaries/agenda";
import { EditAgendaInstant } from "../../../instant-management/adapters/primaries/edition/agendaEvent";
import { EditSpecialOfferInstant } from "../../../instant-management/adapters/primaries/edition/specialOffer";
import { SubscriptionOffers } from "../../../payment/adapters/primaries/subscription";
import { PaymentSuccess } from "../../../payment/adapters/primaries/paymentSuccess";
import { PaymentFail } from "../../../payment/adapters/primaries/paymentFail";
import { CreateAgendaInstantPage } from "../../../instant-management/adapters/primaries/creation/agendaEvent/createAgendaInstant.page";
import { CreateServiceInstantPage } from "../../../instant-management/adapters/primaries/creation/service/createServiceInstant.page";
import { CompanyMessagingPage } from "../../../messaging/adapters/primaries";
import { CreateSpecialOfferInstantPage } from "../../../instant-management/adapters/primaries/creation/specialOffer/createSpecialOfferInstant.page";
import { MunicipalityServices } from "../../../municipality/adapters/primaries";
import { Login } from "../../../authentication/adapters/primaries/login";
import { QrCodeProfile } from "../../../company/adapters/primaries/qrcode";
import { CompanyStats } from "../../../stats/adapters/primaries/CompanyStats";
import { EmailsProPage } from "./components/emailsPro.page";
import { ThemeConfigurationForm } from "../../../company/adapters/primaries/themeConfiguration";
import { ProductsPage } from "../../../ecommerce/adapters/primaries/list";
import { AddProductPage } from "../../../ecommerce/adapters/primaries/add";
import { EditProductPage } from "../../../ecommerce/adapters/primaries/edit";
import { EcommerceOrdersPage } from "../../../ecommerce/adapters/primaries/orderList";
import { EcommerceOrderDetailsPage } from "../../../ecommerce/adapters/primaries/orderDetails";

interface Props {
    hasSession: boolean;
    isSuperAdmin: boolean;
}

export class RootNavigationContainer extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route
                        exact
                        path="/registration/:type"
                        component={() => (
                            <Redirect
                                to={{
                                    pathname: "/inscription/:type",
                                    search: window.location.search,
                                }}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/registration"
                        component={() => (
                            <Redirect
                                to={{
                                    pathname: "/inscription",
                                    search: window.location.search,
                                }}
                            />
                        )}
                    />

                    <Route exact path="/inscription" component={Registration} />
                    <Route
                        exact
                        path="/inscription/:type"
                        component={Registration}
                    />
                    <Route
                        exact
                        path="/"
                        component={() => (
                            <Index isAuthenticated={this.props.hasSession} />
                        )}
                    />

                    <PrivateArea
                        component={CreateServiceInstantPage}
                        path="/createService"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={CreateSpecialOfferInstantPage}
                        path="/createSpecialOffer"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={CreateAgendaInstantPage}
                        path="/createAgendaEvent"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={EditServiceInstant}
                        path="/editService/:id"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={EditAgendaInstant}
                        path="/editAgendaEvent/:id"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={EditSpecialOfferInstant}
                        path="/editSpecialOffer/:id"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={InstantsListing}
                        path="/instants"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={CompanyProfile}
                        path="/company"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={CompanyEdition}
                        path="/editCompany"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={AgendaInstants}
                        path="/agenda"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        isAuthenticated={this.props.hasSession}
                        component={SubscriptionOffers}
                        path={"/subscription"}
                    />

                    <PrivateArea
                        isAuthenticated={this.props.hasSession}
                        component={PaymentSuccess}
                        path={"/paymentSuccess"}
                    />

                    <PrivateArea
                        isAuthenticated={this.props.hasSession}
                        component={PaymentFail}
                        path={"/paymentFail"}
                    />

                    <PrivateArea
                        component={CompanyMessagingPage}
                        path="/messages"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={Stats}
                        path="/stats"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={CompanyStats}
                        path="/my-stats"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={MunicipalityServices}
                        path="/services"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={QrCodeProfile}
                        path="/marketing"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={EmailsProPage}
                        path="/emails"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={ThemeConfigurationForm}
                        path="/template-builder"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={ProductsPage}
                        path="/products"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={AddProductPage}
                        path="/add-product"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={EditProductPage}
                        path="/edit-product/:id"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={EcommerceOrdersPage}
                        path="/transactions"
                        isAuthenticated={this.props.hasSession}
                    />

                    <PrivateArea
                        component={EcommerceOrderDetailsPage}
                        path="/transaction/:id"
                        isAuthenticated={this.props.hasSession}
                    />

                    <Route component={PageNotFoundError} />
                </Switch>
            </BrowserRouter>
        );
    }
}

class Index extends PureComponent<{ isAuthenticated: boolean }> {
    render() {
        if (this.props.isAuthenticated) return <Redirect push to="/company" />;
        return <Redirect push to="/login" />;
    }
}
