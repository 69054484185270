import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { of } from "rxjs";
import { InstantService } from "../../domain/gateway/instant.service";
import { LOAD_AGENDA_INSTANTS, LoadAgendaInstantAction } from "./loadAgendaInstants.types";
import { loadAgendaInstantsFailed, loadAgendaInstantsSucceeded } from "./loadAgendaInstants.actions";
import { InstantHeader } from "../../domain/entites/instantHeader";


export const agendaInstantEpic: Epic = (action$: ActionsObservable<LoadAgendaInstantAction>,
                                        store: StateObservable<AppState>,
                                        { instantService }:
                                            {
                                                instantService: InstantService
                                            }) =>
    action$.pipe(
        ofType(LOAD_AGENDA_INSTANTS),
        switchMap(action => instantService.loadAgendaInstants(action.payload)
            .pipe(
                map(instants => loadAgendaInstantsSucceeded(instants.sort(sortByStartDate()))),
                catchError(error => of(loadAgendaInstantsFailed(error)))
            )
        )
    )


const sortByStartDate = () =>
    (instant1: InstantHeader, instant2: InstantHeader): number =>
        instant1.originalStartDate > instant2.originalStartDate ? 1 : -1
