export const SEND_LOGIN_CONFIRMATION_CODE = 'SEND_LOGIN_CONFIRMATION_CODE'
export const SEND_LOGIN_CONFIRMATION_CODE_FAILED = 'SEND_LOGIN_CONFIRMATION_CODE_FAILED'
export const SEND_LOGIN_CONFIRMATION_CODE_SUCCEEDED = 'SEND_LOGIN_CONFIRMATION_CODE_SUCCEEDED'

export interface SendLoginConfirmationCodeAction {
    type: typeof SEND_LOGIN_CONFIRMATION_CODE;
    payload: {
        phoneNumber: string;
        code: string;
    };
}

interface SendLoginConfirmationCodeFailedAction {
    type: typeof SEND_LOGIN_CONFIRMATION_CODE_FAILED;
    payload: string;
}

interface SendLoginConfirmationCodeSucceededAction {
    type: typeof SEND_LOGIN_CONFIRMATION_CODE_SUCCEEDED;
    payload: string;
}

export type SendLoginConfirmationCodeActionTypes =
    SendLoginConfirmationCodeAction
    | SendLoginConfirmationCodeFailedAction
    | SendLoginConfirmationCodeSucceededAction
