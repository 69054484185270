import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import {
    UPDATE_SINGLE_FIELD,
    UpdateSingleFieldAction,
} from "./actionTypes";
import { CompanyService } from "../../domain/gateway/company.service";
import { updateSingleFieldFailed, updateSingleFieldSucceeded } from "./actions";

export const updateSingleFieldEpic: Epic = (action$: ActionsObservable<UpdateSingleFieldAction>,
                                            store: StateObservable<AppState>,
                                            {companyService}: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(UPDATE_SINGLE_FIELD),
        switchMap((action) => {
                if (store.value.company.company) {
                    return companyService.updateSingleField(store.value.company.company?.id, action.payload.field, action.payload.value)
                        .pipe(
                            map(() => updateSingleFieldSucceeded()),
                            catchError(err => of(updateSingleFieldFailed(err)))
                        )
                } else {
                    return throwError('the is not companyId')
                }
            }
        )
    );
