import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../configuration/i18n";
import { Company } from "../../../../company/domain/entity/company";
import { SubscriptionTable } from "./subscriptionTable";
import { SubscriptionId } from "../../../domain/entities/types/subscription.type";
import { BannerInvoicingLink } from "./subscriptionTable/component/bannerInvoicingLink";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";

interface Props {
    company: Company | null
}

export class SubscriptionOffersContainer extends PureComponent<Props> {

    componentDidMount(): void {
        document.title = i18n.company.subscription_page_title;
    }

    render(): ReactNode {
        if (this.props.company) {
            return (
                <div className={'subscription-payment-table'}>
                    <DefaultPageHeader
                        title={`${i18n.payment.subscription_title} : ${this.props.company.membership.type.toUpperCase()} ${i18n.payment.free_primium(this.props.company.type)}`}/>

                    <div className={'container-fluid custom-width-1260 my-3'}>
                        <div className={'profile-section-container'}>
                            {this.renderInvoicingLink()}

                            <div className={'p-3'}>{i18n.payment.subscription_description}</div>

                            <SubscriptionTable preferredOffer={SubscriptionId.PREMIUM}/>
                        </div>
                    </div>
                </div>
            )
        } else
            return <div/>
    }

    renderInvoicingLink(): JSX.Element | null {
        return this.props.company?.type !== 'association' && this.props.company?.membership.type !== 'freemium' ?
            <BannerInvoicingLink/> :
            this.props.company?.type === 'association' && this.props.company.membership.type !== 'premium' ?
                <BannerInvoicingLink/> : null
    }
}
