import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { PaymentService } from "../../domain/gateway/paymentService";
import { VALIDATE_PAYMENT, ValidatePaymentAction } from "./actionTypes";
import { validatePaymentFailed, validatePaymentSucceeded } from "./action";
import { PaymentStatus } from "../../domain/entities/types/subscription.type";
import { loadCompanyDetails } from "../../../company/usecases/loadCompanyDetails/actions";

export const validatePaymentEpic: Epic = (action$: ActionsObservable<ValidatePaymentAction>,
                                                 store: StateObservable<AppState>,
                                                 { paymentService }: { paymentService: PaymentService }) =>
    action$.pipe(
        ofType(VALIDATE_PAYMENT),
        switchMap((action) => paymentService.validatePayment(action.payload.sessionId)
            .pipe(
                concatMap((status: PaymentStatus) => [
                    validatePaymentSucceeded(status),
                    loadCompanyDetails(action.payload.companyId)
                ]),
                catchError((error: string) => of(validatePaymentFailed(error)))
            )
        )
    )
