import React, { PureComponent, ReactNode } from "react";
import { Schedule } from "../../../../../domain/entities/types/activationTimeType";
import { ApplicationContext } from "../../../../../../configuration/application.context";
import { i18n } from "../../../../../../configuration/i18n";

interface Props {
    schedule: Schedule
    onRemove: () => void;
}

const moment = ApplicationContext.getInstance().momentJs()


export class RecurrentSchedulePreview extends PureComponent<Props> {
    render(): ReactNode {
        const textBefore = this.props.schedule.prePoned &&
            <p>{i18n.management_instant.preview_show_two_hours_before}</p>

        return (
            <div className={'opening-hours-item'}>
                <b>{this.props.schedule.dateRange.label}</b>
                <p>  {`${moment(this.props.schedule.dateRange.startTime, 'HH:mm:ssZ')
                    .format('HH:mm')}-${moment(this.props.schedule.dateRange.endTime, 'HH:mm:ssZ')
                    .format('HH:mm')}`}</p>
                {textBefore}
                <span className={'opening-hours-btn close-btn'} onClick={() => this.props.onRemove()}>
                    <i className="fa fa-times"/>
                </span>
            </div>
        )
    }
}
