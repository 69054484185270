export class TimeLeftColors {

    private static breakpointsColors = {
        start : 'green',
        middle: 'orange',
        end   : 'red'
    }

    static findTimeLeftColor(timeLeft: number, isStarted: boolean, colorsTab: { [key: number]: string }): string {
        if (!isStarted) return this.breakpointsColors.start
        else
            return this.findTimeLeftColorDuring(timeLeft, colorsTab)
    }

    static findTimeLeftColorDuring(timeLeft: number, colorsTab: { [key: number]: string }): string {
        if (this.isUnder30minutes(timeLeft))
            return this.breakpointsColors.end
        if (this.isBetweenOneHourAnd30minutes(timeLeft))
            return this.determineTimeLeftColor(timeLeft, colorsTab)
        return this.breakpointsColors.start
    }

    private static isUnder30minutes(timeLeft: number): boolean {
        return timeLeft <= this.convertMinutesToSeconds(30)
    }

    private static isBetweenOneHourAnd30minutes(timeLeft: number): boolean {
        return this.convertMinutesToSeconds(60) > timeLeft &&
            timeLeft > this.convertMinutesToSeconds(30)
    }

    private static determineTimeLeftColor(timeLeft: number, colorsTab: { [key: number]: string }): string {
        return colorsTab[this.convertSecondsToMinutes(timeLeft)] || this.breakpointsColors.middle
    }

    private static convertSecondsToMinutes(timeLeft: number): number {
        return Math.ceil(timeLeft / 60)
    }

    private static convertMinutesToSeconds(minutes: number): number {
        return minutes * 60
    }

}
