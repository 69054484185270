import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../../../redux-configuration/AppState";
import { ManagementInstant } from "../../../../domain/entities/ManagementInstant";
import { Company } from "../../../../../company/domain/entity/company";
import { CreateInstantAction } from "../../../../usecases/createInstant/createInstant.types";
import { createInstant } from "../../../../usecases/createInstant/createInstant.actions";
import {
    createInstantSuccessSelector,
    isCreatingInstantSelector
} from "../../../../usecases/createInstant/createInstant.selectors";
import { CreateAgendaInstantContainer } from "./createAgendaEvent.container";
import { companyDetailsSelector } from "../../../../../company/usecases/loadCompanyDetails/selector";

interface StateToPropsType {
    company: Company | null;
    loadingCreation: boolean
    successCreation: boolean | null
}

interface DispatchToPropsType {
    createInstant: (instant: ManagementInstant) => void;
}


const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companyDetailsSelector(state),
    loadingCreation: isCreatingInstantSelector(state),
    successCreation: createInstantSuccessSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    createInstant          : (instant: ManagementInstant): CreateInstantAction => dispatch(createInstant(instant))
})


export const CreateAgendaInstant = connect(mapStateToProps ,mapDispatchToProps)(CreateAgendaInstantContainer)
