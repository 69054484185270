import React from 'react';
import { Conversation } from "../../../../domain/entities/conversation";
import { MessagingSpecifications } from "../../specifications";

interface Props {
    conversation: Conversation;
    onClick: () => void;
    disabled: boolean;
    active: boolean;
}

export const ConversationItem = (props: Props): JSX.Element => {

    const className = props.disabled ? ' disabled' : props.active ? ' active' : ''

    return (
        <div className={'conversation d-flex align-items-center' + className} onClick={() => {
            if (!props.disabled) {
                props.onClick()
            }
        }}>
            <div>
                <img src={props.conversation.picture} className={'conversation-image'}/>
            </div>

            <div className={'content'}>
                <div className={'d-flex justify-content-between'}>
                    <p>{props.conversation.title}</p>
                    <p className={'date'}>{MessagingSpecifications.formatDate(props.conversation.updatedAt)}</p>
                </div>
                <span>{props.conversation.excerpt}</span>
            </div>
        </div>
    )
}
