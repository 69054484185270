import { connect } from "react-redux";
import { Dispatch } from "redux";
import { deleteMunicipalityService } from "../../usecases/delete/actions";
import { DeleteMunicipalityServiceAction } from "../../usecases/delete/actionTypes";
import { MunicipalityServicesContainer } from "./services.container";
import { ChangeServicesOrderAction } from "../../usecases/changeOrder/actionTypes";
import { changeServicesOrder } from "../../usecases/changeOrder/actions";
import { listingMunicipalServices } from "../../usecases/listingMunicipalServices/actions";
import { ListingMunicipalServicesAction } from "../../usecases/listingMunicipalServices/actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { companySelector } from "../../../company/usecases/updateStoreCompany/selector";
import { Company } from "../../../company/domain/entity/company";
import { listingMunicipalServicesLoadingSelector, municipalServicesSelector } from "../../usecases/listingMunicipalServices/selectors";
import { MunicipalService } from "../../domain/entities/municipalService";
import { changeServicesOrderLoadingSelector } from "../../usecases/changeOrder/selectors";
import { deleteMunicipalityServiceLoadingSelector } from "../../usecases/delete/selectors";

interface StateToPropsType {
    company: Company | null;
    services: MunicipalService[] | undefined;
    loading: boolean;
}

interface DispatchToPropsType {
    deleteService: (serviceId: string) => void;
    changeServicesOrder: (municipalityId: string, serviceIds: string[]) => void;
    loadServices: (municipalityId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company : companySelector(state),
    services: municipalServicesSelector(state),
    loading : listingMunicipalServicesLoadingSelector(state) || deleteMunicipalityServiceLoadingSelector(state) || changeServicesOrderLoadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    deleteService      : (serviceId: string): DeleteMunicipalityServiceAction => dispatch(deleteMunicipalityService(serviceId)),
    changeServicesOrder: (municipalityId: string, serviceIds: string[]): ChangeServicesOrderAction => dispatch(changeServicesOrder(municipalityId, serviceIds)),
    loadServices       : (municipalityId: string): ListingMunicipalServicesAction => dispatch(listingMunicipalServices(municipalityId)),
})

export const MunicipalityServices = connect(mapStateToProps, mapDispatchToProps)(MunicipalityServicesContainer)
