import React, { PureComponent, ReactNode } from "react";
import { Schedule } from "../../../../../domain/entities/types/activationTimeType";
import { i18n } from "../../../../../../configuration/i18n";
import { ApplicationContext } from "../../../../../../configuration/application.context";
import { CheckboxInput } from "../../../../../../common/adapters/primaries/form/inputs/checkbox.input";
import { TimeInput } from "../../../../../../common/adapters/primaries/form/inputs/time/time.input";

interface Props {
    onSubmit: (schedule: Schedule) => void
}

interface State {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    errorMsg: string | undefined;
    error: boolean | undefined;
    preponed: boolean;
}

const moment = ApplicationContext.getInstance().momentJs()

export class FixedScheduleForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            startDate: moment().format('YYYY-MM-DD'),
            startTime: moment().format('HH:mm'),
            endDate  : moment().format('YYYY-MM-DD'),
            endTime  : moment().add(1, 'hours').format('HH:mm'),
            errorMsg : undefined,
            error    : undefined,
            preponed : false
        }
    }

    render(): ReactNode {
        const errorMsg = this.state.error &&
            <p className={'error'}>{this.state.errorMsg}</p>
        return (
            <div>
                <div className="wizard-form-input opening-hours">
                    <label className="start">{i18n.management_instant.start_date_text}</label>
                    <input type="date" value={this.state.startDate}
                           className={this.state.error ? 'error' : undefined}
                           onChange={(event): void => this.setState(
                               {
                                   startDate: event.target.value,
                                   error    : false
                               })}/>

                    <label className="end">{i18n.management_instant.to}</label>
                    <TimeInput time={this.state.startTime}
                               className={this.state.error ? 'error' : ''}
                               onChange={(startTime: string): void => this.setState({
                                   startTime,
                                   error: undefined
                               })}/>
                </div>
                <div className="wizard-form-input opening-hours">
                    <label className="start">{i18n.management_instant.end_date_text}</label>
                    <input type="date" value={this.state.endDate}
                           className={this.state.error ? 'error' : undefined}
                           onChange={(event) => this.setState({ endDate: event.target.value, error: undefined })}/>

                    <label className="end">{i18n.management_instant.at}</label>
                    <TimeInput time={this.state.endTime}
                               className={this.state.error ? 'error' : ''}
                               onChange={(endTime: string): void => this.setState({
                                   endTime,
                                   error: undefined
                               })}/>
                </div>

                <div className="sub-section text-right">
                    <CheckboxInput checked={this.state.preponed}
                                   label={i18n.management_instant.preview_show_two_hours_before}
                                   raiseUpdates={() => this.setState({ preponed: !this.state.preponed })}/>
                </div>

                {errorMsg}

                <div className={'sub-section'}>
                    <span className={'opening-hours-btn add-btn'}
                          onClick={() => this.onSubmit()}>
                        {i18n.registration.add}
                    </span>
                </div>
            </div>
        )
    }

    onSubmit(): void {
        if (this.validateForm()) {
            const startDateTime = moment(this.state.startDate + this.state.startTime, 'YYYY-MM-DDHH:mm', true)
            const endDateTime = moment(this.state.endDate + this.state.endTime, 'YYYY-MM-DDHH:mm', true)
            const schedule: Schedule = {
                id       : '',
                dateRange: {
                    startTime: startDateTime.format(),
                    endTime  : endDateTime.format(),
                    label    : `${startDateTime.format('ddd ll')} - ${endDateTime.format('ddd ll')}`,
                    key      : 'fixed'
                },
                isPaused : false,
                prePoned : this.state.preponed
            }
            this.props.onSubmit(schedule)
        }
    }

    validateForm(): boolean {
        const startDateTime = moment(this.state.startDate + this.state.startTime, 'YYYY-MM-DDHH:mm', true)
        const endDateTime = moment(this.state.endDate + this.state.endTime, 'YYYY-MM-DDHH:mm', true)
        if (this.state.startDate.length === 0 || this.state.startTime.length === 0 || this.state.endDate.length === 0 || this.state.endTime.length === 0) {
            this.setState({ error: true, errorMsg: i18n.management_instant.error_empty_input })
            return false
        }
        else if (!startDateTime.isValid() || !endDateTime.isValid()) {
            this.setState({ error: true, errorMsg: i18n.management_instant.date_format_error })
            return false
        }
        else if (endDateTime.isBefore(startDateTime)) {
            this.setState({ error: true, errorMsg: i18n.management_instant.end_date_error })
            return false
        }
        else
            return true
    }

}
