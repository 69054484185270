import {
    LOAD_STORED_TOKEN,
    LOAD_STORED_TOKEN_FAILED,
    LOAD_STORED_TOKEN_SUCCEEDED,
    LoadStoredTokenActionTypes,
} from "./actionTypes";

export const loadStoredToken = (): LoadStoredTokenActionTypes => ({
    type: LOAD_STORED_TOKEN
})

export const loadStoredTokenSucceeded = (token: string): LoadStoredTokenActionTypes => ({
    type   : LOAD_STORED_TOKEN_SUCCEEDED,
    payload: token
})

export const loadStoredTokenFailed = (): LoadStoredTokenActionTypes => ({
    type: LOAD_STORED_TOKEN_FAILED
})
