import React, { PureComponent, ReactNode } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { i18n } from "../../../../../configuration/i18n";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { emojiList } from "../emoji";

interface Props {
    error: boolean
    value: string | undefined
    raiseUpdates: (text: string) => void
    maxLength?: number
}

interface State {
    editor: EditorState
}

export class HtmlEditor extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            editor: this.htmlToDraft()
        }
    }

    private htmlToDraft(): EditorState {
        if (this.props.value && this.props.value.length > 1) {
            const html = this.props.value;
            const blocksFromHTML = htmlToDraft(html);
            const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            return EditorState.createWithContent(content)
        }
        else
            return EditorState.createEmpty()
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.value !== this.props.value && !prevProps.value) {
            this.setState({
                editor: this.htmlToDraft(),
            })
        }
    }

    render(): ReactNode {
        const error = this.props.error ? 'error' : undefined
        return (
            <div className="wizard-form-input">
                <label>{i18n.common.description}</label>
                <Editor wrapperClassName={error}
                        editorClassName="editor-custom-style"
                        stripPastedStyles={true}
                        toolbar={{
                            options: ['inline', 'link', 'emoji'],
                            inline : {
                                options: ['bold', 'italic'],
                            },
                            link   : {
                                popupClassName       : 'link-popup',
                                showOpenOptionOnHover: false
                            },
                            emoji: {
                                emojis: emojiList,
                            },
                        }}
                        editorState={this.state.editor}
                        onEditorStateChange={(editorState: EditorState) => this.onEditorStateChange(editorState)}/>
            </div>
        )
    }

    onEditorStateChange(editorState: EditorState): void {
        this.setState({ editor: editorState })
        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.props.raiseUpdates(description)
    }
}
