import { CityService } from "../../../domain/gateway/city.service";
import { Observable, throwError } from "rxjs";
import { CityHeader } from "../../../domain/entity/cityHeader";
import { CityMapper } from "./mapper/city.mapper";
import { catchError, map } from "rxjs/operators";
import { CityDTO } from "./dto/city.dto";
import { ObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/ObservableAjax.httpClient";

export class ApiCityService implements CityService {

    searchCity(keywords: string): Observable<CityHeader[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/syntheticProAccount/search?page=1&type=mairie&keywords=${keywords}`
        return new ObservableAjaxHttpClient()
            .get<{ data: CityDTO[] }>(url)
            .pipe(
                map(response => CityMapper.mapDataToCities(response.data)),
                catchError(err => throwError(err))
            )
    }
}
