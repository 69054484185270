import { ProductDetails } from "../../domain/entities/productDetails";

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const LOAD_PRODUCTS_FAILED = 'LOAD_PRODUCTS_FAILED'
export const LOAD_PRODUCTS_SUCCEEDED = 'LOAD_PRODUCTS_SUCCEEDED'

export interface LoadProductsAction {
    type: typeof LOAD_PRODUCTS;
    payload: string;
}

export interface LoadProductsFailedAction {
    type: typeof LOAD_PRODUCTS_FAILED;
    payload: string;
}

export interface LoadProductsSucceededAction {
    type: typeof LOAD_PRODUCTS_SUCCEEDED;
    payload: ProductDetails[];
}

export type LoadProductsActionTypes = LoadProductsAction | LoadProductsFailedAction | LoadProductsSucceededAction
