import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { POST_INSTANT_ON_INSTAGRAM, PostInstantOnInstagramAction } from "./actionTypes";
import { postInstantOnInstagramFailed,postInstantOnInstagramSucceeded } from "./actions";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";

export const postInstantOnInstagramEpic: Epic = (action$: ActionsObservable<PostInstantOnInstagramAction>,
                                                store: StateObservable<AppState>,
                                                {instantManagementService}: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(POST_INSTANT_ON_INSTAGRAM),
        switchMap(action => instantManagementService.shareInstantOnInstagram(action.payload.page, action.payload.content)
            .pipe(
                map(() => postInstantOnInstagramSucceeded()),
                catchError(error => of(postInstantOnInstagramFailed(error)))
            )
        )
    )

