import React, { PureComponent, ReactNode } from "react";
import Select, { components, MenuListProps, OptionProps, SingleValueProps, } from 'react-select';
import { SelectOptionType } from "../../../../../common/domain/types/selectOption.type";
import { ServiceIcon } from "../../../../domain/entities/serviceIcon";
import './selectorIcon.css'

interface Props {
    onChange: (value: ServiceIcon) => void
    error?: boolean
    selected: ServiceIcon | undefined
    label: string
    icons: ServiceIcon[] | undefined
}

export class SelectorIconService extends PureComponent<Props> {

    render(): ReactNode {
        const error = this.props.error ? 'error select-category' : 'select-category'
        return (
            <div className="wizard-form-input select-option-area select_service">
                <label>{this.props.label}</label>

                <Select className={error}
                        classNamePrefix="select"
                        isSearchable={true}
                        components={{
                            Option     : (innerProps: OptionProps<SelectOptionType>) => this.renderOption(innerProps),
                            MenuList   : (innerProps: MenuListProps<SelectOptionType>) => this.renderMenuList(innerProps),
                            SingleValue: ({children, ...props}: SingleValueProps<SelectOptionType>) => this.renderSingleValue({children, ...props})
                        }}
                        value={this.getValueSelected(this.props.selected)}
                        options={this.getServiceIconOptions()}
                        onChange={selected => this.props.onChange(this.selectedValue((selected as SelectOptionType)))}/>
            </div>
        )
    }

    renderOption(innerProps: OptionProps<SelectOptionType>): JSX.Element {
        return (
            <components.Option {...innerProps} className={'option-icon'}>
                <img src={innerProps.data.icon} alt={innerProps.data.label} className={'icon-select'}/>
            </components.Option>
        )
    }

    renderMenuList(innerProps: MenuListProps<SelectOptionType>): JSX.Element {
        return (
            <components.MenuList {...innerProps}>
                <div className={'options-container'}>
                    {innerProps.children}
                </div>
            </components.MenuList>
        )
    }

    renderSingleValue({children, ...props}: SingleValueProps<SelectOptionType>): JSX.Element {
        return (
            <components.SingleValue {...props}>
                <img src={props.data.icon} alt={props.data.label} className={'icon-value'}/>
                {children}
            </components.SingleValue>
        )
    }

    getServiceIconOptions(): SelectOptionType[] {
        return this.props.icons ? this.props.icons.map(item => ({label: item.label, value: item.id, icon: item.url})) : []
    }

    getValueSelected(selected: ServiceIcon | undefined): SelectOptionType | undefined {
        return selected ? this.getServiceIconOptions().find(item => item.value === selected.id) : undefined
    }

    selectedValue(selected: SelectOptionType): ServiceIcon {
        return new ServiceIcon(selected.value, selected.label, selected.icon ?? '')
    }
}
