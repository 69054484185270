import { CityHeader } from "../entity/cityHeader";

export class CityHeaderBuilder {
    protected _name: string
    protected _zipcode: number

    withName(value: string): CityHeaderBuilder {
        this._name = value
        return this
    }

    withZipCode(value: number): CityHeaderBuilder {
        this._zipcode = value
        return this
    }

    build(): CityHeader {
        return new CityHeader(this._name, this._zipcode)
    }
}
