export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_MESSAGE_SUCCEEDED = 'SEND_MESSAGE_SUCCEEDED'
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED'

export interface SendMessageAction {
    type: typeof SEND_MESSAGE;
    payload: {
        conversationId: string;
        businessId: string;
        message: string;
        image: File | undefined;
    };
}

export interface SendMessageSucceededAction {
    type: typeof SEND_MESSAGE_SUCCEEDED;
}

export interface SendMessageFailedAction {
    type: typeof SEND_MESSAGE_FAILED;
    payload: string;
}

export type SendMessageActionsTypes = SendMessageAction | SendMessageSucceededAction | SendMessageFailedAction
