import React, { PureComponent, ReactNode } from "react";
import { UserBuilder } from "../../../../registration/domain/builder/user.builder";
import { i18n } from "../../../../configuration/i18n";
import { SubmitButton } from "../../../../registration/adapters/primaries/registration/components/submit.button";
import { FormValidation } from "../../../../common/adapters/primaries/form/validation";
import { User } from "../../../../registration/domain/entity/user";
import { PhoneInputField } from "../../../../common/adapters/primaries/form/inputs/phoneInput";

interface Props {
    phoneNumber: string;
    loading: boolean;
    error: string | undefined;
    success: boolean | null;
    onPressNext: (phoneNumber: string) => void;
    signInPhoneNumber: (user: User) => void;
}

interface State {
    phoneNumber: string;
    errorMsg: string | undefined;
}

export class SignInForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            phoneNumber: this.props.phoneNumber,
            errorMsg   : undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.success && prevProps.success !== this.props.success) {
            this.props.onPressNext(this.state.phoneNumber)
        }

        if (this.props.error && prevProps.error !== this.props.error) {
            this.setState({errorMsg: this.renderSignInErrorMessage(this.props.error)})
        }
    }

    componentDidMount(): void {
        const params = (new URL(document.location.href)).searchParams;
        const phone = "+" + params.get("phone")?.trim();
        if(FormValidation.validatePhoneNumberFix(phone)){
         this.setState({phoneNumber: phone})
        }
    }

    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        return (
            <div className="wizard-forms pb-4">
                {loader}
                <div className="inner clearfix pb-4">
                    <div className="wizard-title text-center">
                        <h1 className={'title'}>{i18n.authentication.login_your_account}</h1>
                        <p className="wizard-sub-text px-3">{i18n.authentication.authenticate}</p>
                    </div>

                    <div className="wizard-form-field px-3">
                        <PhoneInputField className={'registration-input'}
                                         error={!!this.state.errorMsg}
                                         label={i18n.authentication.phoneNumber}
                                         value={this.state.phoneNumber}
                                         raiseUpdates={(phoneNumber: string) => this.setState({
                                             phoneNumber,
                                             errorMsg: undefined
                                         })}/>

                        <div className={'sub-section'}>
                            {this.state.errorMsg && <p className={'error mb-2'}>{this.state.errorMsg}</p>}

                            <p className="info mb-4">
                                {i18n.authentication.have_no_account}
                                <a style={{textDecoration: 'underline'}}
                                   href={'/inscription'}>{i18n.authentication.here}</a>
                            </p>

                            <p>{i18n.authentication.inscription_info}</p>
                        </div>
                    </div>
                </div>

                <div className={'sub-section px-3'}>
                    <SubmitButton submit={() => this.signInPhoneNumber()}
                                  goBack={() => void 0}/>
                </div>
            </div>
        )
    }

    signInPhoneNumber(): void {
        this.setState({
            errorMsg: this.renderErrorMessage()
        }, () => {
            if (!this.state.errorMsg) {
                const user = new UserBuilder()
                    .withPhoneNumber(this.state.phoneNumber)
                    .build()
                this.props.signInPhoneNumber(user)
            }
        })
    }

    renderErrorMessage(): string | undefined {
        if (this.state.phoneNumber === '+33' || this.state.phoneNumber.length === 0)
            return i18n.authentication.empty_form_error
        else if (!FormValidation.validate('phoneNumber', this.state.phoneNumber))
            return i18n.authentication.phone_number_error
    }

    renderSignInErrorMessage(error: string): string {
        if (error === '403')
            return i18n.authentication.contact_vivez_clichy
        else
            return i18n.authentication.server_error
    }
}
