import { InstantHeader } from "../../../instantHeader";
import { ApplicationContext } from "../../../../../../configuration/application.context";
import { i18n } from "../../../../../../configuration/i18n";

export interface WeeklyData {
    title: string;
    data: InstantHeader[];
}

const moment = ApplicationContext.getInstance().momentJs()

export class WeeklyInstantHelper {

    static sortInstantListByDay(data: InstantHeader[]): WeeklyData[] {
        return [
            {
                title: i18n.instants.today,
                data : data.filter(instant => {
                    const dateStart = moment(instant.dateStart, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
                    return moment(dateStart).isSameOrBefore(moment().add(0, 'days')) === true
                })
            },
            {
                title: i18n.instants.tomorrow,
                data : data.filter(instant =>
                    moment(instant.dateStart).format('YYYY-MM-DD') === moment().add(1, 'days').format('YYYY-MM-DD'))
            },
            {
                title: moment().add(2, 'days').format('dddd'),
                data : data.filter(instant =>
                    moment(instant.dateStart).format('YYYY-MM-DD') === moment().add(2, 'days').format('YYYY-MM-DD'))
            },
            {
                title: moment().add(3, 'days').format('dddd'),
                data : data.filter(instant =>
                    moment(instant.dateStart).format('YYYY-MM-DD') === moment().add(3, 'days').format('YYYY-MM-DD'))
            },
            {
                title: moment().add(4, 'days').format('dddd'),
                data : data.filter(instant =>
                    moment(instant.dateStart).format('YYYY-MM-DD') === moment().add(4, 'days').format('YYYY-MM-DD'))
            },
            {
                title: moment().add(5, 'days').format('dddd'),
                data : data.filter(instant =>
                    moment(instant.dateStart).format('YYYY-MM-DD') === moment().add(5, 'days').format('YYYY-MM-DD'))
            },
            {
                title: moment().add(6, 'days').format('dddd'),
                data : data.filter(instant =>
                    moment(instant.dateStart).format('YYYY-MM-DD') === moment().add(6, 'days').format('YYYY-MM-DD'))
            },
            {
                title: moment().add(7, 'days').format('dddd'),
                data : data.filter(instant =>
                    moment(instant.dateStart).format('YYYY-MM-DD') === moment().add(7, 'days').format('YYYY-MM-DD'))
            },
            {
                title: this.getTitleThisMonth(),
                data : this.getDataThisMonth(data)
            },
            {
                title: i18n.instants.in_month(moment().add(1, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isAfter(moment().add(8, 'days')) &&  moment(instant.dateStart).isBetween(moment().add(1, 'months').startOf('month'),
                        moment().add(1, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(2, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(2, 'months').startOf('month'),
                        moment().add(2, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(3, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(3, 'months').startOf('month'),
                        moment().add(3, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(4, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(4, 'months').startOf('month'),
                        moment().add(4, 'months').endOf('month')))
            },
            {
                title:i18n.instants.in_month(moment().add(5, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(5, 'months').startOf('month'),
                        moment().add(5, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(6, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(6, 'months').startOf('month'),
                        moment().add(6, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(7, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(7, 'months').startOf('month'),
                        moment().add(7, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(8, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(8, 'months').startOf('month'),
                        moment().add(8, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(9, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(9, 'months').startOf('month'),
                        moment().add(9, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(10, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(10, 'months').startOf('month'),
                        moment().add(10, 'months').endOf('month')))
            },
            {
                title: i18n.instants.in_month(moment().add(11, 'months').format('MMMM')),
                data : data.filter(instant =>
                    moment(instant.dateStart).isBetween(moment().add(11, 'months').startOf('month'),
                        moment().add(11, 'months').endOf('month')))
            }
        ]
    }
    static getTitleThisMonth(): string {
        return moment().add(8, 'days').month() === moment().month() ?
            i18n.instants.next_weeks :
            i18n.instants.in_month(moment().add(8, 'months').format('MMMM'))

    }

    static getDataThisMonth(data: InstantHeader[]): InstantHeader[] {
        const endMonthDate = moment().endOf('month').format()
        return moment().add(8, 'days').month() === moment().month() ?
            data.filter(instant =>
                moment(instant.dateStart).isBetween(moment().add(8, 'days'), moment(endMonthDate))) :
            []
    }
}
