import { Company } from "../../../company/domain/entity/company";

export const LOAD_BUSINESS_BY_CODE = 'LOAD_BUSINESS_BY_CODE'
export const LOAD_BUSINESS_BY_CODE_FAILED = 'LOAD_BUSINESS_BY_CODE_FAILED'
export const LOAD_BUSINESS_BY_CODE_SUCCEEDED = 'LOAD_BUSINESS_BY_CODE_SUCCEEDED'

export interface LoadBusinessByCodeAction {
    type: typeof LOAD_BUSINESS_BY_CODE;
    payload: { code: string; stringifiedInputs: string }
}

export interface LoadBusinessByCodeFailedAction {
    type: typeof LOAD_BUSINESS_BY_CODE_FAILED;
    payload: string;
}

export interface LoadBusinessByCodeSucceededAction {
    type: typeof LOAD_BUSINESS_BY_CODE_SUCCEEDED;
    payload: Company;
}

export type LoadBusinessByCodeActionType = LoadBusinessByCodeAction

export type LoadBusinessByCodeActionTypes =
    | LoadBusinessByCodeAction
    | LoadBusinessByCodeFailedAction
    | LoadBusinessByCodeSucceededAction
