import React, { useEffect, useState } from "react";

interface Props {
    loading: boolean;
    documentUrl: string;
    uploadedDocumentUrl: string | undefined;
    onChange: (value: string) => void;
    uploadDocument: (doc: File) => void;
}

export const EcommerceDocumentInput = (props: Props): JSX.Element => {
    const [isUploadingDocument, setIsUploadingDocument] = useState<boolean>(false);

    useEffect(() => {
        if (props.uploadedDocumentUrl && isUploadingDocument) {
            props.onChange(props.uploadedDocumentUrl);
            setIsUploadingDocument(false);
        }
    }, [props.uploadedDocumentUrl]);

    return (
        <div className={"logo-input wizard-form-input align-items-start mb-0"}>
            <label className={"pt-2 text-left"}>Fichier PDF des CGV *</label>

            {props.documentUrl.length > 0 ? (
                <div className={"img-container"}>
                    <div className="pdf-file-preview d-flex align-items-center justify-content-center flex-column">
                        <i className="fa fa-file-pdf"/>
                        <p>Fichier téléchargé</p>
                    </div>

                    <div className="img-overlay d-flex align-items-center justify-content-center">
                        <button type="button" onClick={() => props.onChange("")}>
                            Supprimer
                        </button>
                    </div>
                </div>
            ) : (
                <div className="add-img-btn d-flex align-items-center justify-content-center flex-column position-relative">
                    {props.loading && isUploadingDocument ? (
                        <div className={"image-loader loader"}/>
                    ) : (
                        <>
                            <div className={"text-center"}>
                                <i className="fa fa-plus"/>
                                <p>Ajouter un PDF</p>
                            </div>

                            <input type="file"
                                   onChange={(e) => {
                                       if (e.target.files?.[0]) {
                                           setIsUploadingDocument(true);
                                           props.uploadDocument(e.target.files[0]);
                                       }
                                   }}/>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
