import { ManagementInstant } from "../../domain/entities/ManagementInstant";

export const LOAD_MANAGEMENT_INSTANT = 'LOAD_MANAGEMENT_INSTANT'
export const LOAD_MANAGEMENT_INSTANT_SUCCEEDED = 'LOAD_MANAGEMENT_INSTANT_SUCCEEDED'
export const LOAD_MANAGEMENT_INSTANT_FAILED = 'LOAD_MANAGEMENT_INSTANT_FAILED'

export interface LoadManagementInstantAction {
    type: typeof LOAD_MANAGEMENT_INSTANT;
    payload: string;
}

export interface LoadManagementInstantSucceededAction {
    type: typeof LOAD_MANAGEMENT_INSTANT_SUCCEEDED;
    payload: ManagementInstant;
}

export interface LoadManagementInstantFailedAction {
    type: typeof LOAD_MANAGEMENT_INSTANT_FAILED;
    payload: string;
}

export type LoadManagementInstantActionsTypes =
    LoadManagementInstantAction
    | LoadManagementInstantSucceededAction
    | LoadManagementInstantFailedAction