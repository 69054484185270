import React, { PureComponent, ReactNode } from 'react'
import { Company } from "../../../../domain/entity/company";
import { i18n } from "../../../../../configuration/i18n";
import { QRCode } from "react-qrcode-logo";
import LogoVivezClichy from "../../../../../assets/img/LogoVivezClichy.png";
import LogoVivezParis from "../../../../../assets/img/LogoVivezParis.png";

interface Props {
    company: Company | null;
}

export class QrcodeApp extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className={'company_tab_container my-3'}>
                <div className={'row'}>
                    <div className={'col-12 col-md-7 col-xl-8 p-4'} dangerouslySetInnerHTML={{__html: i18n.company.description_qrcode}}/>

                    <div className={'col-12 col-md-5 col-xl-4 text-center mt-4'}>
                        <QRCode id="appQR"
                                size={225}
                                value={this.getQRCodeLink()}
                                logoImage={this.getLogoQrCode()}
                                logoWidth={60}
                                logoHeight={60}
                                bgColor={'#363B7A'}
                                fgColor={'#FFFFFF'}/>

                        <a onClick={(e) => {
                            e.preventDefault()
                            this.downloadAppQR()
                        }} className={'link'}>{i18n.company.download}</a>
                    </div>
                </div>
            </div>
        )
    }

    getQRCodeLink(): string {
        if (this.props.company?.address.zipCode === '92110')
            return 'https://clichy.page.link/?link=https://www.vivezclichy.fr/public_info_page/?businessId%3D' + this.props.company?.id + '%26action%3Dfollow&apn=com.vivezici&isi=1358432040&ibi=com.vivezici.vivezclichy'
        else
            return 'https://vparis.page.link/?link=https://www.vivezclichy.fr/public_info_page/?businessId%3D' + this.props.company?.id + '%26action%3Dfollow&apn=com.vivezici.vivezparis&isi=6444020748&ibi=com.vivezici.vivezparis'
    }

    getLogoQrCode(): string {
        if (this.props.company?.address.zipCode === '92110')
            return LogoVivezClichy
        else
            return LogoVivezParis
    }

    downloadAppQR(): void {
        const canvas = document.getElementById('appQR') as HTMLCanvasElement
        if (canvas) {
            const qrCodeURL = canvas.toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            const aEl = document.createElement("a");
            aEl.href = qrCodeURL;
            aEl.download = "QR_Code_App_" + this.props.company?.id + ".png";
            document.body.appendChild(aEl);
            aEl.click();
            document.body.removeChild(aEl);
        }
    }
}
