export type ValueOf<T> = T[keyof T];
export type KeyOf<T> = keyof T;

type AppCategoryType = {
    [key: string]: string
}

export const AssociationCategories: AppCategoryType = {
    sport                  : 'Sport',
    culture                : 'Culture',
    citizen_life           : 'Vie citoyenne',
    childhood_and_youth    : 'Enfance et jeunesse',
    employment_and_training: 'Emploi et formation',
    housing                : 'Logement',
    health_and_disability  : 'Santé et handicap',
    social_affairs         : 'Affaires sociales',
    bar                    : 'Bar'
}

export const AppCategories: AppCategoryType = {
    academie_de_billard                                          : 'Académie de billard',
    acupuncteur                                                  : 'Acupuncteur',
    administration                                               : 'Administration',
    aerodrome                                                    : 'Aérodrome',
    aeroport                                                     : 'Aéroport',
    agence_d_architecture                                        : 'Agence d\'architecture',
    agence_de_baby_sitters                                       : 'Agence de baby - sitters',
    agence_de_communication                                      : 'Agence de communication',
    agence_de_credit_immobilier                                  : 'Agence de crédit immobilier',
    agence_de_developpement                                      : 'Agence de développement',
    agence_de_gardes_d_enfants_a_domicile                        : 'Agence de gardes d\'enfants à domicile',
    agence_de_location_de_bungalows_mobile_homes                 : 'Agence de location de bungalows mobile - homes',
    agence_de_location_de_materiel_divers                        : 'Agence de location de matériel divers',
    agence_de_location_de_motos                                  : 'Agence de location de motos',
    agence_de_location_de_salles                                 : 'Agence de location de salles',
    agence_de_location_de_voitures                               : 'Agence de location de voitures',
    agence_de_location_immobiliere                               : 'Agence de location immobilière',
    agence_de_location_saisonniere                               : 'Agence de location saisonnière',
    agence_de_presse                                             : 'Agence de presse',
    agence_de_publicite                                          : 'Agence de publicité',
    agence_de_relations_publiques                                : 'Agence de relations publiques',
    agence_de_services_d_aide_a_domicile                         : 'Agence de services d\'aide à domicile',
    agence_de_voyages                                            : 'Agence de voyages',
    agence_d_interim                                             : 'Agence d\'intérim',
    agence_immobiliere                                           : 'Agence immobilière',
    agence_matrimoniale                                          : 'Agence matrimoniale',
    agence_web                                                   : 'Agence web',
    agriculteur                                                  : 'Agriculteur',
    aide_a_domicile                                              : 'Aide à domicile',
    allergologue                                                 : 'Allergologue',
    ambassade                                                    : 'Ambassade',
    animalerie                                                   : 'Animalerie',
    aquarium                                                     : 'Aquarium',
    architecte                                                   : 'Architecte',
    architecte_d_interieur                                       : 'Architecte d\'intérieur',
    architecte_paysagiste                                        : 'Architecte paysagiste',
    armurerie                                                    : 'Armurerie',
    artisan_chocolatier                                          : 'Artisan chocolatier',
    artisan_ferronnier                                           : 'Artisan ferronnier',
    artiste_peintre                                              : 'Artiste peintre',
    assistante_maternelle                                        : 'Assistante maternelle',
    assurance                                                    : 'Assurance',
    atelier_d_art                                                : 'Atelier d\'art',
    auberge                                                      : 'Auberge',
    auberge_de_jeunesse                                          : 'Auberge de jeunesse',
    auto_ecole                                                   : 'Auto - école',
    avocat                                                       : 'Avocat',
    banque                                                       : 'Banque',
    bar                                                          : 'Bar',
    barbier                                                      : 'Barbier',
    bar_tabac                                                    : 'Bar - tabac',
    bibliotheque                                                 : 'Bibliothèque',
    bijouterie_joaillerie                                        : 'Bijouterie / joaillerie',
    boite_de_nuit                                                : 'Boîte de nuit',
    boucherie_charcuterie                                        : 'Boucherie / charcuterie',
    boulangerie_patisserie                                       : 'Boulangerie - pâtisserie',
    boutique_de_chaussure                                        : 'Boutique de chaussure',
    boutique_de_decoration                                       : 'Boutique de décoration',
    boutique_de_lingerie                                         : 'Boutique de lingerie',
    boutique_de_luminaire                                        : 'Boutique de luminaire',
    boutique_de_maroquinerie                                     : 'Boutique de maroquinerie',
    boutique_de_pret_a_porter                                    : 'Boutique de prêt-à-porter',
    boutique_de_vetements_enfants                                : 'Boutique de vêtements enfants',
    boutique_seconde_main                                        : 'Boutique seconde main',
    bowling                                                      : 'Bowling',
    brasserie                                                    : 'Brasserie',
    brocante                                                     : 'Brocante',
    bureau_de_poste                                              : 'Bureau de poste',
    cabaret                                                      : 'Cabaret',
    cabinet_de_recrutement                                       : 'Cabinet de recrutement',
    cabinet_medical                                              : 'Cabinet médical',
    cafe                                                         : 'Café',
    camping                                                      : 'Camping',
    cardiologue                                                  : 'Cardiologue',
    carreleur                                                    : 'Carreleur',
    carrosserie_et_peinture_automobile                           : 'Carrosserie et peinture automobile',
    caserne_de_sapeurs_pompiers                                  : 'Caserne de sapeurs - pompiers',
    caserne_militaire                                            : 'Caserne militaire',
    casino                                                       : 'Casino',
    casse_automobile_moto                                        : 'Casse automobile / moto',
    caviste                                                      : 'Caviste',
    centre_aere_et_de_loisirs_pour_enfants                       : 'Centre aéré et de loisirs pour enfants',
    centre_culturel                                              : 'Centre culturel',
    centre_de_bien_etre_et_de_relaxation                         : 'Centre de bien - être et de relaxation',
    centre_de_controle_technique                                 : 'Centre de contrôle technique',
    centre_de_formation                                          : 'Centre de formation',
    centre_de_radiologie                                         : 'Centre de radiologie',
    centre_de_reeducation                                        : 'Centre de rééducation',
    centre_de_shiatsu                                            : 'Centre de shiatsu',
    centre_de_soin                                               : 'Centre de soin',
    centre_de_thalassotherapie                                   : 'Centre de thalassothérapie',
    centre_de_yoga                                               : 'Centre de yoga',
    centre_d_echographie                                         : 'Centre d\'échographie',
    centre_d_entretien_rapide_pour_automobile                    : 'Centre d\'entretien rapide pour automobile',
    centre_equestre                                              : 'Centre équestre',
    centre_hospitalier                                           : 'Centre hospitalier',
    centre_medico_psychologique                                  : 'Centre médico - psychologique',
    centre_socio_culturel                                        : 'Centre socio culturel',
    chaine_de_television                                         : 'Chaîne de télévision',
    chambre_d_agriculture                                        : 'Chambre d\'agriculture',
    chambre_de_commerce_et_d_industrie                           : 'Chambre de commerce et d\'industrie',
    chambre_de_metiers_et_de_l_artisanat                         : 'Chambre de métiers et de l\'artisanat',
    chambre_d_hotes                                              : 'Chambre d\'hôtes',
    charcuterie                                                  : 'Charcuterie',
    charpentier                                                  : 'Charpentier',
    chateau                                                      : 'Château',
    chaudronnerie                                                : 'Chaudronnerie',
    chauffagiste                                                 : 'Chauffagiste',
    chauffeur_de_particulier                                     : 'Chauffeur de particulier',
    chirurgien_d_ophtalmologie_au_laser                          : 'Chirurgien d\'ophtalmologie au laser',
    chirurgien_orthopedique                                      : 'Chirurgien orthopédique',
    cimetiere                                                    : 'Cimetière',
    cinema                                                       : 'Cinéma',
    circuit_automobile                                           : 'Circuit automobile',
    cirque                                                       : 'Cirque',
    clinique                                                     : 'Clinique',
    clinique_veterinaire                                         : 'Clinique vétérinaire',
    club_de_sport                                                : 'Club de sport',
    coach_sportif                                                : 'Coach sportif',
    coiffeur                                                     : 'Coiffeur',
    college                                                      : 'Collège',
    comite_d_organisation_d_expositions_foires_et_salons         : 'Comité d\'organisation d\'expositions, foires et salons',
    commissaire_aux_comptes                                      : 'Commissaire aux comptes',
    commissariat_de_police                                       : 'Commissariat de police',
    compagnie_aerienne                                           : 'Compagnie aérienne',
    compagnie_d_assurance                                        : 'Compagnie d\'assurance',
    comptable                                                    : 'Comptable',
    concessionnaire                                              : 'Concessionnaire',
    conservatoire                                                : 'Conservatoire',
    cordonnier                                                   : 'Cordonnier',
    courtier                                                     : 'Courtier',
    couturier_createur                                           : 'Couturier - créateur',
    couvent                                                      : 'Couvent',
    coworking                                                    : 'Coworking',
    creche                                                       : 'Crèche',
    crematorium                                                  : 'Crématorium',
    creperie                                                     : 'Crêperie',
    cuisiniste                                                   : 'Cuisiniste',
    cybercafe                                                    : 'Cybercafé',
    decorateur                                                   : 'Décorateur',
    demenageur                                                   : 'Déménageur',
    dentiste                                                     : 'Dentiste',
    depot_vente                                                  : 'Dépôt-vente',
    dermatologue                                                 : 'Dermatologue',
    dieteticien                                                  : 'Diététicien',
    disc_jockey                                                  : 'Disc Jockey',
    dispensaire                                                  : 'Dispensaire',
    dresseur_pour_chiens                                         : 'Dresseur pour chiens',
    droguerie_quincaillerie                                      : 'Droguerie-quincaillerie',
    ecole                                                        : 'Ecole',
    ecole_d_aide_soignante                                       : 'École d\'aide soignante',
    ecole_d_architecture                                         : 'École d\'architecture',
    ecole_d_arts                                                 : 'École d\'arts',
    ecole_d_estheticienne                                        : 'École d\'esthéticienne',
    ecole_de_cinema                                              : 'École de cinéma',
    ecole_de_cirque                                              : 'École de cirque',
    ecole_de_coiffure                                            : 'École de coiffure',
    ecole_de_commerce                                            : 'École de commerce',
    ecole_de_communication                                       : 'École de communication',
    ecole_de_danse                                               : 'École de danse',
    ecole_de_design                                              : 'École de design',
    ecole_de_dessin                                              : 'École de dessin',
    ecole_de_gendarmerie                                         : 'École de gendarmerie',
    ecole_de_graphisme                                           : 'École de graphisme',
    ecole_de_langues                                             : 'École de langues',
    ecole_de_musique                                             : 'École de musique',
    ecole_de_police                                              : 'École de police',
    ecole_de_ski                                                 : 'École de ski',
    ecole_de_voile                                               : 'École de voile',
    ecole_dentaire                                               : 'École dentaire',
    ecole_maternelle                                             : 'École maternelle',
    ecole_primaire                                               : 'École primaire',
    ecole_veterinaire                                            : 'École vétérinaire',
    ecurie                                                       : 'Écurie',
    electricien                                                  : 'Électricien',
    eleveur_de_betail                                            : 'Éleveur de bétail',
    eleveur_de_chiens                                            : 'Éleveur de chiens',
    eleveur_de_lapins                                            : 'Éleveur de lapins',
    eleveur_de_moutons_et_de_chevres                             : 'Éleveur de moutons et de chèvres',
    eleveur_de_porcs                                             : 'Éleveur de porcs',
    eleveur_de_volailles                                         : 'Éleveur de volailles',
    endocrinologue                                               : 'Endocrinologue',
    entreposage_et_stockage                                      : 'Entreposage et stockage',
    entrepreneur                                                 : 'Entrepreneur',
    epicerie                                                     : 'Épicerie',
    epilation_au_laser                                           : 'Épilation au laser',
    escape_game                                                  : 'Escape Game',
    etalagiste                                                   : 'Étalagiste',
    expert_immobilier                                            : 'Expert immobilier',
    expert_comptable                                             : 'Expert-comptable',
    fabricant                                                    : 'Fabricant',
    faculte                                                      : 'Faculté',
    ferme                                                        : 'Ferme',
    festival                                                     : 'Festival',
    fleuriste                                                    : 'Fleuriste',
    foire                                                        : 'Foire',
    foret                                                        : 'Forêt',
    formation                                                    : 'Formation',
    fort                                                         : 'Fort',
    fossoyeur                                                    : 'Fossoyeur',
    fournisseur_d_acces_internet                                 : 'Fournisseur d\'accès internet',
    fourriere                                                    : 'Fourrière',
    foyer                                                        : 'Foyer',
    fromagerie                                                   : 'Fromagerie',
    fruitiere                                                    : 'Fruitière',
    galerie                                                      : 'Galerie',
    galerie_d_art                                                : 'Galerie d\'art',
    ganterie                                                     : 'Ganterie',
    garagiste                                                    : 'Garagiste',
    garde_meuble                                                 : 'Garde-meuble',
    garderie                                                     : 'Garderie',
    gardiennage_et_hivernage_de_bateaux                          : 'Gardiennage et hivernage de bateaux',
    gare_ferroviaire                                             : 'Gare ferroviaire',
    gare_routiere                                                : 'Gare routière',
    gastro_enterologue                                           : 'Gastro-entérologue',
    geometre_expert                                              : 'Géomètre expert',
    gite                                                         : 'Gîte',
    glacier                                                      : 'Glacier',
    graphiste                                                    : 'Graphiste',
    groupe_de_presse                                             : 'Groupe de presse',
    groupement_agricole_d_exploitation_en_commun                 : 'Groupement Agricole d\'Exploitation en Commun',
    groupement_d_etablissements                                  : 'Groupement d\'établissements',
    guide_de_haute_montagne                                      : 'Guide de haute-montagne',
    gynecologue                                                  : 'Gynécologue',
    halte_garderie                                               : 'Halte-garderie',
    hammam                                                       : 'Hammam',
    haras                                                        : 'Haras',
    herboristerie                                                : 'Herboristerie',
    hippodrome                                                   : 'Hippodrome',
    homeopathe                                                   : 'Homéopathe',
    hopital                                                      : 'Hôpital',
    horloger                                                     : 'Horloger',
    horticulteur                                                 : 'Horticulteur',
    hotel                                                        : 'Hôtel',
    imprimeur                                                    : 'Imprimeur',
    industrie                                                    : 'Industrie',
    institut_culturel                                            : 'Institut culturel',
    institut_de_beaute                                           : 'Institut de beauté',
    institut_de_bronzage                                         : 'Institut de bronzage',
    institut_de_massages                                         : 'Institut de massages',
    institut_medico_educatif                                     : 'Institut médico-éducatif',
    jardinerie                                                   : 'Jardinerie',
    joaillier                                                    : 'Joaillier',
    kinesitherapeute                                             : 'Kinésithérapeute',
    laboratoire                                                  : 'Laboratoire',
    laboratoire_d_analyses                                       : 'Laboratoire d\'analyses',
    laverie                                                      : 'Laverie',
    librairie                                                    : 'Librairie',
    location_de_voiture                                          : 'Location de voiture',
    logistique                                                   : 'Logistique',
    loisirs_creatifs                                             : 'Loisirs créatifs',
    luthier                                                      : 'Luthier',
    lycee                                                        : 'Lycée',
    macon                                                        : 'Maçon',
    magasin_d_alimentation                                       : 'Magasin d\'alimentation',
    magasin_d_ameublement                                        : 'Magasin d\'ameublement',
    magasin_d_appareils_de_chauffage                             : 'Magasin d\'appareils de chauffage',
    magasin_d_appareils_de_climatisation                         : 'Magasin d\'appareils de climatisation',
    magasin_d_appareils_electromenagers                          : 'Magasin d\'appareils électroménagers',
    magasin_d_articles_de_magie                                  : 'Magasin d\'articles de magie',
    magasin_d_articles_de_peche                                  : 'Magasin d\'articles de pêche',
    magasin_d_articles_de_plage                                  : 'Magasin d\'articles de plage',
    magasin_d_articles_de_sports                                 : 'Magasin d\'articles de sports',
    magasin_d_articles_et_vetements_de_danse                     : 'Magasin d\'articles et vêtements de danse',
    magasin_d_articles_pour_animaux                              : 'Magasin d\'articles pour animaux',
    magasin_d_equipement_de_football                             : 'Magasin d\'équipement de football',
    magasin_d_equipement_de_golf                                 : 'Magasin d\'équipement de golf',
    magasin_d_equipement_de_rugby                                : 'Magasin d\'équipement de rugby',
    magasin_d_equipement_de_skateboard                           : 'Magasin d\'équipement de skateboard',
    magasin_d_equipement_de_sport_de_montagne                    : 'Magasin d\'équipement de sport de montagne',
    magasin_d_equipement_de_surf                                 : 'Magasin d\'équipement de surf',
    magasin_d_equipement_de_tennis                               : 'Magasin d\'équipement de tennis',
    magasin_d_equipement_de_voile                                : 'Magasin d\'équipement de voile',
    magasin_d_equipement_pour_arts_martiaux                      : 'Magasin d\'équipement pour arts martiaux',
    magasin_d_instruments_de_musique                             : 'Magasin d\'instruments de musique',
    magasin_d_objets_de_decoration                               : 'Magasin d\'objets de décoration',
    magasin_d_ordinateur_d_occasion                              : 'Magasin d\'ordinateur d\'occasion',
    magasin_d_ustensiles_de_cuisine                              : 'Magasin d\'ustensiles de cuisine',
    magasin_de_bois_de_chauffage                                 : 'Magasin de bois de chauffage',
    magasin_de_bois_de_construction                              : 'Magasin de bois de construction',
    magasin_de_bonnes_affaires                                   : 'Magasin de bonnes affaires',
    magasin_de_bricolage                                         : 'Magasin de bricolage',
    magasin_de_canapes                                           : 'Magasin de canapés',
    magasin_de_carrelage                                         : 'Magasin de carrelage',
    magasin_de_cartographie                                      : 'Magasin de cartographie',
    magasin_de_cassettes_video_et_DVD                            : 'Magasin de cassettes vidéo et DVD',
    magasin_de_chaussures                                        : 'Magasin de chaussures',
    magasin_de_chaussures_orthopediques                          : 'Magasin de chaussures orthopédiques',
    magasin_de_chocolat                                          : 'Magasin de chocolat',
    magasin_de_cigares                                           : 'Magasin de cigares',
    magasin_de_commerce_equitable                                : 'Magasin de commerce équitable',
    magasin_de_cremes_glacees                                    : 'Magasin de crèmes glacées',
    magasin_de_design                                            : 'Magasin de design',
    magasin_de_disques                                           : 'Magasin de disques',
    magasin_de_fournitures_de_bureau                             : 'Magasin de fournitures de bureau',
    magasin_de_fournitures_de_demenagement                       : 'Magasin de fournitures de déménagement',
    magasin_de_futon                                             : 'Magasin de futon',
    magasin_de_guitares                                          : 'Magasin de guitares',
    magasin_de_jacuzzi                                           : 'Magasin de jacuzzi',
    magasin_de_jeux_et_jouets                                    : 'Magasin de jeux et jouets',
    magasin_de_laine_et_tricot                                   : 'Magasin de laine et tricot',
    magasin_de_literie                                           : 'Magasin de literie',
    magasin_de_luminaires                                        : 'Magasin de luminaires',
    magasin_de_machines_a_coudre                                 : 'Magasin de machines à coudre',
    magasin_de_manga                                             : 'Magasin de manga',
    magasin_de_materiaux_d_isolation                             : 'Magasin de matériaux d\'isolation',
    magasin_de_materiaux_de_construction                         : 'Magasin de materiaux de construction',
    magasin_de_materiel_airsoft                                  : 'Magasin de matériel airsoft',
    magasin_de_materiel_artistique                               : 'Magasin de matériel artistique',
    magasin_de_materiel_audio_et_video                           : 'Magasin de matériel audio et vidéo',
    magasin_de_materiel_d_equitation                             : 'Magasin de matériel d\'équitation',
    magasin_de_materiel_de_billard                               : 'Magasin de matériel de billard',
    magasin_de_materiel_de_camping                               : 'Magasin de matériel de camping',
    magasin_de_materiel_de_maconnerie                            : 'Magasin de matériel de maçonnerie',
    magasin_de_materiel_de_motoculture                           : 'Magasin de matériel de motoculture',
    magasin_de_materiel_de_paintball                             : 'Magasin de matériel de paintball',
    magasin_de_materiel_de_photographie                          : 'Magasin de matériel de photographie',
    magasin_de_materiel_de_plongee_sous_marine                   : 'Magasin de matériel de plongée sous-marine',
    magasin_de_materiel_de_surveillance                          : 'Magasin de matériel de surveillance',
    magasin_de_materiel_electrique                               : 'Magasin de matériel électrique',
    magasin_de_materiel_electronique                             : 'Magasin de matériel électronique',
    magasin_de_materiel_hi_fi                                    : 'Magasin de matériel hi-fi',
    magasin_de_materiel_informatique                             : 'Magasin de matériel informatique',
    magasin_de_materiel_medical_et_paramedical                   : 'Magasin de matériel médical et paramédical',
    magasin_de_materiel_pour_photocopieurs_et_reprographie       : 'Magasin de matériel pour photocopieurs et reprographie',
    magasin_de_meubles                                           : 'Magasin de meubles',
    magasin_de_modeles_reduits                                   : 'Magasin de modèles réduits',
    magasin_de_moquettes                                         : 'Magasin de moquettes',
    magasin_de_papier_peint                                      : 'Magasin de papier-peint',
    magasin_de_partitions_de_musique                             : 'Magasin de partitions de musique',
    magasin_de_peinture                                          : 'Magasin de peinture',
    magasin_de_pianos                                            : 'Magasin de pianos',
    magasin_de_pieces_detachees_pour_appareils_electromenagers   : 'Magasin de pièces détachées pour appareils électroménagers',
    magasin_de_pneus                                             : 'Magasin de pneus',
    magasin_de_pneus_d_occasion                                  : 'Magasin de pneus d\'occasion',
    magasin_de_poterie                                           : 'Magasin de poterie',
    magasin_de_presse                                            : 'Magasin de presse',
    magasin_de_produits_biologiques_dietetiques_et_de_regime     : 'Magasin de produits biologiques, diététiques et de régime',
    magasin_de_produits_issus_de_l_agriculture_biologique        : 'Magasin de produits issus de l\'agriculture biologique',
    magasin_de_puericulture                                      : 'Magasin de puériculture',
    magasin_de_robes_de_mariee_soiree                            : 'Magasin de robes de mariée / soirée',
    magasin_de_sport                                             : 'Magasin de sport',
    magasin_de_telephonie_mobile                                 : 'Magasin de téléphonie mobile',
    magasin_de_tissus                                            : 'Magasin de tissus',
    magasin_de_velos                                             : 'Magasin de vélos',
    magasin_de_vente_de_jeux_video                               : 'Magasin de vente de jeux vidéo',
    magasin_de_vente_en_gros_de_meuble                           : 'Magasin de vente en gros de meuble',
    magasin_de_vetements                                         : 'Magasin de vêtements',
    magasin_de_vetements_de_seconde_main                         : 'Magasin de vêtements de seconde main',
    magasin_de_vetements_de_sport                                : 'Magasin de vêtements de sport',
    magasin_de_vetements_hip_hop                                 : 'Magasin de vêtements hip hop',
    magasin_de_vetements_pour_bebes                              : 'Magasin de vêtements pour bébés',
    magasin_de_vetements_pour_enfants                            : 'Magasin de vêtements pour enfants',
    magasin_de_vetements_pour_femmes                             : 'Magasin de vêtements pour femmes',
    magasin_de_vetements_pour_hommes                             : 'Magasin de vêtements pour hommes',
    magasin_de_vetements_vintage                                 : 'Magasin de vêtements vintage',
    magasin_de_vinyles                                           : 'Magasin de vinyles',
    magasin_de_volets_roulants                                   : 'Magasin de volets roulants',
    magasin_pour_adulte                                          : 'Magasin pour adulte',
    magasin_pour_femmes_enceintes                                : 'Magasin pour femmes enceintes',
    magasins_d_appareil_de_fitness                               : 'Magasins d\'appareil de fitness',
    magasins_d_articles_et_d_accessoires_de_coiffure             : 'Magasins d\'articles et d\'accessoires de coiffure',
    magicien                                                     : 'Magicien',
    mairie                                                       : 'Mairie',
    maison_de_haute_couture                                      : 'Maison de haute-couture',
    maison_de_retraite                                           : 'Maison de retraite',
    manoir                                                       : 'Manoir',
    maraicher                                                    : 'Maraîcher',
    marbrier_funeraire                                           : 'Marbrier funéraire',
    marche                                                       : 'Marché',
    mareyeur                                                     : 'Mareyeur',
    maroquinerie                                                 : 'Maroquinerie',
    masseur                                                      : 'Masseur',
    mecanicien_automobile                                        : 'Mécanicien automobile',
    medecin_de_garde                                             : 'Médecin de garde',
    medecin_du_sport                                             : 'Médecin du sport',
    medecin_generaliste                                          : 'Médecin généraliste',
    medecine_du_travail                                          : 'Médecine du travail',
    mediatheque                                                  : 'Médiathèque',
    memorial                                                     : 'Mémorial',
    menuiserie                                                   : 'Menuiserie',
    mercerie                                                     : 'Mercerie',
    meuble                                                       : 'Meuble',
    minoterie                                                    : 'Minoterie',
    miroiterie                                                   : 'Miroiterie',
    monastere                                                    : 'Monastère',
    moto_ecole                                                   : 'Moto-école',
    musee                                                        : 'Musée',
    mutuelle_de_sante                                            : 'Mutuelle de santé',
    neurologue                                                   : 'Neurologue',
    notaire                                                      : 'Notaire',
    nutritionniste                                               : 'Nutritionniste',
    office_de_tourisme                                           : 'Office de tourisme',
    opera                                                        : 'Opéra',
    operateur_mobile_internet                                    : 'Opérateur mobile / internet',
    ophtalmologiste                                              : 'Ophtalmologiste',
    opticien                                                     : 'Opticien',
    orchestre_de_musique_classique                               : 'Orchestre de musique classique',
    organisateur_de_mariage                                      : 'Organisateur de mariage',
    organisateur_de_randonnee_en_patins_a_roulettes              : 'Organisateur de randonnée en patins à roulettes',
    organisateur_de_sejours_linguistiques                        : 'Organisateur de séjours linguistiques',
    organisateur_de_visites_guidees                              : 'Organisateur de visites guidées',
    orthodontiste                                                : 'Orthodontiste',
    orthopediste_orthesiste                                      : 'Orthopédiste-orthésiste',
    orthophoniste                                                : 'Orthophoniste',
    osteopathe                                                   : 'Ostéopathe',
    oto_rhino_laryngologiste                                     : 'Oto-rhino-laryngologiste',
    palais_des_congres                                           : 'Palais des congrès',
    palais_des_sports                                            : 'Palais des sports',
    papeterie                                                    : 'Papeterie',
    parapharmacie                                                : 'Parapharmacie',
    parc                                                         : 'Parc',
    parc_animalier                                               : 'Parc animalier',
    parc_aquatique                                               : 'Parc aquatique',
    parc_d_accrobranche                                          : 'Parc d\'accrobranche',
    parc_d_attractions                                           : 'Parc d\'attractions',
    parc_de_loisir                                               : 'Parc de loisir',
    parc_des_expositions                                         : 'Parc des expositions',
    parfumerie                                                   : 'Parfumerie',
    parking                                                      : 'Parking',
    patinoire                                                    : 'Patinoire',
    patisserie                                                   : 'Pâtisserie',
    peage                                                        : 'Péage',
    pediatre                                                     : 'Pédiatre',
    pension                                                      : 'Pension',
    pension_d_animaux                                            : 'Pension d\'animaux',
    pepiniere                                                    : 'Pépinière',
    pharmacie                                                    : 'Pharmacie',
    pharmacie_de_garde                                           : 'Pharmacie de garde',
    photographe                                                  : 'Photographe',
    piano_bar                                                    : 'Piano-bar',
    piscine                                                      : 'Piscine',
    pizzeria                                                     : 'Pizzéria',
    plombier                                                     : 'Plombier',
    pneumologue                                                  : 'Pneumologue',
    podologue                                                    : 'Podologue',
    poissonnerie                                                 : 'Poissonnerie',
    polyclinique                                                 : 'Polyclinique',
    poney_club                                                   : 'Poney club',
    port                                                         : 'Port',
    pressing                                                     : 'Pressing',
    primeur                                                      : 'Primeur',
    prison                                                       : 'Prison',
    producteur                                                   : 'Producteur',
    promoteur_immobilier                                         : 'Promoteur immobilier',
    psychanalyste                                                : 'Psychanalyste',
    psychiatre                                                   : 'Psychiatre',
    psychologue                                                  : 'Psychologue',
    psychologue_pour_enfants_et_adolescents                      : 'Psychologue pour enfants et adolescents',
    psychomotricien                                              : 'Psychomotricien',
    psychotherapeute                                             : 'Psychothérapeute',
    quincaillerie                                                : 'Quincaillerie',
    radiologue                                                   : 'Radiologue',
    refuge_pour_animaux                                          : 'Refuge pour animaux',
    regie_publicitaire                                           : 'Régie publicitaire',
    reparateur                                                   : 'Réparateur',
    residence_etudiante                                          : 'Résidence étudiante',
    restaurant                                                   : 'Restaurant',
    restaurant_africain                                          : 'Restaurant africain',
    restaurant_algerien                                          : 'Restaurant algérien',
    restaurant_biologique                                        : 'Restaurant biologique',
    restaurant_brasserie                                         : 'Restaurant Brasserie',
    restaurant_brunch                                            : 'Restaurant brunch',
    restaurant_camerounais                                       : 'Restaurant camerounais',
    restaurant_chinois                                           : 'Restaurant chinois',
    restaurant_congolais                                         : 'Restaurant congolais',
    restaurant_coreen                                            : 'Restaurant coréen',
    restaurant_d_chiche_kebab                                    : 'Restaurant de chiche-kebab',
    restaurant_de_couscous                                       : 'Restaurant de couscous',
    restaurant_de_paella                                         : 'Restaurant de paella',
    restaurant_de_sushi                                          : 'Restaurant de sushi',
    restaurant_de_tapas                                          : 'Restaurant de tapas',
    restaurant_espagnol                                          : 'Restaurant espagnol',
    restaurant_ethiopien                                         : 'Restaurant éthiopien',
    restaurant_français                                          : 'Restaurant français',
    restaurant_gastronomique                                     : 'Restaurant gastronomique',
    restaurant_indien                                            : 'Restaurant indien',
    restaurant_italien                                           : 'Restaurant italien',
    restaurant_ivoirien                                          : 'Restaurant ivoirien',
    restaurant_japonais                                          : 'Restaurant japonais',
    restaurant_karaoke                                           : 'Restaurant karaoké',
    restaurant_libanais                                          : 'Restaurant libanais',
    restaurant_marocain                                          : 'Restaurant marocain',
    restaurant_mexicain                                          : 'Restaurant mexicain',
    restaurant_russe                                             : 'Restaurant russe',
    restaurant_senegalais                                        : 'Restaurant sénégalais',
    restaurant_sri_lankais                                       : 'Restaurant sri-lankais',
    restaurant_sud_africain                                      : 'Restaurant sud-africain',
    restaurant_thailandais                                       : 'Restaurant thaïlandais',
    restaurant_tibetain                                          : 'Restaurant tibétain',
    restaurant_tunisien                                          : 'Restaurant tunisien',
    restaurant_universitaire                                     : 'Restaurant universitaire',
    restaurant_vegetarien                                        : 'Restaurant végétarien',
    restaurant_vietnamien                                        : 'Restaurant vietnamien',
    restaurateur_d_automobiles_de_collection                     : 'Restaurateur d\'automobiles de collection',
    restauration_a_domicile                                      : 'Restauration à domicile',
    restauration_collective                                      : 'Restauration collective',
    restauration_rapide                                          : 'Restauration rapide',
    retouche                                                     : 'Retouche',
    rhumatologue                                                 : 'Rhumatologue',
    sage_femme                                                   : 'Sage-femme',
    salle_de_billard                                             : 'Salle de billard',
    salle_de_cinema                                              : 'Salle de cinéma',
    salle_de_concert                                             : 'Salle de concert',
    salle_de_conference                                          : 'Salle de conférence',
    salle_de_danse                                               : 'Salle de danse',
    salle_de_jeux_video                                          : 'Salle de jeux vidéo',
    salle_de_musculation                                         : 'Salle de musculation',
    salle_de_reception                                           : 'Salle de réception',
    salle_de_sport                                               : 'Salle de sport',
    salon_de_coiffure                                            : 'Salon de coiffure',
    salon_de_coiffure_afro                                       : 'Salon de coiffure afro',
    salon_de_manucure                                            : 'Salon de manucure',
    salon_de_the                                                 : 'Salon de thé',
    sandwicherie                                                 : 'Sandwicherie',
    sante                                                        : 'Santé',
    sauna                                                        : 'Sauna',
    scierie                                                      : 'Scierie',
    sellerie_bourrellerie                                        : 'Sellerie-bourrellerie',
    serigraphe                                                   : 'Sérigraphe',
    serrurier                                                    : 'Serrurier',
    service_ambulancier                                          : 'Service ambulancier',
    service_d_adoption_d_animaux                                 : 'Service d\'adoption d\'animaux',
    service_d_aide_a_domicile_pour_seniors                       : 'Service d\'aide à domicile pour séniors',
    service_d_aide_medicale_d_urgence                            : 'Service d\'aide médicale d\'urgence',
    service_d_amenagement_et_d_entretien_de_jardins              : 'Service d\'aménagement et d\'entretien de jardins',
    service_d_animation_et_d_imagerie_3D                         : 'Service d\'animation et d\'imagerie 3D',
    service_d_elagage                                            : 'Service d\'élagage',
    service_d_encadrement                                        : 'Service d\'encadrement',
    service_d_epilation_a_la_cire                                : 'Service d\'épilation à la cire',
    service_d_hebergement_de_site_internet                       : 'Service d\'hébergement de site internet',
    service_d_imagerie_par_resonance_magnetique                  : 'Service d\'Imagerie par Résonance Magnétique',
    service_d_infirmiers_a_domicile                              : 'Service d\'infirmiers(ères) à domicile',
    service_d_installation_d_equipement_de_telecommunications    : 'Service d\'installation d\'équipement de télécommunications',
    service_d_installation_de_caves_a_vin                        : 'Service d\'installation de caves à vin',
    service_d_installation_de_parquet_en_bois                    : 'Service d\'installation de parquet en bois',
    service_de_conseil_en_e_commerce                             : 'Service de conseil en e-commerce',
    service_de_conseil_en_e_marketing                            : 'Service de conseil en e-marketing',
    service_de_controle_technique_des_batiments                  : 'Service de contrôle technique des bâtiments',
    service_de_creation_de_site_internet                         : 'Service de création de site internet',
    service_de_decoration_florale                                : 'Service de décoration florale',
    service_de_demenagement_de_pianos                            : 'Service de déménagement de pianos',
    service_de_depannage_automobile                              : 'Service de dépannage automobile',
    service_de_depannage_informatique                            : 'Service de dépannage informatique',
    service_de_depannage_pour_chaudieres                         : 'Service de dépannage pour chaudières',
    service_de_detatouage                                        : 'Service de détatouage',
    service_de_location                                          : 'Service de location',
    service_de_maintenance_informatique                          : 'Service de maintenance informatique',
    service_de_montage_video                                     : 'Service de montage vidéo',
    service_de_nettoyage_automobile                              : 'Service de nettoyage automobile',
    service_de_nettoyage_de_piscines                             : 'Service de nettoyage de piscines',
    service_de_photocopie_et_reprographie                        : 'Service de photocopie et reprographie',
    service_de_photographies_aeriennes                           : 'Service de photographies aériennes',
    service_de_pose_de_moquettes                                 : 'Service de pose de moquettes',
    service_de_previsions_meteorologiques                        : 'Service de prévisions météorologiques',
    service_de_ramassage_des_encombrants                         : 'Service de ramassage des encombrants',
    service_de_recuperation_de_donnees_informatiques             : 'Service de récupération de données informatiques',
    service_de_reparation_d_appareils_electromenagers            : 'Service de réparation d\'appareils électroménagers',
    service_de_reparation_de_bateaux                             : 'Service de réparation de bateaux',
    service_de_reparation_de_materiel_audio_et_video             : 'Service de réparation de matériel audio et vidéo',
    service_de_reparation_de_materiel_electronique               : 'Service de réparation de matériel électronique',
    service_de_reparation_de_materiel_hi_fi                      : 'Service de réparation de matériel hi-fi',
    service_de_reparation_de_montres                             : 'Service de réparation de montres',
    service_de_reparation_de_scooter                             : 'Service de réparation de scooter',
    service_de_reparation_de_velo                                : 'Service de réparation de vélo',
    service_de_reparation_et_pose_de_pare_brise                  : 'Service de réparation et pose de pare-brise',
    service_de_restauration_d_art                                : 'Service de restauration d\'art',
    service_de_restauration_de_meubles_anciens                   : 'Service de restauration de meubles anciens',
    service_de_retouches_de_vetements                            : 'Service de retouches de vêtements',
    service_de_serrurerie_d_urgence                              : 'Service de serrurerie d\'urgence',
    service_de_soins_esthetiques_a_domicile                      : 'Service de soins esthétiques à domicile',
    service_de_soutien_scolaire                                  : 'Service de soutien scolaire',
    service_de_taxi                                              : 'Service de taxi',
    service_de_telecommunication_par_satellite                   : 'Service de télécommunication par satellite',
    service_de_toilettage                                        : 'Service de toilettage',
    service_de_traduction                                        : 'Service de traduction',
    service_de_vols_en_deltaplane_montgolfiere_parapente         : 'Service de vols en deltaplane, montgolfière, parapente',
    societe_d_animation_artistique                               : 'Société d\'animation artistique',
    societe_d_entretien_de_piscine                               : 'Société d\'entretien de piscine',
    societe_d_etudes_de_marche                                   : 'Société d\'études de marché',
    societe_d_evenementiel                                       : 'Société d\'événementiel',
    societe_d_import_export                                      : 'Société d\'import-export',
    societe_d_installation_de_climatisation                      : 'Société d\'installation de climatisation',
    societe_de_circuits_touristiques                             : 'Société de circuits touristiques',
    societe_de_conception_de_stand_d_exposition                  : 'Société de conception de stand d\'exposition',
    societe_de_conseil_en_commerce_international                 : 'Société de conseil en commerce international',
    societe_de_conseil_en_economie_d_energie                     : 'Société de conseil en économie d\'énergie',
    societe_de_conseil_en_management                             : 'Société de conseil en management',
    societe_de_conseil_en_placements_financiers                  : 'Société de conseil en placements financiers',
    societe_de_construction_de_chalets                           : 'Société de construction de chalets',
    societe_de_construction_de_piscine                           : 'Société de construction de piscine',
    societe_de_distribution_des_eaux                             : 'Société de distribution des eaux',
    societe_de_fabrication_en_mecanique_et_outillage_de_precision: 'Société de fabrication en mécanique et outillage de précision',
    societe_de_fabrication_et_d_installation_d_escaliers         : 'Société de fabrication et d\'installation d\'escaliers',
    societe_de_gestion_immobiliere                               : 'Société de gestion immobilière',
    societe_de_marquage_publicitaire                             : 'Société de marquage publicitaire',
    societe_de_messagerie_express                                : 'Société de messagerie express',
    societe_de_production_et_de_distribution_d_electricite       : 'Société de production et de distribution d\'électricité',
    societe_de_production_et_de_distribution_de_gaz_naturel      : 'Société de production et de distribution de gaz naturel',
    societe_de_ramonage                                          : 'Société de ramonage',
    societe_de_renovation_immobiliere                            : 'Société de rénovation immobilière',
    societe_de_reparation_de_climatisation                       : 'Société de réparation de climatisation',
    societe_de_restauration_immobiliere                          : 'Société de restauration immobilière',
    societe_de_services_en_ingenierie_informatique               : 'Société de services en ingénierie informatique',
    societe_de_services_logistiques                              : 'Société de services logistiques',
    societe_de_vente_aux_encheres                                : 'Société de vente aux enchères',
    societe_des_auteurs_compositeurs_et_editeurs_de_musique      : 'Société des auteurs, compositeurs et éditeurs de musique',
    societe_editrice_de_logiciels_et_progiciels                  : 'Société éditrice de logiciels et progiciels',
    societe_informatique                                         : 'Société informatique',
    sophrologue                                                  : 'Sophrologue',
    soutien_scolaire                                             : 'Soutien scolaire',
    spa                                                          : 'Spa',
    stand_de_tir                                                 : 'Stand de tir',
    station_de_lavage_automobile                                 : 'Station de lavage automobile',
    station_de_radio                                             : 'Station de radio',
    station_de_ski                                               : 'Station de ski',
    station_service                                              : 'Station service',
    station_thermale                                             : 'Station thermale',
    stomatologue                                                 : 'Stomatologue',
    studio_cinematographique                                     : 'Studio cinématographique',
    studio_d_enregistrement                                      : 'Studio d\'enregistrement',
    studio_de_photographie                                       : 'Studio de photographie',
    studio_de_piercing                                           : 'Studio de piercing',
    studio_de_tatouage                                           : 'Studio de tatouage',
    styliste                                                     : 'Styliste',
    superette                                                    : 'Supérette',
    supermarche_et_hypermarche                                   : 'Supermarché et hypermarché',
    tapissier_garnisseur                                         : 'Tapissier garnisseur',
    terrain_de_airsoft                                           : 'Terrain de airsoft',
    terrain_de_bicross                                           : 'Terrain de bicross',
    terrain_de_football                                          : 'Terrain de football',
    terrain_de_golf                                              : 'Terrain de golf',
    terrain_de_jorkyball                                         : 'Terrain de jorkyball',
    terrain_de_laser_game                                        : 'Terrain de laser game',
    terrain_de_moto_cross                                        : 'Terrain de moto-cross',
    terrain_de_paintball                                         : 'Terrain de paintball',
    terrain_de_rugby                                             : 'Terrain de rugby',
    terrain_de_sport                                             : 'Terrain de sport',
    terrain_de_squash                                            : 'Terrain de squash',
    theatre                                                      : 'Théâtre',
    thermes                                                      : 'Thermes',
    traiteur                                                     : 'Traiteur',
    transport_en_commun                                          : 'Transport en commun',
    universite                                                   : 'Université',
    urologue                                                     : 'Urologue',
    vente_de_composants_electroniques                            : 'Vente de composants électroniques',
    vente_de_produits_high_tech                                  : 'Vente de produits high tech',
    veterinaire                                                  : 'Vétérinaire',
    video_club                                                   : 'Vidéo-club',
    vitrier                                                      : 'Vitrier',
    zone_industrielle                                            : 'Zone industrielle',
    zoo                                                          : 'Zoo'
}
