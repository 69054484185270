import {
    FIND_INSTAGRAM_ACCOUNT, FIND_INSTAGRAM_ACCOUNT_FAILED, FIND_INSTAGRAM_ACCOUNT_SUCCEEDED, FindInstagramAccountAction,
    FindInstagramAccountFailedAction, FindInstagramAccountSucceededAction
} from "./actionTypes";
import { SocialPageInfo } from "../../domain/entity/socialPageInfo";

export const findInstagramAccount = (): FindInstagramAccountAction => ({
    type   : FIND_INSTAGRAM_ACCOUNT
})

export const findInstagramAccountFailed = (error: string): FindInstagramAccountFailedAction => ({
    type   : FIND_INSTAGRAM_ACCOUNT_FAILED,
    payload: error
})

export const findInstagramAccountSucceeded = (pagesInfo: SocialPageInfo[]): FindInstagramAccountSucceededAction => ({
    type   : FIND_INSTAGRAM_ACCOUNT_SUCCEEDED,
    payload: pagesInfo
})
