import {
    UPDATE_INSTANT_STATUS,
    UPDATE_INSTANT_STATUS_FAILED,
    UPDATE_INSTANT_STATUS_SUCCEEDED
} from './updateInstantStatus.actions';
import { UpdateInstantStatusActionTypes } from "./updateInstantStatus.types";
import { UpdateInstantStatusState } from "../../configuration/state";

const initialState: UpdateInstantStatusState = {
    isLoading: false,
    success  : undefined,
    error    : undefined
}

export const updateInstantStatusReducer = (state = initialState, action: UpdateInstantStatusActionTypes): UpdateInstantStatusState => {
    switch (action.type) {
        case UPDATE_INSTANT_STATUS:
            return {
                isLoading: true,
                success  : undefined,
                error    : undefined
            }
        case UPDATE_INSTANT_STATUS_SUCCEEDED:
            return {
                isLoading: false,
                success  : true,
                error    : undefined
            }
        case UPDATE_INSTANT_STATUS_FAILED:
            return {
                isLoading: false,
                success  : false,
                error    : action.payload
            }
        default:
            return state
    }
}
