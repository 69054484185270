import {
    RETRIEVE_SUPER_ADMIN_STATS,
    RETRIEVE_SUPER_ADMIN_STATS_FAILED, RETRIEVE_SUPER_ADMIN_STATS_SUCCEEDED,
    RetrieveSuperAdminStatsAction,
    RetrieveSuperAdminStatsFailedAction, RetrieveSuperAdminStatsSucceededAction
} from "./retrieveStats.types";
import { StatsCompany } from "../../domain/entity/statsCompany";
import { StatsProfile } from "../../domain/entity/statsProfile";

export const retrieveSuperAdminStats = (cityCode: string): RetrieveSuperAdminStatsAction => ({
    type   : RETRIEVE_SUPER_ADMIN_STATS,
    payload: cityCode
})

export const retrieveSuperAdminStatsSucceeded = (profiles: StatsProfile[], companies: StatsCompany[]): RetrieveSuperAdminStatsSucceededAction => ({
    type   : RETRIEVE_SUPER_ADMIN_STATS_SUCCEEDED,
    payload: { profiles, companies }
})

export const retrieveSuperAdminStatsFailed = (error: string): RetrieveSuperAdminStatsFailedAction => ({
    type   : RETRIEVE_SUPER_ADMIN_STATS_FAILED,
    payload: error
})
