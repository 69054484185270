export interface ThemeConfigurationDetailsType {
    title: string;
    website: string;
    primaryColor: string;
    secondaryColor: string;
    primaryFont: string;
    secondaryFont: string;
}

export interface ThemeConfigurationBlockType {
    isActive: boolean;
    title?: string;
    subTitle?: string;
    titleColor?: string;
    textColor?: string;
    itemColor?: string;
    backgroundColor?: string;
    images?: string[];
    placeId?: string;
}

export interface ThemeWelcomeBlockType {
    subTitle: string;
    videoUrl: string;
    buttonUrl: string;
    buttonLabel: string;
    openInNewWindow: boolean;
    backgroundColor: string;
}

export interface ThemeSocialMediaType {
    facebook: string;
    instagram: string;
    tiktok: string;
    linkedin: string;
}

export interface ThemeFoodMenuType {
    isActive: boolean;
    title: string;
    subTitle: string;
    headerTitle: string;
    backgroundColor: string;
    displayAsIcon: boolean;
    tabs: ThemeFoodMenuDocumentType[];
}

export interface ThemeFoodMenuDocumentType {
    title: string;
    url: string;
    icon: string;
}

export interface ThemeEcommerceType {
    isActive: boolean;
    title: string;
    subTitle: string;
    headerTitle: string;
    backgroundImage: string;
    type: string;
    paymentOptions: string[];
    documentUrl: string;
    navigateTo: string;
}

export const ThemeFonts = [
    {
        label: 'Arial',
        value: 'arial'
    },
    {
        label: 'Brush Script MT',
        value: 'brush-bcript-mt'
    },
    {
        label: 'Comic Sans',
        value: 'comic-sans'
    },
    {
        label: 'Courier New',
        value: 'courier-new'
    },
    {
        label: 'Garamond',
        value: 'garamond'
    },
    {
        label: 'Georgia',
        value: 'georgia'
    },
    {
        label: 'Helvetica',
        value: 'helvetica'
    },
    {
        label: 'Impact',
        value: 'impact'
    },
    {
        label: 'Lucida Console',
        value: 'lucida-console'
    },
    {
        label: 'Open Sans',
        value: 'open-sans'
    },
    {
        label: 'Palatino',
        value: 'palatino'
    },
    {
        label: 'Raleway',
        value: 'raleway'
    },
    {
        label: 'Tahoma',
        value: 'tahoma'
    },
    {
        label: 'Times New Roman',
        value: 'times-new-roman'
    },
    {
        label: 'Trebuchet MS',
        value: 'trebuchet-ms'
    },
    {
        label: 'Verdana',
        value: 'verdana'
    }
]

export const defaultFont = {
    label: 'Raleway',
    value: 'raleway'
}

export const ThemeEcommercePaymentOptions = [
    {
        label: 'Mondial Relay',
        value: 'mondialRelay'
    },
    {
        label: 'Colissimo',
        value: 'colissimo'
    },
    {
        label: 'Chronopost Relais',
        value: 'chronoPost'
    },
    {
        label: 'Retrait en magasin (Click&Collect)',
        value: 'clickAndCollect'
    }
]


