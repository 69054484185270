import { SocialPageInfo } from "../../domain/entity/socialPageInfo";

export const FIND_INSTAGRAM_ACCOUNT = 'FIND_INSTAGRAM_ACCOUNT'
export const FIND_INSTAGRAM_ACCOUNT_FAILED = 'FIND_INSTAGRAM_ACCOUNT_FAILED'
export const FIND_INSTAGRAM_ACCOUNT_SUCCEEDED = 'FIND_INSTAGRAM_ACCOUNT_SUCCEEDED'

export interface FindInstagramAccountAction {
    type: typeof FIND_INSTAGRAM_ACCOUNT
}

export interface FindInstagramAccountFailedAction {
    type: typeof FIND_INSTAGRAM_ACCOUNT_FAILED
    payload: string
}

export interface FindInstagramAccountSucceededAction {
    type: typeof FIND_INSTAGRAM_ACCOUNT_SUCCEEDED
    payload: SocialPageInfo[]
}

export type FindInstagramAccountActionTypes = FindInstagramAccountAction | FindInstagramAccountFailedAction | FindInstagramAccountSucceededAction
