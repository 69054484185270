import React, { PureComponent, ReactNode } from "react";
import { Company } from "../../../domain/entity/company";
import './companyEdition.css';
import { i18n } from "../../../../configuration/i18n";
import {
    FormProfileSection,
    FormProfileStateType
} from "../../../../common/adapters/primaries/form/sections/formProfile.section";
import {
    FormLocationSection,
    FormLocationStateType
} from "../../../../common/adapters/primaries/form/sections/formLocation.section";
import { OpeningHoursType } from "../../../../common/domain/types/openingHours.types";
import {
    FormContactSection,
    FormContactStateType
} from "../../../../common/adapters/primaries/form/sections/formContact.section";
import { FormOpeningHoursSection } from "../../../../common/adapters/primaries/form/sections/formOpeningHours.section";
import { CompanyBuilder } from "../../../domain/builder/company.builder";
import { FormValidation } from "../../../../common/adapters/primaries/form/validation";
import { FormPremiumSection } from "./components/formPremium.section";
import { ClickCollectPicture } from "../../../domain/types/clickCollectPicture";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { FormPremiumStateType, FormLegalInfoStateType } from "./components/types";
import { CompanyLegalInfo } from "../../../domain/entity/companyLegalInfo";

interface Props {
    company: Company | null;
    loading: boolean;
    success: boolean | null;
    error: string | undefined;
    editCompany: (company: Company) => void;
}

interface State {
    profile: FormProfileStateType;
    location: FormLocationStateType;
    openingHours: OpeningHoursType[];
    contact: FormContactStateType;
    clickCollect: FormPremiumStateType;
    legalInfo: FormLegalInfoStateType;
    isSubmitted: boolean
    errorMsg: string | undefined
}

export class CompanyEditionContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            profile     : {
                name           : this.props.company ? this.props.company.name : '',
                category       : this.props.company ? this.props.company.category : '',
                coverImage     : this.props.company ? this.props.company.coverImage : undefined,
                profileImage   : this.props.company ? this.props.company.profileImage : undefined,
                coverImageUrl  : this.props.company ? this.props.company.coverImageUrl : undefined,
                profileImageUrl: this.props.company ? this.props.company.profileImageUrl : undefined,
                companyType    : this.props.company ? this.props.company.type : ''
            },
            location    : {
                address  : this.props.company ? this.props.company.address.address : '',
                city     : this.props.company ? this.props.company.address.city : '',
                zipCode  : this.props.company ? this.props.company.address.zipCode : '',
                latitude : this.props.company ? this.props.company.address.latitude : '',
                longitude: this.props.company ? this.props.company.address.longitude : ''
            },
            openingHours: this.mapOpeningHours(),
            contact     : {
                website    : this.props.company ? this.props.company.contacts.website : '',
                noWebsite  : this.props.company ? this.props.company.contacts.website === '' : false,
                email      : this.props.company ? this.props.company.contacts.email : '',
                phoneNumber: this.props.company ? this.props.company.contacts.phoneNumber : '',
                description: this.props.company ? this.props.company.contacts.description : ''
            },
            clickCollect: {
                activeClickCollect     : this.props.company ? this.props.company.membership.hasClickAndCollect && this.props.company.clickCollect.active : false,
                descriptionClickCollect: this.props.company ? this.props.company.clickCollect.description : '',
                picture_0              : this.getPictureClickCollect(0),
                picture_1              : this.getPictureClickCollect(1),
                picture_2              : this.getPictureClickCollect(2),
                picture_3              : this.getPictureClickCollect(3)
            },
            legalInfo   : {
                isProWebsiteEnabled: this.props.company ? this.props.company.membership.hasSubdomain && this.props.company.premiumSubdomain?.length > 0 : false,
                subdomain          : this.props.company ? this.props.company.premiumSubdomain : '',
                companyName        : this.props.company?.legalInfo ? this.props.company.legalInfo.companyName : '',
                legalStatus        : this.props.company?.legalInfo ? this.props.company.legalInfo.legalStatus : '',
                capitalAmount      : this.props.company?.legalInfo ? this.props.company.legalInfo.capitalAmount : '',
                address            : this.props.company?.legalInfo ? this.props.company.legalInfo.address : '',
                phoneNumber        : this.props.company?.legalInfo ? this.props.company.legalInfo.phoneNumber : '',
                email              : this.props.company?.legalInfo ? this.props.company.legalInfo.email : '',
                siret              : this.props.company?.legalInfo ? this.props.company.legalInfo.siret : '',
                tva                : this.props.company?.legalInfo ? this.props.company.legalInfo.tva : '',
                registeredName     : this.props.company?.legalInfo ? this.props.company.legalInfo.registeredName : '',
                others             : this.props.company?.legalInfo ? this.props.company.legalInfo.others : '',
            },
            isSubmitted : false,
            errorMsg    : undefined
        }
    }

    private getPictureClickCollect(index: number): ClickCollectPicture {
        const picture: ClickCollectPicture | undefined = this.props.company?.clickCollect.pictures.find(item => item.id === index)
        return picture !== undefined ? picture : {id: index, picture: undefined, pictureUrl: ''}
    }

    private mapOpeningHours() {
        const openingHours: OpeningHoursType[] = []
        if (this.props.company) {
            this.props.company.openingHours.map(openingHour => {
                openingHours.push({
                    id       : openingHour.id,
                    daytype  : openingHour.openingDays,
                    startTime: openingHour.startTime,
                    endTime  : openingHour.endTime,
                    isPaused : openingHour.isPaused
                })
            })
        }
        return openingHours
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.company !== this.props.company && this.props.company) {
            this.setState({
                profile     : {
                    name           : this.props.company.name,
                    category       : this.props.company.category,
                    coverImage     : this.props.company.coverImage,
                    profileImage   : this.props.company.profileImage,
                    coverImageUrl  : this.props.company.coverImageUrl,
                    profileImageUrl: this.props.company.profileImageUrl,
                    companyType    : this.props.company.type
                },
                location    : {
                    address  : this.props.company.address.address,
                    city     : this.props.company.address.city,
                    zipCode  : this.props.company.address.zipCode,
                    latitude : this.props.company.address.latitude,
                    longitude: this.props.company.address.longitude
                },
                openingHours: this.mapOpeningHours(),
                contact     : {
                    website    : this.props.company.contacts.website,
                    noWebsite  : this.props.company.contacts.website === '',
                    email      : this.props.company.contacts.email,
                    phoneNumber: this.props.company.contacts.phoneNumber,
                    description: this.props.company.contacts.description
                },
                clickCollect: {
                    activeClickCollect     : this.props.company.membership.hasClickAndCollect && this.props.company.clickCollect.active,
                    descriptionClickCollect: this.props.company.clickCollect.description,
                    picture_0              : this.getPictureClickCollect(0),
                    picture_1              : this.getPictureClickCollect(1),
                    picture_2              : this.getPictureClickCollect(2),
                    picture_3              : this.getPictureClickCollect(3)
                },
                legalInfo   : {
                    isProWebsiteEnabled: this.props.company.membership.hasSubdomain && this.props.company.premiumSubdomain?.length > 0,
                    subdomain          : this.props.company.premiumSubdomain,
                    companyName        : this.props.company.legalInfo ? this.props.company.legalInfo.companyName : '',
                    legalStatus        : this.props.company.legalInfo ? this.props.company.legalInfo.legalStatus : '',
                    capitalAmount      : this.props.company.legalInfo ? this.props.company.legalInfo.capitalAmount : '',
                    address            : this.props.company.legalInfo ? this.props.company.legalInfo.address : '',
                    phoneNumber        : this.props.company.legalInfo ? this.props.company.legalInfo.phoneNumber : '',
                    email              : this.props.company.legalInfo ? this.props.company.legalInfo.email : '',
                    siret              : this.props.company.legalInfo ? this.props.company.legalInfo.siret : '',
                    tva                : this.props.company.legalInfo ? this.props.company.legalInfo.tva : '',
                    registeredName     : this.props.company.legalInfo ? this.props.company.legalInfo.registeredName : '',
                    others             : this.props.company.legalInfo ? this.props.company.legalInfo.others : ''
                },
            })
        }

        if (this.props.error === '415' && this.props.error !== prevProps.error) {
            this.setState({errorMsg: i18n.registration.over_sized_image_error})
        } else if (this.props.error === '404' && this.props.error !== prevProps.error) {
            this.setState({errorMsg: i18n.registration.data_format_error})
        } else if (this.props.error && this.props.error !== prevProps.error) {
            this.setState({errorMsg: i18n.registration.server_error})
        }

        if (prevProps.success != this.props.success && this.props.success) {
            window.location.href = '/company'
        }
    }

    componentDidMount(): void {
        document.title = i18n.company.company_edition_page_title;
    }

    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        if (this.props.company) {
            return (
                <div>
                    <DefaultPageHeader title={i18n.company.edit_profile}/>

                    <div className="profile-edition">
                        {loader}

                        <div className="wizard-form-field mt-3 mb-60">
                            <FormProfileSection profile={this.state.profile}
                                                alreadySavedBusiness={null}
                                                isSubmitted={this.state.isSubmitted}
                                                raiseUpdate={(profile: FormProfileStateType) => this.setState({
                                                    profile,
                                                    isSubmitted: false,
                                                    errorMsg   : ''
                                                })}/>
                            <FormLocationSection location={this.state.location}
                                                 isSubmitted={this.state.isSubmitted}
                                                 raiseUpdate={(location: FormLocationStateType) => this.setState({
                                                     location,
                                                     isSubmitted: false,
                                                     errorMsg   : ''
                                                 })}/>
                            <FormOpeningHoursSection openingHours={this.state.openingHours}
                                                     isSubmitted={this.state.isSubmitted}
                                                     raiseUpdate={(openingHours: OpeningHoursType[]) => this.setState({
                                                         openingHours,
                                                         isSubmitted: false,
                                                         errorMsg   : ''
                                                     })}/>
                            <FormContactSection contact={this.state.contact}
                                                type={this.state.profile.companyType}
                                                isSubmitted={this.state.isSubmitted}
                                                raiseUpdate={(contact: FormContactStateType) => this.setState({
                                                    contact,
                                                    isSubmitted: false,
                                                    errorMsg   : ''
                                                })}/>
                            {this.props.company.membership.hasClickAndCollect || this.props.company.membership.hasSubdomain ?
                                <FormPremiumSection clickCollect={this.state.clickCollect}
                                                    legalInfo={this.state.legalInfo}
                                                    company={this.props.company}
                                                    raiseClickCollectUpdates={(clickCollect: FormPremiumStateType) => this.setState({
                                                        clickCollect,
                                                        isSubmitted: false,
                                                        errorMsg   : ''
                                                    })}
                                                    raiseLegalInfoUpdates={(key: keyof FormLegalInfoStateType, value: string) => this.setState({
                                                        legalInfo  : {
                                                            ...this.state.legalInfo,
                                                            [key]: value
                                                        },
                                                        isSubmitted: false,
                                                        errorMsg   : ''
                                                    })}
                                                    manageSiteInternet={() => this.setState({
                                                        legalInfo  : {
                                                            ...this.state.legalInfo,
                                                            isProWebsiteEnabled: !this.state.legalInfo.isProWebsiteEnabled,
                                                            subdomain          : ''
                                                        },
                                                        isSubmitted: false,
                                                        errorMsg   : ''
                                                    })}
                                                    companyType={this.props.company.type}
                                                    isSubmitted={this.state.isSubmitted}/> : null
                            }

                            <p className={'error'}
                               dangerouslySetInnerHTML={{__html: this.state.errorMsg ?? ''}}/>

                            <button onClick={() => this.updateBusiness()} className={'company-submit-btn'}>
                                {i18n.company.save}
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else
            return <div/>
    }

    validateForm(): string | undefined {
        const picturesClickCollect = [
            this.state.clickCollect.picture_0,
            this.state.clickCollect.picture_1,
            this.state.clickCollect.picture_2,
            this.state.clickCollect.picture_3
        ]

        if (this.state.profile.name.length === 0 || !this.state.profile.category || !this.state.profile.profileImage ||
            this.state.location.address.length === 0 || this.state.location.city.length === 0 || this.state.location.zipCode.length === 0 ||
            this.state.openingHours.length === 0 ||
            this.state.contact.description.length === 0) {
            return i18n.registration.required_data_error
        } else if (this.state.profile.name.length < 3) {
            return i18n.registration.business_name_format_error
        }
        //Validate Location
        else if (this.state.location.address.length < 6) {
            return i18n.registration.address_format_error
        } else if (this.state.location.city.length < 4) {
            return i18n.registration.city_format_error
        } else if (!FormValidation.validate('zipCode', this.state.location.zipCode)) {
            return i18n.registration.zipCode_format_error
        }
        //Validate Contact
        else if (this.state.contact.description.length < 14) {
            return i18n.registration.description_format_error
        } else if (!FormValidation.validateWebsite(this.state.contact.website) && !this.state.contact.noWebsite) {
            return i18n.registration.site_format_error
        } else if (!FormValidation.validateEmail(this.state.contact.email)) {
            return i18n.registration.email_format_error
        } else if (!FormValidation.validatePhoneNumberFix(this.state.contact.phoneNumber)) {
            return i18n.registration.phone_number_error
        }
        //Validate Click & Collect
        else if (!FormValidation.validateDescriptionClickCollect(this.state.clickCollect.descriptionClickCollect, this.state.clickCollect.activeClickCollect))
            return i18n.registration.click_collect_description_error
        else if (!FormValidation.validateClickCollect(this.state.clickCollect.activeClickCollect, picturesClickCollect))
            return i18n.company.error_click_collect_pictures
        //Validate Legal Infos
        else if (this.state.legalInfo.isProWebsiteEnabled) {
            if (!FormValidation.validatePremiumSubdomain(this.state.legalInfo.subdomain)) {
                return i18n.company.error_subdomain_name
            } else if (this.state.legalInfo.companyName.length < 2 || this.state.legalInfo.legalStatus.length < 2 ||
                this.state.legalInfo.address.length < 2 || this.state.legalInfo.registeredName.length < 2 ||
                this.state.legalInfo.tva.length < 2 || this.state.legalInfo.siret.length !== 14 ||
                !FormValidation.validateNumber(this.state.legalInfo.siret) ||
                !FormValidation.validateNumber(this.state.legalInfo.capitalAmount) ||
                !FormValidation.validatePhoneNumberFix(this.state.legalInfo.phoneNumber) ||
                !FormValidation.validateEmail(this.state.legalInfo.email)
            ) {
                return i18n.company.error_legal_info
            }
        }
    }

    updateBusiness(): void {
        this.setState({
            errorMsg   : this.validateForm(),
            isSubmitted: true
        }, () => {
            if (!this.state.errorMsg && this.props.company) {
                const picturesClickCollect = [
                    this.state.clickCollect.picture_0,
                    this.state.clickCollect.picture_1,
                    this.state.clickCollect.picture_2,
                    this.state.clickCollect.picture_3
                ]

                const legalInfo = new CompanyLegalInfo(
                    this.state.legalInfo.companyName,
                    this.state.legalInfo.registeredName,
                    this.state.legalInfo.legalStatus,
                    this.state.legalInfo.capitalAmount,
                    this.state.legalInfo.address,
                    this.state.legalInfo.phoneNumber,
                    this.state.legalInfo.email,
                    this.state.legalInfo.siret,
                    this.state.legalInfo.tva,
                    this.state.legalInfo.others
                )

                const business: CompanyBuilder = new CompanyBuilder()
                    .withId(this.props.company.id)
                    .withMembership(this.props.company.membership)
                    .withSiren(this.props.company.siren)
                    .withName(this.state.profile.name)
                    .withType(this.state.profile.companyType)
                    .withCategory(this.state.profile.category)
                    .withAddress(this.state.location.address)
                    .withZipCode(this.state.location.zipCode)
                    .withCity(this.state.location.city)
                    .withLongitude(this.state.location.longitude)
                    .withLatitude(this.state.location.latitude)
                    .withOpeningHours(this.state.openingHours)
                    .withDescription(this.state.contact.description)
                    .withWebsite(this.state.contact.website === 'none' ? '' : this.state.contact.website)
                    .withEmail(this.state.contact.email)
                    .withPremiumSubdomain(this.state.legalInfo.subdomain)
                    .withActiveClickCollect(this.state.clickCollect.activeClickCollect)
                    .withDescriptionClickCollect(this.state.clickCollect.descriptionClickCollect)
                    .withPicturesClickCollect(picturesClickCollect)
                    .withPhoneNumber(this.state.contact.phoneNumber)
                    .withLegalInfo(legalInfo)

                if (this.state.profile.profileImage && !this.isURL(this.state.profile.profileImageUrl))
                    business.withProfileImage(this.state.profile.profileImage)
                if (this.state.profile.coverImage && !this.isURL(this.state.profile.coverImageUrl))
                    business.withCoverImage(this.state.profile.coverImage)

                this.props.editCompany(business.build())
            }
        })
    }

    private isURL(url: string | undefined): boolean {
        const regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)$/
        return regex.test(url || '')
    }
}
