import React, { PureComponent, ReactNode } from "react";
import { ManagementInstant } from "../../../../domain/entities/ManagementInstant";
import { i18n } from "../../../../../configuration/i18n";
import { ManagementAgendaInstantFormType, ManagementInstantFormType } from "../../form/type/managementInstantFormType";
import { InstantLocationSection } from "../../form/sections/instantLocation.section";
import { InstantNotificationSection } from "../../form/sections/instantNotification.section";
import { match } from "react-router";
import { ActivationTimeType, Schedule } from "../../../../domain/entities/types/activationTimeType";
import { ManagementInstantActivationTime } from "../../../../domain/entities/managementInstantActivationTime";
import { ApplicationContext } from "../../../../../configuration/application.context";
import { ActiveDaysNames } from "../../../../domain/entities/types/activationDayType";
import { ManagementInstantCoordinates } from "../../../../domain/entities/ManagementInstantCoordinates";
import { ManagementInstantBuilder } from "../../../../domain/entities/managementInstantBuilder";
import { InstantFormValidator } from "../../form/instantForm.validator";
import { Company } from "../../../../../company/domain/entity/company";
import '../../form/instantForm.css';
import { NotificationDateModal } from "../../form/components/notificationDate.modal";
import {
    BasicInformationAgendaInstantSection
} from "../../creation/agendaEvent/component/basicInformationsAgendaInstant.section";
import { AgendaInstantPlaningSection } from "../../creation/agendaEvent/component/agendaInstantPlaning.section";
import { ManagementAgendaInstantCategoryType } from "../../../../domain/entities/types/managementInstantCategoryType";
import { DefaultPageHeader } from "../../../../../common/adapters/primaries/defaultPage.header";

interface Props {
    instant: ManagementInstant | null;
    loadManagementInstant: (instantId: string) => void;
    editInstant: (instant: ManagementInstant) => void;
    match: match<{ id: string }>
    loading: boolean
    company: Company | null
    successEdition: boolean | null
}

interface State extends ManagementAgendaInstantFormType {
    id: string;
    businessId: string;
    activeSections: number[];
    errorSection: number | undefined;
    errorMsg: string
    visibleNotificationModal: boolean;
}

const moment = ApplicationContext.getInstance().momentJs()

export class EditAgendaInstantContainer extends PureComponent<Props, State> {

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            id                      : '',
            businessId              : '',
            description             : '',
            picture                 : undefined,
            pictureUrl              : undefined,
            title                   : '',
            category                : 'default',
            longDescription         : '',
            address                 : '',
            city                    : '',
            zip                     : '',
            coordinates             : {
                latitude : 0,
                longitude: 0
            },
            activationTime          : undefined,
            notificationSent        : undefined,
            notificationDate        : undefined,
            activeSections          : [0],
            errorSection            : undefined,
            errorMsg                : '',
            visibleNotificationModal: false
        }
    }

    componentDidMount(): void {
        document.title = i18n.management_instant.instant_edition_page_title;
        this.props.loadManagementInstant(this.props.match.params.id)
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.instant && this.props.instant !== prevProps.instant)
            this.setState({...this.mapToManagementInstantType(this.props.instant)})

        if (this.props.successEdition && prevProps.loading !== this.props.loading)
            window.location.href = '/instants'
    }


    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )
        const subTitle = this.props.instant ? this.props.instant.instantInformation.title : ''

        return (
            <div>
                <DefaultPageHeader title={i18n.management_instant.edit_instant + ': ' + subTitle}/>

                <div className="profile-edition">
                    {loader}

                    <div className="wizard-form-field mb-85 instant-edition">

                        <BasicInformationAgendaInstantSection instant={this.state}
                                                              raiseUpdates={(key, value): void => this.raiseUpdates(key, value)}
                                                              hasError={this.state.errorSection === 0}/>

                        <InstantLocationSection instant={this.state}
                                                raiseUpdates={(key, value): void => this.raiseUpdates(key, value)}
                                                hasError={this.state.errorSection === 1}/>

                        <AgendaInstantPlaningSection instant={this.state}
                                                     companyOpeningHours={this.props.company?.openingHours}
                                                     raiseUpdate={(key, value): void => this.raiseUpdates(key, value)}
                                                     hasError={this.state.errorSection === 2}/>

                        <InstantNotificationSection notificationDate={this.state.notificationDate}
                                                    isNotificationSent={this.state.notificationSent}
                                                    allowedNotification={this.props.company ? this.props.company.membership.allowedNotifications === -1 : false}
                                                    raiseUpdate={(date: string | undefined): void => this.raiseUpdates('notificationDate', date)}
                                                    hasError={this.state.errorSection === 3}/>

                        <p className={'error mt-4'}>{this.state.errorMsg}</p>

                        <button className={'instant-submit-btn'} onClick={() => this.openNotificationModal()}>
                            {i18n.management_instant.save_instant}
                        </button>
                        <NotificationDateModal visible={this.state.visibleNotificationModal}
                                               onClose={() => this.setState({visibleNotificationModal: false})}
                                               publishInstant={() => this.editInstant()}
                                               setNotificationDate={() => this.setNotificationDateAndSave()}/>
                    </div>
                </div>
            </div>
        )
    }

    setNotificationDateAndSave(): void {
        this.setState({
            visibleNotificationModal: false,
            notificationDate        : moment().add(5, 'minutes').format('DD/MM/YYYY HH:mm')
        }, () => this.editInstant())
    }

    openNotificationModal(): void {
        const submissionResult = InstantFormValidator.validate(this.state)

        if (submissionResult === undefined && !this.props.loading) {
            if (this.state.notificationDate)
                this.editInstant()
            else
                this.setState({visibleNotificationModal: true})

        } else if (submissionResult !== undefined) {
            this.setState({
                errorSection  : submissionResult,
                activeSections: [submissionResult],
                errorMsg      : this.renderError(submissionResult)
            })
        }
    }

    raiseUpdates(key: keyof ManagementInstantFormType, value: string | File | ActivationTimeType | ManagementInstantCoordinates | undefined): void {
        this.setState({
            ...this.state, [key]: value
        })
    }

    editInstant(): void {
        const notificationDate = this.getNotificationDate(this.state.notificationDate)

        if (this.state.category !== 'default' && this.props.company && this.state.activationTime) {
            const instantSubmission = new ManagementInstantBuilder()
                .withId(this.state.id)
                .withBusinessId(this.state.businessId)
                .withCategory(this.state.category)
                .withTitle(this.state.title)
                .withShortDescription(this.state.description)
                .withLongDescription(this.state.longDescription)
                .withAddress(this.state.address)
                .withCity(this.state.city)
                .withZipcode(Number(this.state.zip))
                .withActivationTimeType(this.state.activationTime)

            if (notificationDate)
                instantSubmission.withNotificationDate(notificationDate)

            if (this.state.picture && !this.isURL(this.state.pictureUrl))
                instantSubmission.withPicture(this.state.picture)

            this.props.editInstant(instantSubmission.build())
        }
    }

    mapToManagementInstantType(instant: ManagementInstant): State {
        if (instant) {
            const scheduleKey = instant.instantActivationTimes[0].activationDayType === 'fixed' ? 'fixed' : 'recurrent'
            const schedules: Schedule[] = instant.instantActivationTimes.map((item: ManagementInstantActivationTime) => {
                    return {
                        id       : item.id,
                        dateRange: {
                            key      : item.activationDayType,
                            label    : scheduleKey === 'fixed' ?
                                this.getLabelFromDates(item.startDateTime, item.endDateTime) : ActiveDaysNames[item.activationDayType],
                            startTime: item.startDateTime,
                            endTime  : item.endDateTime
                        },
                        isPaused : !item.isEnabled,
                        prePoned : item.DelayType === 'DURING_AND_BEFORE_TWO_HOUR'
                    }
                }
            )
            return {
                id                      : instant.instantInformation.id,
                businessId              : instant.instantInformation.businessId,
                description             : instant.instantInformation.shortDescription,
                picture                 : instant.instantInformation.picture,
                pictureUrl              : instant.instantInformation.pictureUrl,
                title                   : instant.instantInformation.title,
                category                : this.getCategoryAgendaEvent(instant.instantInformation.category),
                longDescription         : instant.instantInformation.longDescription,
                address                 : instant.address.address,
                city                    : instant.address.city,
                zip                     : instant.address.zipCode.toString(),
                coordinates             : instant.address.coords,
                activationTime          : {key: scheduleKey, schedule: schedules},
                notificationSent        : instant.notificationSent,
                notificationDate        : instant.notificationDate,
                activeSections          : this.state.activeSections,
                errorSection            : this.state.errorSection,
                errorMsg                : this.state.errorMsg,
                visibleNotificationModal: this.state.visibleNotificationModal
            }
        } else
            return this.state
    }

    private getCategoryAgendaEvent(category: string): ManagementAgendaInstantCategoryType | 'default' {
        const agendaEventCategories: ManagementAgendaInstantCategoryType[] = ['cultural', 'sportive', 'discovery', 'heart', 'info', 'musical']
        const foundCategory = agendaEventCategories.find(item => item === category)
        if (foundCategory !== undefined)
            return foundCategory
        return "default"
    }

    private getLabelFromDates(startTime: string, endTime: string): string {
        const formattedStartDate = moment(startTime).format('ll');
        const formattedEndDate = moment(endTime).format('ll');

        return formattedStartDate === formattedEndDate ? `Le ${formattedStartDate}` : `${formattedStartDate} au ${formattedEndDate}`
    }

    private getNotificationDate(notificationDate: string | undefined): string | undefined {
        if (this.props.instant && notificationDate === this.props.instant.notificationDate && this.props.instant.notificationSent)
            return undefined
        else if (notificationDate === 'CLEAR')
            return 'CLEAR'
        else
            return notificationDate ? moment(notificationDate, 'YYYY-MM-DD HH:mm').format() : undefined
    }

    renderError(sectionNumber: number): string {
        switch (sectionNumber) {
            case 0:
                if (!InstantFormValidator.checkTitle(this.state.title))
                    return i18n.management_instant.title_format_error
                else if (!InstantFormValidator.checkDescription(this.state.description) || this.state.longDescription.length < 17)
                    return i18n.management_instant.description_format_error
                else if (!InstantFormValidator.checkLongDescription(this.state.longDescription))
                    return i18n.management_instant.long_description_format_error
                else
                    return i18n.management_instant.section_zero_error
            case 1:
                return i18n.management_instant.section_one_error
            case 2:
                return i18n.management_instant.section_two_error
            case 3:
                return i18n.management_instant.section_three_error
            default:
                return i18n.management_instant.form_data_error
        }
    }

    private isURL(url: string | undefined): boolean {
        const regex = /^(https?):\/\/[^\s$.?#].[^\s]*$/
        return regex.test(url || '')
    }
}
