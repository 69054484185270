import React from "react";
import { ThemeCheckboxInput } from "../checkbox.input";

interface Props {
    value: string;
    onChange: (value: string) => void;
}

export const EcommerceTypeOptions = (props: Props): JSX.Element => {
    return (
        <div className={'wizard-form-input radio-buttons align-items-start'}>
            <label className="pt-2">Catalogue produits</label>

            <div className="food-menu-checkbox">
                <ThemeCheckboxInput label="Affichage e-commerce"
                                    checked={props.value === 'default'}
                                    onChange={(isChecked: boolean) => props.onChange(isChecked ? 'default' : 'food')}/>

                <ThemeCheckboxInput label="Affichage menu de restaurant"
                                    checked={props.value === 'food'}
                                    onChange={(isChecked: boolean) => props.onChange(!isChecked ? 'default' : 'food')}/>
            </div>
        </div>
    )
}
