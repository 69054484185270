import {
    CREATE_INSTANT,
    CREATE_INSTANT_FAILED,
    CREATE_INSTANT_SUCCEEDED,
    CreateInstantAction, CreateInstantFailedAction, CreateInstantSucceededAction
} from "./createInstant.types";
import { ManagementInstant } from "../../domain/entities/ManagementInstant";


export const createInstant = (instant: ManagementInstant): CreateInstantAction => ({
    type   : CREATE_INSTANT,
    payload: instant
})
export const createInstantSucceeded = (): CreateInstantSucceededAction => ({
    type: CREATE_INSTANT_SUCCEEDED
})

export const createInstantFailed = (error: string): CreateInstantFailedAction => ({
    type   : CREATE_INSTANT_FAILED,
    payload: error
})
