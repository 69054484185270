import {
    CANCEL_ORDER,
    CANCEL_ORDER_FAILED,
    CANCEL_ORDER_SUCCEEDED,
    CancelOrderActionTypes
} from './actionTypes';
import { CancelOrderState } from "../../configuration/state";

const initialState: CancelOrderState = {
    loading: false,
    success: false,
    error  : undefined
}

export const cancelOrderReducer = (state = initialState, action: CancelOrderActionTypes): CancelOrderState => {
    switch (action.type) {
        case CANCEL_ORDER:
            return {
                loading: true,
                success: false,
                error  : undefined
            }
        case CANCEL_ORDER_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        case CANCEL_ORDER_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        default:
            return state
    }
}
