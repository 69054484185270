import { from, Observable, Subject, throwError } from 'rxjs';
import { CompanyService } from "../../../domain/gateway/company.service";
import { Company } from "../../../domain/entity/company";
import { catchError, map } from "rxjs/operators";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import {
    SecuredObservableAjaxHttpClient
} from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { CompanyOpeningHoursEditionDto, CompanyDto } from "./dto/company.dto";
import { CompanyMapper } from "./mapper/mapper";
import { CompanyOpeningHours } from "../../../domain/entity/companyOpeningHours";
import { SocialPageInfo } from "../../../domain/entity/socialPageInfo";
import { Follower } from "../../../domain/entity/follower";
import { FollowerDTO } from './dto/follower.dto';
import { CompanyJSONMapper } from "./mapper/jsonMapper";
import { KeyOf, ValueOf } from "../../../../common/domain/types/app.categories";
import { BusinessRegistrationType } from "../../../../registration/domain/type/BusinessRegistrationType";


export class ApiCompanyService implements CompanyService {

    getCompanyId(): Observable<string> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/ownership'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{
            data: Array<{ companyId: string, profileId: string }>
        }>(url)
            .pipe(
                map((response: { data: Array<{ companyId: string, profileId: string }> }) => {
                    return response.data[0].companyId
                }),
                catchError(err => throwError(err))
            )
    }

    getCompanyDetails(companyId: string): Observable<Company> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/settings/' + companyId
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{
            data: CompanyDto
        }>(url)
            .pipe(
                map((response: { data: CompanyDto }) => CompanyMapper.mapCompanyDTO(response.data)),
                catchError(err => throwError(err))
            )
    }

    editCompany(company: Company): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/' + company.id + '/update-settings-and-openingHours'
        const body = new FormData();

        if (company.contacts.phoneNumber)
            body.append('phoneNumber', company.contacts.phoneNumber);
        if (company.contacts.website)
            body.append('website', company.contacts.website);

        body.append('premium_subdomain', company.premiumSubdomain)
        if (company.legalInfo) {
            body.append('legalInformation', CompanyJSONMapper.mapLegalInformation(company))
        }

        if (company.membership.hasClickAndCollect && company.clickCollect.active) {
            const referencesImages: Array<{ rank: string; action: 'DELETE' | 'UPDATE' }> = []
            company.clickCollect.pictures.map((item) => {
                if (item.pictureUrl === '')
                    referencesImages.push({rank: item.id.toString(), action: 'DELETE'})
                else if (item.picture !== undefined) {
                    referencesImages.push({rank: item.id.toString(), action: 'UPDATE'})
                    const fileName = 'fileName_' + item.id + '.jpg'
                    const blob = item.picture.slice(0, item.picture.size, 'image/png');
                    const newPicture = new File([blob], fileName, {type: 'image/png'});
                    body.append('cacImages[]', newPicture)
                }
            })

            if (referencesImages.length > 0)
                body.append('cacReferences', JSON.stringify(referencesImages))
        }

        if (company.clickCollect.description)
            body.append('cacDescription', company.clickCollect.description)

        body.append('cacActive', company.clickCollect.active.toString())

        body.append('companyId', company.id);
        body.append('name', company.name);
        body.append('type', company.type);
        body.append('category', company.category)
        body.append('profileImage', company.profileImage);
        body.append('coverImage', company.coverImage);
        body.append('address', company.address.address);
        body.append('city', company.address.city);
        body.append('zipCode', company.address.zipCode);
        body.append('longitude', company.address.longitude);
        body.append('latitude', company.address.latitude);
        body.append('description', company.contacts.description);
        body.append('email', company.contacts.email);
        body.append('openingHours', JSON.stringify(this.openingHoursCommand(company.openingHours)));

        if (company.themeConfiguration) {
            body.append('domain', company.themeConfiguration.general.website);
            body.append('themeConfiguration', CompanyJSONMapper.mapThemeConfiguration(company))
        }

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    findFacebookPagesInfo(): Observable<SocialPageInfo[]> {
        const findPromise = new Promise<SocialPageInfo[]>((resolve, reject) => {
            window.FB.login((response: { authResponse: { accessToken: string } }) => {
                if (response.authResponse)
                    window.FB.api(
                        `/me/accounts?fields=picture,name&access_token&access_token=${response.authResponse.accessToken}`,
                        ({data}: {
                            data: [{
                                id: string,
                                name: string,
                                access_token: string,
                                picture: { data: { url: string } }
                            }]
                        }) => {
                            const result: SocialPageInfo[] = []
                            if (data.length > 0)
                                data.map(item => {
                                    result.push(new SocialPageInfo(item.id, item.name, item.picture.data.url))
                                })
                            resolve(result)
                        })
                else
                    reject('NOT_CONNECTED')
            }, () => reject('NOT_CONNECTED'));

        })
        return from(findPromise)
    }

    findInstagramPagesInfo(): Observable<SocialPageInfo[]> {

        const account$ = new Subject<SocialPageInfo[]>()
        const accountsResult: Array<SocialPageInfo> = [];

        window.FB.login((responseLogin: { authResponse: { accessToken: string } }) => {
            if (responseLogin.authResponse) {
                window.FB.api(
                    '/me?access_token=' + responseLogin.authResponse.accessToken,
                    'GET',
                    {"fields": "accounts{instagram_business_account,name}"},
                    (response: {
                        accounts: {
                            data: [{ id: string, instagram_business_account: { id: string }, name: string }]
                        }
                    }) => {
                        if (response.accounts?.data.length > 0) {
                            let totalBusinessAccount = 0
                            response.accounts.data.map((account) => {
                                if (account.instagram_business_account !== undefined) {
                                    totalBusinessAccount++
                                }
                            })
                            let counter = 0
                            response.accounts.data.map((account) => {
                                if (account.instagram_business_account !== undefined) {
                                    window.FB.api(
                                        `/${account.instagram_business_account.id}?access_token=${responseLogin.authResponse.accessToken}`,
                                        'GET',
                                        {"fields": "name,username,profile_picture_url"},
                                        (data: { name: string, id: string, profile_picture_url: string }) => {
                                            accountsResult.push(new SocialPageInfo(data.id, data.name, data.profile_picture_url))
                                            counter++
                                            if (totalBusinessAccount === counter) {
                                                account$.next(accountsResult)
                                            }
                                        }
                                    );
                                }
                            })
                        } else {
                            account$.error('NO_ACCOUNT')
                        }
                    }
                )
            } else {
                account$.error('NOT_CONNECTED')
            }

        }, () => account$.error('NOT_CONNECTED'))

        return account$
    }

    loadFollowersList(companyId: string): Observable<Follower[]> {
        const URL = process.env.REACT_APP_API_URL + `/v1/relationships/company/${companyId}/followers`

        const headers = {
            'Content-Type': 'application/json'
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: FollowerDTO[] }>(URL, headers)
            .pipe(
                map(response => CompanyMapper.mapToArrayFollower(response.data)),
                catchError(err => throwError(err.status.toString())
                )
            )
    }

    updateSingleField(companyId: string, field: KeyOf<BusinessRegistrationType>, value: ValueOf<BusinessRegistrationType>): Observable<void> {
        function isArrayOfType<T>(value: unknown): value is Array<T> {
            return Array.isArray(value);
        }

        const URL = process.env.REACT_APP_API_URL + `/v1/company/${companyId}/update`
        const body = new FormData();
        if (field === 'openingHours' && isArrayOfType<CompanyOpeningHours>(value)) {
            body.append('propertyName', 'openingHours');
            body.append('propertyValue', JSON.stringify(this.openingHoursCommand(value)));
        } else if (typeof value === 'string' || value instanceof File) {
            body.append('propertyName', field);
            body.append('propertyValue', value);
        }

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(URL, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    uploadAdvancedImage(image: File, companyId: string): Observable<string> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/settings/' + companyId + '/upload/image'
        const body = new FormData();
        body.append('image', image);

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map(response => response.response.data),
                catchError(err => throwError(err.status.toString()))
            )
    }

    private openingHoursCommand(openingHours: CompanyOpeningHours[]): CompanyOpeningHoursEditionDto[] {
        const openingHoursArray: CompanyOpeningHoursEditionDto[] = []
        openingHours.map(item => {
            openingHoursArray.push({
                id       : item.id,
                daytype  : item.openingDays,
                startTime: item.startTime,
                endTime  : item.endTime,
                enabled  : '1'
            })
        })
        return openingHoursArray
    }
}
