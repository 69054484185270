import React from 'react';

interface Props {
    title: string;
}

export const DefaultPageHeader = (props: Props): JSX.Element => {
    return (
        <div className={'profile-edition'}>
            <div className="section-title">
                <h2 className="title">{props.title}</h2>
            </div>
        </div>
    )
}
