import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../../configuration/i18n";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";
import { ManagementServiceInstantFormType } from "../../../form/type/managementInstantFormType";
import { HtmlEditor } from "../../../../../../common/adapters/primaries/form/inputs/htmlEditor";
import { CropImageModal } from "../../../../../../common/adapters/primaries/modal/cropImageModal";
import Resizer from "react-image-file-resizer";
import { PictureInput } from "../../../../../../common/adapters/primaries/form/inputs/pictureInput";
import { InstantFormValidator } from "../../../form/instantForm.validator";
import { ServiceCategorySelector } from "./serviceCategory.selector";

interface Props {
    instant: ManagementServiceInstantFormType;
    raiseUpdates: (key: keyof ManagementServiceInstantFormType, value: string | File) => void;
    hasError: boolean;
}

interface State {
    category: string;
    title: string;
    shortDescription: string;
    longDescription: string;
    picture: File | undefined;
    pictureUrl: string | undefined
    categoryError: boolean;
    titleError: boolean;
    shortDescriptionError: boolean;
    pictureError: boolean;
    longDescriptionError: boolean;
    visibleCropModal: boolean
    errorMessagePicture: string
}

export class ServiceBasicInformationSection extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            category             : this.props.instant.category,
            title                : this.props.instant.title,
            shortDescription     : this.props.instant.description,
            longDescription      : this.props.instant.longDescription,
            picture              : this.props.instant.picture,
            pictureUrl           : this.props.instant.pictureUrl,
            categoryError        : false,
            titleError           : false,
            shortDescriptionError: false,
            pictureError         : false,
            longDescriptionError : false,
            visibleCropModal     : false,
            errorMessagePicture  : ''
        }
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
        if (nextProps.hasError) {
            return {
                ...prevState,
                categoryError        : !InstantFormValidator.checkCategory(prevState.category),
                titleError           : !InstantFormValidator.checkTitle(prevState.title),
                pictureError         : !InstantFormValidator.checkPicture(prevState.picture),
                shortDescriptionError: !InstantFormValidator.checkDescription(prevState.shortDescription),
                longDescriptionError : !InstantFormValidator.checkLongDescription(prevState.longDescription)
            }
        }
        return prevState
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.instant && this.props.instant !== prevProps.instant)
            this.setState({
                category        : this.props.instant.category,
                title           : this.props.instant.title,
                shortDescription: this.props.instant.description,
                longDescription : this.props.instant.longDescription,
                picture         : this.props.instant.picture,
                pictureUrl      : this.props.instant.pictureUrl,
            })
    }

    render(): ReactNode {
        const errorMessage = this.state.errorMessagePicture !== '' &&
            <p className={'error'}>{this.state.errorMessagePicture}</p>

        return (
            <div className={'section_pro'}>
                <span className="wizard-sub-text">{i18n.management_instant.basic_information}</span>

                <div className={'px-3'}>
                    <ServiceCategorySelector error={this.state.categoryError}
                                             selected={this.state.category}
                                             onChange={(category: string) =>
                                                 this.setState({category},
                                                     () => this.props.raiseUpdates('category', category)
                                                 )
                                             }/>

                    <TextInput type={'text'}
                               error={this.state.titleError}
                               label={i18n.management_instant.title}
                               value={this.state.title}
                               raiseUpdates={(title: string) => this.setState({
                                   title,
                               }, () => this.props.raiseUpdates('title', title))}/>

                    <TextInput value={this.state.shortDescription}
                               type={'text'}
                               raiseUpdates={(textValue: string): void => {
                                   this.setState({shortDescription: textValue})
                                   this.props.raiseUpdates('description', textValue)
                               }}
                               error={this.state.shortDescriptionError}
                               label={i18n.management_instant.short_description}
                               numberOfLines={3}/>

                    <PictureInput label={i18n.management_instant.instant_picture}
                                  error={this.state.pictureError}
                                  value={this.state.pictureUrl}
                                  raiseUpdates={(image: File) => this.onChangePicture(image)}/>

                    {errorMessage}

                    {this.renderCropModal()}

                    <HtmlEditor value={this.state.longDescription}
                                raiseUpdates={(textValue: string): void => {
                                    this.setState({longDescription: textValue})
                                    this.props.raiseUpdates('longDescription', textValue)
                                }}
                                error={this.state.longDescriptionError}/>
                </div>
            </div>
        )
    }

    onChangePicture(image: File): void {
        if (image !== undefined)
            this.checkFileSize(image).then(result => {
                if (result)
                    this.setState({
                        picture         : image,
                        pictureUrl      : image && URL.createObjectURL(image),
                        visibleCropModal: true
                    })
                else
                    this.setState({errorMessagePicture: i18n.common.error_size_picture(500, 500)})
            })
    }

    checkFileSize(image: File): Promise<boolean> {
        const promiseCheck = new Promise<boolean>((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(image);
            img.onload = () => {
                URL.revokeObjectURL(objectUrl);
                resolve(img.width >= 500 && img.height >= 500)
            }
            img.src = objectUrl
        })
        return promiseCheck
    }

    renderCropModal(): JSX.Element | undefined {
        if (this.state.visibleCropModal && this.state.picture)
            return (
                <CropImageModal width={500}
                                height={500}
                                url={URL.createObjectURL(this.state.picture)}
                                name={this.state.picture.name}
                                crop={(image: File) => this.setState({
                                    picture            : image,
                                    pictureUrl         : image && URL.createObjectURL(image),
                                    pictureError       : false,
                                    visibleCropModal   : false,
                                    errorMessagePicture: ''
                                }, () => {
                                    if (this.state.picture) {
                                        this.resizeImage(this.state.picture, 500)
                                    }
                                })}
                                close={() => this.setState({
                                    visibleCropModal: false,
                                    picture         : undefined,
                                    pictureUrl      : ''
                                })}/>
            )
    }

    resizeImage(picture: File, size: number): void {
        Resizer.imageFileResizer(picture, size, size, "JPEG", 90, 0,
            (result) => {
                fetch(result.toString())
                    .then(res => res.blob())
                    .then(blob => {
                        const file = new File([blob], picture.name, {type: "image/png"})
                        this.props.raiseUpdates('picture', file)
                        this.props.raiseUpdates('pictureUrl', file && URL.createObjectURL(file))
                    })
            })
    }
}
