
import { FindSocialPageState } from "../../configuration/state";
import {
    FIND_INSTAGRAM_ACCOUNT, FIND_INSTAGRAM_ACCOUNT_FAILED, FIND_INSTAGRAM_ACCOUNT_SUCCEEDED, FindInstagramAccountActionTypes
} from "./actionTypes";

const initialState: FindSocialPageState = {
    loading: false,
    error: undefined,
    pageInfo: null
}
export const findInstagramAccountReducer = (state = initialState, action: FindInstagramAccountActionTypes): FindSocialPageState =>{
    switch (action.type){
        case FIND_INSTAGRAM_ACCOUNT:
            return { loading: true, error: undefined, pageInfo: null}
        case FIND_INSTAGRAM_ACCOUNT_FAILED:
            return {loading: false, error: action.payload, pageInfo: null}
        case FIND_INSTAGRAM_ACCOUNT_SUCCEEDED:
            return {loading: false, error: undefined, pageInfo: action.payload}
        default:
            return state
    }
}
