import { BusinessRegistrationType } from "../../../registration/domain/type/BusinessRegistrationType";
import { ValueOf } from "../../../common/domain/types/app.categories";

export const UPDATE_SINGLE_FIELD = 'UPDATE_SINGLE_FIELD'
export const UPDATE_SINGLE_FIELD_SUCCEEDED = 'UPDATE_SINGLE_FIELD_SUCCEEDED'
export const UPDATE_SINGLE_FIELD_FAILED = 'UPDATE_SINGLE_FIELD_FAILED'

export interface UpdateSingleFieldAction {
    type: typeof UPDATE_SINGLE_FIELD;
    payload: { field: keyof BusinessRegistrationType, value: ValueOf<BusinessRegistrationType> };
}

export interface UpdateSingleFieldSucceededAction {
    type: typeof UPDATE_SINGLE_FIELD_SUCCEEDED;
}

export interface UpdateSingleFieldFailedAction {
    type: typeof UPDATE_SINGLE_FIELD_FAILED;
    payload: string;
}

export type UpdateSingleFieldActionTypes =
    UpdateSingleFieldAction
    | UpdateSingleFieldSucceededAction
    | UpdateSingleFieldFailedAction
