import { combineEpics, Epic } from 'redux-observable';
import { signUpEpic } from "../usecases/signUp/epic";
import { sendConfirmationCodeEpic } from "../usecases/confirmation/epic";
import { updateProfileEpic } from "../usecases/updateProfile/epic";
import { loadBusinessByCodeEpic } from "../usecases/loadBusinessByCode/epic";
import { registerBusinessEpic } from "../usecases/registerBusiness/epic";
import { updateBusinessEpic } from "../usecases/updateBusiness/epic";
import { loadLocalUserEpic } from "../usecases/loadLocalUser/epic";
import { loadLocalBusinessEpic } from "../usecases/loadLocalBusiness/epic";
import { loadLocalCodeEpic } from "../usecases/loadLocalCode/epic";
import { searchCityEpic } from "../usecases/searchCity/epic";

export const registrationEpic: Epic = combineEpics(
    signUpEpic,
    sendConfirmationCodeEpic,
    updateProfileEpic,
    loadBusinessByCodeEpic,
    registerBusinessEpic,
    updateBusinessEpic,
    loadLocalUserEpic,
    loadLocalCodeEpic,
    loadLocalBusinessEpic,
    searchCityEpic
)
