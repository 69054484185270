import { combineReducers } from "redux";
import { ManagementInstantState } from "./state";
import { loadInstantsCompanyReducer } from "../usecases/loadInstantsCompany/loadInstantsCompany.reducer";
import { loadManagementInstantReducer } from "../usecases/loadManagementInstantById/loadManagementInstant.reducer";
import { editInstantReducer } from "../usecases/editInstant/editinstant.reducer";
import { updateInstantStatusReducer } from "../usecases/updateStatus/updateInstantStatus.reducer";
import { deleteInstantReducer } from "../usecases/delete/deleteInstant.reducer";
import { createInstantReducer } from "../usecases/createInstant/createInstant.reducer";
import { loadPreviewInstantsCompanyReducer } from "../usecases/loadPreviewIstantsCompany/loadPreviewInstantsCompany.reducer";
import { postInstantOnFacebookReducer } from "../usecases/postOnFacebook/reducer";
import { postInstantOnInstagramReducer } from "../usecases/postOnInstagram/reducer";

export const ManagementInstantsRootReducer = combineReducers<ManagementInstantState>({
    loadInstantsCompany       : loadInstantsCompanyReducer,
    loadManagementInstant     : loadManagementInstantReducer,
    editInstant               : editInstantReducer,
    updateInstantStatus       : updateInstantStatusReducer,
    deleteInstant             : deleteInstantReducer,
    createInstant             : createInstantReducer,
    loadPreviewInstantsCompany: loadPreviewInstantsCompanyReducer,
    postInstantOnFacebook     : postInstantOnFacebookReducer,
    postInstantOnInstagram    : postInstantOnInstagramReducer
})
