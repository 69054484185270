import { Order } from "../../domain/entities/order";
import {
    VALIDATE_ORDER,
    VALIDATE_ORDER_FAILED,
    VALIDATE_ORDER_SUCCEEDED,
    ValidateOrderAction, ValidateOrderFailedAction, ValidateOrderSucceededAction
} from "./actionTypes";

export const validateOrder = (order: Order, total: string): ValidateOrderAction => ({
    type   : VALIDATE_ORDER,
    payload: {order, total}
})

export const validateOrderFailed = (error: string): ValidateOrderFailedAction => ({
    type   : VALIDATE_ORDER_FAILED,
    payload: error
})

export const validateOrderSucceeded = (conversationId: string): ValidateOrderSucceededAction => ({
    type   : VALIDATE_ORDER_SUCCEEDED,
    payload: conversationId
})
