import { AsyncState } from "../../configuration/state";
import {
    UPDATE_BUSINESS,
    UPDATE_BUSINESS_FAILED,
    UPDATE_BUSINESS_SUCCEEDED,
    UpdateBusinessActionTypes
} from "./actionTypes";

const initialState: AsyncState = {
    loading: false,
    success: null,
    error  : undefined
}

export const updateBusinessReducer = (state = initialState, action: UpdateBusinessActionTypes): AsyncState => {
    switch (action.type) {
        case UPDATE_BUSINESS:
            return {
                loading: true,
                error  : undefined,
                success: null
            }
        case UPDATE_BUSINESS_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                success: true
            }
        case UPDATE_BUSINESS_FAILED:
            return {
                loading: false,
                error  : action.payload,
                success: false
            }
        default:
            return state
    }
}
