import { Observable, throwError } from 'rxjs';
import { ProductService } from "../../../domain/gateway/product.service";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { ProductDetails } from "../../../domain/entities/productDetails";
import { catchError, map } from "rxjs/operators";
import { ProductDetailsDTO } from "./dto/productDetails.dto";
import { ProductMapper } from "./mapper/product.mapper";
import { ProductGalleryImage } from "../../../domain/entities/productGalleryImage";

export class ApiProductService implements ProductService {

    getProducts(companyId: string): Observable<ProductDetails[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/ecommerce/' + companyId + '/products'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: ProductDetailsDTO[] }>(url)
            .pipe(
                map((response: { data: ProductDetailsDTO[] }) => ProductMapper.mapToProductDetails(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    addProduct(product: ProductDetails, companyId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/ecommerce/' + companyId + '/product'
        const body = new FormData();

        body.append('name', product.name);
        body.append('description', product.description);
        body.append('shortDescription', product.shortDescription);
        body.append('price', product.price.toString());
        body.append('stock', product.stock.toString());
        body.append('category', product.category);
        body.append('weight', product.weight.toString());
        body.append('length', product.length.toString());
        body.append('width', product.width.toString());
        body.append('height', product.height.toString());

        if (product.image instanceof File) {
            body.append('featuredImage', product.image)
        }

        product.gallery.map(image => {
            if (image instanceof File) {
                body.append('gallery[]', image)
            }
        });

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map(response => {
                    console.log(response.response.data)
                }),
                catchError(err => throwError(err.status.toString()))
            )
    }

    editProduct(product: ProductDetails, companyId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/ecommerce/' + companyId + '/product/' + product.id + '/update'
        const body = new FormData();

        body.append('name', product.name);
        body.append('description', product.description);
        body.append('shortDescription', product.shortDescription);
        body.append('price', product.price.toString());
        body.append('stock', product.stock.toString());
        body.append('category', product.category);
        body.append('weight', product.weight.toString());
        body.append('length', product.length.toString());
        body.append('width', product.width.toString());
        body.append('height', product.height.toString());

        if (product.image instanceof File) {
            body.append('featuredImage', product.image)
        }

        const oldGallery: string[] = []
        product.gallery.map(image => {
            if (image instanceof File) {
                body.append('gallery[]', image)
            } else if (image instanceof ProductGalleryImage) {
                oldGallery.push(image.id)
            }
        });

        body.append('oldGallery', JSON.stringify(oldGallery))

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    deleteProduct(productId: string, companyId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/ecommerce/' + companyId + '/product/' + productId + '/update'
        const body = new FormData();

        body.append('status', 'REMOVED');

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    editProductStatus(product: ProductDetails, companyId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/ecommerce/' + companyId + '/product/' + product.id + '/update'
        const body = new FormData();

        body.append('status', product.isActive ? 'ACTIVE' : 'INACTIVE');

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

}
