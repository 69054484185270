import {
    RETRIEVE_SUPER_ADMIN_STATS,
    RETRIEVE_SUPER_ADMIN_STATS_FAILED,
    RETRIEVE_SUPER_ADMIN_STATS_SUCCEEDED,
    RetrieveStatsActionTypes
} from "./retrieveStats.types";
import { RetrieveSuperAdminStatsState } from "../../configuration/state";


const initialState: RetrieveSuperAdminStatsState = {
    loading  : false,
    companies: undefined,
    profiles : undefined,
    error    : undefined
}

export const retrieveStatsReducer = (state = initialState, action: RetrieveStatsActionTypes): RetrieveSuperAdminStatsState => {
    switch (action.type) {
        case RETRIEVE_SUPER_ADMIN_STATS:
            return {
                loading  : true,
                profiles : undefined,
                companies: undefined,
                error    : undefined
            }
        case RETRIEVE_SUPER_ADMIN_STATS_FAILED:
            return {
                loading  : false,
                profiles : undefined,
                companies: undefined,
                error    : action.payload
            }
        case RETRIEVE_SUPER_ADMIN_STATS_SUCCEEDED:
            return {
                loading  : false,
                profiles : action.payload.profiles,
                companies: action.payload.companies,
                error    : undefined
            }
        default:
            return state
    }
}
