import { StatsService } from "../../../domain/gateway/StatsService";
import { Observable, throwError } from "rxjs";
import { StatsCompany } from "../../../domain/entity/statsCompany";
import {
    SecuredObservableAjaxHttpClient
} from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { catchError, map } from "rxjs/internal/operators";
import { ApiStatsDTO } from "./dto/apiStatsDTO";
import { StatsMapper } from "./mapper/Stats.mapper";
import { StatsProfile } from "../../../domain/entity/statsProfile";
import { CartesianData } from "../../../domain/entity/cartesianData";

export class ApiStatsService implements StatsService {

    retrieveSuperAdminStats(cityCode: string): Observable<{ profiles: StatsProfile[]; companies: StatsCompany[] }> {
        const url = `${process.env.REACT_APP_API_URL}/v1/stats?zipCode=${cityCode}`
        const headers = {
            'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: ApiStatsDTO }>(url, headers)
            .pipe(
                map((response: { data: ApiStatsDTO }) => StatsMapper.map(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    retrieveCompanyVisitStats(companyId: string): Observable<CartesianData[]> {
        const url = `${process.env.REACT_APP_TRACKING_URL}/stats/${companyId}/visits`
        const headers = {
            'content-type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        }

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<Array<{ xValue: string; yValue: number }>>(url, headers)
            .pipe(
                map((response) => StatsMapper.mapToCartesianData(response)),
                catchError(err => throwError(err.toString()))
            )

    }

    retrieveCompanyFollowStats(companyId: string): Observable<CartesianData[]> {
        const url = `${process.env.REACT_APP_TRACKING_URL}/stats/${companyId}/followers`
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept'      : 'application/json'
        }

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<Array<{ xValue: string; yValue: number }>>(url, headers)
            .pipe(
                map((response) => StatsMapper.mapToCartesianData(response)),
                catchError(err => throwError(err.toString()))
            )
    }
}
