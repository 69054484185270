export class CompanyMembership {
    static FREEMIUM: CompanyMembershipType = 'freemium'
    static PREMIUM: CompanyMembershipType = 'premium'
    static BASIC: CompanyMembershipType = 'basic'
    static DIGITAL: CompanyMembershipType = 'digital'

    constructor(
        protected _type: CompanyMembershipType,
        protected _allowedInstants: number,
        protected _allowedConversations: number,
        protected _allowedNotifications: number,
        protected _allowedBroadcastMessages: number,
        protected _hasSubdomain: boolean,
        protected _hasClickAndCollect: boolean
    ) {}

    get type(): CompanyMembershipType {
        return this._type;
    }

    get allowedInstants(): number {
        return this._allowedInstants;
    }

    get allowedConversations(): number {
        return this._allowedConversations;
    }

    get allowedNotifications(): number {
        return this._allowedNotifications;
    }

    get allowedBroadcastMessages(): number {
        return this._allowedBroadcastMessages;
    }

    get hasSubdomain(): boolean {
        return this._hasSubdomain;
    }

    get hasClickAndCollect(): boolean {
        return this._hasClickAndCollect;
    }
}

export type CompanyMembershipType =  'freemium' | 'premium' | 'basic' | 'digital';
