import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrderDTO } from './DTO/order.dto';
import { ClickCollectMapper } from './mapper/mapper';
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { ClickCollectService } from "../../../domain/gateway/clickCollectService";
import { Order } from "../../../domain/entities/order";
import { throwError } from "rxjs/internal/observable/throwError";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";

export class ApiClickCollectService implements ClickCollectService {

    cancelOrder(orderId: string, message: string): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/clickAndCollect/order/${orderId}/cancel`
        const body = new FormData();
        body.append('cancelMessage', message);

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString())
                )
            )
    }

    loadOrder(orderId: string): Observable<Order> {
        const url = `${process.env.REACT_APP_API_URL}/v1/clickAndCollect/order/${orderId}`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: OrderDTO }>(url).pipe(
            map(result => ClickCollectMapper.mapOrderDtoToOrderDomain(result.data, orderId)),
            catchError(err => throwError(err))
        )
    }

    validateOrder(order: Order, total: string): Observable<string> {
        const body = new FormData();
        const url = `${process.env.REACT_APP_API_URL}/v1/clickAndCollect/order/${order.id}/validate`

        body.append('pickupDateTime', order.time);
        body.append('orderContent', order.content);
        body.append('total', total);

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body).pipe(
            map((result: { response: { data: { discussionId: string } } }) => result.response.data.discussionId),
            catchError(err => throwError(err.status.toString()))
        )
    }
}
