import { LoadBusinessState } from "../../configuration/state";
import {
    LOAD_LOCAL_BUSINESS,
    LOAD_LOCAL_BUSINESS_FAILED,
    LOAD_LOCAL_BUSINESS_SUCCEEDED,
    LoadLocalBusinessActionTypes
} from "./actionTypes";

const initialState: LoadBusinessState = {
    loading : false,
    business: null,
    error   : undefined
}

export const loadLocalBusinessReducer = (state = initialState, action: LoadLocalBusinessActionTypes): LoadBusinessState => {
    switch (action.type) {
        case LOAD_LOCAL_BUSINESS:
            return {
                loading : true,
                business: null,
                error   : undefined
            }
        case LOAD_LOCAL_BUSINESS_SUCCEEDED:
            return {
                loading : false,
                business: action.payload,
                error   : undefined
            }
        case LOAD_LOCAL_BUSINESS_FAILED:
            return {
                loading : false,
                business: null,
                error   : action.payload
            }
        default:
            return state
    }
};
