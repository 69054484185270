import { InstantHeader } from "../../domain/entites/instantHeader";


export const LOAD_AGENDA_INSTANTS = 'LOAD_AGENDA_INSTANTS'
export const LOAD_AGENDA_INSTANTS_SUCCEEDED = 'LOAD_AGENDA_INSTANTS_SUCCEEDED'
export const LOAD_AGENDA_INSTANTS_FAILED = 'LOAD_AGENDA_INSTANTS_FAILED'


export interface LoadAgendaInstantAction {
    type: typeof LOAD_AGENDA_INSTANTS;
    payload: string;
}

export interface LoadAgendaInstantSucceededAction {
    type: typeof LOAD_AGENDA_INSTANTS_SUCCEEDED;
    payload: {
        instants: InstantHeader[];
    };
}

export interface LoadAgendaInstantFailedAction {
    type: typeof LOAD_AGENDA_INSTANTS_FAILED;
    payload: string;
}


export type LoadAgendaInstantActionTypes =
    LoadAgendaInstantAction
    | LoadAgendaInstantSucceededAction
    | LoadAgendaInstantFailedAction
