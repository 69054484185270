import {
    POST_INSTANT_ON_INSTAGRAM, POST_INSTANT_ON_INSTAGRAM_FAILED, POST_INSTANT_ON_INSTAGRAM_SUCCEEDED, PostInstantOnInstagramAction,
    PostInstantOnInstagramFailedAction, PostInstantOnInstagramSucceededAction
} from "./actionTypes";
import { ContentPostType } from "../../domain/entities/types/contentPostType";
import { SocialPageInfo } from "../../../company/domain/entity/socialPageInfo";


export const postInstantOnInstagram = (page: SocialPageInfo, post: ContentPostType): PostInstantOnInstagramAction =>({
    type: POST_INSTANT_ON_INSTAGRAM,
    payload: {page, content: post}
})

export const postInstantOnInstagramFailed =(error: string): PostInstantOnInstagramFailedAction =>({
    type: POST_INSTANT_ON_INSTAGRAM_FAILED,
    payload: error
})

export const postInstantOnInstagramSucceeded =():PostInstantOnInstagramSucceededAction =>({
    type: POST_INSTANT_ON_INSTAGRAM_SUCCEEDED
})
