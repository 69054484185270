import React from "react";

interface Props {
    onClick: () => void;
}

export const AddServiceButton = (props: Props): JSX.Element => {
    return (
        <div className={'service-item add-button d-flex justify-content-center align-items-center'}
             draggable={false}
             onClick={() => props.onClick()}>

            <div className={'text-center'}>
                <i className="fa fa-plus"/>
                <p className="title">Ajouter un service</p>
            </div>
        </div>
    )
}
