import React, { PureComponent, ReactNode } from 'react'
import FilerobotImageEditor, { TABS } from 'react-filerobot-image-editor';
import { i18n } from "../../../../configuration/i18n";

interface Props {
    url: string
    name: string
    close: () => void
    crop: (file: File) => void
    width: number
    height: number
}

export class AdvancedCropModal extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className="modal crop-modal default-modal">
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="header">
                            <p className={'title'}>{i18n.common.edit_image}</p>
                            <span className={'close-button'} onClick={() => this.props.close()}/>
                        </div>

                        <div className="body">
                            <div className={'cropContainer'}>
                                <FilerobotImageEditor
                                    source={this.props.url} language={'fr'} translations={{save:'Enregistrer'}}
                                    onBeforeSave={()=> false}
                                    onSave={(editedImageObject) => {
                                        if (editedImageObject.imageBase64)
                                            this.urlToFile(editedImageObject.imageBase64, this.props.name, 'image/png')
                                                .then((file) => {
                                                    this.props.crop(file)
                                                })
                                    }}

                                    onClose={(): void => this.props.close()}
                                    savingPixelRatio={10} previewPixelRatio={10}
                                    defaultSavedImageType={'jpeg'}
                                    annotationsCommon={{
                                        fill: '#ff0000'
                                    }}
                                    Text={{text: 'Logo...'}}
                                    Rotate={{angle: 90, componentType: 'slider'}}
                                    disableZooming={false}
                                    Crop={{minHeight: 200, minWidth: 200, ratio: 1, noPresets: true}}
                                    tabsIds={[TABS.ADJUST, TABS.FILTERS, TABS.FINETUNE, TABS.ANNOTATE]}
                                    defaultTabId={TABS.ADJUST}
                                    observePluginContainerSize={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
     urlToFile(url:string, filename:string, mimeType: string):Promise<File>{
        return (fetch(url)
                .then(function(res){return res.arrayBuffer();})
                .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }
}
