import { CompanyService } from "../domain/gateway/company.service";
import { ApiCompanyService } from "../adapters/secondaries/real/apiCompany.service";
import { InMemoryCompanyService } from "../adapters/secondaries/inMemory/inMemoryCompany.service";

export class CompanyDependenciesFactory {
    static getCompanyService(): CompanyService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiCompanyService()
            default:
                return new InMemoryCompanyService()
        }
    }
}
