import {
    SEND_CONFIRMATION_CODE,
    SEND_CONFIRMATION_CODE_FAILED,
    SEND_CONFIRMATION_CODE_SUCCEEDED, SendConfirmationCodeAction,
    SendConfirmationCodeActionTypes
} from "./actionTypes";

export const sendConfirmationCode = (phoneNumber: string, code: string): SendConfirmationCodeAction => ({
    type   : SEND_CONFIRMATION_CODE,
    payload: {phoneNumber, code}
})

export const sendConfirmationCodeFailed = (error: string): SendConfirmationCodeActionTypes => ({
    type   : SEND_CONFIRMATION_CODE_FAILED,
    payload: error
})

export const sendConfirmationCodeSucceeded = (token: string): SendConfirmationCodeActionTypes => ({
    type   : SEND_CONFIRMATION_CODE_SUCCEEDED,
    payload: token
})
