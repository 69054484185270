import React, { PureComponent, ReactNode } from "react";
import { Bar } from "react-chartjs-2";
import { CartesianData } from "../../../../domain/entity/cartesianData";
import { i18n } from "../../../../../configuration/i18n";
import { Theme } from "../../../../../common/configuration/theme/app.theme";
import { ApplicationContext } from "../../../../../configuration/application.context";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    visitsData: CartesianData[];
}

export class BusinessVisitsChart extends PureComponent<Props> {

    render(): ReactNode {

        const visitsSum = this.props.visitsData.reduce((accumulator, currentValue) => accumulator + currentValue.yValue, 0)
        const visitsAverage = parseInt((visitsSum / this.props.visitsData.length).toString())

        const visitsData = {
            labels  : this.props.visitsData.map(item => moment(item.xValue, "YYYY-MM-DD hh:mm:ss").format('MMM')),
            datasets: [
                {
                    label          : i18n.stats.average_visits_number,
                    type           : 'line' as const,
                    data           : this.props.visitsData.map(() => visitsAverage),
                    fill           : false,
                    backgroundColor: Theme.supernova,
                    borderColor    : Theme.supernova,
                },
                {
                    label          : i18n.stats.visits_number,
                    type           : 'bar' as const,
                    data           : this.props.visitsData.map(item => item.yValue),
                    fill           : false,
                    backgroundColor: this.props.visitsData.map((item, index) => {
                        return index === this.props.visitsData.length - 1 ? '#99cc33' : Theme.blueMinsk
                    }),

                }
            ]
        };

        return <Bar className="p-3" type={'bar'} data={visitsData} style={{maxHeight: 400}}/>;
    }
}
