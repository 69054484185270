import { PaymentService } from "../../../domain/gateway/paymentService";
import { Observable, throwError } from "rxjs";
import {
    SecuredObservableAjaxHttpClient
} from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { catchError, map } from "rxjs/operators";
import {
    SubscriptionPeriodicity,
    SubscriptionId,
    PaymentStatus
} from "../../../domain/entities/types/subscription.type";

export class ApiPaymentService implements PaymentService {

    retrievePaymentSession(companyId: string, subscriptionId: SubscriptionId, periodicity: SubscriptionPeriodicity): Observable<string> {
        const url = `${process.env.REACT_APP_API_URL}/v1/payment/create/paymentSession`

        const body = new FormData();
        body.append('companyId', companyId);
        body.append('subscriptionId', subscriptionId);
        body.append('periodicity', periodicity);

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(response => response.response.data),
                catchError(err => throwError(err.response.message))
            )
    }

    validatePayment(sessionId: string): Observable<PaymentStatus> {
        const url = `${process.env.REACT_APP_API_URL}/v1/payment/validate`

        const body = new FormData();
        body.append('sessionId', sessionId);

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(response => response.response.data),
                catchError(err => throwError(err.response.message))
            )
    }

}
