import { HandleMunicipalServiceState } from "../../configuration/state";
import {
    DELETE_MUNICIPALITY_SERVICE, DELETE_MUNICIPALITY_SERVICE_FAILED, DELETE_MUNICIPALITY_SERVICE_SUCCEEDED, DeleteMunicipalityServiceActionTypes
} from "./actionTypes";

const initialState: HandleMunicipalServiceState = {
    loading: false,
    error  : undefined
}

export const deleteMunicipalityServiceReducer = (state = initialState, action: DeleteMunicipalityServiceActionTypes): HandleMunicipalServiceState => {
    switch (action.type) {
        case DELETE_MUNICIPALITY_SERVICE:
            return {
                loading: true,
                error  : undefined
            }
        case DELETE_MUNICIPALITY_SERVICE_FAILED:
            return {
                loading: false,
                error  : action.payload
            }
        case DELETE_MUNICIPALITY_SERVICE_SUCCEEDED:
            return {
                loading: false,
                error  : undefined
            }
        default:
            return state;
    }
}
