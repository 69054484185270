import { BroadcastMessage } from './broadcastMessage';

export class BroadcastConversation {
    constructor(
        protected _id: string,
        protected _title: string,
        protected _excerpt: string,
        protected _updatedAt: Date,
        protected _messages: BroadcastMessage[]
    ) {}

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get excerpt(): string {
        return this._excerpt;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    get messages(): BroadcastMessage[] {
        return this._messages;
    }
}
