import { BroadcastConversation } from "../../../domain/entities/broadcastConversation";
import { BroadcastConversationBuilder } from "../../../domain/builder/broadcastConversation.builder";
import { UPDATE_BROADCAST_CONVERSATION, UpdateBroadcastConversationAction } from "./action";

const initialState: BroadcastConversation = new BroadcastConversationBuilder().build()

export const broadcastConversationReducer = (state = initialState, action: UpdateBroadcastConversationAction): BroadcastConversation => {
    switch (action.type) {
        case UPDATE_BROADCAST_CONVERSATION:
            return action.payload
        default:
            return state
    }
}
