import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { UPLOAD_ADVANCED_IMAGE, UploadAdvancedImageAction } from "./actionTypes";
import { CompanyService } from "../../domain/gateway/company.service";
import { uploadAdvancedImageFailed, uploadAdvancedImageSucceeded } from "./actions";

export const uploadAdvancedImageEpic: Epic = (action$: ActionsObservable<UploadAdvancedImageAction>,
                                              store: StateObservable<AppState>,
                                              {companyService}: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(UPLOAD_ADVANCED_IMAGE),
        switchMap((action) => companyService.uploadAdvancedImage(action.payload.image, action.payload.companyId)
            .pipe(
                map((imageUrl: string) => uploadAdvancedImageSucceeded(imageUrl)),
                catchError(err => of(uploadAdvancedImageFailed(err)))
            )
        )
    );
