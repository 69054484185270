export const instantsI18n = {
    page_title                  : 'VivezICI | L\'Agenda de ',
    time_left_status_expired    : 'Terminé',
    time_left_status_starts_on  : 'Début',
    time_left_status_ends_on    : 'Fin',
    distance_away               : 'd\'ici',
    tomorrow                    : 'Demain',
    today                       : 'Aujourd\'hui',
    on                          : 'à',
    in                          : 'dans',
    instant_schedule            : (date: string, startHour: string, endHour: string): string => `${date} de ${startHour} à ${endHour}`,
    next_weeks                  : 'Les jours suivant',
    agenda_of                   : 'L\'Agenda de ',
    no_instants_found_for_agenda: 'Il n\'y a pas d\'instants prévus dans les 30 prochains jours.',
    in_month                    : (month: string): string => `En ${month}`,
}
