import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { RegistrationService } from "../../domain/gateway/registration.service";
import { AppState } from "../../../redux-configuration/AppState";
import { REGISTER_BUSINESS, RegisterBusinessAction } from "./actionTypes";
import { registerBusinessFailed, registerBusinessSucceeded } from "./actions";
import { loadCompanyDetails } from "../../../company/usecases/loadCompanyDetails/actions";
import { updateProfileSucceeded } from "../updateProfile/actions";

export const registerBusinessEpic: Epic = (action$: ActionsObservable<RegisterBusinessAction>,
                                           store: StateObservable<AppState>,
                                           {registrationService}:
                                               { registrationService: RegistrationService }) =>
    action$.pipe(
        ofType(REGISTER_BUSINESS),
        switchMap((action) => registrationService.updateProfile(action.payload.user)
            .pipe(
                mergeMap(() => registrationService.registerBusiness(action.payload.business, action.payload.originalProspectEmail)
                    .pipe(
                        concatMap((businessId: string) => [
                            loadCompanyDetails(businessId),
                            updateProfileSucceeded(),
                            registerBusinessSucceeded(businessId)
                        ]),
                        catchError(err => of(registerBusinessFailed(err)))
                    )
                ),
                catchError(err => of(registerBusinessFailed(err)))
            )
        )
    );
