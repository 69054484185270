export class ThemeBlock {
    constructor(
        protected _isActive: boolean,
        protected _text: string,
        protected _textColor: string,
        protected _backgroundColor: string,
        protected _images?: string[],
        protected _titleColor?: string,
        protected _itemColor?: string
    ) {}

    get isActive(): boolean {
        return this._isActive;
    }

    get text(): string {
        return this._text;
    }

    get textColor(): string {
        return this._textColor;
    }

    get backgroundColor(): string {
        return this._backgroundColor;
    }

    get images(): string[] | undefined {
        return this._images;
    }

    get titleColor(): string | undefined {
        return this._titleColor;
    }

    get itemColor(): string | undefined {
        return this._itemColor;
    }
}
