import { combineReducers } from "redux";
import { ClickCollectState } from "./state";
import { loadOrderReducer } from "../usecases/loadOrder/reducer";
import { cancelOrderReducer } from "../usecases/cancelOrder/reducer";
import { validateOrderReducer } from "../usecases/validateOrder/reducer";

export const clickCollectRootReducer = combineReducers<ClickCollectState>({
    loadOrder    : loadOrderReducer,
    cancelOrder  : cancelOrderReducer,
    validateOrder: validateOrderReducer
})
