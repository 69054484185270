import { ChatUser } from './chatUser';

export class Conversation {
    constructor(
        private _id: string,
        private _title: string,
        private _excerpt: string,
        private _updatedAt: Date,
        private _unreadMessages: number,
        private _users: ChatUser[],
        private _owner: ChatUser,
        private _picture: string,
        private _isBlocked: boolean
    ) {
    }

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get excerpt(): string {
        return this._excerpt;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    get unreadMessages(): number {
        return this._unreadMessages;
    }

    get users(): ChatUser[] {
        return this._users;
    }

    get owner(): ChatUser {
        return this._owner;
    }

    get picture(): string {
        return this._picture
    }

    get isBlocked(): boolean {
        return this._isBlocked;
    }

    notEquals(conversation: this | null): boolean {
        if (conversation === null)
            return true
        else return conversation.id !== this._id
    }
}
