import React, { PureComponent, ReactNode } from "react";
import { Company } from "../../../../domain/entity/company";
import { i18n } from "../../../../../configuration/i18n";
import { MapField } from "../../../../../common/adapters/primaries/map/mapField";
import { CompanyContactItem } from "./companyContact.item";

interface Props {
    company: Company
}

export class CompanyContactsPresentational extends PureComponent<Props> {
    render(): ReactNode {
        const mapCoords = `https://www.google.com/maps/dir//${this.props.company.name},${this.props.company.address.address }
            /@${ this.props.company.address.coordinates.latitude},${ this.props.company.address.coordinates.longitude},16z`;
        const address = this.props.company.address.address + ', ' + this.props.company.address.zipCode + ', ' + this.props.company.address.city

        return (
            <div className="row">
                <div className="col-md-6 col-xl-5 mb-3 mb-md-0 contact-block">
                    <h2 className="title">{i18n.company.contact}</h2>

                    <CompanyContactItem icon={'fa fa-phone'} title={this.props.company.contacts.phoneNumber}/>

                    <CompanyContactItem icon={'far fa-envelope'} title={this.props.company.contacts.email}/>

                    <CompanyContactItem icon={'fa fa-globe'} title={this.props.company.contacts.website}/>

                    <CompanyContactItem icon={'far fa-building'} title={address}/>

                    <a href={mapCoords} rel="noreferrer" className="yellow-button mt-20" target="_blank">
                        {i18n.company.route}
                    </a>
                </div>

                <div className="col-md-6 col-xl-7">
                    <MapField lat={Number(this.props.company.address.coordinates.latitude)}
                              lng={Number(this.props.company.address.coordinates.longitude)}/>
                </div>
            </div>
        )
    }
}
