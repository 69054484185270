import { ClickCollectPicture } from "../../../../company/domain/types/clickCollectPicture";

const moment = require('moment');
const psl = require('psl');

export type FieldType = 'name' | 'phoneNumber' | 'zipCode'

export class FormValidation {
    static validate(fieldName: FieldType, value: string): boolean {
        const constraints = {
            phoneNumber: {
                format: {
                    pattern: /^(\+33|0033|0)(6|7)[0-9]{8}$/
                }
            },
            name       : {
                format: {
                    pattern: /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ  -]{2,60}$/
                }
            },
            zipCode    : {
                format: {
                    pattern: /^(?:[0-8]\d|9[0-8])\d{3}$/
                }
            }
        }

        return new RegExp(constraints[fieldName].format.pattern).test(value)
    }

    static validateBirthDay(value: string): boolean {
        return moment().diff(moment(value, 'YYYY-MM-DD'), 'years') >= 18
    }

    static validateNumber(value: string): boolean {
        return new RegExp(/^\d+$/).test(value)
    }

    static validateSiren(value: string): boolean {
        return new RegExp(/^[0-9]{9}$/).test(value)
    }

    static validateRNA(value: string): boolean {
        return new RegExp(/^[w|W][0-9a-zA-Z]{9}$/).test(value)
    }

    static validateEmail(email: string): boolean {
        const regex = new RegExp(['^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
            '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
            '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
            '[a-zA-Z]{2,}))$'].join(''));

        if (regex.test(email?.toLowerCase())) {
            const domain = email.split('@');
            return psl.isValid(domain[1]);
        }
        return false
    }

    static validateWebsite(website: string): boolean {
        const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        return regex.test(website)
    }

    static validateWww(website: string | undefined): boolean {
        const regex = /^www\.[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        if (website && website.length > 0)
            return regex.test(website)
        return false
    }

    static validateDomainName(website: string | undefined): boolean {
        const regex = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        if (website && website.length > 0)
            return regex.test(website)
        return false
    }

    static validatePhoneNumberFix(phoneNumber: string): boolean {
        const regex = /^(\+33|0033|0)[1-9][0-9]{8}$/
        return regex.test(phoneNumber)
    }

    static validateDescriptionClickCollect(description: string | undefined, active: boolean): boolean {
        if (active)
            return description !== undefined && description !== null && description.length > 10
        return true
    }

    static validatePremiumSubdomain(subdomain: string): boolean {
        const regex = /^([a-zA-Z0-9][a-zA-Z0-9-_]*)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/
        return (subdomain.indexOf(' ') === -1 && regex.test(subdomain)) && subdomain.length > 0
    }

    static validateClickCollect(active: boolean, pictures: ClickCollectPicture[]): boolean {
        let pictureExists = false
        pictures.map(item => {
            if (item.pictureUrl !== '')
                pictureExists = true
        })
        if (active)
            return (pictureExists)
        return true
    }

}
