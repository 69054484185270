import { Company } from "../../../company/domain/entity/company";

export const UPDATE_BUSINESS = 'UPDATE_BUSINESS'
export const UPDATE_BUSINESS_SUCCEEDED = 'UPDATE_BUSINESS_SUCCEEDED'
export const UPDATE_BUSINESS_FAILED = 'UPDATE_BUSINESS_FAILED'

export interface UpdateBusinessAction {
    type: typeof UPDATE_BUSINESS;
    payload: Company;
}

export interface UpdateBusinessSucceededAction {
    type: typeof UPDATE_BUSINESS_SUCCEEDED;
}

export interface UpdateBusinessFailedAction {
    type: typeof UPDATE_BUSINESS_FAILED;
    payload: string;
}

export type UpdateBusinessActionType = UpdateBusinessAction

export type UpdateBusinessActionTypes =
    UpdateBusinessAction
    | UpdateBusinessSucceededAction
    | UpdateBusinessFailedAction
