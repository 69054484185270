import React, { PureComponent, ReactNode } from "react";
import { Schedule } from "../../../../../domain/entities/types/activationTimeType";
import { i18n } from "../../../../../../configuration/i18n";
import { ActiveDaysNames } from "../../../../../domain/entities/types/activationDayType";
import { CompanyOpeningHours } from "../../../../../../company/domain/entity/companyOpeningHours";
import { ApplicationContext } from "../../../../../../configuration/application.context";
import { CheckboxInput } from "../../../../../../common/adapters/primaries/form/inputs/checkbox.input";
import { TimeInput } from "../../../../../../common/adapters/primaries/form/inputs/time/time.input";
import { OpeningDaysSelector } from "../../../../../../common/adapters/primaries/form/inputs/openingDaysSelector";

interface Props {
    schedules: Schedule[] | undefined
    companyOpeningHours: CompanyOpeningHours[] | undefined
    raiseUpdate: (schedule: Schedule[]) => void
    cleanAllSchedules: () => void
}

interface State {
    selectedDay: string;
    startTime: string;
    endTime: string;
    errorDate: boolean | undefined
    errorTime: boolean | undefined
    preponed: boolean;
    useOpeningHours: boolean
}

const moment = ApplicationContext.getInstance().momentJs()

export class RecurrentScheduleForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            selectedDay    : 'all_days',
            startTime      : '',
            endTime        : '',
            useOpeningHours: false,
            errorDate      : undefined,
            errorTime      : undefined,
            preponed       : false
        }
    }

    render(): ReactNode {
        const timeError = this.state.errorTime && !this.state.errorDate &&
            <p className={'error'}>{i18n.management_instant.wrong_time}</p>

        return (
            <div>
                <div className="sub-section">
                    {!this.props.schedules || this.props.schedules?.length === 0 ? <CheckboxInput checked={this.state.useOpeningHours}
                                                                         label={i18n.management_instant.opening_hours_option}
                                                                         raiseUpdates={(isChecked: boolean) => this.handleCheckboxChange(isChecked)}/> : null}
                </div>
                <OpeningDaysSelector onChange={(value) => this.setState({selectedDay: value})}
                                     error={this.state.errorDate}
                                     label={i18n.management_instant.active_days} selected={this.state.selectedDay}/>

                <div className="wizard-form-input opening-hours">
                    <label className="start">{i18n.management_instant.from}</label>
                    <TimeInput time={this.state.startTime}
                               className={this.state.errorTime ? 'error' : ''}
                               onChange={(startTime: string): void => this.setState({startTime})}/>

                    <label className="end">{i18n.management_instant.to}</label>
                    <TimeInput time={this.state.endTime}
                               className={this.state.errorTime ? 'error' : ''}
                               onChange={(endTime: string): void => this.setState({endTime})}/>
                </div>
                {timeError}

                <div className="sub-section text-right">
                    <CheckboxInput checked={this.state.preponed}
                                   label={i18n.management_instant.preview_show_two_hours_before}
                                   raiseUpdates={() => this.setState({preponed: !this.state.preponed})}/>
                </div>

                <div className={'sub-section'}>
                    <span className={'opening-hours-btn add-btn'}
                          onClick={() => this.onSubmit()}>
                        {i18n.registration.add}
                    </span>
                </div>
            </div>
        )
    }

    private handleCheckboxChange(isChecked: boolean) {
        this.setState({useOpeningHours: !this.state.useOpeningHours}, () => {
            if (isChecked === true) {
                this.props.cleanAllSchedules()
                setTimeout(() => {
                    this.addCompanyOpeningHours()
                }, 100)
            } else {
                this.props.cleanAllSchedules()
            }
        })
    }

    addCompanyOpeningHours(): void {
        if (this.props.companyOpeningHours) {
            const schedules: Schedule[] = this.props.schedules ? this.props.schedules : []
            this.props.companyOpeningHours.map(item => {
                const startTime = item.startTime.length === 5 ? moment(item.startTime, 'HH:mm').format('HH:mm:ssZ') : item.startTime
                const endTime = item.endTime.length === 5 ? moment(item.endTime, 'HH:mm').format('HH:mm:ssZ') : item.endTime

                const schedule: Schedule = {
                    id       : '',
                    dateRange: {
                        key      : item.openingDays,
                        label    : ActiveDaysNames[item.openingDays],
                        startTime: startTime,
                        endTime  : endTime
                    },
                    isPaused : false,
                    prePoned : false
                }

                if (this.validateSchedule(schedule))
                    schedules.push({...schedule, prePoned: this.state.preponed})
            })
            this.cleanAndUpdate(schedules)
            this.setState({
                preponed       : false,
                useOpeningHours: false
            })
        }
    }

    onSubmit(): void {
        this.setState({
            errorDate: !this.state.selectedDay,
            errorTime: !this.state.startTime || !this.state.endTime
        }, () => {
            if (!this.state.errorDate && !this.state.errorTime) {
                const schedule: Schedule = {
                    id       : '',
                    dateRange: {
                        key      : this.state.selectedDay,
                        label    : ActiveDaysNames[this.state.selectedDay],
                        startTime: moment(this.state.startTime, 'HH:mm').format('HH:mm:ssZ'),
                        endTime  : moment(this.state.endTime, 'HH:mm').format('HH:mm:ssZ')
                    },
                    isPaused : false,
                    prePoned : false
                }
                this.addSchedule(schedule)
            }
        })
    }

    addSchedule(schedule: Schedule): void {
        if (this.validateSchedule(schedule)) {
            if (this.props.schedules)
                this.cleanAndUpdate([...this.props.schedules, {...schedule, prePoned: this.state.preponed}])
            else
                this.cleanAndUpdate([{...schedule, prePoned: this.state.preponed}])
            this.setState({
                preponed: false
            })
        }
    }

    validateSchedule(schedule: Schedule): boolean {
        const activationStartDateTime = this.state.preponed ?
            moment(schedule.dateRange.startTime, 'HH:mm:ssZ').subtract(2, 'hours') :
            moment(schedule.dateRange.startTime, 'HH:mm:ssZ')
        const activationEndDateTime = moment(schedule.dateRange.endTime, 'HH:mm:ssZ')
        return (activationStartDateTime.format('YYYY-MM-DD') === activationEndDateTime.format('YYYY-MM-DD'))
    }

    private cleanAndUpdate(schedules: Schedule[]): void {
        this.props.raiseUpdate(schedules)
    }

}
