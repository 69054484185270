import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { SEND_LOGIN_CONFIRMATION_CODE, SendLoginConfirmationCodeAction } from "./actionTypes";
import { RegistrationService } from "../../../registration/domain/gateway/registration.service";
import { AuthenticationRepository } from "../../domain/gateway/authenticationRepository";
import { Token } from '../../../registration/domain/entity/token';
import { sendLoginConfirmationCodeFailed, sendLoginConfirmationCodeSucceeded } from "./action";
import { loadOwnedCompany } from "../../../company/usecases/loadCompanyDetails/actions";

export const sendLoginConfirmationCodeEpic: Epic = (action$: ActionsObservable<SendLoginConfirmationCodeAction>,
                                                    store: StateObservable<AppState>,
                                                    {registrationService, authenticationRepository}:
                                                        {
                                                            registrationService: RegistrationService,
                                                            authenticationRepository: AuthenticationRepository
                                                        }) =>
    action$.pipe(
        ofType(SEND_LOGIN_CONFIRMATION_CODE),
        switchMap(action => registrationService.sendConfirmationCode(action.payload.phoneNumber, action.payload.code)
            .pipe(
                mergeMap((data: Token) => authenticationRepository.saveAuthorizationToken(data.token)
                    .pipe(
                        concatMap(() => [
                            loadOwnedCompany(),
                            sendLoginConfirmationCodeSucceeded(data.token)
                        ]),
                        catchError(error => of(sendLoginConfirmationCodeFailed(error)))
                    )
                ),
                catchError(error => of(sendLoginConfirmationCodeFailed(error)))
            )
        )
    )
