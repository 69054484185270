import React, { PureComponent, ReactNode } from "react";

interface Props {
    day: string;
    time: string;
    remove: () => void;
}

export class OpeningHoursItem extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className={'opening-hours-item'}>
                <b>{this.props.day}</b>
                <p>{this.props.time}</p>
                <span className={'opening-hours-btn close-btn'} onClick={() => this.props.remove()}>
                    <i className="fa fa-times"/>
                </span>
            </div>
        )
    }
}
