import React from "react";
import { InstantHeader } from "../../../../domain/entites/instantHeader";
import { AgendaInstantItem } from "./agendaInstantItem";

interface Props {
    title: string;
    instants: InstantHeader[];
}

export const AgendaInstantHeader = (props: Props): JSX.Element => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-xl-8'}>
                <p className={'section_title'}>{props.title}</p>
                {props.instants.map(instant => <AgendaInstantItem instant={instant}
                                                                  key={instant.id}/>)}
            </div>
        </div>
    )
}
