import React, { PureComponent, ReactNode } from 'react'
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { i18n } from "../../../../configuration/i18n";
import './qrcode.css'
import { Company } from "../../../domain/entity/company";
import { QrcodeWeb } from "./components/qrcode.web";
import { QrcodeApp } from "./components/qrcode.app";
import { FoodMenuQrCode } from "./components/foodMenu.qrcode";
import { QrCodeReview } from "./components/qrcode.review";

interface Props {
    company: Company | null;
}

export class QrcodeContainer extends PureComponent<Props> {
    render(): ReactNode {

        return (
            <div className={'marketing-page'}>
                <DefaultPageHeader title={`${i18n.company.qrcode_title} ${this.props.company?.name}`}/>

                <div className="container-fluid custom-width-1260">
                    <QrcodeApp company={this.props.company}/>

                    {this.displayQRWeb() && <QrcodeWeb company={this.props.company}/>}

                    {this.shouldDisplayFoodMenuQrCode() && <FoodMenuQrCode company={this.props.company}/>}

                    {this.shouldDisplayReviewQrCode() && <QrCodeReview company={this.props.company}/>}
                </div>
            </div>
        )
    }

    displayQRWeb(): boolean {
        return this.props.company !== null && this.props.company.premiumSubdomain !== '' && this.props.company.membership.hasSubdomain
    }

    shouldDisplayFoodMenuQrCode(): boolean {
        return this.props.company?.themeConfiguration?.general.website !== undefined &&
            this.props.company?.themeConfiguration?.foodMenu.isActive === true
    }

    shouldDisplayReviewQrCode(): boolean {
        return this.props.company?.themeConfiguration?.general.website !== undefined &&
            this.props.company?.themeConfiguration?.review.isActive === true
    }

}
