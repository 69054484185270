import React, { ChangeEvent, PureComponent, ReactNode } from "react";

interface Props {
    checked: boolean
    label: string
    raiseUpdates: (isChecked: boolean) => void
    className?:string
}

interface State {
    isChecked: boolean
}

export class CheckboxInput extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isChecked: this.props.checked
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if(prevProps.checked !== this.props.checked)
            this.setState({isChecked: this.props.checked})
    }

    render(): ReactNode {
        const className= this.props.className ? this.props.className+' block-option' : 'block-option'
        return (
            <label className={className}>
                <input type="checkbox"
                       name="day-checkout"
                       className={'checked-checkbox'}
                       checked={this.state.isChecked}
                       onChange={(event) => this.handleInputChange(event)}
                />

                <span className="checkbox-tick"/>
                <span className="day-label">{this.props.label}</span>
            </label>
        )
    }


    handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
        const target = event.target;
        const value = target.checked
        this.setState({ isChecked: value }, () => {
            this.props.raiseUpdates(value)
        });
    }
}
