import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChatMessage } from '../../../domain/entities/chatMessage';
import { AppState } from "../../../../redux-configuration/AppState";
import { MessagingService } from "../../../domain/gateway/messaging.service";
import { LOAD_PREVIOUS_CHAT_MESSAGES, LoadPreviousChatMessagesAction } from "./actionTypes";
import { loadPreviousChatMessagesFailed, loadPreviousChatMessagesSucceeded } from "./actions";

const sortById = (chatMessages: ChatMessage[]): ChatMessage[] => chatMessages.sort(
    (item1: ChatMessage, item2: ChatMessage) => item1.id > item2.id ? -1 : 1)

export const loadPreviousChatMessagesEpic: Epic = (action$: ActionsObservable<LoadPreviousChatMessagesAction>, store: StateObservable<AppState>,
                                                   {messagingService}: { messagingService: MessagingService }) =>
    action$.pipe(
        ofType(LOAD_PREVIOUS_CHAT_MESSAGES),
        switchMap(
            action => messagingService.loadPreviousChatMessages(action.payload.conversationId, action.payload.messageId)
                .pipe(
                    map((chatMessages: ChatMessage[]) => loadPreviousChatMessagesSucceeded(sortById(chatMessages)))
                    , catchError(error => of(loadPreviousChatMessagesFailed(error)))
                )
        )
    )
