import { Action, applyMiddleware, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { AppState } from './AppState';
import { epicsMiddleware, rootEpics } from './epicsMiddleware.redux';
import { reduxReducer } from './reducers.redux';

const logger = createLogger({
    collapsed: true,
    level    : 'info',
});

export const reduxStore = (): Store<AppState> => {
    let store: Store;

    if (process.env.NODE_ENV !== 'production') {
        store = createStore<AppState, Action, {}, {}>(
            reduxReducer,
            applyMiddleware(epicsMiddleware, logger),
        );
    } else {
        store = createStore<AppState, Action, {}, {}>(
            reduxReducer,
            applyMiddleware(epicsMiddleware),
        );
    }
    epicsMiddleware.run(rootEpics);

    return store;
};
