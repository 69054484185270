import { SocialPageInfo } from "../../domain/entity/socialPageInfo";

export const FIND_FACEBOOK_PAGE = 'FIND_FACEBOOK_PAGE'
export const FIND_FACEBOOK_PAGE_FAILED = 'FIND_FACEBOOK_PAGE_FAILED'
export const FIND_FACEBOOK_PAGE_SUCCEEDED = 'FIND_FACEBOOK_PAGE_SUCCEEDED'

export interface FindFacebookPageAction {
    type: typeof FIND_FACEBOOK_PAGE
}

export interface FindFacebookPageFailedAction {
    type: typeof FIND_FACEBOOK_PAGE_FAILED
    payload: string
}

export interface FindFacebookPageSucceededAction {
    type: typeof FIND_FACEBOOK_PAGE_SUCCEEDED
    payload: SocialPageInfo[]
}

export type FindFacebookPageActionTypes = FindFacebookPageAction | FindFacebookPageFailedAction | FindFacebookPageSucceededAction
