import { AppState } from "../../../redux-configuration/AppState";
import { Company } from "../../domain/entity/company";

const superAdminIds = ['255e1a21-d47d-447e-8637-cf22e4827634', '715cd3f2-6f4f-4180-85f8-8b44366d51ae', 'f0361bce-1755-49ba-9b28-c3001fb766ed','1778ec80-3f70-49e4-925f-db27aea42f5b']

export const companySelector = (appState: AppState): Company | null => appState.company.company
export const isSuperAdminCompany = (appState: AppState): boolean => {
    if (appState.company.company)
        return superAdminIds.includes(appState.company.company.id)
    return false
}
