export const ManagementInstantInstantI18n = {
    instant_edition_page_title      : 'VivezICI | Modifier l\'instant',
    instant_list_page_title         : 'VivezICI | Mes instants',
    instant_creation_page_title     : 'VivezICI | Ajouter un service',
    offer_creation_page_title       : 'VivezICI | Ajouter une offre',
    agenda_creation_page_title      : 'VivezICI | Ajouter un événement ',
    edit_instant                    : 'Modifier l\'instant',
    basic_information               : 'Informations générales',
    basic_information_sub_title     : 'Titre et description',
    title                           : 'Titre*',
    short_description               : 'Description courte*',
    instant_picture                 : 'Photo de l\'instant*',
    location                        : 'Lieu',
    location_sub_title              : 'Adresse et coordonnées GPS',
    address                         : 'Adresse',
    street                          : 'Rue*',
    city                            : 'Ville*',
    zipCode                         : 'Code postal*',
    cultural                        : 'Instant culturel',
    sportive                        : 'Instant sportif',
    discovery                       : 'Instant découverte',
    musical                         : 'Instant musical',
    gourmand                        : 'Instant gourmand',
    festive                         : 'Instant festif',
    heart                           : 'Instant coup de coeur',
    info                            : 'Instant info',
    offer                           : 'Instant offre',
    gift                            : 'Instant cadeau',
    default                         : 'Par défaut',
    activationTime_sub_title        : 'Planifier la date et l\'heure',
    notifications                   : 'Notifications',
    notifications_subtitle          : 'Notifiez vos abonnés',
    active_days                     : 'Horaires',
    from                            : 'de',
    to                              : 'à',
    active_days_sub_title           : 'Planifier la date et l\'heure',
    long_description_too_long       : 'la description doit contenir entre 20 et 3000 caractères',
    choose_category                 : 'Type d\'instant*',
    notificationDateError           : 'Vous devez choisir une date dans le future (+5min)',
    time_placeholder                : '--:--',
    date_placeholder                : '--/--/----',
    send_notif_in_5_min             : 'Planifier dans 5min',
    notification_not_sended         : 'Vous pouvez prévenir tous vos abonnés de ce nouvel instant' +
        ' en leur envoyant une notification (une seule fois par instant pour la version gratuite).' +
        ' Nous vous conseillons de bien choisir le moment afin d\'éviter les désabonnements.\n' +
        'Indiquez ci-dessous le jour et l\'heure de votre notification : ',
    form_data_error                 : 'Merci de vérifier les données remplies',
    discard_changes                 : 'Souhaitez-vous quitter et perdre vos modifications?',
    stay                            : 'Rester',
    instant_photo                   : 'Photo de l\'instant',
    address_not_found               : 'Adresse introuvable',
    recurrent                       : 'Récurrent',
    fixed                           : 'Unique',
    preview_show_two_hours_before   : ' (affiché 2h avant)',
    save_time                       : 'Enregistrer',
    add_schedule                    : 'Ajouter une date',
    validation_date_error           : 'L\'heure d\'activation de l\'instant doit être dans la même journée',
    start_date_text                 : 'Date de début',
    end_date_text                   : 'Date de fin',
    date_format_error               : 'le format doit être :  JJ/MM/AAAA à hh:mm',
    error_empty_input               : 'Veuillez remplir tout les champs',
    end_date_error                  : 'L\'heure de fin doit être supérieure à l\'heure de début',
    starts                          : 'Début',
    ends                            : 'Fin',
    send_notification               : 'Notifier mes abonnés',
    notification_sended             : (notificationDate: string): string => `Vous avez déjà envoyé une notification à vos abonnés pour les prevenir de cet instant le ${notificationDate}.
Créez de nouveaux instants pour prévenir vos abonnés de votre actualité.`,
    premium_notification_sended     : (notificationDate: string, time: string): string => `Vous avez déjà envoyé une notification à vos abonnés pour les prevenir de cet instant le ${notificationDate}.
Vous pouvez envoyé la prochaine notification dans ${time}min.`,
    lastNotificationSended          : (notificationDate: string): string => `Dernière notification envoyé le ${notificationDate}`,
    over_sized_image_error          : 'La taille de l\'image choisie est trop grande',
    title_format_error              : 'Le titre doit comporter au moins 6 caractères',
    description_format_error        : 'La description doit comporter au moins 10 caractères',
    long_description_format_error   : 'Attention, un copier/coller peut ajouter des caractères invisibles',
    section_zero_error              : 'Veuillez vérifier la zone "Informations générales"',
    section_one_error               : 'Veuillez vérifier la zone "Adresse et coordonnées GPS"',
    section_two_error               : 'Veuillez vérifier la zone "Planifier la date et l\'heure"',
    section_three_error             : 'Veuillez vérifier la zone "Prévenir vos abonnés de l\'instant"',
    schedule_format_error           : 'Merci d\'ajouter un horaire à votre instant',
    wrong_time                      : 'Veuillez vérifier l\'horaire de votre instant',
    opening_hours_option            : 'Identique aux horaires d\'ouverture.',
    error_business_hours            : 'Vous n\'avez pas encore des heures d\'ouvertures enregistrés',
    empty_list                      : 'Vous n\'avez pas encore d\'instants',
    all_days                        : 'Tous les jours',
    monday_friday                   : 'Du Lundi au Vendredi',
    mondays_to_saturdays            : 'Du Lundi au Samedi',
    tuesdays_to_fridays             : 'Du Mardi au Vendredi',
    tuesdays_to_saturdays           : 'Du Mardi au Samedi',
    saturday_sunday                 : 'Samedi et Dimanche',
    mondays                         : 'Tous les Lundis',
    tuesdays                        : 'Tous les Mardis',
    wednesdays                      : 'Tous les Mercredis',
    thursdays                       : 'Tous les Jeudis',
    fridays                         : 'Tous les Vendredis',
    saturdays                       : 'Tous les Samedis',
    sundays                         : 'Tous les Dimanches',
    at                              : 'à',
    notification_date               : 'Date de notification',
    save_instant                    : 'Publier',
    add_service                     : 'Ajouter un service',
    my_instants                     : 'Mes instants',
    search                          : 'Chercher',
    cultural_description            : 'Ce type d\'instant concerne les événements culturels comme le cinéma, le théâtre, les musées, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville.',
    sportive_description            : 'Ce type d\'instant concerne les événements sportifs comme les compétitions, les démonstrations, les portes ouvertes d\'associations sportives, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville.',
    discovery_description           : 'Ce type d\'instant concerne les événements pédagogiques ou informatifs, comme les formations, les présentations, les visites guidées, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville.',
    musical_description             : 'Ce type d\'instant concerne les événements musicaux comme les concerts, les karaokés, les cours, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville.',
    gourmand_description            : 'Ce type d\'instant concerne les événements gustatifs ou culinaires comme les déjeuner, les dégustations, les formules, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h).',
    festive_description             : 'Ce type d\'instant concerne les événements festifs comme les soirées, les happy hours, les célébrations, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville.',
    heart_description               : 'Ce type d\'instant concerne les moments coups de coeur comme le dont du sang, les collectes, les distributions, les services de bénévolat, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville.',
    info_description                : 'Ce type d\'instant concerne les informations temps réelles de la ville comme les marchés, les travaux, renseignements, alertes météos, etc. Il peut être récurrent (tous les mercredi à 10h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville.',
    offer_description               : 'Ce type d\'instant concerne les moments promotionnels, comme les offres commerciales, les soldes, les ventes privées, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h).',
    gift_description                : 'Ce type d\'instant concerne les offres commerciales proposées uniquement aux utilisateurs de l\'application mobile, comme les réductions, les cadeaux, les gratuités, etc. Il peut être récurrent (tous les mardi à 18h) ou bien fixe (le 17/03 à 20h) ; s\'il est fixe, il apparaitra dans l\'agenda de la ville. Ce sont des offres exclusives mises en avant dans l\'application mobile pour inviter les utilisateurs à découvrir de nouveaux commerces.',
    description_instants_commerce   : '<p>Les <b>instants</b> sont des moments pendant lesquels vous proposez un service, ils peuvent être:<li class="pl-4"><b>récurrents</b> (du lundi au vendredi de 12h à 14h)</li><li class="pl-4"><b>uniques</b> (le mercredi 10 janvier de 20h à 22h).</li></p>' +
        '<p>En tant que <b>Commerçant</b>, ajoutez vos offres de services (instants récurrents) de type Happy Hours, Promotions, Formule midi, Prestations, Produits, etc.<br>' +
        'Ou bien vos événements (instants uniques) de type Concerts, Expositions, Jeux, Inaugurations, Information, etc.</p>',
    description_instants_entreprise : '<p>Les <b>instants</b> sont des moments pendant lesquels vous proposez un service, ils peuvent être:<li class="pl-4"><b>récurrents</b> (du lundi au vendredi de 12h à 14h)</li><li class="pl-4"><b>uniques</b> (le mercredi 10 janvier de 20h à 22h).</li></p>' +
        '<p>En tant qu’<b>Entreprise</b>, ajoutez vos offres de services (instants récurrents) de type Prestations, Produits, offres d’emplois, etc.<br>' +
        'Ou bien vos événements (instants uniques) de type Journées portes ouvertes, Réunions, Conférences, etc.</p>',
    description_instants_mairie     : '<p>Les <b>instants</b> sont des moments pendant lesquels vous proposez un service, ils peuvent être:<li class="pl-4"><b>récurrents</b> (du lundi au vendredi de 12h à 14h)</li><li class="pl-4"><b>uniques</b> (le mercredi 10 janvier de 20h à 22h).</li></p>' +
        '<p>En tant que <b>Mairie</b>, ajoutez vos offres de services (instants récurrents) de type Jours du marché, Prestations, Vacances scolaires, Offres d’emplois, etc.<br>' +
        'Ou bien vos événements (instants uniques) de type Concerts, Feux d’artifices, Expositions culturelles, Forum des associations, Informations, Alertes, Travaux, Couvre-feu, infos pratiques, Inaugurations, Bureaux de votes, etc.</p>',
    description_instants_association: '<p>Les <b>instants</b> sont des moments pendant lesquels vous proposez un service, ils peuvent être:<li class="pl-4"><b>récurrents</b> (du lundi au vendredi de 12h à 14h)</li><li class="pl-4"><b>uniques</b> (le mercredi 10 janvier de 20h à 22h).</li></p>' +
        '<p>En tant qu’<b>Association</b>, ajoutez vos offres de services (instants récurrents) de type Entraînements sportifs, Cours, Permanences, Réunions régulières, etc.<br>' +
        'Ou bien vos événements (instants uniques) de type Journées portes ouvertes, Compétitions, Assemblées générales, Événements etc.</p>',
    you_want_to_send_notification   : 'Souhaitez-vous envoyer une notification à vos abonnés ?',
    notification_description        : 'OUI : la notification partira dans 5mn.<br/>' +
        'NON : votre instant sera publié sans envoyer de notifications.<br/>' +
        'FERMER la fenêtre pour revenir au formulaire.',
    yes                             : 'Oui',
    no                              : 'Non',
    add_offer_instant               : 'Ajouter une offre',
    add_agenda_instant              : 'Ajouter un événement  ',
    instant_membership_error        : 'Vous avez atteint le nombre maximum d\'instants que vous pouvez créer',
    events                          : 'Événements',
    services                        : 'Services',
    special_offer                   : 'Offres spéciales',
    description_events              : 'Gagnez en visibilité en ajoutant des « événements » qui apparaîtront dans l\'agenda de la ville parmi ceux de la mairie, des associations et des commerces : concerts, expositions, jeux, inaugurations, compétitions, journées portes ouvertes, etc.',
    description_services            : 'Présentez votre activité en ajoutant vos offres de « services » décrivant en détail ce que vous proposez et à quel prix : cela vous permet de donner envie aux habitants de découvrir votre commerce. Par exemple : happy hours, formule déjeuner, soin visage, développement photos, shampoing coupe, cours de danses, etc.',
    description_special_offers      : 'Attirez de nouveaux clients en ajoutant une « offre spéciale » pour les habitants de votre ville : c\'est un excellent moyen de faire découvrir vos services et de générer du trafic dans votre établissement. Par exemple : un apéritif offert pour un repas pris sur place, -50% pour le deuxième produit acheté, 15% de réductions aux abonnés de votre compte, une bouteille offerte pour une table de 4 couverts, etc.',
    ok                              : 'OK',
    you_want_to_share               : (name: string): string => `Souhaitez-vous partager l'instant : ${name}`,
    facebook                        : 'Facebook',
    share_facebook                  : 'Publier sur ma page Facebook',
    share_instagram                 : 'Publier sur ma page Instagram',
    description_share               : 'Pour poster un instant sur Facebook, vous devez avoir \n' +
        'une page Facebook associée à votre compte personnel.',
    page_facebook_not_found         : 'Vous n\'avez pas encore une page Facebook associé a votre compte',
    update_post_text                : 'Modifier le text de votre post Facebook',
    not_connected_facebook          : 'Merci de vérifier votre connexion à Facebook',
    instagram                       : 'Instagram',
    login_facebook                  : 'Connexion via Facebook',
    login_instagram                 : 'Connexion via Instagram',
    create_post_facebook            : 'Création d\'un post Facebook',
    dont_have_facebook_page         : 'Vous n\'avez pas de page facebook pour poster vos instant',
    dont_have_instagram_account     : 'Vous n\'avez pas de compte instagram business pour créer des postes',
    description_share_instagram     : 'Pour poster un instant sur Instagram, vous devez avoir \n' +
        'un compte Instagram « Business » associé à une page Facebook.',
    create_post_instagram           : 'Création d\'un post Instagram ',
    instagram_account_not_found     : 'Vous n\'avez pas encore un compte instagram associé a votre compte',
    not_connected_instagram         : 'Merci de vérifier vos identifiants',

}
