import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    error: boolean
    value: string | number | undefined
    raiseUpdates: (fileName: string) => void
}

export class CompanySubdomainInput extends PureComponent<Props> {
    render(): ReactNode {
        const className = this.props.error ? 'error w-auto' : 'w-auto'

        return (
            <div className="wizard-form-input">
                <label>{i18n.company.subdomain}</label>

                <div className="subdomain-input d-flex align-items-center">
                    <input type="text"
                           value={this.props.value ?? ''}
                           className={className}
                           onChange={(event) => this.props.raiseUpdates(event.target.value)}/>
                    <span>.vivezici.com</span>
                </div>
            </div>
        )
    }
}
