export class ProductGalleryImage {

    constructor(
        private _id: string,
        private _url: string
    ) {
    }

    get id(): string {
        return this._id
    }

    get url(): string {
        return this._url
    }

}
