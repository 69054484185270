import { CompanyDto } from "../dto/company.dto";
import { Company } from "../../../../domain/entity/company";
import { CompanyBuilder } from "../../../../domain/builder/company.builder";
import { OpeningHoursType } from "../../../../../common/domain/types/openingHours.types";
import { ClickCollectPicture } from "../../../../domain/types/clickCollectPicture";
import { CompanyMembership } from "../../../../domain/entity/companyMembership";
import { FollowerDTO } from "../dto/follower.dto";
import { Follower } from "../../../../domain/entity/follower";
import { FollowerBuilder } from "../../../../domain/builder/follower.builder";
import moment from "moment";
import { CompanyLegalInfo } from "../../../../domain/entity/companyLegalInfo";
import { ThemeConfiguration } from "../../../../domain/entity/ThemeConfiguration";
import { ThemeConfigurationBuilder } from "../../../../domain/builder/themeConfiguration.builder";
import { ThemeDetails } from "../../../../domain/entity/ThemeDetails";
import { ThemeBlock } from "../../../../domain/entity/ThemeBlock";
import { ThemeConfigurationDTO } from "../dto/themeConfiguration.dto";
import { AppCategories, AssociationCategories } from "../../../../../common/domain/types/app.categories";
import { ThemeSocialMedia } from "../../../../domain/entity/ThemeSocialMedia";
import { ThemeWelcomeBlock } from "../../../../domain/entity/ThemeWelcomeBlock";
import { ThemeFoodMenu } from "../../../../domain/entity/ThemeFoodMenu";
import { ThemeEcommerce } from "../../../../domain/entity/ThemeEcommerce";

export class CompanyMapper {

    static mapCompanyDTO(company: CompanyDto): Company {
        const openingHours: OpeningHoursType [] = []
        if (company.openHours.length > 0)
            company.openHours.map(item => openingHours.push({
                    id       : item.id,
                    daytype  : item.type,
                    startTime: item.startTime,
                    endTime  : item.endTime,
                    isPaused : item.enabled === 'false'
                })
            )

        const coverImagePieces = company.coverImage.split('/');
        const profileImagePieces = company.profileImage.split('/');
        const coverImage = new File([company.coverImage], coverImagePieces[coverImagePieces.length - 1], {type: "image/jpeg"})
        const profileImage = new File([company.profileImage], profileImagePieces[profileImagePieces.length - 1], {type: "image/jpeg"})

        const clickCollectPictures: ClickCollectPicture[] = []

        if (company.cacImagesPath && company.cacImagesPath.length > 0)
            company.cacImagesPath.map(picture => {
                clickCollectPictures.push({
                    id        : picture.rank,
                    pictureUrl: picture.url,
                    picture   : undefined
                })
            })

        const description = company.description ?
            company.description.replaceAll('<b>', '<strong>')
                .replaceAll('</b>', '</strong>')
                .replaceAll('<i>', '<em>')
                .replaceAll('</i>', '</em>') : '';

        const companyDetails = new CompanyBuilder()
            .withId(company.companyId)
            .withSellerId(company.sellerId)
            .withName(company.name)
            .withMembership(new CompanyMembership(company.subscription.membership,
                company.subscription.allowedInstant,
                company.subscription.allowedConversation,
                company.subscription.allowedNotificationPerInstant,
                company.subscription.allowedBroadcast,
                company.subscription.hasSubdomain,
                company.subscription.hasClickAndCollect))
            .withType(company.type)
            .withCategory(company.category)
            .withAddress(company.address)
            .withZipCode(company.zipCode)
            .withCity(company.city)
            .withLongitude(company.longitude)
            .withLatitude(company.latitude)
            .withCoverImage(coverImage)
            .withProfileImage(profileImage)
            .withCoverImageUrl(company.coverImage)
            .withProfileImageUrl(company.profileImage)
            .withDescription(description)
            .withWebsite(company.website)
            .withEmail(company.email)
            .withPhoneNumber(company.phoneNumber)
            .withFollowersCount(company.followersCount)
            .withOpeningHours(openingHours)
            .withPremiumSubdomain(company.premiumSubdomain)
            .withActiveClickCollect(company.cacActive)
            .withDescriptionClickCollect(company.cacDescription)
            .withPicturesClickCollect(clickCollectPictures)
            .withSiren(company.administrativeNumber)

        if (company.legalInformation) {
            const legalInfo = new CompanyLegalInfo(
                company.legalInformation.companyName,
                company.legalInformation.registeredName,
                company.legalInformation.legalStatus,
                company.legalInformation.capitalAmount,
                company.legalInformation.address,
                company.legalInformation.phoneNumber,
                company.legalInformation.email,
                company.legalInformation.siret,
                company.legalInformation.tva,
                company.legalInformation.others
            )
            companyDetails.withLegalInfo(legalInfo)
        }

        const domain = company.domain ?? "";
        if (company.themeConfiguration) {
            const themeConfiguration = this.mapToThemeConfiguration(company.themeConfiguration, domain, companyDetails.build())
            companyDetails.withThemeConfiguration(themeConfiguration)
        } else if (company.membership === 'digital') { // Default theme configuration
            const themeConfiguration = this.mapToDefaultThemeConfiguration(companyDetails.build(), domain)
            companyDetails.withThemeConfiguration(themeConfiguration)
        }

        return companyDetails.build()
    }

    static mapToArrayFollower(followersDto: FollowerDTO[]): Follower[] {
        return followersDto.map(item => new FollowerBuilder()
            .withLastName(item.last_name)
            .withFirstName(item.first_name)
            .withPhoneNumber(item.phone_number)
            .withCity(item.city)
            .withZipCode(parseInt(item.zip_code))
            .withImageURL(item.image_url)
            .withFollowDate(item.follow_date !== null ? moment(item.follow_date.date).format('DD-MM-YYYY') : '')
            .build())
    }

    private static mapToDefaultThemeConfiguration(company: Company, domain: string): ThemeConfiguration {
        const category = company.type === 'association' ? AssociationCategories[company.category] : AppCategories[company.category]
        const clickCollect = company.clickCollect.active ? new ThemeBlock(
            true,
            company.clickCollect.description,
            ThemeConfiguration.TITLE_COLOR,
            ThemeConfiguration.TEXT_COLOR,
            []
        ) : undefined

        const settings = new ThemeDetails(
            company.name + " - " + category + " à " + company.address.city,
            domain,
            ThemeConfiguration.PRIMARY_COLOR,
            ThemeConfiguration.SECONDARY_COLOR,
            ThemeConfiguration.PRIMARY_FONT,
            ThemeConfiguration.SECONDARY_FONT
        )
        const header = new ThemeBlock(
            true,
            company.name,
            ThemeConfiguration.TEXT_COLOR,
            ThemeConfiguration.PRIMARY_COLOR,
            [company.profileImageUrl]
        )

        const footer = new ThemeBlock(
            true,
            company.name,
            ThemeConfiguration.TEXT_COLOR,
            ThemeConfiguration.PRIMARY_COLOR,
            [company.profileImageUrl]
        )

        const welcome = new ThemeWelcomeBlock(
            category + " à " + company.address.city,
            'À propos',
            '#about',
            '',
            false,
            ThemeWelcomeBlock.BACKGROUND_COLOR
        )

        const about = new ThemeBlock(
            true,
            '',
            ThemeConfiguration.TEXT_COLOR,
            ThemeConfiguration.PRIMARY_COLOR,
            [],
            ThemeConfiguration.SECONDARY_COLOR
        )

        const gallery = new ThemeBlock(
            false,
            "Les photos de notre " + category,
            ThemeConfiguration.TITLE_COLOR,
            ThemeConfiguration.BACKGROUND_COLOR,
            []
        )

        const instants = new ThemeBlock(
            true,
            "Découvrez toutes nos offres",
            ThemeConfiguration.TITLE_COLOR,
            ThemeConfiguration.TEXT_COLOR,
            [],
            '',
            ThemeConfiguration.PRIMARY_COLOR
        )

        const address = new ThemeBlock(
            true,
            "Rendez-nous visite dans notre " + category,
            ThemeConfiguration.TITLE_COLOR,
            ThemeConfiguration.BACKGROUND_COLOR,
            [],
            '',
            ThemeConfiguration.PRIMARY_COLOR
        )

        const review = new ThemeBlock(
            false,
            "Les avis de nos clients",
            ThemeConfiguration.TITLE_COLOR,
            ThemeConfiguration.TEXT_COLOR,
            []
        )

        const contact = new ThemeBlock(
            true,
            "Besoin d’un conseil, envoyez-nous un message",
            ThemeConfiguration.TITLE_COLOR,
            ThemeConfiguration.TEXT_COLOR,
            []
        )

        const socialMedia = new ThemeSocialMedia('', '', '', '')
        const themeFoodMenu = new ThemeFoodMenu(
            false,
            'Nos Cartes',
            'Voir la selection',
            'très variées',
            ThemeConfiguration.BACKGROUND_COLOR,
            false,
            []
        )

        const themeEcommerce = new ThemeEcommerce(
            false,
            'Boutique',
            'Boutique',
            'Découvrez tous nos produits',
            company.coverImageUrl,
            'default',
            [],
            '',
            'shop'
        );

        const themeConfigurationBuilder = new ThemeConfigurationBuilder()
            .withGeneral(settings)
            .withHeader(header)
            .withFooter(footer)
            .withWelcome(welcome)
            .withAbout(about)
            .withGallery(gallery)
            .withInstants(instants)
            .withAddress(address)
            .withReview(review)
            .withContact(contact)
            .withSocialMedia(socialMedia)
            .withFoodMenu(themeFoodMenu)
            .withEcommerce(themeEcommerce)

        if (clickCollect) {
            themeConfigurationBuilder.withClickCollect(clickCollect)
        }

        return themeConfigurationBuilder.build()
    }

    private static mapToThemeConfiguration(theme: ThemeConfigurationDTO, domain: string, company: Company): ThemeConfiguration {
        const settings = new ThemeDetails(
            theme.general.title,
            domain,
            theme.general.primary_color,
            theme.general.secondary_color,
            theme.general.primary_font,
            theme.general.secondary_font,
            theme.general.place_id
        )
        const header = new ThemeBlock(
            true,
            theme.header.title,
            theme.header.text_color,
            theme.header.background_color,
            theme.header.images ?? []
        )

        const footer = new ThemeBlock(
            true,
            theme.footer.title,
            theme.footer.text_color,
            theme.footer.background_color,
            theme.footer.images ?? []
        )

        const welcome = new ThemeWelcomeBlock(
            theme.welcome.sub_title,
            theme.welcome.button_label ?? '',
            this.removeTargetParameter(theme.welcome.button_url),
            theme.welcome.video_url ?? '',
            this.hasTargetParameter(theme.welcome.button_url),
            theme.welcome.background_color ?? ThemeWelcomeBlock.BACKGROUND_COLOR
        )

        const about = new ThemeBlock(
            true,
            '',
            theme.about.text_color,
            theme.about.background_color,
            [],
            theme.about.title_color ?? theme.general.secondary_color
        )

        const gallery = new ThemeBlock(
            theme.gallery.active,
            theme.gallery.sub_title,
            ThemeConfiguration.TITLE_COLOR,
            theme.gallery.background_color,
            theme.gallery.images
        )

        const instants = new ThemeBlock(
            theme.instants.active,
            theme.instants.sub_title,
            ThemeConfiguration.TITLE_COLOR,
            theme.instants.background_color,
            [],
            '',
            theme.instants.item_color ?? theme.general.primary_color
        )

        const address = new ThemeBlock(
            theme.address.active,
            theme.address.sub_title,
            ThemeConfiguration.TITLE_COLOR,
            theme.address.background_color,
            theme.address.images,
            '',
            theme.address.item_color ?? theme.general.primary_color
        )

        const review = new ThemeBlock(
            theme.review.active,
            theme.review.sub_title,
            ThemeConfiguration.TITLE_COLOR,
            theme.review.background_color,
            []
        )

        const contact = new ThemeBlock(
            theme.contact.active,
            theme.contact.sub_title,
            ThemeConfiguration.TITLE_COLOR,
            theme.contact.background_color,
            []
        )

        const socialMedia = new ThemeSocialMedia(
            theme.social_media?.facebook ?? '',
            theme.social_media?.instagram ?? '',
            theme.social_media?.tiktok ?? '',
            theme.social_media?.linkedin ?? ''
        )

        const clickCollect = new ThemeBlock(
            theme.click_collect?.active ?? false,
            theme.click_collect?.sub_title ?? '',
            ThemeConfiguration.TITLE_COLOR,
            theme.click_collect?.background_color ?? ThemeConfiguration.TEXT_COLOR,
            []
        )

        const themeFoodMenu = new ThemeFoodMenu(
            theme.food_menu?.active ?? false,
            theme.food_menu?.header_title ?? 'Menu',
            theme.food_menu?.title ?? 'Notre menu',
            theme.food_menu?.sub_title ?? 'Découvrez tous nos menus',
            theme.food_menu?.background_color ?? ThemeConfiguration.BACKGROUND_COLOR,
            theme.food_menu?.display_as_icon ?? false,
            theme.food_menu?.tabs ? JSON.parse(theme.food_menu?.tabs) : []
        )

        const ecommerce = new ThemeEcommerce(
            theme.ecommerce?.active ?? false,
            theme.ecommerce?.header_title ?? 'Boutique',
            theme.ecommerce?.title ?? 'Boutique',
            theme.ecommerce?.sub_title ?? 'Découvrez tous nos produits',
            theme.ecommerce?.background_image ?? company.coverImageUrl,
            theme.ecommerce?.type ?? 'default',
            theme.ecommerce?.payment_options ? JSON.parse(String(theme.ecommerce.payment_options)) : [],
            theme.ecommerce?.document_url ?? '',
            theme.ecommerce?.navigate_to ?? 'shop'
        );

        return new ThemeConfigurationBuilder()
            .withGeneral(settings)
            .withHeader(header)
            .withFooter(footer)
            .withWelcome(welcome)
            .withAbout(about)
            .withGallery(gallery)
            .withInstants(instants)
            .withAddress(address)
            .withReview(review)
            .withContact(contact)
            .withSocialMedia(socialMedia)
            .withClickCollect(clickCollect)
            .withFoodMenu(themeFoodMenu)
            .withEcommerce(ecommerce)
            .build()
    }

    static hasTargetParameter(url?: string): boolean {
        if (!url) {
            return false;
        }
        try {
            const urlObj = new URL(url);
            const searchParams = urlObj.searchParams;
            return searchParams.has('target') || url.includes('?target=') || url.includes('&target=');
        } catch (e) {
            return false
        }
    }

    static removeTargetParameter(url?: string): string {
        if (!url) {
            return '';
        }
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.delete('target');
            return urlObj.toString();
        } catch (e) {
            return url
        }
    }
}
