import React from "react";
import { i18n } from "../../../../../configuration/i18n";

const PrivacyAndCGU = (props: { type: string; }): JSX.Element => {

    const cgu = 'https://vivezici.fr/conditions-generales-dutilisation/'
    const privacy = 'https://vivezici.fr/politique-de-confidentialite-vivezparis/'

    return (
        <div className={'cgu'}>
            <p>
                {i18n.registration.i_accept_the}
                <a href={privacy} target="_blank" rel="noopener noreferrer">{i18n.registration.privacy}</a>
                {i18n.registration.and}
                <a href={cgu} target="_blank"
                   rel="noopener noreferrer">{props.type === 'mairie' ? i18n.registration.cgu_mairie : i18n.registration.cgu}</a>
                {i18n.registration.of_vivez_clichy}
            </p>
        </div>
    )
}

export default PrivacyAndCGU;
