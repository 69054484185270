import { ChatUser } from './chatUser';

export class ChatMessage {
    constructor(
        private _id: number,
        private _content: string,
        private _createdAt: Date,
        private _user: ChatUser,
        private _image: string | undefined = undefined
    ) {
    }

    get id(): number {
        return this._id;
    }

    get content(): string {
        return this._content;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get user(): ChatUser {
        return this._user;
    }

    get image(): string | undefined {
        return  this._image
    }
}
