import React from "react";
import { ThemeEcommercePaymentOptions } from "../../types";
import { ThemeCheckboxInput } from "../checkbox.input";

interface Props {
    values: string[];
    onChange: (value: string[]) => void;
}

export const EcommercePaymentOptions = (props: Props): JSX.Element => {
    return (
        <div className={'wizard-form-input radio-buttons align-items-start'}>
            <label className="pt-2">Options de paiement</label>

            <div className="food-menu-checkbox">
                {ThemeEcommercePaymentOptions.map((option) => (
                    <ThemeCheckboxInput label={option.label}
                                        key={option.value}
                                        checked={!!props.values.find((element) => element === option.value)}
                                        onChange={(isChecked: boolean) => {
                                            if (!isChecked) {
                                                const array: string[] = [...props.values]
                                                const index = array.findIndex(item => item === option.value)
                                                if (index > -1) {
                                                    array.splice(index, 1);
                                                    props.onChange(array)
                                                }
                                            } else {
                                                props.onChange([...props.values, option.value])
                                            }
                                        }}
                    />
                ))}
            </div>
        </div>
    )
}
