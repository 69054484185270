export const AuthenticationI18n = {
    login_your_account      : 'Authentification',
    authenticate            : 'Authentifiez vous pour acceder à votre compte pro',
    phoneNumber             : 'Téléphone portable',
    add_sms_code            : 'Veuillez saisir le code que vous venez de recevoir par SMS',
    confirmationCode        : 'Code de confirmation',
    next                    : 'Suivant',
    back                    : 'Retour',
    no_company_found        : 'Compte pro non trouvé',
    are_u_sure              : 'Aucun compte n\'est associé avec votre numéro de téléphone',
    have_no_account         : 'Vous n\'avez pas de compte ? Vous pouvez le créer un par ',
    create_pro_account      : 'Vous pouvez créer votre compte pro par ',
    here                    : 'ici',
    empty_code_error        : 'Veuillez saisir le code de confirmation',
    code_error              : 'Veuillez Vérifier votre code de confirmation',
    confirmation            : 'Validation utilisateur',
    empty_form_error        : 'Veuillez saisir votre numéro de téléphone',
    required_data_error     : 'Les champs avec un (*) sont obligatoires',
    inscription_info        : 'Ces informations vous permettront de vous connecter sur l\'application mobile et de gérer votre compte depuis votre téléphone portable.',
    phone_number_error      : 'Veuillez entrer un numéro de téléphone valide',
    contact_vivez_clichy    : 'Vous avez effectué trop de tentatives, pour créer votre compte, contactez directement VIVEZ ICI contact@vivezici.fr',
    server_error            : 'Une erreur serveur est survenu. Si vous rencontrez des difficultés à créer votre compte pro, veuillez nous contacter par email à contact@vivezici.fr',
    verify_your_phone_number: 'Ce numéro du téléphone n\'est pas inscrit, veuillez vérifier votre numéro du téléphone',
    enter_valid_code        : 'Code erroné : veuillez entrer le code reçu par SMS',
    page_title              : 'Authentification',
    success_login           : 'Authentifié avec succès',
    redirect                : 'Vous serez rediréger dans un instant...',
    text_footer             : 'Un souci ? Prenez RDV avec nous !'
}
