import {
    VALIDATE_PAYMENT,
    VALIDATE_PAYMENT_FAILED,
    VALIDATE_PAYMENT_SUCCEEDED, ValidatePaymentAction, ValidatePaymentActionTypes,
} from "./actionTypes";
import { PaymentStatus } from "../../domain/entities/types/subscription.type";

export const validatePayment = (sessionId: string, companyId: string): ValidatePaymentAction => ({
    type   : VALIDATE_PAYMENT,
    payload: {sessionId, companyId}
})

export const validatePaymentSucceeded = (status: PaymentStatus): ValidatePaymentActionTypes => ({
    type   : VALIDATE_PAYMENT_SUCCEEDED,
    payload: status
})

export const validatePaymentFailed = (error: string): ValidatePaymentActionTypes => ({
    type   : VALIDATE_PAYMENT_FAILED,
    payload: error
})
