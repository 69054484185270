import { ChatUser } from '../entities/chatUser';

export class ChatUserBuilder {
    protected _id: string
    protected _avatar: string
    protected _name: string
    protected _subtitle?: string

    withId(value: string): ChatUserBuilder {
        this._id = value
        return this
    }

    withAvatar(value: string): ChatUserBuilder {
        this._avatar = value
        return this
    }

    withName(value: string): ChatUserBuilder {
        this._name = value
        return this
    }

    withSubTitle(value: string): ChatUserBuilder {
        this._subtitle = value
        return this
    }

    build(): ChatUser {
        return new ChatUser(this._id, this._avatar, this._name, this._subtitle)
    }
}
