export const RegistrationI18n = {
    create_your_account                 : 'Création de votre compte utilisateur',
    fillProfessionalInfo                : 'Veuillez saisir vos informations professionnelles',
    add_sms_code                        : 'Veuillez saisir le code que vous venez de recevoir par SMS',
    sms_confirm                         : 'Confirmation par SMS',
    confirmationCode                    : 'Code de confirmation',
    association                         : 'Association',
    company                             : 'Entreprise',
    commerce                            : 'Commerce',
    mairie                              : 'Mairie',
    siren                               : 'Siren',
    siret                               : 'Siret *',
    rna                                 : 'RNA',
    type                                : 'Type',
    next                                : 'Suivant',
    back                                : 'Retour',
    submit                              : 'Valider',
    add                                 : 'Ajouter',
    firstName                           : 'Prénom *',
    lastName                            : 'Nom *',
    birthday                            : 'Date de naissance *',
    address                             : 'Adresse',
    phoneNumber                         : 'Téléphone portable *',
    cin_recto                           : 'Pièce d’identité du gérant - Recto *',
    cin_verso                           : 'Pièce d’identité du gérant - Verso *',
    personal_info                       : 'Compte utilisateur',
    confirmation                        : 'Validation utilisateur',
    cin_and_documents                   : 'Création compte pro',
    professional_info                   : (type: string): string => 'Référencement du compte ' + type,
    review                              : (type: string): string => 'Validation du compte ' + type,
    professional_info_title             : (type: string): string => 'Référencement de votre compte ' + type,
    review_title                        : 'Félicitations, votre inscription est terminée !',
    create_pro_account                  : 'Créez gratuitement votre compte pro',
    create_mairie_account               : 'Créez gratuitement le compte de votre mairie',
    create_mairie_association           : 'Créez gratuitement le compte de votre association ',
    image_placeholder                   : 'Choisir une image',
    empty_form_error                    : 'Veuillez remplir tous les champs',
    empty_siret_error                   : 'Veuillez saisir votre numéro SIRET',
    empty_rna_error                     : 'Veuillez saisir votre numéro RNA',
    required_data_error                 : 'Les champs avec un (*) sont obligatoires',
    empty_code_error                    : 'Veuillez saisir le code de confirmation',
    code_error                          : 'Veuillez Vérifier votre code de confirmation',
    business_name_format_error          : 'Nom de l\'établissement doit comporter au moins 3 caractères.',
    description_format_error            : 'Description dot comporter au moins 5 caractères.',
    click_collect_description_error     : 'La phrase d\'accroche de service Click & Collect doit comporter au moins 10 caractères.',
    address_format_error                : 'Adresse doit comporter au moins 6 caractères.',
    city_format_error                   : 'Description doit comporter au moins 4 caractères.',
    zipCode_format_error                : 'Veuillez entrer un code postal valide.',
    site_format_error                   : 'Veuillez entrer un site web valide.',
    email_format_error                  : 'Veuillez entrer un email valide.',
    name_format_error                   : 'Votre nom et prénom doivent comporter au moins 2 caractères. ' +
        'Les caractères spéciaux, les chiffres et la ponctuation ne sont pas autorisés',
    birthday_error                      : 'Vous devez être majeur pour vous inscrire.',
    phone_number_error                  : 'Veuillez entrer un numéro de téléphone valide',
    update_business_description         : (type: string): string => `Complétez maintenant le profil de votre ${type} afin de ${(type === 'commerce' || type === 'compte pro') ? 'le' : 'la'} référencer dans l'application`,
    register_business_description       : 'Pour créer un compte PRO, vous devez être le gérant et disposer du n° SIRET/RNA : ' +
        'votre identité sera vérifiée par la suite par l’équipe VIVEZ ICI.',
    register_mairie_description         : 'Pour créer le compte PRO de votre mairie, vous devez faire partie de la mairie et disposer du numéro de SIRET : ' +
        'votre identité sera vérifiée par la suite par l’équipe VIVEZ ICI.',
    register_association_description    : 'Pour créer le compte Pro de votre association, vous devez faire partie du bureau et disposer du numéro RNA ou du SIRET : votre identité sera vérifiée par la suite par l’équipe VIVEZ ICI.',
    server_error                        : 'Une erreur serveur est survenu. ' +
        'Si vous rencontrez des difficultés à créer votre compte pro, veuillez nous contacter par email à <a href="mailto:contact@vivezici.fr">contact@vivezici.fr</a>',
    invalid_siret                       : 'Veuillez vérifier votre numéro SIRET. ' +
        'Si vous rencontrez des difficultés à créer votre compte pro, veuillez nous contacter par email à <a href="mailto:contact@vivezici.fr">contact@vivezici.fr</a>',
    invalid_rna                         : 'Veuillez vérifier votre numéro RNA. ' +
        'Si vous rencontrez des difficultés à créer votre compte pro, veuillez nous contacter par email à <a href="mailto:contact@vivezici.fr">contact@vivezici.fr</a>',
    siren_already_used                  : (phone?: string): string => `Ce numéro SIRET est déjà utilisé. Si c'est par vous,<a href="/login${phone ? '?phone=' + phone : ''}"><strong> connectez-vous ici</strong></a>.`,
    rna_already_used                    : (phone?: string): string => `Ce numéro RNA est déjà utilisé.  Si c'est par vous,<a href="/login${phone ? '?phone=' + phone : ''}"><strong> connectez-vous ici</strong></a>. `,
    contact_vivezici                    : '<br/>Si vous rencontrez des difficultés à créer votre compte pro, veuillez nous contacter par email à <a href="mailto:contact@vivezici.fr">contact@vivezici.fr</a> ',
    over_sized_image_error              : 'La taille de l\'image choisie est trop grande',
    data_format_error                   : 'Merci de vérifier les données remplies',
    success_subscription                : 'Inscription réussie!',
    for_more_details                    : 'Choisissez maintenant l\'offre qui vous plait avec un paiement mensuel ou annuel:',
    contact_vivez_clichy                : 'Vous avez effectué trop de tentatives, pour créer votre compte, ' +
        'contactez directement VIVEZ ICI <a href="mailto:contact@vivezici.fr">contact@vivezici.fr</a>',
    enter_valid_code                    : 'Code erroné : veuillez entrer le code reçu par SMS',
    verify_your_phone_number            : 'Ce numéro du téléphone n\'est pas inscrit, veuillez vérifier votre numéro du téléphone',
    i_accept_the                        : 'En créant mon compte PRO, j\'accepte les ',
    cgu                                 : 'Conditions Générales de Vente',
    cgu_mairie                          : 'Conditions Générales d\'utilisation',
    and                                 : ' et les ',
    privacy                             : 'Politiques de Confidentialité',
    of_vivez_clichy                     : ' de VIVEZ ICI.',
    inscription_info                    : 'Ces informations vous permettront de vous connecter sur le back office ou sur l\'application mobile pour gérer votre compte pro.',
    already_has_business                : 'Vous avez déjà un compte pro associé à votre compte utilisateur, veuillez vous connecter sur l\'application mobile si vous souhaitez le mettre à jour.',
    already_have_account                : 'Avez-vous déjà un compte utilisateur sur l\'application mobile ?',
    yes                                 : 'Oui',
    no                                  : 'Non',
    title_commerce                      : 'Création de votre compte « Commerçant »',
    title_association                   : 'Création de votre compte « Association »',
    title_company                       : 'Création de votre compte « Entreprise »',
    title_mairie                        : 'Création de votre compte « Mairie »',
    title_business                      : 'Création de votre compte « Pro »',
    footer_text                         : 'Contactez-nous si vous avez besoin d\'assistance.',
    city                                : 'Ville d’habitation *',
    search_city                         : 'Chercher votre ville',
    no_city_found                       : 'Aucune ville ne correspond à vos critères de recherche',
    show_profile_pro                    : 'Voir mon compte pro',
    address_error                       : 'L\'adresse n\'est pas correcte',
    address_zip_code_error              : 'Merci d\'ajouter un code postal',
    address_city_error                  : 'Merci de choisir une ville dans la liste déroulante',
    creation_compte                     : (type: string): string => 'Création du compte ' + type,
    creation_compte_title               : (type: string): string => 'Création de votre compte ' + type,
    email                               : 'Email *',
    register_user                       : 'Merci d’entrer les coordonnées de la personne en charge du compte : ',
    register_user_association           : 'Ces informations vous permettront de vous connecter sur \nl\'application mobile et de gérer votre compte depuis votre téléphone. : ',
    other_city                          : 'Autre ville',
    zipcode                             : 'Code postal *',
    user_info                           : 'Information du responsable',
    company_info                        : (type: string): string => {
        switch (type) {
            case 'mairie' :
                return 'Information sur la mairie :'
            case 'association' :
                return 'Information sur l\'association :'
            case 'commerce' :
                return 'Information sur le commerce :'
            case 'entreprise' :
                return 'Information sur l\'entreprise :'
            default:
                return 'Information sur le commerce :'
        }

    },
    commerce_info                       : 'Information du commerce',
    subtitle_success_inscription        : (type: string | undefined): string => 'Votre ' + (type !== undefined ? type : 'commerce') + ' est maintenant ' + (type !== 'association' ? 'référencé' : 'référencée') + ' sur le réseau local VIVEZ ICI',
    display_subscription_offer_link     : 'Découvrir les offres payantes',
    description_step_3                  : (type: string | undefined): string => '<p>Nous vous invitons à ajouter vos événements, services et offres spéciales pour mettre en avant votre ' + (type ? type : 'commerce') + ' et augmenter votre nombre d’abonnés : ils seront visibles sur les applications VIVEZ ICI.<br/></p>',
    description_event                   : '<p><b style="font-size: 18px">3. Gagnez en visibilité en ajoutant un « événement »</b> qui apparaîtra dans l’agenda de la ville parmi ceux de la mairie, des associations et des commerces :</p>',
    description_special_offer           : (type: string | undefined): string => '<p><b style="font-size: 18px">2. Attirez de nouveaux ' + (type !== undefined && type === 'association' ? 'adhérents' : 'clients') + ' en ajoutant une « offre spéciale »</b> pour les habitants de votre ville : c’est un excellent moyen de faire découvrir vos services et de générer du trafic dans votre établissement.</p>',
    description_service                 : (type: string | undefined): string => '<p><b style="font-size: 18px">1. Présentez votre activité en ajoutant un « service »</b> décrivant en détail ce que vous proposez et à quel prix : cela vous permet de donner envie aux habitants de découvrir votre ' + (type !== undefined ? type : 'commerce') + '.</p>',
    add_event                           : 'Ajouter un événement',
    add_special_offer                   : 'Ajouter une offre spéciale',
    add_service                         : 'Ajouter un service ',
    description_notification_step_3     : (type: string | undefined): string => '<p>A chaque fois, nous vous conseillons de programmer une <b>notification</b> afin d’informer vos abonnés de vos actions : l’objectif est de leur rappeler que vous existez et de les faire revenir dans votre établissement.<br/></p>' +
        '<p><span style="text-decoration: underline"> La clé du succès :</span><b> avoir le plus grand nombre d’abonnés ! </b><br/></p>' +
        '<p>Pour cela, invitez vos amis et clients à suivre votre ' + (type !== undefined ? type : 'commerce') + ' sur l’application mobile et animez vos abonnés en leur envoyant régulièrement des <b>messages groupés</b> pour qu’ils pensent à vous (un par semaine par exemple).<br/></p>',
    description_subscription_step_3     : '<p><b style="text-decoration: underline">PS:</b> <b>Allez plus loin avec nos offres payantes</b>:<br/>Vous pourrez mettre en place votre propre <span style="text-decoration: underline">site internet</span>, avoir un <span style="text-decoration: underline">email professionnel</span> avec votre domaine, lancer votre <span style="text-decoration: underline">Click & Collect</span>, avoir un <span style="text-decoration: underline">service illimité</span> et un <span style="text-decoration: underline">support technique personnalisé</span> dans la gestion de votre compte et de <span style="text-decoration: underline">son marketing</span>.</p>',
    description_subscription_association: '<p>IMPORTANT : les associations bénéficient gratuitement des fonctionnalités de l’abonnement PREMIUM</p>',
    service_added                       : 'Service ajouté',
    special_offer_added                 : 'Offre spéciale ajoutée',
    event_added                         : 'Événement ajoutée',
    update_business_identity            : 'Afin de vérifier que vous êtes bien le gérant nous avons besoin de votre pièce d\'identité',
    identity_description                : 'Informations sur l\'identité du gérant'
}
