import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { CompanyPremiumClickCollect } from "./clickCollect/clickCollect.form";
import { CompanyWebSiteForm } from "./website/website.form";
import { CompanyPremiumCheckbox } from "./checkbox";
import { FormLegalInfoStateType, FormPremiumStateType } from "./types";
import { Company } from "../../../../domain/entity/company";

interface Props {
    clickCollect: FormPremiumStateType;
    legalInfo: FormLegalInfoStateType;
    raiseClickCollectUpdates: (clickCollect: FormPremiumStateType) => void
    raiseLegalInfoUpdates: (key: keyof FormLegalInfoStateType, value: string) => void;
    company: Company;
    manageSiteInternet: () => void;
    isSubmitted: boolean
    companyType: string
}

export class FormPremiumSection extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className={'section_pro company-premium-block'}>
                <span className="wizard-sub-text">{i18n.company.membership_title}</span>

                {this.renderClickCollect()}

                <CompanyPremiumCheckbox checked={this.props.legalInfo.isProWebsiteEnabled}
                                        checkboxLabel={i18n.company.active_subdomain}
                                        toggleInput={(): void => this.props.manageSiteInternet()}>
                    <CompanyWebSiteForm legalInfo={this.props.legalInfo}
                                        company={this.props.company}
                                        isSubmitted={this.props.isSubmitted}
                                        raiseUpdates={(key: keyof FormLegalInfoStateType, value: string) => this.props.raiseLegalInfoUpdates(key, value)}/>
                </CompanyPremiumCheckbox>
            </div>
        )
    }

    renderClickCollect(): JSX.Element | undefined {
        if (this.props.companyType !== 'association')
            return (
                <CompanyPremiumCheckbox checked={this.props.clickCollect.activeClickCollect}
                                        checkboxLabel={i18n.company.active_clickCollect}
                                        toggleInput={(): void => this.toggleClickCollectInput()}>

                    <CompanyPremiumClickCollect clickCollect={this.props.clickCollect}
                                                isSubmitted={this.props.isSubmitted}
                                                raiseUpdates={(clickCollect: FormPremiumStateType) => this.props.raiseClickCollectUpdates(clickCollect)}/>
                </CompanyPremiumCheckbox>
            )
    }

    toggleClickCollectInput(): void {
        this.props.raiseClickCollectUpdates({
            ...this.props.clickCollect,
            activeClickCollect: !this.props.clickCollect.activeClickCollect
        })
    }
}
