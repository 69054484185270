import { InstantHeader } from "../../../domain/entites/instantHeader";
import { AppState } from "../../../../redux-configuration/AppState";
import {
    loadingInstantsAgendaSelector, instantsAgendaSelector
} from "../../../usecases/loadAgendaInstants/loadAgendaInstants.selectors";
import { Dispatch } from "redux";
import { LoadAgendaInstantAction } from "../../../usecases/loadAgendaInstants/loadAgendaInstants.types";
import { loadAgendaInstants } from "../../../usecases/loadAgendaInstants/loadAgendaInstants.actions";
import { connect } from "react-redux";
import { AgendaContainer } from "./agenda.container";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";

interface StateToPropsType {
    company: Company | null;
    instants: InstantHeader[]
    loading: boolean
}

interface DispatchToPropsType {
    loadAgendaInstants: (cityCode: string) => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company : companySelector(state),
    instants: instantsAgendaSelector(state),
    loading : loadingInstantsAgendaSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadAgendaInstants: (cityCode: string): LoadAgendaInstantAction => dispatch(loadAgendaInstants(cityCode))
})

export const AgendaInstants = connect(mapStateToProps, mapDispatchToProps)(AgendaContainer)
