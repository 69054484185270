import React, { createRef, PureComponent, ReactNode } from 'react';
import { EmojiButtonPicker } from "./emoji.button";
import { ImageButtonPicker } from "./image.button";

interface Props {
    value: string
    onChange: (value: string) => void;
    onSendMessage: () => void;
    onSelectImage: (value: File) => void;
}

export class MessageTextInput extends PureComponent<Props> {

    private textAreaRef = createRef<HTMLTextAreaElement>();

    render(): ReactNode {
        return (
            <div className={'d-flex align-items-center'}>
                <div className={'message-input-container'}>
                    <textarea className={'message-input'}
                              rows={1}
                              ref={this.textAreaRef}
                              value={this.props.value}
                              placeholder={'Type a message'}
                              onChange={e => this.onChange(e.target.value)}/>

                    <div className={'send-button'} onClick={() => this.props.onSendMessage()}>
                        <i className="fa fa-paper-plane"/>
                    </div>
                </div>

                <EmojiButtonPicker onClick={(emoji) => {
                    this.props.onChange(this.props.value + emoji.emoji)
                }}/>

                <ImageButtonPicker onClick={(image: File | undefined) => {
                    if (image) {
                        this.props.onSelectImage(image)
                    }
                }}/>
            </div>
        )
    }

    onChange(value: string): void {
        this.props.onChange(value)

        //Creating a Textarea with dynamic height
        if (this.textAreaRef.current) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            this.textAreaRef.current.style.height = "0px";
            const scrollHeight = this.textAreaRef.current.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            this.textAreaRef.current.style.height = scrollHeight + "px";
        }
    }

}
