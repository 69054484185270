import { AppState } from "../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { addProductErrorSelector, addProductLoadingSelector, addProductSuccessSelector } from "../../../usecases/addProduct/selectors";
import { ProductDetails } from "../../../domain/entities/productDetails";
import { addProduct } from "../../../usecases/addProduct/actions";
import { AddProductAction } from "../../../usecases/addProduct/actionTypes";
import { AddProductContainer } from "./addProduct.container";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";

interface StateToPropsType {
    company: Company | null;
    loading: boolean;
    success: boolean | undefined;
    error: string | undefined;
}

interface DispatchToPropsType {
    addProduct: (product: ProductDetails, companyId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companySelector(state),
    loading: addProductLoadingSelector(state),
    success: addProductSuccessSelector(state),
    error  : addProductErrorSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    addProduct: (product: ProductDetails, companyId: string): AddProductAction => dispatch(addProduct(product, companyId))
})

export const AddProductPage = connect(mapStateToProps, mapDispatchToProps)(AddProductContainer)
