import { EditCompanyState } from "../../configuration/state";
import {
    UPDATE_SINGLE_FIELD,
    UPDATE_SINGLE_FIELD_FAILED,
    UPDATE_SINGLE_FIELD_SUCCEEDED,
    UpdateSingleFieldActionTypes
} from "./actionTypes";

const initialState: EditCompanyState = {
    loading: false,
    success: null,
    error  : undefined
}

export const updateSingleFieldReducer = (state = initialState, action: UpdateSingleFieldActionTypes): EditCompanyState => {
    switch (action.type) {
        case UPDATE_SINGLE_FIELD:
            return {
                loading: true,
                success: null,
                error  : undefined
            }
        case UPDATE_SINGLE_FIELD_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case UPDATE_SINGLE_FIELD_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
}
