export class ThemeEcommerce {
    constructor(
        protected _isActive: boolean,
        protected _headerTitle: string,
        protected _title: string,
        protected _subtitle: string,
        protected _backgroundImage: string,
        protected _type: string,
        protected _paymentOptions: string[],
        protected _documentUrl: string,
        protected _navigateTo: string
    ) {}

    get isActive(): boolean {
        return this._isActive;
    }

    get headerTitle(): string {
        return this._headerTitle;
    }

    get title(): string {
        return this._title;
    }

    get subtitle(): string {
        return this._subtitle;
    }

    get backgroundImage(): string {
        return this._backgroundImage;
    }

    get type(): string {
        return this._type;
    }

    get paymentOptions(): string[] {
        return this._paymentOptions;
    }

    get documentUrl(): string {
        return this._documentUrl;
    }

    get navigateTo(): string {
        return this._navigateTo;
    }
}
