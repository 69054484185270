import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { CompanyAddress } from "../../../../../company/domain/entity/companyAddress";

interface Props {
    title: string
    location: CompanyAddress
}

export class BusinessInfo extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className={'business-info'}>
                <p>{i18n.registration.lastName}: {this.props.title}</p>
                <p>{i18n.registration.address}: {this.props.location.address}, {this.props.location.zipCode}, {this.props.location.city}</p>
            </div>
        )
    }
}
