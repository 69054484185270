import { Order } from "../../domain/entities/order";
import {
    LOAD_ORDER,
    LOAD_ORDER_FAILED,
    LOAD_ORDER_SUCCEEDED,
    LoadOrderAction,
    LoadOrderFailedAction, LoadOrderSucceededAction
} from "./actionTypes";

export const loadOrder = (orderId: string): LoadOrderAction => ({
    type   : LOAD_ORDER,
    payload: orderId
})

export const loadOrderFailed = (error: string): LoadOrderFailedAction => ({
    type   : LOAD_ORDER_FAILED,
    payload: error
})

export const loadOrderSucceeded = (order: Order): LoadOrderSucceededAction => ({
    type   : LOAD_ORDER_SUCCEEDED,
    payload: order
})
