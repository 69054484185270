import { AppState } from "../../../../redux-configuration/AppState";
import { ProductsContainer } from "./products.container";
import { LoadProductsAction } from "../../../usecases/loadProducts/actionTypes";
import { loadProducts } from "../../../usecases/loadProducts/actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { loadProductsErrorSelector, loadProductsLoadingSelector, productsSelector } from "../../../usecases/loadProducts/selectors";
import { ProductDetails } from "../../../domain/entities/productDetails";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";

interface StateToPropsType {
    company: Company | null;
    products: ProductDetails[] | undefined;
    loading: boolean;
    error: string | undefined;
}

interface DispatchToPropsType {
    loadProducts: (companyId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company : companySelector(state),
    products: productsSelector(state),
    loading : loadProductsLoadingSelector(state),
    error   : loadProductsErrorSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadProducts: (companyId: string): LoadProductsAction => dispatch(loadProducts(companyId))
})

export const ProductsPage = connect(mapStateToProps, mapDispatchToProps)(ProductsContainer)
