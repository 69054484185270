import React from 'react';
import { BroadcastConversation } from "../../../../domain/entities/broadcastConversation";
import icon from '../../../../../assets/icons/broadcast.png';

interface Props {
    broadcast: BroadcastConversation;
    onClick: () => void;
}

export const BroadcastConversationItem = (props: Props): JSX.Element => {
    return (
        <div className={'broadcast d-flex align-items-center'} onClick={() => props.onClick()}>
            <div>
                <img src={icon} className={'broadcast-icon'}/>
            </div>

            <div className={'content'}>
                <p>{props.broadcast.title}</p>
                <span>{props.broadcast.excerpt}</span>
            </div>
        </div>
    )
}
