import React, { PureComponent, ReactNode } from 'react'
import { ManagementInstantHeader } from "../../../domain/entities/managementInstantHeader";
import { Company } from "../../../../company/domain/entity/company";
import './instantListing.css'
import { i18n } from "../../../../configuration/i18n";
import { InstantsListingTabContent } from "./components/instantsListing.content";
import { InstantManagementSpecifications } from "../specifications";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";

interface Props {
    instants: ManagementInstantHeader[] | null;
    company: Company | null;
    loading: boolean;
    updateInstantStatus: (instantId: string, newStatus: boolean) => void;
    deleteInstant: (instantId: string, companyId: string) => void;
}

interface State {
    isMembershipMsgVisible: boolean;
    currentTab: string;
}

export class InstantsListingContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            isMembershipMsgVisible: false,
            currentTab            : this.props.company?.type === 'mairie' ? 'event' : 'service'
        }
    }

    componentDidMount(): void {
        document.title = i18n.management_instant.instant_list_page_title;
    }

    render(): ReactNode {
        return (
            <div>
                <DefaultPageHeader title={i18n.management_instant.my_instants}/>

                <div className={'profile-edition'}>
                    <div className={'content-instant-list'}>
                        <div className={'container-fluid instants-list'}>
                            <div className={'profile-section'}>
                                <div className={'profile-section-container border-radius-none'} style={{marginTop: 0}}>
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className={this.state.currentTab === 'service' ? "nav-link active" : 'nav-link'}
                                               onClick={() => this.setState({currentTab: 'service'})}>{this.renderTabTitle('service')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={this.state.currentTab === 'specialOffer' ? "nav-link active" : 'nav-link'}
                                               onClick={() => this.setState({currentTab: 'specialOffer'})}>{this.renderTabTitle('special_offer')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={this.state.currentTab === 'event' ? "nav-link active" : 'nav-link'} aria-current="page"
                                               onClick={() => this.setState({currentTab: 'event'})}>{this.renderTabTitle('event')}</a>
                                        </li>
                                    </ul>

                                    {this.renderTabContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderTabTitle(tab: 'event' | 'service' | 'special_offer'): string {
        const spec = new InstantManagementSpecifications(this.props.instants ? this.props.instants : [])
        if (tab === 'event')
            return this.props.company && this.props.company.membership.allowedInstants !== -1 ?
                `${i18n.management_instant.events} ( ${spec.events.length} / ${this.props.company?.membership.allowedInstants} )` :
                i18n.management_instant.events
        else if (tab === 'service')
            return this.props.company && this.props.company.membership.allowedInstants !== -1 ?
                `${i18n.management_instant.services} ( ${spec.services.length} / ${this.props.company?.membership.allowedInstants} )` :
                i18n.management_instant.services
        else
            return this.props.company && this.props.company.membership.allowedInstants !== -1 ?
                `${i18n.management_instant.special_offer} ( ${spec.specialOffers.length} / ${this.props.company?.membership.allowedInstants} )` :
                i18n.management_instant.special_offer
    }

    renderTabContent(): JSX.Element {
        if (this.props.instants) {
            const spec = new InstantManagementSpecifications(this.props.instants)
            if (this.state.currentTab === 'event')
                return <InstantsListingTabContent instants={spec.events}
                                                  type={'event'}
                                                  addInstant={() => this.addEvent()}
                                                  company={this.props.company}
                                                  editInstantPath={'/editAgendaEvent/'}
                                                  deleteInstant={(instantId: string, companyId: string) => this.props.deleteInstant(instantId, companyId)}
                                                  updateInstantStatus={(instantId: string, status: boolean) => this.props.updateInstantStatus(instantId, status)}/>
            else if (this.state.currentTab === 'service')
                return <InstantsListingTabContent instants={spec.services}
                                                  type={'service'}
                                                  addInstant={() => this.addService()} company={this.props.company}
                                                  editInstantPath={'/editService/'}
                                                  deleteInstant={(instantId: string, companyId: string) => this.props.deleteInstant(instantId, companyId)}
                                                  updateInstantStatus={(instantId: string, status: boolean) => this.props.updateInstantStatus(instantId, status)}/>
            else
                return <InstantsListingTabContent instants={spec.specialOffers}
                                                  type={'specialOffer'}
                                                  editInstantPath={'/editSpecialOffer/'}
                                                  addInstant={this.addSpecialOffer} company={this.props.company}
                                                  deleteInstant={(instantId: string, companyId: string) => this.props.deleteInstant(instantId, companyId)}
                                                  updateInstantStatus={(instantId: string, status: boolean) => this.props.updateInstantStatus(instantId, status)}/>
        } else
            return (
                <p className={'empty-list border-grey'}>{i18n.management_instant.empty_list}</p>
            )
    }

    addService(): void {
        window.location.href = '/createService'
    }

    addSpecialOffer(): void {
        window.location.href = '/createSpecialOffer'
    }

    addEvent(): void {
        window.location.href = '/createAgendaEvent'
    }
}
