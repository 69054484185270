import {
    SEND_LOGIN_CONFIRMATION_CODE,
    SEND_LOGIN_CONFIRMATION_CODE_FAILED,
    SEND_LOGIN_CONFIRMATION_CODE_SUCCEEDED,
    SendLoginConfirmationCodeAction,
    SendLoginConfirmationCodeActionTypes
} from "./actionTypes";

export const sendLoginConfirmationCode = (phoneNumber: string, code: string): SendLoginConfirmationCodeAction => ({
    type   : SEND_LOGIN_CONFIRMATION_CODE,
    payload: {phoneNumber, code}
})

export const sendLoginConfirmationCodeFailed = (error: string): SendLoginConfirmationCodeActionTypes => ({
    type   : SEND_LOGIN_CONFIRMATION_CODE_FAILED,
    payload: error
})

export const sendLoginConfirmationCodeSucceeded = (token: string): SendLoginConfirmationCodeActionTypes => ({
    type   : SEND_LOGIN_CONFIRMATION_CODE_SUCCEEDED,
    payload: token
})
