import { Conversation } from "../../domain/entities/conversation";
import { Company } from "../../../company/domain/entity/company";

export const LOAD_BUSINESS_CONVERSATIONS = 'LOAD_BUSINESS_CONVERSATIONS'
export const LOAD_BUSINESS_CONVERSATIONS_FAILED = 'LOAD_BUSINESS_CONVERSATIONS_FAILED'
export const LOAD_BUSINESS_CONVERSATIONS_SUCCEEDED = 'LOAD_BUSINESS_CONVERSATIONS_SUCCEEDED'

export interface LoadBusinessConversationsAction {
    type: typeof LOAD_BUSINESS_CONVERSATIONS;
    payload: Company;
}

export interface LoadBusinessConversationsSucceededAction {
    type: typeof LOAD_BUSINESS_CONVERSATIONS_SUCCEEDED;
    payload: Conversation[];
}

export interface LoadBusinessConversationsFailedAction {
    type: typeof LOAD_BUSINESS_CONVERSATIONS_FAILED;
    payload: string;
}

export type LoadBusinessConversationsActionTypes =
    LoadBusinessConversationsAction
    | LoadBusinessConversationsFailedAction
    | LoadBusinessConversationsSucceededAction
