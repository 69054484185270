import React, { useState } from "react";
import { Company } from "../../../../../../domain/entity/company";
import { i18n } from "../../../../../../../configuration/i18n";
import { TextInput } from "../../../../../../../common/adapters/primaries/form/inputs/text.input";
import { FoodMenuDocumentInput } from "./document.input";
import { ThemeFoodMenuDocumentType } from "../../../types";

interface Props {
    isVisible: boolean;
    addFoodMenu: (menu: ThemeFoodMenuDocumentType) => void;
    onClose: () => void;

    imageUrl: string | undefined;
    loading: boolean;
    error: string | undefined;
    company: Company | null;
    uploadImage: (image: File, companyId: string) => void;
}

export const AddFoodMenuModalContainer = (props: Props): JSX.Element => {
    const emptyFoodMenu = {
        title: '',
        icon : '',
        url  : ''
    }

    const [foodMenu, setFoodMenu] = useState<ThemeFoodMenuDocumentType>(emptyFoodMenu)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)

    if (props.isVisible)
        return (
            <div className="modal default-modal food-menu-modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="header">
                            <p className={'title'}>Ajout d&apos;un document</p>
                            <span className={'close-button'} onClick={() => {
                                props.onClose()
                                setFoodMenu(emptyFoodMenu)
                            }}/>
                        </div>

                        <div className="body px-sm-5 mx-md-5 pt-5 pb-sm-4">
                            <TextInput type="text"
                                       error={false}
                                       label={i18n.company.doc_title}
                                       value={foodMenu.title}
                                       className="justify-content-start"
                                       raiseUpdates={(title: string) => {
                                           setFoodMenu({...foodMenu, title})
                                           setErrorMsg(null)
                                       }}/>

                            <FoodMenuDocumentInput type={'icon'}
                                                   loading={props.loading}
                                                   documentUrl={foodMenu.icon}
                                                   uploadedDocumentUrl={props.imageUrl}
                                                   onChange={(icon: string) => {
                                                       setFoodMenu({...foodMenu, icon})
                                                       setErrorMsg(null)
                                                   }}
                                                   uploadDocument={(doc: File) => {
                                                       if (props.company) {
                                                           props.uploadImage(doc, props.company.id)
                                                       }
                                                   }}/>

                            <FoodMenuDocumentInput type={'pdf'}
                                                   loading={props.loading}
                                                   documentUrl={foodMenu.url}
                                                   uploadedDocumentUrl={props.imageUrl}
                                                   onChange={(url: string) => {
                                                       setFoodMenu({...foodMenu, url})
                                                       setErrorMsg(null)
                                                   }}
                                                   uploadDocument={(doc: File) => {
                                                       if (props.company) {
                                                           props.uploadImage(doc, props.company.id)
                                                       }
                                                   }}/>

                            {errorMsg && <p className={'error'}>{errorMsg}</p>}

                            <button className={'default-btn ml-auto d-block px-5 mt-3'} onClick={() => addFoodMenu()}>
                                Ajouter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    else
        return <></>

    function addFoodMenu() {
        if (foodMenu.title.length > 3 && foodMenu.url.length > 0 && foodMenu.icon.length > 0) {
            props.addFoodMenu(foodMenu)
            setFoodMenu(emptyFoodMenu)
            props.onClose()
        } else {
            setErrorMsg(i18n.company.empty_form_error)
        }
    }
}
