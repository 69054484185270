import { Conversation } from "../../domain/entities/conversation";
import { Company } from "../../../company/domain/entity/company";
import {
    LOAD_BUSINESS_CONVERSATIONS, LOAD_BUSINESS_CONVERSATIONS_FAILED, LOAD_BUSINESS_CONVERSATIONS_SUCCEEDED,
    LoadBusinessConversationsAction,
    LoadBusinessConversationsFailedAction,
    LoadBusinessConversationsSucceededAction
} from "./actionTypes";

export const loadBusinessConversations = (company: Company): LoadBusinessConversationsAction => ({
    type   : LOAD_BUSINESS_CONVERSATIONS,
    payload: company
})

export const loadBusinessConversationsSucceeded = (conversations: Conversation[]): LoadBusinessConversationsSucceededAction => ({
    type   : LOAD_BUSINESS_CONVERSATIONS_SUCCEEDED,
    payload: conversations
})

export const loadBusinessConversationsFailed = (error: string): LoadBusinessConversationsFailedAction => ({
    type   : LOAD_BUSINESS_CONVERSATIONS_FAILED,
    payload: error
})
