import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, mergeMap, switchMap, map } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { SEND_CONFIRMATION_CODE, SendConfirmationCodeAction } from "./actionTypes";
import { RegistrationService } from "../../domain/gateway/registration.service";
import { Token } from "../../domain/entity/token";
import { sendConfirmationCodeFailed, sendConfirmationCodeSucceeded } from "./action";
import { AuthenticationRepository } from "../../../authentication/domain/gateway/authenticationRepository";

export const sendConfirmationCodeEpic: Epic = (action$: ActionsObservable<SendConfirmationCodeAction>,
                                               store: StateObservable<AppState>,
                                               {registrationService, authenticationRepository}:
                                                   {
                                                       registrationService: RegistrationService,
                                                       authenticationRepository: AuthenticationRepository
                                                   }) =>
    action$.pipe(
        ofType(SEND_CONFIRMATION_CODE),
        switchMap(action => registrationService.sendConfirmationCode(action.payload.phoneNumber, action.payload.code)
            .pipe(
                mergeMap((data: Token) => authenticationRepository.saveAuthorizationToken(data.token)
                    .pipe(
                        map(() => sendConfirmationCodeSucceeded(data.token)),
                        catchError(error => of(sendConfirmationCodeFailed(error)))
                    )
                ),
                catchError(error => of(sendConfirmationCodeFailed(error)))
            )
        )
    )
