import { InstantService } from "../../../domain/gateway/instant.service";
import { Observable, throwError } from "rxjs";
import { InstantHeader } from "../../../domain/entites/instantHeader";
import { ApplicationContext } from "../../../../configuration/application.context";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { InstantHeaderDTO } from "./dto/instantHeader.dto";
import { catchError, map } from "rxjs/operators";
import { InstantMapper } from "./mapper/instant.mapper";

const moment = ApplicationContext.getInstance().momentJs()

export class ApiInstantService implements InstantService {
    loadAgendaInstants(cityCode: string): Observable<InstantHeader[]> {
        const cities = `&cityCodes=${JSON.stringify([cityCode])}`
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/calendar?date=${moment().format()}${cities}`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: InstantHeaderDTO[] }>(url)
            .pipe(
                map((response: { data: InstantHeaderDTO[] }) => InstantMapper.mapToArrayInstantHeaders(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
