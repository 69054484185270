import { i18n } from "../../../../configuration/i18n";

export type ManagementInstantCategoryType =
    'cultural'
    | 'sportive'
    | 'discovery'
    | 'gourmand'
    | 'festive'
    | 'heart'
    | 'info'
    | 'offer'
    | 'gift'
    | 'musical'

export type ManagementServiceInstantCategoryType =
    'cultural'
    | 'sportive'
    | 'discovery'
    | 'gourmand'
    | 'festive'
    | 'heart'
    | 'info'
    | 'offer'
    | 'musical'

export type ManagementAgendaInstantCategoryType =
    'cultural'
    | 'sportive'
    | 'discovery'
    | 'festive'
    | 'heart'
    | 'info'
    | 'musical'

type InstantCategoryType = {
    [key: string]: string
}

export const InstantCategories: InstantCategoryType = {
    cultural : i18n.management_instant.cultural,
    sportive : i18n.management_instant.sportive,
    discovery: i18n.management_instant.discovery,
    gourmand : i18n.management_instant.gourmand,
    festive  : i18n.management_instant.festive,
    heart    : i18n.management_instant.heart,
    info     : i18n.management_instant.info,
    offer    : i18n.management_instant.offer,
    gift     : i18n.management_instant.gift,
    musical  : i18n.management_instant.musical
}

export const InstantCategoriesDescription: InstantCategoryType = {
        cultural : i18n.management_instant.cultural_description,
        sportive : i18n.management_instant.sportive_description,
        discovery: i18n.management_instant.discovery_description,
        gourmand : i18n.management_instant.gourmand_description,
        festive  : i18n.management_instant.festive_description,
        heart    : i18n.management_instant.heart_description,
        info     : i18n.management_instant.info_description,
        offer    : i18n.management_instant.offer_description,
        gift     : i18n.management_instant.gift_description,
        musical  : i18n.management_instant.musical_description
}

export const AgendaInstantCategories: InstantCategoryType = {
    cultural : i18n.management_instant.cultural,
    sportive : i18n.management_instant.sportive,
    discovery: i18n.management_instant.discovery,
    heart    : i18n.management_instant.heart,
    info     : i18n.management_instant.info,
    musical  : i18n.management_instant.musical,
    festive  : i18n.management_instant.festive,
}

export const ServiceInstantCategories: InstantCategoryType = {
    gourmand : i18n.management_instant.gourmand,
    festive  : i18n.management_instant.festive,
    offer    : i18n.management_instant.offer,
    cultural : i18n.management_instant.cultural,
    sportive : i18n.management_instant.sportive,
    discovery: i18n.management_instant.discovery,
    heart    : i18n.management_instant.heart,
    info     : i18n.management_instant.info,
    musical  : i18n.management_instant.musical
}
