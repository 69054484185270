import React, { PureComponent, ReactNode } from 'react'
import logo from '../../../../assets/img/logo.png'
import './header.css'

interface State {
    collapsed: boolean;
}

export class HeaderMenu extends PureComponent<{}, State> {

    constructor(props: {}) {
        super(props)
        this.state = {
            collapsed: true
        }
    }

    render(): ReactNode {
        const className = this.state.collapsed ? "collapse navbar-collapse" : "navbar-collapse"

        return (
            <header id="masthead" className="login-header" role="banner">
                <nav className="navbar navbar-expand-md navbar-dark">
                    <div className="container">
                        <a href="https://www.vivezici.fr/" className="navbar-brand">
                            <img src={logo} height="55" width="240" alt="VIVEZ ICI"/>
                        </a>

                        <button className="navbar-toggler" type="button"
                                onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={className} id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.vivezclichy.fr/actualites/">Actualités</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.vivezclichy.fr/histoire/">A propos</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.vivezclichy.fr/contact/">Contact</a>
                                </li>

                                <li className="nav-item active">
                                    <a className="nav-link" href="#">Espace Pro</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}
