import React, { PureComponent, ReactNode } from "react";
import { Line } from "react-chartjs-2";
import { CartesianData } from "../../../../domain/entity/cartesianData";
import { i18n } from "../../../../../configuration/i18n";
import { Theme } from "../../../../../common/configuration/theme/app.theme";
import { ApplicationContext } from "../../../../../configuration/application.context";
import ChartDataLabels from "chartjs-plugin-datalabels";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    followData: CartesianData[];
}

export class BusinessFollowChart extends PureComponent<Props> {

    render(): ReactNode {
        const differences = [];

        differences.push(0)
        for (let i = 1; i < this.props.followData.length; i++) {
            differences.push(this.props.followData[i].yValue - this.props.followData[i - 1].yValue);
        }

        const followsData = {
            labels  : this.props.followData.map(item => moment(item.xValue, "YYYY-MM-DD hh:mm:ss").format('MMM')),
            datasets: [
                {
                    label          : i18n.stats.followers_number,
                    data           : this.props.followData.map(item => item.yValue),
                    fill           : false,
                    type           : 'line' as const,
                    backgroundColor: Theme.blueMinsk,
                    borderColor    : Theme.blueMinsk,

                },
                {
                    label          : i18n.stats.increase_rate,
                    type           : 'bar' as const,
                    data           : differences.map((item) => {
                        if (item === 0) {
                            return null
                        } else if (item < 0) {
                            return '-' + item
                        } else if (item > 0) {
                            return '+' + item
                        }
                    }),
                    fill           : false,
                    backgroundColor: this.props.followData.map((item, index) => {
                        return index === this.props.followData.length - 1 ? '#99cc33' : Theme.supernova
                    }),
                },
            ]
        };

        const sortedArr = this.props.followData.sort((itemA: CartesianData, itemB: CartesianData) => itemB.yValue > itemA.yValue ? 1 : -1)

        const max = sortedArr[0].yValue
        const options = {
            scales : {
                y:
                    {
                        max: max + Math.round(max * 0.1),
                        min: 0,
                    },
            },
            plugins: {
                datalabels: {
                    display: true,
                    color  : "black",
                    align  : "end",
                    anchor : "end",
                    font   : {size: "14"},
                }
            },
            legend : {
                display: true
            }
        };

        return <Line plugins={[ChartDataLabels]} options={options} type={'bar'} data={followsData}
                     style={{maxHeight: 400}}/>
    }
}
