import { Observable, from } from 'rxjs';
import { RegistrationRepository } from "../../../domain/gateway/registration.repository";
import { User } from "../../../domain/entity/user";
import { BusinessMapper } from "./mapper/business.mapper";
import { UserMapper } from "./mapper/user.mapper";
import { Company } from "../../../../company/domain/entity/company";

export class LocalStorageRegistrationRepository implements RegistrationRepository {
    saveUser(user: User): Observable<void> {
        const saveUserPromise = new Promise<void>((resolve) => {
            localStorage.setItem('user', JSON.stringify(user))
            resolve(void 0)
        })
        return from(saveUserPromise)
    }

    getUser(): Observable<User> {
        const getUserPromise = new Promise<User>((resolve, reject) => {
            const user = localStorage.getItem('user')
            if (typeof user === "string")
                resolve(UserMapper.mapLocalUser(user))
            else
                reject('USER_NOT_FOUND')
        })
        return from(getUserPromise)
    }

    saveConfirmationCode(confirmationCode: string): Observable<void> {
        const saveCodePromise = new Promise<void>((resolve) => {
            localStorage.setItem('confirmationCode', confirmationCode)
            resolve(void 0)
        })
        return from(saveCodePromise)
    }

    getConfirmationCode(): Observable<string> {
        const getCodePromise = new Promise<string>((resolve, reject) => {
            const confirmationCode = localStorage.getItem('confirmationCode')
            if (typeof confirmationCode === "string")
                resolve(confirmationCode)
            else
                reject('CODE_NOT_FOUND')
        })
        return from(getCodePromise)
    }

    saveBusiness(business: Company): Observable<void> {
        const saveBusinessPromise = new Promise<void>((resolve) => {
            localStorage.setItem('business', JSON.stringify(business))
            resolve(void 0)
        })
        return from(saveBusinessPromise)
    }

    getBusiness(): Observable<Company> {
        const getBusinessPromise = new Promise<Company>((resolve, reject) => {
            const business = localStorage.getItem('business')
            if (typeof business === "string") {
                resolve(BusinessMapper.mapLocalBusiness(business))
            } else {
                reject('BUSINESS_NOT_FOUND')
            }
        })
        return from(getBusinessPromise)
    }
}
