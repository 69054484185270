import {
    LOAD_LOCAL_CODE,
    LOAD_LOCAL_CODE_FAILED,
    LOAD_LOCAL_CODE_SUCCEEDED,
    LoadLocalCodeActionTypes
} from "./actionTypes";


export const loadLocalCode = (): LoadLocalCodeActionTypes => ({
    type: LOAD_LOCAL_CODE
})

export const loadLocalCodeSucceeded = (code: string): LoadLocalCodeActionTypes => ({
    type   : LOAD_LOCAL_CODE_SUCCEEDED,
    payload: code
})

export const loadLocalCodeFailed = (error: string): LoadLocalCodeActionTypes => ({
    type   : LOAD_LOCAL_CODE_FAILED,
    payload: error
})
