import { User } from "../../domain/entity/user";

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_SUCCEEDED = 'UPDATE_PROFILE_SUCCEEDED'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'

interface UpdateProfileAction {
    type: typeof UPDATE_PROFILE;
    payload: User;
}

interface UpdateProfileFailedAction {
    type: typeof UPDATE_PROFILE_FAILED;
    payload: string;
}

interface UpdateProfileSucceededAction {
    type: typeof UPDATE_PROFILE_SUCCEEDED;
}

export type UpdateProfileActionType = UpdateProfileAction
export type UpdateProfileActionTypes =
    UpdateProfileAction
    | UpdateProfileFailedAction
    | UpdateProfileSucceededAction
