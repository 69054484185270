import { Conversation } from "../../domain/entities/conversation";
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { ApplicationContext } from "../../../configuration/application.context";
import { catchError, map, switchMap } from "rxjs/operators";
import { MessagingService } from "../../domain/gateway/messaging.service";
import { of } from "rxjs";
import { AppState } from "../../../redux-configuration/AppState";
import { LOAD_BUSINESS_CONVERSATIONS, LoadBusinessConversationsAction } from "./actionTypes";
import { loadBusinessConversationsFailed, loadBusinessConversationsSucceeded } from "./actions";

const moment = ApplicationContext.getInstance().momentJs()

const sortByUpdateAt = () =>
    (item1: Conversation, item2: Conversation): number =>
        moment(item1.updatedAt).diff(item2.updatedAt) > 0 ? -1 : 1

export const loadBusinessConversationsEpic: Epic = (action$: ActionsObservable<LoadBusinessConversationsAction>, store$: StateObservable<AppState>,
                                                    {messagingService}: { messagingService: MessagingService }) =>
    action$.pipe(
        ofType(LOAD_BUSINESS_CONVERSATIONS),
        switchMap(action => messagingService.retrieveBusinessConversations(action.payload.id)
            .pipe(
                map((conversations: Conversation[]) => loadBusinessConversationsSucceeded(conversations.sort(sortByUpdateAt()))),
                catchError(error => of(loadBusinessConversationsFailed(error)))
            )
        )
    )
