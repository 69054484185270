import { SIGN_UP, SIGN_UP_FAILED, SIGN_UP_SUCCEEDED, SignUpActionTypes } from "./actionTypes";
import { User } from "../../domain/entity/user";

export const signUp = (user: User): SignUpActionTypes => ({
    type   : SIGN_UP,
    payload: user
})

export const signUpFailed = (error: string): SignUpActionTypes => ({
    type   : SIGN_UP_FAILED,
    payload: error
})

export const signUpSucceeded = (): SignUpActionTypes => ({
    type: SIGN_UP_SUCCEEDED
})
