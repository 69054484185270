import React, { PureComponent, ReactNode } from "react";
import './modal.css';
import 'react-image-crop/dist/ReactCrop.css';
import Slider from '@material-ui/core/Slider'
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import { i18n } from "../../../../configuration/i18n";

interface Props {
    url: string
    name: string
    close: () => void
    crop: (file: File) => void
    width: number
    height: number
}

interface State {
    imageSrc: string
    crop: Point
    zoom: number | number[]
    aspect: number
    croppedArea: Area,
    rotation: number | number[]
}

export class CropImageModal extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            imageSrc   : this.props.url,
            crop       : {x: 0, y: 0},
            zoom       : 1,
            aspect     : this.props.width / this.props.height,
            croppedArea: {
                x     : 0, y: 0,
                width : this.props.width,
                height: this.props.height
            },
            rotation   : 0
        }
    }

    render(): ReactNode {
        return (
            <div className="modal crop-modal default-modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="header">
                            <p className={'title'}>{i18n.common.edit_image}</p>
                            <span className={'close-button'} onClick={() => this.props.close()}/>
                        </div>

                        <div className="body px-3">
                            <div className={'cropContainer'}>
                                <Cropper
                                    image={this.state.imageSrc}
                                    crop={this.state.crop}
                                    zoom={Number(this.state.zoom)}
                                    rotation={Number(this.state.rotation)}
                                    objectFit={'contain'}
                                    aspect={this.state.aspect}
                                    onCropChange={(crop: Point): void => this.setState({crop})}
                                    onCropComplete={(croppedArea: Area, croppedAreaPixels: Area): void =>
                                        this.setState({croppedArea: croppedAreaPixels})}
                                    onZoomChange={(zoom: number | number[]): void => this.setState({zoom})}/>
                            </div>

                            <div className="controls d-flex px-3">
                                <label className={'sliderLabel'}>{i18n.common.zoom}</label>
                                <Slider value={this.state.zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e, zoom): void => this.setState({zoom})}/>
                            </div>

                            <div className="controls d-flex px-3">
                                <label className={'sliderLabel'}>{i18n.common.rotation}</label>
                                <Slider value={this.state.rotation}
                                        min={0}
                                        max={360}
                                        step={1}
                                        aria-labelledby="Rotation"
                                        onChange={(e, rotation) => this.setState({rotation})}/>
                            </div>

                            <div className={'text-center mt-2 mb-4'}>
                                <button className={'default-btn'}
                                        onClick={(event): void => {
                                            event.preventDefault()
                                            this.getCroppedImage()
                                        }}>{i18n.common.crop}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getCroppedImage(): void {
        this.getCroppedImg(
            this.state.imageSrc,
            this.state.croppedArea,
            Number(this.state.rotation)
        ).then(croppedImage => {
            if (croppedImage !== null)
                this.props.crop(croppedImage)
            else
                this.props.close()
        })
    }

    async getCroppedImg(imageSrc: string, pixelCrop: Area, rotation = 0): Promise<File | null> {
        const image: HTMLImageElement = new Image()
        image.src = imageSrc
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const maxSize = Math.max(image.width, image.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
        canvas.width = safeArea
        canvas.height = safeArea

        if (ctx !== null) {
            ctx.translate(safeArea / 2, safeArea / 2)
            ctx.rotate(Number((rotation * Math.PI) / 180))
            ctx.translate(-safeArea / 2, -safeArea / 2)

            ctx.drawImage(
                image,
                safeArea / 2 - image.width * 0.5,
                safeArea / 2 - image.height * 0.5
            )
            const data = ctx.getImageData(0, 0, safeArea, safeArea)

            canvas.width = pixelCrop.width
            canvas.height = pixelCrop.height

            ctx.putImageData(
                data,
                Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
                Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
            )
        }

        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                if (file !== null) {
                    const newPicture = new File([file], this.props.name, {type: 'image/png'});
                    resolve(newPicture)
                } else resolve(null)
            }, 'image/png')
        })
    }
}
