import { LoadLocalUserState } from "../../configuration/state";
import {
    LOAD_LOCAL_USER,
    LOAD_LOCAL_USER_FAILED,
    LOAD_LOCAL_USER_SUCCEEDED,
    LoadLocalUserActionTypes
} from "./actionTypes";

const initialState: LoadLocalUserState = {
    loading: false,
    user   : null,
    error  : undefined
}

export const loadLocalUserReducer = (state = initialState, action: LoadLocalUserActionTypes): LoadLocalUserState => {
    switch (action.type) {
        case LOAD_LOCAL_USER:
            return {
                loading: true,
                user   : null,
                error  : undefined
            }
        case LOAD_LOCAL_USER_SUCCEEDED:
            return {
                loading: false,
                user   : action.payload,
                error  : undefined
            }
        case LOAD_LOCAL_USER_FAILED:
            return {
                loading: false,
                user   : null,
                error  : action.payload
            }
        default:
            return state
    }
}
