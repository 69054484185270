import { CompanyMembership, CompanyMembershipType } from "../entity/companyMembership";
import { Business } from "../../../registration/domain/entity/business/business";

export class CompanyMembershipFactory {
    static getMemberShip(membership: CompanyMembershipType): CompanyMembership {
        switch (membership) {
            case "freemium":
                return new CompanyMembership(Business.FREEMIUM, 1, 5, 1, 0, false, false)
            default:
                throw new Error('thus you need to specify more')
        }
    }
}
