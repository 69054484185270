import {
    LOAD_AGENDA_INSTANTS,
    LOAD_AGENDA_INSTANTS_FAILED,
    LOAD_AGENDA_INSTANTS_SUCCEEDED,
    LoadAgendaInstantAction,
    LoadAgendaInstantFailedAction,
    LoadAgendaInstantSucceededAction
} from "./loadAgendaInstants.types";
import { InstantHeader } from "../../domain/entites/instantHeader";

export const loadAgendaInstants = (cityCode: string): LoadAgendaInstantAction => ({
    type   : LOAD_AGENDA_INSTANTS,
    payload: cityCode
})

export const loadAgendaInstantsSucceeded = (instants: InstantHeader[]): LoadAgendaInstantSucceededAction => ({
    type   : LOAD_AGENDA_INSTANTS_SUCCEEDED,
    payload: { instants }
})

export const loadAgendaInstantsFailed = (error: string): LoadAgendaInstantFailedAction => ({
    type   : LOAD_AGENDA_INSTANTS_FAILED,
    payload: error
})
