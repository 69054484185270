import React from "react";
import { EcommerceOrder } from "../../../../domain/entities/ecommerceOrder";

interface Props {
    order: EcommerceOrder;
    companyId: string | undefined;
}

export const OrderPaymentStatus = (props: Props): JSX.Element => {
    switch (props.order.paymentStatus) {
        case "userWalletSuccess":
            return <div className={"status completed-status"}>Complété</div>;
        case "depositSuccess":
            return <div className={"status in-progress-status"}>Cantonné</div>;
        case "inProgress":
            return <div className={"status in-progress-status"}>En cours</div>;
        case "none":
            return <div className={"status none-status"}>Aucun</div>;
        default:
            return <div className={"status none-status"}>Aucun</div>;
    }
};
