import {
    SEND_BROADCAST_MESSAGE, SEND_BROADCAST_MESSAGE_FAILED, SEND_BROADCAST_MESSAGE_SUCCEEDED,
    SendBroadcastMessageActionTypes
} from "./actionTypes";
import { ManageMessagingState } from "../../../configuration/state";

const initialState: ManageMessagingState = {
    success: null,
    loading: false,
    error  : undefined
}

export const sendBroadcastMessageReducer = (state = initialState, action: SendBroadcastMessageActionTypes): ManageMessagingState => {
    switch (action.type) {
        case SEND_BROADCAST_MESSAGE:
            return {
                success: null,
                loading: true,
                error  : undefined
            }
        case SEND_BROADCAST_MESSAGE_SUCCEEDED:
            return {
                success: true,
                loading: false,
                error  : undefined
            }
        case SEND_BROADCAST_MESSAGE_FAILED:
            return {
                success: false,
                loading: false,
                error  : action.payload
            }
        default:
            return state
    }
}
