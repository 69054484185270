import React, { PureComponent, ReactNode } from "react";
import { ChatMessage } from "../../../../domain/entities/chatMessage";
import { ClickCollectMessage } from "../clickCollect";
import { CompanyMessage } from "./companyMessage";
import { UserMessage } from "./userMessage";

interface Props {
    message: ChatMessage
}

export class MessageItem extends PureComponent<Props> {

    render(): ReactNode {
        if (this.props.message.user.isCompany()) {
            return <CompanyMessage message={this.props.message}
                                   isBroadcastMessage={this.isBroadcastMessage()}/>
        } else {
            if (this.isBroadcastMessage()) {
                return <ClickCollectMessage message={this.props.message}/>
            }
            return <UserMessage message={this.props.message}/>
        }
    }

    isBroadcastMessage(): boolean {
        const regexp = new RegExp(/#markdown\[.+\]$/gm);
        return regexp.test(this.props.message.content)
    }
}
