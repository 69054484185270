import { ContentPostType } from "../../../../../domain/entities/types/contentPostType";
import { connect } from "react-redux";
import { AppState } from "../../../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { SocialPageInfo } from "../../../../../../company/domain/entity/socialPageInfo";
import { facebookPagesSelector, findFacebookPagesErrorSelector } from "../../../../../../company/usecases/findFacebookPage/selectors";
import { FindFacebookPageAction } from "../../../../../../company/usecases/findFacebookPage/actionTypes";
import { findFacebookPage } from "../../../../../../company/usecases/findFacebookPage/actions";
import { PostInstantOnFacebookAction } from "../../../../../usecases/postOnFacebook/actionTypes";
import { postInstantOnFacebook } from "../../../../../usecases/postOnFacebook/actions";
import { postInstantOnFacebookLoadingSelector } from "../../../../../usecases/postOnFacebook/selectors";
import { PostFacebookModalContainer } from "./postFacebookModalContainer";

interface StateToPropsType {
    loading: boolean;
    facebookPages: SocialPageInfo[] | null;
    errorFindFacebookPages: string | undefined;
}

interface DispatchToPropsType {
    postInstant: (page: SocialPageInfo, content: ContentPostType) => void;
    findFacebookPages: () => void
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading            : postInstantOnFacebookLoadingSelector(state),
    facebookPages         : facebookPagesSelector(state),
    errorFindFacebookPages: findFacebookPagesErrorSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    postInstant  : (page: SocialPageInfo, content: ContentPostType): PostInstantOnFacebookAction => dispatch(postInstantOnFacebook(page, content)),
    findFacebookPages: (): FindFacebookPageAction => dispatch(findFacebookPage())
})

export const PostFacebookModal = connect(mapStateToProps, mapDispatchToProps)(PostFacebookModalContainer)
