import { Company } from "../../domain/entity/company";

export const EDIT_COMPANY = 'EDIT_COMPANY'
export const EDIT_COMPANY_SUCCEEDED = 'EDIT_COMPANY_SUCCEEDED'
export const EDIT_COMPANY_FAILED = 'EDIT_COMPANY_FAILED'

export interface EditCompanyAction {
    type: typeof EDIT_COMPANY;
    payload: Company;
}

export interface EditCompanySucceededAction {
    type: typeof EDIT_COMPANY_SUCCEEDED;
}

export interface EditCompanyFailedAction {
    type: typeof EDIT_COMPANY_FAILED;
    payload: string;
}

export type EditCompanyActionType = EditCompanyAction

export type EditCompanyActionTypes =
    EditCompanyAction
    | EditCompanySucceededAction
    | EditCompanyFailedAction
