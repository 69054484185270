import { UPDATE_STORE_COMPANY, UpdateStoreCompanyActionType } from "./actionType";
import { Company } from "../../domain/entity/company";

export const updateStoreCompanyReducer = (state: Company | null = null, action: UpdateStoreCompanyActionType): Company | null => {
    switch (action.type) {
        case UPDATE_STORE_COMPANY:
            return action.payload
        default:
            return state
    }
}
