export const MunicipalityI18n = {
    add_service    : 'Ajouter un service',
    edit_service   : 'Modifier le service',
    service_label  : 'Nom de service*',
    service_type   : 'Type de service*',
    service_link   : 'Lien*',
    service_content: 'Contenu*',
    service_icon   : 'Icon de service*',
    link_type      : 'Lien',
    page_type      : 'Page interne',
    publish        : 'Publier',
    error_msg      : 'Veuiller vérifier les données'
}
