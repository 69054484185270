export class EcommerceBuyer {
    constructor(private _name: string, private _email: string, private _phone: string) {
    }

    get name(): string {
        return this._name;
    }

    get email(): string {
        return this._email;
    }

    get phone(): string {
        return this._phone;
    }
}
