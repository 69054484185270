import { EcommerceBuyer } from "./ecommerceBuyer";
import { EcommerceOrder } from "./ecommerceOrder";
import { ProductDetails } from "./productDetails";

export class EcommerceOrderBuilder {
    private _id: string;
    private _price: number;
    private _transactionStatus: string;
    private _paymentStatus: string;
    private _createdAt: string;
    private _eventUrl: string;
    private _buyer: EcommerceBuyer;
    private _products: ProductDetails[];

    withId(value: string): EcommerceOrderBuilder {
        this._id = value;
        return this;
    }

    withPrice(value: number): EcommerceOrderBuilder {
        this._price = value;
        return this;
    }

    withTransactionStatus(value: string): EcommerceOrderBuilder {
        this._transactionStatus = value;
        return this;
    }

    withPaymentStatus(value: string): EcommerceOrderBuilder {
        this._paymentStatus = value;
        return this;
    }

    withCreatedAt(value: string): EcommerceOrderBuilder {
        this._createdAt = value;
        return this;
    }

    withBuyer(value: EcommerceBuyer): EcommerceOrderBuilder {
        this._buyer = value;
        return this;
    }

    withProducts(value: ProductDetails[]): EcommerceOrderBuilder {
        this._products = value;
        return this;
    }

    withEventUrl(value: string): EcommerceOrderBuilder {
        this._eventUrl = value;
        return this;
    }

    build(): EcommerceOrder {
        return new EcommerceOrder(
            this._id,
            this._price,
            this._transactionStatus,
            this._paymentStatus,
            this._createdAt,
            this._eventUrl,
            this._buyer,
            this._products
        );
    }
}
