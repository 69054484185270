import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RegistrationService } from "../../domain/gateway/registration.service";
import { AppState } from "../../../redux-configuration/AppState";
import { LOAD_BUSINESS_BY_CODE, LoadBusinessByCodeActionType } from "./actionTypes";
import { loadBusinessByCodeFailed, loadBusinessByCodeSucceeded } from "./actions";
import { Company } from "../../../company/domain/entity/company";

export const loadBusinessByCodeEpic: Epic = (action$: ActionsObservable<LoadBusinessByCodeActionType>,
                                             store: StateObservable<AppState>,
                                             {registrationService}: { registrationService: RegistrationService }) =>
    action$.pipe(
        ofType(LOAD_BUSINESS_BY_CODE),
        switchMap((action: { payload: { code: string, stringifiedInputs: string } }) => registrationService.loadBusinessByCode(action.payload.code, action.payload.stringifiedInputs)
            .pipe(
                map((business: Company) => loadBusinessByCodeSucceeded(business)),
                catchError(error => of(loadBusinessByCodeFailed(error)))
            )
        )
    )
