import React, { PureComponent, ReactNode } from "react";

interface Props {
    title: string;
    isActive: boolean
    icon: string
}

export class StepItem extends PureComponent<Props> {
    render(): ReactNode {
        const className = this.props.isActive ? 'multisteps-form__progress-btn js-active' : 'multisteps-form__progress-btn'
        return (
            <span className={className} title={this.props.title}>
                <i className={this.props.icon}/>
                <span>{this.props.title}</span>
            </span>
        )
    }
}
