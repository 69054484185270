import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { DELETE_PRODUCT, DeleteProductAction } from "./actionTypes";
import { deleteProductFailed, deleteProductSucceeded } from "./actions";
import { ProductService } from "../../domain/gateway/product.service";

export const deleteProductEpic: Epic = (action$: ActionsObservable<DeleteProductAction>,
                                        store: StateObservable<AppState>,
                                        {productService}: { productService: ProductService }) =>
    action$.pipe(
        ofType(DELETE_PRODUCT),
        switchMap(
            (action) => productService.deleteProduct(action.payload.productId, action.payload.companyId)
                .pipe(
                    map(() => deleteProductSucceeded()),
                    catchError(error => of(deleteProductFailed(error)))
                )
        )
    )
