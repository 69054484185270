import React, { PureComponent, ReactNode } from "react";
import Geocode from "react-geocode";
import { ManagementInstantFormType } from "../type/managementInstantFormType";
import { i18n } from "../../../../../configuration/i18n";
import { TextInput } from "../../../../../common/adapters/primaries/form/inputs/text.input";
import { ManagementInstantCoordinates } from "../../../../domain/entities/ManagementInstantCoordinates";
import { InstantFormValidator } from "../instantForm.validator";
import { MapField } from "../../../../../common/adapters/primaries/map/mapField";


interface Props {
    instant: ManagementInstantFormType;
    raiseUpdates: (key: keyof ManagementInstantFormType, value: string | ManagementInstantCoordinates | undefined) => void
    hasError: boolean
}

interface State {
    address: string;
    city: string
    zipCode: string
    latitude: string
    longitude: string
    addressError: boolean;
    cityError: boolean;
    zipCodeError: boolean;
}

export class InstantLocationSection extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            address     : '',
            city        : '',
            zipCode     : '',
            latitude    : this.props.instant.coordinates.latitude.toString(),
            longitude   : this.props.instant.coordinates.longitude.toString(),
            addressError: false,
            cityError   : false,
            zipCodeError: false
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (this.props.instant && this.props.instant !== prevProps.instant && prevState.address === '') {
            this.setState({
                address  : this.props.instant.address,
                city     : this.props.instant.city,
                zipCode  : this.props.instant.zip,
                latitude : this.props.instant.coordinates.latitude.toString(),
                longitude: this.props.instant.coordinates.longitude.toString()
            }, () => {
                Geocode.setApiKey("AIzaSyDq3EfG7M6rh2ajWdFZ5axtrWU8efmxstI");
                Geocode.setLanguage("fr");
                Geocode.setRegion("fr");
                Geocode.fromAddress(this.state.address + ', ' + this.state.city + ', ' + this.state.zipCode).then(
                    (response) => {
                        const {lat, lng} = response.results[0].geometry.location;
                        this.setState({
                            latitude : lat,
                            longitude: lng
                        })
                    })
            })
        }
        if (this.props.hasError) {
            this.setState({
                addressError: !InstantFormValidator.checkAddress(this.state.address),
                cityError   : !InstantFormValidator.checkCity(this.state.city),
                zipCodeError: !InstantFormValidator.checkZipCode(this.state.zipCode)
            })
        }
    }

    render(): ReactNode {
        return (
            <div className={'section_pro'}>
                <span className="wizard-sub-text">{i18n.management_instant.location_sub_title}</span>

                <div className={'px-3'}>
                    <TextInput type={'text'}
                               error={this.state.addressError}
                               label={i18n.management_instant.street}
                               value={this.state.address}
                               raiseUpdates={(address: string) => this.setState({
                                   address,
                                   addressError: false
                               }, () => {
                                   this.props.raiseUpdates('address', address)
                                   this.getCoordinatesFromAddress()
                               })
                               }/>

                    <TextInput type={'text'}
                               error={this.state.cityError}
                               label={i18n.management_instant.city}
                               value={this.state.city}
                               raiseUpdates={(city: string) => this.setState({
                                   city,
                                   cityError: false
                               }, () => {
                                   this.props.raiseUpdates('city', city)
                                   this.getCoordinatesFromAddress()
                               })}/>

                    <TextInput type={'text'}
                               error={this.state.zipCodeError}
                               label={i18n.management_instant.zipCode}
                               value={this.state.zipCode}
                               raiseUpdates={(zipCode: string) => this.setState({
                                   zipCode,
                                   zipCodeError: false
                               }, () => {
                                   this.props.raiseUpdates('zip', zipCode)
                                   this.getCoordinatesFromAddress()
                               })}/>

                    <div className="wizard-form-input map sub-section">
                        <MapField lat={Number(this.state.latitude)}
                                  lng={Number(this.state.longitude)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    getCoordinatesFromAddress(): void {
        if (this.state.address.length > 0 && this.state.city.length > 0 && this.state.zipCode.length === 5) {
            Geocode.setApiKey("AIzaSyDq3EfG7M6rh2ajWdFZ5axtrWU8efmxstI");
            Geocode.setLanguage("fr");
            Geocode.setRegion("fr");
            Geocode.fromAddress(this.state.address + ', ' + this.state.city + ', ' + this.state.zipCode).then(
                (response) => {
                    const {lat, lng} = response.results[0].geometry.location;
                    this.setState({
                        latitude : lat,
                        longitude: lng
                    }, () => {
                        this.props.raiseUpdates('coordinates', {
                            latitude : Number(this.state.latitude),
                            longitude: Number(this.state.longitude)
                        })
                    })
                }
            );
        } else {
            this.props.raiseUpdates('coordinates', {
                latitude : Number(this.state.latitude),
                longitude: Number(this.state.longitude)
            })
        }

    }
}
