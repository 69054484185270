import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { FormValidation } from "../../../../../../common/adapters/primaries/form/validation";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";
import { PhoneInputField } from "../../../../../../common/adapters/primaries/form/inputs/phoneInput";
import CitySelector from "../../components/inputs/city.selector";
import { CityHeader } from "../../../../../domain/entity/cityHeader";
import '../../registration.css'

interface Props {
    loading: boolean;
    error: string | undefined;
    hasError: boolean;
    cities: CityHeader [] | null
    raiseFormUpdates: (key: 'phoneNumber' | 'firstName' | 'lastName' | 'date' | 'email' | 'city' | 'zipCode', value: string) => void
}

interface State {
    date: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    city: string;
    zipCode: string;
    errorMsg: string | undefined;
    dateError: boolean;
    phoneNumberError: boolean;
    firstNameError: boolean;
    lastNameError: boolean;
    emailError: boolean;
    cityError: boolean;
}

export class SignUpForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            date            : '',
            phoneNumber     : '',
            firstName       : '',
            lastName        : '',
            email           : '',
            city            : '',
            zipCode         : '',
            errorMsg        : undefined,
            dateError       : false,
            phoneNumberError: false,
            firstNameError  : false,
            lastNameError   : false,
            emailError      : false,
            cityError       : false
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.hasError)
            this.setErrorMessage()

        if (this.props.error === '403' && prevProps.error !== this.props.error)
            this.setState({errorMsg: i18n.registration.contact_vivez_clichy})
        else if (this.props.error && prevProps.error !== this.props.error)
            this.setState({errorMsg: i18n.registration.server_error})
        else
            this.setState({errorMsg: ''})
    }

    componentDidMount(): void {
        this.initiateFormWithUrlParameters()
    }

    render(): ReactNode {
        const thePath = window.location.href
        const isAssociation = (new RegExp('association')).test(thePath)

        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        return (
            <div className="wizard-forms">
                {loader}
                <div className="inner pb-4 clearfix registration-section">

                    <div className="wizard-form-field">
                        <div className="wizard-title">
                            <div className={'title-registration'}>{i18n.registration.user_info}</div>
                            <p className="wizard-sub-text">{isAssociation ? i18n.registration.register_user_association : i18n.registration.register_user}</p>
                        </div>

                        <div className="px-3">
                            <TextInput type={'text'} className={'registration-input'}
                                       error={this.state.firstNameError}
                                       label={i18n.registration.firstName}
                                       value={this.state.firstName}
                                       raiseUpdates={(firstName: string) => {
                                           this.setState({
                                               firstName,
                                               firstNameError: false
                                           })
                                           this.props.raiseFormUpdates('firstName', firstName)
                                       }}/>

                            <TextInput type={'text'}
                                       className={'registration-input'}
                                       error={this.state.lastNameError}
                                       label={i18n.registration.lastName}
                                       value={this.state.lastName}
                                       raiseUpdates={(lastName: string) => {
                                           this.setState({
                                               lastName,
                                               lastNameError: false
                                           })
                                           this.props.raiseFormUpdates('lastName', lastName)
                                       }}/>

                            <PhoneInputField className={'registration-input'}
                                             error={this.state.phoneNumberError}
                                             label={i18n.registration.phoneNumber}
                                             value={this.state.phoneNumber}
                                             raiseUpdates={(phoneNumber: string) => {
                                                 this.setState({
                                                     phoneNumber,
                                                     phoneNumberError: false
                                                 })
                                                 this.props.raiseFormUpdates('phoneNumber', phoneNumber)
                                             }}/>

                            <TextInput type={'date'}
                                       className={'registration-input'}
                                       error={this.state.dateError}
                                       label={i18n.registration.birthday}
                                       value={this.state.date}
                                       raiseUpdates={(date: string) => {
                                           this.setState({date, dateError: false})
                                           this.props.raiseFormUpdates('date', date)
                                       }}/>

                            <CitySelector error={this.state.cityError}
                                          onChooseCity={(value: CityHeader | null) => this.onCityChange(value)}/>

                            <TextInput type={'text'}
                                       className={'registration-input'}
                                       error={this.state.emailError}
                                       label={i18n.registration.email}
                                       value={this.state.email}
                                       raiseUpdates={(email: string) => {
                                           this.setState({
                                               email,
                                               emailError: false
                                           })
                                           this.props.raiseFormUpdates('email', email)
                                       }}/>

                            {this.state.errorMsg && <p className={'error'}
                                                       dangerouslySetInnerHTML={{__html: this.state.errorMsg}}/>}
                        </div>

                        <div className={'login-form-with cgu'}>
                            <p>{i18n.registration.inscription_info}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    setErrorMessage(): void {
        const phoneNumber = this.state.phoneNumber.indexOf('+') !== -1 ? this.state.phoneNumber : '+' + this.state.phoneNumber
        this.setState({
            dateError       : !FormValidation.validateBirthDay(this.state.date),
            phoneNumberError: !FormValidation.validate('phoneNumber', phoneNumber),
            firstNameError  : !FormValidation.validate('name', this.state.firstName),
            lastNameError   : !FormValidation.validate('name', this.state.lastName),
            emailError      : !FormValidation.validateEmail(this.state.email),
            cityError       : !this.isValidCity()
        }, () => {
            this.setState({errorMsg: this.renderError()})
        })
    }

    renderError(): string | undefined {
        if (this.state.firstName.length === 0 ||
            this.state.lastName.length === 0 ||
            this.state.date.length === 0)
            return i18n.registration.empty_form_error
        else if (this.state.city.length === 0) {
            this.setState({errorMsg: i18n.registration.address_city_error})
            return i18n.registration.address_city_error
        } else if (this.state.lastNameError || this.state.firstNameError)
            return i18n.registration.name_format_error
        else if (this.state.phoneNumberError)
            return i18n.registration.phone_number_error
        else if (this.state.dateError)
            return i18n.registration.birthday_error
        else if (this.state.emailError)
            return i18n.registration.email_format_error
        else if (this.state.cityError)
            return i18n.registration.zipCode_format_error
    }

    onCityChange(value: CityHeader | null): void {
        if (value) {
            this.setState({zipCode: value.zipcode.toString()},
                () => {
                    this.props.raiseFormUpdates('zipCode', value.zipcode.toString())
                    this.setState({city: value.name}, () => {
                        this.props.raiseFormUpdates('city', value.name)
                    })
                })
        } else {
            this.setState({zipCode: ''})
        }
    }

    isValidCity(): boolean {
        return !!this.state.city && !!this.state.zipCode
            && this.state.city.length > 0 && this.state.zipCode.length === 5
            && !isNaN(parseInt(this.state.zipCode))
    }

    initiateFormWithUrlParameters(): void {
        const paramsString = window.location.search
        const searchParams = new URLSearchParams(paramsString);

        if (paramsString.length > 0) {
            const userEmail = searchParams.get('useremail')
            const userFirstName = searchParams.get('userfirstname')
            const userLastName = searchParams.get('username')
            const userPhoneNumber = "+" + searchParams.get("userphone")?.trim();

            if (userEmail && userEmail !== 'NULL') {
                this.setState({email: userEmail})
                this.props.raiseFormUpdates('email', userEmail)
            }
            if (userFirstName && userFirstName !== 'NULL') {
                this.setState({firstName: userFirstName})
                this.props.raiseFormUpdates('firstName', userFirstName)
            }
            if (userLastName && userLastName !== 'NULL') {
                this.setState({lastName: userLastName})
                this.props.raiseFormUpdates('lastName', userLastName)
            }
            if (FormValidation.validatePhoneNumberFix(userPhoneNumber)) {
                this.setState({phoneNumber: userPhoneNumber})
                this.props.raiseFormUpdates('phoneNumber', userPhoneNumber)
            }

        }
    }
}
