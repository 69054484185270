import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    visible: boolean;
    onClose: () => void;
    cancelOrder: (message: string) => void
    success: boolean;
    loading: boolean;
    error: string | undefined;
}

interface State {
    value: string;
    errorMsg: string | undefined;
}

export class CancelOrderModal extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            value   : '',
            errorMsg: undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.error && this.props.error !== prevProps.error) {
            this.handleErrorMsg(this.props.error)
        }

        if (this.props.success && this.props.success !== prevProps.success) {
            this.closeModal()
        }
    }

    render(): ReactNode {
        if (this.props.visible)
            return (
                <div className="modal order-modal default-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className={'header'}>
                                <h3 className={'title'}>{i18n.messaging.order_cancellation}</h3>
                                <span className={'close-button'} onClick={() => this.closeModal()}/>
                            </div>

                            <div className={'body'}>
                                <label>{i18n.messaging.cancel_msg}</label>

                                <textarea rows={5}
                                          className={this.state.errorMsg ? 'w-100 error' : 'w-100'}
                                          value={this.state.value}
                                          placeholder={'...'}
                                          onChange={e => this.setState({
                                              value   : e.target.value,
                                              errorMsg: ''
                                          })}/>

                                {this.props.loading && (
                                    <div className={'modal-loader'}>
                                        <div className={'loader'}/>
                                    </div>
                                )}

                                {this.state.errorMsg && <p className={'error-msg'}>{this.state.errorMsg}</p>}

                                <div className={'d-flex justify-content-end mt-3'}>
                                    <button className={'default-btn back-btn mr-3'} onClick={() => this.closeModal()}>
                                        {i18n.messaging.back}
                                    </button>

                                    <button className={'default-btn'} onClick={() => this.cancelOrder(this.state.value)}>
                                        {i18n.messaging.send}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        return <div/>
    }

    cancelOrder(message: string): void {
        if (message.length > 0) {
            this.props.cancelOrder(message)
        } else {
            this.setState({errorMsg: i18n.messaging.empty_message_error})
        }
    }

    handleErrorMsg(error: string): void {
        if (error === '409') {
            this.setState({errorMsg: i18n.messaging.error_message_409})
        } else if (error === '404') {
            this.setState({errorMsg: i18n.messaging.error_message_404})
        } else if (error === '401') {
            this.setState({errorMsg: i18n.messaging.error_message_401})
        }
    }

    closeModal(): void {
        this.props.onClose()
        this.setState({
            value   : '',
            errorMsg: undefined
        })
    }
}
