import React, { PureComponent, ReactNode } from 'react'
import './component/tableOffrePro.css'
import { HeaderButtonsSubscription } from "./component/headerButtonsSubscription";
import { SubscriptionProHeaderTable } from "./component/subscriptionProHeaderTable";
import { SubscriptionsProBodyTable } from "./component/subscriptionsProBodyTable";
import { SubscriptionProFooterTable } from "./component/subscriptionProFooterTable";
import {
    subscriptionProData,
} from "../../data/subscriptionProData";
import { SubscriptionItem } from "./component/subscriptionItem";
import {
    SubscriptionPeriodicity,
    SubscriptionId,
    SubscriptionType
} from "../../../../domain/entities/types/subscription.type";
import { Company } from "../../../../../company/domain/entity/company";
import { loadStripe, Stripe } from '@stripe/stripe-js';


interface Props {
    preferredOffer: SubscriptionId;
    company: Company | null;
    loading: boolean;
    sessionId: string | undefined;
    paymentError: string | undefined;
    retrievePaymentSession: (companyId: string, subscriptionId: SubscriptionId, frequency: SubscriptionPeriodicity) => void;
}

interface State {
    isPreferred: SubscriptionId;
    isMonthly: boolean;
}

export class SubscriptionTableContainer extends PureComponent <Props, State> {
    private wasRedirected: boolean
    private stripePromise: Promise<Stripe | null>;

    constructor(props: Props) {
        super(props);
        this.state = {
            isPreferred: SubscriptionId.PREMIUM,
            isMonthly  : true
        }
        this.wasRedirected = false
    }

    componentDidUpdate(): void {
        if (this.props.paymentError) {
            window.scrollTo({
                top     : 0,
                left    : 0,
                behavior: "smooth"
            });
        }

        if (this.props.sessionId && !this.wasRedirected) {
            this.handlePayment()
            this.wasRedirected = true
        }
    }

    componentDidMount(): void {
        if (process.env.REACT_APP_STRIPE_PUB_KEY) {
            this.stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
        }
    }


    render(): ReactNode {
        const loader = this.props.loading ? (
            <div className={'d-flex justify-content-center align-items-center payment-loader loader-mi-transparent'}>
                <div className={'loader'}/>
            </div>
        ) : null

        const error = this.props.paymentError ? (
            <div className="alert alert-danger smooth" role="alert">
                {this.props.paymentError}
            </div>
        ) : <></>
        return (
            <div className={'subscription-container'}>
                {loader}
                {error}
                <HeaderButtonsSubscription onPressMonthly={() => this.setState({isMonthly: true})}
                                           onPressYearly={() => this.setState({isMonthly: false})}
                                           isMonthly={this.state.isMonthly}/>
                <div className="portlet-body">
                    <table className="table">
                        <SubscriptionProHeaderTable isMonthly={this.state.isMonthly}
                                                    preferredOffer={this.props.preferredOffer}/>
                        <tbody>
                        <SubscriptionsProBodyTable preferredOffer={this.props.preferredOffer}/>
                        <SubscriptionProFooterTable
                            onPress={(id: SubscriptionId) => this.sendSubscriptionOffer(id)}/>
                        </tbody>
                    </table>
                </div>
                <div className={'subscription-mobile-version row text-center'}>
                    {subscriptionProData.map((item: SubscriptionType) => {
                        return <SubscriptionItem isMonthly={this.state.isMonthly} subscription={item}
                                                 key={item.id}
                                                 isPreferred={this.props.preferredOffer === item.id}
                                                 onPress={() => this.sendSubscriptionOffer(item.id)}>

                        </SubscriptionItem>
                    })}
                </div>
            </div>
        )
    }

    sendSubscriptionOffer(id: SubscriptionId): void {
        if (this.props.company) {
            const periodicity = this.state.isMonthly ? SubscriptionPeriodicity.MONTHLY : SubscriptionPeriodicity.ANNUAL
            this.props.retrievePaymentSession(this.props.company.id, id, periodicity)
        }
    }

    handlePayment(): void {

        this.stripePromise.then((stripe) => {
            if (stripe && this.props.sessionId) {
                stripe.redirectToCheckout({sessionId: this.props.sessionId})
                    .then(() => void 0)
            }
        })
    }
}

