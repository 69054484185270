import React, { PureComponent, ReactNode } from "react";
import { CompanyOpeningHours } from "../../../../domain/entity/companyOpeningHours";
import { CompanyOpeningDays, CompanySpecifications } from "./company.specifications";
import { i18n } from "../../../../../configuration/i18n";
import { CompanyOpeningDay } from "./company.openingDay";

interface Props {
    openingHours: CompanyOpeningHours[]
}

export class CompanyOpeningHoursPresentational extends PureComponent<Props> {

    render(): ReactNode {
        if (this.props.openingHours.length > 0) {
            const businessHours = CompanySpecifications.getCompanyOpeningDays(this.props.openingHours).map((openingHour: CompanyOpeningDays, index) => {
                return <CompanyOpeningDay openingHour={openingHour} key={index}/>
            })

            return (
                <div className="col-md-4">
                    <div className="services-right">
                        <h3 className="title">{i18n.company.opening_hours}</h3>

                        <div className="single-services">
                            {businessHours}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="col-md-4">
                <p className={'text-right clichy-blue pt-4 pr-2'} style={{fontWeight: 600}}>{i18n.company.temporary_closed}</p>
            </div>
        )
    }
}
