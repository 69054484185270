export class User {
    constructor(
        private _firstName: string,
        private _lastName: string,
        private _phoneNumber: string,
        private _birthDate: Date,
        private _email: string,
        private _city: string,
        private _zipCode: string
    ) {}

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    get birthDate(): Date {
        return this._birthDate
    }

    get email(): string {
        return this._email
    }

    get city(): string {
        return this._city;
    }

    get zipCode(): string{
        return this._zipCode
    }
}
