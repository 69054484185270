import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RegistrationRepository } from "../../domain/gateway/registration.repository";
import { LOAD_LOCAL_CODE, LoadLocalCodeActionType } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { loadLocalCodeFailed, loadLocalCodeSucceeded } from "./actions";

export const loadLocalCodeEpic: Epic = (action$: ActionsObservable<LoadLocalCodeActionType>,
                                            store: StateObservable<AppState>,
                                            { registrationRepository }: { registrationRepository: RegistrationRepository }) =>
    action$.pipe(
        ofType(LOAD_LOCAL_CODE),
        switchMap(() => registrationRepository.getConfirmationCode()
            .pipe(
                map((code: string) => loadLocalCodeSucceeded(code)),
                catchError(err => of(loadLocalCodeFailed(err)))
            )
        )
    )

