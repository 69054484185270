
export class CityHeader{
    constructor(
        protected _name: string,
        protected _zipcode: number
    ) {
    }

    get name(): string {
        return this._name;
    }

    get zipcode(): number {
        return this._zipcode;
    }
}
