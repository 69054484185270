import { ManagementInstant } from "../../domain/entities/ManagementInstant";

export const EDIT_INSTANT = 'EDIT_INSTANT'
export const EDIT_INSTANT_SUCCEEDED = 'EDIT_INSTANT_SUCCEEDED'
export const EDIT_INSTANT_FAILED = 'EDIT_INSTANT_FAILED'

export interface EditInstantAction {
    type: typeof EDIT_INSTANT;
    payload: ManagementInstant;
}

export interface EditInstantSucceededAction {
    type: typeof EDIT_INSTANT_SUCCEEDED;
}

export interface EditInstantFailedAction {
    type: typeof EDIT_INSTANT_FAILED;
    payload: string;
}

export type EditInstantActionTypes = EditInstantAction | EditInstantSucceededAction | EditInstantFailedAction
