import { MunicipalService } from "../../domain/entities/municipalService";
import {
    ADD_MUNICIPAL_SERVICE, ADD_MUNICIPAL_SERVICE_FAILED, ADD_MUNICIPAL_SERVICE_SUCCEEDED, AddMunicipalServiceAction, AddMunicipalServiceFailedAction,
    AddMunicipalServiceSucceededAction
} from "./actionTypes";

export const addMunicipalService = (service: MunicipalService, cityId: string): AddMunicipalServiceAction => ({
    type   : ADD_MUNICIPAL_SERVICE,
    payload: {service, cityId}
})

export const addMunicipalServiceFailed = (error: string): AddMunicipalServiceFailedAction => ({
    type   : ADD_MUNICIPAL_SERVICE_FAILED,
    payload: error
})

export const addMunicipalServiceSucceeded = (): AddMunicipalServiceSucceededAction => ({
    type: ADD_MUNICIPAL_SERVICE_SUCCEEDED
})
