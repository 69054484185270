import {
    LOAD_LOCAL_USER, LOAD_LOCAL_USER_FAILED,
    LOAD_LOCAL_USER_SUCCEEDED,
    LoadLocalUserActionTypes
} from "./actionTypes";
import { User } from "../../domain/entity/user";

export const loadLocalUser = (): LoadLocalUserActionTypes => ({
    type: LOAD_LOCAL_USER
})

export const loadLocalUserSucceeded = (user: User): LoadLocalUserActionTypes => ({
    type   : LOAD_LOCAL_USER_SUCCEEDED,
    payload: user
})

export const loadLocalUserFailed = (error: string): LoadLocalUserActionTypes => ({
    type   : LOAD_LOCAL_USER_FAILED,
    payload: error
})
