import { SendConfirmationCodeState } from "../../configuration/state";
import {
    SEND_CONFIRMATION_CODE,
    SEND_CONFIRMATION_CODE_FAILED,
    SEND_CONFIRMATION_CODE_SUCCEEDED,
    SendConfirmationCodeActionTypes
} from "./actionTypes";

const initialState: SendConfirmationCodeState = {
    loading: false,
    token  : undefined,
    error  : undefined,
}

export const sendConfirmationCodeReducer = (state = initialState, action: SendConfirmationCodeActionTypes): SendConfirmationCodeState => {
    switch (action.type) {
        case SEND_CONFIRMATION_CODE:
            return {
                loading: true,
                token  : undefined,
                error  : undefined
            }
        case SEND_CONFIRMATION_CODE_SUCCEEDED:
            return {
                loading: false,
                token  : action.payload,
                error  : undefined
            }
        case SEND_CONFIRMATION_CODE_FAILED:
            return {
                loading: false,
                token  : undefined,
                error  : action.payload
            }
        default:
            return state
    }
}
