import { OrderDTO } from '../DTO/order.dto';
import { ApplicationContext } from "../../../../../configuration/application.context";
import { OrderBuilder } from "../../../../domain/builders/order.builder";
import { Order } from "../../../../domain/entities/order";

const moment = ApplicationContext.getInstance().momentJs()

export class ClickCollectMapper {

    static mapOrderDtoToOrderDomain = (data: OrderDTO, orderId: string): Order => {
        const minOrder = data.minOrder !== '' ? data.minOrder : '0'
        const orderDate = moment.parseZone(data.customerPickupDateTime, 'YYYY-MM-DDTHH:mm:ssZ').format()
        return new OrderBuilder()
            .withId(orderId)
            .withContent(data.customerOrderContent)
            .withTime(orderDate)
            .withCoupon(data.couponMessage !== '')
            .withTextCoupon(data.couponMessage)
            .withMinOrder(minOrder)
            .build()
    }

}
