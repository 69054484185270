import React from "react";
import Select from 'react-select';
import { SelectOptionType } from "../../../../../common/domain/types/selectOption.type";
import { defaultFont, ThemeFonts } from "../types";


interface Props {
    value: string;
    label: string;
    error: boolean;
    onChange: (value: string) => void
}

export const ThemeFontSelector = (props: Props): JSX.Element => {

    const error = props.error ? 'error' : ''

    const getValueSelected = (label: string): SelectOptionType => {
        return ThemeFonts.find(item => item.label === label) || defaultFont
    }

    return (
        <div className={"wizard-form-input select-option-area"}>
            <label>{props.label}</label>

            <Select value={getValueSelected(props.value)}
                    className={'select-category ' + error}
                    classNamePrefix="select"
                    isSearchable={true}
                    options={ThemeFonts}
                    onChange={selected => {
                        if (selected) {
                            props.onChange(selected.label)
                        }
                    }}/>
        </div>
    )
}
