import { Company } from "../../../company/domain/entity/company";
import { User } from "../../domain/entity/user";

export const REGISTER_BUSINESS = 'REGISTER_BUSINESS'
export const REGISTER_BUSINESS_FAILED = 'REGISTER_BUSINESS_FAILED'
export const REGISTER_BUSINESS_SUCCEEDED = 'REGISTER_BUSINESS_SUCCEEDED'

export interface RegisterBusinessAction {
    type: typeof REGISTER_BUSINESS;
    payload: { business: Company; user: User; originalProspectEmail: string | null };
}

export interface RegisterBusinessSucceededAction {
    type: typeof REGISTER_BUSINESS_SUCCEEDED;
    payload: string;
}

export interface RegisterBusinessFailedAction {
    type: typeof REGISTER_BUSINESS_FAILED;
    payload: string;
}

export type RegisterBusinessActionTypes =
    RegisterBusinessAction
    | RegisterBusinessFailedAction
    | RegisterBusinessSucceededAction
