import React, { PureComponent, ReactNode } from "react";
import { AdministrativeNumber } from "../../../../../domain/entity/business/administrativeNumber";
import NumberFormat from "react-number-format";

interface Props {
    error: boolean
    label: string
    value: AdministrativeNumber
    raiseUpdates: (code: string) => void
    className?: string

}

export class AdministrativeNumberInput extends PureComponent<Props> {

    render(): ReactNode {
        const error = this.props.error ? 'error' : undefined
        const classContainer = this.props.className ? this.props.className + ' wizard-form-input' : 'wizard-form-input'
        return (
            <div className={classContainer}>
                <label>{this.props.label}</label>
                {this.props.value && this.props.value.type() === 'RNA' ?
                    <input type={'text'}
                           value={this.props.value.number}
                           className={error}
                           placeholder={'W.........'}
                           maxLength={this.props.value.length()}
                           onChange={(event) => this.handleChange(event.target.value)}/> :
                    <NumberFormat displayType="input"
                                  type="text"
                                  format={'### ### ### #####'}
                                  value={this.props.value.number}
                                  className={error}
                                  onValueChange={(values) => this.props.raiseUpdates(values.value)}
                    />
                }
            </div>
        )
    }

    private handleChange(str: string) {
        const cleanStr = str
            .replace(/\s/g, '')
            .replace(/^\d{9}$/, 'w$&')
        this.props.raiseUpdates(cleanStr);
    }
}
