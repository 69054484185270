import { AppState } from "../../../../redux-configuration/AppState";
import { connect } from "react-redux";
import { AppSideBar } from "./sidebar";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector, isSuperAdminCompany } from "../../../../company/usecases/updateStoreCompany/selector";

interface StateToPropsType {
    company: Company | null;
    isSuperAdmin: boolean;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company     : companySelector(state),
    isSuperAdmin: isSuperAdminCompany(state)
})

export const SideBar = connect(mapStateToProps)(AppSideBar)
