import React, { PureComponent, ReactNode } from "react";
import { InstantCategories } from "../../../../../domain/entities/types/managementInstantCategoryType";
import { i18n } from "../../../../../../configuration/i18n";
import { SelectOptionType } from "../../../../../../common/domain/types/selectOption.type";
import Select from 'react-select';
import { InstantCategoryModal } from "./category.modal";

interface Props {
    onChange: (value: string) => void
    error: boolean
    selected: string
}

interface State {
    category: string;
    visible: boolean;
}

export class InstantCategoriesSelector extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            category: '',
            visible : false
        }
    }

    render(): ReactNode {
        const error = this.props.error ? 'error select-category' : 'select-category'
        return (
            <div className="wizard-form-input select-option-area">
                <label>{i18n.management_instant.choose_category}</label>

                <Select className={error}
                        classNamePrefix="select"
                        isSearchable={true}
                        value={this.getValueSelected(this.props.selected)}
                        options={this.categoriesOptionList()}
                        onChange={(category: SelectOptionType | null) => {
                            if (category !== null) {
                                this.props.onChange(category.value)
                                this.setState({
                                    category: category.value,
                                    visible : true
                                })
                            }
                        }}/>

                <InstantCategoryModal category={this.state.category}
                                      visible={this.state.visible}
                                      onClose={(): void => this.setState({ visible: false })}/>
            </div>
        )
    }

    categoriesOptionList(): SelectOptionType[] {
        return Object.keys(InstantCategories).map(category => ({ label: InstantCategories[category], value: category }))
    }

    getValueSelected(value: string): SelectOptionType | undefined {
        return this.categoriesOptionList().find(item => item.value === value)
    }
}
