import {
    POST_INSTANT_ON_FACEBOOK, POST_INSTANT_ON_FACEBOOK_FAILED, POST_INSTANT_ON_FACEBOOK_SUCCEEDED, PostInstantOnFacebookAction, PostInstantOnFacebookFailedAction, PostInstantOnFacebookSucceededAction
} from "./actionTypes";
import { ContentPostType } from "../../domain/entities/types/contentPostType";
import { SocialPageInfo } from "../../../company/domain/entity/socialPageInfo";


export const postInstantOnFacebook = (page: SocialPageInfo, post: ContentPostType): PostInstantOnFacebookAction =>({
    type: POST_INSTANT_ON_FACEBOOK,
    payload: {page, content: post}
})

export const postInstantOnFacebookFailed =(error: string): PostInstantOnFacebookFailedAction =>({
    type: POST_INSTANT_ON_FACEBOOK_FAILED,
    payload: error
})

export const postInstantOnFacebookSucceeded =():PostInstantOnFacebookSucceededAction =>({
    type: POST_INSTANT_ON_FACEBOOK_SUCCEEDED
})
