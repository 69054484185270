import React from "react";

interface Props {
    imageUrl: string;
    removeImage: () => void;
}

export const GalleryImageInput = (props: Props): JSX.Element => {
    return (
        <div className={'img-container'}>
            <img src={props.imageUrl} alt={"gallery"}/>

            <div className={'img-overlay d-flex align-items-center justify-content-center'}>
                <button type="button"
                        onClick={() => props.removeImage()}>
                    Supprimer
                </button>
            </div>
        </div>
    )
}
