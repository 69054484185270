import {
    LOAD_INSTANTS_COMPANY, LOAD_INSTANTS_COMPANY_FAILED,
    LOAD_INSTANTS_COMPANY_SUCCEEDED,
    LoadInstantsCompanyAction, LoadInstantsCompanyFailedAction, LoadInstantsCompanySucceededAction
} from "./loadInstantsCompany.types";
import { ManagementInstantHeader } from "../../domain/entities/managementInstantHeader";

export const loadInstantsCompany = (companyId: string): LoadInstantsCompanyAction => ({
    type   : LOAD_INSTANTS_COMPANY,
    payload: companyId
})

export const loadInstantsCompanySucceeded = (instants: ManagementInstantHeader[]): LoadInstantsCompanySucceededAction => ({
    type   : LOAD_INSTANTS_COMPANY_SUCCEEDED,
    payload: instants
})

export const loadInstantsCompanyFailed = (error: string): LoadInstantsCompanyFailedAction => ({
    type   : LOAD_INSTANTS_COMPANY_FAILED,
    payload: error
})
