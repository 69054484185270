import {
    SET_REGISTRATION_STEP,
    SetRegistrationStepAction
} from "./actionTypes";


export const setRegistrationStep = (step: number): SetRegistrationStepAction => ({
    type   : SET_REGISTRATION_STEP,
    payload: step
})
