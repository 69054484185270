import { Company } from "../../domain/entity/company";

export const UPDATE_STORE_COMPANY = 'UPDATE_STORE_COMPANY'

export interface UpdateStoreCompanyAction {
    type: typeof UPDATE_STORE_COMPANY;
    payload: Company;
}

export type UpdateStoreCompanyActionType = UpdateStoreCompanyAction
