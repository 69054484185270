import { connect } from "react-redux";
import { MunicipalService } from "../../../domain/entities/municipalService";
import { Dispatch } from "redux";
import { AddMunicipalServiceAction } from "../../../usecases/addMunicipalService/actionTypes";
import { addMunicipalService } from "../../../usecases/addMunicipalService/actions";
import { AppState } from "../../../../redux-configuration/AppState";
import { addMunicipalServiceErrorSelector, addMunicipalServiceLoadingSelector } from "../../../usecases/addMunicipalService/selectors";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import { ServiceIcon } from "../../../domain/entities/serviceIcon";
import { serviceIconsSelector } from "../../../usecases/listingMunicipalServices/selectors";
import { FormServiceContainer } from "./formService.container";
import { EditMunicipalServiceAction } from "../../../usecases/editMunicipalService/actionTypes";
import { editMunicipalService } from "../../../usecases/editMunicipalService/actions";
import { editMunicipalServiceErrorSelector, editMunicipalServiceLoadingSelector } from "../../../usecases/editMunicipalService/selectors";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined;
    cityId: string | undefined;
    icons: ServiceIcon[] | undefined;
}

interface DispatchToPropsType {
    addService: (service: MunicipalService, cityId: string) => void;
    editService: (service: MunicipalService) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: addMunicipalServiceLoadingSelector(state) || editMunicipalServiceLoadingSelector(state),
    error  : addMunicipalServiceErrorSelector(state) || editMunicipalServiceErrorSelector(state),
    cityId : companySelector(state)?.id,
    icons  : serviceIconsSelector(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    addService : (service: MunicipalService, cityId: string): AddMunicipalServiceAction => dispatch(addMunicipalService(service, cityId)),
    editService: (service: MunicipalService): EditMunicipalServiceAction => dispatch(editMunicipalService(service))
})
export const FormServiceModal = connect(mapStateToProps, mapDispatchToProps)(FormServiceContainer)
