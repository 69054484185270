import React, { useEffect, useState } from "react";

interface Props {
    previousUrl: string;
    companyId: string;
    loading: boolean;
    error: string | undefined;
    newFileUrl: string | undefined;
    uploadImage: (image: File, companyId: string) => void;
    onChange: (value: string) => void;
}

export const ThemeImageInputContainer = (props: Props): JSX.Element => {

    const [hasNewImage, setHasNewImage] = useState<boolean>(false)

    useEffect(() => {
        if (props.newFileUrl && hasNewImage) {
            props.onChange(props.newFileUrl)
            setHasNewImage(false)
        }
    }, [props.newFileUrl])

    const loading = props.loading && hasNewImage ? ' loading' : ''

    return (
        <div className={'logo-input wizard-form-input align-items-start mb-0'}>
            <label className={'mt-3 text-left'}>Image de background</label>

            <div className={'img-container'}>
               <img src={props.previousUrl} alt={"Boutique"}/>

                <div className={'img-overlay d-flex align-items-center justify-content-center' + loading}>
                    {props.loading && hasNewImage ? <div className={'image-loader loader'}/> : (
                        <div className={'add-img-btn'}>
                            <span>Changer</span>

                            <input type="file" onChange={e => {
                                if (e.target.files?.[0]) {
                                    setHasNewImage(true)
                                    props.uploadImage(e.target.files[0], props.companyId)
                                }
                            }}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
