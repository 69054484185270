import { LoadMessageState } from "../../../configuration/state";
import {
    LOAD_NEW_CHAT_MESSAGES, LOAD_NEW_CHAT_MESSAGES_FAILED, LOAD_NEW_CHAT_MESSAGES_SUCCEEDED,
    LoadNewChatMessagesActionTypes
} from "./actionTypes";

const initialState: LoadMessageState = {
    loading : false,
    messages: null,
    error   : undefined
}

export const loadNewChatMessagesReducer = (state = initialState, action: LoadNewChatMessagesActionTypes): LoadMessageState => {
    switch (action.type) {
        case LOAD_NEW_CHAT_MESSAGES:
            return {
                loading : true,
                messages: null,
                error   : undefined
            }
        case LOAD_NEW_CHAT_MESSAGES_SUCCEEDED:
            return {
                loading : false,
                messages: action.payload,
                error   : undefined
            }
        case LOAD_NEW_CHAT_MESSAGES_FAILED:
            return {
                loading : false,
                messages: null,
                error   : action.payload
            }
        default:
            return state
    }
}
