import { ProductGalleryImage } from "./productGalleryImage";

export class ProductDetails {

    constructor(
        private _id: string,
        private _name: string,
        private _image: string | File,
        private _category: string,
        private _price: number,
        private _stock: number,
        private _isActive: boolean,
        private _gallery: (ProductGalleryImage | File | string)[],
        private _sku: string,
        private _description: string,
        private _shortDescription: string,
        private _weight: number,
        private _length: number,
        private _width: number,
        private _height: number,
        private _createdAt: Date,
        private _updatedAt: Date
    ) {
    }

    get id(): string {
        return this._id
    }

    get name(): string {
        return this._name
    }

    get image(): string | File {
        return this._image
    }

    get category(): string {
        return this._category
    }

    get price(): number {
        return this._price
    }

    get stock(): number {
        return this._stock
    }

    get isActive(): boolean {
        return this._isActive
    }

    get gallery(): (ProductGalleryImage | File | string)[] {
        return this._gallery
    }

    get sku(): string {
        return this._sku
    }

    get description(): string {
        return this._description
    }

    get shortDescription(): string {
        return this._shortDescription
    }

    get length(): number {
        return this._length;
    }

    get width(): number {
        return this._width;
    }

    get height(): number {
        return this._height;
    }

    get weight(): number {
        return this._weight
    }

    get createdAt(): Date {
        return this._createdAt
    }

    get updatedAt(): Date {
        return this._updatedAt
    }

}
