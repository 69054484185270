import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../../configuration/i18n";
import { ManagementInstantHeader } from "../../../../../domain/entities/managementInstantHeader";
import { ContentPostType } from "../../../../../domain/entities/types/contentPostType";
import { SocialPageInfo } from "../../../../../../company/domain/entity/socialPageInfo";
import './postInstagramModal.css'
import { PreviewInstagramPost } from "./components/previewInstagramPost";
import { PostInstagramForm } from './components/postInstagramForm';

interface Props {
    visible: boolean
    onClose: () => void
    instant: ManagementInstantHeader;
    loading: boolean;
    instagramAccounts: SocialPageInfo[] | null;
    errorFindInstagramAccount: string | undefined;
    postInstant: (page: SocialPageInfo, content: ContentPostType) => void;
    findInstagramAccounts: () => void
}

interface State {
    checked: boolean
    instagramAccount: SocialPageInfo | undefined
    errorMessage: string
    textPost: string
}

export class PostInstagramModalContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            checked         : false,
            instagramAccount: this.props.instagramAccounts && this.props.instagramAccounts.length === 1 ? this.props.instagramAccounts[0] : undefined,
            textPost        : this.props.instant.description,
            errorMessage    : ''
        }

    }


    componentDidUpdate(prevProps: Props): void {
        if (this.props.errorFindInstagramAccount === 'NO_PAGE_FOUND')
            this.setState({errorMessage: i18n.management_instant.instagram_account_not_found})
        else if (prevProps.errorFindInstagramAccount !== this.props.errorFindInstagramAccount && this.props.errorFindInstagramAccount === 'NOT_CONNECTED') {
            this.setState({errorMessage: i18n.management_instant.not_connected_instagram})
        }
        if (prevProps.instagramAccounts !== this.props.instagramAccounts && this.props.instagramAccounts)
            this.setState({
                instagramAccount: this.props.instagramAccounts.length === 1 ? this.props.instagramAccounts[0] : undefined,
                checked         : this.props.instagramAccounts.length === 1,
                errorMessage    : ''
            })
        if (prevProps.loading && !this.props.loading)
            this.props.onClose()
    }

    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )
        if (this.props.visible)
            return (
                <div className="modal default-modal">
                    <div className="modal-dialog modal-post-facebook">
                        <div className="modal-content container_share_modal">
                            <div className="header">
                                <p className={'title'}>{i18n.management_instant.create_post_instagram}</p>
                                <span className={'close-button close_post_modal'} onClick={() => this.props.onClose()}/>
                            </div>
                            <div className="body content_share_modal">
                                {loader}
                                {this.state.errorMessage !== '' ? <div className={'error_share'}>{this.state.errorMessage}</div> : null}
                                {this.state.instagramAccount ?
                                    <PreviewInstagramPost account={this.state.instagramAccount}
                                                          textPost={this.state.textPost}
                                                          picturePost={this.props.instant.picture}
                                                          changeText={(text: string): void => this.setState({textPost: text})}
                                    />
                                    : <PostInstagramForm findSocialPage={this.props.findInstagramAccounts}
                                                         selectedPage={this.state.checked}
                                                         socialInfo={this.props.instagramAccounts}
                                                         onSelectPage={(checked: boolean, item: SocialPageInfo) => this.setState({checked: checked, instagramAccount: checked ? item : undefined})}
                                    />}
                            </div>
                            {this.state.instagramAccount ? <button className={'add-btn btn-share'}
                                                                   onClick={() => this.shareInstant()}>{i18n.management_instant.share_instagram}</button> : null}
                        </div>
                    </div>
                </div>)
        return (<div/>)
    }

    shareInstant(): void {
        if (this.state.checked && this.state.instagramAccount)
            this.props.postInstant(this.state.instagramAccount, {
                text : this.state.textPost,
                link : `https://instants.vivezici.fr/?uuid=${this.props.instant.id}`,
                image: this.props.instant.picture
            })
    }
}
