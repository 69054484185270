import { LoadInstantsCompanyState } from "../../configuration/state";
import {
    LOAD_INSTANTS_COMPANY, LOAD_INSTANTS_COMPANY_FAILED,
    LOAD_INSTANTS_COMPANY_SUCCEEDED,
    LoadInstantsCompanyActionsTypes
} from "./loadInstantsCompany.types";

const initialState: LoadInstantsCompanyState = {
    instants : null,
    isLoading: false,
    error    : undefined
}

export const loadInstantsCompanyReducer = (state = initialState, action: LoadInstantsCompanyActionsTypes): LoadInstantsCompanyState => {
    switch (action.type) {
        case LOAD_INSTANTS_COMPANY:
            return {
                instants : null,
                isLoading: true,
                error    : undefined
            }
        case LOAD_INSTANTS_COMPANY_SUCCEEDED:
            return {
                instants : action.payload,
                isLoading: false,
                error    : undefined
            }
        case LOAD_INSTANTS_COMPANY_FAILED:
            return {
                instants : null,
                isLoading: false,
                error    : action.payload
            }
        default:
            return state
    }
}