import React, { PureComponent, ReactNode } from "react";
import Select from 'react-select';
import { SelectOptionType } from "../../../../domain/types/selectOption.type";
import { OpeningDayNames } from "../../../../domain/types/openingHours.types";


interface Props {
    onChange: (value: string) => void
    error?: boolean
    selected: string
    label: string
    className?:string
}

export class OpeningDaysSelector extends PureComponent<Props> {
    render(): ReactNode {
        const error = this.props.error ? 'error select-category' : 'select-category'
        const classContainer = this.props.className ? this.props.className+' wizard-form-input select-option-area':'wizard-form-input select-option-area'
        return (
            <div className={classContainer}>
                <label>{this.props.label}</label>

                <Select
                    className={error}
                    classNamePrefix="select"
                    isSearchable={true}
                    value={this.getValueSelected(this.props.selected)}
                    options={this.optionList()}
                    onChange={selected => {
                        if (selected !== null)
                            this.props.onChange(selected.value)
                    }}
                />
            </div>
        )
    }

    optionList(): SelectOptionType[] {
            return Object.keys(OpeningDayNames).map(category => ({ label: OpeningDayNames[category], value: category }))
    }

    getValueSelected(value: string): SelectOptionType | undefined {
        return this.optionList().find(item => item.value === value)
    }
}
