import React, { PureComponent, ReactNode } from "react";
import { Schedule } from "../../../../../domain/entities/types/activationTimeType"
import { FixedScheduleForm } from "./fixedSchedule.form";
import { FixedSchedulePreview } from "./fixedSchedule.preview";

interface Props {
    schedule: Schedule | undefined
    raiseUpdate: (schedule: Schedule | undefined) => void
    isSubmitted: boolean
}

interface State {
    schedule: Schedule | undefined;
}

export class FixedScheduleContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            schedule: this.props.schedule
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.schedule && this.props.schedule !== prevProps.schedule)
            this.setState({ schedule: this.props.schedule })
    }
    render(): ReactNode {
        if (this.state.schedule)
            return <FixedSchedulePreview onRemove={(): void => this.removeSchedule()}
                                         schedule={this.state.schedule}/>
        else
            return <FixedScheduleForm onSubmit={(schedule: Schedule): void => this.addSchedule(schedule)}/>
    }

    addSchedule(schedule: Schedule): void {
        this.setState({ schedule }, (): void => {
            const cleanSchedule = this.cleanSchedule()
            this.props.raiseUpdate(cleanSchedule)
        })
    }

    removeSchedule(): void {
        this.setState({ schedule: undefined })
        this.props.raiseUpdate(undefined)
    }

    private cleanSchedule(): Schedule | undefined {
        if (this.state.schedule && this.isUUID(this.state.schedule.id))
            return { ...this.state.schedule, id: '' }
        else
            return this.state.schedule
    }

    private isUUID(id: string): boolean {
        const regexp = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
        return regexp.test(id);
    }
}
