import { connect } from "react-redux";
import { Company } from "../../../../../company/domain/entity/company";
import { AppState } from "../../../../../redux-configuration/AppState";
import { companySelector } from "../../../../../company/usecases/updateStoreCompany/selector";
import { SubscriptionTableContainer } from "./subscriptionTableContainer";
import { SubscriptionPeriodicity, SubscriptionId } from "../../../../domain/entities/types/subscription.type";
import { Dispatch } from "redux";
import { retrievePaymentSession, } from "../../../../usecases/retrievePaymentSession/action";
import { RetrievePaymentSessionAction } from "../../../../usecases/retrievePaymentSession/actionTypes";
import {
    paymentSessionIdSelector,
    retrievePaymentSessionErrorSelector,
    retrievePaymentSessionLoadingSelector
} from "../../../../usecases/retrievePaymentSession/selector";

interface StateToPropsType {
    company: Company | null;
    loading:boolean;
    paymentError: string | undefined;
    sessionId: string | undefined;
}

interface DispatchToPropsType {
    retrievePaymentSession: (companyId: string, subscriptionId: SubscriptionId, periodicity: SubscriptionPeriodicity) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    retrievePaymentSession: (companyId: string, subscriptionId: SubscriptionId, periodicity: SubscriptionPeriodicity)
        : RetrievePaymentSessionAction => dispatch(retrievePaymentSession(companyId, subscriptionId, periodicity))
})

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companySelector(state),
    loading: retrievePaymentSessionLoadingSelector(state),
    paymentError : retrievePaymentSessionErrorSelector(state),
    sessionId: paymentSessionIdSelector(state)
})


export const SubscriptionTable = connect(mapStateToProps, mapDispatchToProps)(SubscriptionTableContainer)
