import React from "react";
import { i18n } from "../../../../configuration/i18n";
import { GalleryImageInput } from "../../../../company/adapters/primaries/themeConfiguration/components/blockWithImages/image.input";
import { ProductGalleryImage } from "../../../domain/entities/productGalleryImage";

interface Props {
    error: boolean;
    images: (ProductGalleryImage | File | string)[];
    raiseUpdates: (value: (ProductGalleryImage | File | string)[]) => void;
}

export const ProductImagesInput = (props: Props): JSX.Element => {

    return (
        <div id={"theme-gallery"}>
            <div className={'wizard-form-input align-items-start mb-0'}>
                <label className={'mt-3'}>{i18n.ecommerce.gallery}</label>

                <div className={'d-flex align-items-center flex-wrap image-list'}>
                    {props.images.map((image, index) => {
                        const imageUrl = image instanceof File ? URL.createObjectURL(image) :
                            image instanceof ProductGalleryImage ? image.url : image
                        return (
                            <GalleryImageInput key={index}
                                               imageUrl={imageUrl}
                                               removeImage={() => {
                                                   const images = props.images ? [...props.images] : []
                                                   images.splice(index, 1)
                                                   props.raiseUpdates([...images])
                                               }}/>
                        )
                    })}

                    <div className={'add-img-btn d-flex align-items-center justify-content-center flex-column position-relative'}>
                        <div className={'text-center'}>
                            <i className="fa fa-plus"/>
                            <p>Ajouter une photo</p>
                        </div>

                        <input type="file" onChange={e => {
                            if (e.target.files?.[0]) {
                                props.raiseUpdates([...props.images, e.target.files[0]])
                            }
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
