import { connect } from 'react-redux'
import { RootNavigationContainer } from "./rootNavigationContainer";
import { AppState } from "../../../redux-configuration/AppState";
import { isSuperAdminCompany } from "../../../company/usecases/updateStoreCompany/selector";

interface StateToPropsType {
    isSuperAdmin: boolean;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    isSuperAdmin: isSuperAdminCompany(state)
})

export const RootNavigation = connect(mapStateToProps)(RootNavigationContainer)
