import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable'
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs'
import { UPDATE_PROFILE, UpdateProfileActionType } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { RegistrationService } from "../../domain/gateway/registration.service";
import { updateProfileFailed, updateProfileSucceeded } from "./actions";
import { map } from "rxjs/internal/operators";

export const updateProfileEpic: Epic = (action$: ActionsObservable<UpdateProfileActionType>,
                                        store: StateObservable<AppState>,
                                        {registrationService}:
                                            {
                                                registrationService: RegistrationService,
                                            }) =>
    action$.pipe(
        ofType(UPDATE_PROFILE),
        switchMap(action => registrationService.updateProfile(action.payload)
            .pipe(
                map(() => updateProfileSucceeded()),
                catchError(error => of(updateProfileFailed(error)))
            )
        )
    )
