import React, { PureComponent, ReactNode } from "react";
import { InstantHeader } from "../../../domain/entites/instantHeader";
import { i18n } from "../../../../configuration/i18n";
import { WeeklyInstantHelper } from "../../../domain/entites/specifications/time/helpers/weeklyInstant.helper";
import { AgendaInstantHeader } from "./components/agendaInstantHeader";
import { Company } from "../../../../company/domain/entity/company";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";

interface Props {
    company: Company | null;
    instants: InstantHeader[]
    loading: boolean
    loadAgendaInstants: (cityCode: string) => void
}

export interface State {
    sortedInstants: Array<{ title: string; data: InstantHeader[] }>;
}

export class AgendaContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            sortedInstants: WeeklyInstantHelper.sortInstantListByDay(this.props.instants)
        }
    }

    static getDerivedStateFromProps(nextProps: Props): State {
        return {sortedInstants: WeeklyInstantHelper.sortInstantListByDay(nextProps.instants)}
    }

    componentDidMount(): void {
        if (this.props.company) {
            this.loadAgendaInstants(this.props.company)
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.company !== this.props.company && this.props.company) {
            this.loadAgendaInstants(this.props.company)
        }
    }

    render(): ReactNode {
        const title = i18n.instants.agenda_of + this.props.company?.address.city ?? ''
        return (
            <div>
                <DefaultPageHeader title={title}/>

                <div className={'container-fluid custom-width-1260'}>
                    <div className={'bg-white'}>
                        <div className={'row justify-content-center'}>
                            <div className="col-xl-8 blog-image">
                                <img src={'https://cdn.vivezclichy.fr/mobile/banniere-agenda.jpg'} alt={title}/>
                            </div>
                        </div>

                        {this.state.sortedInstants.map(item => {
                            if (item.data.length > 0)
                                return <AgendaInstantHeader key={item.title} title={item.title} instants={item.data}/>
                        })}

                        {this.renderEmptyListMessage()}
                    </div>
                </div>
            </div>
        )
    }

    renderEmptyListMessage(): JSX.Element | undefined {
        if (!this.props.loading && this.props.instants.length === 0)
            return (
                <p className={'text-center py-5'} style={{fontSize: 14}}>
                    {i18n.instants.no_instants_found_for_agenda}
                </p>
            )
    }

    loadAgendaInstants(company: Company): void {
        this.props.loadAgendaInstants(company.address.zipCode)
        document.title = i18n.instants.page_title + company.address.city
    }
}
