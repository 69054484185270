import React, { useState } from "react";
import arrow from "../../../../../assets/img/arrow.svg";

interface Props {
    title: string;
    children: JSX.Element;
}

export const AccordionItem = (props: Props): JSX.Element => {

    const [show, setShow] = useState(true)

    return (
        <div className="card mb-3">
            <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                    <button className="btn btn-link d-flex justify-content-between" data-toggle="collapse"
                            onClick={() => setShow(!show)}>
                        {props.title}
                        <img src={arrow} alt=""/>
                    </button>
                </h5>
            </div>

            <div className={show ? "collapse show" : "collapse"}>
                <div className="card-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
