import { BusinessOpeningHours } from "./businessOpeningHours";
import { BusinessContact } from "./businessContact";
import { BusinessAddress } from "./businessAddress";
import { AdministrativeNumber } from "./administrativeNumber";

export class Business {
    static FREEMIUM: 'freemium' = 'freemium'

    constructor(
        protected _id: string,
        protected _name: string,
        protected _type: string,
        protected _category: string,
        protected _membership: 'freemium',
        protected _administrativeNumber: AdministrativeNumber,
        protected _coverImage: File,
        protected _profileImage: File,
        protected _cinFront: File,
        protected _cinBack: File,
        protected _openingHours: BusinessOpeningHours[],
        protected _contacts: BusinessContact,
        protected _address: BusinessAddress
    ) {}

    get id(): string {
        return this._id
    }

    get name(): string {
        return this._name
    }

    get type(): string {
        return this._type
    }

    get category(): string {
        return this._category
    }

    get membership(): 'freemium' {
        return this._membership;
    }

    get address(): BusinessAddress {
        return this._address
    }

    get administrativeNumber(): AdministrativeNumber {
        return this._administrativeNumber
    }

    get coverImage(): File {
        return this._coverImage
    }

    get profileImage(): File {
        return this._profileImage
    }

    get cinFront(): File {
        return this._cinFront
    }

    get cinBack(): File {
        return this._cinBack
    }

    get openingHours(): BusinessOpeningHours[] {
        return this._openingHours
    }

    get contacts(): BusinessContact {
        return this._contacts
    }
}
