import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    submit: () => void
    goBack: () => void
    hasBackButton?: boolean
}

export class SubmitButton extends PureComponent<Props> {
    render(): ReactNode {
        const backButton = this.props.hasBackButton && (
            <li onClick={() => this.props.goBack()}>
                <span className="js-btn-prev" title="BACK">
                    <i className="fa fa-arrow-left"/> {i18n.registration.back}
                </span>
            </li>
        )

        return (
            <div className="actions">
                <ul>
                    {backButton}
                    <li onClick={() => this.props.submit()}>
                        <span className="js-btn-next" title="NEXT">
                            {i18n.registration.next} <i className="fa fa-arrow-right"/>
                        </span>
                    </li>
                </ul>
            </div>
        )
    }
}
