import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../../redux-configuration/AppState";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import { CompanyStatsContainer } from "./companyStats.container";
import {
    companyFollowersStatsSelector,
    companyVisitsStatsSelector, retrieveCompanyStatsLoadingSelector
} from "../../../usecases/retrieveCompanyStats/retrieveCompanyStats.selectors";
import { CartesianData } from "../../../domain/entity/cartesianData";
import { retrieveCompanyStats } from "../../../usecases/retrieveCompanyStats/retrieveCompanyStats.actions";
import { RetrieveCompanyStatsAction } from "../../../usecases/retrieveCompanyStats/retrieveCompanyStats.types";
import {
    instantsCompanySelector
} from "../../../../instant-management/usecases/loadInstantsCompany/loadInstantsCompany.selectors";
import { ManagementInstantHeader } from "../../../../instant-management/domain/entities/managementInstantHeader";

interface StateToPropsType {
    loading: boolean;
    company: Company | null;
    visitStats: CartesianData[] | undefined;
    followersStats: CartesianData[] | undefined;
    followersNumber: number | undefined;
    instants: ManagementInstantHeader[] | null;
}

interface DispatchToPropsType {
    retrieveCompanyStats: (companyId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading        : retrieveCompanyStatsLoadingSelector(state),
    company        : companySelector(state),
    followersStats : companyFollowersStatsSelector(state),
    visitStats     : companyVisitsStatsSelector(state),
    followersNumber: companySelector(state)?.followersCount,
    instants : instantsCompanySelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    retrieveCompanyStats: (companyId: string): RetrieveCompanyStatsAction => dispatch(retrieveCompanyStats(companyId))
})

export const CompanyStats = connect(mapStateToProps, mapDispatchToProps)(CompanyStatsContainer)
