import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../../../redux-configuration/AppState";
import { ManagementInstant } from "../../../../domain/entities/ManagementInstant";
import {
    loadingManagementInstantSelector, managementInstantSelector
} from "../../../../usecases/loadManagementInstantById/loadManagementInstant.selectors";
import { LoadManagementInstantAction } from "../../../../usecases/loadManagementInstantById/loadManagementInstant.types";
import { loadManagementInstant } from "../../../../usecases/loadManagementInstantById/loadManagementInstant.actions";
import { EditInstantAction } from "../../../../usecases/editInstant/editInstant.types";
import { editInstant } from "../../../../usecases/editInstant/editinstant.actions";
import { EditServiceInstantContainer } from "./editServiceInstant.container";
import {
    isEditInstantLoadingSelector,
    successEditInstantSelector
} from "../../../../usecases/editInstant/editinstant.selectors";
import { Company } from "../../../../../company/domain/entity/company";
import { companyDetailsSelector } from "../../../../../company/usecases/loadCompanyDetails/selector";

interface StateToPropsType {
    instant: ManagementInstant | null;
    loading: boolean;
    company: Company | null;
    successEdition: boolean | null
}

interface DispatchToPropsType {
    loadManagementInstant: (instantId: string) => void;
    editInstant: (instant: ManagementInstant) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    instant       : managementInstantSelector(state),
    loading       : isEditInstantLoadingSelector(state) || loadingManagementInstantSelector(state),
    company       : companyDetailsSelector(state),
    successEdition: successEditInstantSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadManagementInstant: (instantId: string): LoadManagementInstantAction => dispatch(loadManagementInstant(instantId)),
    editInstant          : (instant: ManagementInstant): EditInstantAction => dispatch(editInstant(instant))
})

export const EditServiceInstant = connect(mapStateToProps, mapDispatchToProps)(EditServiceInstantContainer)
