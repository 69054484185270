import { AppState } from "../../../../redux-configuration/AppState";
import { connect } from "react-redux";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import { PaymentFailContainer } from "./paymentFail.container";

interface StateToPropsType {
    company: Company | null;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company: companySelector(state)
})


export const PaymentFail = connect(mapStateToProps)(PaymentFailContainer)
