import { SendLoginConfirmationCodeState } from "../../configuration/state";
import {
    SEND_LOGIN_CONFIRMATION_CODE, SEND_LOGIN_CONFIRMATION_CODE_FAILED, SEND_LOGIN_CONFIRMATION_CODE_SUCCEEDED, SendLoginConfirmationCodeActionTypes
} from "./actionTypes";

const initialState: SendLoginConfirmationCodeState = {
    loading: false,
    token  : undefined,
    error  : undefined,
}

export const sendLoginConfirmationCodeReducer = (state = initialState, action: SendLoginConfirmationCodeActionTypes): SendLoginConfirmationCodeState => {
    switch (action.type) {
        case SEND_LOGIN_CONFIRMATION_CODE:
            return {
                loading: true,
                token  : undefined,
                error  : undefined
            }
        case SEND_LOGIN_CONFIRMATION_CODE_SUCCEEDED:
            return {
                loading: false,
                token  : action.payload,
                error  : undefined
            }
        case SEND_LOGIN_CONFIRMATION_CODE_FAILED:
            return {
                loading: false,
                token  : undefined,
                error  : action.payload
            }
        default:
            return state
    }
}
