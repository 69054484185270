import { BroadcastMessage } from '../entities/broadcastMessage';

export class BroadcastMessageBuilder {
    protected _id: number
    protected _content: string
    protected _sentTo: string
    protected _createdAt: Date
    protected _image: string | undefined

    withId(value: number): BroadcastMessageBuilder {
        this._id = value
        return this
    }

    withContent(value: string): BroadcastMessageBuilder {
        this._content = value
        return this
    }

    withSentTo(value: string): BroadcastMessageBuilder {
        this._sentTo = value
        return this
    }

    withCreatedAt(value: Date): BroadcastMessageBuilder {
        this._createdAt = value
        return this
    }
    withImage(value: string | undefined): BroadcastMessageBuilder {
        this._image = value
        return this
    }

    build(): BroadcastMessage {
        return new BroadcastMessage(
            this._id,
            this._content,
            this._sentTo,
            this._createdAt,
            this._image
        )
    }
}
