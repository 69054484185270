import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { StatsService } from "../../domain/gateway/StatsService";
import { RETRIEVE_COMPANY_STATS, RetrieveCompanyStatsAction } from "./retrieveCompanyStats.types";
import { retrieveCompanyStatsFailed, retrieveCompanyStatsSucceeded } from "./retrieveCompanyStats.actions";

export const retrieveCompanyStatsEpic: Epic = (action$: ActionsObservable<RetrieveCompanyStatsAction>,
                                               store: StateObservable<AppState>,
                                               {statsService}: { statsService: StatsService }) =>
    action$.pipe(
        ofType(RETRIEVE_COMPANY_STATS),
        switchMap(action => statsService.retrieveCompanyVisitStats(action.payload)
            .pipe(
                mergeMap((visits) => statsService.retrieveCompanyFollowStats(action.payload)
                    .pipe(
                        map((followers) => retrieveCompanyStatsSucceeded(visits, followers)),
                        catchError(err => of(retrieveCompanyStatsFailed(err)))
                    )
                ),
                catchError(err => of(retrieveCompanyStatsFailed(err)))
            )
        )
    )
