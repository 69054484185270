import { SubscriptionPeriodicity, SubscriptionId } from "../../domain/entities/types/subscription.type";

export const RETRIEVE_PAYMENT_SESSION = 'RETRIEVE_PAYMENT_SESSION'
export const RETRIEVE_PAYMENT_SESSION_SUCCEEDED = 'RETRIEVE_PAYMENT_SESSION_SUCCEEDED'
export const RETRIEVE_PAYMENT_SESSION_FAILED = 'RETRIEVE_PAYMENT_SESSION_FAILED'

export interface RetrievePaymentSessionAction {
    type: typeof RETRIEVE_PAYMENT_SESSION;
    payload: {
        companyId: string;
        subscriptionId: SubscriptionId;
        periodicity: SubscriptionPeriodicity
    }
}

export interface RetrievePaymentSessionSucceededAction {
    type: typeof RETRIEVE_PAYMENT_SESSION_SUCCEEDED;
    payload: string;
}

export interface RetrievePaymentSessionFailedAction {
    type: typeof RETRIEVE_PAYMENT_SESSION_FAILED;
    payload: string;
}

export type RetrievePaymentSessionActionTypes = RetrievePaymentSessionAction
    | RetrievePaymentSessionSucceededAction
    | RetrievePaymentSessionFailedAction
