import { ApplicationContext } from "../../../configuration/application.context";

const moment = ApplicationContext.getInstance().momentJs()

export class MessagingSpecifications {

    static formatDate(date: Date): string {
        const messageDateTime = moment(date).format('YYYY-MM-DD')

        if (messageDateTime === moment().format('YYYY-MM-DD'))
            return moment(date).format('HH:mm')
        else if (messageDateTime === moment().subtract(1, 'days').format('YYYY-MM-DD'))
            return 'hier'
        else if (moment(messageDateTime).isSameOrAfter(moment().subtract(7, 'days')))
            return moment(date).format('ddd')
        else if (moment(messageDateTime).format('YYYY') === moment().format('YYYY'))
            return moment(date).format('DD MMM')
        else
            return moment(date).format('DD MMM YYYY')
    }

    static shouldRenderDate(previousMessageDate: Date | undefined, currentMessageDate: Date): boolean {
        if (previousMessageDate) {
            const diff = moment.duration(moment(currentMessageDate)
                .diff(moment(previousMessageDate)))
                .asHours();
            const isMessagesSentToday = moment().format('YYYY-MM-DD') === moment(currentMessageDate).format('YYYY-MM-DD');
            const isMessagesSentAtTheSameDay = moment(previousMessageDate).format('YYYY-MM-DD') === moment(currentMessageDate).format('YYYY-MM-DD');

            return (diff > 1 && isMessagesSentToday) || !isMessagesSentAtTheSameDay
        }
        return true;
    }

}
