export interface SubscriptionType {
    id: SubscriptionId
    label: string
    monthly_price: number,
    promo_price: number,
    properties: SubscriptionProperty[]
}


export enum SubscriptionId {
    BASIC = 'basic',
    PREMIUM = 'premium',
    DIGITAL = 'digital'
}

export enum SubscriptionPeriodicity {
    MONTHLY = 'monthly',
    ANNUAL = 'annual',
}

export interface SubscriptionProperty {
    label: string
    value: string | boolean
    id: string
}

export enum PaymentStatus {
    PAID = 'paid',
    UNPAID = 'unpaid'
}
