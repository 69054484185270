import { ServiceIcon } from "./serviceIcon";

export class MunicipalService {
    constructor(
        private _id: string,
        private _label: string,
        private _type: string,
        private _content: string,
        private _rank: number,
        private _icon: ServiceIcon,
        private _active: boolean) {

    }

    get id(): string {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    get type(): string {
        return this._type;
    }

    get content(): string {
        return this._content;
    }

    get rank(): number {
        return this._rank;
    }

    get icon(): ServiceIcon {
        return this._icon;
    }

    get active(): boolean {
        return this._active
    }
}
