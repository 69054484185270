export const LOAD_LOCAL_CODE = 'LOAD_LOCAL_CODE'
export const LOAD_LOCAL_CODE_SUCCEEDED = 'LOAD_LOCAL_CODE_SUCCEEDED'
export const LOAD_LOCAL_CODE_FAILED = 'LOAD_LOCAL_CODE_FAILED'

export interface LoadLocalCodeAction {
    type: typeof LOAD_LOCAL_CODE;
}

export interface LoadLocalCodeSucceededAction {
    type: typeof LOAD_LOCAL_CODE_SUCCEEDED;
    payload: string;
}

export interface LoadLocalCodeFailedAction {
    type: typeof LOAD_LOCAL_CODE_FAILED;
    payload: string;
}

export type LoadLocalCodeActionType = LoadLocalCodeAction

export type LoadLocalCodeActionTypes =
    LoadLocalCodeAction
    | LoadLocalCodeFailedAction
    | LoadLocalCodeSucceededAction
