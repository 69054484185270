import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { UPDATE_BUSINESS, UpdateBusinessActionType } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { RegistrationService } from "../../domain/gateway/registration.service";
import { updateBusinessFailed, updateBusinessSucceeded } from "./actions";
import { RegistrationRepository } from "../../domain/gateway/registration.repository";
import { concatMap } from "rxjs/internal/operators";
import { loadCompanyDetails } from "../../../company/usecases/loadCompanyDetails/actions";
import { Company } from "../../../company/domain/entity/company";

export const updateBusinessEpic: Epic = (action$: ActionsObservable<UpdateBusinessActionType>,
                                         store: StateObservable<AppState>,
                                         { registrationService, registrationRepository }:
                                             { registrationService: RegistrationService, registrationRepository: RegistrationRepository }) =>
    action$.pipe(
        ofType(UPDATE_BUSINESS),
        switchMap((action: { payload: Company }) => registrationService.updateBusiness(action.payload)
            .pipe(
                mergeMap(() => registrationRepository.saveBusiness(action.payload)
                    .pipe(
                        concatMap(() => [
                            loadCompanyDetails(action.payload.id), updateBusinessSucceeded()
                        ]),
                        catchError(err => of(updateBusinessFailed(err)))
                    )
                ),
                catchError(err => of(updateBusinessFailed(err)))
            )
        )
    );
