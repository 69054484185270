export const EcommerceI18n = {
    all_products_page_title: "VivezICI | Tous les produits",
    all_orders_page_title: "VivezICI | Tous les commandes",
    add_product_page_title: "VivezICI | Ajouter un produit",
    edit_product_page_title: "VivezICI | Modifier un produit",
    order_details_page_title: "VivezICI | Détails de commande",
    all_my_orders: "Tous mes commandes",
    general_settings: "Général",
    advanced_settings: "Paramètres avancées",
    name: "Nom du produit *",
    stock: "Stock *",
    price: "Prix *",
    size: "Dimension (centimètre) *",
    weight: "Poids (gramme)*",
    weight_placeholder: "Poids (g)",
    image: "Image *",
    gallery: "Galerie",
    category: "Catégorie",
    short_description: "Description courte *",
    description: "Description",
    empty_form_error: "Veuillez remplir tous les champs",
    edit: "Modifier",
    no_products: "Vous n'avez pas des produits",
    no_orders: "Vous n'avez pas des commandes",
    order_details: "Détails de la commande",
};
