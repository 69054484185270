import { AdministrativeNumber } from "./administrativeNumber";

export class Siret extends AdministrativeNumber{

    validateNumber(): boolean{
        if (this._number.replace(/\s/g, '').length !== 14)
            return false
        else {
            let somme = 0
            let tmp = 0
            const siren = this._number.substr(0,9)
            const arraySiren = siren.replace(/\s/g, '').split('')
            arraySiren.map((val, index) => {
                if (index % 2) {
                    tmp = parseInt(val, 0) * 2
                    if (tmp > 9) tmp -= 9
                } else tmp = parseInt(val, 0)
                somme += tmp
            })
            return ((somme % 10) === 0)
        }
    }

    type(): string{
        return 'SIRET'
    }

    length(): number {
        return 14
    }
}