import React from "react";
import { i18n } from "../../../../configuration/i18n";
import { GalleryImageInput } from "../../../../company/adapters/primaries/themeConfiguration/components/blockWithImages/image.input";
import { ValueOf } from "../../../../common/domain/types/app.categories";
import { ProductDetailsType } from "../types";

interface Props {
    error: boolean;
    image: string | File | undefined;
    raiseUpdates: (value: ValueOf<ProductDetailsType>) => void;
}

export const ProductImageInput = (props: Props): JSX.Element => {

    const renderImage = () => {
        if (props.image) {
            const imageUrl = (props.image instanceof File) ? URL.createObjectURL(props.image) : props.image
            return <GalleryImageInput imageUrl={imageUrl}
                                      removeImage={() => props.raiseUpdates(undefined)}/>
        }
        else {
            return (
                <div className={'add-img-btn d-flex align-items-center justify-content-center flex-column position-relative'}>
                    <div className={'text-center'}>
                        <i className="fa fa-plus"/>
                        <p>Ajouter une photo</p>
                    </div>

                    <input type="file" onChange={e => {
                        if (e.target.files?.[0]) {
                            props.raiseUpdates(e.target.files[0])
                        }
                    }}/>
                </div>
            )
        }
    }

    return (
        <div id={"theme-gallery"}>
            <div className={'wizard-form-input align-items-start mb-0'}>
                <label className={'mt-3'}>{i18n.ecommerce.image}</label>

                <div className={'d-flex align-items-center flex-wrap image-list'}>
                    {renderImage()}
                </div>
            </div>
        </div>
    )
}
