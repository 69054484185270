export const SEND_BROADCAST_MESSAGE = 'SEND_BROADCAST_MESSAGE'
export const SEND_BROADCAST_MESSAGE_SUCCEEDED = 'SEND_BROADCAST_MESSAGE_SUCCEEDED'
export const SEND_BROADCAST_MESSAGE_FAILED = 'SEND_BROADCAST_MESSAGE_FAILED'

export interface SendBroadcastMessageAction {
    type: typeof SEND_BROADCAST_MESSAGE;
    payload: {
        businessId: string;
        message: string;
        image: File | undefined
    };
}

export interface SendBroadcastMessagesSucceededAction {
    type: typeof SEND_BROADCAST_MESSAGE_SUCCEEDED;
}

export interface SendBroadcastMessageFailedAction {
    type: typeof SEND_BROADCAST_MESSAGE_FAILED;
    payload: string;
}

export type SendBroadcastMessageActionTypes =
    SendBroadcastMessageAction
    | SendBroadcastMessagesSucceededAction
    | SendBroadcastMessageFailedAction
