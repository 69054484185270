import {
    SEND_BROADCAST_MESSAGE, SEND_BROADCAST_MESSAGE_FAILED, SEND_BROADCAST_MESSAGE_SUCCEEDED,
    SendBroadcastMessageAction,
    SendBroadcastMessageFailedAction,
    SendBroadcastMessagesSucceededAction
} from "./actionTypes";

export const sendBroadcastMessage = (businessId: string, message: string, image: File | undefined): SendBroadcastMessageAction => ({
    type   : SEND_BROADCAST_MESSAGE,
    payload: {businessId, message, image}
})

export const sendBroadcastMessageSucceeded = (): SendBroadcastMessagesSucceededAction => ({
    type: SEND_BROADCAST_MESSAGE_SUCCEEDED
})

export const sendBroadcastMessageFailed = (error: string): SendBroadcastMessageFailedAction => ({
    type   : SEND_BROADCAST_MESSAGE_FAILED,
    payload: error
})
