import React, { PureComponent, ReactNode } from 'react';
import { Conversation } from "../../domain/entities/conversation";
import { BroadcastConversation } from "../../domain/entities/broadcastConversation";
import { Company } from "../../../company/domain/entity/company";
import './companyMessaging.css';
import { BroadcastConversationItem } from "./components/broadcast/broadcastConversation.item";
import { BroadcastMessage } from "../../domain/entities/broadcastMessage";
import { ChatMessage } from "../../domain/entities/chatMessage";
import { i18n } from "../../../configuration/i18n";
import { MessagesList } from "./components/message/messages.list";
import { MessageTextInput } from "./components/text.input";
import { ConversationsList } from "./components/conversation/conversations.list";
import { DefaultPageHeader } from "../../../common/adapters/primaries/defaultPage.header";
import alert from "../../../assets/message-pop-alert.mp3";
import { ApplicationContext } from "../../../configuration/application.context";
import { BroadcastAlert } from "./components/broadcast/broadcast.alert";
import { SendBroadcastModal } from "./components/modal/broadcast.modal";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    loadingMessages: boolean;
    company: Company | null;
    messages: ChatMessage[] | null;
    newMessages: ChatMessage[] | null;
    conversations: Conversation[] | null;
    broadcast: BroadcastConversation;
    successSendMsg: boolean | null;
    successSendBroadcastMsg: boolean | null;
    loadConversations: (company: Company) => void;
    loadBroadcast: (companyId: string) => void;
    loadPreviousMessages: (conversationId: string, messageId: number) => void;
    loadNewMessages: (conversationId: string, messageId: number) => void;
    sendMessage: (conversationId: string, companyId: string, message: string, image: File | undefined) => void;
    sendBroadcastMessage: (companyId: string, message: string, image: File | undefined) => void;
}

interface State {
    text: string;
    messagesTitle: string;
    isBroadcastMsg: boolean;
    broadcastImage: File | undefined;
    isVisible: boolean;
    messages: ChatMessage[];
    broadcastMessages: BroadcastMessage[];
    currentConversation: Conversation | null;
    allowedBroadcast: number | null;
}

export class CompanyMessagingContainer extends PureComponent<Props, State> {

    private interval$: NodeJS.Timeout | null;

    constructor(props: Props) {
        super(props)
        this.state = {
            text               : '',
            messagesTitle      : 'Messages',
            isBroadcastMsg     : false,
            broadcastImage     : undefined,
            isVisible          : false,
            messages           : [],
            broadcastMessages  : [],
            currentConversation: null,
            allowedBroadcast   : null
        }
    }

    componentDidUpdate(prevProps: Props): void {

        this.getPreviousMessages(prevProps.messages)
        this.getNewMessages(prevProps.newMessages)
        this.getBroadcastMessages(prevProps.broadcast)
        this.getConversations(prevProps.company)

        this.handleSuccessSendMessage(prevProps.successSendMsg)
        this.handleSuccessSendBroadcast(prevProps.successSendBroadcastMsg)
    }

    componentDidMount(): void {
        document.title = i18n.messaging.page_title;
        this.getConversations(null)
        this.startDataAutoLoad()
    }

    componentWillUnmount(): void {
        if (this.interval$) {
            clearInterval(this.interval$);
        }
        this.interval$ = null;
    }

    render(): ReactNode {
        return (
            <div>
                <DefaultPageHeader title={i18n.messaging.messages}/>

                <div className={'px-lg-3'}>
                    <div className={'container-fluid custom-width-1230 company-messaging'}>
                        <div className={'row h-100'}>
                            <div className={'col-4 company-conversations d-flex flex-column'}>
                                <h2>Conversations</h2>

                                <BroadcastConversationItem broadcast={this.props.broadcast}
                                                           onClick={() => this.setState({
                                                               messagesTitle    : this.props.broadcast.title,
                                                               isBroadcastMsg   : true,
                                                               broadcastMessages: [...this.props.broadcast.messages]
                                                           })}/>

                                <ConversationsList conversations={this.props.conversations}
                                                   currentConversation={this.state.isBroadcastMsg ? null : this.state.currentConversation}
                                                   allowedConversations={this.props.company?.membership.allowedConversations ?? -1}
                                                   onClick={(conversation: Conversation) => {
                                                       this.setState({
                                                               messagesTitle      : conversation.title,
                                                               currentConversation: conversation,
                                                               isBroadcastMsg     : false,
                                                               messages           : []
                                                           },
                                                           () => this.props.loadPreviousMessages(conversation.id, 0))
                                                   }}/>
                            </div>

                            <div className={'col-8 company-messages d-flex flex-column'}>
                                <h2>{this.state.messagesTitle}</h2>

                                <MessagesList isBroadcastMsg={this.state.isBroadcastMsg}
                                              loading={this.props.loadingMessages}
                                              messages={this.state.messages}
                                              broadcastMessages={this.state.broadcastMessages}
                                              loadPreviousMessages={() => this.loadPreviousMessages()}/>

                                {this.state.allowedBroadcast !== null && <BroadcastAlert allowedBroadcast={this.state.allowedBroadcast}
                                                                                         onClose={() => this.setState({allowedBroadcast: null})}/>}

                                <MessageTextInput value={this.state.text}
                                                  onChange={(text: string) => this.setState({text})}
                                                  onSelectImage={(image: File) => {
                                                      this.setState({broadcastImage: image})
                                                      this.onSelectImage(image)
                                                  }}
                                                  onSendMessage={() => this.onSendMessage()}/>

                                <SendBroadcastModal visible={this.state.isVisible}
                                                    onClose={() => this.setState({isVisible: false, text: '', broadcastImage: undefined})}
                                                    sendBroadcast={() => this.sendBroadcastMessage()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onSendMessage(): void {
        if (this.props.company) {
            if (this.state.isBroadcastMsg) {
                this.openBroadcastConfirmationModal()
            } else if (this.state.currentConversation) {
                this.props.sendMessage(this.state.currentConversation.id, this.props.company.id, this.state.text, undefined)
            }
        }
    }

    onSelectImage(image: File): void {
        if (this.props.company) {
            if (this.state.isBroadcastMsg) {
                this.openBroadcastConfirmationModal()
            } else if (this.state.currentConversation) {
                this.props.sendMessage(this.state.currentConversation.id, this.props.company.id, '', image)
            }
        }
    }

    loadPreviousMessages(): void {
        if (this.state.currentConversation && this.state.messages.length > 0) {
            const lastMessage = this.state.messages[this.state.messages.length - 1]
            this.props.loadPreviousMessages(this.state.currentConversation.id, lastMessage.id)
        }
    }

    getPreviousMessages(previousMessages: ChatMessage[] | null): void {
        if (this.props.messages && this.props.messages.length > 0 && this.props.messages !== previousMessages) {
            this.setState({messages: [...this.state.messages, ...this.props.messages]})
        }
    }

    getNewMessages(newMessages: ChatMessage[] | null): void {
        if (this.props.newMessages && this.props.newMessages.length > 0 && this.props.newMessages !== newMessages) {
            this.setState({messages: [...this.props.newMessages, ...this.state.messages]})
            if (this.props.company) {
                this.props.loadConversations(this.props.company)
            }

            for (let i = 0; i < this.props.newMessages.length; i++) {
                if (!this.props.newMessages[i].user.isCompany()) {
                    const audio = new Audio(alert);
                    audio.loop = false;
                    audio.play();
                }
            }
        }
    }

    getBroadcastMessages(broadcast: BroadcastConversation): void {
        if (this.props.broadcast && this.props.broadcast !== broadcast) {
            this.setState({
                isBroadcastMsg   : true,
                messagesTitle    : this.props.broadcast.title,
                broadcastMessages: [...this.props.broadcast.messages]
            })
        }
    }

    getConversations(company: Company | null): void {
        if (this.props.company && this.props.company !== company) {
            this.props.loadBroadcast(this.props.company.id)
            this.props.loadConversations(this.props.company)
        }
    }

    handleSuccessSendMessage(successSendMsg: boolean | null): void {
        if (this.props.successSendMsg && this.props.successSendMsg !== successSendMsg) {
            this.setState({text: '', broadcastImage: undefined})
            if (this.state.currentConversation && this.state.messages.length > 0) {
                this.props.loadNewMessages(this.state.currentConversation.id, this.state.messages[0].id)
            }
        }
    }

    handleSuccessSendBroadcast(successSendBroadcastMsg: boolean | null): void {
        if (this.props.successSendBroadcastMsg && this.props.successSendBroadcastMsg !== successSendBroadcastMsg) {
            this.setState({text: '', broadcastImage: undefined})
        }
    }

    startDataAutoLoad(): void {
        this.interval$ = setInterval(() => {
            if (this.state.currentConversation && this.state.messages.length > 0) {
                this.props.loadNewMessages(this.state.currentConversation.id, this.state.messages[0].id)
            }
            if (this.props.company) {
                this.props.loadConversations(this.props.company)
            }
        }, 7000);
    }

    sendBroadcastMessage(): void {
        if (this.props.company) {
            if (this.state.broadcastImage) {
                this.props.sendBroadcastMessage(this.props.company.id, '', this.state.broadcastImage)
            } else {
                this.props.sendBroadcastMessage(this.props.company.id, this.state.text, undefined)
            }
        }
        this.setState({isVisible: false})
    }

    openBroadcastConfirmationModal(): void {
        if (this.props.company && this.props.company.membership.allowedBroadcastMessages !== -1) {
            if (this.shouldSendBroadcastMessage(this.props.company.membership.allowedBroadcastMessages)) {
                this.setState({isVisible: true})
            } else {
                this.setState({allowedBroadcast: this.props.company.membership.allowedBroadcastMessages})
            }
        } else {
            this.setState({isVisible: true})
        }
    }

    shouldSendBroadcastMessage(allowedBroadcastMessages: number): boolean {
        const firstDay = moment().startOf('week').format()
        const lastDay = moment().endOf('week').format()

        let messageNumber = 0
        this.state.broadcastMessages.map(item => {
            if (moment(item.createdAt).isBetween(firstDay, lastDay)) {
                messageNumber++
            }
        })
        return messageNumber < allowedBroadcastMessages
    }
}
