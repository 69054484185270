import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../../configuration/i18n";
import { SubscriptionId } from "../../../../../domain/entities/types/subscription.type";
interface Props{
    onPress:(id: SubscriptionId) =>void
}
export class SubscriptionProFooterTable extends PureComponent<Props>{
render():ReactNode{
    return (
        <tr className={'footer'}>
            <th scope="row"></th>
            <td>
                <div onClick={()=>this.props.onPress(SubscriptionId.BASIC)} className={'btn_buy'}><a className={'button'} >{i18n.payment.buy}</a></div>
            </td>
            <td>
                <div onClick={()=>this.props.onPress(SubscriptionId.PREMIUM)} className={'btn_buy'}><a className={'button'} >{i18n.payment.buy}</a></div>
            </td>
            <td>
                <div onClick={()=>this.props.onPress(SubscriptionId.DIGITAL)} className={'btn_buy'}><a className={'button'} >{i18n.payment.buy}</a></div>
            </td>
        </tr>
    )
}
}
