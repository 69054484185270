import React from "react";
import { EcommerceOrder } from "../../../../domain/entities/ecommerceOrder";

interface Props {
    order: EcommerceOrder;
    companyId: string | undefined;
}

export const OrderTransactionStatus = (props: Props): JSX.Element => {
    switch (props.order.transactionStatus) {
        case "checkedIn":
            return <div className={"status completed-status"}>Terminée</div>;
        case "none":
            return <div className={"status none-status"}>Aucun</div>;
        default:
            return <div className={"status none-status"}>Aucun</div>;
    }
};
