import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { LOAD_PRODUCTS, LoadProductsAction } from "./actionTypes";
import { loadProductsFailed, loadProductsSucceeded } from "./actions";
import { ProductService } from "../../domain/gateway/product.service";
import { ProductDetails } from "../../domain/entities/productDetails";

export const loadProductsEpic: Epic = (action$: ActionsObservable<LoadProductsAction>,
                                       store: StateObservable<AppState>,
                                       {productService}: { productService: ProductService }) =>
    action$.pipe(
        ofType(LOAD_PRODUCTS),
        switchMap(
            (action) => productService.getProducts(action.payload)
                .pipe(
                    map((data: ProductDetails[]) => loadProductsSucceeded(data)),
                    catchError(error => of(loadProductsFailed(error)))
                )
        )
    )
