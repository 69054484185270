import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";

interface Props {
    phoneNumber: string;
    onClose: () => void;
    loading: boolean;
    successConfirmation: boolean;
    isVisible: boolean;
    error: string | undefined;
    hasBusiness: boolean | null
    sendConfirmationCode: (phoneNumber: string, code: string) => void;
    onConfirmationSucceeded: () => void;
}

interface State {
    codeSms: string;
    error: boolean;
    errorMsg: string | undefined;
}

export class ConfirmationForm extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            codeSms : '',
            error   : false,
            errorMsg: undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.hasBusiness !== this.props.hasBusiness) {
            if (this.props.hasBusiness)
                window.location.href = '/company'
        }

        if (this.props.error === '403' && this.props.error !== prevProps.error) {
            this.setState({error: true, errorMsg: i18n.registration.contact_vivez_clichy})
        } else if (this.props.error && this.props.error !== prevProps.error) {
            this.setState({error: true, errorMsg: i18n.registration.enter_valid_code})
        } else if (this.props.successConfirmation != prevProps.successConfirmation) {
            this.props.onConfirmationSucceeded()
        }
    }

    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )

        if (this.props.isVisible)
            return (
                <div className="modal modal-confirmation default-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="header">
                                <p className={'title'}>{i18n.registration.sms_confirm}</p>
                                <span className={'close-button'} onClick={() => this.props.onClose()}/>
                            </div>

                            <div className="body">
                                <p className={'mb-4 mt-3'}>{i18n.registration.add_sms_code}</p>

                                {loader}
                                <div className="wizard-form-field">
                                    <TextInput type={'number'}
                                               maxLength={5}
                                               error={this.state.error}
                                               label={i18n.registration.confirmationCode}
                                               value={this.state.codeSms}
                                               raiseUpdates={(codeSms: string) => this.setState({
                                                   codeSms,
                                                   error   : false,
                                                   errorMsg: undefined
                                               })}
                                               onKeyDown={(e) => {
                                                   if (e.key == 'Enter')
                                                       this.sendConfirmationCode()
                                               }}/>

                                    {this.state.errorMsg && <p className={'error'}
                                                               dangerouslySetInnerHTML={{__html: this.state.errorMsg}}/>}
                                </div>

                                <div className={'d-flex justify-content-end mt-3'}>
                                    <button className={'default-btn back-btn mr-3'} onClick={(event): void => {
                                        event.preventDefault()
                                        this.props.onClose()
                                    }}>{i18n.registration.back}</button>
                                    <button className={'default-btn'} onClick={(event): void => {
                                        event.preventDefault()
                                        this.sendConfirmationCode()
                                    }}>{i18n.registration.next}</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        return <div/>
    }

    sendConfirmationCode(): void {
        if (this.state.codeSms.length === 5)
            this.props.sendConfirmationCode(this.props.phoneNumber, this.state.codeSms)
        else if (this.state.codeSms.length === 0)
            this.setState({error: true, errorMsg: i18n.registration.empty_code_error})
        else
            this.setState({error: true, errorMsg: i18n.registration.code_error})
    }
}
