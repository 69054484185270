import React, { PureComponent, ReactNode } from "react";
import { MunicipalService } from "../../../domain/entities/municipalService";

interface Props {
    service: MunicipalService;
    editService: () => void;
    deleteService: () => void;
    swapServices: (sourceServiceId: string, destinationServiceId: string) => void;
}

export class ServiceItem extends PureComponent <Props> {
    render(): ReactNode {
        return (
            <div className={'service-item'}
                 draggable={true}
                 onDrop={e => this.onDrop(e)}
                 onDragStart={e => this.onDragStart(e)}
                 onDragOver={e => e.preventDefault()}>

                <div className={'content'}>
                    <img alt={this.props.service.label} draggable={false} src={this.props.service.icon.url}/>
                    <p className="title">{this.props.service.label}</p>
                </div>

                <div className={'service-actions d-flex justify-content-end'}>
                    <span className={'edit'} onClick={() => this.props.editService()}>Editer</span>
                    <span className={'delete'} onClick={() => this.props.deleteService()}>Supprimer</span>
                </div>
            </div>
        )
    }

    onDragStart(event: React.DragEvent): void {
        const sourceService = JSON.stringify({id: this.props.service.id});
        if (event.dataTransfer) {
            event.dataTransfer.setData("dragContent", sourceService);
        }
    }

    onDrop(event: React.DragEvent): void {
        event.preventDefault();
        if (event.dataTransfer) {
            const sourceService = JSON.parse(event.dataTransfer.getData("dragContent"));
            this.props.swapServices(sourceService.id, this.props.service.id);
        }
    }
}
