import { TextInput } from "../../../../../../../common/adapters/primaries/form/inputs/text.input";
import React from "react";
import { SocialPageInfo } from "../../../../../../../company/domain/entity/socialPageInfo";

interface Props{
    facebookAccount: SocialPageInfo
    textPost: string
    picturePost: string
    changeText: (text: string) => void
}
export const PreviewPost = (props:Props): JSX.Element =>{
    return(
        <div className={'preview_share'}>
         <div className={'account'}>
                <img src={props.facebookAccount.image} className={'account_icon'}/>
                <div className={'account_name'}>{props.facebookAccount.name}</div>
            </div>
            <TextInput type={'text'} numberOfLines={2} className={'title_post'} label={''}
                       error={false} value={props.textPost}
                       raiseUpdates={(text: string) => props.changeText(text)}/>
            <img src={props.picturePost} className={'preview-img'}/>
        </div>
    )
}
