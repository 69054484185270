import {
    VALIDATE_PAYMENT,
    VALIDATE_PAYMENT_FAILED, VALIDATE_PAYMENT_SUCCEEDED, ValidatePaymentActionTypes,
} from "./actionTypes";
import { ValidatePaymentState } from "../../configuration/state";

const initialState: ValidatePaymentState = {
    status: undefined,
    loading: false,
    error: undefined
}

export const validatePaymentReducer = (state = initialState, action: ValidatePaymentActionTypes): ValidatePaymentState => {
    switch (action.type) {
        case VALIDATE_PAYMENT:
            return {
                status: undefined,
                loading: true,
                error: undefined
            }
        case VALIDATE_PAYMENT_SUCCEEDED:
            return {
                status: action.payload,
                loading: false,
                error: undefined
            }
        case VALIDATE_PAYMENT_FAILED:
            return {
                status: undefined,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}
