import React from "react";
import { BroadcastMessage } from "../../../../domain/entities/broadcastMessage";
import { MessagingSpecifications } from "../../specifications";

interface Props {
    message: BroadcastMessage
}

export const BroadcastMessageItem = (props: Props): JSX.Element => {
    const index = props.message.content.indexOf('#markdown[')
    const message = props.message.content.slice(0, index)

    return (
        <div className={'message-container d-flex flex-column align-items-end'}>
            <p className={'date text-center w-100'}>{MessagingSpecifications.formatDate(props.message.createdAt)}</p>

            <div className={'broadcast-message'}>
                <p dangerouslySetInnerHTML={{ __html: message }} />
                {props.message.image && <img src={props.message.image}/>}
                <span>{props.message.sentTo}</span>
            </div>
        </div>
    );
}
