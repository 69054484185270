import { EcommerceOrder } from "../../domain/entities/ecommerceOrder";

export const LOAD_ECOMMERCE_ORDERS = 'LOAD_ECOMMERCE_ORDERS'
export const LOAD_ECOMMERCE_ORDERS_FAILED = 'LOAD_ECOMMERCE_ORDERS_FAILED'
export const LOAD_ECOMMERCE_ORDERS_SUCCEEDED = 'LOAD_ECOMMERCE_ORDERS_SUCCEEDED'

export interface LoadEcommerceOrdersAction {
    type: typeof LOAD_ECOMMERCE_ORDERS;
    payload: string;
}

export interface LoadEcommerceOrdersFailedAction {
    type: typeof LOAD_ECOMMERCE_ORDERS_FAILED;
    payload: string;
}

export interface LoadEcommerceOrdersSucceededAction {
    type: typeof LOAD_ECOMMERCE_ORDERS_SUCCEEDED;
    payload: EcommerceOrder[];
}

export type LoadEcommerceOrdersActionTypes = LoadEcommerceOrdersAction | LoadEcommerceOrdersFailedAction | LoadEcommerceOrdersSucceededAction
