import React, { PureComponent, ReactNode } from "react";
import logo from "../../../../assets/img/logo.png";
import "./sidebar.css";
import { Company } from "../../../../company/domain/entity/company";
import { i18n } from "../../../../configuration/i18n";

interface Props {
    company: Company | null;
    isSuperAdmin: boolean;
}

interface State {
    isVisible: boolean;
    showProducts: boolean;
}

export class AppSideBar extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isVisible   : false,
            showProducts: this.showProductSubMenu(),
        };
    }

    render(): ReactNode {
        const className = this.state.isVisible ? "app-sidebar open" : "app-sidebar";

        return (
            <div className={className}>
                <div className="app-navbar justify-content-between align-items-center">
                    <img src={logo} alt="VivezICI"/>

                    <button className="navbar-toggler" type="button"
                            onClick={() => this.setState({isVisible: !this.state.isVisible})}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div className="app-sidebar-content">
                    <div className="company-profile">
                        <img src={logo} alt="VivezICI" className="app-sidebar-logo"/>

                        <div className="profile-img" style={{backgroundImage: `url("${this.props.company?.profileImageUrl}")`,}}/>

                        <h1>{this.props.company?.name}</h1>
                    </div>

                    <div className="app-sidebar-menu">
                        <a className={this.isActive(["/agenda"])} href="/agenda">
                            <i className="fa fa-calendar"/>
                            <span>{i18n.common.agenda}</span>
                        </a>

                        <a className={this.isActive(["/company", "/editCompany"])} href="/company">
                            <i className="fa fa-building"/>
                            <span>{i18n.common.profile}</span>
                        </a>

                        <a className={this.isActive([
                            "/instants",
                            "/editAgendaEvent/",
                            "/editService/",
                            "/editSpecialOffer/",
                            "/createService",
                            "/createSpecialOffer",
                            "/createAgendaEvent",
                        ])} href="/instants">
                            <i className="fa fa-map-marker-alt"/>
                            <span>{i18n.common.instants}</span>
                        </a>

                        <a className={this.isActive(["/messages"])} href="/messages">
                            <i className="fa fa-comments"/>
                            <span>{i18n.common.messages}</span>
                        </a>

                        {this.renderSubscriptionLink()}

                        {this.renderStatsLink()}

                        {this.renderServicesLink()}

                        <a className={this.isActive(["/marketing"])} href="/marketing">
                            <i className="fa fa-bullhorn"/>
                            <span>{i18n.common.qrcode}</span>
                        </a>

                        {this.props.company?.sellerId &&
                            (
                                <React.Fragment>
                                    <div className={this.isActive(["/products", "/add-product", "/edit-product"])}
                                         onClick={() => this.onProductsClick()}>
                                        <i className="fa fa-box-open"/>
                                        <span>{i18n.common.products}</span>
                                    </div>

                                    <div className={this.state.showProducts ? "app-submenu show" : "app-submenu"}>
                                        <a className={this.isActive(["/products"])} href="/products">
                                            <span>{i18n.common.all_products}</span>
                                        </a>

                                        <a className={this.isActive(["/add-product"])} href="/add-product">
                                            <span>{i18n.common.add_product}</span>
                                        </a>
                                    </div>

                                    <a className={this.isActive(["/transactions"])} href="/transactions">
                                        <i className="fa fa-list-ul"/>
                                        <span>{i18n.common.orders}</span>
                                    </a>
                                </React.Fragment>
                            )
                        }

                        {/*
                        <a className={this.isActive(['/emails'])} href={'/emails'}>
                            <i className={'fa fa-envelope'}/>
                            <span>{i18n.common.emails_pro}</span>
                        </a>*/}

                        {/*<a className={this.isActive(['/my-stats'])} href={'/my-stats'}>
                            <i className={'fa fa-chart-pie'}/>
                            <span>{i18n.common.myStats}</span>
                        </a>*/}

                        {/*this.props.company && this.props.company.membership.type === 'digital' && (
                            <a className={this.isActive(['/template-builder'])} href={'/template-builder'}>
                                <i className={'fa fa-wrench'}/>
                                <span>{i18n.common.template_builder}</span>
                            </a>
                        )*/}
                    </div>
                </div>
            </div>
        );
    }

    isActive(path: string[]): string {
        const active = path.find(
            (pathName) => window.location.pathname.indexOf(pathName) !== -1
        );
        if (active) return "nav-link active";
        else return "nav-link";
    }

    renderStatsLink(): JSX.Element | undefined {
        if (this.props.isSuperAdmin)
            return (
                <a className={this.isActive(["/stats"])} href="/stats">
                    <i className="fa fa-chart-line"/>
                    <span>{i18n.common.stats}</span>
                </a>
            );
    }

    renderSubscriptionLink(): JSX.Element | undefined {
        if (this.canDisplaySubscriptionLink())
            return (
                <a className={this.isActive(["/subscription"])} href="/subscription">
                    <i className="fa fa-credit-card"/>
                    <span>{i18n.common.subscription}</span>
                </a>
            );
    }

    renderServicesLink(): JSX.Element | undefined {
        if (this.props.company && this.props.company.type === "mairie")
            return (
                <a className={this.isActive(["/services"])} href="/services">
                    <i className="fa fa-list-ul"/>
                    <span>{i18n.common.services}</span>
                </a>
            );
    }

    canDisplaySubscriptionLink(): boolean {
        return this.props.company?.type !== "mairie";
    }

    private showProductSubMenu(): boolean {
        const pages = ["/products", "/add-product", "/edit-product"];
        return !!pages.find((pathName) => window.location.pathname.indexOf(pathName) !== -1);
    }

    private onProductsClick(): void {
        const pages = ["/products", "/add-product"];
        if (!pages.find((pathName) => window.location.pathname.indexOf(pathName) !== -1)) {
            window.location.href = "/products";
        } else {
            this.setState({showProducts: !this.state.showProducts});
        }
    }
}
