import React from "react";
import { ThemeCheckboxInput } from "../checkbox.input";

interface Props {
    value: string;
    onChange: (value: string) => void;
}

export const EcommerceShopOptions = (props: Props): JSX.Element => {
    return (
        <div className={"wizard-form-input radio-buttons align-items-start"}>
            <label className="pt-2">Ajout en panier</label>

            <div className="food-menu-checkbox">
                <ThemeCheckboxInput label="Redirection vers le catalogue (boutique)"
                                    checked={props.value === "shop"}
                                    onChange={(isChecked: boolean) => props.onChange(isChecked ? "shop" : "cart")}/>

                <ThemeCheckboxInput label="Redirection vers le panier"
                                    checked={props.value === "cart"}
                                    onChange={(isChecked: boolean) => props.onChange(!isChecked ? "shop" : "cart")}/>
            </div>
        </div>
    )
}
