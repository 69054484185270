import { ProductService } from "../domain/gateway/product.service";
import { ApiProductService } from "../adapters/secondaries/real/apiProduct.service";
import { InMemoryProductService } from "../adapters/secondaries/inMemory/inMemoryProduct.service";
import { EcommerceOrderService } from "../domain/gateway/order.service";
import { ApiEcommerceOrderService } from "../adapters/secondaries/real/apiEcommerceOrder.service";
import { InMemoryEcommerceOrderService } from "../adapters/secondaries/inMemory/inMemoryEcommerceOrder.service";

export class EcommerceDependenciesFactory {

    static getProductService(): ProductService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiProductService()
            default:
                return new InMemoryProductService()
        }
    }

    static getEcommerceOrderService(): EcommerceOrderService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiEcommerceOrderService()
            default:
                return new InMemoryEcommerceOrderService()
        }
    }

}
