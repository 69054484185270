import { ProductDetails } from "../../domain/entities/productDetails";

export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED'
export const EDIT_PRODUCT_SUCCEEDED = 'EDIT_PRODUCT_SUCCEEDED'
export const EDIT_RESET_ACTION = 'EDIT_RESET_ACTION'

export interface EditProductAction {
    type: typeof EDIT_PRODUCT;
    payload: {
        product: ProductDetails;
        companyId: string;
    };
}

export interface EditProductFailedAction {
    type: typeof EDIT_PRODUCT_FAILED;
    payload: string;
}

export interface EditProductSucceededAction {
    type: typeof EDIT_PRODUCT_SUCCEEDED;
}

export interface EditResetAction {
    type: typeof EDIT_RESET_ACTION;
}

export type EditProductActionTypes = EditProductAction | EditProductFailedAction | EditProductSucceededAction | EditResetAction
