import React, { PureComponent, ReactNode } from "react";
import Select from 'react-select';
import { SelectOptionType } from "../../../../domain/types/selectOption.type";

interface Props {
    onChange: (value: string) => void
    error?: boolean
    selected: string
    data: SelectOptionType[]
    label: string
}


export class ChoicePicker extends PureComponent<Props> {

    render(): ReactNode {
        const error = this.props.error ? 'error select-category' : 'select-category'
        return (
            <div className="wizard-form-input select-option-area">
                <label>{this.props.label}</label>

                <Select className={error}
                        classNamePrefix="select"
                        isSearchable={true}
                        value={this.getValueSelected(this.props.selected)}
                        options={this.props.data}
                        onChange={(selected: SelectOptionType | null) => {
                            if (selected !== null) {
                                this.props.onChange(selected.value)
                            }
                        }}/>
            </div>
        )
    }

    getValueSelected(value: string): SelectOptionType | undefined {
        return this.props.data.find(item => item.value === value)
    }
}
