import { Company } from "../../../domain/entity/company";
import { AppState } from "../../../../redux-configuration/AppState";
import { companySelector } from "../../../usecases/updateStoreCompany/selector";
import { connect } from "react-redux";
import { CompanyProfileContainer } from "./companyProfile.container";
import { ManagementInstantHeader } from "../../../../instant-management/domain/entities/managementInstantHeader";
import {
    previewInstantsCompanySelector
} from "../../../../instant-management/usecases/loadPreviewIstantsCompany/loadPreviewInstantsCompany.selectors";
import { Dispatch } from "redux";
import {
    loadPreviewInstantsCompany
} from "../../../../instant-management/usecases/loadPreviewIstantsCompany/loadPreviewInstantsCompany.actions";
import {
    LoadPreviewInstantsCompanyAction
} from "../../../../instant-management/usecases/loadPreviewIstantsCompany/loadPreviewInstantsCompany.types";
import { Follower } from "../../../domain/entity/follower";
import { followersSelector } from "../../../usecases/loadFollowers/selectors";
import { LoadFollowersAction } from "../../../usecases/loadFollowers/actionTypes";
import { loadFollowers } from "../../../usecases/loadFollowers/actions";
import { registrationStepSelector } from "../../../../registration/usecases/manageRegistrationStep/selectors";
import { loadCompanyDetailsLoadingSelector } from "../../../usecases/loadCompanyDetails/selector";

interface StateToPropsType {
    company: Company | null;
    instants: ManagementInstantHeader[] | null;
    followers: Follower[] | null;
    registrationStep: number
    loading: boolean;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company         : companySelector(state),
    instants        : previewInstantsCompanySelector(state),
    followers       : followersSelector(state),
    registrationStep: registrationStepSelector(state),
    loading :  loadCompanyDetailsLoadingSelector(state)
})

interface DispatchToPropsType {
    loadInstants: (companyId: string) => void;
    loadFollowers: (companyId: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadInstants : (companyId: string): LoadPreviewInstantsCompanyAction => dispatch(loadPreviewInstantsCompany(companyId)),
    loadFollowers: (companyId: string): LoadFollowersAction => dispatch(loadFollowers(companyId))
})

export const CompanyProfile = connect(mapStateToProps, mapDispatchToProps)(CompanyProfileContainer)
