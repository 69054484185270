import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from "../../../redux-configuration/AppState";
import { AuthenticationRepository } from "../../domain/gateway/authenticationRepository";
import { LOAD_STORED_TOKEN, LoadStoredTokenAction } from "./actionTypes";
import { loadStoredTokenFailed, loadStoredTokenSucceeded } from "./action";

export const loadStoredTokenEpic: Epic = (action$: ActionsObservable<LoadStoredTokenAction>,
                                        store: StateObservable<AppState>,
                                        { authenticationRepository }: { authenticationRepository: AuthenticationRepository }) =>
    action$.pipe(
        ofType(LOAD_STORED_TOKEN),
        switchMap(() => authenticationRepository.getAuthorizationToken()
            .pipe(
                map((token: string) =>loadStoredTokenSucceeded(token)),
                catchError(() => of(loadStoredTokenFailed()))
            )
        )
    )
