export const LOAD_STORED_TOKEN = 'LOAD_STORED_TOKEN'
export const LOAD_STORED_TOKEN_SUCCEEDED = 'LOAD_STORED_TOKEN_SUCCEEDED'
export const LOAD_STORED_TOKEN_FAILED = 'LOAD_STORED_TOKEN_FAILED'

export interface LoadStoredTokenAction {
    type: typeof LOAD_STORED_TOKEN;
}

export interface LoadStoredTokenSucceededAction {
    type: typeof LOAD_STORED_TOKEN_SUCCEEDED;
    payload: string;
}

export interface LoadStoredTokenFailedAction {
    type: typeof LOAD_STORED_TOKEN_FAILED;
}

export type LoadStoredTokenActionType = LoadStoredTokenAction

export type LoadStoredTokenActionTypes =
    LoadStoredTokenAction
    | LoadStoredTokenSucceededAction
    | LoadStoredTokenFailedAction
