import React, { PureComponent, ReactNode } from 'react';
import './instantItem.buttons.css';
import { FacebookProvider } from "react-facebook";

interface Props {
    instantId: string;
    instantStatus: boolean;
    deleteInstant: () => void;
    updateStatus: () => void;
    editInstantPath: string
    shareInstantOnFacebook: () => void;
    shareInstantOnInstagram: () => void;
    displayShare: boolean;
}

export class InstantItemButtons extends PureComponent<Props> {

    render(): ReactNode {
        const className = this.props.instantStatus ? "btn status-btn active" : "btn status-btn"
        const icon = this.props.instantStatus ? "fas fa-play" : "fas fa-pause"
        return (
            <div className="instant-btn d-flex align-items-center">
                {this.props.displayShare ? <FacebookProvider appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`} version={'v16.0'}>
                    <button onClick={() => this.props.shareInstantOnInstagram()} className="btn share-btn">
                        <img src={'https://cdn-icons-png.flaticon.com/512/3955/3955024.png'} className={'icon-facebook'}/>
                    </button>
                    <button onClick={() => this.props.shareInstantOnFacebook()} className="btn share-btn">
                        <img src={'https://cdn-icons-png.flaticon.com/512/145/145802.png'} className={'icon-facebook'}/>
                    </button>
                </FacebookProvider> : null}
                <a href={this.props.editInstantPath} className="btn edit-btn">
                    <i className="fas fa-edit"/>
                </a>
                <button type="button" className="btn delete-btn"
                        onClick={() => this.props.deleteInstant()}>
                    <i className="far fa-trash-alt"/>
                </button>
                <button type="button" className={className}
                        onClick={() => this.props.updateStatus()}>
                    <i className={icon}/>
                </button>
            </div>
        )
    }
}
