import { ApplicationContext } from "../../../configuration/application.context";

const moment = ApplicationContext.getInstance().momentJs()

export class StatsCompany {
    constructor(
        private _id: string,
        private _name: string,
        private _type: 'commerce' | 'entreprise' | 'association',
        private _category: string | undefined,
        private _address: string,
        private _icon: string,
        private _subdomain: string,
        private _email: string,
        private _latitude: number,
        private _longitude: number,
        private _creationDate: Date
    ) {
    }

    get id(): string {
        return this._id
    }

    get name(): string {
        return this._name;
    }

    get type(): 'commerce' | 'entreprise' | 'association' {
        return this._type;
    }

    get category(): string | undefined {
        return this._category;
    }

    get address(): string {
        return this._address;
    }

    get icon(): string {
        return this._icon;
    }

    get subdomain(): string {
        return this._subdomain;
    }

    get email(): string {
        return this._email;
    }

    get latitude(): number {
        return this._latitude;
    }

    get longitude(): number {
        return this._longitude;
    }

    get stringCreationDate(): string {
        return moment(this._creationDate).format('ll');
    }

    get creationDate(): Date {
        return this._creationDate
    }


}