import {
    POST_INSTANT_ON_INSTAGRAM, POST_INSTANT_ON_INSTAGRAM_FAILED, POST_INSTANT_ON_INSTAGRAM_SUCCEEDED, PostInstantOnInstagramActionTypes
} from "./actionTypes";
import { ShareInstantState } from "../../configuration/state";

const initialState: ShareInstantState = {
    loading: false,
    error: undefined
}
export const postInstantOnInstagramReducer = (state = initialState, action: PostInstantOnInstagramActionTypes): ShareInstantState =>{
    switch (action.type){
        case POST_INSTANT_ON_INSTAGRAM:
            return {loading: true, error: undefined}
        case POST_INSTANT_ON_INSTAGRAM_SUCCEEDED:
            return {loading: false, error: undefined}
        case POST_INSTANT_ON_INSTAGRAM_FAILED:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}
