import { Observable, from } from 'rxjs';
import { AuthenticationRepository } from '../../../domain/gateway/authenticationRepository';


export class LocalStorageRepository implements AuthenticationRepository {

    saveAuthorizationToken(token: string): Observable<void> {
        const saveTokenPromise = new Promise<void>((resolve) => {
            localStorage.setItem('token', token)
            resolve(void 0)
        })

        return from(saveTokenPromise)
    }

    getAuthorizationToken(): Observable<string> {
        const getTokenPromise = new Promise<string>((resolve) => {
            const token = localStorage.getItem('token')
            if (typeof token === "string")
                resolve(token)
            else
                resolve('TOKEN_NOT_FOUND')
        })

        return from(getTokenPromise)
    }
}
