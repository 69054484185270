import { combineEpics, Epic } from "redux-observable";
import { loadPreviousChatMessagesEpic } from "../usecases/message/load/epic";
import { loadNewChatMessagesEpic } from "../usecases/message/loadNewMessages/epic";
import { sendChatMessageEpic } from "../usecases/message/send/epic";
import { loadBusinessConversationsEpic } from "../usecases/conversation/epic";
import { loadBroadcastConversationEpic } from "../usecases/broadcast/load/epic";
import { sendBroadcastMessageEpic } from "../usecases/broadcast/send/epic";

export const messagingRootEpics: Epic = combineEpics(
    loadPreviousChatMessagesEpic,
    loadNewChatMessagesEpic,
    sendChatMessageEpic,
    loadBusinessConversationsEpic,
    loadBroadcastConversationEpic,
    sendBroadcastMessageEpic
)
