import React, { PureComponent, ReactNode } from "react";
import { Line } from 'react-chartjs-2';
import { StatsProfile } from "../../../../domain/entity/statsProfile";
import { i18n } from "../../../../../configuration/i18n";
import { Theme } from "../../../../../common/configuration/theme/app.theme";
import './users.chart.css';

interface Props {
    profiles: StatsProfile[];
}

interface State {
    slice: number
}

export class UsersChart extends PureComponent<Props, State> {

    readonly slices = [30, 60, 90, 120, 150]

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            slice: 30
        }
    }

    render(): ReactNode {
        const profiles = this.props.profiles.slice(Math.max(this.props.profiles.length - this.state.slice, 0))
        const data = {
            labels  : profiles.map(item => item.creationDate),
            datasets: [
                {
                    label          : i18n.stats.inscription_number,
                    data           : profiles.map(item => item.count),
                    fill           : false,
                    backgroundColor: Theme.blueMinsk,
                    borderColor    : Theme.blueMinsk,
                }
            ]
        };
        return (
            <div className={'line-chart mb-3 p-3'}>
                <div className="d-flex align-items-center justify-content-end">
                    <select className="form-control"
                            onChange={(event): void => this.setState({ slice: Number(event.target.value) })}>

                        {this.slices.map((option, index) => <option key={index} value={option}>{option} {i18n.stats.days}</option>)}
                    </select>
                </div>

                <Line type="line" data={data}/>
            </div>
        )
    }
}
