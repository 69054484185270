import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RegistrationRepository } from "../../domain/gateway/registration.repository";
import { LOAD_LOCAL_USER, LoadLocalUserActionType } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { loadLocalUserFailed, loadLocalUserSucceeded } from "./actions";
import { User } from "../../domain/entity/user";

export const loadLocalUserEpic: Epic = (action$: ActionsObservable<LoadLocalUserActionType>,
                                            store: StateObservable<AppState>,
                                            { registrationRepository }: { registrationRepository: RegistrationRepository }) =>
    action$.pipe(
        ofType(LOAD_LOCAL_USER),
        switchMap(() => registrationRepository.getUser()
            .pipe(
                map((user: User) => loadLocalUserSucceeded(user)),
                catchError(err => of(loadLocalUserFailed(err)))
            )
        )
    )

