import { EDIT_PRODUCT_STATUS, EDIT_PRODUCT_STATUS_FAILED, EDIT_PRODUCT_STATUS_SUCCEEDED, EditProductStatusActionTypes } from "./actionTypes";
import { ProductManagementState } from "../../configuration/state";

const initialState: ProductManagementState = {
    loading: false,
    success: undefined,
    error  : undefined,
}

export const editProductStatusReducer = (state = initialState, action: EditProductStatusActionTypes): ProductManagementState => {
    switch (action.type) {
        case EDIT_PRODUCT_STATUS:
            return {
                loading: true,
                success: undefined,
                error  : undefined,
            }
        case EDIT_PRODUCT_STATUS_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case EDIT_PRODUCT_STATUS_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
}
