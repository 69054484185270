import React, { PureComponent, ReactNode } from 'react';
import { ManagementInstantHeader } from "../../../../../domain/entities/managementInstantHeader";
import { ApplicationContext } from "../../../../../../configuration/application.context";
import { i18n } from "../../../../../../configuration/i18n";
import './instantItem.css';
import { InstantItemButtons } from "../buttons/instantItem.buttons";
import { ApplicationIcons } from "../../../../../../common/adapters/primaries/applicationIcons";
import { PostFacebookModal } from '../postFacebookModal';
import { PostInstagramModal } from '../postInstagramModal';

interface Props {
    instant: ManagementInstantHeader;
    deleteInstant: (instantId: string, companyId: string) => void;
    updateInstantStatus: (instantId: string, newStatus: boolean) => void;
    editInstantPath: string;
    displayShare: boolean;
}

interface State {
    status: boolean
    visibleFacebookModal: boolean
    visibleInstagramModal: boolean
}

const moment = ApplicationContext.getInstance().momentJs()

export class InstantItem extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            status      : this.props.instant.status,
            visibleFacebookModal: false,
            visibleInstagramModal: false
        }
    }

    render(): ReactNode {
        return (
            <div className="col-12 col-xl-6 instant-item pl-0">
                <div className="instant-card d-flex flex-wrap">
                    <div className="block-image" style={{backgroundImage: `url("${this.props.instant.picture}")`}}/>

                    <div className="block-content">
                        <div className="d-flex justify-content-between">
                            <div className="instant-item-title d-flex justify-content-start align-items-center">
                                {ApplicationIcons(this.props.instant.category, 25, 28)}
                                <h3 className="title margin-10">
                                    <a href={this.props.editInstantPath + this.props.instant.id}>{this.props.instant.title}</a>
                                </h3>
                            </div>

                            <InstantItemButtons instantId={this.props.instant.id} displayShare={this.props.displayShare}
                                                instantStatus={this.state.status}
                                                deleteInstant={() => this.deleteInstant()}
                                                editInstantPath={this.props.editInstantPath + this.props.instant.id}
                                                shareInstantOnFacebook={() => this.setState({visibleFacebookModal: true})}
                                                shareInstantOnInstagram={() => this.setState({visibleInstagramModal: true})}
                                                updateStatus={() => {
                                                    this.setState({status: !this.state.status},
                                                        () => this.props.updateInstantStatus(this.props.instant.id, this.state.status)
                                                    )
                                                }}/>
                            <PostFacebookModal visible={this.state.visibleFacebookModal}
                                               instant={this.props.instant}
                                               onClose={() => this.setState({visibleFacebookModal: false})}/>
                            <PostInstagramModal visible={this.state.visibleInstagramModal}
                                               instant={this.props.instant}
                                               onClose={() => this.setState({visibleInstagramModal: false})}/>
                        </div>

                        <p className="instant-date">{moment(this.props.instant.dateStart).format('DD MMMM YYYY à HH:mm')}</p>
                        <span className="instant-description">{this.props.instant.description}</span>
                    </div>
                </div>
            </div>
        )
    }

    deleteInstant(): void {
        const answer = window.confirm(i18n.company.deleteInstant(this.props.instant.title));
        if (answer) {
            this.props.deleteInstant(this.props.instant.id, this.props.instant.businessId)
        }
    }
}
