import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { CREATE_INSTANT, CreateInstantAction } from "./createInstant.types";
import { AppState } from "../../../redux-configuration/AppState";
import { createInstantFailed, createInstantSucceeded } from "./createInstant.actions";
import { loadInstantsCompany } from "../loadInstantsCompany/loadInstantsCompany.actions";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";

export const createInstantEpic: Epic = (action$: ActionsObservable<CreateInstantAction>,
                                        store: StateObservable<AppState>,
                                        { instantManagementService }: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(CREATE_INSTANT),
        switchMap(action => instantManagementService.createRemoteInstant(action.payload)
            .pipe(
                concatMap(() => [
                    createInstantSucceeded(),
                    loadInstantsCompany(action.payload.instantInformation.businessId)
                ]),
                catchError(error => of(createInstantFailed(error)))
            )
        )
    )
