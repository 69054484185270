import React from "react";
import './statCard.css';
import { Theme } from "../../../../../common/configuration/theme/app.theme";

interface Props {
    title: string;
    number: number | undefined;
    prefix?: string;
    suffix?: string;
    color?: string
}

export const StatCard = (props: Props): JSX.Element => {

    return (
        <div className={'statCard'}>
            <h3 className={'number'} style={{color: props.color}}>{props.prefix} {props.number} {props.suffix}</h3>
            <p className={'title'}>{props.title}</p>
        </div>
    )
}

StatCard.defaultProps = {
    color: Theme.blueMinsk,
};
