import { MunicipalService } from "../../domain/entities/municipalService";
import { ServiceIcon } from "../../domain/entities/serviceIcon";

export const LISTING_MUNICIPAL_SERVICES = 'LISTING_MUNICIPAL_SERVICES'
export const LISTING_MUNICIPAL_SERVICES_FAILED = 'LISTING_MUNICIPAL_SERVICES_FAILED'
export const LISTING_MUNICIPAL_SERVICES_SUCCEEDED = 'LISTING_MUNICIPAL_SERVICES_SUCCEEDED'
export const LISTING_SERVICE_ICONS_SUCCEEDED = 'LISTING_SERVICE_ICONS_SUCCEEDED'


export interface ListingMunicipalServicesAction {
    type: typeof LISTING_MUNICIPAL_SERVICES,
    payload: string
}

export interface ListingMunicipalServicesFailedAction {
    type: typeof LISTING_MUNICIPAL_SERVICES_FAILED,
    payload: string
}

export interface ListingMunicipalServicesSucceededAction {
    type: typeof LISTING_MUNICIPAL_SERVICES_SUCCEEDED,
    payload: MunicipalService[]
}

export interface ListingServiceIconsSucceededAction {
    type: typeof LISTING_SERVICE_ICONS_SUCCEEDED,
    payload: ServiceIcon[]
}

export type ListingMunicipalServicesActionTypes =
    ListingMunicipalServicesAction
    | ListingMunicipalServicesFailedAction
    | ListingMunicipalServicesSucceededAction
    | ListingServiceIconsSucceededAction
