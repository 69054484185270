import React from "react";
import {
    InstantCategories,
    InstantCategoriesDescription
} from "../../../../../domain/entities/types/managementInstantCategoryType";
import { i18n } from "../../../../../../configuration/i18n";

interface Props {
    category: string;
    visible: boolean;
    onClose: () => void;
}

export const InstantCategoryModal = (props: Props): JSX.Element => {
    if (props.visible)
        return (
            <div className="modal instant-management-modal default-modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="header">
                            <p className={'title'}>{InstantCategories[props.category]}</p>
                            <span className={'close-button'} onClick={() => props.onClose()}/>
                        </div>

                        <div className="body">
                            <p>{InstantCategoriesDescription[props.category]}</p>

                            <div className="d-flex justify-content-end">
                                <button className={'default-btn'} onClick={() => props.onClose()}>{i18n.management_instant.ok}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    return <div/>
};
