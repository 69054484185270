import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { RegistrationContainer } from "./registration.container";
import { User } from "../../../domain/entity/user";
import { AppState } from "../../../../redux-configuration/AppState";
import { localUserSelector } from "../../../usecases/loadLocalUser/selector";
import { localCodeSelector } from "../../../usecases/loadLocalCode/selector";
import { localBusinessSelector } from "../../../usecases/loadLocalBusiness/selector";
import {
    companyDetailsSelector,
    loadCompanyDetailsLoadingSelector
} from "../../../../company/usecases/loadCompanyDetails/selector";
import { Company } from '../../../../company/domain/entity/company';
import { ManagementInstantHeader } from "../../../../instant-management/domain/entities/managementInstantHeader";
import {
    instantsCompanySelector
} from "../../../../instant-management/usecases/loadInstantsCompany/loadInstantsCompany.selectors";
import { registrationStepSelector } from "../../../usecases/manageRegistrationStep/selectors";

interface StateToPropsType {
    localUser: User | null;
    registrationFormStep: number | undefined;
    localCode: string | undefined;
    localBusiness: Company | null;
    business: Company | null;
    loading: boolean;
    instants: ManagementInstantHeader[] | null;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    localUser           : localUserSelector(state),
    registrationFormStep: registrationStepSelector(state),
    localCode           : localCodeSelector(state),
    localBusiness       : localBusinessSelector(state),
    business            : companyDetailsSelector(state),
    instants            : instantsCompanySelector(state),
    loading             : loadCompanyDetailsLoadingSelector(state)
})


export const Registration = connect(mapStateToProps)(withRouter(RegistrationContainer))
