import { Observable, of } from 'rxjs';
import { RegistrationRepository } from "../../../domain/gateway/registration.repository";
import { User } from "../../../domain/entity/user";
import { UserBuilder } from "../../../domain/builder/user.builder";
import { Siret } from "../../../domain/entity/business/Siret";
import { Company } from "../../../../company/domain/entity/company";
import { CompanyBuilder } from "../../../../company/domain/builder/company.builder";

const moment = require('moment');

export class InMemoryRegistrationRepository implements RegistrationRepository {
    saveStep(): Observable<void> {
        return of();
    }

    getStep(): Observable<number> {
        return of(1);
    }

    saveUser(): Observable<void> {
        return of();
    }

    getUser(): Observable<User> {
        return of(
            new UserBuilder()
            .withFirstName('John')
            .withLastName('Doe')
            .withPhoneNumber('+33755506670')
            .withBirthDate(moment('2000-01-01').format('YYYY-MM-DD'))
            .build()
        );
    }

    saveConfirmationCode(): Observable<void> {
        return of();
    }

    getConfirmationCode(): Observable<string> {
        return of('12345');
    }

    saveBusiness(): Observable<void> {
        return of();
    }

    getBusiness(): Observable<Company> {
        return of(
            new CompanyBuilder()
                .withName('Store')
                .withType('commerce')
                .withAdministrativeNumber(new Siret('493856812'))
                .build()
        );
    }

    saveBusinessByCode(): Observable<void> {
        return of();
    }

    getBusinessByCode(): Observable<Company> {
        return of(
            new CompanyBuilder()
                .withName('Store')
                .withType('commerce')
                .withAdministrativeNumber(new Siret('493856812'))
                .build()
        );
    }
}
