import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { ClickCollectPicture } from "../../../../../domain/types/clickCollectPicture";
import { FileWithIconInput } from "../../../../../../common/adapters/primaries/form/inputs/fileInputWithIcon/fileWithIcon.input";
import { CropImageModal } from "../../../../../../common/adapters/primaries/modal/cropImageModal";
import { TextInput } from "../../../../../../common/adapters/primaries/form/inputs/text.input";
import { FormPremiumStateType } from "../types";

interface Props {
    clickCollect: FormPremiumStateType;
    isSubmitted: boolean;
    raiseUpdates: (state: FormPremiumStateType) => void
}

interface State {
    pictureToCrop: File | undefined
    pictureName: string
    visibleModal: boolean
    errorPictureSize: string
}

export class CompanyPremiumClickCollect extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            pictureToCrop   : undefined,
            pictureName     : '',
            visibleModal    : false,
            errorPictureSize: ''
        }
    }

    render(): ReactNode {
        const errorMessage = this.state.errorPictureSize !== '' && <p className={'error'}>{this.state.errorPictureSize}</p>

        return (
            <div className={'click-collect-section pl-sm-4 ml-sm-5 mb-4 py-3'}>
                <TextInput type={'text'}
                           error={this.props.isSubmitted && this.props.clickCollect.descriptionClickCollect.length < 10}
                           label={i18n.company.description_clickCollect}
                           value={this.props.clickCollect.descriptionClickCollect}
                           raiseUpdates={(value: string) => this.raiseUpdateDescription(value)}/>

                <div className={'click-collect-images-block'}>
                    <div className={'ml-2 mb-2'}>
                        <p className={'mb-2'}>{i18n.company.add_pictures_clickCollect}</p>
                        <p className={'ml-4 mb-1'}>{i18n.company.your_menu}</p>
                        <p className={'ml-4'}>{i18n.company.your_products}</p>
                    </div>

                    <div className={'sub-section d-flex justify-content-center flex-wrap mb-3'}>
                        <FileWithIconInput file={this.props.clickCollect.picture_0}
                                           error={this.props.isSubmitted && !this.props.clickCollect.picture_0.picture}
                                           raiseUpdates={(picture_0: ClickCollectPicture) => this.raiseUpdate(picture_0, 'picture_0')}/>

                        <FileWithIconInput file={this.props.clickCollect.picture_1}
                                           error={false}
                                           raiseUpdates={(picture_1: ClickCollectPicture) => this.raiseUpdate(picture_1, 'picture_1')}/>

                        <FileWithIconInput file={this.props.clickCollect.picture_2}
                                           error={false}
                                           raiseUpdates={(picture_2: ClickCollectPicture) => this.raiseUpdate(picture_2, 'picture_2')}/>
                        <FileWithIconInput file={this.props.clickCollect.picture_3}
                                           error={false}
                                           raiseUpdates={(picture_3: ClickCollectPicture) => this.raiseUpdate(picture_3, 'picture_3')}/>
                    </div>

                    {errorMessage}

                    {this.renderCropModal()}
                </div>
            </div>
        )
    }

    raiseUpdateDescription(descriptionClickCollect: string): void {
        this.props.raiseUpdates({
            ...this.props.clickCollect, descriptionClickCollect: descriptionClickCollect
        })
    }

    raiseUpdate(picture: ClickCollectPicture, name: string): void {
        this.setState({errorPictureSize: ''})
        if (picture.picture)
            this.checkFileSize(picture.picture).then(result => {
                if (result)
                    this.setState({
                        visibleModal : true,
                        pictureName  : name,
                        pictureToCrop: picture.picture
                    })
                else
                    this.setState({errorPictureSize: i18n.company.error_picture_size})
            })
        else
            this.props.raiseUpdates({
                ...this.props.clickCollect, [name]: picture
            })
    }

    checkFileSize(image: File): Promise<boolean> {
        const promiseCheck = new Promise<boolean>((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(image);
            img.onload = () => {
                URL.revokeObjectURL(objectUrl);
                resolve(img.width >= 800 && img.height >= 1000)
            }
            img.src = objectUrl
        })
        return promiseCheck
    }

    renderCropModal(): JSX.Element | undefined {
        if (this.state.pictureToCrop && this.state.visibleModal && this.state.pictureName !== '') {
            return (
                <CropImageModal url={URL.createObjectURL(this.state.pictureToCrop)}
                                name={this.state.pictureToCrop.name}
                                width={800}
                                height={1000}
                                crop={(picture: File) => {
                                    this.setState({
                                        visibleModal : false,
                                        pictureToCrop: undefined
                                    }, () => {
                                        const id = Number(this.state.pictureName.substr(this.state.pictureName.length - 1))
                                        this.props.raiseUpdates({
                                            ...this.props.clickCollect,
                                            [this.state.pictureName]: {
                                                id,
                                                pictureUrl: URL.createObjectURL(picture),
                                                picture
                                            }
                                        })
                                    })
                                }}
                                close={() => this.setState({
                                    visibleModal : false,
                                    pictureToCrop: undefined
                                }, () => {
                                    const id = Number(this.state.pictureName.substr(this.state.pictureName.length - 1))
                                    this.props.raiseUpdates({
                                        ...this.props.clickCollect,
                                        [this.state.pictureName]: {
                                            id,
                                            pictureUrl: '',
                                            picture   : undefined
                                        }
                                    })
                                })}/>
            )
        }
    }
}
