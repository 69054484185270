import { Follower } from "../../domain/entity/follower";
import {
    LOAD_FOLLOWERS, LOAD_FOLLOWERS_FAILED, LOAD_FOLLOWERS_SUCCEEDED, LoadFollowersAction, LoadFollowersFailedAction, LoadFollowersSucceededAction
} from "./actionTypes";


export const loadFollowers = (companyId: string): LoadFollowersAction =>({
    type: LOAD_FOLLOWERS,
    payload: companyId
})
export const loadFollowersFailed = (error: string): LoadFollowersFailedAction =>({
    type: LOAD_FOLLOWERS_FAILED,
    payload: error
})

export const loadFollowersSucceeded=(followers: Follower[]): LoadFollowersSucceededAction =>({
    type: LOAD_FOLLOWERS_SUCCEEDED,
    payload: followers
})
