import React, { PureComponent, ReactNode } from 'react';
import { CreateAgendaInstant } from '.';
import { i18n } from "../../../../../configuration/i18n";
import { DefaultPageHeader } from "../../../../../common/adapters/primaries/defaultPage.header";

interface Props {
    onCreate: () => void
}

export class CreateAgendaInstantPage extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div>
                <DefaultPageHeader title={i18n.management_instant.add_agenda_instant}/>

                <CreateAgendaInstant onCreate={() => window.location.href = '/instants'}/>
            </div>
        )
    }
}
