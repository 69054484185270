import { combineReducers } from 'redux';
import { RegistrationState } from "./state";
import { signUpReducer } from "../usecases/signUp/reducer";
import { sendConfirmationCodeReducer } from "../usecases/confirmation/reducer";
import { updateProfileReducer } from "../usecases/updateProfile/reducer";
import { loadBusinessByCodeReducer } from "../usecases/loadBusinessByCode/reducer";
import { registerBusinessReducer } from "../usecases/registerBusiness/reducer";
import { updateBusinessReducer } from "../usecases/updateBusiness/reducer";
import { loadLocalUserReducer } from "../usecases/loadLocalUser/reducer";
import { loadLocalBusinessReducer } from "../usecases/loadLocalBusiness/reducer";
import { loadLocalCodeReducer } from "../usecases/loadLocalCode/reducer";
import { searchCityReducer } from "../usecases/searchCity/reducer";
import { manageRegistrationStepReducer } from "../usecases/manageRegistrationStep/reducer";


export const registrationReducer = combineReducers<RegistrationState>({
    signUp           : signUpReducer,
    confirmation     : sendConfirmationCodeReducer,
    updateProfile    : updateProfileReducer,
    loadBusiness     : loadBusinessByCodeReducer,
    registerBusiness : registerBusinessReducer,
    updateBusiness   : updateBusinessReducer,
    loadLocalUser    : loadLocalUserReducer,
    loadLocalCode    : loadLocalCodeReducer,
    loadLocalBusiness: loadLocalBusinessReducer,
    searchCity       : searchCityReducer,
    step             : manageRegistrationStepReducer
})
