import { User } from "../entity/user";

export class UserBuilder {
    protected _firstName: string
    protected _lastName: string
    protected _phoneNumber: string
    protected _birthDate: Date
    protected _email: string
    protected _city: string
    protected _zipCode: string

    withFirstName(value: string): UserBuilder {
        this._firstName = value
        return this
    }

    withLastName(value: string): UserBuilder {
        this._lastName = value
        return this
    }

    withPhoneNumber(value: string): UserBuilder {
        this._phoneNumber = value
        return this
    }

    withBirthDate(value: Date): UserBuilder {
        this._birthDate = value
        return this
    }
    withEmail(value: string): UserBuilder {
        this._email= value
        return this
    }

    withCity(value: string): UserBuilder {
        this._city = value
        return this
    }

    withZipCode(value: string): UserBuilder {
        this._zipCode = value
        return this
    }

    build(): User {
        return new User(
            this._firstName,
            this._lastName,
            this._phoneNumber,
            this._birthDate,
            this._email,
            this._city,
            this._zipCode
        )
    }
}
