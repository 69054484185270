import {
    DELETE_PRODUCT, DELETE_PRODUCT_FAILED, DELETE_PRODUCT_SUCCEEDED,
    DeleteProductAction, DeleteProductFailedAction, DeleteProductSucceededAction,
} from "./actionTypes";

export const deleteProduct = (productId: string, companyId: string): DeleteProductAction => ({
    type   : DELETE_PRODUCT,
    payload: {productId, companyId}
})

export const deleteProductFailed = (error: string): DeleteProductFailedAction => ({
    type   : DELETE_PRODUCT_FAILED,
    payload: error
})

export const deleteProductSucceeded = (): DeleteProductSucceededAction => ({
    type: DELETE_PRODUCT_SUCCEEDED
})
