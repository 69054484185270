import React from "react";
import { i18n } from "../../../../../configuration/i18n";
import { TextInput } from "../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeConfigurationBlockType } from "../types";
import { ThemeLogoInput } from "./logoInput";

interface Props {
    data: ThemeConfigurationBlockType;
    isSubmitted: boolean;
    companyId: string;
    onChange: (key: keyof ThemeConfigurationBlockType, value: string[] | string) => void;
}

export const HeaderConfiguration = (props: Props): JSX.Element => {
    return (
        <div>
            <ThemeLogoInput type={'image'}
                            previousUrl={props.data.images?.[0] ?? ''}
                            companyId={props.companyId}
                            onChange={(value: string) => props.onChange('images', [value])}/>

            <TextInput type={'color'}
                       error={props.isSubmitted && (!props.data.textColor || props.data.textColor.length === 0)}
                       label={i18n.company.text_color}
                       value={props.data.textColor ?? ''}
                       raiseUpdates={(value: string) => props.onChange('textColor', value)}/>

            <TextInput type={'color'}
                       error={props.isSubmitted && (!props.data.backgroundColor || props.data.backgroundColor.length === 0)}
                       label={i18n.company.background_color}
                       value={props.data.backgroundColor ?? ''}
                       raiseUpdates={(value: string) => props.onChange('backgroundColor', value)}/>
        </div>
    )
}
