import {
    CHANGE_SERVICES_ORDER, CHANGE_SERVICES_ORDER_FAILED, CHANGE_SERVICES_ORDER_SUCCEEDED, ChangeServicesOrderAction, ChangeServicesOrderFailedAction,
    ChangeServicesOrderSucceededAction
} from "./actionTypes";

export const changeServicesOrder = (mairieId: string, serviceIds: string[]): ChangeServicesOrderAction => ({
    type   : CHANGE_SERVICES_ORDER,
    payload: {mairieId, serviceIds}
})

export const changeServicesOrderFailed = (error: string): ChangeServicesOrderFailedAction => ({
    type   : CHANGE_SERVICES_ORDER_FAILED,
    payload: error
})

export const changeServicesOrderSucceeded = (): ChangeServicesOrderSucceededAction => ({
    type: CHANGE_SERVICES_ORDER_SUCCEEDED
})
