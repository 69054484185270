import { PaymentStatus } from "../../domain/entities/types/subscription.type";

export const VALIDATE_PAYMENT = 'VALIDATE_PAYMENT'
export const VALIDATE_PAYMENT_SUCCEEDED = 'VALIDATE_PAYMENT_SUCCEEDED'
export const VALIDATE_PAYMENT_FAILED = 'VALIDATE_PAYMENT_FAILED'

export interface ValidatePaymentAction {
    type: typeof VALIDATE_PAYMENT;
    payload: {
        sessionId: string;
        companyId: string;
    }
}

export interface ValidatePaymentSucceededAction {
    type: typeof VALIDATE_PAYMENT_SUCCEEDED;
    payload: PaymentStatus;
}

export interface ValidatePaymentFailedAction {
    type: typeof VALIDATE_PAYMENT_FAILED;
    payload: string;
}

export type ValidatePaymentActionTypes = ValidatePaymentAction
    | ValidatePaymentSucceededAction
    | ValidatePaymentFailedAction
