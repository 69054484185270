import { ProductDetails } from "./productDetails";
import { ProductGalleryImage } from "./productGalleryImage";

export class ProductDetailsBuilder {

    private _id: string;
    private _name: string;
    private _image: string | File;
    private _category: string;
    private _price: number;
    private _stock: number;
    private _weight: number;
    private _length: number;
    private _width: number;
    private _height: number;
    private _isActive: boolean;
    private _gallery: (ProductGalleryImage | File | string)[];
    private _sku: string;
    private _description: string;
    private _shortDescription: string;
    private _createdAt: Date;
    private _updatedAt: Date;

    withId(value: string): ProductDetailsBuilder {
        this._id = value
        return this
    }

    withName(value: string): ProductDetailsBuilder {
        this._name = value
        return this
    }

    withImage(value: string | File): ProductDetailsBuilder {
        this._image = value
        return this
    }

    withCategory(value: string): ProductDetailsBuilder {
        this._category = value
        return this
    }

    withPrice(value: number): ProductDetailsBuilder {
        this._price = value
        return this
    }

    withStock(value: number): ProductDetailsBuilder {
        this._stock = value
        return this
    }

    withWeight(value: number): ProductDetailsBuilder {
        this._weight = value
        return this
    }

    withLength(value: number): ProductDetailsBuilder {
        this._length = value
        return this
    }

    withWidth(value: number): ProductDetailsBuilder {
        this._width = value
        return this
    }

    withHeight(value: number): ProductDetailsBuilder {
        this._height = value
        return this
    }

    withIsActive(value: boolean): ProductDetailsBuilder {
        this._isActive = value
        return this
    }

    withGallery(value: (ProductGalleryImage | File | string)[]): ProductDetailsBuilder {
        this._gallery = value
        return this
    }

    withSku(value: string): ProductDetailsBuilder {
        this._sku = value
        return this
    }

    withDescription(value: string): ProductDetailsBuilder {
        this._description = value
        return this
    }

    withShortDescription(value: string): ProductDetailsBuilder {
        this._shortDescription = value
        return this
    }

    withCreatedAt(value: Date): ProductDetailsBuilder {
        this._createdAt = value
        return this
    }

    withUpdatedAt(value: Date): ProductDetailsBuilder {
        this._updatedAt = value
        return this
    }

    build(): ProductDetails {
        return new ProductDetails(
            this._id,
            this._name,
            this._image,
            this._category,
            this._price,
            this._stock,
            this._isActive,
            this._gallery,
            this._sku,
            this._description,
            this._shortDescription,
            this._weight,
            this._length,
            this._width,
            this._height,
            this._createdAt,
            this._updatedAt
        )
    }
}
