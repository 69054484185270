import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { HttpClient } from "../../../domain/gateway/httpClients";
import { AuthenticationRepository } from "../../../../authentication/domain/gateway/authenticationRepository";

export class SecuredObservableAjaxHttpClient implements HttpClient {
    private authenticationRepository: AuthenticationRepository

    constructor(authenticationRepository: AuthenticationRepository) {
        this.authenticationRepository = authenticationRepository
    }

    get<R>(url: string, headers?: object): Observable<R> {
        return this.authenticationRepository.getAuthorizationToken().pipe(
            switchMap(
                (token: string) => ajax.getJSON<R>(url, this.getHeaders(token, headers))
            )
        )
    }

    post(url: string, body?: object, headers?: object): Observable<AjaxResponse> {
        return this.authenticationRepository.getAuthorizationToken().pipe(
            switchMap((token: string) =>
                ajax.post(url, body, this.getHeaders(token, headers))
            )
        )
    }


    delete(url: string, headers?: object): Observable<AjaxResponse> {
        return this.authenticationRepository.getAuthorizationToken().pipe(
            switchMap((token: string) =>
                ajax.delete(url, this.getHeaders(token, headers))
            )
        )
    }


    private getHeaders(token: string, headers?: object): object {
        return {
            ...headers,
            Authorization: `${token}`
        }
    }
}
