import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { ManagementInstantFormType } from "../../../form/type/managementInstantFormType";
import { ActivationTimeType, Schedule } from "../../../../../domain/entities/types/activationTimeType";
import { CompanyOpeningHours } from "../../../../../../company/domain/entity/companyOpeningHours";
import { FixedScheduleContainer } from "../../../form/components/fixedSchedule/fixedSchedule.container";

interface Props {
    instant: ManagementInstantFormType;
    companyOpeningHours: CompanyOpeningHours[] | undefined
    raiseUpdate: (key: keyof ManagementInstantFormType, value: ActivationTimeType | undefined) => void
    hasError: boolean
}

interface State {
    fixedSchedule: Schedule | undefined;
}

export class AgendaInstantPlaningSection extends PureComponent<Props, State> {

    private fixedSchedule: Schedule | undefined

    constructor(props: Props) {
        super(props)
        if (this.props.instant.activationTime) {
            if (this.props.instant.activationTime.key === 'fixed') {
                if (Array.isArray(this.props.instant.activationTime.schedule))
                    this.fixedSchedule = this.props.instant.activationTime.schedule[ 0 ]
                else
                    this.fixedSchedule = this.props.instant.activationTime.schedule
            }
        }
        this.state = {
            fixedSchedule: this.fixedSchedule
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.instant && this.props.instant !== prevProps.instant) {
            if (this.props.instant.activationTime) {
                if (this.props.instant.activationTime.key === 'fixed') {
                    if (Array.isArray(this.props.instant.activationTime.schedule))
                        this.fixedSchedule = this.props.instant.activationTime.schedule[ 0 ]
                    else
                        this.fixedSchedule = this.props.instant.activationTime.schedule
                }
            }
            this.setState({
                fixedSchedule: this.fixedSchedule
            })
        }
    }

    render(): ReactNode {
        const error = this.props.hasError && this.isScheduleEmpty() &&
            <p className={'error'}>{i18n.management_instant.schedule_format_error}</p>

        return (
            <div className={"opening-hours-block section_pro"}>
                <span className="wizard-sub-text">{i18n.management_instant.activationTime_sub_title}</span>

                <div id="fixed" className="tabcontent">
                    <FixedScheduleContainer schedule={this.state.fixedSchedule}
                                            raiseUpdate={(schedule: Schedule | undefined) => this.updateFixedSchedule(schedule)}
                                            isSubmitted={false}/>
                </div>

                {error}
            </div>
        )
    }


    updateFixedSchedule(schedule: Schedule | undefined): void {
        this.setState({ fixedSchedule: schedule })
        if (schedule)
            this.props.raiseUpdate('activationTime', {
                key     : 'fixed',
                schedule: [ schedule ]
            })
        else
            this.props.raiseUpdate('activationTime', {
                key     : 'fixed',
                schedule: undefined
            })
    }


    isScheduleEmpty(): boolean {
        return (!this.state.fixedSchedule)
    }

}
