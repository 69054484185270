import { Instant } from './instant'
import { InstantTimeSpecification } from "./specifications/time/instant.time.specification";
import { CategoryType } from "./types/categoryType";

export class InstantHeader extends Instant {

    constructor(protected _id: string,
                protected _title: string,
                private _picture: string,
                protected _description: string,
                protected _category: CategoryType,
                protected _latitude: number,
                protected _longitude: number,
                protected _delay: string,
                protected _activationStartDate: Date,
                protected _activationEndDate: Date,
                protected _originalStartDate: Date,
                protected _businessId: string,
    ) {
        super(_id, _title, _description, _category, _latitude, _longitude, _delay, _activationStartDate, _activationEndDate, _originalStartDate, _businessId)
    }

    get picture(): string {
        return this._picture
    }

    get delay(): string {
        return this._delay
    }

    formattedTimeLeft(): string {
        return InstantTimeSpecification.formatTimeLeft(this.timeLeftInSecond())
    }
}
