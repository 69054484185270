import {
    EDIT_MUNICIPAL_SERVICE, EDIT_MUNICIPAL_SERVICE_FAILED, EDIT_MUNICIPAL_SERVICE_SUCCEEDED, EditMunicipalServiceActionTypes
} from "./actionTypes";
import { HandleMunicipalServiceState } from "../../configuration/state";

const initialState: HandleMunicipalServiceState = {
    loading: false,
    error  : undefined
}
export const editMunicipalServiceReducer = (state = initialState, action: EditMunicipalServiceActionTypes): HandleMunicipalServiceState => {
    switch (action.type) {
        case EDIT_MUNICIPAL_SERVICE:
            return {loading: true, error: undefined}
        case EDIT_MUNICIPAL_SERVICE_FAILED:
            return {loading: false, error: action.payload}
        case EDIT_MUNICIPAL_SERVICE_SUCCEEDED:
            return {loading: false, error: undefined}
        default:
            return state

    }
}
