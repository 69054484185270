import React, { PureComponent, ReactNode } from "react";

interface Props {
    text: string
}

export class FormHeader extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className="wizard-title">
                <p className="wizard-sub-text">{this.props.text}</p>
            </div>
        )
    }
}
