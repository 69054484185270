import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { TextInput } from "../inputs/text.input";
import { CategoriesSelector } from "../inputs/categorySelector/categories.selector";
import { ImageCropInput } from "../inputs/imageCrop.input";
import {
    CompanyTypeSelector
} from "../../../../../registration/adapters/primaries/registration/components/inputs/companyType.selector";
import { store } from "../../../../../App";
import { updateSingleField } from "../../../../../company/usecases/updateSingleField/actions";
import { FormValidation } from "../validation";
import { KeyOf, ValueOf } from "../../../../domain/types/app.categories";
import { BusinessRegistrationType } from "../../../../../registration/domain/type/BusinessRegistrationType";
import { Company } from "../../../../../company/domain/entity/company";

export interface FormProfileStateType {
    name: string;
    category: string;
    coverImage: File | undefined;
    profileImage: File | undefined;
    coverImageUrl: string | undefined;
    profileImageUrl: string | undefined;
    companyType: string
}

interface Props {
    profile: FormProfileStateType;
    raiseUpdate: (profile: FormProfileStateType) => void
    isSubmitted: boolean
    alreadySavedBusiness: Company | null
}

interface State {
    profile: FormProfileStateType
    nameError: boolean
    categoryError: boolean
    profileImageError: boolean
}

export class FormProfileSection extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            profile          : {
                name           : this.props.profile.name,
                category       : this.props.alreadySavedBusiness?.category ?? '',
                coverImage     : this.props.profile.coverImage,
                profileImage   : this.props.profile.profileImage,
                coverImageUrl  : this.props.profile.coverImageUrl ?? this.props.alreadySavedBusiness?.coverImageUrl,
                profileImageUrl: this.props.profile.profileImageUrl ?? this.props.alreadySavedBusiness?.profileImageUrl,
                companyType    : this.props.profile.companyType
            },
            nameError        : false,
            categoryError    : false,
            profileImageError: false
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.profile && this.props.profile !== prevProps.profile)
            this.setState({
                profile: {...this.props.profile}
            })

        if (this.props.isSubmitted && this.props.isSubmitted !== prevProps.isSubmitted)
            this.setState({
                nameError        : this.state.profile.name.length < 3,
                categoryError    : this.state.profile.category?.length === 0,
                profileImageError: !this.state.profile.profileImage
            })
    }

    render(): ReactNode {
        return (
            <div className={'section_pro'}>
                <span className="wizard-sub-text">{i18n.common.profile_description}</span>

                <div className={'px-3'}>
                    <ImageCropInput imageWidth={500}
                                    imageHeight={500}
                                    subtitle={i18n.common.subtitle_profile_picture}
                                    className={'profile-edition-input'}
                                    label={i18n.common.profile_picture}
                                    image={this.state.profile.profileImage}
                                    imageUrl={this.state.profile.profileImageUrl}
                                    isSubmitted={this.props.isSubmitted}
                                    raiseUpdate={(profileImage, profileImageUrl) => this.setState({
                                            profile: {
                                                ...this.state.profile,
                                                profileImage,
                                                profileImageUrl
                                            }
                                        }, () => {
                                            if (profileImage) {
                                                this.updateField('profileImage', profileImage)
                                            }
                                            this.props.raiseUpdate(this.state.profile)
                                        }
                                    )}/>

                    <ImageCropInput imageWidth={1200}
                                    imageHeight={600}
                                    className={'profile-edition-input'}
                                    label={i18n.common.cover_picture}
                                    subtitle={i18n.common.subtitle_cover_picture}
                                    image={this.state.profile.coverImage}
                                    imageUrl={this.state.profile.coverImageUrl}
                                    isSubmitted={this.props.isSubmitted}
                                    raiseUpdate={(coverImage, coverImageUrl) => this.setState({
                                        profile: {
                                            ...this.state.profile,
                                            coverImage,
                                            coverImageUrl
                                        }
                                    }, () => {
                                        if (coverImage) {
                                            this.updateField('coverImage', coverImage)
                                        }
                                        this.props.raiseUpdate(this.state.profile)
                                    })}/>

                    <TextInput type={'text'} className={'profile-edition-input'}
                               error={this.state.nameError}
                               label={i18n.common.business_name}
                               value={this.state.profile.name}
                               onBlur={() => this.updateField('name', this.state.profile.name)}
                               raiseUpdates={(name: string) => this.setState({
                                   profile  : {...this.state.profile, name},
                                   nameError: false
                               }, () => this.props.raiseUpdate(this.state.profile))}/>

                    {this.canChangeType() ? <CompanyTypeSelector value={this.state.profile.companyType}
                                                                 className={'profile-edition-input'}

                                                                 onChange={(companyType: string) => this.setState({
                                                                     profile: {...this.props.profile, companyType}
                                                                 }, () => {
                                                                     this.updateField('type', companyType)
                                                                     this.props.raiseUpdate(this.state.profile)
                                                                 })}
                                                                 disabled={false}/> : null}
                    <CategoriesSelector error={this.state.categoryError}
                                        className={'profile-edition-input'}
                                        type={this.props.profile.companyType}
                                        selected={this.state.profile.category}
                                        onChange={(category: string) => this.setState({
                                                profile      : {...this.state.profile, category},
                                                categoryError: false
                                            }, () => {
                                                this.updateField('category', category)
                                                this.props.raiseUpdate(this.state.profile)
                                            }
                                        )}/>

                </div>
            </div>
        )
    }

    canChangeType(): boolean {
        return this.props.profile.companyType === 'entreprise' || this.props.profile.companyType === 'commerce'
    }

    private updateField(field: KeyOf<BusinessRegistrationType>, value: ValueOf<BusinessRegistrationType>) {
        switch (field) {
            case 'name':
                if (typeof value === 'string' && FormValidation.validate('name', value)) {
                    store.dispatch(updateSingleField(field, value))
                } else {
                    this.setState({nameError: true})
                }
                break
            default:
                store.dispatch(updateSingleField(field, value))
        }
    }
}
