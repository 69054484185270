import { connect } from 'react-redux'
import { Dispatch } from "redux";
import { Order } from "../../../../../click-collect/domain/entities/order";
import { AppState } from "../../../../../redux-configuration/AppState";
import { orderSelector } from "../../../../../click-collect/usecases/loadOrder/selector";
import { LoadOrderAction } from "../../../../../click-collect/usecases/loadOrder/actionTypes";
import { loadOrder } from "../../../../../click-collect/usecases/loadOrder/actions";
import { ValidateOrderAction } from "../../../../../click-collect/usecases/validateOrder/actionTypes";
import { validateOrder } from "../../../../../click-collect/usecases/validateOrder/actions";
import { ClickCollectMessageItem } from "./clickCollectMessage.item";
import { CancelOrderAction } from "../../../../../click-collect/usecases/cancelOrder/actionTypes";
import { cancelOrder } from "../../../../../click-collect/usecases/cancelOrder/actions";
import {
    cancelOrderErrorSelector, cancelOrderLoadingSelector, cancelOrderSuccessSelector
} from "../../../../../click-collect/usecases/cancelOrder/selector";
import {
    validateOrderErrorSelector, validateOrderLoadingSelector, validateOrderSuccessSelector
} from "../../../../../click-collect/usecases/validateOrder/selector";

interface StateToPropsType {
    order: Order | null;
    cancellationError: string | undefined;
    cancellationSuccess: boolean;
    cancellationLoading: boolean;
    validationError: string | undefined;
    validationSuccess: string | undefined;
    validationLoading: boolean;
}

interface DispatchToPropsType {
    loadOrder: (orderId: string) => void;
    validateOrder: (order: Order, total: string) => void;
    cancelOrder: (orderId: string, message: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    order              : orderSelector(state),
    cancellationError  : cancelOrderErrorSelector(state),
    cancellationSuccess: cancelOrderSuccessSelector(state),
    cancellationLoading: cancelOrderLoadingSelector(state),
    validationError    : validateOrderErrorSelector(state),
    validationSuccess  : validateOrderSuccessSelector(state),
    validationLoading  : validateOrderLoadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadOrder    : (orderId: string): LoadOrderAction => dispatch(loadOrder(orderId)),
    validateOrder: (order: Order, total: string): ValidateOrderAction => dispatch(validateOrder(order, total)),
    cancelOrder  : (orderId: string, message: string): CancelOrderAction => dispatch(cancelOrder(orderId, message))
})

export const ClickCollectMessage = connect(mapStateToProps, mapDispatchToProps)(ClickCollectMessageItem)
