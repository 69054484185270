import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../configuration/i18n";
import { Company } from "../../../../domain/entity/company";

interface Props {
    company: Company | null
}

export class CompanyHeaderTitle extends PureComponent<Props> {
    render(): ReactNode {
        const website = this.props.company?.themeConfiguration?.general.website ?
            "https://" + this.props.company?.themeConfiguration?.general.website : this.props.company?.premiumSubdomain ?
                "https://" + this.props.company?.premiumSubdomain + ".vivezici.fr" : null

        return (
            <div className={'profile-edition'}>
                <div className="section-title">
                    <h2 className={'title'}>{i18n.company.my_profile}</h2>

                    <div className={'buttons-header-container'}>
                        {website && (
                            <a className={'edit-btn'} href={website} rel="noreferrer"
                               target="_blank">
                                {i18n.company.show_website}
                                <i className={'far fa-web'}/>
                            </a>
                        )}

                        <a className={'edit-btn'} href={'/editCompany'}>
                            {i18n.company.edit_profile}
                            <i className={'far fa-edit'}/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
