import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { LOAD_ECOMMERCE_ORDERS, LoadEcommerceOrdersAction } from "./actionTypes";
import { loadEcommerceOrdersFailed, loadEcommerceOrdersSucceeded } from "./actions";
import { EcommerceOrderService } from "../../domain/gateway/order.service";
import { EcommerceOrder } from "../../domain/entities/ecommerceOrder";

export const loadEcommerceOrdersEpic: Epic = (action$: ActionsObservable<LoadEcommerceOrdersAction>,
                                              store: StateObservable<AppState>,
                                              {ecommerceOrderService}: { ecommerceOrderService: EcommerceOrderService }) =>
    action$.pipe(
        ofType(LOAD_ECOMMERCE_ORDERS),
        switchMap(
            (action) => ecommerceOrderService.getOrders(action.payload)
                .pipe(
                    map((data: EcommerceOrder[]) => loadEcommerceOrdersSucceeded(data)),
                    catchError(error => of(loadEcommerceOrdersFailed(error)))
                )
        )
    )
