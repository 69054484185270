import React from "react";
import { i18n } from "../../../../configuration/i18n";

export const SuccessLogin = (): JSX.Element => {
    return (
        <div className="wizard-forms pt-4 pb-5 px-3">
            <div className="inner clearfix">
                <div className="wizard-title text-center w-100">
                    <h1 className="title mb-4">{i18n.authentication.success_login}</h1>
                    <p className="wizard-sub-text">{i18n.authentication.redirect}</p>
                </div>
            </div>
        </div>
    )
}
