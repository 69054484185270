export const SEND_CONFIRMATION_CODE = 'SEND_CONFIRMATION_CODE'
export const SEND_CONFIRMATION_CODE_FAILED = 'SEND_CONFIRMATION_CODE_FAILED'
export const SEND_CONFIRMATION_CODE_SUCCEEDED = 'SEND_CONFIRMATION_CODE_SUCCEEDED'

export interface SendConfirmationCodeAction {
    type: typeof SEND_CONFIRMATION_CODE;
    payload: {
        phoneNumber: string;
        code: string;
    };
}

interface SendConfirmationCodeFailedAction {
    type: typeof SEND_CONFIRMATION_CODE_FAILED;
    payload: string;
}

interface SendConfirmationCodeSucceededAction {
    type: typeof SEND_CONFIRMATION_CODE_SUCCEEDED;
    payload: string;
}

export type SendConfirmationCodeActionTypes = SendConfirmationCodeAction
    | SendConfirmationCodeFailedAction
    | SendConfirmationCodeSucceededAction
