export const LOAD_BROADCAST_CONVERSATION = 'LOAD_BROADCAST_CONVERSATION'
export const LOAD_BROADCAST_CONVERSATION_SUCCEEDED = 'LOAD_BROADCAST_CONVERSATION_SUCCEEDED'
export const LOAD_BROADCAST_CONVERSATION_FAILED = 'LOAD_BROADCAST_CONVERSATION_FAILED'

export interface LoadBroadcastConversationAction {
    type: typeof LOAD_BROADCAST_CONVERSATION;
    payload: string;
}

export interface LoadBroadcastConversationSucceededAction {
    type: typeof LOAD_BROADCAST_CONVERSATION_SUCCEEDED;
}

export interface LoadBroadcastConversationFailedAction {
    type: typeof LOAD_BROADCAST_CONVERSATION_FAILED;
    payload: string;
}

export type LoadBroadcastConversationActionTypes =
    LoadBroadcastConversationAction
    | LoadBroadcastConversationSucceededAction
    | LoadBroadcastConversationFailedAction
