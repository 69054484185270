export const UPLOAD_ADVANCED_IMAGE = 'UPLOAD_ADVANCED_IMAGE'
export const UPLOAD_ADVANCED_IMAGE_SUCCEEDED = 'UPLOAD_ADVANCED_IMAGE_SUCCEEDED'
export const UPLOAD_ADVANCED_IMAGE_FAILED = 'UPLOAD_ADVANCED_IMAGE_FAILED'

export interface UploadAdvancedImageAction {
    type: typeof UPLOAD_ADVANCED_IMAGE;
    payload: {
        image: File;
        companyId: string;
    };
}

export interface UploadAdvancedImageSucceededAction {
    type: typeof UPLOAD_ADVANCED_IMAGE_SUCCEEDED;
    payload: string;
}

export interface UploadAdvancedImageFailedAction {
    type: typeof UPLOAD_ADVANCED_IMAGE_FAILED;
    payload: string;
}

export type UploadAdvancedImageActionTypes =
    UploadAdvancedImageAction
    | UploadAdvancedImageSucceededAction
    | UploadAdvancedImageFailedAction
