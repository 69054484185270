import { EDIT_PRODUCT, EDIT_PRODUCT_FAILED, EDIT_PRODUCT_SUCCEEDED, EDIT_RESET_ACTION, EditProductActionTypes } from "./actionTypes";
import { ProductManagementState } from "../../configuration/state";

const initialState: ProductManagementState = {
    loading: false,
    success: undefined,
    error  : undefined,
}

export const editProductReducer = (state = initialState, action: EditProductActionTypes): ProductManagementState => {
    switch (action.type) {
        case EDIT_PRODUCT:
            return {
                loading: true,
                success: undefined,
                error  : undefined,
            }
        case EDIT_PRODUCT_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case EDIT_PRODUCT_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        case EDIT_RESET_ACTION:
            return {
                loading: false,
                success: undefined,
                error  : undefined
            }
        default:
            return state
    }
}
