import {
    EDIT_PRODUCT_STATUS, EDIT_PRODUCT_STATUS_FAILED, EDIT_PRODUCT_STATUS_SUCCEEDED,
    EditProductStatusAction, EditProductStatusFailedAction, EditProductStatusSucceededAction,
} from "./actionTypes";
import { ProductDetails } from "../../domain/entities/productDetails";

export const editProductStatus = (product: ProductDetails, companyId: string): EditProductStatusAction => ({
    type   : EDIT_PRODUCT_STATUS,
    payload: {product, companyId}
})

export const editProductStatusFailed = (error: string): EditProductStatusFailedAction => ({
    type   : EDIT_PRODUCT_STATUS_FAILED,
    payload: error
})

export const editProductStatusSucceeded = (): EditProductStatusSucceededAction => ({
    type: EDIT_PRODUCT_STATUS_SUCCEEDED
})
