import { connect } from 'react-redux';
import { AppState } from "../../../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { BusinessRegistrationForm } from "./businessRegistration.form";
import {
    registerBusinessErrorSelector, registerBusinessLoadingSelector
} from "../../../../../usecases/registerBusiness/selectors";
import { loadBusinessByCode } from "../../../../../usecases/loadBusinessByCode/actions";
import { LoadBusinessByCodeActionTypes } from "../../../../../usecases/loadBusinessByCode/actionTypes";
import {
    loadBusinessByCodeErrorSelector, loadBusinessByCodeLoadingSelector,
    loadedBusinessByCodeSelector
} from "../../../../../usecases/loadBusinessByCode/selectors";
import { Company } from "../../../../../../company/domain/entity/company";

interface StateToPropsType {
    loading: boolean;
    registrationError: string | undefined;
    loadingError: string | undefined;
    businessByCode: Company | null;
    loadingBusinessByCode: boolean;
}

interface DispatchToPropsType {
    loadBusinessByCode: (code: string, stringifiedInputs: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading          : registerBusinessLoadingSelector(state),
    registrationError: registerBusinessErrorSelector(state),
    loadingError     : loadBusinessByCodeErrorSelector(state),
    businessByCode   : loadedBusinessByCodeSelector(state),
    loadingBusinessByCode: loadBusinessByCodeLoadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadBusinessByCode: (code: string, stringifiedInputs: string): LoadBusinessByCodeActionTypes => dispatch(loadBusinessByCode(code, stringifiedInputs))
})

export const BusinessRegistrationContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessRegistrationForm)
