import React, { PureComponent, ReactNode } from "react";
import { StepContainer } from "./components/stepContainer";
import { match, RouteComponentProps } from "react-router";
import { User } from "../../../domain/entity/user";
import { BusinessInfoContainer } from "./forms/businessInfo";
import { SuccessContainer } from "./forms/success/success";
import { HeaderForm } from "./components/header.form";
import { i18n } from "../../../../configuration/i18n";
import { Creation } from "./forms/creation";
import { Company } from "../../../../company/domain/entity/company";
import { ManagementInstantHeader } from "../../../../instant-management/domain/entities/managementInstantHeader";

interface Props extends RouteComponentProps {
    localUser: User | null;
    registrationFormStep: number | undefined,
    business: Company | null;
    instants: ManagementInstantHeader[] | null;
    match: match<{
        type?: string
    }>
    loading: boolean;
}

interface State {
    type: string;
}

export class RegistrationContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            type: this.props.match.params.type ? this.props.match.params.type : 'commerce'
        }
    }

    componentDidMount(): void {
        if (!this.haveTheAppropriateType(this.state.type)) {
            window.location.href = '/inscription/commerce'
        }
    }

    render(): ReactNode {
        if (this.props.loading) {
            return (
                <div className={'show-loader'}>
                    <div className={'loader'}/>
                </div>
            )
        }
        return (
            <div className="wrapper wizard d-flex clearfix multisteps-form position-relative">
                <StepContainer currentStep={this.props.registrationFormStep || 1}
                               type={this.getLabelType(this.state.type)}/>

                <form className="multisteps-form__form w-75 order-1" id={'wizard'}>
                    <HeaderForm type={this.state.type} step={this.props.registrationFormStep || 1}/>

                    <div className="form-area position-relative">
                        <div className="multisteps-form__panel js-active" data-animation="slideHorz">
                            {this.renderFormStep()}
                        </div>
                    </div>

                    <div className={'footer-form'}>
                        <a href="https://meetings-eu1.hubspot.com/equipe-vivez-ici/support-vivez-ici" rel="noreferrer"
                           target="_blank">{i18n.registration.footer_text}</a>
                    </div>
                </form>
            </div>
        )
    }

    renderFormStep(): JSX.Element {
        switch (this.props.registrationFormStep) {
            case 1:
                return <Creation updateType={(type: string) => this.setState({type})}
                                 defaultType={this.props.match.params.type}/>
            case 2:
                return <BusinessInfoContainer type={this.state.type}/>
            case 3:
                return <SuccessContainer type={this.state.type}
                                         business={this.props.business}
                                         instants={this.props.instants}/>
            default:
                return <Creation updateType={(type: string) => this.setState({type})}
                                 defaultType={this.props.match.params.type}/>
        }
    }

    haveTheAppropriateType(type: string): boolean {
        return type === 'commerce' || type === 'association' || type === 'entreprise' || type === 'mairie'
    }

    getLabelType(type: string | undefined): string {
        if (type === 'commerce')
            return i18n.registration.commerce
        else if (type === 'association')
            return i18n.registration.association
        else if (type === 'entreprise')
            return i18n.registration.company
        else if (type === 'mairie')
            return i18n.registration.mairie
        else
            return i18n.registration.commerce
    }
}
