import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TextInput } from "../inputs/text.input";
import draftToHtml from "draftjs-to-html";
import { FormValidation } from "../validation";
import htmlToDraft from "html-to-draftjs";
import { CheckboxInput } from "../inputs/checkbox.input";
import { emojiList } from "../emoji";
import { PhoneInputField } from "../inputs/phoneInput";
import { store } from "../../../../../App";
import { updateSingleField } from "../../../../../company/usecases/updateSingleField/actions";
import { BusinessRegistrationType } from "../../../../../registration/domain/type/BusinessRegistrationType";
import { ValueOf } from "../../../../domain/types/app.categories";

export interface FormContactStateType {
    website: string;
    email: string;
    phoneNumber: string;
    description: string;
    noWebsite: boolean;
}

interface Props {
    contact: FormContactStateType;
    raiseUpdate: (contact: FormContactStateType) => void
    isSubmitted: boolean
    type: string
}

interface State {
    contact: FormContactStateType;
    editorState: EditorState | undefined;
    websiteError: boolean;
    emailError: boolean;
    descriptionError: boolean;
    phoneNumberError: boolean;
}

export class FormContactSection extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            contact: {
                website    : this.props.contact.website,
                email      : this.props.contact.email,
                phoneNumber: this.props.contact.phoneNumber,
                description: this.props.contact.description,
                noWebsite  : this.props.contact.website === '',
            },

            editorState     : undefined,
            websiteError    : false,
            emailError      : false,
            descriptionError: false,
            phoneNumberError: false
        }
    }

    private htmlToDraft(): EditorState {
        if (this.props.contact.description && this.props.contact.description.length > 0) {
            const html = this.props.contact.description;
            const blocksFromHTML = htmlToDraft(html);
            const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            return EditorState.createWithContent(content)
        } else
            return EditorState.createEmpty()
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.contact && this.props.contact !== prevProps.contact) {
            if (!this.state.editorState) {
                this.setState({
                    contact    : this.props.contact,
                    editorState: this.htmlToDraft()
                })
            } else {
                this.setState({
                    contact: this.props.contact
                })
            }
        }

        if (this.props.isSubmitted && this.props.isSubmitted !== prevProps.isSubmitted)
            this.setState({
                websiteError    : !FormValidation.validateWebsite(this.state.contact.website) && !this.state.contact.noWebsite,
                emailError      : !FormValidation.validateEmail(this.state.contact.email),
                descriptionError: !this.state.editorState || this.state.editorState.getCurrentContent().getPlainText().length < 6,
                phoneNumberError: !FormValidation.validatePhoneNumberFix(this.state.contact.phoneNumber)
            })
    }

    render(): ReactNode {
        const className = this.state.descriptionError ? 'error' : undefined
        return (
            <div className={'section_pro'}>
                <span className="wizard-sub-text">{i18n.common.contact_description(this.props.type)}</span>

                <div className={'px-3'}>
                    <div className="profile-edition-input wizard-form-input">
                        <label style={{alignSelf: 'flex-start'}}>{i18n.common.description}</label>
                        <Editor wrapperClassName={className}
                                editorClassName="editor-custom-style"
                                stripPastedStyles={true}
                                toolbar={{
                                    options: ['inline', 'link', 'emoji'],
                                    inline : {
                                        options: ['bold', 'italic'],
                                    },
                                    link   : {
                                        popupClassName       : 'link-popup',
                                        showOpenOptionOnHover: false
                                    },
                                    emoji  : {
                                        emojis: emojiList,
                                    }
                                }}
                                localization={{locale: 'fr'}}
                                editorState={this.state.editorState}
                                onBlur={() => this.updateField('description', this.state.contact.description)}
                                onEditorStateChange={(editorState: EditorState) => this.onEditorStateChange(editorState)}/>
                    </div>

                    <TextInput type={'text'}
                               className={'profile-edition-input'}
                               error={this.state.websiteError}
                               label={i18n.common.website}
                               value={this.state.contact.website}
                               onBlur={() => this.updateField('website', this.state.contact.website)}
                               raiseUpdates={(website: string) => this.onWebSiteChange(website)}>

                    </TextInput>

                    <CheckboxInput label={i18n.common.dont_have_website}
                                   className={'profile-edition-input wizard-form-input checkbox_website w-auto'}
                                   raiseUpdates={(value: boolean) => this.onCheckWebsite(value)}
                                   checked={this.state.contact.noWebsite}/>

                    <TextInput type={'text'}
                               className={'profile-edition-input'}
                               error={this.state.emailError}
                               label={i18n.common.email}
                               onBlur={() => this.updateField('email', this.state.contact.email)}
                               value={this.state.contact.email}
                               raiseUpdates={(email: string) => this.setState({
                                   contact   : {...this.state.contact, email},
                                   emailError: false
                               }, () => this.props.raiseUpdate(this.state.contact))}/>

                    <PhoneInputField className={'profile-edition-input'}
                                     error={this.state.phoneNumberError}
                                     label={i18n.common.phoneNumber}
                                     value={this.state.contact.phoneNumber}
                                     onBlur={() => this.updateField('phoneNumber', this.state.contact.phoneNumber)}
                                     raiseUpdates={(phoneNumber: string) => this.setState({
                                         contact         : {...this.state.contact, phoneNumber},
                                         phoneNumberError: false
                                     }, () => this.props.raiseUpdate(this.state.contact))}/>
                </div>
            </div>
        )
    }

    onEditorStateChange(editorState: EditorState): void {
        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.setState({
            contact         : {...this.state.contact, description},
            editorState,
            descriptionError: false
        }, () => {
            this.props.raiseUpdate(this.state.contact)
        })
    }

    onCheckWebsite(value: boolean): void {
        this.setState({
            contact     : {...this.state.contact, noWebsite: value, website: value ? '' : this.props.contact.website},
            websiteError: false
        }, (): void => {
            this.updateField('website', value ? '' : this.props.contact.website)
            this.props.raiseUpdate(this.state.contact)
        })
    }

    onWebSiteChange(website: string): void {
        const regexHttp = FormValidation.validateWebsite(website)
        const regexWww = FormValidation.validateWww(website)
        const regexDomainName = FormValidation.validateDomainName(website)
        if (regexHttp)
            this.setState({
                contact     : {...this.state.contact, website, noWebsite: false},
                websiteError: false
            }, (): void => this.props.raiseUpdate(this.state.contact))
        else if (regexWww || regexDomainName)
            this.setState({
                contact     : {...this.state.contact, website: 'http://' + website, noWebsite: false},
                websiteError: false
            }, (): void => this.props.raiseUpdate(this.state.contact))
        else
            this.setState({
                contact     : {...this.state.contact, website, noWebsite: false},
                websiteError: false
            }, (): void => this.props.raiseUpdate(this.state.contact))
    }

    private updateField(field: keyof BusinessRegistrationType, value: ValueOf<BusinessRegistrationType>) {

        switch (field) {
            case 'description': {
                if (typeof value === "string" && FormValidation.validateDescriptionClickCollect(value, true)) {
                    store.dispatch(updateSingleField('description', value))
                } else {
                    this.setState({descriptionError: true})
                }
                break
            }
            case 'website': {
                if (typeof value === "string" && FormValidation.validateWebsite(value)) {
                    store.dispatch(updateSingleField('website', value))
                } else {
                    this.setState({websiteError: true})
                }
                break
            }
            case 'phoneNumber': {
                if (typeof value === "string" && FormValidation.validatePhoneNumberFix(value)) {
                    store.dispatch(updateSingleField('phoneNumber', value))
                } else {
                    this.setState({phoneNumberError: true})
                }
                break
            }
            case 'email': {
                if (typeof value === "string" && FormValidation.validateEmail(value)) {
                    store.dispatch(updateSingleField('email', value))
                } else {
                    this.setState({emailError: true})
                }
                break
            }
            default:
                store.dispatch(updateSingleField(field, value))
        }
    }
}
