import { ThemeConfigurationBlockType, ThemeEcommerceType, ThemeFoodMenuType } from "./types";

export class ThemeFormValidation {

    static isValidBlock(block: ThemeConfigurationBlockType): boolean {
        return (block.isActive && block.subTitle?.length !== 0 && block.backgroundColor?.length !== 0) || !block.isActive
    }

    static isValidGalleryImages(gallery: ThemeConfigurationBlockType): boolean {
        return (gallery.isActive && gallery.images !== undefined && gallery.images.length > 5) || !gallery.isActive
    }

    static isValidReviewBlock(block: ThemeConfigurationBlockType): boolean {
        return (block.isActive && block.subTitle?.length !== 0 && block.backgroundColor?.length !== 0 && block.placeId?.length !== 0) || !block.isActive
    }

    static isValidFoodMenuBlock(block: ThemeFoodMenuType): boolean {
        return (block.isActive && block.title.length !== 0 && block.subTitle.length !== 0 && block.headerTitle.length !== 0 && block.backgroundColor.length !== 0) || !block.isActive
    }

    static isValidFoodMenuDocuments(block: ThemeFoodMenuType): boolean {
        return (block.isActive && block.tabs.length > 0) || !block.isActive
    }

    static isValidEcommerceBlock(block: ThemeEcommerceType): boolean {
        return (block.isActive && block.title.length !== 0 && block.subTitle.length !== 0 && block.headerTitle.length !== 0 && block.backgroundImage.length !== 0) || !block.isActive
    }
}
