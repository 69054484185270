import { RNA } from "../../../../domain/entity/business/RNA";
import { Siret } from "../../../../domain/entity/business/Siret";
import { Company } from "../../../../../company/domain/entity/company";
import { CompanyBuilder } from "../../../../../company/domain/builder/company.builder";

export class BusinessMapper {
    static mapLocalBusinessByCode(data: string): Company {
        const localBusiness = JSON.parse(data)
        return new CompanyBuilder()
            .withId(localBusiness._id)
            .withName(localBusiness._name)
            .withType(localBusiness._type)
            .withAdministrativeNumber(localBusiness._administrativeNumber._number.length === RNA.length ? new RNA(localBusiness._administrativeNumber._number) : new Siret(localBusiness._administrativeNumber._number))
            .withAddress(localBusiness._address._address)
            .withCity(localBusiness._address._city)
            .withZipCode(localBusiness._address._zipCode)
            .build()
    }

    static mapLocalBusiness(data: string): Company {
        const localBusiness = JSON.parse(data)
        const frontFile = new File([localBusiness._cinFront.url], localBusiness._cinFront.name, { type: "image/jpeg" })
        const backFile = new File([localBusiness._cinBack.url], localBusiness._cinBack.name, { type: "image/jpeg" })
        const business: Company = new CompanyBuilder()
            .withName(localBusiness._name)
            .withCategory(localBusiness._category)
            .withProfileImage(localBusiness._profileImage)
            .withCoverImage(localBusiness._coverImage)
            .withAddress(localBusiness._address._address)
            .withCity(localBusiness._address._city)
            .withZipCode(localBusiness._address._zipCode)
            .withLatitude(localBusiness._address._latitude)
            .withLongitude(localBusiness._address._longitude)
            .withOpeningHours(localBusiness._openingHours)
            .withDescription(localBusiness._contacts._description)
            .withEmail(localBusiness._contacts._email)
            .withPhoneNumber(localBusiness._contacts._phoneNumber)
            .withWebsite(localBusiness._contacts._website)
            .withCinBack(backFile)
            .withCinFront(frontFile)
            .build()
        return business
    }
}
