import { POST_INSTANT_ON_FACEBOOK, POST_INSTANT_ON_FACEBOOK_FAILED, POST_INSTANT_ON_FACEBOOK_SUCCEEDED, PostInstantOnFacebookActionTypes } from "./actionTypes";
import { ShareInstantState } from "../../configuration/state";

const initialState: ShareInstantState = {
    loading: false,
    error: undefined
}
export const postInstantOnFacebookReducer = (state = initialState, action: PostInstantOnFacebookActionTypes): ShareInstantState =>{
    switch (action.type){
        case POST_INSTANT_ON_FACEBOOK:
            return {loading: true, error: undefined}
        case POST_INSTANT_ON_FACEBOOK_SUCCEEDED:
            return {loading: false, error: undefined}
        case POST_INSTANT_ON_FACEBOOK_FAILED:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}
