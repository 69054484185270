import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { SelectOptionType } from "../../../../../../common/domain/types/selectOption.type";
import Select from 'react-select';

interface Props {
    value: string
    disabled: boolean
    onChange: (value: string) => void
    className?: string
}

export class BusinessTypeSelector extends PureComponent<Props> {
    render(): ReactNode {
        const classContainer= this.props.className ? this.props.className+' wizard-form-input select-option-area': 'wizard-form-input select-option-area'
        return (
            <div className={classContainer}>
                <label>{i18n.registration.type}</label>
                <Select
                                    className={'select-category'}
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    isDisabled={this.props.disabled}
                                    value={this.getValueSelected(this.props.value)}
                                    options={this.typeOptionList()}
                                    onChange={selected => {
                                        if (selected !== null)
                                            this.props.onChange(selected.value)
                                    }}
                                />
            </div>
        )
    }

      typeOptionList(): SelectOptionType[] {
           return[{label: i18n.registration.commerce, value: 'commerce'},
           {label: i18n.registration.company, value: 'entreprise'},
           {label: i18n.registration.association, value: 'association'},
           {label: i18n.registration.mairie, value: 'mairie'},
           ]
           }

        getValueSelected(value: string): SelectOptionType | undefined {
            return this.typeOptionList().find(item => item.value === value)
        }
}
