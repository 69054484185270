import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { SIGN_UP, SignUpActionType } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { signUpFailed, signUpSucceeded } from "./actions";
import { RegistrationService } from "../../domain/gateway/registration.service";
import { of } from "rxjs";
import { RegistrationRepository } from "../../domain/gateway/registration.repository";

export const signUpEpic: Epic = (action$: ActionsObservable<SignUpActionType>,
                                 store: StateObservable<AppState>,
                                 {registrationService, registrationRepository}:
                                     { registrationService: RegistrationService, registrationRepository: RegistrationRepository }) =>
    action$.pipe(
        ofType(SIGN_UP),
        switchMap(action => registrationService.signUp(action.payload.phoneNumber)
            .pipe(
                mergeMap(() => registrationRepository.saveUser(action.payload)
                    .pipe(
                        map(() => signUpSucceeded()),
                        catchError(error => of(signUpFailed(error)))
                    )
                ),
                catchError(error => of(signUpFailed(error)))
            )
        )
    )
