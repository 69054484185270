import { ChatMessage } from '../entities/chatMessage';
import { ChatUser } from '../entities/chatUser';

export class ChatMessageBuilder {
    protected _id: number
    protected _content: string
    protected _createdAt: Date
    protected _user: ChatUser
    protected _image: string | undefined

    withId(value: number): ChatMessageBuilder {
        this._id = value
        return this
    }

    withContent(value: string): ChatMessageBuilder {
        this._content = value
        return this
    }

    withCreatedAt(value: Date): ChatMessageBuilder {
        this._createdAt = value
        return this
    }

    withUser(value: ChatUser): ChatMessageBuilder {
        this._user = value
        return this
    }

    withImage(value: string | undefined): ChatMessageBuilder {
        this._image = value
        return this
    }

    build(): ChatMessage {
        return new ChatMessage(this._id, this._content, this._createdAt, this._user, this._image)
    }
}
