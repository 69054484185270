import React, { PureComponent, ReactNode } from "react";
import { Theme } from "../../../configuration/theme/app.theme";
import GoogleMapReact from 'google-map-react';

interface Props {
    lat: number;
    lng: number;
}

export class MapField extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div style={styles.container}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCRg2QG-zyoZx6vhVfrxuZlTD31PFrIrkg' }}
                    defaultCenter={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                    center={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                    defaultZoom={16}>
                    <Marker lat={this.props.lat}
                            lng={this.props.lng}
                            color={Theme.supernova}
                    />
                </GoogleMapReact>
            </div>
        )
    }
}

const Marker = (props: { lat: number, lng: number, color: string }) => {
    return (
        <div>
            <div className="pin bounce"
                 style={{ backgroundColor: props.color, cursor: 'pointer' }}/>
            <div className="pulse"/>
        </div>
    );
};

const styles = {
    container:
        {
            width: '100%',
            height: '100%',
            minHeight: 280,
            borderWidth: 1,
            borderColor: '#DDD',
            borderStyle: 'solid',
            borderRadius   : 4,
            overflow: 'hidden'
        }
}
