import { Order } from "../entities/order";

export class OrderBuilder {
    protected _id: string
    protected _time: string
    protected _content: string
    protected _coupon: boolean
    protected _textCoupon: string
    protected _minOrder: string

    withId(value: string): OrderBuilder {
        this._id = value
        return this
    }

    withTime(value: string): OrderBuilder {
        this._time = value
        return this
    }

    withContent(value: string): OrderBuilder {
        this._content = value
        return this
    }

    withCoupon(value: boolean): OrderBuilder {
        this._coupon = value
        return this
    }

    withTextCoupon(value: string): OrderBuilder {
        this._textCoupon = value
        return this
    }

    withMinOrder(value: string): OrderBuilder {
        this._minOrder = value
        return this
    }

    build(): Order {
        return new Order(
            this._id,
            this._time,
            this._content,
            this._coupon,
            this._textCoupon,
            this._minOrder
        )
    }
}
