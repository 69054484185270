import {
    DELETE_INSTANT,
    DELETE_INSTANT_FAILED,
    DELETE_INSTANT_SUCCEEDED,
    DeleteInstantAction, DeleteInstantFailedAction, DeleteInstantSucceededAction
} from "./delateInstant.types";

export const deleteInstant = (instantId: string, companyId: string): DeleteInstantAction => ({
    type   : DELETE_INSTANT,
    payload: { instantId, companyId: companyId }
})

export const deleteInstantFailed = (error: string): DeleteInstantFailedAction => ({
    type   : DELETE_INSTANT_FAILED,
    payload: error
})

export const deleteInstantSucceeded = (): DeleteInstantSucceededAction => ({
    type: DELETE_INSTANT_SUCCEEDED
})
