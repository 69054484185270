import { Company } from "../../domain/entity/company";

export const LOAD_COMPANY_DETAILS = 'LOAD_COMPANY_DETAILS'
export const LOAD_OWNED_COMPANY = 'LOAD_OWNED_COMPANY'
export const LOAD_COMPANY_DETAILS_SUCCEEDED = 'LOAD_COMPANY_DETAILS_SUCCEEDED'
export const LOAD_COMPANY_DETAILS_FAILED = 'LOAD_COMPANY_DETAILS_FAILED'

export interface LoadCompanyDetailsAction {
    type: typeof LOAD_COMPANY_DETAILS;
    payload: string;
}

export interface LoadOwnedCompanyAction {
    type: typeof LOAD_OWNED_COMPANY;
}

export interface LoadCompanyDetailsSucceededAction {
    type: typeof LOAD_COMPANY_DETAILS_SUCCEEDED;
    payload: Company;
}

export interface LoadCompanyDetailsFailedAction {
    type: typeof LOAD_COMPANY_DETAILS_FAILED;
    payload: string;
}

export type LoadCompanyDetailsActionType = LoadCompanyDetailsAction

export type LoadCompanyDetailsActionTypes =
    LoadCompanyDetailsAction
    | LoadCompanyDetailsSucceededAction
    | LoadCompanyDetailsFailedAction
    | LoadOwnedCompanyAction
