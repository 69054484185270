import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../configuration/i18n";
import { TextInput } from "../../../../common/adapters/primaries/form/inputs/text.input";
import { ServiceIcon } from "../../../domain/entities/serviceIcon";
import { ChoicePicker } from '../../../../common/adapters/primaries/form/inputs/choice.picker';
import { SelectOptionType } from "../../../../common/domain/types/selectOption.type";
import { HtmlEditor } from "../../../../common/adapters/primaries/form/inputs/htmlEditor";
import { SelectorIconService } from "./components/selectorIconService";
import { MunicipalServiceBuilder } from "../../../domain/builder/municipalService.builder";
import { MunicipalService } from "../../../domain/entities/municipalService";

interface Props {
    visible: boolean
    onClose: () => void
    addService: (service: MunicipalService, cityId: string) => void;
    loading: boolean;
    error: string | undefined;
    cityId: string | undefined;
    icons: ServiceIcon[] | undefined;
    service: MunicipalService | undefined
    editService: (service: MunicipalService) => void;
}

interface State {
    label: string
    type: string
    icon: ServiceIcon | undefined
    content: string
    link: string
    linkError: boolean
    labelError: boolean
    iconError: boolean
    contentError: boolean
    errorMessage: string
}

export class FormServiceContainer extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            label       : '',
            type        : 'LINK',
            icon        : undefined,
            content     : '',
            link        : '',
            linkError   : false,
            labelError  : false,
            iconError   : false,
            contentError: false,
            errorMessage: ''
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.service !== this.props.service && this.props.service) {
            this.setState({
                label  : this.props.service.label,
                type   : this.props.service.type,
                content: this.props.service.type !== 'LINK' ? this.props.service.content : '',
                link   : this.props.service.type === 'LINK' ? this.props.service.content : '',
                icon   : this.props.service.icon
            })
        }
        if (prevProps.loading && !this.props.loading && this.props.error === undefined)
            this.closeForm()
        else if (prevProps.error !== this.props.error && this.props.error)
            this.setState({errorMessage: i18n.municipality.error_msg})
    }

    render(): ReactNode {
        const spinner = this.props.loading ? <div className={'show-loader'}>
            <div className={'loader'}/>
        </div> : null
        const title = this.props.service !== undefined ? i18n.municipality.edit_service : i18n.municipality.add_service
        if (this.props.visible)
            return (
                <div className="modal default-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="header">
                                <p className={'title'}>{title}</p>
                                <span className={'close-button'} onClick={() => this.closeForm()}/>
                            </div>

                            <div className="body create-service">
                                {spinner}

                                <div className="wizard-form-field">
                                    <TextInput type={'text'}
                                               error={this.state.labelError}
                                               label={i18n.municipality.service_label}
                                               value={this.state.label}
                                               raiseUpdates={(label: string) => this.setState({
                                                   label,
                                                   labelError: false
                                               })}/>

                                    <SelectorIconService onChange={(icon: ServiceIcon) => this.setState({icon, iconError: false})}
                                                         selected={this.state.icon}
                                                         icons={this.props.icons}
                                                         error={this.state.iconError}
                                                         label={i18n.municipality.service_icon}/>

                                    <ChoicePicker label={i18n.municipality.service_type}
                                                  selected={this.state.type}
                                                  onChange={(type: string) => this.setState({type})}
                                                  data={this.getTypeChoiceList()}/>

                                    {this.state.type === 'LINK' ?
                                        <TextInput type={'text'}
                                                   error={this.state.linkError}
                                                   label={i18n.municipality.service_link}
                                                   value={this.state.link}
                                                   raiseUpdates={(link: string) => this.setState({
                                                       link,
                                                       linkError: false
                                                   })}/> :
                                        <HtmlEditor value={this.state.content}
                                                    raiseUpdates={(textValue: string): void => {
                                                        this.setState({content: textValue, contentError: false})
                                                    }}
                                                    error={this.state.contentError}/>}
                                </div>

                                <p className={'error'}>{this.state.errorMessage}</p>

                                <div className={'text-center pt-3'}>
                                    <button className={'default-btn'} onClick={(): void => this.submitService()}>
                                        {i18n.municipality.publish}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return null
    }

    closeForm(): void {
        this.setState({label: '', type: 'LINK', icon: undefined, content: '', link: '', errorMessage: ''}, () => this.props.onClose())
        this.props.onClose()
    }

    submitService(): void {
        this.setState({
            labelError  : this.state.label.length < 3,
            iconError   : this.state.icon === undefined,
            linkError   : this.state.type === 'LINK' && !this.validateLink(this.state.link),
            contentError: this.state.type !== 'LINK' && this.state.content.length === 0
        }, () => {
            if (this.validForm() && this.state.icon) {
                const content = this.state.type === 'LINK' ? this.state.link : this.state.content
                const service: MunicipalServiceBuilder = new MunicipalServiceBuilder()
                    .withLabel(this.state.label)
                    .withType(this.state.type)
                    .withIcon(this.state.icon)
                    .withContent(content)
                if (this.props.service) {
                    this.props.editService(service.withId(this.props.service.id)
                        .withRank(this.props.service.rank)
                        .build())
                } else if (this.props.cityId) {
                    this.props.addService(service.build(), this.props.cityId)
                }
            } else
                this.setState({errorMessage: i18n.municipality.error_msg})
        })
    }

    validForm(): boolean {
        return !this.state.labelError && !this.state.iconError && !this.state.linkError && !this.state.contentError
    }

    getTypeChoiceList(): SelectOptionType[] {
        return [{label: i18n.municipality.link_type, value: 'LINK'}, {label: i18n.municipality.page_type, value: 'INTERNAL_PAGE'}]
    }

    validateLink(website: string): boolean {
        const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        return regex.test(website)
    }
}
