export class BroadcastMessage {
    constructor(
        protected _id: number,
        protected _content: string,
        protected _sentTo: string,
        protected _createdAt: Date,
        private _image: string | undefined = undefined
    ) {}

    get id(): number {
        return this._id;
    }

    get content(): string {
        return this._content;
    }

    get sentTo(): string {
        return this._sentTo;
    }

    get createdAt(): Date {
        return this._createdAt;
    }
    get image(): string | undefined {
        return  this._image
    }
}
