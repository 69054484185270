import React from "react";
import { CompanyOpeningDays } from "./company.specifications";

interface Props {
    openingHour: CompanyOpeningDays
}

export const CompanyOpeningDay = (props: Props): JSX.Element => {
    return (
        <div className="services-content mt-3">
            <h4 className="title">{props.openingHour.day}</h4>
            <div>
                {props.openingHour.hour.map((hour, index) => <p key={index}>{hour}</p>)}
            </div>
        </div>
    )
}
