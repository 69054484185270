import { AppState } from "../../../redux-configuration/AppState";
import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { LISTING_MUNICIPAL_SERVICES, ListingMunicipalServicesAction } from "./actionTypes";
import { MunicipalService } from "../../domain/entities/municipalService";
import { listingMunicipalServicesFailed, listingMunicipalServicesSucceeded, listingServiceIconsSucceeded } from "./actions";
import { MunicipalityService } from "../../domain/gateway/municipality.service";
import { ServiceIcon } from "../../domain/entities/serviceIcon";

export const listingMunicipalServicesEpic: Epic = (action$: ActionsObservable<ListingMunicipalServicesAction>,
                                                   store: StateObservable<AppState>,
                                                   {municipalityService}: { municipalityService: MunicipalityService }) =>
    action$.pipe(
        ofType(LISTING_MUNICIPAL_SERVICES),
        switchMap(action => municipalityService.loadServiceIcons()
            .pipe(
                mergeMap((icons: ServiceIcon[]) => municipalityService.loadMunicipalServices(action.payload)
                    .pipe(
                        concatMap((services: MunicipalService[]) => {
                            services.sort((a, b) => a.rank - b.rank)
                            return [
                                listingMunicipalServicesSucceeded(services),
                                listingServiceIconsSucceeded(icons)
                            ]
                        }),
                        catchError(error => of(listingMunicipalServicesFailed(error)))
                    )
                ), catchError(error => of(listingMunicipalServicesFailed(error)))
            )
        )
    )
