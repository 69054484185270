import React, { PureComponent, ReactNode } from "react";
import { Line } from 'react-chartjs-2';
import { i18n } from "../../../../../configuration/i18n";
import { Theme } from "../../../../../common/configuration/theme/app.theme";
import { ApplicationContext } from "../../../../../configuration/application.context";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    cumulativeDataSets: Map<string, number>;
}

export class CompanyChart extends PureComponent<Props> {

    render(): ReactNode {
        const axisX: string[] = []
        const axisY: number[] = []

        this.props.cumulativeDataSets.forEach((value, key) => {
            this.pushAllDaysBetween(axisX, axisY, key)
            axisX.push(key)
            axisY.push(value)
        })

        const data = {
            labels  : axisX,
            datasets: [
                {
                    label          : i18n.stats.company_number,
                    data           : axisY,
                    fill           : false,
                    backgroundColor: Theme.blueMinsk,
                    borderWidth    : 0
                }
            ]
        };

        return <Line type={'line'} data={data}/>
    }

    pushAllDaysBetween(axisX: string[], axisY: number[], nextDate: string): void {
        if (axisX.length !== 0) {
            const days = this.getDiffDays(axisX[axisX.length - 1], nextDate)
            if (days > 1) {
                for (let i = 1; i < days; i++) {
                    axisX.push(moment(axisX[axisX.length - 1], 'D MMM YYYY').add(1, 'days').format('D MMM YYYY'))
                    axisY.push(axisY[axisY.length - 1])
                }
            }
        }
    }

    getDiffDays(startDate: string, endDate: string): number {
        const start = moment(startDate, 'D MMMM YYYY')
        const end = moment(endDate, 'D MMMM YYYY')
        return end.diff(start, 'days')
    }
}
