import { RegistrationService } from "../domain/gateway/registration.service";
import { InMemoryRegistrationService } from "../adapters/secondaries/inMemory/inMemoryRegistration.service";
import { ApiRegistrationService } from "../adapters/secondaries/real/apiRegistration.service";
import { RegistrationRepository } from "../domain/gateway/registration.repository";
import { LocalStorageRegistrationRepository } from "../adapters/secondaries/real/localStorageRegistration.repository";
import { InMemoryRegistrationRepository } from "../adapters/secondaries/inMemory/InMemoryRegistration.repository";
import { ApiCityService } from "../adapters/secondaries/real/apiCity.service";
import { CityService } from "../domain/gateway/city.service";
import { InMemoryCityService } from "../adapters/secondaries/inMemory/inMemoryCity.service";

export class RegistrationDependenciesFactory {
    static getRegistrationService(): RegistrationService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiRegistrationService()
            default:
                return new InMemoryRegistrationService()
        }
    }

    static getRegistrationRepository(): RegistrationRepository {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new LocalStorageRegistrationRepository()
            default:
                return new InMemoryRegistrationRepository()
        }
    }

    static getCityService(): CityService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiCityService()
            default:
                return new InMemoryCityService()
        }
    }
}
