import { ClickCollectService } from "../domain/gateway/clickCollectService";
import { ApiClickCollectService } from "../adapters/secondaries/real/apiClickCollect.service";
import { InMemoryClickCollectService } from "../adapters/secondaries/inMemory/inMemoryClickCollect.service";

export class ClickCollectDependenciesFactory {
    static getClickCollectService(): ClickCollectService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiClickCollectService()
            default:
                return new InMemoryClickCollectService()
        }
    }
}
