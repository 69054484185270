import { combineEpics } from "redux-observable";
import { loadInstantsCompanyEpic } from "../usecases/loadInstantsCompany/loadInstantsCompany.epics";
import { loadManagementInstantEpic } from "../usecases/loadManagementInstantById/loadManagementInstant.epic";
import { editInstantEpic } from "../usecases/editInstant/editinstant.epic";
import { updateInstantStatusEpic } from "../usecases/updateStatus/updateInstantStatus.epic";
import { deleteInstantEpic } from "../usecases/delete/deleteInstant.epic";
import { createInstantEpic } from "../usecases/createInstant/createInstant.epic";
import { loadPreviewInstantsCompanyEpic } from "../usecases/loadPreviewIstantsCompany/loadPreviewInstantsCompany.epic";
import { postInstantOnFacebookEpic } from "../usecases/postOnFacebook/epic";
import { postInstantOnInstagramEpic } from "../usecases/postOnInstagram/epic";

export const managementInstantRootEpics = combineEpics(
    loadInstantsCompanyEpic,
    loadManagementInstantEpic,
    editInstantEpic,
    updateInstantStatusEpic,
    deleteInstantEpic,
    createInstantEpic,
    loadPreviewInstantsCompanyEpic,
    postInstantOnFacebookEpic,
    postInstantOnInstagramEpic
)
