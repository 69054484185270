import {
    DELETE_INSTANT,
    DELETE_INSTANT_FAILED,
    DELETE_INSTANT_SUCCEEDED,
    DeleteInstantActionTypes
} from "./delateInstant.types";
import { DeleteInstantState } from "../../configuration/state";


const initialState: DeleteInstantState = {
    isLoading: false,
    success  : undefined,
    error    : undefined
}

export const deleteInstantReducer = (state = initialState, action: DeleteInstantActionTypes): DeleteInstantState => {
    switch (action.type) {
        case DELETE_INSTANT:
            return {
                ...state, isLoading: true,
            }
        case DELETE_INSTANT_FAILED:
            return {
                ...state,
                isLoading: false,
                error    : action.payload
            }
        case DELETE_INSTANT_SUCCEEDED:
            return {
                isLoading: false,
                success  : true,
                error    : undefined
            }
        default:
            return state
    }
}
