import React, { PureComponent, ReactNode } from 'react';
import { i18n } from "../../../../../configuration/i18n";
import { ManagementInstantHeader } from "../../../../domain/entities/managementInstantHeader";
import { InstantItem } from "./instantItem/instantItem";
import { InstantManagementSpecifications } from "../../specifications";
import { Company } from "../../../../../company/domain/entity/company";

interface Props {
    instants: ManagementInstantHeader[];
    deleteInstant: (instantId: string, companyId: string) => void;
    updateInstantStatus: (instantId: string, status: boolean) => void;
    addInstant: () => void;
    type: 'event' | 'service' | 'specialOffer'
    company: Company | null;
    editInstantPath: string;
}

interface State {
    searchedValue: string;
    instants: ManagementInstantHeader[];
    isMembershipMsgVisible: boolean;
}

export class InstantsListingTabContent extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            searchedValue         : '',
            isMembershipMsgVisible: false,
            instants              : []
        }
    }

    componentDidMount(): void {
        if (this.props.instants)
            this.setState({instants: this.props.instants})
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.instants && this.props.instants !== prevProps.instants)
            this.setState({instants: this.props.instants, isMembershipMsgVisible: false})
    }

    render(): ReactNode {
        return (
            <div className={'tabContent'}>
                <div className={'profile-section'}>
                    <div className={'empty-list'}>
                        {this.renderDescriptionFooter()}
                    </div>

                    {this.state.isMembershipMsgVisible &&
                        <p className={'membership-info-text'}>{i18n.management_instant.instant_membership_error}</p>}

                    <div className={'row'}>
                        <div className={'col-sm-6'}>
                            <div className={'instant-search-input'}>
                                <i className="fas fa-search"/>
                                <input type="text"
                                       placeholder={i18n.management_instant.search}
                                       value={this.state.searchedValue}
                                       onChange={(event) => this.setState({searchedValue: event.target.value})}/>
                            </div>
                        </div>

                        <div className={'col-sm-6 buttons-header-container justify-content-end mt-0'}>
                            <button className={'add-instant-btn'} onClick={(): void => this.addInstant()}>
                                {this.getTitle()}
                            </button>
                        </div>
                    </div>

                    <div className={'row ml-0'}>
                        {this.renderInstantList()}
                    </div>
                </div>
            </div>
        )
    }

    renderDescriptionFooter(): string {
        return this.props.type === 'event' ? i18n.management_instant.description_events :
            this.props.type === 'service' ? i18n.management_instant.description_services :
                i18n.management_instant.description_special_offers
    }

    addInstant(): void {
        if (InstantManagementSpecifications.canAddInstant(this.props.instants, this.props.company?.membership.allowedInstants ?? 0))
            this.props.addInstant()
        else
            this.setState({isMembershipMsgVisible: true})
    }

    renderInstantList(): JSX.Element[] | JSX.Element {
        const filteredInstants = this.state.instants.filter((instant: ManagementInstantHeader) => {
                return instant.title.toLowerCase().includes(this.state.searchedValue.toLowerCase())
                    || instant.description.toLowerCase().includes(this.state.searchedValue.toLowerCase())
                    || instant.zipCode.toString().includes(this.state.searchedValue.toString())
            }
        )
        if (filteredInstants.length > 0) {
            return filteredInstants.map(instant =>
                <InstantItem instant={instant}
                             key={instant.id}
                             displayShare={this.props.company?.id === '715cd3f2-6f4f-4180-85f8-8b44366d51ae'}
                             deleteInstant={this.props.deleteInstant}
                             editInstantPath={this.props.editInstantPath}
                             updateInstantStatus={this.props.updateInstantStatus}/>
            )
        } else {
            return <p className={'empty-list'}>{i18n.management_instant.empty_list}</p>
        }
    }

    getTitle(): string {
        return this.props.type === 'event' ?
            i18n.management_instant.add_agenda_instant : this.props.type === 'service' ?
                i18n.management_instant.add_service :
                i18n.management_instant.add_offer_instant
    }
}
