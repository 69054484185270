import { ManagementInstantHeader } from './managementInstantHeader'
import { ManagementInstantCategoryType } from "./types/managementInstantCategoryType";
import { ManagementInstantDelayType } from "./types/managementInstantDelayType";
import { ActivationDayType } from "./types/activationDayType";

export class ManagementInstantHeaderBuilder {

    protected _id: string
    protected _title: string
    protected _picture: string
    protected _category: ManagementInstantCategoryType
    protected _description: string
    protected _latitude: number
    protected _longitude: number
    protected _zipCode: string
    protected _delay: ManagementInstantDelayType
    protected _activationStartDate: Date
    protected _activationEndDate: Date
    protected _originalStartDate: Date
    protected _lastUpdate: Date
    protected _businessId: string
    protected _status: boolean
    protected _activationDayType: ActivationDayType

    withId(value: string): ManagementInstantHeaderBuilder {
        this._id = value
        return this
    }

    withTitle(value: string): ManagementInstantHeaderBuilder {
        this._title = value
        return this
    }

    withPicture(value: string): ManagementInstantHeaderBuilder {
        this._picture = value
        return this
    }

    withDescription(value: string): ManagementInstantHeaderBuilder {
        this._description = value
        return this
    }

    withCategory(value: ManagementInstantCategoryType): ManagementInstantHeaderBuilder {
        this._category = value
        return this
    }

    withLatitude(value: number): ManagementInstantHeaderBuilder {
        this._latitude = value
        return this
    }

    withLongitude(value: number): ManagementInstantHeaderBuilder {
        this._longitude = value
        return this
    }

    withDelay(value: ManagementInstantDelayType): ManagementInstantHeaderBuilder {
        this._delay = value
        return this
    }

    withActivationStartDate(value: Date): ManagementInstantHeaderBuilder {
        this._activationStartDate = value
        return this
    }

    withActivationEndDate(value: Date): ManagementInstantHeaderBuilder {
        this._activationEndDate = value
        return this
    }

    withOriginalStartDate(value: Date): ManagementInstantHeaderBuilder {
        this._originalStartDate = value
        return this
    }

    withLastUpdate(value: Date): ManagementInstantHeaderBuilder {
        this._lastUpdate = value
        return this
    }

    withBusinessId(value: string): ManagementInstantHeaderBuilder {
        this._businessId = value
        return this
    }

    withStatus(value: boolean): ManagementInstantHeaderBuilder {
        this._status = value
        return this
    }

    withActivationDayType(value: ActivationDayType): ManagementInstantHeaderBuilder {
        this._activationDayType = value
        return this
    }

    withZipcode(value: string): ManagementInstantHeaderBuilder {
        this._zipCode = value
        return this
    }

    build(): ManagementInstantHeader {
        return new ManagementInstantHeader(
            this._id,
            this._title,
            this._picture,
            this._description,
            this._category,
            this._latitude,
            this._longitude,
            this._zipCode,
            this._delay,
            this._activationStartDate,
            this._activationEndDate,
            this._originalStartDate,
            this._lastUpdate,
            this._businessId,
            this._status,
            this._activationDayType
        )
    }
}
