import React, { useEffect, useState } from "react";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { i18n } from "../../../../configuration/i18n";
import { ProductDetails } from "../../../domain/entities/productDetails";
import { ProductDetailsType } from "../types";
import { Company } from "../../../../company/domain/entity/company";
import { ProductDetailsBuilder } from "../../../domain/entities/productDetails.builder";
import { ValueOf } from "../../../../common/domain/types/app.categories";
import { ProductFormContainer } from "../components/form";

interface Props {
    company: Company | null;
    loading: boolean;
    success: boolean | undefined;
    error: string | undefined;
    addProduct: (product: ProductDetails, companyId: string) => void;
}

export const AddProductContainer = (props: Props): JSX.Element => {

    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
    const [productDetails, setProductDetails] = useState<ProductDetailsType>({
        name            : '',
        image           : undefined,
        gallery         : [],
        category        : "",
        price           : "",
        stock           : "",
        weight          : '',
        length          : '',
        width           : '',
        height          : '',
        description     : "",
        shortDescription: ""
    })

    useEffect(() => {
        document.title = i18n.ecommerce.add_product_page_title;
    }, [])

    useEffect(() => {
        if (props.success) {
            window.location.href = '/products'
        }
    }, [props.success])

    useEffect(() => {
        if (props.error === "500") {
            setErrorMsg("Server error")
        }
    }, [props.error])

    const isValidForm = () => {
        const weight = parseFloat(productDetails.weight);
        const length = parseFloat(productDetails.length);
        const width = parseFloat(productDetails.width);
        const height = parseFloat(productDetails.height);

        if (productDetails.name.length === 0 || productDetails.category.length === 0 ||
            productDetails.price.length === 0 || productDetails.stock.length === 0 ||
            productDetails.shortDescription.length === 0 || productDetails.description.length === 0 ||
            !productDetails.image ||
            weight <= 0 || isNaN(weight) ||
            length <= 0 || isNaN(length) ||
            width <= 0 || isNaN(width) ||
            height <= 0 || isNaN(height)) {

            setErrorMsg(i18n.ecommerce.empty_form_error)
            return false
        }
        return true
    }
    const addProduct = () => {
        if (props.company && isValidForm()) {
            const newProduct = new ProductDetailsBuilder()
                .withName(productDetails.name)
                .withCategory(productDetails.category)
                .withImage(productDetails.image ?? "")
                .withPrice(+productDetails.price)
                .withStock(+productDetails.stock)
                .withWeight(+productDetails.weight)
                .withLength(+productDetails.length)
                .withWidth(+productDetails.width)
                .withHeight(+productDetails.height)
                .withDescription(productDetails.description)
                .withShortDescription(productDetails.shortDescription)
                .withGallery(productDetails.gallery)
                .build()
            props.addProduct(newProduct, props.company.id)
        }
    }

    const loader = props.loading && (
        <div className={'show-loader'}>
            <div className={'loader'}/>
        </div>
    )

    return (
        <div>
            <DefaultPageHeader title={i18n.common.add_product}/>

            <div className="profile-edition theme-builder">
                {loader}

                <ProductFormContainer data={productDetails}
                                      error={!!errorMsg}
                                      raiseUpdates={(key: keyof ProductDetailsType, value: ValueOf<ProductDetailsType>) => {
                                          setProductDetails({
                                              ...productDetails,
                                              [key]: value
                                          })
                                          setErrorMsg(undefined)
                                      }}/>

                <div className="wizard-form-field">
                    {errorMsg && <p className={'error'}>{errorMsg}</p>}

                    <button onClick={(): void => addProduct()} className={'company-submit-btn my-5 mx-auto'}>
                        {i18n.company.save}
                    </button>
                </div>
            </div>
        </div>
    )

}
