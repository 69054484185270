import { EcommerceOrderDTO } from "../dto/ecommerceOrder.dto";
import { EcommerceOrder } from "../../../../domain/entities/ecommerceOrder";
import { EcommerceOrderBuilder } from "../../../../domain/entities/ecommerceOrder.builder";
import { ProductDetailsBuilder } from "../../../../domain/entities/productDetails.builder";
import { EcommerceBuyer } from "../../../../domain/entities/ecommerceBuyer";

export class EcommerceOrderMapper {
    static mapToEcommerceOrders(orders: EcommerceOrderDTO[]): EcommerceOrder[] {
        console.log(orders);
        return orders.map((order) => {
            const products = order.orderItems.map((item) =>
                new ProductDetailsBuilder()
                    .withCategory(item.category)
                    .withName(item.title)
                    .withPrice(item.unitPrice)
                    .build()
            );
            return new EcommerceOrderBuilder()
                .withId(order.id)
                .withPrice(order.amount)
                .withTransactionStatus(order.delivery.status)
                .withPaymentStatus(order.paymentStatus)
                .withCreatedAt(order.dateCreated)
                .withProducts(products)
                .withEventUrl(order.eventUrl)
                .withBuyer(
                    new EcommerceBuyer(
                        order.buyer.firstName + " " + order.buyer.lastName,
                        order.buyer.email,
                        order.buyer.phone,
                    )
                )
                .build();
        });
    }
}
