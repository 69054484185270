import {
    LOAD_STORED_TOKEN,
    LOAD_STORED_TOKEN_FAILED, LOAD_STORED_TOKEN_SUCCEEDED,
    LoadStoredTokenActionTypes
} from "./actionTypes";
import { LoadTokenState } from "../../configuration/state";

const initialState: LoadTokenState = {
    token  : null,
    loading: false,
    error  : null
}

export const loadStoredTokenReducer = (state = initialState, action: LoadStoredTokenActionTypes): LoadTokenState => {
    switch (action.type) {
        case LOAD_STORED_TOKEN:
            return {
                token  : null,
                loading: true,
                error  : null
            }
        case LOAD_STORED_TOKEN_SUCCEEDED:
            return {
                token  : action.payload,
                loading: false,
                error  : false
            }
        case LOAD_STORED_TOKEN_FAILED:
            return {
                token  : null,
                loading: false,
                error  : true
            }
        default:
            return state
    }
}
