import React, { PureComponent, ReactNode } from "react";
import { FormValidation } from "../../../../../../common/adapters/primaries/form/validation";
import { SubmitButton } from "../../components/submit.button";
import { User } from "../../../../../domain/entity/user";
import { UserBuilder } from "../../../../../domain/builder/user.builder";
import { SignUpContainer } from "../signUp";
import { BusinessRegistrationContainer } from "../businessRegistration";
import { ConfirmationContainer } from "../confirmation";
import { AdministrativeNumber } from "../../../../../domain/entity/business/administrativeNumber";
import { RNA } from "../../../../../domain/entity/business/RNA";
import { Siret } from "../../../../../domain/entity/business/Siret";
import { Company } from "../../../../../../company/domain/entity/company";
import { CompanyBuilder } from "../../../../../../company/domain/builder/company.builder";
import { CompanyMembershipFactory } from "../../../../../../company/domain/builder/companyMembershipFactory";

const moment = require('moment');

interface Props {
    defaultType: string | undefined
    submissionError: string | undefined;
    successSignup: boolean | null;
    signUpPhoneNumber: (user: User) => void;
    businessBySiretCode: Company | null;
    registerBusiness: (business: Company, user: User, prospectEmail: string | null) => void;
    updateType: (value: string) => void;
}

interface State {
    date: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    city: string;
    zipCode: string;
    type: string;
    administrativeNumber: AdministrativeNumber;
    error: boolean
    visible: boolean;
}

export class CreationContainer extends PureComponent<Props, State> {

    private prospectEmail: string | null

    constructor(props: Props) {
        super(props);
        this.prospectEmail = null;
        this.state = {
            date                : '',
            phoneNumber         : '',
            firstName           : '',
            lastName            : '',
            email               : '',
            city                : '',
            zipCode             : '',
            type                : this.props.defaultType ? this.props.defaultType : 'commerce',
            administrativeNumber: this.props.defaultType && this.props.defaultType === 'association' ? new RNA('') : new Siret(''),
            error               : false,
            visible             : false
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if(this.props.successSignup != prevProps.successSignup && this.props.successSignup){
            this.setState({visible: true})
        }
    }

    componentDidMount(): void {
        const paramsString = window.location.search
        const searchParams = new URLSearchParams(paramsString);
        if (paramsString.length > 0) {
            this.prospectEmail = searchParams.get('useremail')
        }
    }

    render(): ReactNode {
        const error = this.props.submissionError ?
            <p className={"footer-form error"}>{this.props.submissionError}</p> : null
        return (
            <div>
                <SignUpContainer hasError={this.state.error}
                    // @ts-ignore
                                 raiseFormUpdates={(key, value) => this.setState({[key]: value})}/>

                <ConfirmationContainer phoneNumber={this.state.phoneNumber}
                                       isVisible={this.state.visible}
                                       onClose={() => this.setState({visible: false})}
                                       onConfirmationSucceeded={() => this.registerBusiness()}/>

                <BusinessRegistrationContainer phoneNumber={this.state.phoneNumber}
                                               defaultType={this.props.defaultType}
                                               hasError={this.state.error}
                                               updateType={(type: string) => {
                                                   this.setState({type})
                                                   this.props.updateType(type)
                                               }}
                    // @ts-ignore
                                               raiseUpdates={(key, value) => this.setState({
                                                   [key]: value
                                               })}
                />

                <div className={'registration-footer'}>
                    {error}
                    <SubmitButton submit={() => this.signUpPhoneNumber()}
                                  goBack={() => void 0}/>
                </div>
            </div>
        )
    }

    signUpPhoneNumber(): void {
        this.setState({error: !this.validForm()}, () => {
            if (!this.state.error && this.props.businessBySiretCode) {
                const phoneNumber = this.state.phoneNumber.indexOf('+') !== -1 ? this.state.phoneNumber : '+' + this.state.phoneNumber
                const user = new UserBuilder()
                    .withFirstName(this.state.firstName)
                    .withLastName(this.state.lastName)
                    .withPhoneNumber(phoneNumber)
                    .withBirthDate(moment(this.state.date).format())
                    .withEmail(this.state.email)
                    .withCity(this.state.city)
                    .withZipCode(this.state.zipCode)
                    .build()
                this.props.signUpPhoneNumber(user)
            }
        })
    }

    registerBusiness(): void {
        this.setState({visible: false})
        if (this.props.businessBySiretCode) {
            const business = new CompanyBuilder()
                .fromCompany(this.props.businessBySiretCode)
                .withMembership(CompanyMembershipFactory.getMemberShip('freemium'))
                .withType(this.state.type)

            const user = new UserBuilder()
                .withFirstName(this.state.firstName)
                .withLastName(this.state.lastName)
                .withPhoneNumber(this.state.phoneNumber)
                .withBirthDate(moment(this.state.date).format())
                .withCity(this.state.city)
                .withEmail(this.state.email)
                .withZipCode(this.state.zipCode)
                .build()

            this.props.registerBusiness(business.build(), user, this.prospectEmail)
        }
    }

    validForm(): boolean {
        return FormValidation.validate('phoneNumber', this.state.phoneNumber) &&
            FormValidation.validateBirthDay(this.state.date) &&
            FormValidation.validate('name', this.state.firstName) &&
            FormValidation.validate('name', this.state.lastName) &&
            FormValidation.validateEmail(this.state.email) &&
            !isNaN(parseInt(this.state.zipCode)) && this.state.zipCode.length === 5 &&
            this.state.administrativeNumber.validateNumber()
    }
}
