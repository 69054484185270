import { connect } from 'react-redux'
import { ManagementInstantHeader } from "../../../domain/entities/managementInstantHeader";
import {
    instantsCompanySelector,
    loadingInstantsCompanySelector
} from "../../../usecases/loadInstantsCompany/loadInstantsCompany.selectors";
import { AppState } from "../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { InstantsListingContainer } from "./instantsListing.container";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import { updateInstantStatus } from "../../../usecases/updateStatus/updateInstantStatus.actions";
import { deleteInstant } from "../../../usecases/delete/deleteInstant.actions";

interface StateToPropsType {
    instants: ManagementInstantHeader[] | null;
    company: Company | null;
    loading: boolean;
}

interface DispatchToPropsType {
    updateInstantStatus: (instantId: string, newStatus: boolean) => void;
    deleteInstant: (instantId: string, companyId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    instants: instantsCompanySelector(state),
    company : companySelector(state),
    loading : loadingInstantsCompanySelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    updateInstantStatus: (instantId: string, newStatus: boolean) => dispatch(updateInstantStatus(instantId, newStatus)),
    deleteInstant      : (instantId: string, companyId: string) => dispatch(deleteInstant(instantId, companyId))
})

export const InstantsListing = connect(mapStateToProps, mapDispatchToProps)(InstantsListingContainer)
