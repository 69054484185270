import React from "react";
import { ValueOf } from "../../../../common/domain/types/app.categories";
import { i18n } from "../../../../configuration/i18n";
import { AccordionItem } from "../../../../company/adapters/primaries/themeConfiguration/components/accordion.item";
import { EcommerceProductGeneralForm } from "./general.form";
import { ProductDetailsType } from "../types";
import { EcommerceProductAdvancedForm } from "./advanced.form";

interface Props {
    data: ProductDetailsType;
    error: boolean;
    raiseUpdates: (key: keyof ProductDetailsType, value: ValueOf<ProductDetailsType>) => void;
}

export const ProductFormContainer = (props: Props): JSX.Element => {

    return (
        <div className="wizard-form-field mt-3 mb-60">
            <div id="accordion">
                <AccordionItem title={i18n.ecommerce.general_settings}>
                    <EcommerceProductGeneralForm data={props.data}
                                                 error={props.error}
                                                 onChange={(key: keyof ProductDetailsType, value: ValueOf<ProductDetailsType>) => props.raiseUpdates(key, value)}/>
                </AccordionItem>

                <AccordionItem title={i18n.ecommerce.advanced_settings}>
                    <EcommerceProductAdvancedForm data={props.data}
                                                 error={props.error}
                                                 onChange={(key: keyof ProductDetailsType, value: ValueOf<ProductDetailsType>) => props.raiseUpdates(key, value)}/>
                </AccordionItem>
            </div>
        </div>
    )

}
