import { ManagementInstantCoordinates } from "./ManagementInstantCoordinates";
import { ManagementInstantActivationTime } from "./managementInstantActivationTime";
import { ManagementInstantCategoryType } from "./types/managementInstantCategoryType";
import { ActivationTimeType, Schedule } from "./types/activationTimeType";
import { ManagementInstant } from "./ManagementInstant";
import { ManagementInstantInformation } from "./managementInstantInformation";
import { ManagementInstantAddress } from "./managementInstantAddress";


export class ManagementInstantBuilder {

    protected _id: string
    protected _businessId: string
    protected _title: string
    protected _picture: File
    protected _pictureUrl: string
    protected _shortDescription: string
    protected _longDescription: string
    protected _category: ManagementInstantCategoryType

    protected _address: string
    protected _zipCode: number
    protected _city: string
    protected _coordinates: ManagementInstantCoordinates

    protected _activationTime: ManagementInstantActivationTime[] = []
    protected _notificationSent: boolean
    protected _notificationDate: string
    protected _instantStatus: boolean

    withId(id: string): ManagementInstantBuilder {
        this._id = id
        return this
    }

    withBusinessId(id: string): ManagementInstantBuilder {
        this._businessId = id
        return this
    }

    withActivationTimeType(value: ActivationTimeType): ManagementInstantBuilder {
        if (Array.isArray(value.schedule))
            value.schedule?.map((item: Schedule) => {
                this._activationTime.push(new ManagementInstantActivationTime(
                    item.id,
                    item.prePoned ? 'DURING_AND_BEFORE_TWO_HOUR' : 'DURING',
                    item.dateRange.startTime,
                    item.dateRange.endTime,
                    !item.isPaused,
                    item.dateRange.key
                ))
            })
        return this
    }


    withActivationTime(value: ManagementInstantActivationTime[]): ManagementInstantBuilder {
        this._activationTime = value;
        return this
    }

    withTitle(value: string): ManagementInstantBuilder {
        this._title = value
        return this
    }

    withShortDescription(value: string): ManagementInstantBuilder {
        this._shortDescription = value
        return this
    }

    withPicture(value: File): ManagementInstantBuilder {
        this._picture = value
        return this
    }

    withPictureUrl(value: string): ManagementInstantBuilder {
        this._pictureUrl = value
        return this
    }

    withLongDescription(value: string): ManagementInstantBuilder {
        this._longDescription = value
        return this
    }

    withCategory(value: ManagementInstantCategoryType): ManagementInstantBuilder {
        this._category = value
        return this
    }

    withAddress(value: string): ManagementInstantBuilder {
        this._address = value
        return this
    }

    withZipcode(value: number): ManagementInstantBuilder {
        this._zipCode = value
        return this
    }

    withCity(value: string): ManagementInstantBuilder {
        this._city = value
        return this
    }

    withNotificationDate(value: string): ManagementInstantBuilder {
        this._notificationDate = value
        return this
    }

    withNotificationSent(value: boolean): ManagementInstantBuilder {
        this._notificationSent = value
        return this
    }

    withInstantStatus(value: boolean): ManagementInstantBuilder {
        this._instantStatus = value
        return this
    }


    withCoords(value: ManagementInstantCoordinates): ManagementInstantBuilder {
        this._coordinates = value
        return this
    }

    build(): ManagementInstant {
        const information = new ManagementInstantInformation(
            this._id,
            this._businessId,
            this._title,
            this._picture,
            this._pictureUrl,
            this._shortDescription,
            this._longDescription,
            this._category,
            this._instantStatus
        )

        const address = new ManagementInstantAddress(
            this._address,
            this._zipCode,
            this._city,
            this._coordinates
        )

        return new ManagementInstant(
            information,
            address,
            this._activationTime,
            this._notificationSent,
            this._notificationDate,
            this._instantStatus
        )
    }
}
