import React, { PureComponent, ReactNode } from "react";
import { SignInContainer } from "../signIn";
import { ConfirmationContainer } from "../confirmation";
import { NoCompanyFound } from "../components/noCompanyFound";
import { i18n } from "../../../../configuration/i18n";
import { SuccessLogin } from "../components/successLogin";
import './login.css'
import { HeaderMenu } from "../components/headerMenu";
import { Company } from "../../../../company/domain/entity/company";

interface Props {
    company: Company | null;
}

interface State {
    step: number;
    phoneNumber: string;
}

export class LoginContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            step       : 1,
            phoneNumber: ''
        }
    }

    componentDidMount(): void {
        document.title = i18n.authentication.page_title;
        if (this.props.company)
            if (this.props.company.category !== null) {
                window.location.href = '/company'
            } else {
                window.location.href = '/inscription'
            }

    }


    render(): ReactNode {
        return (
            <div>
                <HeaderMenu/>

                <div className="wrapper wrapper_login wizard clearfix position-relative">
                    <form className="multisteps-form__form" id={'wizard'} onSubmit={e => e.preventDefault()}>
                        {this.renderFormStep()}

                        <div className={'footer-form px-3'}>
                            <a href="https://meetings-eu1.hubspot.com/edouard-nilsson" rel="noreferrer"
                               target="_blank">{i18n.authentication.text_footer}</a>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    renderFormStep(): JSX.Element {
        switch (this.state.step) {
            case 1:
                return <SignInContainer phoneNumber={this.state.phoneNumber}
                                        onPressNext={(phoneNumber: string) => this.setState({step: 2, phoneNumber})}/>
            case 2:
                return <ConfirmationContainer phoneNumber={this.state.phoneNumber}
                                              onPressBack={() => this.setState({step: 1})}
                                              onPressNext={(step: number) => this.setState({step})}/>
            case 3:
                if (this.props.company)
                    if (this.props.company.category !== null) {
                        window.location.href = '/company'
                    } else {
                        window.location.href = '/inscription'
                    }
                return <SuccessLogin/>

            case 4:
                return <NoCompanyFound/>

            default:
                return <SignInContainer phoneNumber={this.state.phoneNumber}
                                        onPressNext={(phoneNumber: string) => this.setState({step: 1, phoneNumber})}/>
        }
    }
}
