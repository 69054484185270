import { ManagementInstant } from "../../domain/entities/ManagementInstant";

export const CREATE_INSTANT = 'CREATE_INSTANT'
export const CREATE_INSTANT_SUCCEEDED = 'CREATE_INSTANT_SUCCEEDED'
export const CREATE_INSTANT_FAILED = 'CREATE_INSTANT_FAILED'

export interface CreateInstantAction {
    type: typeof CREATE_INSTANT;
    payload: ManagementInstant;
}

export interface CreateInstantSucceededAction {
    type: typeof CREATE_INSTANT_SUCCEEDED;
}

export interface CreateInstantFailedAction {
    type: typeof CREATE_INSTANT_FAILED;
    payload: string;
}

export type CreateInstantActionTypes = CreateInstantAction | CreateInstantSucceededAction | CreateInstantFailedAction
