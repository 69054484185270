import React, { PureComponent, ReactNode, forwardRef, ForwardedRef } from "react";
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Link from '@material-ui/icons/Link';
import Search from '@material-ui/icons/Search';
import { Theme } from "../../../../../common/configuration/theme/app.theme";
import { SvgIconTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { CompanyDataType } from "../../superAdminStats/stats.container";
import { ApplicationContext } from "../../../../../configuration/application.context";


const moment  = ApplicationContext.getInstance().momentJs()

interface Props {
    data: CompanyDataType[]
}

export class CompanyDataTable extends PureComponent<Props> {

    render(): ReactNode {
        const columns = [
            {
                title: 'Icone', field: 'icon', render: (rowData: { icon: string }) => (
                    <img style={{ borderRadius: 5, width: 60, height: 60 }} src={rowData.icon}/>
                ),
            },
            { title: 'Nom', field: 'name' },
            { title: 'Catégorie', field: 'category' },
            { title: 'Adresse', field: 'address' },
            {
                title : 'Site web',
                field : 'subdomain',
                render: (rowData: { subdomain: string }) => this.renderWebsiteButton(rowData)
            },
            { title: 'Email', field: 'email', render: (rowData: { email: string }) => this.renderEmailButton(rowData) },
            {
                title     : 'Date de création',
                field     : 'creationDate',
                render    : (rowData: { creationDate: Date }) => moment(rowData.creationDate).format('ll'),
                customSort: (
                    data1: { creationDate: Date },
                    data2: { creationDate: Date }) => data1.creationDate.getTime() - data2.creationDate.getTime()

            }
        ];

        const tableIcons = {
            Clear       : forwardRef((props: DefaultComponentProps<SvgIconTypeMap<{}, "svg">>, ref: ForwardedRef<SVGSVGElement>) =>
                <Clear {...props} ref={ref}/>),
            DetailPanel : forwardRef((props: DefaultComponentProps<SvgIconTypeMap<{}, "svg">>, ref: ForwardedRef<SVGSVGElement>) =>
                <ChevronRight {...props} ref={ref}/>),
            NextPage    : forwardRef((props: DefaultComponentProps<SvgIconTypeMap<{}, "svg">>, ref: ForwardedRef<SVGSVGElement>) =>
                <ChevronRight {...props} ref={ref}/>),
            PreviousPage: forwardRef((props: DefaultComponentProps<SvgIconTypeMap<{}, "svg">>, ref: ForwardedRef<SVGSVGElement>) =>
                <ChevronLeft {...props} ref={ref}/>),
            ResetSearch : forwardRef((props: DefaultComponentProps<SvgIconTypeMap<{}, "svg">>, ref: ForwardedRef<SVGSVGElement>) =>
                <Clear style={{ color: '#777' }} {...props} ref={ref}/>),
            Search      : forwardRef((props: DefaultComponentProps<SvgIconTypeMap<{}, "svg">>, ref: ForwardedRef<SVGSVGElement>) =>
                <Search {...props} ref={ref}/>),
            SortArrow   : forwardRef((props: DefaultComponentProps<SvgIconTypeMap<{}, "svg">>, ref: ForwardedRef<SVGSVGElement>) =>
                <ArrowDownward style={{ color: '#FFF' }} {...props} ref={ref}/>),
        };

        return (
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    icons={tableIcons}
                    style={{ width: '100%' }}
                    options={{
                        headerStyle             : {
                            backgroundColor: Theme.blueMinsk,
                            color          : '#FFFFFF'
                        },
                        showFirstLastPageButtons: false,
                        rowStyle                : () => ({
                            backgroundColor: '#EEE'
                        }),
                        pageSize                : 20,
                        pageSizeOptions         : [10, 20, 50]

                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count} ligne',
                        },
                        toolbar   : {
                            searchPlaceholder: ''
                        }
                    }}
                    columns={columns}
                    data={this.props.data}
                    title={''}/>
            </div>
        )
    }

    renderWebsiteButton(company: { subdomain: string }): JSX.Element {
        if (company.subdomain)
            return (
                <a className={'stat-website-btn'} target='_blank' rel="noreferrer" href={company.subdomain}>
                    <Link style={styles.icon}/>
                </a>
            )
        else
            return <p className="pl-1">---</p>
    }

    renderEmailButton(company: { email: string }): JSX.Element {
        if (company.email)
            return (
                <a className={'stat-email-btn'} target='_blank' rel="noreferrer" href={"mailto:" + company.email}>
                    {company.email}
                </a>
            )
        else
            return <p className="text-center">---</p>
    }
}

const styles = {
    icon: {
        color : Theme.blueMinsk,
        width : '30px',
        height: '30px',
    }
}
