import { ADD_PRODUCT, ADD_PRODUCT_FAILED, ADD_PRODUCT_SUCCEEDED, AddProductActionTypes } from "./actionTypes";
import { ProductManagementState } from "../../configuration/state";

const initialState: ProductManagementState = {
    loading: false,
    success: undefined,
    error  : undefined,
}

export const addProductReducer = (state = initialState, action: AddProductActionTypes): ProductManagementState => {
    switch (action.type) {
        case ADD_PRODUCT:
            return {
                loading: true,
                success: undefined,
                error  : undefined,
            }
        case ADD_PRODUCT_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case ADD_PRODUCT_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
}
