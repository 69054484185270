import { Observable, throwError } from 'rxjs';
import { RegistrationService } from "../../../domain/gateway/registration.service";
import { ObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/ObservableAjax.httpClient";
import { catchError, map } from "rxjs/operators";
import { Token } from "../../../domain/entity/token";
import { User } from "../../../domain/entity/user";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { UserMapper } from "./mapper/user.mapper";
import { BusinessInfoDto, OpeningHoursDto } from "./dto/businessInfo.dto";
import { DeviceInfoCollector } from "../../../../common/adapters/secondaries/real/DeviceInfoCollector";
import { Siret } from "../../../domain/entity/business/Siret";
import { RNA } from "../../../domain/entity/business/RNA";
import { CompanyBuilder } from "../../../../company/domain/builder/company.builder";
import { Company } from "../../../../company/domain/entity/company";
import { CompanyOpeningHours } from "../../../../company/domain/entity/companyOpeningHours";

export class ApiRegistrationService extends DeviceInfoCollector implements RegistrationService {

    private headers: { [key: string]: string } = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept'      : 'application/json'
    }

    signUp(phoneNumber: string): Observable<void> {

        const URL = process.env.REACT_APP_API_URL + '/v1/authentication/send-activation-code'
        const body = {phone: phoneNumber, device_id: 'WEB_APPLICATOIN_NAVIGATOR', app_version: 'x.0.17'}
        return new ObservableAjaxHttpClient().post(URL, body, this.headers)
            .pipe(
                map(() => (void 0)),
                catchError(err => {
                    let formattedError
                    switch (err.status.toString()) {
                        case "400":
                            formattedError = 'Merci de vérifier votre numéro de téléphone'
                            break
                        default:
                            formattedError = 'Une erreur est survenue, merci de vérifier vos données'
                    }
                    return throwError(formattedError)
                })
            )
    }

    sendConfirmationCode(phoneNumber: string, code: string): Observable<Token> {
        const URL = process.env.REACT_APP_API_URL + '/v1/authentication/start-session'
        const body = {phone: phoneNumber, code, app_id: process.env.REACT_APP_ADD_ID}
        return new ObservableAjaxHttpClient().post(URL, body, this.headers)
            .pipe(
                map(response => ({
                    token       : response.response.data.token,
                    registration: response.response.data.registration
                })),
                catchError(err => throwError(err.status.toString()))
            )
    }

    updateProfile(user: User): Observable<void> {
        const URL = process.env.REACT_APP_API_URL + '/v1/profile/update'
        const userDto = UserMapper.mapUserEntityToApiUser(user)
        const body = {...userDto, app_id: process.env.REACT_APP_ADD_ID}
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(URL, body, this.headers)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadBusinessByCode(code: string, stringifiedInputs: string): Observable<Company> {
        const URL = process.env.REACT_APP_SLACK_URL ? process.env.REACT_APP_SLACK_URL : '';
        window.fetch(URL, {
            method : 'POST',
            mode   : 'no-cors',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body   : JSON.stringify({"text": stringifiedInputs})
        })

        const url = process.env.REACT_APP_API_URL + '/v1/company/siren?siren=' + code
        return new ObservableAjaxHttpClient().get<{ data: BusinessInfoDto }>(url)
            .pipe(
                map((response: { data: BusinessInfoDto }) =>
                    new CompanyBuilder()
                        .withName(response.data.name)
                        .withAddress(response.data.address)
                        .withZipCode(response.data.zipCode)
                        .withCity(response.data.city)
                        .withAdministrativeNumber(code.length === RNA.length ? new RNA(code) : new Siret(code))
                        .build()
                ),
                catchError(err => throwError(err.status.toString()))
            )
    }

    getBusinessId(): Observable<string> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/ownership'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: Array<{ companyId: string, profileId: string }> }>(url)
            .pipe(
                map((response: { data: Array<{ companyId: string, profileId: string }> }) => response.data[0].companyId),
                catchError(err => throwError(err))
            )
    }

    registerBusiness(business: Company, originalProspectEmail: string | null): Observable<string> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/subscription'

        const body = new FormData();
        if (originalProspectEmail) {
            body.append('originalProspectEmail', originalProspectEmail);
        }
        body.append('type', business.type);
        body.append('siren', business.administrativeNumber.number);
        body.append('membership', business.membership.type);
        body.append('device_id', 'WEB_APPLICATOIN_NAVIGATOR');
        body.append('app_version', 'x.0.17');

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map((response) => {
                    if (response.response.data !== undefined) {
                        return response.response.data
                    }
                    return throwError('Une erreur est survenue, merci de vérifier vos donner et rééssayer')
                }),
                catchError((err) => {
                    if (err.status.toString() === '409') {
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000)
                        return throwError('Une erreur est survenue, merci de recharger la page')
                    }
                    return throwError('Une erreur est survenue, merci de vérifier vos donner et rééssayer')
                })
            )
    }

    updateBusiness(company: Company): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/' + company.id + '/update-settings-and-openingHours'
        const body = new FormData();

        if (company.contacts.phoneNumber)
            body.append('phoneNumber', company.contacts.phoneNumber);
        if (company.contacts.website)
            body.append('website', company.contacts.website);
        if (company.contacts.email)
            body.append('email', company.contacts.email);

        body.append('id-card-front', company.cinFront);
        body.append('id-card-back', company.cinBack);
        body.append('companyId', company.id);
        body.append('name', company.name);
        body.append('type', company.type);
        body.append('category', company.category)
        body.append('profileImage', company.profileImage);
        body.append('coverImage', company.coverImage);
        body.append('address', company.address.address);
        body.append('city', company.address.city);
        body.append('zipCode', company.address.zipCode);
        body.append('longitude', company.address.longitude);
        body.append('latitude', company.address.latitude);
        body.append('description', company.contacts.description);
        body.append('openingHours', JSON.stringify(this.openingHoursCommand(company.openingHours)));

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    private openingHoursCommand(openingHours: CompanyOpeningHours[]): OpeningHoursDto[] {
        const openingHoursArray: OpeningHoursDto[] = []
        openingHours.map(item => {
            openingHoursArray.push({
                id       : '',
                daytype  : item.openingDays,
                startTime: item.startTime,
                endTime  : item.endTime,
                enabled  : '1'
            })
        })
        return openingHoursArray
    }
}
