import { ManageMessagingState } from "../../../configuration/state";
import { SEND_MESSAGE, SEND_MESSAGE_FAILED, SEND_MESSAGE_SUCCEEDED, SendMessageActionsTypes } from "./actionTypes";

const initialState: ManageMessagingState = {
    loading: false,
    success: null,
    error  : undefined
}

export const sendMessageReducer = (state = initialState, action: SendMessageActionsTypes): ManageMessagingState => {
    switch (action.type) {
        case SEND_MESSAGE:
            return {
                loading: true,
                success: null,
                error  : undefined
            }
        case SEND_MESSAGE_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case SEND_MESSAGE_FAILED:
            return {
                loading: false,
                success: null,
                error  : action.payload
            }
        default:
            return state
    }
}
