import { LOAD_ECOMMERCE_ORDERS, LOAD_ECOMMERCE_ORDERS_FAILED, LOAD_ECOMMERCE_ORDERS_SUCCEEDED, LoadEcommerceOrdersActionTypes } from "./actionTypes";
import { LoadEcommerceOrdersState } from "../../configuration/state";

const initialState: LoadEcommerceOrdersState = {
    loading: false,
    data   : undefined,
    error  : undefined,
}

export const loadEcommerceOrdersReducer = (state = initialState, action: LoadEcommerceOrdersActionTypes): LoadEcommerceOrdersState => {
    switch (action.type) {
        case LOAD_ECOMMERCE_ORDERS:
            return {
                loading: true,
                data   : undefined,
                error  : undefined,
            }
        case LOAD_ECOMMERCE_ORDERS_SUCCEEDED:
            return {
                loading: false,
                data   : action.payload,
                error  : undefined
            }
        case LOAD_ECOMMERCE_ORDERS_FAILED:
            return {
                loading: false,
                data   : undefined,
                error  : action.payload
            }
        default:
            return state
    }
}
