import React from "react";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    website: string | undefined;
}

export const SuccessThemeConfiguration = (props: Props): JSX.Element => {
    return (
        <div className={'success-msg'}>
            {i18n.company.success_message}
            {props.website &&
                <a href={"https://" + props.website} target={'_blank'} rel="noreferrer">{i18n.company.show_website}</a>}
        </div>
    )
}
