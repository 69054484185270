import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import React, { PureComponent, ReactNode } from 'react';

interface Props {
    onClick: (emoji: EmojiClickData) => void;
}

interface State {
    isVisible: boolean
}

export class EmojiButtonPicker extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    render(): ReactNode {
        return (
            <div className={'emoji-modal-container'}>
                <button className={'icon-btn'} onClick={() => this.setState({isVisible: !this.state.isVisible})}>
                    <i className="fa fa-smile"/>
                </button>

                {this.state.isVisible && (
                    <div className={'emoji-modal'}>
                        <EmojiPicker skinTonesDisabled
                                     width={300}
                                     height={350}
                                     previewConfig={{showPreview: false}}
                                     onEmojiClick={(emoji: EmojiClickData) => {
                                         this.props.onClick(emoji)
                                         this.setState({isVisible: false})
                                     }}/>
                    </div>
                )}
            </div>
        )
    }
}
