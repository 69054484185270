import { connect } from "react-redux";
import { AppState } from "../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { Conversation } from "../../domain/entities/conversation";
import { Company } from "../../../company/domain/entity/company";
import { BroadcastConversation } from "../../domain/entities/broadcastConversation";
import { businessConversationsSelector } from "../../usecases/conversation/selector";
import { broadcastConversationSelector } from "../../usecases/broadcast/update/selector";
import { LoadBusinessConversationsAction } from "../../usecases/conversation/actionTypes";
import { loadBusinessConversations } from "../../usecases/conversation/actions";
import { loadBroadcastConversation } from "../../usecases/broadcast/load/actions";
import { LoadBroadcastConversationAction } from "../../usecases/broadcast/load/actionTypes";
import { companySelector } from "../../../company/usecases/updateStoreCompany/selector";
import { CompanyMessagingContainer } from "./companyMessaging.container";
import { ChatMessage } from "../../domain/entities/chatMessage";
import { previousChatMessagesSelector, loadPreviousChatMessagesLoadingSelector } from "../../usecases/message/load/selector";
import { LoadPreviousChatMessagesAction } from "../../usecases/message/load/actionTypes";
import { loadPreviousChatMessages } from "../../usecases/message/load/actions";
import { SendMessageAction } from "../../usecases/message/send/actionTypes";
import { sendMessage } from "../../usecases/message/send/actions";
import { SendBroadcastMessageAction } from "../../usecases/broadcast/send/actionTypes";
import { sendBroadcastMessage } from "../../usecases/broadcast/send/actions";
import { successSendingChatMessageSelector } from "../../usecases/message/send/selector";
import { successSendBroadcastMessageSelector } from "../../usecases/broadcast/send/selector";
import { LoadNewChatMessagesAction } from "../../usecases/message/loadNewMessages/actionTypes";
import { loadNewChatMessages } from "../../usecases/message/loadNewMessages/actions";
import { newChatMessagesSelector } from "../../usecases/message/loadNewMessages/selector";

interface StateToPropsType {
    loadingMessages: boolean;
    company: Company | null;
    conversations: Conversation[] | null;
    broadcast: BroadcastConversation;
    messages: ChatMessage[] | null;
    newMessages: ChatMessage[] | null;
    successSendMsg: boolean | null;
    successSendBroadcastMsg: boolean | null;
}

interface DispatchToPropsType {
    loadConversations: (company: Company) => void;
    loadBroadcast: (companyId: string) => void;
    loadPreviousMessages: (conversationId: string, messageId: number) => void;
    loadNewMessages: (conversationId: string, messageId: number) => void;
    sendMessage: (conversationId: string, companyId: string, message: string, image: File | undefined) => void;
    sendBroadcastMessage: (companyId: string, message: string, image: File | undefined) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loadingMessages        : loadPreviousChatMessagesLoadingSelector(state),
    company                : companySelector(state),
    conversations          : businessConversationsSelector(state),
    broadcast              : broadcastConversationSelector(state),
    messages               : previousChatMessagesSelector(state),
    newMessages            : newChatMessagesSelector(state),
    successSendMsg         : successSendingChatMessageSelector(state),
    successSendBroadcastMsg: successSendBroadcastMessageSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadConversations   : (company: Company): LoadBusinessConversationsAction => dispatch(loadBusinessConversations(company)),
    loadBroadcast       : (companyId: string): LoadBroadcastConversationAction => dispatch(loadBroadcastConversation(companyId)),
    loadPreviousMessages: (conversationId: string, messageId: number): LoadPreviousChatMessagesAction => dispatch(loadPreviousChatMessages(conversationId, messageId)),
    loadNewMessages     : (conversationId: string, messageId: number): LoadNewChatMessagesAction => dispatch(loadNewChatMessages(conversationId, messageId)),
    sendMessage         : (conversationId: string, companyId: string, message: string, image: File | undefined): SendMessageAction => dispatch(sendMessage(conversationId, companyId, message, image)),
    sendBroadcastMessage: (companyId: string, message: string, image: File | undefined): SendBroadcastMessageAction => dispatch(sendBroadcastMessage(companyId, message, image)),
})

export const CompanyMessagingPage = connect(mapStateToProps, mapDispatchToProps)(CompanyMessagingContainer)
