import React, { PureComponent, ReactNode } from "react";
import { StepItem } from "./step.item";
import { i18n } from "../../../../../configuration/i18n";
import vivezici from '../../../../../assets/img/vivezici.png';

interface Props {
    currentStep: number;
    type: string;
}

export class StepContainer extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className="steps order-2 position-relative w-25">
                <img src={vivezici} alt={'VIVEZ ICI'} className={'logo'}/>

                <div className="multisteps-form__progress">
                    <StepItem title={i18n.registration.creation_compte(this.props.type)} isActive={this.props.currentStep === 1} icon={'far fa-user'}/>

                    <StepItem title={i18n.registration.professional_info(this.props.type)} isActive={this.props.currentStep === 2} icon={'far fa-building'}/>

                    <StepItem title={i18n.registration.review(this.props.type)} isActive={this.props.currentStep === 3} icon={'far fa-user'}/>
                </div>
            </div>
        )
    }
}
