import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { ApplicationContext } from "../../../../../configuration/application.context";
import { InstantFormValidator } from "../instantForm.validator";
import { TimeInput } from "../../../../../common/adapters/primaries/form/inputs/time/time.input";

interface Props {
    notificationDate: string | undefined;
    isNotificationSent: boolean | undefined;
    raiseUpdate: (value: string | undefined) => void
    hasError: boolean
    allowedNotification: boolean
}

interface State {
    date: string | undefined;
    time: string | undefined;
    dateError: boolean
    timeError: boolean
    error: boolean
    oldNotificationDate: string | undefined
}

const moment = ApplicationContext.getInstance().momentJs()

export class InstantNotificationSection extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            date               : undefined,
            time               : undefined,
            dateError          : false,
            timeError          : false,
            error              : false,
            oldNotificationDate: undefined
        }
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {
        if (nextProps.notificationDate !== undefined && !prevState.date && !prevState.time) {
            if (nextProps.notificationDate && nextProps.notificationDate.length > 0 &&
                nextProps.notificationDate !== 'CLEAR' && !nextProps.isNotificationSent) {
                const dateTime = nextProps.notificationDate.split(' ')
                return {
                    ...prevState,
                    date               : dateTime[0],
                    time               : dateTime[1],
                    oldNotificationDate: nextProps.notificationDate
                }
            } else
                return {
                    ...prevState,
                    date               : '',
                    time               : '',
                    oldNotificationDate: nextProps.notificationDate
                }
        }
        return null
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.hasError && prevProps.hasError !== this.props.hasError)
            this.setState({
                error: !InstantFormValidator.checkNotificationDate(this.state.date + ' ' + this.state.time, this.props.isNotificationSent)
            })
    }

    render(): ReactNode {
        const errorMessage = this.state.error &&
            <p className={'error'}>{i18n.management_instant.notificationDateError}</p>

        const lastNotificationSended = this.props.isNotificationSent && this.props.allowedNotification && this.state.oldNotificationDate ?
            <div className="sub-section">
                <p className={'text-justify'}>{i18n.management_instant.lastNotificationSended(this.state.oldNotificationDate)}</p>
            </div> : null
        if (this.isNotificationSent())
            return (
                <div className={'notification-block section_pro'}>
                    <span className="wizard-sub-text">{i18n.management_instant.notifications_subtitle}</span>
                    <div className={'sub-section px-3'}>
                        <p className={'text-justify'}>{this.renderNotificationText()}</p>
                    </div>
                </div>
            )

        else
            return (
                <div className={'notification-block section_pro'}>
                    <span className="wizard-sub-text">{i18n.management_instant.notifications_subtitle}</span>

                    <div className={'px-3'}>
                        <div className={'sub-section'}>
                            <p className={'text-justify'}>{i18n.management_instant.notification_not_sended}</p>
                        </div>

                        <div className="wizard-form-input opening-hours registration-input mt-4">
                            <label className="start">{i18n.management_instant.notification_date}</label>
                            <input type="date"
                                   value={this.state.date}
                                   className={this.state.dateError ? 'error' : undefined}
                                   onChange={(event) => this.onDateChange(event.target.value)}/>

                            <label className="end">{i18n.management_instant.at}</label>

                            <TimeInput time={this.state.time ? this.state.time : ''}
                                       className={this.state.dateError ? 'error' : ''}
                                       onChange={(time: string): void => this.onTimeChange(time)}/>

                            {this.renderClearButton()}
                        </div>

                        {lastNotificationSended}

                        <div className={'sub-section'}>
                            <span className={'notification-btn'} onClick={() => this.setDefaultDate()}>
                                {i18n.management_instant.send_notif_in_5_min}
                            </span>
                        </div>

                        {errorMessage}
                    </div>
                </div>
            )
    }

    renderClearButton(): JSX.Element | undefined {
        if (this.state.date !== '' || this.state.time !== '')
            return (
                <button className={'btn clear-btn'}
                        onClick={() => {
                            this.props.raiseUpdate('CLEAR')
                            this.setState({
                                date: '',
                                time: ''
                            })
                        }}>
                    <i className="fa fa-times"/>
                </button>
            )
    }

    onDateChange = (date: string): void => {
        this.setState({date, error: false})
        if (date === '' && this.state.time === '')
            this.props.raiseUpdate('CLEAR')
        else if (this.state.time !== '' && moment(date + ' ' + this.state.time, 'YYYY-MM-DD HH:mm').isValid())
            this.props.raiseUpdate(date + ' ' + this.state.time)
    }

    onTimeChange(time: string): void {
        this.setState({time, error: false})
        if (time === '' && this.state.date === '')
            this.props.raiseUpdate('CLEAR')
        else if (this.state.date !== '' && moment(this.state.date + ' ' + time, 'YYYY-MM-DD HH:mm').isValid())
            this.props.raiseUpdate(this.state.date + ' ' + time)
    }

    setDefaultDate(): void {
        this.setState({
            date : moment().add(5, 'minutes').format('YYYY-MM-DD'),
            time : moment().add(5, 'minutes').format('HH:mm'),
            error: false
        })
        this.props.raiseUpdate(moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm'))
    }

    renderNotificationText(): string | undefined {
        if (this.props.allowedNotification && this.props.notificationDate) {
            const now = moment()
            const notifTime = moment(this.props.notificationDate, 'YYYY-MM-DD HH:mm')
            const duration = moment.duration(now.diff(notifTime));
            const time = 21 - Math.round(duration.asMinutes())
            return i18n.management_instant.premium_notification_sended(this.props.notificationDate, time.toString())
        } else if (this.props.notificationDate)
            return i18n.management_instant.notification_sended(this.props.notificationDate)
    }

    isNotificationSent(): boolean {
        if (this.props.isNotificationSent && !this.props.allowedNotification)
            return true
        else if (this.props.isNotificationSent && this.props.allowedNotification && this.state.oldNotificationDate)
            return moment(this.state.oldNotificationDate, 'YYYY-MM-DD HH:mm').add(20, 'minutes').isAfter(moment())
        else
            return false
    }
}
