import { combineEpics, Epic } from "redux-observable";
import { loadOrderEpic } from "../usecases/loadOrder/epic";
import { cancelOrderEpic } from "../usecases/cancelOrder/epic";
import { validateOrderEpic } from "../usecases/validateOrder/epic";

export const clickCollectRootEpics: Epic = combineEpics(
    loadOrderEpic,
    cancelOrderEpic,
    validateOrderEpic
)
