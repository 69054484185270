export const StatsI18n = {
    stats_page_title     : 'VIVEZ ICI | Statistiques',
    inscription          : 'Inscriptions',
    commerces            : 'Commerces',
    associations         : 'Associations',
    entreprises          : 'Entreprises',
    days                 : 'Jours',
    inscription_number   : 'Nombre d\'inscriptions',
    followers_number     : 'Nombre d\'aboonés',
    company_number       : 'Nombre de compte pro',
    clichy_stats         : 'Statistiques',
    companies_list       : 'Liste des comptes Pro',
    commerce             : 'Commerce',
    entreprise           : 'Entreprise',
    association          : 'Association',
    open_website         : 'Voir le site',
    contact_him          : 'Contact-Le',
    chart                : 'Evolution au cours de temps',
    follower             : 'Abonnés',
    visit_transform_rate : 'Evolution du mois dernier',
    increase_rate        : 'Nouveaux abonnés',
    visits_number        : 'Nombre de visites',
    average_visits_number: 'Moyenne des visites',
    instants_number      : 'Instants crées',
    active_instants      : 'Instants Actifs',
};
