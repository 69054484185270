import {
    CREATE_INSTANT,
    CREATE_INSTANT_FAILED,
    CREATE_INSTANT_SUCCEEDED,
    CreateInstantActionTypes
} from "./createInstant.types";
import { CreateInstantState } from "../../configuration/state";

const initialState: CreateInstantState = {
    isLoading        : false,
    error            : undefined,
    success          : null,
}
export const createInstantReducer = (state = initialState, action: CreateInstantActionTypes): CreateInstantState => {
    switch (action.type) {
        case CREATE_INSTANT:
            return {
                isLoading        : true,
                error            : undefined,
                success          : null
            }
        case CREATE_INSTANT_SUCCEEDED:
            return {
                isLoading        : false,
                error            : undefined,
                success          : true
            }
        case CREATE_INSTANT_FAILED:
            return {
                isLoading        : false,
                error            : action.payload,
                success          : false
            }
        default:
            return state
    }
}
