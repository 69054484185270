import React from "react";
import { i18n } from "../../../../../configuration/i18n";
import { TextInput } from "../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeConfigurationBlockType } from "../types";
import { ThemeCheckboxInput } from "./checkbox.input";

interface Props {
    label: string;
    data: ThemeConfigurationBlockType;
    isSubmitted: boolean
    onChange: (key: keyof ThemeConfigurationBlockType, value: string | boolean) => void;
}

export const ClickCollectBlockConfiguration = (props: Props): JSX.Element => {
    return (
        <div>
            <ThemeCheckboxInput label={props.label}
                                checked={props.data.isActive}
                                onChange={(checked: boolean) => props.onChange('isActive', checked)}/>

            {props.data.isActive && (
                <div className={'mt-3'}>
                    <TextInput type={'color'}
                               error={props.isSubmitted && (!props.data.backgroundColor || props.data.backgroundColor.length === 0)}
                               label={i18n.company.background_color}
                               value={props.data.backgroundColor ?? ''}
                               raiseUpdates={(value: string) => props.onChange('backgroundColor', value)}/>
                </div>
            )}
        </div>
    )
}
