import React, { Component, ReactNode } from 'react'
import { i18n } from "../../../../../../configuration/i18n";

interface Props {
    isMonthly: boolean
    onPressMonthly: () => void
    onPressYearly: () => void
}

export class HeaderButtonsSubscription extends Component<Props> {
    render(): ReactNode {
        return (
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item" style={{width: '100%', maxWidth: 300}}>
                    <a className={!this.props.isMonthly ? "nav-link active" : 'nav-link'}
                       onClick={() => this.props.onPressYearly()}>{i18n.payment.yearly_subscription}</a>
                </li>
                <li className="nav-item" style={{width: '100%', maxWidth: 300}}>
                    <a className={this.props.isMonthly ? "nav-link active" : 'nav-link'}
                       onClick={() => this.props.onPressMonthly()}>{i18n.payment.monthly_subscription}</a>
                </li>
            </ul>
        )
    }
}
