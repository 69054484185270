import { CompanyOpeningHours } from "./companyOpeningHours";
import { CompanyContact } from "./companyContact";
import { CompanyAddress } from "./companyAddress";
import { CompanyClickCollect } from "./companyClickCollect";
import { CompanyMembership } from "./companyMembership";
import { CompanyLegalInfo } from "./companyLegalInfo";
import { ThemeConfiguration } from "./ThemeConfiguration";
import { AdministrativeNumber } from "../../../registration/domain/entity/business/administrativeNumber";

export class Company {
    static FREEMIUM: 'freemium' = 'freemium'
    static PREMIUM: 'premium' = 'premium'

    constructor(
        protected _id: string,
        protected _sellerId: string | undefined,
        protected _name: string,
        protected _type: string,
        protected _category: string,
        protected _membership: CompanyMembership,
        protected _administrativeNumber: AdministrativeNumber,
        protected _siren: string,
        protected _coverImage: File,
        protected _profileImage: File,
        protected _cinFront: File,
        protected _cinBack: File,
        protected _coverImageUrl: string,
        protected _profileImageUrl: string,
        protected _followersCount: number,
        protected _openingHours: CompanyOpeningHours[],
        protected _contacts: CompanyContact,
        protected _address: CompanyAddress,
        protected _premiumSubdomain: string,
        protected _clickCollect: CompanyClickCollect,
        protected _legalInfo: CompanyLegalInfo,
        protected _themeConfiguration: ThemeConfiguration
    ) {
    }

    get id(): string {
        return this._id
    }

    get sellerId(): string | undefined {
        return this._sellerId
    }

    get name(): string {
        return this._name
    }

    get type(): string {
        return this._type
    }

    get category(): string {
        return this._category
    }

    get membership(): CompanyMembership {
        return this._membership;
    }

    get address(): CompanyAddress {
        return this._address
    }

    get siren(): string {
        return this._siren
    }

    get coverImage(): File {
        return this._coverImage
    }

    get profileImage(): File {
        return this._profileImage
    }

    get cinFront(): File {
        return this._cinFront;
    }

    get cinBack(): File {
        return this._cinBack;
    }

    get coverImageUrl(): string {
        return this._coverImageUrl
    }

    get administrativeNumber(): AdministrativeNumber {
        return this._administrativeNumber;
    }

    get profileImageUrl(): string {
        return this._profileImageUrl
    }

    get followersCount(): number {
        return this._followersCount;
    }

    get openingHours(): CompanyOpeningHours[] {
        return this._openingHours
    }

    get contacts(): CompanyContact {
        return this._contacts
    }

    get premiumSubdomain(): string {
        return this._premiumSubdomain
    }

    get clickCollect(): CompanyClickCollect {
        return this._clickCollect;
    }

    get legalInfo(): CompanyLegalInfo {
        return this._legalInfo;
    }

    get themeConfiguration(): ThemeConfiguration {
        return this._themeConfiguration;
    }
}
