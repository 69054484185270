import { BroadcastConversation } from '../entities/broadcastConversation';
import { BroadcastMessage } from '../entities/broadcastMessage';

export class BroadcastConversationBuilder {
    protected _id: string
    protected _title: string
    protected _excerpt: string
    protected _updatedAt: Date
    protected _messages: BroadcastMessage[]

    withId(value: string): BroadcastConversationBuilder {
        this._id = value
        return this
    }

    withTitle(value: string): BroadcastConversationBuilder {
        this._title = value
        return this
    }

    withExcerpt(value: string): BroadcastConversationBuilder {
        this._excerpt = value
        return this
    }

    withUpdatedAt(value: Date): BroadcastConversationBuilder {
        this._updatedAt = value
        return this
    }

    withMessages(value: BroadcastMessage[]): BroadcastConversationBuilder {
        this._messages = value
        return this
    }

    build(): BroadcastConversation {
        return new BroadcastConversation(
            this._id,
            this._title,
            this._excerpt,
            this._updatedAt,
            this._messages
        )
    }
}
