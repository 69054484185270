import { StatsCompany } from "../../domain/entity/statsCompany";
import { StatsProfile } from "../../domain/entity/statsProfile";

export const RETRIEVE_SUPER_ADMIN_STATS = 'RETRIEVE_SUPER_ADMIN_STATS'
export const RETRIEVE_SUPER_ADMIN_STATS_SUCCEEDED = 'RETRIEVE_SUPER_ADMIN_STATS_SUCCEEDED'
export const RETRIEVE_SUPER_ADMIN_STATS_FAILED = 'RETRIEVE_SUPER_ADMIN_STATS_FAILED'

export interface RetrieveSuperAdminStatsAction {
    type: typeof RETRIEVE_SUPER_ADMIN_STATS;
    payload: string;
}

export interface RetrieveSuperAdminStatsSucceededAction {
    type: typeof RETRIEVE_SUPER_ADMIN_STATS_SUCCEEDED;
    payload: {
        profiles: StatsProfile[];
        companies: StatsCompany[]
    };
}

export interface RetrieveSuperAdminStatsFailedAction {
    type: typeof RETRIEVE_SUPER_ADMIN_STATS_FAILED;
    payload: string;
}

export type RetrieveStatsActionTypes =
    RetrieveSuperAdminStatsAction
    | RetrieveSuperAdminStatsSucceededAction
    | RetrieveSuperAdminStatsFailedAction
