import {
    SEND_MESSAGE, SEND_MESSAGE_FAILED, SEND_MESSAGE_SUCCEEDED,
    SendMessageAction, SendMessageFailedAction, SendMessageSucceededAction
} from "./actionTypes";

export const sendMessage = (conversationId: string, businessId: string, message: string, image: File | undefined): SendMessageAction => ({
    type   : SEND_MESSAGE,
    payload: {conversationId, businessId, message, image}
})

export const sendMessageSucceeded = (): SendMessageSucceededAction => ({
    type: SEND_MESSAGE_SUCCEEDED
})

export const sendMessageFailed = (error: string): SendMessageFailedAction => ({
    type   : SEND_MESSAGE_FAILED,
    payload: error
})
