import { ClickCollectPicture } from "../types/clickCollectPicture";

export class CompanyClickCollect {
    constructor(
        protected _isActive: boolean,
        protected _description: string,
        protected _pictures: ClickCollectPicture[]
    ) {}

    get active(): boolean {
        return this._isActive;
    }

    get description(): string {
        return this._description;
    }

    get pictures(): ClickCollectPicture[] {
        return this._pictures;
    }
}
