import { FIND_FACEBOOK_PAGE, FIND_FACEBOOK_PAGE_FAILED, FIND_FACEBOOK_PAGE_SUCCEEDED, FindFacebookPageActionTypes } from "./actionTypes";
import { FindSocialPageState } from "../../configuration/state";

const initialState: FindSocialPageState = {
    loading: false,
    error: undefined,
    pageInfo: null
}
export const findFacebookPageReducer = (state = initialState, action: FindFacebookPageActionTypes): FindSocialPageState =>{
    switch (action.type){
        case FIND_FACEBOOK_PAGE:
            return { loading: true, error: undefined, pageInfo: null}
        case FIND_FACEBOOK_PAGE_FAILED:
            return {loading: false, error: action.payload, pageInfo: null}
        case FIND_FACEBOOK_PAGE_SUCCEEDED:
            return {loading: false, error: undefined, pageInfo: action.payload}
        default:
            return state
    }
}
