import React from "react";
import "./marker.css";

const Marker = (props: {lat: number, lng: number}): JSX.Element => {
    return (
        <div className={"marker"} key={props.lat + props.lng}/>
    )
}

export default Marker;
