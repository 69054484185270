import { LoadManagementInstantState } from "../../configuration/state";
import {
    LOAD_MANAGEMENT_INSTANT, LOAD_MANAGEMENT_INSTANT_FAILED,
    LOAD_MANAGEMENT_INSTANT_SUCCEEDED,
    LoadManagementInstantActionsTypes
} from "./loadManagementInstant.types";

const initialState: LoadManagementInstantState= {
    isLoading: false,
    error    : undefined,
    instant  : null
}

export const loadManagementInstantReducer = (state = initialState, action: LoadManagementInstantActionsTypes): LoadManagementInstantState => {
    switch (action.type) {
        case LOAD_MANAGEMENT_INSTANT:
            return {
                isLoading: true,
                error    : undefined,
                instant  : null
            }
        case LOAD_MANAGEMENT_INSTANT_SUCCEEDED:
            return {
                isLoading: false,
                error    : undefined,
                instant  : action.payload
            }
        case LOAD_MANAGEMENT_INSTANT_FAILED:
            return {
                isLoading: false,
                error    : action.payload,
                instant  : null
            }
        default:
            return state
    }
}
