import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadOrderFailed, loadOrderSucceeded } from './actions';
import { ActionsObservable, Epic, ofType, StateObservable } from "redux-observable";
import { LOAD_ORDER, LoadOrderAction } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { Order } from "../../domain/entities/order";
import { ClickCollectService } from "../../domain/gateway/clickCollectService";

export const loadOrderEpic: Epic = (action$: ActionsObservable<LoadOrderAction>, store: StateObservable<AppState>,
                                    {clickCollectService}: { clickCollectService: ClickCollectService }) =>
    action$.pipe(
        ofType(LOAD_ORDER),
        switchMap(action => clickCollectService.loadOrder(action.payload)
            .pipe(
                map((order: Order) => loadOrderSucceeded(order)),
                catchError(err => of(loadOrderFailed(err)))
            )
        )
    )
