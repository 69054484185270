import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { CityHeader } from "../../../../../domain/entity/cityHeader";
import './city.selector.css'
import { Subject } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { connect } from "react-redux";
import { searchCityLoadingSelector, searchedCitiesSelector } from "../../../../../usecases/searchCity/selector";
import { AppState } from "../../../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { searchCity, searchCitySucceeded } from "../../../../../usecases/searchCity/actions";

interface Props {
    error: boolean;
    loading: boolean;
    onChooseCity: (value: CityHeader | null) => void;
    searchedCities: CityHeader[] | null;
    searchCities: (keywords: string) => void;
    resetSearchResult: () => void;
}

interface State {
    inputValue: string
}

class CitySelector extends PureComponent<Props, State> {

    private searchSubject = new Subject<string>()

    constructor(props: Props) {
        super(props);
        this.state = {
            inputValue: '',
        }
        this.searchSubject = new Subject()
    }

    componentDidMount() {
        this.searchSubject.pipe(
            filter(keywords => {
                this.props.onChooseCity(null)
                return keywords !== '' && keywords.length > 2
            }),
            debounceTime(1000)
        )
            .subscribe((keywords) => {
                this.props.searchCities(keywords)
            })
    }

    componentWillUnmount(): void {
        this.searchSubject.unsubscribe()
    }


    render(): ReactNode {
        const error = this.props.error ? 'error select-category' : 'select-category'

        return (
            <div>
                <div className={'registration-input wizard-form-input'}>
                    <label>{i18n.registration.city}</label>
                    <div className={"flex flex-column"}>
                        <input className={error}
                               style={{borderRadius: '5px 5px 0 0'}}
                               placeholder={i18n.registration.search_city}
                               value={this.state.inputValue}
                               onChange={e => {
                                   this.setState({inputValue: e.target.value}, () => {
                                       this.searchSubject.next(e.target.value)
                                   })
                               }}/>
                        {
                            this.displayListContent()
                        }
                    </div>
                </div>
            </div>
        )
    }

    displayListContent(): JSX.Element {
        if (this.props.loading) {
            return (
                <div className="d-flex justify-content-center bg-light">
                    <p className="loader"></p>
                </div>
            )
        } else if (this.props.searchedCities) {
            if (this.props.searchedCities.length > 0) {
                return (
                    <ul className={"w-100 city-list"}>
                        {
                            this.props.searchedCities.map((city: CityHeader, index) => {
                                return <li className="" onClick={() => {
                                    this.setState({inputValue: `${city.name} (${city.zipcode})`})
                                    this.props.onChooseCity(city)
                                    this.props.resetSearchResult()
                                }} key={`${index}${city.zipcode}`}>{city.name} ({city.zipcode})</li>
                            })
                        }
                    </ul>
                )
            } else {
                return <div className="d-flex justify-content-center bg-light">
                    <p className="no-city">{i18n.registration.no_city_found}</p>
                </div>
            }
        } else {
            return <></>
        }
    }
}

interface DispatchToPropsType {
    searchCities: (keywords: string) => void;
    resetSearchResult: () => void;
}

interface StateToPropsType {
    searchedCities: CityHeader[] | null
    loading: boolean
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    searchedCities: searchedCitiesSelector(state),
    loading       : searchCityLoadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    searchCities     : (keywords: string) => dispatch(searchCity(keywords)),
    resetSearchResult: () => dispatch(searchCitySucceeded(null))
})
export default connect(mapStateToProps, mapDispatchToProps)(CitySelector)
