import React, { PureComponent, ReactNode } from 'react';
import { Conversation } from "../../../../domain/entities/conversation";
import { ConversationItem } from "./conversation.item";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    conversations: Conversation[] | null;
    onClick: (conversation: Conversation) => void;
    allowedConversations: number;
    currentConversation: Conversation | null;
}

export class ConversationsList extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className={'conversation-list'}>
                {this.renderText()}

                {this.props.conversations?.map((conversation: Conversation, index: number) => {
                    const isDisabled = this.props.allowedConversations === -1 ? false : this.props.allowedConversations < index + 1
                    return <ConversationItem key={conversation.id}
                                             conversation={conversation}
                                             disabled={isDisabled}
                                             active={conversation.id === this.props.currentConversation?.id}
                                             onClick={() => this.props.onClick(conversation)}/>
                })}
            </div>
        )
    }

    renderText(): JSX.Element | undefined {
        if (this.props.conversations) {
            if (this.props.conversations.length === 0) {
                return <p className={'empty-list'}>{i18n.messaging.empty_conversation_list}</p>
            } else if (this.props.allowedConversations !== -1 && this.props.conversations.length > this.props.allowedConversations) {
                return <a href={'subscription'} className={'membership-text'} >{i18n.messaging.allowed_conversations(this.props.allowedConversations)}</a>
            }
        }
    }
}
