import { Coordinates } from "../../../Coordinates";

export class DistanceCalculatorHelpers {

    private static radiusOfTheEarthInMeter: number = 6371 * 1000
    private static radiusOfTheEarthInKilometer = 6371
    private static earthCircumference =  Math.PI * 2 * 6371 * 1000

    static calculateDistanceInMeter(fromCoordinates: Coordinates, toCoordinates: Coordinates): number {
        return this.roundDistanceInMeter(this.distanceCalculator(fromCoordinates, toCoordinates))
    }

    static formatDistance(fromCoordinates: Coordinates, toCoordinates: Coordinates): string {
        return this.determineDistance(this.distanceCalculator(fromCoordinates, toCoordinates))
    }

    static calculatePixelsPerMeter(latitudeDelta: number, latitude: number): number {
        return Math.round(this.earthCircumference * Math.cos(this.deg2rad(latitude)) / Math.pow(2, this.zoomLevel(latitudeDelta)))
    }

    private static distanceCalculator(fromCoordinates: Coordinates, toCoordinates: Coordinates): number {
        const latitudeDelta = this.deg2rad(toCoordinates.latitude - fromCoordinates.latitude)
        const longitudeDelta = this.deg2rad(toCoordinates.longitude - fromCoordinates.longitude)

        const a = Math.sin(latitudeDelta / 2) *
            Math.sin(latitudeDelta / 2) +
            Math.cos(this.deg2rad(fromCoordinates.latitude)) *
            Math.cos(this.deg2rad(toCoordinates.latitude)) *
            Math.sin(longitudeDelta / 2) *
            Math.sin(longitudeDelta / 2)
        return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    }

    private static roundDistanceInMeter(distance: number): number {
        return Math.round(this.radiusOfTheEarthInMeter * distance)
    }

    private static deg2rad(deg: number): number {
        return deg * (Math.PI / 180)
    }

    private static determineDistance(distance: number): string {
        return this.isMoreThanOneKilometer(distance)
            ? this.formatAboveOneKilometer(distance)
            : this.formatBelowOneKilometer(distance)
    }

    private static isMoreThanOneKilometer(distance: number): boolean {
        return this.radiusOfTheEarthInKilometer * distance >= 1
    }

    private static formatAboveOneKilometer(distance: number): string {
        return Math.round(this.radiusOfTheEarthInKilometer * distance * 10) / 10 + 'km'
    }

    private static formatBelowOneKilometer(distanceInKilometer: number): string {
        return this.roundDistanceInMeter(distanceInKilometer) + 'm'
    }

    private static zoomLevel(latitudeDelta: number): number {
        return Math.round(Math.log(360 / latitudeDelta) / Math.LN2)
    }
}
