import { connect } from 'react-redux';
import { AppState } from "../../../../../../redux-configuration/AppState";
import { Dispatch } from "redux";

import { CreationContainer } from "./creation.container";
import { User } from "../../../../../domain/entity/user";
import {
    signUpErrorSelector,
    signUpSuccessSelector
} from "../../../../../usecases/signUp/selectors";
import { SignUpActionTypes } from "../../../../../usecases/signUp/actionTypes";
import { signUp } from "../../../../../usecases/signUp/actions";
import { loadedBusinessByCodeSelector } from "../../../../../usecases/loadBusinessByCode/selectors";
import { RegisterBusinessActionTypes } from "../../../../../usecases/registerBusiness/actionTypes";
import { registerBusiness } from "../../../../../usecases/registerBusiness/actions";
import {
    registerBusinessErrorSelector,
    registeredBusinessIdSelector
} from "../../../../../usecases/registerBusiness/selectors";
import { Company } from "../../../../../../company/domain/entity/company";

interface StateToPropsType {
    submissionError: string | undefined;
    successSignup: boolean | null;
    businessBySiretCode: Company | null;
    businessId: string | undefined;
}

interface DispatchToPropsType {
    signUpPhoneNumber: (user: User) => void;
    registerBusiness: (business: Company, user: User, prospectEmail: string | null) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    submissionError: signUpErrorSelector(state) || registerBusinessErrorSelector(state),
    successSignup  : signUpSuccessSelector(state),
    businessId     : registeredBusinessIdSelector(state),
    businessBySiretCode : loadedBusinessByCodeSelector(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    signUpPhoneNumber: (user: User): SignUpActionTypes => dispatch(signUp(user)),
    registerBusiness : (business: Company, user: User, originalProspectEmail: string | null): RegisterBusinessActionTypes => dispatch(registerBusiness(business, user, originalProspectEmail))
})

export const Creation = connect(mapStateToProps, mapDispatchToProps)(CreationContainer)
