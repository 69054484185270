import { RegistrationI18n } from "../registration/configuration/fr.i18n";
import { NavigationI18n } from "../navigation/config/navigation.i18n";
import { ManagementInstantInstantI18n } from "../instant-management/configuration/fr.i18n";
import { CompanyI18n } from "../company/configuration/i18n";
import { StatsI18n } from "../stats/configuration/i18n";
import { CommonI18n } from "../common/configuration/i18n";
import { AuthenticationI18n } from "../authentication/configuration/fr.i18n";
import { instantsI18n } from "../instant/configuration/fr.i18n";
import { PaymentI18n } from "../payment/configuration/fr.i18n";
import { MessagingI18n } from "../messaging/configuration/fr.i18n";
import { MunicipalityI18n } from "../municipality/configuration/fr.i18n";
import { EcommerceI18n } from "../ecommerce/configuration/i18n";

export const i18n = {
    registration      : RegistrationI18n,
    navigation        : NavigationI18n,
    company           : CompanyI18n,
    management_instant: ManagementInstantInstantI18n,
    stats             : StatsI18n,
    common            : CommonI18n,
    authentication    : AuthenticationI18n,
    instants          : instantsI18n,
    payment           : PaymentI18n,
    messaging         : MessagingI18n,
    municipality      : MunicipalityI18n,
    ecommerce         : EcommerceI18n
}
