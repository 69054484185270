import React, { PureComponent, ReactNode } from 'react'

interface Props {
    description: string;
    labelButton: string;
    canAdd: boolean;
    onClick: () => void;
}

export class DescriptionTypeInstant extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div className={'content_description_instant'}>
                <div className={'sub-section description'} dangerouslySetInnerHTML={{__html: this.props.description}}/>

                {this.props.canAdd ?
                    <button onClick={(e) => {
                        e.preventDefault()
                        this.props.onClick()
                    }}>{this.props.labelButton}</button>
                    : <div className={'instant-added'}>{this.props.labelButton} <i className="fas fa-check"/></div>
                }
            </div>
        )
    }
}
