import React, { PureComponent, ReactNode } from "react";
import { CheckboxInput } from "../../../../../common/adapters/primaries/form/inputs/checkbox.input";

interface Props {
    checked: boolean
    checkboxLabel: string
    toggleInput: () => void;
    children: JSX.Element | undefined;
}

export class CompanyPremiumCheckbox extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className={'px-3'}>
                <div className="sub-section premium-checkbox">
                    <CheckboxInput checked={this.props.checked}
                                   label={this.props.checkboxLabel}
                                   raiseUpdates={() => this.props.toggleInput()}/>

                    {this.props.checked && this.props.children}
                </div>
            </div>
        )
    }
}
