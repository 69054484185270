import { combineReducers } from 'redux';
import { CompanyState } from "./state";
import { loadCompanyDetailsReducer } from "../usecases/loadCompanyDetails/reducer";
import { updateStoreCompanyReducer } from "../usecases/updateStoreCompany/reducer";
import { editCompanyReducer } from "../usecases/editCompany/reducer";
import { findFacebookPageReducer } from "../usecases/findFacebookPage/reducer";
import { findInstagramAccountReducer } from "../usecases/findInstagramAccount/reducer";
import { loadFollowersReducer } from "../usecases/loadFollowers/reducer";
import { updateSingleFieldReducer } from "../usecases/updateSingleField/reducer";
import { uploadAdvancedImageReducer } from "../usecases/uploadImage/reducer";

export const companyReducer = combineReducers<CompanyState>({
    loadCompanyDetails  : loadCompanyDetailsReducer,
    editCompany         : editCompanyReducer,
    company             : updateStoreCompanyReducer,
    findFacebookPage    : findFacebookPageReducer,
    findInstagramAccount: findInstagramAccountReducer,
    loadFollowers       : loadFollowersReducer,
    updateSingleField   : updateSingleFieldReducer,
    uploadImage         : uploadAdvancedImageReducer
})
