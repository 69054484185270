import {
    LOAD_AGENDA_INSTANTS, LOAD_AGENDA_INSTANTS_FAILED,
    LOAD_AGENDA_INSTANTS_SUCCEEDED,
    LoadAgendaInstantActionTypes
} from "./loadAgendaInstants.types";
import { AgendaInstantState } from "../../configuration/state";

const initialState: AgendaInstantState = {
    loading: false,
    error    : undefined,
    instants : []
}

export const agendaInstantsReducer = (state = initialState, action: LoadAgendaInstantActionTypes): AgendaInstantState => {
    switch (action.type) {
        case LOAD_AGENDA_INSTANTS:
            return {
                ...state,
                loading: true
            }
        case LOAD_AGENDA_INSTANTS_FAILED:
            return {
                ...state,
                loading: false,
                error    : action.payload
            }
        case LOAD_AGENDA_INSTANTS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                instants : action.payload.instants
            }
        default:
            return state
    }
}
