import { Order } from "../../domain/entities/order";

export const VALIDATE_ORDER = 'VALIDATE_ORDER'
export const VALIDATE_ORDER_FAILED = 'VALIDATE_ORDER_FAILED'
export const VALIDATE_ORDER_SUCCEEDED = 'VALIDATE_ORDER_SUCCEEDED'

export interface ValidateOrderAction {
    type: typeof VALIDATE_ORDER;
    payload: {
        order: Order;
        total: string;
    };
}

export interface ValidateOrderFailedAction {
    type: typeof VALIDATE_ORDER_FAILED;
    payload: string;
}

export interface ValidateOrderSucceededAction {
    type: typeof VALIDATE_ORDER_SUCCEEDED;
    payload: string;
}

export type ValidateOrderActionTypes = ValidateOrderAction | ValidateOrderFailedAction | ValidateOrderSucceededAction
