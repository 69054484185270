import {
    VALIDATE_ORDER,
    VALIDATE_ORDER_FAILED,
    VALIDATE_ORDER_SUCCEEDED,
    ValidateOrderActionTypes
} from './actionTypes';
import { ValidateOrderState } from "../../configuration/state";

const initialState: ValidateOrderState = {
    loading: false,
    data   : undefined,
    error  : undefined
}
export const validateOrderReducer = (state = initialState, action: ValidateOrderActionTypes): ValidateOrderState => {
    switch (action.type) {
        case VALIDATE_ORDER:
            return {
                loading: true,
                error  : undefined,
                data   : undefined
            }
        case VALIDATE_ORDER_FAILED:
            return {
                loading: false,
                error  : action.payload,
                data   : undefined
            }
        case VALIDATE_ORDER_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                data   : action.payload
            }
        default:
            return state
    }
}
