import React from "react";
import { i18n } from "../../../../../configuration/i18n";
import { TextInput } from "../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeWelcomeBlockType } from "../types";
import { ThemeLogoInput } from "./logoInput";
import { ValueOf } from "../../../../../common/domain/types/app.categories";
import { ThemeLinkInput } from "./link.input";

interface Props {
    data: ThemeWelcomeBlockType;
    companyId: string;
    isSubmitted: boolean;
    onChange: (key: keyof ThemeWelcomeBlockType, value: ValueOf<ThemeWelcomeBlockType>) => void;
}

export const WelcomeConfiguration = (props: Props): JSX.Element => {
    return (
        <div>
            <TextInput type={'text'}
                       error={props.isSubmitted && (!props.data.subTitle || props.data.subTitle.length === 0)}
                       label={i18n.company.sub_title}
                       value={props.data.subTitle ?? ''}
                       raiseUpdates={(value: string) => props.onChange('subTitle', value)}/>

            <TextInput type={'color'}
                       error={props.isSubmitted && (!props.data.backgroundColor || props.data.backgroundColor.length === 0)}
                       label={i18n.company.background_color}
                       value={props.data.backgroundColor ?? ''}
                       raiseUpdates={(value: string) => props.onChange('backgroundColor', value)}/>

            <ThemeLogoInput type={'video'}
                            previousUrl={props.data.videoUrl ?? ''}
                            companyId={props.companyId}
                            onChange={(value: string) => props.onChange('videoUrl', value)}/>

            <TextInput type={'text'}
                       error={props.isSubmitted && (!props.data.buttonLabel || props.data.buttonLabel.length === 0)}
                       label={i18n.company.button_label}
                       value={props.data.buttonLabel ?? ''}
                       raiseUpdates={(value: string) => props.onChange('buttonLabel', value)}/>

            <ThemeLinkInput data={props.data}
                            error={props.isSubmitted && (!props.data.buttonUrl || props.data.buttonUrl.length === 0)}
                            raiseUpdates={(key: keyof ThemeWelcomeBlockType, value: ValueOf<ThemeWelcomeBlockType>) => props.onChange(key, value)}/>
        </div>
    )
}
