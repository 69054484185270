import { connect } from 'react-redux';
import { SignInForm } from "./signIn.form";
import { Dispatch } from "redux";
import { User } from "../../../../registration/domain/entity/user";
import { AppState } from "../../../../redux-configuration/AppState";
import {
    signUpErrorSelector,
    signUpLoadingSelector,
    signUpSuccessSelector
} from "../../../../registration/usecases/signUp/selectors";
import { SignUpActionTypes } from "../../../../registration/usecases/signUp/actionTypes";
import { signUp } from "../../../../registration/usecases/signUp/actions";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined;
    success: boolean | null;
}

interface DispatchToPropsType {
    signInPhoneNumber: (user: User) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: signUpLoadingSelector(state),
    error  : signUpErrorSelector(state),
    success: signUpSuccessSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    signInPhoneNumber: (user: User): SignUpActionTypes => dispatch(signUp(user))
})

export const SignInContainer = connect(mapStateToProps, mapDispatchToProps)(SignInForm)
