import { Observable, throwError } from 'rxjs';
import { EcommerceOrderService } from "../../../domain/gateway/order.service";
import { EcommerceOrder } from "../../../domain/entities/ecommerceOrder";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { catchError, map } from "rxjs/operators";
import { EcommerceOrderMapper } from "./mapper/ecommerceOrder.mapper";

export class ApiEcommerceOrderService implements EcommerceOrderService {

    getOrders(companyId: string): Observable<EcommerceOrder[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/ecommerce/' + companyId + '/transaction'
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url)
            .pipe(
                map((response) => EcommerceOrderMapper.mapToEcommerceOrders(response.response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

}
