import {
    RETRIEVE_PAYMENT_SESSION,
    RETRIEVE_PAYMENT_SESSION_FAILED, RETRIEVE_PAYMENT_SESSION_SUCCEEDED,
    RetrievePaymentSessionActionTypes
} from "./actionTypes";
import { RetrievePaymentSessionState } from "../../configuration/state";

const initialState: RetrievePaymentSessionState = {
    sessionId: undefined,
    loading: false,
    error: undefined
}

export const retrievePaymentSessionReducer = (state = initialState, action: RetrievePaymentSessionActionTypes): RetrievePaymentSessionState => {
    switch (action.type) {
        case RETRIEVE_PAYMENT_SESSION:
            return {
                sessionId: undefined,
                loading: true,
                error: undefined
            }
        case RETRIEVE_PAYMENT_SESSION_SUCCEEDED:
            return {
                sessionId: action.payload,
                loading: false,
                error: undefined
            }
        case RETRIEVE_PAYMENT_SESSION_FAILED:
            return {
                sessionId: undefined,
                loading: false,
                error: action.payload

            }
        default:
            return state
    }
}
