import { ManagementInstantHeader } from "../../domain/entities/managementInstantHeader";

export const LOAD_PREVIEW_INSTANTS_COMPANY = 'LOAD_PREVIEW_INSTANTS_COMPANY'
export const LOAD_PREVIEW_INSTANTS_COMPANY_SUCCEEDED = 'LOAD_PREVIEW_INSTANTS_COMPANY_SUCCEEDED'
export const LOAD_PREVIEW_INSTANTS_COMPANY_FAILED = 'LOAD_PREVIEW_INSTANTS_COMPANY_FAILED'

export interface LoadPreviewInstantsCompanyAction {
    type: typeof LOAD_PREVIEW_INSTANTS_COMPANY;
    payload: string;
}

export interface LoadPreviewInstantsCompanySucceededAction {
    type: typeof LOAD_PREVIEW_INSTANTS_COMPANY_SUCCEEDED;
    payload: ManagementInstantHeader[];
}

export interface LoadPreviewInstantsCompanyFailedAction {
    type: typeof LOAD_PREVIEW_INSTANTS_COMPANY_FAILED;
    payload: string;
}

export type LoadPreviewInstantsCompanyActionsTypes =
    LoadPreviewInstantsCompanyAction
    | LoadPreviewInstantsCompanySucceededAction
    | LoadPreviewInstantsCompanyFailedAction