import { ApiStatsDTO } from "../dto/apiStatsDTO";
import { StatsCompany } from "../../../../domain/entity/statsCompany";
import { ApplicationContext } from "../../../../../configuration/application.context";
import { StatsProfile } from "../../../../domain/entity/statsProfile";
import { CartesianData } from "../../../../domain/entity/cartesianData";

const moment = ApplicationContext.getInstance().momentJs()

export class StatsMapper {

    static map(apiStats: ApiStatsDTO): { profiles: StatsProfile[], companies: StatsCompany[] } {

        return {
            profiles : apiStats.profiles.map(item => new StatsProfile(item.count, moment(item.creationDate, 'YYYY-MM-DD').toDate())),
            companies: apiStats.companies.map(item => {
                    return new StatsCompany(
                        item.id,
                        item.name,
                        item.type,
                        item.category,
                        item.address,
                        item.icon,
                        item.subdomain,
                        item.email,
                        item.latitude,
                        item.longitude,
                        moment(item.creationDate, 'YYYY-MM-DD').toDate()
                    )
                }
            )
        }
    }

    public static mapToCartesianData(data: Array<{ xValue: string, yValue: number }>): CartesianData[] {
        return data.map((item) => {
            return new CartesianData(item.xValue, item.yValue)
        })
    }
}
