import { combineEpics, Epic } from "redux-observable";
import { listingMunicipalServicesEpic } from "../usecases/listingMunicipalServices/epic";
import { addMunicipalServiceEpic } from "../usecases/addMunicipalService/epic";
import { deleteMunicipalityServiceEpic } from "../usecases/delete/epic";
import { changeServicesOrderEpic } from "../usecases/changeOrder/epic";
import { editMunicipalServiceEpic } from "../usecases/editMunicipalService/epic";

export const municipalityRootEpics: Epic = combineEpics(
    listingMunicipalServicesEpic,
    addMunicipalServiceEpic,
    deleteMunicipalityServiceEpic,
    changeServicesOrderEpic,
    editMunicipalServiceEpic
)
