import { InstantManagementService } from "../domain/gateway/instantManagementService";
import { ApiInstantManagementService } from "../adapters/secondaries/real/apiInstantManagementService";
import { InMemoryInstantManagementService } from "../adapters/secondaries/inMemory/inMemoryInstantManagement.service";

export class managementInstantDependenciesFactory {
    static getInstantManagementService(): InstantManagementService {
        switch (process.env.NODE_ENV) {
            case 'development':
            case 'production':
                return new ApiInstantManagementService()
            default:
                return new InMemoryInstantManagementService()
        }
    }
}