import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RegistrationRepository } from "../../domain/gateway/registration.repository";
import { AppState } from "../../../redux-configuration/AppState";
import { LOAD_LOCAL_BUSINESS, LoadLocalBusinessActionType } from "./actionTypes";
import { loadLocalBusinessFailed, loadLocalBusinessSucceeded } from "./actions";
import { Company } from "../../../company/domain/entity/company";

export const loadLocalBusinessEpic: Epic = (action$: ActionsObservable<LoadLocalBusinessActionType>,
                                            store: StateObservable<AppState>,
                                            { registrationRepository }: { registrationRepository: RegistrationRepository }) =>
    action$.pipe(
        ofType(LOAD_LOCAL_BUSINESS),
        switchMap(() => registrationRepository.getBusiness()
            .pipe(
                map((business: Company) => loadLocalBusinessSucceeded(business)),
                catchError(err => of(loadLocalBusinessFailed(err)))
            )
        )
    )

