import { AdministrativeNumber } from "./administrativeNumber";

export class RNA extends AdministrativeNumber {
    validateNumber(): boolean {
        return new RegExp(/^[w|W][0-9a-zA-Z]{9}$/).test(this._number)
    }

    type(): string {
        return 'RNA'
    }
    length(): number {
        return 10
    }
}