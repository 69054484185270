import { connect } from 'react-redux';
import { AppState } from "../../../../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { sendConfirmationCode } from "../../../../../usecases/confirmation/action";
import {
    SendConfirmationCodeAction,
} from "../../../../../usecases/confirmation/actionTypes";
import { ConfirmationForm } from "./confirmation.form";
import {
    sendConfirmationCodeErrorSelector,
    sendConfirmationCodeLoadingSelector, tokenSelector
} from "../../../../../usecases/confirmation/selectors";
import { companyDetailsSelector } from "../../../../../../company/usecases/loadCompanyDetails/selector";

interface StateToPropsType {
    loading: boolean;
    error: string | undefined;
    successConfirmation: boolean;
    hasBusiness: boolean | null
}

interface DispatchToPropsType {
    sendConfirmationCode: (phoneNumber: string, code: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading            : sendConfirmationCodeLoadingSelector(state),
    error              : sendConfirmationCodeErrorSelector(state),
    successConfirmation: tokenSelector(state) !== undefined,
    hasBusiness        : companyDetailsSelector(state) !== null,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    sendConfirmationCode: (phoneNumber: string, code: string): SendConfirmationCodeAction => dispatch(sendConfirmationCode(phoneNumber, code))
})

export const ConfirmationContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmationForm)
