import { ProductDetails } from "../../../../domain/entities/productDetails";
import { ProductDetailsDTO } from "../dto/productDetails.dto";
import { ProductDetailsBuilder } from "../../../../domain/entities/productDetails.builder";
import { ProductGalleryImage } from "../../../../domain/entities/productGalleryImage";

export class ProductMapper {

    static mapToProductDetails(products: ProductDetailsDTO[]): ProductDetails[] {
        return products.filter(product => product.status !== 'REMOVED')
            .map(product => {
                return new ProductDetailsBuilder()
                    .withId(product.id)
                    .withName(product.name)
                    .withImage(product.featuredImage)
                    .withCategory(product.category)
                    .withPrice(product.price)
                    .withStock(product.stock)
                    .withWeight(product.weight)
                    .withLength(product.length)
                    .withWidth(product.width)
                    .withHeight(product.height)
                    .withDescription(product.description)
                    .withShortDescription(product.shortDescription)
                    .withGallery(product.gallery.map(image => new ProductGalleryImage(image.id, image.url)))
                    .withIsActive(product.status === "ACTIVE")
                    .build()
            })
    }

}
