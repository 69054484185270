import { Coordinates } from "../../Coordinates";
import { DistanceCalculatorHelpers } from "./helpers/distance.helpers";

export class InstantSpaceSpecification {

    static calculateDistanceInMeter(fromCoordinates: Coordinates, toCoordinates: Coordinates): number {
        return DistanceCalculatorHelpers.calculateDistanceInMeter(fromCoordinates, toCoordinates)
    }

    static formatDistance(fromCoordinates: Coordinates, toCoordinates: Coordinates): string {
        return DistanceCalculatorHelpers.formatDistance(fromCoordinates, toCoordinates)
    }
}