import React from "react";

interface Props {
    label: string;
    className?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
}

export const ThemeCheckboxInput = (props: Props): JSX.Element => {
    const className = props.className ?? ""
    return (
        <div className={"form-check " + className}>
            <label>
                <input type="checkbox" className="form-check-input" value=""
                       checked={props.checked}
                       onChange={event => props.onChange(event.target.checked)}/>
                {props.label}
            </label>
        </div>
    )
}
