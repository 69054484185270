import React, { PureComponent, ReactNode } from 'react'
import { FormTimeValidation } from "./time.validation";

interface Props {
    time: string;
    className: string;
    onChange: (time: string) => void;
}

export class TimeInput extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <input className={this.props.className}
                   type={'tel'}
                   placeholder={'--:--'}
                   value={this.props.time}
                   onBlur={(e): void => this.onBlur(e.target.value)}
                   onChange={(e) => this.onChange(e.target.value)}/>
        )
    }

    onBlur(value: string): void {
        if (value.length === 1 && parseInt(value) < 10)
            this.props.onChange('0' + value + ':00')
        else if (value.length === 2 && parseInt(value) > 9)
            this.props.onChange(value + ':00')
        else if (value.length === 3 && (parseInt(value.substr(0, 2)) > 9 || parseInt(value.substr(0, 2)) < 10))
            this.props.onChange(value + '00')
        else if (value.length === 4 && parseInt(value.charAt(3)) < 6)
            this.props.onChange(value + '0')
        else if (value.length === 4 && parseInt(value.charAt(3)) >= 6 && parseInt(value.charAt(3)) < 10)
            this.props.onChange(value.substr(0, 2) + ':0' + value.charAt(3))
    }

    onChange(value: string): void {
        if (value.length < this.props.time.length)
            this.props.onChange(value)
        else if (value.indexOf(' ') === -1) {
            if (value.length === 1) {
                if (FormTimeValidation.isValidHour(value))
                    this.props.onChange(value)
            } else if (value.length === 2) {
                if (FormTimeValidation.isValidHour(value))
                    this.props.onChange(value + ':')
                else if (FormTimeValidation.isValidHour(value.split(':')[0]))
                    this.props.onChange('0' + value)
            } else if (value.length === 3) {
                if (FormTimeValidation.isValidHour(value.substr(0, 2)))
                    this.props.onChange(value.substr(0, 2) + ':' + value.charAt(3))
            } else if ((value.length === 4 || value.length === 5) && value.split(':').length === 2) {
                if (FormTimeValidation.isValidHour(value.split(':')[0]) && FormTimeValidation.isValidMinute(value.split(':')[1]))
                    this.props.onChange(value)
            }
        }

    }
}
