import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../../configuration/i18n";
import { ManagementInstantHeader } from "../../../../../domain/entities/managementInstantHeader";
import { ContentPostType } from "../../../../../domain/entities/types/contentPostType";
import { SocialPageInfo } from "../../../../../../company/domain/entity/socialPageInfo";
import './postFacebookModal.css'
import { PreviewPost } from "./components/previewPost";
import { PostForm } from './components/postForm';

interface Props {
    visible: boolean
    onClose: () => void
    instant: ManagementInstantHeader;
    loading: boolean;
    facebookPages: SocialPageInfo[] | null;
    errorFindFacebookPages: string | undefined;
    postInstant: (page: SocialPageInfo, content: ContentPostType) => void;
    findFacebookPages: () => void
}

interface State {
    checkedFacebook: boolean
    facebookAccount: SocialPageInfo | undefined
    errorMessage: string
    textPost: string
}

export class PostFacebookModalContainer extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            checkedFacebook: false,
            facebookAccount: this.props.facebookPages && this.props.facebookPages.length === 1 ? this.props.facebookPages[0] : undefined,
            textPost       : this.props.instant.title + '\n' + this.props.instant.description,
            errorMessage   : ''
        }

    }


    componentDidUpdate(prevProps: Props): void {
        if (this.props.errorFindFacebookPages === 'NO_PAGE_FOUND')
            this.setState({errorMessage: i18n.management_instant.page_facebook_not_found})
        else if (prevProps.errorFindFacebookPages !== this.props.errorFindFacebookPages && this.props.errorFindFacebookPages === 'NOT_CONNECTED') {
            this.setState({errorMessage: i18n.management_instant.not_connected_facebook})
        }
        if (prevProps.facebookPages !== this.props.facebookPages && this.props.facebookPages)
            this.setState({
                facebookAccount: this.props.facebookPages.length == 1 ? this.props.facebookPages[0] : undefined,
                checkedFacebook         : this.props.facebookPages.length === 1,
                errorMessage   : ''
            })
        if (prevProps.loading && !this.props.loading)
            this.props.onClose()
    }

    render(): ReactNode {
        const loader = this.props.loading && (
            <div className={'show-loader'}>
                <div className={'loader'}/>
            </div>
        )
        if (this.props.visible)
            return (
                <div className="modal default-modal">
                    <div className="modal-dialog modal-post-facebook">
                        <div className="modal-content container_share_modal">
                            <div className="header">
                                <p className={'title'}>{i18n.management_instant.create_post_facebook}</p>
                                <span className={'close-button close_post_modal'} onClick={() => this.props.onClose()}/>
                            </div>
                            <div className="body content_share_modal">
                                {loader}
                                {this.state.errorMessage !== '' ? <div className={'error_share'}>{this.state.errorMessage}</div> : null}
                                {this.state.facebookAccount ?
                                    <PreviewPost facebookAccount={this.state.facebookAccount}
                                                 textPost={this.state.textPost}
                                                 picturePost={this.props.instant.picture}
                                                 changeText={(text) => this.setState({textPost: text})}
                                    />
                                    : <PostForm findSocialPage={this.props.findFacebookPages}
                                                selectedPage={this.state.checkedFacebook}
                                                socialInfo={this.props.facebookPages}
                                                onSelectPage={(checked: boolean, item: SocialPageInfo) => this.setState({checkedFacebook: checked, facebookAccount: checked ? item : undefined})}
                                    />}
                            </div>
                            {this.state.facebookAccount ? <button className={'add-btn btn-share'}
                                                                  onClick={() => this.shareInstant()}>{i18n.management_instant.share_facebook}</button> : null}
                        </div>
                    </div>
                </div>)
        return (<div/>)
    }

    shareInstant(): void {
        if (this.state.checkedFacebook && this.state.facebookAccount)
            this.props.postInstant(this.state.facebookAccount, {
                text : this.state.textPost,
                link : `https://instants.vivezici.fr/?uuid=${this.props.instant.id}`,
                image: this.props.instant.picture
            })
    }
}
