export class InstantWalkTime {

    static formatWalkTime(walkTime: number): string {
        return this.isLessThanAMinute(walkTime)
            ? '1min'
            : this.stringifyHourPart(walkTime) + this.stringifyMinutePart(this.extractMinutesLeft(walkTime))
    }

    private static isLessThanAMinute(walkTime: number): boolean {
        return walkTime <= 60
    }

    private static stringifyHourPart(walkTime: number): string {
        return this.isMoreThanOneHour(walkTime)
            ? Math.floor(walkTime / 3600) + 'h '
            : ''
    }

    private static isMoreThanOneHour(walkTime: number): boolean {
        return walkTime / 3600 >= 1
    }

    private static stringifyMinutePart(walkTime: number): string {
        return Math.floor(walkTime / 60) + 'min'
    }

    private static extractMinutesLeft(walkTime: number): number {
        return this.isMoreThanOneHour(walkTime)
            ? walkTime % 3600
            : walkTime
    }

}
