import { i18n } from "../../../../configuration/i18n";

export type ActivationDayType =
    'fixed'
    | 'all_days'
    | 'mondays_to_fridays'
    | 'mondays_to_saturdays'
    | 'tuesdays_to_saturdays'
    | 'saturdays_and_sundays'
    | 'all_mondays'
    | 'all_tuesdays'
    | 'all_wednesdays'
    | 'all_thursdays'
    | 'all_fridays'
    | 'all_saturdays'
    | 'all_sundays'

export const ActiveDaysNames: { [key: string]: string } = {
    all_days             : i18n.management_instant.all_days,
    mondays_to_fridays   : i18n.management_instant.monday_friday,
    tuesdays_to_fridays  : i18n.management_instant.tuesdays_to_fridays,
    mondays_to_saturdays : i18n.management_instant.mondays_to_saturdays,
    tuesdays_to_saturdays: i18n.management_instant.tuesdays_to_saturdays,
    saturdays_and_sundays: i18n.management_instant.saturday_sunday,
    all_mondays          : i18n.management_instant.mondays,
    all_tuesdays         : i18n.management_instant.tuesdays,
    all_wednesdays       : i18n.management_instant.wednesdays,
    all_thursdays        : i18n.management_instant.thursdays,
    all_fridays          : i18n.management_instant.fridays,
    all_saturdays        : i18n.management_instant.saturdays,
    all_sundays          : i18n.management_instant.sundays
}