import {
     SET_REGISTRATION_STEP,
    SetRegistrationStepAction
} from "./actionTypes";

const initialStep = 0

export const manageRegistrationStepReducer = (state = initialStep, action: SetRegistrationStepAction): number => {
    switch (action.type) {
        case SET_REGISTRATION_STEP:
            return action.payload
        default:
            return state
    }
}
