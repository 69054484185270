import React, { KeyboardEventHandler, PureComponent, ReactNode } from "react";

interface Props {
    type: string
    icon: string
    label: string
    error: boolean
    value: string | undefined
    raiseUpdates: (fileName: string) => void
    maxLength?: number,
    numberOfLines?: number
    className?: string
    onKeyDown?: KeyboardEventHandler
    onBlur?: () => void;
    onIconClick: () => void;
    disabled?: boolean;
    placeholder?: string;
}

export class TextWithIconInput extends PureComponent<Props> {
    render(): ReactNode {
        const className = this.props.error ? 'error' : ''
        const classNameContainer = this.props.className ? this.props.className + ' wizard-form-input' : 'wizard-form-input'
        const isDisabled = this.props.disabled ? ' disabled' : ''

        return (
            <div className={classNameContainer + isDisabled}>
                <label>{this.props.label} &nbsp;<i className={this.props.icon} style={{cursor: 'pointer'}}
                                                   onClick={() => this.props.onIconClick()}></i></label>
                {this.props.numberOfLines ?
                    <textarea rows={this.props.numberOfLines}
                              value={this.props.value ?? ''}
                              className={className}
                              onBlur={this.props.onBlur}
                              onChange={(event) => this.props.raiseUpdates(event.target.value)}>{this.props.value}</textarea>
                    :
                    <input type={this.props.type}
                           disabled={!!this.props.disabled}
                           maxLength={this.props.maxLength}
                           value={this.props.value ?? ''}
                           className={className}
                           placeholder={this.props.placeholder ?? ''}
                           onBlur={this.props.onBlur}
                           onKeyDown={this.props.onKeyDown}
                           onChange={(event) => this.props.raiseUpdates(event.target.value)}/>
                }

            </div>
        )
    }
}
