import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../configuration/i18n";
import { Company } from "../../../../company/domain/entity/company";
import { PaymentFailContent } from "./paymentFail.content";

interface Props {
    company: Company | null
}

export class PaymentFailContainer extends PureComponent<Props> {

    render(): ReactNode {
            return (
                <div>
                    <PaymentFailContent error={i18n.payment.sth_went_wrong}/>
                </div>
            )
    }
}
