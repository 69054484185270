export class ChatUser {
    constructor(
        private  _id: string,
        private  _avatar: string,
        private  _name: string,
        private  _subtitle?: string
    ) {
    }

    get id(): string {
        return this._id;
    }

    get avatar(): string {
        return this._avatar;
    }

    get name(): string {
        return this._name;
    }

    get subtitle(): string | undefined {
        return this._subtitle;
    }

    isCompany(): boolean {
        const regexp = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
        return regexp.test(this._id);
    }
}
