import { ChatMessage } from "../../../domain/entities/chatMessage";

export const LOAD_PREVIOUS_CHAT_MESSAGES = 'LOAD_PREVIOUS_CHAT_MESSAGES'
export const LOAD_PREVIOUS_CHAT_MESSAGES_SUCCEEDED = 'LOAD_PREVIOUS_CHAT_MESSAGES_SUCCEEDED'
export const LOAD_PREVIOUS_CHAT_MESSAGES_FAILED = 'LOAD_PREVIOUS_CHAT_MESSAGES_FAILED'

export interface LoadPreviousChatMessagesAction {
    type: typeof LOAD_PREVIOUS_CHAT_MESSAGES;
    payload: {
        conversationId: string;
        messageId: number
    };
}

export interface LoadPreviousChatMessagesSucceededAction {
    type: typeof LOAD_PREVIOUS_CHAT_MESSAGES_SUCCEEDED;
    payload: ChatMessage[];
}

export interface LoadPreviousChatMessagesFailedAction {
    type: typeof LOAD_PREVIOUS_CHAT_MESSAGES_FAILED;
    payload: string;
}

export type LoadPreviousChatMessagesActionTypes =
    | LoadPreviousChatMessagesAction
    | LoadPreviousChatMessagesSucceededAction
    | LoadPreviousChatMessagesFailedAction
