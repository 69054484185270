import React from "react";

interface Props {
    imageUrl: string;
    title: string;
    removeImage: () => void;
}

export const FoodMenuPreview = (props: Props): JSX.Element => {
    return (
        <div className={'food-menu-preview img-container'}>
            <div className={'d-flex align-items-center justify-content-center flex-column h-100'}>
                <img src={props.imageUrl} alt={"gallery"}/>
                <p>{props.title}</p>
            </div>

            <div className={'img-overlay d-flex align-items-center justify-content-center'}>
                <button type="button"
                        onClick={() => props.removeImage()}>
                    Supprimer
                </button>
            </div>
        </div>
    )
}
