import { Company } from "../../../../domain/entity/company";
import { ThemeWelcomeBlock } from "../../../../domain/entity/ThemeWelcomeBlock";

export class CompanyJSONMapper {

    static mapLegalInformation(company: Company): string {
        return JSON.stringify({
            "companyName"   : company.legalInfo.companyName,
            "legalStatus"   : company.legalInfo.legalStatus,
            "capitalAmount" : company.legalInfo.capitalAmount,
            "address"       : company.legalInfo.address,
            "phoneNumber"   : company.legalInfo.phoneNumber,
            "email"         : company.legalInfo.email,
            "siret"         : company.legalInfo.siret,
            "tva"           : company.legalInfo.tva,
            "registeredName": company.legalInfo.registeredName,
            "others"        : company.legalInfo.others
        })
    }

    static mapThemeConfiguration(company: Company): string {
        return JSON.stringify({
            "general"      : {
                "title"          : company.themeConfiguration.general.title,
                "primary_color"  : company.themeConfiguration.general.primaryColor,
                "secondary_color": company.themeConfiguration.general.secondaryColor,
                "primary_font"   : company.themeConfiguration.general.primaryFont,
                "secondary_font" : company.themeConfiguration.general.secondaryFont,
                "place_id"       : company.themeConfiguration.general.placeId
            },
            "header"       : {
                "title"           : company.themeConfiguration.header.text,
                "text_color"      : company.themeConfiguration.header.textColor,
                "background_color": company.themeConfiguration.header.backgroundColor,
                "images"          : company.themeConfiguration.header.images
            },
            "footer"       : {
                "title"           : company.themeConfiguration.footer.text,
                "text_color"      : company.themeConfiguration.footer.textColor,
                "background_color": company.themeConfiguration.footer.backgroundColor,
                "images"          : company.themeConfiguration.footer.images
            },
            "welcome"      : {
                "sub_title"       : company.themeConfiguration.welcome.text,
                "button_label"    : company.themeConfiguration.welcome.buttonLabel,
                "button_url"      : CompanyJSONMapper.buildUrl(company.themeConfiguration.welcome),
                "video_url"       : company.themeConfiguration.welcome.videoUrl,
                "background_color": company.themeConfiguration.welcome.backgroundColor
            },
            "about"        : {
                "sub_title"       : company.themeConfiguration.about.text,
                "text_color"      : company.themeConfiguration.about.textColor,
                "background_color": company.themeConfiguration.about.backgroundColor,
                "title_color"     : company.themeConfiguration.about.titleColor
            },
            "gallery"      : {
                "active"          : company.themeConfiguration.gallery.isActive,
                "sub_title"       : company.themeConfiguration.gallery.text,
                "text_color"      : company.themeConfiguration.gallery.textColor,
                "background_color": company.themeConfiguration.gallery.backgroundColor,
                "images"          : company.themeConfiguration.gallery.images
            },
            "instants"     : {
                "active"          : company.themeConfiguration.instants.isActive,
                "sub_title"       : company.themeConfiguration.instants.text,
                "text_color"      : company.themeConfiguration.instants.textColor,
                "background_color": company.themeConfiguration.instants.backgroundColor,
                "item_color"      : company.themeConfiguration.instants.itemColor
            },
            "address"      : {
                "active"          : company.themeConfiguration.address.isActive,
                "sub_title"       : company.themeConfiguration.address.text,
                "text_color"      : company.themeConfiguration.address.textColor,
                "background_color": company.themeConfiguration.address.backgroundColor,
                "images"          : company.themeConfiguration.address.images,
                "item_color"      : company.themeConfiguration.address.itemColor
            },
            "review"       : {
                "active"          : company.themeConfiguration.review.isActive,
                "sub_title"       : company.themeConfiguration.review.text,
                "text_color"      : company.themeConfiguration.review.textColor,
                "background_color": company.themeConfiguration.review.backgroundColor
            },
            "contact"      : {
                "active"          : company.themeConfiguration.contact.isActive,
                "sub_title"       : company.themeConfiguration.contact.text,
                "text_color"      : company.themeConfiguration.contact.textColor,
                "background_color": company.themeConfiguration.contact.backgroundColor
            },
            "social_media" : {
                "facebook" : company.themeConfiguration.socialMedia.facebook,
                "instagram": company.themeConfiguration.socialMedia.instagram,
                "tiktok"   : company.themeConfiguration.socialMedia.tiktok,
                "linkedin" : company.themeConfiguration.socialMedia.linkedin
            },
            "food_menu"    : {
                "active"          : company.themeConfiguration.foodMenu.isActive,
                "header_title"    : company.themeConfiguration.foodMenu.headerTitle,
                "title"           : company.themeConfiguration.foodMenu.title,
                "sub_title"       : company.themeConfiguration.foodMenu.subtitle,
                "display_as_icon" : company.themeConfiguration.foodMenu.displayAsIcon,
                "background_color": company.themeConfiguration.foodMenu.backgroundColor,
                "tabs"            : JSON.stringify(company.themeConfiguration.foodMenu.tabs)
            },
            "ecommerce"    : {
                "active"          : company.themeConfiguration.ecommerce.isActive,
                "header_title"    : company.themeConfiguration.ecommerce.headerTitle,
                "title"           : company.themeConfiguration.ecommerce.title,
                "sub_title"       : company.themeConfiguration.ecommerce.subtitle,
                "background_image": company.themeConfiguration.ecommerce.backgroundImage,
                "type"            : company.themeConfiguration.ecommerce.type,
                "payment_options" : JSON.stringify(company.themeConfiguration.ecommerce.paymentOptions),
                "document_url"    : company.themeConfiguration.ecommerce.documentUrl,
                "navigate_to"     : company.themeConfiguration.ecommerce.navigateTo,
            },
            "click_collect": company.themeConfiguration.clickCollect ? {
                "active"          : company.themeConfiguration.clickCollect.isActive,
                "background_color": company.themeConfiguration.clickCollect.backgroundColor
            } : undefined
        })
    }

    private static buildUrl(welcome: ThemeWelcomeBlock): string {

        if (this.hasTargetParameter(welcome.buttonUrl)) {
            if (welcome.openInNewWindow) {
                return welcome.buttonUrl
            } else {
                return this.removeTargetParameter(welcome.buttonUrl)
            }
        } else {
            if (!welcome.openInNewWindow) {
                return welcome.buttonUrl
            } else {
                return this.addTargetParameter(welcome.buttonUrl)
            }
        }
    }

    static hasTargetParameter(url?: string): boolean {
        if (!url) {
            return false;
        }
        try {
            const urlObj = new URL(url);
            const searchParams = urlObj.searchParams;
            return searchParams.has('target') || url.includes('?target=') || url.includes('&target=');
        } catch (e) {
            return false
        }
    }

    static removeTargetParameter(url: string): string {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.delete('target');
            return urlObj.toString();
        } catch (e) {
            return url
        }
    }

    static addTargetParameter(url: string): string {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('target', 'blank');
            return urlObj.toString();
        } catch (e) {
            return url
        }
    }
}
