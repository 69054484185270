import { ContentPostType } from "../../domain/entities/types/contentPostType";
import { SocialPageInfo } from "../../../company/domain/entity/socialPageInfo";

export const POST_INSTANT_ON_INSTAGRAM = 'POST_INSTANT_ON_INSTAGRAM'
export const POST_INSTANT_ON_INSTAGRAM_FAILED ='POST_INSTANT_ON_INSTAGRAM_FAILED'
export const POST_INSTANT_ON_INSTAGRAM_SUCCEEDED ='POST_INSTANT_ON_INSTAGRAM_SUCCEEDED'

export interface PostInstantOnInstagramAction {
    type: typeof POST_INSTANT_ON_INSTAGRAM;
    payload: {page: SocialPageInfo, content: ContentPostType}
}

export interface PostInstantOnInstagramFailedAction {
    type: typeof POST_INSTANT_ON_INSTAGRAM_FAILED;
    payload: string
}
export interface PostInstantOnInstagramSucceededAction {
    type: typeof POST_INSTANT_ON_INSTAGRAM_SUCCEEDED;
}

export type PostInstantOnInstagramActionTypes = PostInstantOnInstagramAction | PostInstantOnInstagramFailedAction | PostInstantOnInstagramSucceededAction
