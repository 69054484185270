import { EcommerceBuyer } from "./ecommerceBuyer";
import { ProductDetails } from "./productDetails";

export class EcommerceOrder {
    constructor(
        private _id: string,
        private _price: number,
        private _transactionStatus: string,
        private _paymentStatus: string,
        private _createdAt: string,
        private _eventUrl: string,
        private _buyer: EcommerceBuyer,
        private _products: ProductDetails[]
    ) {}

    get id(): string {
        return this._id;
    }

    get price(): number {
        return this._price;
    }

    get transactionStatus(): string {
        return this._transactionStatus;
    }

    get paymentStatus(): string {
        return this._paymentStatus;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    get buyer(): EcommerceBuyer {
        return this._buyer;
    }

    get products(): ProductDetails[] {
        return this._products;
    }

    get eventUrl(): string {
        return this._eventUrl;
    }
}
