import React, { PureComponent, ReactNode } from "react";
import { ChatMessage } from "../../../../domain/entities/chatMessage";

interface Props {
    message: ChatMessage
    isBroadcastMessage: boolean;
}

export class CompanyMessage extends PureComponent<Props> {

    render(): ReactNode {
        return (
            <div className={'company-message'}>
                <p dangerouslySetInnerHTML={{ __html: this.getMessageContent() }} />
                {this.props.message.image && <img src={this.props.message.image}/>}
                {this.props.isBroadcastMessage && <span>Message envoyé à mes abonnés</span>}
            </div>
        );
    }

    getMessageContent(): string {
        if (this.props.isBroadcastMessage) {
            const index = this.props.message.content.indexOf('#markdown[')
            return this.props.message.content.slice(0, index)
        }
        return this.props.message.content
    }
}
