import { ProductDetails } from "../../domain/entities/productDetails";

export const EDIT_PRODUCT_STATUS = 'EDIT_PRODUCT_STATUS'
export const EDIT_PRODUCT_STATUS_FAILED = 'EDIT_PRODUCT_STATUS_FAILED'
export const EDIT_PRODUCT_STATUS_SUCCEEDED = 'EDIT_PRODUCT_STATUS_SUCCEEDED'

export interface EditProductStatusAction {
    type: typeof EDIT_PRODUCT_STATUS;
    payload: {
        product: ProductDetails;
        companyId: string;
    }
}

export interface EditProductStatusFailedAction {
    type: typeof EDIT_PRODUCT_STATUS_FAILED;
    payload: string;
}

export interface EditProductStatusSucceededAction {
    type: typeof EDIT_PRODUCT_STATUS_SUCCEEDED;
}

export type EditProductStatusActionTypes = EditProductStatusAction | EditProductStatusFailedAction | EditProductStatusSucceededAction
