import { MunicipalService } from "../../../domain/entities/municipalService";
import { Observable, throwError } from "rxjs";
import { MunicipalityService } from "../../../domain/gateway/municipality.service";
import { ServiceIcon } from "../../../domain/entities/serviceIcon";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjax.httpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/configuration/dependencies.factory";
import { catchError, map } from "rxjs/operators";
import { MunicipalityMapper } from "./mapper/municipalityMapper";
import { MunicipalServiceDto, ServiceIconDto } from "./dto/municipalService.dto";

export class ApiMunicipalityService implements MunicipalityService {

    loadMunicipalServices(cityId: string): Observable<MunicipalService[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/municipality/${cityId}/service`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: MunicipalServiceDto[] }>(url)
            .pipe(
                map((response: { data: MunicipalServiceDto[] }) => MunicipalityMapper.mapMunicipalServiceDtoToMunicipalService(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadServiceIcons(): Observable<ServiceIcon[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/municipality/icons`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).get<{ data: ServiceIconDto[] }>(url)
            .pipe(
                map((response: { data: ServiceIconDto[] }) => MunicipalityMapper.mapServiceIconDtoToServiceIcon(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    addMunicipalService(service: MunicipalService, mairieId: string): Observable<MunicipalService[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/municipality/${mairieId}/service`
        const body = new FormData();
        body.append('type', service.type);
        body.append('label', service.label);
        body.append('content', service.content);
        body.append('iconId', service.icon.id);
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(response => MunicipalityMapper.mapMunicipalServiceDtoToMunicipalService(response.response.data)),
                catchError(err => throwError(err.status.toString()))
            )

    }

    deleteService(serviceId: string): Observable<MunicipalService[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/municipality/service/${serviceId}/delete`
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url)
            .pipe(
                map((response) => MunicipalityMapper.mapMunicipalServiceDtoToMunicipalService(response.response.data)),
                catchError(err => throwError(err))
            )
    }

    changeServicesOrder(mairieId: string, serviceIds: string[]): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/municipality/${mairieId}/service/rearrange`
        const body = new FormData()
        const formattedServiceIds = MunicipalityMapper.mapServicesOrdersDTO(serviceIds)
        body.append('idRankCouples', JSON.stringify(formattedServiceIds))

        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository()).post(url, body).pipe(
            map(() => void 0),
            catchError(err => throwError(err))
        )
    }

    editMunicipalService(service: MunicipalService): Observable<MunicipalService[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/municipality/service/${service.id}/update`
        const body = new FormData();
        body.append('type', service.type);
        body.append('label', service.label);
        body.append('content', service.content);
        body.append('iconId', service.icon.id);
        return new SecuredObservableAjaxHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(response => MunicipalityMapper.mapMunicipalServiceDtoToMunicipalService(response.response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
