import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../../redux-configuration/AppState";
import { StatsCompany } from "../../../domain/entity/statsCompany";
import { StatsContainer } from "./stats.container";
import { StatsProfile } from "../../../domain/entity/statsProfile";
import { Company } from "../../../../company/domain/entity/company";
import { companySelector } from "../../../../company/usecases/updateStoreCompany/selector";
import {
    retrieveSuperAdminStatsCompaniesSelector,
    retrieveSuperAdminStatsProfilesSelector
} from "../../../usecases/retrieveSuperAdminStats/retrieveStats.selectors";
import { RetrieveSuperAdminStatsAction } from "../../../usecases/retrieveSuperAdminStats/retrieveStats.types";
import { retrieveSuperAdminStats } from "../../../usecases/retrieveSuperAdminStats/retrieveStats.actions";

interface StateToPropsType {
    company: Company | null;
    companies: StatsCompany[] | undefined;
    profiles: StatsProfile[] | undefined;
}

interface DispatchToPropsType {
    retrieveStats: (cityCode: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company  : companySelector(state),
    companies: retrieveSuperAdminStatsCompaniesSelector(state),
    profiles : retrieveSuperAdminStatsProfilesSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    retrieveStats: (cityCode: string): RetrieveSuperAdminStatsAction => dispatch(retrieveSuperAdminStats(cityCode))
})

export const Stats = connect(mapStateToProps, mapDispatchToProps)(StatsContainer)
