import {
    SEARCH_CITY,
    SEARCH_CITY_FAILED,
    SEARCH_CITY_SUCCEEDED,
    SearchCityActionTypes
} from './actionTypes';
import { LoadCitiesState } from "../../configuration/state";

const initialState: LoadCitiesState = {
    loading: false,
    cities : null,
    error  : undefined
}

export const searchCityReducer = (state = initialState, action: SearchCityActionTypes): LoadCitiesState => {
    switch (action.type) {
        case SEARCH_CITY:
            return {
                loading: true,
                cities : null,
                error  : undefined
            }
        case SEARCH_CITY_SUCCEEDED:
            return {
                loading: false,
                cities : action.payload,
                error  : undefined
            }
        case SEARCH_CITY_FAILED:
            return {
                loading: false,
                cities : null,
                error  : action.payload
            }
        default:
            return state
    }
}
