import React, { useEffect, useState } from "react";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { i18n } from "../../../../configuration/i18n";
import DataTable from "react-data-table-component";
import { ProductStatusButton } from "./components/status.button";
import "./../ecommerce.css";
import { ProductActionButtons } from "./components/action.buttons";
import { ProductDetails } from "../../../domain/entities/productDetails";
import { Company } from "../../../../company/domain/entity/company";

interface Props {
    company: Company | null;
    products: ProductDetails[] | undefined;
    loading: boolean;
    error: string | undefined;
    loadProducts: (companyId: string) => void;
}

export const ProductsContainer = (props: Props): JSX.Element => {
    const [search, setSearch] = useState<string>("");
    const [products, setProducts] = useState<ProductDetails[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<ProductDetails[]>(
        []
    );

    useEffect(() => {
        if (props.products) {
            setProducts([...props.products]);
            setFilteredProducts([...props.products]);
        }
    }, [props.products]);

    useEffect(() => {
        const result = products.filter((product) =>
            product.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredProducts(result);
    }, [search]);

    useEffect(() => {
        if (props.company) {
            props.loadProducts(props.company.id);
        }
    }, [props.company]);

    useEffect(() => {
        document.title = i18n.ecommerce.all_products_page_title;
    }, []);

    const getImageUrl = (image: string | File): string => {
        return image instanceof File ? URL.createObjectURL(image) : image;
    };

    const onDelete = (productId: string) => {
        const newArr = [...products]
            .map((product) => product.id !== productId ? product : null)
            .filter((item): item is ProductDetails => item !== null)
        setProducts(newArr);
        setTimeout(() => {
            window.location.reload()
        }, 500)
    };

    const columns = [
        {
            name: <i className={"fa fa-image"}/>,
            cell: (row: ProductDetails) => (
                <img className={"icon"} src={getImageUrl(row.image)}/>
            ),
        },
        {
            name    : "Nom du produit",
            selector: (row: ProductDetails) => row.name,
            sortable: true,
        },
        {
            name    : "Stock",
            selector: (row: ProductDetails) => row.stock,
            sortable: true,
        },
        {
            name    : "Prix",
            selector: (row: ProductDetails) =>
                new Intl.NumberFormat("fr-FR", {
                    style   : "currency",
                    currency: "EUR",
                }).format(row.price),
            sortable: true,
        },
        {
            name    : "Statut",
            cell    : (row: ProductDetails) => (
                <ProductStatusButton
                    product={row}
                    companyId={props.company?.id}
                />
            ),
            sortable: true,
        },
        {
            name    : "Catégorie",
            selector: (row: ProductDetails) => row.category,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row: ProductDetails) => (
                <ProductActionButtons
                    productId={row.id}
                    companyId={props.company?.id}
                    onDelete={() => onDelete(row.id)}
                />
            ),
        },
    ];

    const loader = props.loading && (
        <div className={"show-loader"}>
            <div className={"loader"}/>
        </div>
    );

    return (
        <div>
            <DefaultPageHeader title={i18n.common.all_products}/>

            <div className="profile-edition theme-builder ecommerce-page">
                {loader}

                <div className="wizard-form-field mt-3 mb-60 products-table listing-table">
                    <DataTable
                        data={filteredProducts}
                        columns={columns}
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        paginationComponentOptions={{
                            rowsPerPageText   : "Produits par page",
                            rangeSeparatorText: "de",
                        }}
                        highlightOnHover
                        subHeader
                        noDataComponent={
                            <div className="no-data-msg">
                                {i18n.ecommerce.no_products}
                            </div>
                        }
                        subHeaderComponent={
                            <input
                                type={"text"}
                                placeholder={"Chercher un produit"}
                                className={"form-control w-25"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};
