import React from "react";
import { i18n } from "../../../../../configuration/i18n";

interface Props {
    publishInstant: () => void
    setNotificationDate: () => void
    visible: boolean;
    onClose: () => void;
}

export const NotificationDateModal = (props: Props): JSX.Element => {
    if (props.visible)
        return (
            <div className="modal instant-management-modal default-modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="header">
                            <p className={'title'}>{i18n.management_instant.you_want_to_send_notification}</p>
                            <span className={'close-button'} onClick={() => props.onClose()}/>
                        </div>

                        <div className="body">
                            <p dangerouslySetInnerHTML={{__html: i18n.management_instant.notification_description}}/>

                            <div className={'d-flex align-items-center justify-content-end'}>
                                <button className={'default-btn back-btn mr-3'} onClick={() => {
                                    props.publishInstant()
                                    props.onClose()
                                }}>
                                    {i18n.management_instant.no}
                                </button>

                                <button className={'default-btn'} onClick={() => props.setNotificationDate()}>
                                    {i18n.management_instant.yes}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    return <div/>
};
