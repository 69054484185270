export class ServiceIcon {
    constructor(
        private _id: string,
        private _label:string,
        private _url: string
    ) {
    }

    get id(): string {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    get url(): string {
        return this._url;
    }
}
