import {
    EDIT_INSTANT,
    EDIT_INSTANT_FAILED,
    EDIT_INSTANT_SUCCEEDED,
    EditInstantAction, EditInstantFailedAction,
    EditInstantSucceededAction
} from "./editInstant.types";
import { ManagementInstant } from "../../domain/entities/ManagementInstant";

export const editInstant = (instant: ManagementInstant): EditInstantAction => ({
    type   : EDIT_INSTANT,
    payload: instant
})

export const editInstantSucceeded = (): EditInstantSucceededAction => ({
    type: EDIT_INSTANT_SUCCEEDED
})

export const editInstantFailed = (error: string): EditInstantFailedAction => ({
    type   : EDIT_INSTANT_FAILED,
    payload: error
})
