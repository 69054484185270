import React, { PureComponent, ReactNode } from 'react'
import { i18n } from "../../../../../../../configuration/i18n";
import { SocialPageInfo } from "../../../../../../../company/domain/entity/socialPageInfo";

interface Props {
    socialInfo: SocialPageInfo[] | null
    selectedPage: boolean;
    findSocialPage: () => void;
    onSelectPage: (checked: boolean, item: SocialPageInfo) => void;

}

export class PostInstagramForm extends PureComponent<Props> {
    render(): ReactNode {
        const emptyListSocialInfo = this.props.socialInfo && this.props.socialInfo.length === 0 ?
            <div className={'error_share'}>{i18n.management_instant.dont_have_instagram_account}</div> : null
        return (
            <div className="wizard-form-field">
                <div className={'text_login'}>{i18n.management_instant.description_share_instagram}</div>
                {emptyListSocialInfo}
                {this.props.socialInfo ?
                    <div className={'checkbox_share'}>
                        {this.props.socialInfo.map(item =>
                            <div key={item.idPage} className={'account_item'}>
                                <input type="checkbox"
                                       name="day-checkout"
                                       className={'checked-checkbox'}
                                       checked={this.props.selectedPage}
                                       onChange={(event) => this.props.onSelectPage(event.target.checked, item)}
                                />

                                <img src={item.image} className={'account_icon'}/>
                                <span className="checkbox-tick"/>
                                <span className="day-label">{item.name}</span>
                            </div>
                        )}
                    </div>
                    : <button className={'opening-hours-btn add-btn btn_login'} onClick={() => this.props.findSocialPage()}>
                        {i18n.management_instant.login_facebook}
                    </button>}

            </div>
        )
    }
}
