import React, { Component, ReactNode } from "react";
import { i18n } from "../../../../../../configuration/i18n";
import { AppCategories, AssociationCategories } from "../../../../../domain/types/app.categories";
import Select from 'react-select';
import './categories.selector.css'
import { SelectOptionType } from "../../../../../domain/types/selectOption.type";


interface Props {
    onChange: (value: string) => void
    error: boolean
    selected: string
    type: string
    className?: string
}

export class CategoriesSelector extends Component<Props> {

    shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        if (nextProps.selected === '') {
            return false
        }
        return true
    }

    render(): ReactNode {
        const error = this.props.error ? 'error select-category' : 'select-category'
        const classNameContainer = this.props.className ? this.props.className + ' wizard-form-input select-option-area' : 'wizard-form-input select-option-area'

        return (
            <div className={classNameContainer}>
                <label>{i18n.common.category}</label>

                <Select
                    className={error}
                    classNamePrefix="select"
                    isSearchable={true}
                    value={this.getValueSelected(this.props.selected)}
                    options={this.categoriesOptionList()}
                    onChange={selected => {
                        if (selected !== null)
                            this.props.onChange(selected.value)
                    }}
                />
            </div>
        )
    }

    categoriesOptionList(): SelectOptionType[] {
        if (this.props.type === 'association' || this.props.type === 'Association')
            return Object.keys(AssociationCategories).map(category => ({
                label: AssociationCategories[category],
                value: category
            }))
        else
            return Object.keys(AppCategories).map(category => ({label: AppCategories[category], value: category}))
    }

    getValueSelected(value: string): SelectOptionType | undefined {
        return this.categoriesOptionList().find(item => item.value === value)
    }
}
