import { CompanyCoordinates } from "./companyCoordinates";

export class CompanyAddress {
    constructor(
        protected _address: string,
        protected _zipCode: string,
        protected _latitude: string,
        protected _longitude: string,
        protected _city: string
    ) {}

    get address(): string {
        return this._address;
    }

    get zipCode(): string {
        return this._zipCode;
    }

    get latitude(): string {
        return this._latitude;
    }

    get longitude(): string {
        return this._longitude;
    }

    get city(): string {
        return this._city;
    }

    get fullAddress(): string {
        return `${this._address}, ${this._zipCode}, ${this._city}`
    }

    get coordinates(): CompanyCoordinates {
        return {
            longitude: this._longitude,
            latitude: this._latitude
        }
    }
}
