import { combineReducers } from "redux";
import { BroadcastState, MessageState, MessagingState } from "./state";
import { loadPreviousChatMessagesReducer } from "../usecases/message/load/reducer";
import { sendMessageReducer } from "../usecases/message/send/reducer";
import { loadBroadcastConversationReducer } from "../usecases/broadcast/load/reducer";
import { sendBroadcastMessageReducer } from "../usecases/broadcast/send/reducer";
import { broadcastConversationReducer } from "../usecases/broadcast/update/reducer";
import { loadBusinessConversationsReducer } from "../usecases/conversation/reducer";
import { loadNewChatMessagesReducer } from "../usecases/message/loadNewMessages/reducer";

export const messagingRootReducer = combineReducers<MessagingState>({
    message     : combineReducers<MessageState>({
        load       : loadPreviousChatMessagesReducer,
        send       : sendMessageReducer,
        newMessages: loadNewChatMessagesReducer
    }),
    broadcast   : combineReducers<BroadcastState>({
        load                 : loadBroadcastConversationReducer,
        send                 : sendBroadcastMessageReducer,
        broadcastConversation: broadcastConversationReducer
    }),
    conversation: loadBusinessConversationsReducer
})
