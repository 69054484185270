import {
    CANCEL_ORDER,
    CANCEL_ORDER_FAILED,
    CANCEL_ORDER_SUCCEEDED,
    CancelOrderAction,
    CancelOrderFailedAction, CancelOrderSucceeded
} from "./actionTypes";

export const cancelOrder = (orderId: string, message: string): CancelOrderAction => ({
    type   : CANCEL_ORDER,
    payload: {orderId, message}
})

export const cancelOrderFailed = (error: string): CancelOrderFailedAction => ({
    type   : CANCEL_ORDER_FAILED,
    payload: error
})

export const cancelOrderSucceeded = (): CancelOrderSucceeded => ({
    type: CANCEL_ORDER_SUCCEEDED
})
