import {
    UPDATE_BUSINESS,
    UPDATE_BUSINESS_FAILED,
    UPDATE_BUSINESS_SUCCEEDED,
    UpdateBusinessActionTypes
} from "./actionTypes";
import { Company } from "../../../company/domain/entity/company";

export const updateBusiness = (business: Company): UpdateBusinessActionTypes => ({
    type   : UPDATE_BUSINESS,
    payload: business
})

export const updateBusinessSucceeded = (): UpdateBusinessActionTypes => ({
    type: UPDATE_BUSINESS_SUCCEEDED
})

export const updateBusinessFailed = (error: string): UpdateBusinessActionTypes => ({
    type   : UPDATE_BUSINESS_FAILED,
    payload: error
})
