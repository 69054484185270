import {
    FIND_FACEBOOK_PAGE, FIND_FACEBOOK_PAGE_FAILED, FIND_FACEBOOK_PAGE_SUCCEEDED, FindFacebookPageAction, FindFacebookPageFailedAction,
    FindFacebookPageSucceededAction
} from "./actionTypes";
import { SocialPageInfo } from "../../domain/entity/socialPageInfo";

export const findFacebookPage = (): FindFacebookPageAction => ({
    type   : FIND_FACEBOOK_PAGE
})

export const findFacebookPageFailed = (error: string): FindFacebookPageFailedAction => ({
    type   : FIND_FACEBOOK_PAGE_FAILED,
    payload: error
})

export const findFacebookPageSucceeded = (pagesInfo: SocialPageInfo[]): FindFacebookPageSucceededAction => ({
    type   : FIND_FACEBOOK_PAGE_SUCCEEDED,
    payload: pagesInfo
})
