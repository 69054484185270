import React, { PureComponent, ReactNode } from "react";
import { i18n } from "../../../../../configuration/i18n";
import { ApplicationContext } from "../../../../../configuration/application.context";
import { TimeInput } from "../../../../../common/adapters/primaries/form/inputs/time/time.input";
import { Order } from "../../../../../click-collect/domain/entities/order";
import { OrderBuilder } from "../../../../../click-collect/domain/builders/order.builder";

interface Props {
    order: Order | null;
    visible: boolean;
    onClose: () => void;
    success: string | undefined;
    loading: boolean;
    error: string | undefined;
    validateOrder: (order: Order, total: string) => void
}

interface State {
    date: string;
    time: string;
    message: string;
    price: string;
    errorMsg: string | undefined;
}

const moment = ApplicationContext.getInstance().momentJs()

export class ValidateOrderModal extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            price   : '',
            message : '',
            date    : moment().format('YYYY-MM-DD'),
            time    : moment().format('HH:mm'),
            errorMsg: undefined
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.order && this.props.order !== prevProps.order) {
            this.setState({
                price  : this.props.order.minOrder,
                message: this.props.order.content,
                date   : moment(this.props.order.time).format('YYYY-MM-DD'),
                time   : moment(this.props.order.time).format('HH:mm')
            })
        }

        if (this.props.error && this.props.error !== prevProps.error) {
            this.handleErrorMsg(this.props.error)
        }

        if (this.props.success && this.props.success !== prevProps.success) {
            this.closeModal()
        }
    }

    render(): ReactNode {
        if (this.props.visible)
            return (
                <div className="modal order-modal default-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="header">
                                <h3 className={'title'}>{i18n.messaging.order_validation}</h3>
                                <span className={'close-button'} onClick={() => this.closeModal()}/>
                            </div>

                            <div className={'body'}>
                                <div className="order-date mb-3">
                                    <label className={'mr-2'}>{i18n.messaging.date}</label>
                                    <input type="date" value={this.state.date}
                                           onChange={(event): void => this.setState({
                                               date: event.target.value
                                           })}/>

                                    <label className="mx-2">{i18n.messaging.to}</label>
                                    <TimeInput time={this.state.time} className={''}
                                               onChange={(time: string): void => this.setState({time})}/>
                                </div>

                                <div className="order-date mb-3">
                                    <label className={'mr-2'}>{i18n.messaging.price}</label>

                                    <input value={this.state.price} type={'number'}
                                           onChange={(event): void => this.setState({price: event.target.value})}/>
                                </div>

                                <label>{i18n.messaging.validate_msg}</label>
                                <textarea rows={3}
                                          className={'w-100'}
                                          value={this.state.message}
                                          onChange={e => this.setState({message: e.target.value})}/>

                                {this.props.loading && (
                                    <div className={'modal-loader'}>
                                        <div className={'loader'}/>
                                    </div>
                                )}

                                {this.state.errorMsg && <p className={'error-msg'}>{this.state.errorMsg}</p>}

                                <div className={'d-flex justify-content-end mt-3'}>
                                    <button className={'default-btn back-btn mr-3'} onClick={() => this.closeModal()}>
                                        {i18n.messaging.back}
                                    </button>

                                    <button className={'default-btn'} onClick={() => this.onSubmit()}>
                                        {i18n.messaging.send}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        return <div/>
    }

    onSubmit(): void {
        if (this.props.order && this.validateForm()) {
            const order = new OrderBuilder()
                .withId(this.props.order.id)
                .withContent(this.state.message)
                .withTime(this.getOrderDateTime())
                .build()
            this.props.validateOrder(order, this.state.price)
        }
    }

    validateForm(): boolean {
        const orderTime = moment(this.state.date + this.state.time, 'YYYY-MM-DDHH:mm', true)
        if (this.state.date.length === 0 || this.state.time.length === 0 || this.state.message.length === 0 || this.state.price.length === 0) {
            this.setState({errorMsg: i18n.management_instant.error_empty_input})
            return false
        } else if (!orderTime.isValid()) {
            this.setState({errorMsg: i18n.management_instant.date_format_error})
            return false
        } else if (orderTime.isBefore(moment())) {
            this.setState({errorMsg: i18n.management_instant.end_date_error})
            return false
        } else
            return true
    }

    getOrderDateTime(): string {
        const dateTime = this.state.date + ' ' + this.state.time
        return moment(dateTime, 'YYYY-MM-DD HH:mm', true).format()
    }

    handleErrorMsg(error: string): void {
        if (error === '409') {
            this.setState({errorMsg: i18n.messaging.error_message_409})
        } else if (error === '404') {
            this.setState({errorMsg: i18n.messaging.error_message_404})
        } else if (error === '401') {
            this.setState({errorMsg: i18n.messaging.error_message_401})
        }
    }

    closeModal(): void {
        this.props.onClose()
        this.setState({errorMsg: undefined})
    }
}
