import React, { PureComponent, ReactNode } from "react";
import { ChatMessage } from "../../../../domain/entities/chatMessage";
import { MessageItem } from "./message.item";
import { BroadcastMessage } from "../../../../domain/entities/broadcastMessage";
import { MessagingSpecifications } from "../../specifications";
import { BroadcastMessagesList } from "../broadcast/broadcastMessages.list";

interface Props {
    loading: boolean;
    isBroadcastMsg: boolean;
    messages: ChatMessage[]
    broadcastMessages: BroadcastMessage[];
    loadPreviousMessages: () => void;
}

export class MessagesList extends PureComponent<Props> {

    render(): ReactNode {
        if (this.props.isBroadcastMsg) {
            return <BroadcastMessagesList broadcastMessages={this.props.broadcastMessages}/>
        }
        return (
            <div className={'message-list d-flex flex-column-reverse'}
                 onScroll={(e) => this.loadPreviousMessages(e)}>

                {this.renderLoading()}

                {this.props.messages.map((message: ChatMessage, index: number) =>
                    this.renderMessageItem(message, index))}
            </div>
        );
    }

    renderMessageItem(message: ChatMessage, index: number): JSX.Element {
        const previousMessageDate = this.props.messages[index + 1]?.createdAt;

        if (MessagingSpecifications.shouldRenderDate(previousMessageDate, message.createdAt)) {
            return (
                <div className={'message-container d-flex flex-column'} key={message.id}>
                    <p className={'date text-center w-100'}>{MessagingSpecifications.formatDate(message.createdAt)}</p>
                    <MessageItem message={message}/>
                </div>
            )
        } else {
            return <MessageItem key={message.id} message={message}/>
        }
    }

    renderLoading(): JSX.Element | undefined {
        if (this.props.loading && this.props.messages.length === 0) {
            return (
                <div className={'d-flex align-items-center justify-content-center h-100'}>
                    <div className={'loader'}/>
                </div>
            )
        }
    }

    loadPreviousMessages(e: React.UIEvent<HTMLElement>): void {
        const scrollTop = e.currentTarget.scrollTop + e.currentTarget.scrollHeight - e.currentTarget.clientHeight

        if (scrollTop < 2) {
            this.props.loadPreviousMessages()
        }
    }
}
