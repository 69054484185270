import React, { useEffect, useState } from "react";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { i18n } from "../../../../configuration/i18n";
import DataTable from "react-data-table-component";
import { OrderTransactionStatus } from "./components/transaction.status";
import "./../ecommerce.css";
import { Company } from "../../../../company/domain/entity/company";
import { EcommerceOrder } from "../../../domain/entities/ecommerceOrder";
import { OrderPaymentStatus } from "./components/payment.status";
import { useHistory } from "react-router-dom";

interface Props {
    company: Company | null;
    orders: EcommerceOrder[] | undefined;
    loading: boolean;
    error: string | undefined;
    loadOrders: (companyId: string) => void;
}

export const EcommerceOrdersContainer = (props: Props): JSX.Element => {
    const history = useHistory();

    const [search, setSearch] = useState<string>("");
    const [orders, setOrders] = useState<EcommerceOrder[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<EcommerceOrder[]>([]);

    useEffect(() => {
        if (props.orders) {
            setOrders([...props.orders]);
            setFilteredOrders([...props.orders]);
        }
    }, [props.orders]);

    useEffect(() => {
        const result = orders.filter((order) => {
            const productsNames =
                order.products?.map((item) => item.name).join(", ") ?? "";
            return productsNames.toLowerCase().includes(search.toLowerCase());
        });
        setFilteredOrders(result);
    }, [search]);

    useEffect(() => {
        if (props.company) {
            props.loadOrders(props.company.id);
        }
    }, [props.company]);

    useEffect(() => {
        document.title = i18n.ecommerce.all_orders_page_title;
    }, []);

    const columns = [
        {
            name: "Date de création",
            selector: (row: EcommerceOrder) => row.createdAt,
            sortable: true,
        },
        {
            name: "Produits",
            selector: (row: EcommerceOrder) =>
                row.products?.map((item) => item.name).join(", ") ?? "",
            sortable: true,
        },
        {
            name: "Montant",
            selector: (row: EcommerceOrder) =>
                new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                }).format(row.price),
            sortable: true,
        },
        {
            name: "Statut transaction",
            cell: (row: EcommerceOrder) => (
                <OrderTransactionStatus
                    order={row}
                    companyId={props.company?.id}
                />
            ),
            sortable: true,
        },
        {
            name: "Statut paiement",
            cell: (row: EcommerceOrder) => (
                <OrderPaymentStatus order={row} companyId={props.company?.id} />
            ),
            sortable: true,
        },
    ];

    const loader = props.loading && (
        <div className={"show-loader"}>
            <div className={"loader"} />
        </div>
    );

    return (
        <div>
            <DefaultPageHeader title={i18n.ecommerce.all_my_orders} />

            <div className="profile-edition theme-builder ecommerce-page">
                {loader}

                <div className="wizard-form-field mt-3 mb-60 orders-table listing-table">
                    <DataTable
                        data={filteredOrders}
                        columns={columns}
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        paginationComponentOptions={{
                            rowsPerPageText: "Commandes par page",
                            rangeSeparatorText: "de",
                        }}
                        highlightOnHover
                        subHeader
                        noDataComponent={
                            <div className="no-data-msg">
                                {i18n.ecommerce.no_orders}
                            </div>
                        }
                        subHeaderComponent={
                            <input
                                type={"text"}
                                placeholder={"Chercher une commande"}
                                className={"form-control w-25"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                        onRowClicked={(row: EcommerceOrder) =>
                            history.push("/transaction/" + row.id)
                        }
                    />
                </div>
            </div>
        </div>
    );
};
