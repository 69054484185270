export class FormTimeValidation {

    static isValidHour(value: string): boolean {
        const hour = Number(value)
        return Number.isInteger(hour) && hour >= 0 && hour < 24;
    }

    static isValidMinute(value: string): boolean {
        const minutes = Number(value)
        return Number.isInteger(minutes) && minutes >= 0 && minutes < 60;
    }

}
