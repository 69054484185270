import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../../../usecases/deleteProduct/actions";

interface Props {
    productId: string;
    companyId: string | undefined;
    onDelete: () => void;
}

export const ProductActionButtons = (props: Props): JSX.Element => {

    const history = useHistory();
    const dispatch = useDispatch()

    const editProductById = () => {
        history.push('/edit-product/' + props.productId);
    }

    const deleteProductById = () => {
        if (props.companyId) {
            if (confirm('êtes vous sûr de vouloir supprimer cet article ?')) {
                props.onDelete()
                dispatch(deleteProduct(props.productId, props.companyId))
            }
        }
    }

    return (
        <div className={'action-buttons'}>
            <button className={'btn edit-product-btn'} onClick={() => editProductById()}><i className={'fa fa-edit'}/></button>
            <button className={'btn delete-product-btn'} onClick={() => deleteProductById()}><i className={'fa fa-trash-alt'}/></button>
        </div>
    )

}
