export class ThemeFoodMenu {
    constructor(
        protected _isActive: boolean,
        protected _headerTitle: string,
        protected _title: string,
        protected _subtitle: string,
        protected _backgroundColor: string,
        protected _displayAsIcon: boolean,
        protected _tabs: Array<{ title: string; url: string, icon: string }>
    ) {
    }

    get isActive(): boolean {
        return this._isActive;
    }

    get headerTitle(): string {
        return this._headerTitle;
    }

    get title(): string {
        return this._title;
    }

    get subtitle(): string {
        return this._subtitle;
    }

    get backgroundColor(): string {
        return this._backgroundColor;
    }

    get displayAsIcon(): boolean {
        return this._displayAsIcon;
    }

    get tabs(): Array<{ title: string; url: string; icon: string }> {
        return this._tabs;
    }
}
