import React from "react";
import { TextInput } from "../../../../../common/adapters/primaries/form/inputs/text.input";
import { ThemeSocialMediaType } from "../types";

interface Props {
    data: ThemeSocialMediaType;
    isSubmitted: boolean
    onChange: (key: keyof ThemeSocialMediaType, value: string) => void;
}

export const SocialMediaConfiguration = (props: Props): JSX.Element => {
    return (
        <div>
            <TextInput type={'text'}
                       error={false}
                       label={'Facebook'}
                       value={props.data.facebook}
                       raiseUpdates={(value: string) => props.onChange('facebook', value)}/>

            <TextInput type={'text'}
                       error={false}
                       label={'Instagram'}
                       value={props.data.instagram}
                       raiseUpdates={(value: string) => props.onChange('instagram', value)}/>

            <TextInput type={'text'}
                       error={false}
                       label={'TikTok'}
                       value={props.data.tiktok}
                       raiseUpdates={(value: string) => props.onChange('tiktok', value)}/>

            <TextInput type={'text'}
                       error={false}
                       label={'LinkedIn'}
                       value={props.data.linkedin}
                       raiseUpdates={(value: string) => props.onChange('linkedin', value)}/>
        </div>
    )
}
