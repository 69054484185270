import { ManagementInstantHeader } from "../../domain/entities/managementInstantHeader";

export const LOAD_INSTANTS_COMPANY = 'LOAD_INSTANTS_COMPANY'
export const LOAD_INSTANTS_COMPANY_SUCCEEDED = 'LOAD_INSTANTS_COMPANY_SUCCEEDED'
export const LOAD_INSTANTS_COMPANY_FAILED = 'LOAD_INSTANTS_COMPANY_FAILED'

export interface LoadInstantsCompanyAction {
    type: typeof LOAD_INSTANTS_COMPANY;
    payload: string;
}

export interface LoadInstantsCompanySucceededAction {
    type: typeof LOAD_INSTANTS_COMPANY_SUCCEEDED;
    payload: ManagementInstantHeader[];
}

export interface LoadInstantsCompanyFailedAction {
    type: typeof LOAD_INSTANTS_COMPANY_FAILED;
    payload: string;
}

export type LoadInstantsCompanyActionsTypes =
    LoadInstantsCompanyAction
    | LoadInstantsCompanySucceededAction
    | LoadInstantsCompanyFailedAction