import {
    RETRIEVE_COMPANY_STATS, RETRIEVE_COMPANY_STATS_FAILED, RETRIEVE_COMPANY_STATS_SUCCEEDED,
    RetrieveCompanyStatsAction, RetrieveCompanyStatsFailedAction,
    RetrieveCompanyStatsSucceededAction
} from "./retrieveCompanyStats.types";
import { CartesianData } from "../../domain/entity/cartesianData";

export const retrieveCompanyStats = (companyId: string): RetrieveCompanyStatsAction => ({
    type   : RETRIEVE_COMPANY_STATS,
    payload: companyId
})

export const retrieveCompanyStatsSucceeded = (visits: CartesianData[], followers: CartesianData[]): RetrieveCompanyStatsSucceededAction => ({
    type   : RETRIEVE_COMPANY_STATS_SUCCEEDED,
    payload: {visits, followers}
})

export const retrieveCompanyStatsFailed = (error: string): RetrieveCompanyStatsFailedAction => ({
    type   : RETRIEVE_COMPANY_STATS_FAILED,
    payload: error
})
