import { MunicipalService } from "../../domain/entities/municipalService";
import {
    EDIT_MUNICIPAL_SERVICE, EDIT_MUNICIPAL_SERVICE_FAILED, EDIT_MUNICIPAL_SERVICE_SUCCEEDED, EditMunicipalServiceAction,
    EditMunicipalServiceFailedAction, EditMunicipalServiceSucceededAction
} from "./actionTypes";

export const editMunicipalService = (service: MunicipalService): EditMunicipalServiceAction =>({
    type: EDIT_MUNICIPAL_SERVICE,
    payload: service
})

export const editMunicipalServiceFailed = (error: string): EditMunicipalServiceFailedAction =>({
    type: EDIT_MUNICIPAL_SERVICE_FAILED,
    payload: error
})
export const editMunicipalServiceSucceeded = (): EditMunicipalServiceSucceededAction =>({
    type: EDIT_MUNICIPAL_SERVICE_SUCCEEDED,
})
