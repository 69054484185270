import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap, concatMap } from 'rxjs/operators';
import { DELETE_INSTANT, DeleteInstantAction } from "./delateInstant.types";
import { AppState } from "../../../redux-configuration/AppState";
import { deleteInstantFailed, deleteInstantSucceeded } from "./deleteInstant.actions";
import { InstantManagementService } from "../../domain/gateway/instantManagementService";
import { loadInstantsCompany } from "../loadInstantsCompany/loadInstantsCompany.actions";

export const deleteInstantEpic: Epic = (action$: ActionsObservable<DeleteInstantAction>,
                                         store: StateObservable<AppState>,
                                         { instantManagementService }: { instantManagementService: InstantManagementService }) =>
    action$.pipe(
        ofType(DELETE_INSTANT),
        switchMap(action => instantManagementService.deleteInstant(action.payload.instantId)
            .pipe(
                concatMap(() => [
                    deleteInstantSucceeded(),
                    loadInstantsCompany(action.payload.companyId)
                ]),
                catchError(err => of(deleteInstantFailed(err)))
            )
        )
    )
