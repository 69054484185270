import React, { PureComponent, ReactNode } from 'react'
import { Company } from "../../../../domain/entity/company";
import { i18n } from "../../../../../configuration/i18n";
import { QRCode } from "react-qrcode-logo";
import LogoWeb from "../../../../../assets/img/LogoWeb.png";

interface Props {
    company: Company | null;
}

export class FoodMenuQrCode extends PureComponent<Props> {
    render(): ReactNode {
        console.log(this.getWebLink())
        return (
            <div className={'company_tab_container my-3'}>
                <div className={'row'}>
                    <div className={'col-12 col-md-7 col-xl-8 p-4'} dangerouslySetInnerHTML={{__html: i18n.company.description_qrcode_foodMenu}}/>

                    <div className={'col-12 col-md-5 col-xl-4 text-center mt-4'}>
                        <QRCode id="foodMEnuQR"
                                size={225}
                                value={this.getWebLink()}
                                logoImage={LogoWeb}
                                logoWidth={60}
                                logoHeight={60}
                                bgColor={'#363B7A'}
                                fgColor={'#FFFFFF'}/>
                        <a onClick={(e) => {
                            e.preventDefault()
                            this.downloadWebQR()
                        }} className={'link'}>{i18n.company.download}</a>
                    </div>
                </div>
            </div>
        )
    }

    getWebLink(): string {
        return `https://www.${this.props.company?.themeConfiguration.general.website}#documents`
    }

    downloadWebQR(): void {
        const canvas = document.getElementById('webQR') as HTMLCanvasElement
        if (canvas) {
            const qrCodeURL = canvas.toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            const aEl = document.createElement("a");
            aEl.href = qrCodeURL;
            aEl.download = "QR_Code_Menu_" + this.props.company?.id + ".png";
            document.body.appendChild(aEl);
            aEl.click();
            document.body.removeChild(aEl);
        }
    }
}
