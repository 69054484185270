import { ChatMessage } from '../../../domain/entities/chatMessage';
import {
    LOAD_NEW_CHAT_MESSAGES, LOAD_NEW_CHAT_MESSAGES_FAILED, LOAD_NEW_CHAT_MESSAGES_SUCCEEDED,
    LoadNewChatMessagesAction, LoadNewChatMessagesFailedAction, LoadNewChatMessagesSucceededAction
} from "./actionTypes";

export const loadNewChatMessages = (conversationId: string, messageId: number): LoadNewChatMessagesAction => ({
    type   : LOAD_NEW_CHAT_MESSAGES,
    payload: {conversationId, messageId}
})

export const loadNewChatMessagesSucceeded = (chatMessages: ChatMessage[]): LoadNewChatMessagesSucceededAction => ({
    type   : LOAD_NEW_CHAT_MESSAGES_SUCCEEDED,
    payload: chatMessages
})

export const loadNewChatMessagesFailed = (error: string): LoadNewChatMessagesFailedAction => ({
    type   : LOAD_NEW_CHAT_MESSAGES_FAILED,
    payload: error
})
