import React, { useEffect, useState } from "react";
import { DefaultPageHeader } from "../../../../common/adapters/primaries/defaultPage.header";
import { i18n } from "../../../../configuration/i18n";
import { ProductDetails } from "../../../domain/entities/productDetails";
import { ProductDetailsType } from "../types";
import { ProductDetailsBuilder } from "../../../domain/entities/productDetails.builder";
import { ValueOf } from "../../../../common/domain/types/app.categories";
import { ProductFormContainer } from "../components/form";
import { useHistory, useParams } from 'react-router-dom';
import { Company } from "../../../../company/domain/entity/company";

interface Props {
    products: ProductDetails[] | undefined;
    company: Company | null;
    loading: boolean;
    success: boolean | undefined;
    error: string | undefined;
    editProduct: (product: ProductDetails, companyId: string) => void;
    loadProducts: (companyId: string) => void;
    reset: () => void;
}

export const EditProductContainer = (props: Props): JSX.Element => {

    const history = useHistory();
    const params: { id: string } = useParams();

    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
    const [productDetails, setProductDetails] = useState<ProductDetailsType>({
        name            : "",
        image           : undefined,
        gallery         : [],
        category        : "",
        price           : "",
        stock           : "",
        weight          : '',
        length          : '',
        width           : '',
        height          : '',
        description     : "",
        shortDescription: ""
    })

    const getProductToBeEdited = () => {
        const product = props.products?.find((product: ProductDetails) => product.id.toString() === params.id)
        if (product) {
            setProductDetails({
                name            : product.name,
                image           : product.image,
                gallery         : product.gallery,
                category        : product.category,
                price           : product.price.toString(),
                stock           : product.stock.toString(),
                weight          : product.weight.toString(),
                length          : product.length.toString(),
                width           : product.width.toString(),
                height          : product.height.toString(),
                description     : product.description,
                shortDescription: product.shortDescription
            })
        }
    }

    useEffect(() => {
        document.title = i18n.ecommerce.edit_product_page_title;
        getProductToBeEdited()
    }, [])

    useEffect(() => {
        if (!props.products && props.company) {
            props.loadProducts(props.company.id)
        }
    }, [props.company])

    useEffect(() => {
        getProductToBeEdited()
    }, [props.products])

    useEffect(() => {
        if (props.success) {
            props.reset()
            history.push('/products');
        }
    }, [props.success])

    useEffect(() => {
        if (props.error === "500") {
            setErrorMsg("Server error")
        }
    }, [props.error])

    const isValidForm = () => {
        const weight = parseFloat(productDetails.weight);
        const length = parseFloat(productDetails.length);
        const width = parseFloat(productDetails.width);
        const height = parseFloat(productDetails.height);

        if (productDetails.name.length === 0 || productDetails.category.length === 0 ||
            productDetails.price.length === 0 || productDetails.stock.length === 0 ||
            productDetails.shortDescription.length === 0 || productDetails.description.length === 0 ||
            !productDetails.image ||
            weight <= 0 || isNaN(weight) ||
            length <= 0 || isNaN(length) ||
            width <= 0 || isNaN(width) ||
            height <= 0 || isNaN(height)
        ) {

            setErrorMsg(i18n.ecommerce.empty_form_error)
            return false
        }
        return true
    }

    const editProduct = () => {
        if (props.company && isValidForm()) {
            const newProduct = new ProductDetailsBuilder()
                .withId(params.id)
                .withName(productDetails.name)
                .withCategory(productDetails.category)
                .withImage(productDetails.image ?? "")
                .withPrice(+productDetails.price)
                .withStock(+productDetails.stock)
                .withWeight(+productDetails.weight)
                .withLength(+productDetails.length)
                .withWidth(+productDetails.width)
                .withHeight(+productDetails.height)
                .withDescription(productDetails.description)
                .withShortDescription(productDetails.shortDescription)
                .withGallery(productDetails.gallery)
                .build()

            props.editProduct(newProduct, props.company.id)
        }
    }

    const loader = props.loading && (
        <div className={'show-loader'}>
            <div className={'loader'}/>
        </div>
    )

    return (
        <div>
            <DefaultPageHeader title={i18n.common.edit_product}/>

            <div className="profile-edition theme-builder">
                {loader}

                <ProductFormContainer data={productDetails}
                                      error={!!errorMsg}
                                      raiseUpdates={(key: keyof ProductDetailsType, value: ValueOf<ProductDetailsType>) => {
                                          setProductDetails({
                                              ...productDetails,
                                              [key]: value
                                          })
                                          setErrorMsg(undefined)
                                      }}/>

                <div className="wizard-form-field">
                    {errorMsg && <p className={'error'}>{errorMsg}</p>}

                    <button onClick={(): void => editProduct()} className={'company-submit-btn my-5 mx-auto'}>
                        {i18n.ecommerce.edit}
                    </button>
                </div>
            </div>
        </div>
    )

}
