import React, { PureComponent, ReactNode } from 'react'
import { ManagementInstantHeader } from "../../../../../instant-management/domain/entities/managementInstantHeader";
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import { i18n } from "../../../../../configuration/i18n";
import { CompanyInstantItem } from "./companyInstant.item";

interface Props {
    instants: ManagementInstantHeader[] | null
}

export class CompanyInstants extends PureComponent <Props> {

    render(): ReactNode {
        const responsive = {
            large  : {
                breakpoint   : {max: 3000, min: 1200},
                items        : 4,
                slidesToSlide: 4
            },
            desktop: {
                breakpoint   : {max: 1199, min: 768},
                items        : 3,
                slidesToSlide: 3
            },
            tablet : {
                breakpoint   : {max: 767, min: 464},
                items        : 2,
                slidesToSlide: 2
            },
            mobile : {
                breakpoint   : {max: 464, min: 0},
                items        : 1,
                slidesToSlide: 1
            }
        };
        if (this.props.instants && this.props.instants.length > 0)
            return (
                <Carousel responsive={responsive}
                          swipeable={false}
                          draggable={false}
                          showDots={false}
                          transitionDuration={500}
                          containerClass="carousel-container">

                    {this.props.instants.map(instant => <CompanyInstantItem instant={instant} key={instant.id}/>)}
                </Carousel>
            )
        else
            return <p>{i18n.company.no_instant_found}</p>
    }
}
