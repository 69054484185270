import { LoadConversationsState } from "../../configuration/state";
import {
    LOAD_BUSINESS_CONVERSATIONS, LOAD_BUSINESS_CONVERSATIONS_FAILED, LOAD_BUSINESS_CONVERSATIONS_SUCCEEDED,
    LoadBusinessConversationsActionTypes
} from "./actionTypes";

const initialState: LoadConversationsState = {
    loading: false,
    data   : null,
    error  : undefined
}
export const loadBusinessConversationsReducer = (state = initialState, action: LoadBusinessConversationsActionTypes): LoadConversationsState => {
    switch (action.type) {
        case LOAD_BUSINESS_CONVERSATIONS:
            return {
                ...state,
                loading: true,
                error  : undefined
            }
        case LOAD_BUSINESS_CONVERSATIONS_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                data   : action.payload
            }
        case LOAD_BUSINESS_CONVERSATIONS_FAILED:
            return {
                ...state,
                loading: false,
                error  : action.payload
            }
        default:
            return state
    }
}
