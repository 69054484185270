import {
    LOAD_BROADCAST_CONVERSATION, LOAD_BROADCAST_CONVERSATION_FAILED, LOAD_BROADCAST_CONVERSATION_SUCCEEDED,
    LoadBroadcastConversationAction,
    LoadBroadcastConversationFailedAction,
    LoadBroadcastConversationSucceededAction
} from "./actionTypes";

export const loadBroadcastConversation = (businessId: string): LoadBroadcastConversationAction => ({
    type   : LOAD_BROADCAST_CONVERSATION,
    payload: businessId
})

export const loadBroadcastConversationSucceeded = (): LoadBroadcastConversationSucceededAction => ({
    type: LOAD_BROADCAST_CONVERSATION_SUCCEEDED
})

export const loadBroadcastConversationFailed = (error: string): LoadBroadcastConversationFailedAction => ({
    type   : LOAD_BROADCAST_CONVERSATION_FAILED,
    payload: error
})
