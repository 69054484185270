import {
    DELETE_MUNICIPALITY_SERVICE, DELETE_MUNICIPALITY_SERVICE_FAILED, DELETE_MUNICIPALITY_SERVICE_SUCCEEDED, DeleteMunicipalityServiceAction,
    DeleteMunicipalityServiceFailedAction, DeleteMunicipalityServiceSucceededAction
} from "./actionTypes";

export const deleteMunicipalityService = (serviceId: string): DeleteMunicipalityServiceAction => ({
    type   : DELETE_MUNICIPALITY_SERVICE,
    payload: serviceId
})

export const deleteMunicipalityServiceFailed = (error: string): DeleteMunicipalityServiceFailedAction => ({
    type   : DELETE_MUNICIPALITY_SERVICE_FAILED,
    payload: error
})

export const deleteMunicipalityServiceSucceeded = (): DeleteMunicipalityServiceSucceededAction => ({
    type: DELETE_MUNICIPALITY_SERVICE_SUCCEEDED
})
